import { useTranslation } from "react-i18next";

import UserWorkflow from "./UserWorkflow";
import Layout from "@components/layout/Layout";
import { useParams } from "react-router-dom";
import { useSubscriber } from "@hooks/query/useSubscriber";
import { useCreateUser } from "@hooks/mutation/useCreateUser";

const UserCreate = () => {
  const { t } = useTranslation();
  const { id: subscriberId = "" } = useParams();
  const { data: subscriber = {} } = useSubscriber(subscriberId, {
    enabled: Boolean(subscriberId),
  });

  const { mutateAsync: createUserMutation } = useCreateUser();

  return (
    <Layout
      permissions={["CreateUsers"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("common.subscribers"),
          href: "/polestar/subscribers",
        },
        {
          label: subscriber?.name,
          href: `/polestar/subscribers/${subscriber?.id}`,
        },
        {
          label: t("user.create.title"),
        },
      ]}
      title={t("user.create.title")}
    >
      <UserWorkflow onSubmit={createUserMutation} subscriberId={subscriberId} />
    </Layout>
  );
};

export default UserCreate;

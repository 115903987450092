import { DBTables } from "@utils/constants/dbTables";
import api from "./api";

export type SaveMessagePayload = {
  subscriberIds: string[];
  message: {
    type: string;
    subject: string;
    body: string;
    fromDate?: Date;
    toDate?: Date;
    evidence?: File;
  };
  notificationId?: string;
};

export const saveMessage = async (
  payload: SaveMessagePayload
): Promise<any> => {
  const { subscriberIds, message, notificationId } = payload;
  const { type, subject, body, fromDate, toDate, evidence } = message;

  const { data } = await api.post("/admin/messages", {
    subscriberIds,
    type,
    subject,
    body,
    fromDate,
    toDate,
    notificationId,
  });

  if (evidence && data.message) {
    const formData = new FormData();

    formData.append("title", evidence.name);
    formData.append("description", "Message attachment");
    formData.append("references[0][referenceId]", data.message?.id);
    formData.append("references[0][referenceTable]", DBTables.Messages);
    formData.append("isPublic", "false");
    formData.append("isEncrypted", "true");
    formData.append("attachment", evidence);

    for (let i = 0; i < subscriberIds.length; i++) {
      formData.append(`references[${i + 1}][referenceId]`, subscriberIds[i]);
      formData.append(
        `references[${i + 1}][referenceTable]`,
        DBTables.Subscribers
      );
    }

    await api.post("/evidences", formData);
  }

  return data.message;
};

export const saveMessageForZenith = async (messageData: {
  notificationTypeId: string;
  subject: string;
  body: string;
}): Promise<any> => {
  const { notificationTypeId, subject, body } = messageData;

  const { data } = await api.post("/messages", {
    notificationTypeId,
    subject,
    body,
  });

  return data.message;
};

export const getMessages = async (params?: {
  recipientWalletId?: string;
}): Promise<any[]> => {
  const { data } = await api.get("/admin/messages", { params });

  return data.messages;
};

export const getSentMessages = async (): Promise<any[]> => {
  const { data } = await api.get("/admin/messages");

  return data.messages;
};

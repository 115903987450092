import React from "react";
import { useTranslation } from "react-i18next";

import SelectSubscribers from "./SelectSubscribers";
import { useStepContext } from "@context/shared/StepContext";

type ForwardDrawSelectSubscribersProps = {
  subscribers: any;
  selectedSubscribers: any;
  onSelect: any;
  onCancel: () => void;
};

const ForwardDrawSelectSubscribers: React.FunctionComponent<
  ForwardDrawSelectSubscribersProps
> = ({
  subscribers,
  selectedSubscribers,
  onSelect,
  onCancel,
}: ForwardDrawSelectSubscribersProps) => {
  const { t } = useTranslation();
  const { stepHelpers } = useStepContext();

  return (
    <>
      <SelectSubscribers
        title={t("approval.forward_draws.approve.select_subscribers")}
        subscribers={subscribers}
        selectedSubscribers={selectedSubscribers}
        setSelectedSubscribers={onSelect}
      />

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>
        <button
          className="btn-primary"
          disabled={!selectedSubscribers.length}
          onClick={stepHelpers.goToNextStep}
        >
          {t("common.next_step")}
        </button>
        <button className="btn-outline-primary" onClick={onCancel}>
          {t("common.cancel")}
        </button>
      </footer>
    </>
  );
};

export default ForwardDrawSelectSubscribers;

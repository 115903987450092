import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";

export const getAccountingPeriodOptions = (
  accountingPeriods: {
    id: string;
    periodStart: string;
    periodEnd: string;
  }[],
) => {
  return accountingPeriods?.map(y => ({
    label: formatAccountingPeriod({
      periodStart: y.periodStart,
      periodEnd: y.periodEnd,
    }),
    value: y,
  }));
};

import { useTranslation } from "react-i18next";

type BoundarySelectedProps = {
  selected?: boolean;
};

const BoundarySelected: React.FunctionComponent<BoundarySelectedProps> = ({
  selected,
}) => {
  const { t } = useTranslation();

  return (
    <div className="mt-2 flex gap-1 items-center text-sm text-gray-600 font-bold">
      {selected
        ? t("location.boundary_selected")
        : t("location.boundary_not_selected")}
    </div>
  );
};

export default BoundarySelected;

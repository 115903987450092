import React from "react";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import Table from "@components/layout/Table";
import Tag from "@components/shared/Tag";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import { useStepContext } from "@context/shared/StepContext";
import { useSubscriberCreateOrEditContext } from "@context/SubscriberCreateOrEditContext";

const initialFilter = {
  name: "",
  email: "",
};

type Filter = typeof initialFilter;

const SelectPrimaryUser = () => {
  const { t } = useTranslation();
  const { stepHelpers } = useStepContext();
  const { allUsers, primaryUser, setPrimaryUser, cancel } =
    useSubscriberCreateOrEditContext();
  const [filter, setFiler] = React.useState<Filter>(initialFilter);

  const handleFilterChange = (field: keyof Filter, value: string) => {
    setFiler({
      ...filter,
      [field]: value,
    });
  };

  const tableData = allUsers
    .map((i) => ({
      id: i.id,
      radio: (
        <input
          type="radio"
          onChange={() => {
            setPrimaryUser({
              id: i.id,
              name: i.name,
            });
          }}
          checked={i.id === primaryUser.id}
        />
      ),
      name: i.name,
      email: i.email,
      tag: (
        <Tag status={i.isPrimary ? "info" : "warning"}>
          {i.isPrimary ? t("user.primary") : t("user.general")}
        </Tag>
      ),
      isPrimary: i.isPrimary,
    }))
    .filter((i) => {
      return filter.name === ""
        ? i
        : i.name.toLowerCase().includes(filter.name.toLowerCase());
    })
    .filter((i) => {
      return filter.email === ""
        ? i
        : i.email.toLowerCase().includes(filter.email.toLowerCase());
    });

  const tableFields = [
    {
      title: "",
      name: "radio",
    },
    {
      title: t("user.username"),
      name: "name",
    },
    {
      title: t("user.email"),
      name: "email",
    },
    {
      title: t("common.type"),
      name: "tag",
    },
  ];
  return (
    <>
      <div className="flex flex-col grow p-6 gap-4">
        <Heading light>{t("subscriber.create.select_primary_user")}</Heading>

        <form className="flex gap-4">
          <div className="w-70">
            <Label htmlFor="name">{t("user.filter.name")}</Label>
            <SearchInput
              id="name"
              value={filter.name}
              onChange={(e) => handleFilterChange("name", e.target.value)}
            />
          </div>

          <div className="w-70">
            <Label htmlFor="email">{t("user.filter.email")}</Label>
            <SearchInput
              id="email"
              value={filter.email}
              onChange={(e) => handleFilterChange("email", e.target.value)}
            />
          </div>
        </form>

        <Table fields={tableFields} data={tableData} />
      </div>

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>
        <button className="btn-primary" onClick={stepHelpers.goToNextStep}>
          {t("common.next_step")}
        </button>
        <button type="button" className="btn-outline-primary" onClick={cancel}>
          {t("common.cancel")}
        </button>
      </footer>
    </>
  );
};

export default SelectPrimaryUser;

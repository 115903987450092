import { useQuery } from "@tanstack/react-query";

import {
  getAllExtractionRights,
  GetAllExtractionRightsParams,
} from "@services/extractionRight";

export const useAllExtractionRights = ({
  params,
  options,
}: {
  params?: GetAllExtractionRightsParams;
  options?: Record<string, any>;
} = {}) => {
  return useQuery<any>({
    queryKey: ["extractionRights", params],
    queryFn: () => getAllExtractionRights(params),
    ...options,
  });
};

import { useMutation } from "@tanstack/react-query";

import {
  AdminTransferPayload,
  createAdminTransfer,
} from "@services/administrativeApprovals";

export const useCreateAdminTransfer = (options: any = {}) => {
  return useMutation<any, any, AdminTransferPayload>({
    mutationKey: ["create_admin_transfer"],
    mutationFn: createAdminTransfer,
    ...options,
  });
};

import api from "./api";

export const getWaterCharges = async (params?: {
  fromDate?: Date;
  toDate?: Date;
  level1ResourceId?: string;
  billingGroupId?: string;
}): Promise<any> => {
  const { data } = await api.get("/admin/waterCharges", { params });

  return data.waterCharges;
};

export const getWaterCharge = async (id: string): Promise<any> => {
  const { data } = await api.get(`/admin/waterCharges/${id}`);

  return data.waterCharge;
};

export const createWaterCharge = async (waterChargeData: {
  billingGroupId: string;
  fixedName: string;
  varName: string;
  fixedItemNo: string;
  varItemNo: string;
  fixedValue: number;
  varValue: number;
  effectiveAt: Date;
}) => {
  const { data } = await api.post("/admin/waterCharges", waterChargeData);

  return [data.waterCharge, data.workflowInstance];
};
export const updateWaterCharge = async (waterChargeData: {
  id: string;
  billingGroupId: string;
  fixedName: string;
  varName: string;
  fixedItemNo: string;
  varItemNo: string;
  fixedValue: number;
  varValue: number;
  effectiveAt: Date;
}) => {
  const { data } = await api.put(
    `/admin/waterCharges/${waterChargeData.id}`,
    waterChargeData
  );

  return [data.waterCharge, data.workflowInstance];
};

import {
  EndSpecialAnnouncementPayload,
  endSpecialAnnouncement,
} from "@services/specialAnnouncements";
import { useMutation } from "@tanstack/react-query";

export const useEndSpecialAnnouncement = (options: any = {}) => {
  return useMutation<any, any, EndSpecialAnnouncementPayload>({
    mutationKey: ["end_special_announcements"],
    mutationFn: endSpecialAnnouncement,
    ...options,
  });
};

import React from "react";

const BucketCircleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <rect width="30" height="30" fill="#004A88" rx="15"></rect>
      <path
        fill="#fff"
        d="M10.833 8a.812.812 0 00-.61.279.91.91 0 00-.221.659l.75 11.03C10.83 21.114 11.736 22 12.83 22h4.34c1.093 0 2-.886 2.078-2.032l.75-11.03a.9.9 0 00-.221-.66.816.816 0 00-.61-.278h-8.334zm1.068 4.28l-.172-2.53h6.542l-.172 2.53-.63.33c-.505.265-1.099.265-1.604 0a1.86 1.86 0 00-1.73 0c-.505.265-1.099.265-1.604 0l-.63-.33z"
      ></path>
    </svg>
  );
};

export default BucketCircleIcon;

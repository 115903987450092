import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Layout from "@components/layout/Layout";
import {
  WaterClassProvider,
  useWaterClassContext,
} from "@context/WaterClassContext";
import { EvidenceProvider } from "@context/shared/EvidenceContext";
import UpdateWaterClassWorkflow from "@components/form/water_classes/create/UpdateWaterClassWorkflow";
import { useWaterClass } from "@hooks/query/useWaterClass";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";
import { convertLiterToML } from "@utils/convertUnits";
import { formatVolume } from "@utils/formatVolume";

const UpdateWaterClass = () => {
  return (
    <EvidenceProvider>
      <WaterClassProvider>
        <Main />
      </WaterClassProvider>
    </EvidenceProvider>
  );
};

const Main = () => {
  const { t } = useTranslation();
  const { waterClassId = "", id = "" } = useParams();
  const {
    setDetails,
    details,
    setClassCategoryName,
    setUpdatingWaterClass,
    modifyStatuses,
  } = useWaterClassContext();

  const { data: level1Resource } = useGetLevel1Resource(id, {
    enabled: Boolean(id),
  });

  useWaterClass(waterClassId, {
    enabled: Boolean(waterClassId),
    onSuccess: (data: any) => {
      if (details.id === "") {
        setUpdatingWaterClass(data);
        setDetails((prev) => ({
          ...prev,
          id: data.id,
          name: data.name,
          level0ResourceId: data.level0ResourceId,
          classCategoryId: data.classCategoryId,
          volume: +convertLiterToML(data.volume),
          acctPriority: +data.acctPriority,
          allocation: data.activeExchangeRate?.rate * 100,
          startAt: data.activeExchangeRate?.startAt,
          originalName: data.name,
        }));
        setClassCategoryName(data.classCategory?.name);

        const hasChangedClassVolume = data.level0Resource?.waterClasses?.some(
          (cl: any) => +cl.volumeNextAccountingPeriod > 0
        );

        if (hasChangedClassVolume) {
          modifyStatuses({
            stepNumber: 2,
            fieldName: "yieldNextYear_1",
            message: (
              <div>
                {t("water_class.update.step_2.warning_1", {
                  level0ResourceIdentifier: data.level0Resource?.identifier,
                })}
                {data.level0Resource?.waterClasses?.length > 0 && (
                  <>
                    <ul className="mt-1 pl-3 list-disc space-y-1">
                      {data.level0Resource?.waterClasses?.map((wc: any) => (
                        <li key={`${wc.name}-next-volume`}>
                          <strong>{wc.name}: </strong>
                          {formatVolume(wc.volumeNextAccountingPeriod)}
                        </li>
                      ))}
                    </ul>
                    <p>{t("water_class.update.step_2.warning_1_suffix")}</p>
                  </>
                )}
              </div>
            ),
            infoType: "warning",
          });
        }
      }
    },
  });

  return (
    <Layout
      permissions={["CreateWaterClasses"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: level1Resource?.name,
          href: `/polestar/level1wrs/${level1Resource?.id}#0`,
        },
        {
          label: t("level1wrs.zone_and_classes"),
          href: `/polestar/level1wrs/${level1Resource?.id}#1`,
        },
        {
          label: details?.name,
          href: `/polestar/level1wrs/${level1Resource?.id}#1`,
        },
        {
          label: t("water_class.update.title"),
        },
      ]}
      title={t("water_class.update.title")}
      accountingPeriodLevel1ResourceId={level1Resource?.id}
    >
      <UpdateWaterClassWorkflow />
    </Layout>
  );
};

export default UpdateWaterClass;

import React from "react";
import classNames from "classnames";
import { noop } from "lodash";
import { useTranslation } from "react-i18next";

import Input from "react-phone-number-input";
import ErrorMessage from "@components/form/ErrorMessage";

import "react-phone-number-input/style.css";

type Props = {
  id?: string;
  name?: string;
  value?: string;
  onChange?: (value: string) => void;
  errorMessage?: string;
};

const PhoneInput: React.FunctionComponent<Props> = ({
  id,
  name,
  value,
  onChange = noop,
  errorMessage,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Input
        id={id}
        name={name}
        className={classNames(
          "phone-input appearance-none rounded-sm border border-gray-300 px-3 py-2 placeholder-gray-400 focus:outline-blue-500 focus:border-0 focus:ring-0",
          {
            "border-red-300 focus:outline-red-500": Boolean(errorMessage),
          }
        )}
        placeholder={t("subscriber.create.subscriber_phone_placeholder")}
        value={value}
        onChange={onChange}
        defaultCountry="AU"
      />

      {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
    </>
  );
};

export default PhoneInput;

import { useTranslation } from "react-i18next";

import Layout from "@components/layout/Layout";
import { useAppContext } from "@context/AppContext";
import NotificationHistory from "@components/form/notification/NotificationHistory";

const NewNotification: React.FunctionComponent = () => {
  const { notifications } = useAppContext();
  const { t } = useTranslation();

  return (
    <Layout
      permissions={["ViewNotifications"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("notification.subscriber_widget_title"),
        },
      ]}
      title={t("notification.history", {
        newNotification: notifications?.length,
      })}
    >
      <div className="flex flex-col grow">
        <NotificationHistory />
      </div>
    </Layout>
  );
};

export default NewNotification;

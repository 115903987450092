import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  updateAdministrativeApprovalStatus,
  type UpdateStatusPayload,
} from "@services/administrativeApprovals";

export const useUpdateAdministrativeApprovalStatus = (
  options: Record<string, any> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation<any, any, UpdateStatusPayload>({
    mutationFn: updateAdministrativeApprovalStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["administrativeApprovals"],
      });
    },
    ...options,
  });
};

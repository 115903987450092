import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

type MeterReading = Record<string, any>;

type ContextValue = {
  level1WRS: any;
  setLevel1WRS: React.Dispatch<React.SetStateAction<any>>;
  meterReadings: MeterReading[];
  setMeterReadings: React.Dispatch<React.SetStateAction<MeterReading[]>>;
  isValidFile: boolean;
  setIsValidFile: React.Dispatch<React.SetStateAction<boolean>>;
  handleCancel: () => void;
  reset: () => void;
  isComplete: boolean;
  setIsComplete: React.Dispatch<React.SetStateAction<boolean>>;
};

const BulkMeterUploadContext = React.createContext<ContextValue | undefined>(
  undefined
);

const BulkMeterUploadProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { id: level1ResourceId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isValidFile, setIsValidFile] = React.useState(true);
  const [level1WRS, setLevel1WRS] = React.useState<any>();
  const [isComplete, setIsComplete] = React.useState(false);
  const [meterReadings, setMeterReadings] = React.useState<MeterReading[]>([]);

  const handleCancel = () =>
    !!searchParams.get("newWindow")
      ? window.close()
      : navigate(`/polestar/level1wrs/${level1ResourceId}#5`);

  const reset = React.useCallback(() => {
    setIsValidFile(true);
    setMeterReadings([]);
  }, []);

  const values: ContextValue = {
    level1WRS,
    setLevel1WRS,
    meterReadings,
    setMeterReadings,
    handleCancel,
    isValidFile,
    setIsValidFile,
    reset,
    isComplete,
    setIsComplete,
  };

  return (
    <BulkMeterUploadContext.Provider value={values}>
      {children}
    </BulkMeterUploadContext.Provider>
  );
};

const useBulkMeterUploadContext = () => {
  const context = React.useContext(BulkMeterUploadContext);
  if (context === undefined) {
    throw new Error(
      "useBulkMeterUploadContext must be used within a BulkUploadProvider"
    );
  }
  return context;
};

export { BulkMeterUploadProvider, useBulkMeterUploadContext };

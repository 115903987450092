import { useQuery } from "@tanstack/react-query";

import { getSubscriberLevel0Resources } from "@services/subscriber";

export const useSubscriberLevel0Resources = (options?: Record<string, any>) => {
  return useQuery<any>({
    queryKey: ["subscriberLevel0Resources"],
    queryFn: () => getSubscriberLevel0Resources(),
    ...options,
  });
};

import classNames from "classnames";
import { ReactNode } from "react";

type SubHeadingProps = {
  className?: string;
  light?: boolean;
  children: ReactNode;
};

const SubHeading: React.FunctionComponent<SubHeadingProps> = ({
  className,
  children,
  light,
}: SubHeadingProps) => {
  return (
    <div className="border-b border-gray-200">
      <h1
        className={classNames(
          "text-lg text-primary-1 font-roboto",
          { "font-bold": !light, "font-light": light },
          className
        )}
      >
        {children}
      </h1>
    </div>
  );
};

export default SubHeading;

import { useTranslation } from "react-i18next";
import React from "react";
import Label from "@components/form/Label";
import TextInput from "./TextInput";
import { convertLiterToML } from "@utils/convertUnits";
import Heading from "@components/layout/Heading";

type VolumeInputProps = {
  selectedExtractionRights: any;
  volumes: number[];
  onVolumeChange: (volumes: number[]) => void;
  onNext: () => void;
  onPrevious: () => void;
  onCancel: () => void;
  fixedRate?: boolean;
};

const VolumeInput: React.FunctionComponent<VolumeInputProps> = ({
  selectedExtractionRights,
  volumes,
  onVolumeChange,
  onNext,
  onPrevious,
  onCancel,
  fixedRate = false,
}) => {
  const { t } = useTranslation();

  const handleVolumeChange = (
    index: number,
    newVolume: number,
    maxAllowedVolume: number,
  ) => {
    if (!fixedRate) {
      const updatedVolumes = [...volumes];
      updatedVolumes[index] = Math.min(newVolume, maxAllowedVolume);
      onVolumeChange(updatedVolumes);
    }
  };

  React.useEffect(() => {
    if (fixedRate) {
      const maxVolumes = selectedExtractionRights.map((right: any) =>
        Number(convertLiterToML(right.volume * 0.2)),
      );
      onVolumeChange(maxVolumes);
    }
  }, [fixedRate, selectedExtractionRights, onVolumeChange]);

  return (
    <div className="flex flex-col p-6 grow justify-between">
      <Heading light className="mb-6">
        {t("approval.forward_draws.approve.input_volumes")}
      </Heading>
      <div className="flex flex-col grow gap-6">
        <form className="mb-2">
          {selectedExtractionRights.map((right: any, index: number) => {
            const maxAllowedVolume = Number(
              convertLiterToML(right.volume * 0.2),
            );
            return (
              <div key={right.id} className="max-w-xs mb-4">
                <Label className="text-base">
                  {t("approval.forward_draws.approve.input_volume_for", {
                    extractionRightName: right.name,
                  })}
                </Label>
                <TextInput
                  type="number"
                  id={`volume-${right.id}`}
                  min={0}
                  max={maxAllowedVolume}
                  step={0.001}
                  value={fixedRate ? maxAllowedVolume : volumes[index] || 0}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleVolumeChange(
                      index,
                      Number(e.target.value),
                      maxAllowedVolume,
                    );
                  }}
                  required
                  disabled={fixedRate}
                />
                <p className="text-sm text-gray-500 mt-1">
                  {t("approval.forward_draws.approve.max_allowed")}{" "}
                  {maxAllowedVolume.toString()}
                </p>
              </div>
            );
          })}
        </form>
      </div>
      <footer className="flex gap-4 -mx-6 mt-6 p-6 pb-0 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={onPrevious}
        >
          {t("common.prev_step")}
        </button>
        <button type="button" className="btn-primary" onClick={onNext}>
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </div>
  );
};

export default VolumeInput;

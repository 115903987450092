import React from "react";

const DocumentIcon: React.FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        fill="#004A88"
        d="M8.594 10.938a.781.781 0 100 1.562h7.812a.781.781 0 100-1.563H8.594zm-.781 3.906a.781.781 0 01.78-.781h7.813a.781.781 0 110 1.562H8.594a.781.781 0 01-.781-.781zm0 3.125a.781.781 0 01.78-.782h3.126a.781.781 0 110 1.563H8.594a.781.781 0 01-.781-.781z"
      ></path>
      <path
        fill="#004A88"
        d="M14.844 0H6.25a3.125 3.125 0 00-3.125 3.125v18.75A3.125 3.125 0 006.25 25h12.5a3.125 3.125 0 003.125-3.125V7.031L14.844 0zm0 1.563v3.125a2.344 2.344 0 002.344 2.343h3.125v14.844a1.563 1.563 0 01-1.563 1.563H6.25a1.563 1.563 0 01-1.563-1.563V3.125A1.562 1.562 0 016.25 1.562h8.594z"
      ></path>
    </svg>
  );
};

export default DocumentIcon;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isFunction } from "lodash";

import { manualApproveForwardDraw } from "@services/administrativeApprovals";

export const useManualApproveForwardDraw = (
  options: Record<string, any> = {}
) => {
  const { onSuccess, ...restOptions } = options;
  const queryClient = useQueryClient();

  return useMutation(manualApproveForwardDraw, {
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["administrativeApprovals"],
      });

      if (isFunction(onSuccess)) {
        onSuccess(res);
      }
    },
    ...restOptions,
  });
};

import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link } from "react-router-dom";

import { resetPassword as authResetPassword } from "../../../services/auth";
import Heading from "../../layout/Heading";
import TextInput from "../TextInput";
import Label from "../Label";

type ResetPasswordFormProps = {
  onSubmit: (userData: any) => void;
};

const ResetPasswordForm: React.FunctionComponent<ResetPasswordFormProps> = ({
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const {
    mutateAsync: resetPassword,
    isLoading,
    error,
  } = useMutation<any, any>(() => authResetPassword({ email }));

  const handleResetPasswordClick = async (e: any) => {
    e.preventDefault();

    await resetPassword();

    onSubmit(email);
  };

  return (
    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <Heading className="mb-4 text-2xl">Please enter your email</Heading>
      <form className="space-y-6" onSubmit={handleResetPasswordClick}>
        <div>
          <Label htmlFor="email">{t("user.email")}</Label>
          <div className="mt-1">
            <TextInput
              type="email"
              id="email"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
              errorMessage={error?.response?.data?.message}
            />
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <button
            type="submit"
            className="btn-primary text-sm font-semibold w-full"
            disabled={isLoading}
          >
            {t("common.submit")}
          </button>
          <Link
            className="btn-default text-sm font-semibold w-full"
            to="../login"
          >
            {t("common.cancel")}
          </Link>
        </div>
      </form>
    </div>
  );
};

export default ResetPasswordForm;

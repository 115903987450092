import Heading1 from "@components/layout/Heading";
import ConfirmModal from "@components/shared/ConfirmModal";
import DropzoneBox from "@components/shared/DropzoneBox";
import { useAppContext } from "@context/AppContext";
import { useForwardDrawContext } from "@context/ForwardDrawContext";
import { useCreateEvidence } from "@hooks/mutation/useCreateEvidence";
import { useCreateForwardDraw } from "@hooks/mutation/zenith/useCreateForwardDraw";
import { useCreateMessage } from "@hooks/mutation/zenith/useCreateMessage";
import { DBTables } from "@utils/constants/dbTables";
import { toastError, toastSuccess } from "@utils/toast";
import { FunctionComponent, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type WorkflowCreateForwardDrawStep3Props = {
  onSubmit: () => void;
  onCancel: () => void;
};

const WorkflowCreateForwardDrawStep3: FunctionComponent<
  WorkflowCreateForwardDrawStep3Props
> = ({ onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const {
    details,
    message,
    setSupportingEvidence,
    supportingEvidence,
    setWorkflowInstance,
  } = useForwardDrawContext();
  const {
    loggedInInfo: { userDetails },
  } = useAppContext();

  const accept = {
    "image/jpeg": [".jpeg", ".jpg"],
    "image/png": [".png"],
    "application/pdf": [".pdf"],
    "application/msword": [".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
      ".xlsx",
    ],
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files: any) => setSupportingEvidence(files?.[0]),
    accept: accept,
  });

  const {
    mutateAsync: createForwardDrawMutation,
    isLoading: isCreateForwardDrawLoading,
  } = useCreateForwardDraw();
  const {
    mutateAsync: createMessageMutation,
    isLoading: isCreateMessageLoading,
  } = useCreateMessage();
  const {
    mutateAsync: createEvidenceMutation,
    isLoading: isCreateEvidenceLoading,
  } = useCreateEvidence();

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setShowConfirmModal(true);
  };

  const handleConfirm = async () => {
    try {
      const [, workflowInstance] = await createForwardDrawMutation({
        extractionRightIds: details.extractionRights?.map((er: any) => er.id),
      });

      const response = await createMessageMutation({
        notificationTypeId: message.messageTemplate?.notificationTypeId,
        subject: message.subject,
        body: message.body,
      });

      if (supportingEvidence) {
        await uploadFile(response, supportingEvidence, workflowInstance);
      }
      toastSuccess(t("approval.forward_draws.approve.toast.success"));

      setWorkflowInstance(workflowInstance);

      onSubmit();
    } catch (error) {
      toastError(t("approval.forward_draws.approve.toast.fail"));
    }
    setShowConfirmModal(false);
  };
  const uploadFile = async (
    message: any,
    file: File,
    workflowInstance: any,
  ) => {
    const evidenceReferences = [
      {
        referenceId: message?.id,
        referenceTable: DBTables.Messages,
      },
      {
        referenceId: workflowInstance?.id,
        referenceTable: DBTables.WorkflowInstances,
      },
    ];

    for (const subscriber of details.subscribers) {
      evidenceReferences.push({
        referenceId: subscriber.id,
        referenceTable: DBTables.Subscribers,
      });
    }

    await createEvidenceMutation({
      title: t("notification.evidence_data.title"),
      references: evidenceReferences,
      description: t("notification.evidence_saved", {
        senderName: userDetails?.name,
      }),
      isPublic: false,
      isEncrypted: true,
      attachment: file,
    });
  };

  return (
    <form className="flex flex-col grow" onSubmit={handleSubmit}>
      <div className="flex flex-col grow gap-4">
        <div className="w-full">
          <Heading1 className="text-xl leading-6" light>
            {t("approval.forward_draws.approve.upload_file")}
          </Heading1>
        </div>
        <div className="w-full">
          <p className="text-primary-2">
            {t("approval.forward_draws.approve.upload_heading")}
          </p>
          <DropzoneBox
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            files={supportingEvidence ? [supportingEvidence] : []}
            multiple={false}
            onDelete={() => {
              setSupportingEvidence(null);
            }}
            subtitle={
              t("approval.forward_draws.approve.upload_subtitle") as string
            }
            acceptedFileTypes={accept}
          />
        </div>
      </div>
      <footer className="p-4 flex gap-2">
        <button
          type="button"
          className="btn-outline-primary text-sm font-semibold"
          onClick={onCancel}
        >
          {t("common.prev_step")}
        </button>
        <button type="submit" className="btn-primary text-sm font-semibold">
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-default text-sm font-semibold"
          onClick={() => navigate("/zenith/workflows")}
        >
          {t("common.cancel")}
        </button>
      </footer>
      <ConfirmModal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleConfirm}
        isSubmitting={
          isCreateForwardDrawLoading ||
          isCreateEvidenceLoading ||
          isCreateMessageLoading
        }
      >
        {t("approval.forward_draws.approve.modal.request_title")}
      </ConfirmModal>
    </form>
  );
};

export default WorkflowCreateForwardDrawStep3;

import { useState } from "react";
import { Link } from "react-router-dom";
import { sumBy } from "lodash";
import { useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import Label from "@components/form/Label";
import PermanentTransferModal from "@components/modal/PermanentTransferModal";
import SearchInput from "@components/form/SearchInput";
import SelectAdminApprovalStatus from "@components/form/SelectAdminApprovalStatus";
import SelectLevel1Resource from "@components/form/SelectLevel1Resource";
import SelectAccountingPeriod from "@components/form/SelectAccountingPeriod";
import ApprovalTag from "@components/shared/ApprovalTag";
import { useAppContext } from "@context/AppContext";
import { useAllAdministrativeApprovals } from "@hooks/query/useAllAdministrativeApprovals";
import { useGetAdministrativeApproval } from "@hooks/query/useGetAdministrativeApproval";
import { formatDate } from "@utils/formatDate";
import { formatVolume } from "@utils/formatVolume";
import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";
import {
  AdministrativeApprovalNumericStatus,
  AdministrativeApprovalType,
} from "@services/administrativeApprovals";

const PermanentTransferList: React.FunctionComponent = () => {
  const { checkPermissions } = useAppContext();
  const { t } = useTranslation();
  const [selectedId, setSelectedId] = useState("");
  const [filter, setFilter] = useState<{
    level1ResourceId?: string;
    accountingPeriod?: {
      id: string;
      periodStart: Date;
      periodEnd: Date;
    };
    customerName?: string;
    status?: string;
  }>({});

  const { data: permanentTransfers = [], isLoading } =
    useAllAdministrativeApprovals({
      params: {
        type: AdministrativeApprovalType.PT,
      },
    });

  const { data: approval } = useGetAdministrativeApproval(selectedId);

  const getActionTimestamp = (app: any) => {
    let timestamp = app?.lodgedAt;

    switch (app?.status) {
      case AdministrativeApprovalNumericStatus.Approved:
        timestamp = app?.approvedAt;
        break;
      case AdministrativeApprovalNumericStatus.Declined:
        timestamp = app?.rejectedAt;
        break;
      case AdministrativeApprovalNumericStatus.UnderReview:
        timestamp = app?.updatedAt;
        break;
    }

    return formatDate(new Date(timestamp));
  };

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  return (
    <>
      <header className="flex flex-col xl:flex-row justify-between gap-4 items-start xl:items-end relative z-20">
        <form className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 md:gap-2 xl:gap-4 w-full 2xl:w-2/3">
          <div>
            <Label htmlFor="level1Resource">{t("common.level1wrs")}</Label>
            <SelectLevel1Resource
              inputId="level1Resource"
              onChange={e => {
                handleFilterChange("level1ResourceId", e?.value);
              }}
              isClearable
            />
          </div>
          {filter.level1ResourceId && (
            <div>
              <Label htmlFor="accountingPeriod">
                {t("common.accounting_period")}
              </Label>
              <SelectAccountingPeriod
                inputId="accountingPeriod"
                level1ResourceId={filter.level1ResourceId}
                value={filter.accountingPeriod}
                onChange={(e: any) => {
                  handleFilterChange("accountingPeriod", {
                    id: e?.value?.id,
                    periodStart: e?.value?.perideStart
                      ? new Date(e?.value?.periodStart)
                      : "",
                    periodEnd: e?.value?.periodEnd
                      ? new Date(e?.value?.periodEnd)
                      : "",
                  });
                }}
              />
            </div>
          )}
          <div>
            <Label htmlFor="customer-name">
              {t("subscriber.filter_subscriber_name")}
            </Label>
            <SearchInput
              id="customer-name"
              value={filter.customerName}
              onChange={e => handleFilterChange("customerName", e.target.value)}
            />
          </div>
          <div>
            <Label>{t("common.status")}</Label>
            <SelectAdminApprovalStatus
              onValueChange={value => handleFilterChange("status", value)}
              adminApprovalsAndTransfers={permanentTransfers}
            />
          </div>
        </form>
        {checkPermissions(["ViewApprovals"]) && (
          <Link
            to="permanent_trade"
            className="btn-secondary text-sm rounded whitespace-nowrap"
          >
            {t("approval.permanent_trades.create")}
          </Link>
        )}
      </header>
      <Table
        fields={[
          {
            title: t("common.level1wrs"),
            name: "level1Resource",
          },
          {
            title: t("common.accounting_period"),
            name: "accountingPeriod",
          },
          {
            title: t("approval.permanent_trades.from_subscriber"),
            name: "sellerName",
          },
          {
            title: t("approval.permanent_trades.to_subscriber"),
            name: "buyerName",
          },
          {
            title: t("extraction_right.volume"),
            name: "volume",
          },
          {
            title: t("common.status"),
            name: "status",
          },
          {
            title: t("approval.permanent_trades.timestamp"),
            name: "timestamp",
          },
          {
            title: t("common.effective_date"),
            name: "effectiveDate",
          },
          {
            title: t("common.action"),
            name: "action",
          },
        ]}
        data={permanentTransfers
          ?.filter(
            (row: { seller: any; buyer: any }) =>
              !filter?.customerName?.length ||
              row.seller?.name
                .toString()
                .toLowerCase()
                .includes(filter?.customerName.toString().toLowerCase()) ||
              row.buyer?.name
                .toString()
                .toLowerCase()
                .includes(filter?.customerName.toString().toLowerCase()),
          )
          ?.filter(
            (row: { level1Resource: any }) =>
              filter.level1ResourceId === undefined ||
              row.level1Resource?.id === filter.level1ResourceId,
          )
          ?.filter(
            (row: { accountingPeriod: any }) =>
              filter.accountingPeriod === undefined ||
              row.accountingPeriod?.id === filter.accountingPeriod?.id,
          )
          ?.filter(
            (row: { status: any }) =>
              filter.status === undefined || row.status === filter.status,
          )
          ?.map((app: any) => ({
            ...app,
            level1Resource: app.level1Resource.identifier,
            createdAt: formatDate(new Date(app.lodgedAt)),
            sellerName: app.seller.name,
            buyerName: app.buyer.name,
            volume: formatVolume(
              sumBy(app.extractionRights, (i: any) => +i.volume),
            ),
            accountingPeriod: formatAccountingPeriod(app.accountingPeriod),
            status: (
              <ApprovalTag
                isAccounted={Boolean(app?.accountedAt)}
                status={app?.status}
              />
            ),
            timestamp: getActionTimestamp(app),
            effectiveDate: app.effectiveDate
              ? formatDate(new Date(app.effectiveDate))
              : "-",
            action: (
              <div className="flex gap-1 items-center w-full">
                <button
                  type="button"
                  onClick={() => setSelectedId(app.id)}
                  className="btn-default text-sm"
                >
                  {t("common.view")}
                </button>
              </div>
            ),
          }))}
        stickyHeader
        loading={isLoading}
      />

      {selectedId && approval ? (
        <PermanentTransferModal
          data={approval}
          onClose={() => setSelectedId("")}
        />
      ) : null}
    </>
  );
};

export default PermanentTransferList;

import { useMutation } from "@tanstack/react-query";

import {
  updateAccountingPeriod,
  updateYearPayload,
} from "@services/accountingPeriod";

export const useUpdateAccountingPeriod = (options: any = {}) => {
  return useMutation<any, any, updateYearPayload>({
    mutationKey: ["updateAccountingPeriod"],
    mutationFn: updateAccountingPeriod,
    ...options,
  });
};

import Select from "react-select";

import ErrorMessage from "@components/form/ErrorMessage";

type Props = {
  onChange?: (value: any) => void;
  className?: string;
  value?: number;
  name?: string;
  errorMessage?: string;
};

const meterUnits = [
  { label: "ML", value: 1 },
  { label: "M3", value: 2 },
  { label: "AF", value: 3 },
];

export function getMeterUnit(value: number) {
  return meterUnits.find((item) => item.value === value)?.label ?? "";
}

const SelectMeterUnits: React.FunctionComponent<Props> = ({
  onChange,
  className,
  value = 1,
  name,
  errorMessage,
}) => {
  return (
    <>
      <Select
        className={className}
        value={meterUnits.find((i) => i.value === value) ?? meterUnits[0]}
        name={name}
        options={meterUnits}
        onChange={onChange}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};

export default SelectMeterUnits;

import { useMutation } from "@tanstack/react-query";

import { SaveMessagePayload, saveMessage } from "@services/message";

export const useCreateMessage = (options: any = {}) => {
  return useMutation<any, any, SaveMessagePayload>({
    mutationKey: ["create_message"],
    mutationFn: saveMessage,
    ...options,
  });
};

import WorkflowPanels from "@components/form/WorkflowPanels";
import InfoPanel from "@components/form/InfoPanel";
import WaterClassDetails from "@components/form/water_classes/create/WaterClassDetails";
import WaterClassVolume from "@components/form/water_classes/create/WaterClassVolume";
import WaterClassLedger from "@components/form/water_classes/create/WaterClassLedger";
import CheckCircleIcon from "@components/icons/CheckCircleIcon";
import StepButton from "@components/shared/StepButton";
import { useWaterClassContext } from "@context/WaterClassContext";
import { formatVolume } from "@utils/formatVolume";
import WaterClassInfoTable from "@components/table/WaterClassInfoTable";
import StepInfo from "@components/shared/StepInfo";
import { useEvidenceContext } from "@context/shared/EvidenceContext";
import UploadEvidencesWorkflowForm from "@components/form/UploadEvidenceWorkflowForm";
import { Trans, useTranslation } from "react-i18next";
import WaterClassWorkflowCompleted from "./WaterClassWorkflowCompleted";
import InformationCircleIcon from "@components/icons/InformationCircleIcon";

const UpdateWaterClassWorkflow = () => {
  const { t } = useTranslation();
  const {
    currentStep,
    level0Resource,
    details,
    setDetails,
    stepHelpers,
    waterClasses,
    classCategoryName,
    isVolumeExceed,
    navigateForCancel,
    workflowCompleted,
    totalWaterClassesVolume,
    getInfoMessages,
  } = useWaterClassContext();

  const { getEvidencesInfo } = useEvidenceContext();

  const steps = [
    {
      label: (
        <StepButton
          index={0}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
          info={
            <ul className="text-sm text-gray-600 mt-1 space-y-1">
              {[
                {
                  key: t("common.class"),
                  value: details?.name,
                },
                {
                  key: t("common.level0wrs"),
                  value: level0Resource?.identifier,
                },
                {
                  key: t("water_class.class_category"),
                  value: classCategoryName,
                },
              ].map(({ key, value }: any) => {
                return (
                  <li key={key}>
                    <strong>{key}:</strong> {value}
                  </li>
                );
              })}
            </ul>
          }
        >
          {t("water_class.enter_details")}
        </StepButton>
      ),
      panel: <WaterClassDetails />,
      infoPanel: (
        <InfoPanel>
          <ul className="space-y-6 mb-6">
            <li>{t("water_class.info.enter_name")}</li>
            <li>{t("water_class.info.select_zone")}</li>
          </ul>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={1}
          currentStep={currentStep}
          info={
            <ul className="text-sm text-gray-600 mt-1 space-y-1">
              {[
                {
                  key: t("common.volume"),
                  value: `${details?.volume} ${t("common.volume_unit")}`,
                },
                {
                  key: t("exchange_rate.rate"),
                  value: details?.allocation,
                },
                {
                  key: t("common.priority"),
                  value: details?.acctPriority,
                },
              ].map(({ key, value }: any) => {
                return (
                  <li key={key}>
                    <strong>{key}:</strong> {value}
                  </li>
                );
              })}
            </ul>
          }
          isOnChain={workflowCompleted}
        >
          {t("water_class.enter_class_volume")}
        </StepButton>
      ),
      panel: <WaterClassVolume />,
      infoPanel: (
        <InfoPanel
          errors={[
            ...(isVolumeExceed ? [t("water_class.info.volume_exceed")] : []),
            ...getInfoMessages("error", 2),
          ]}
          successes={getInfoMessages("success", 2)}
          warnings={getInfoMessages("warning", 2)}
        >
          <div className="space-y-8">
            <ul className="space-y-4">
              {isVolumeExceed ? (
                <>
                  <li>
                    {t("water_class.info.total_zone_volume", {
                      level0ResourceName: level0Resource?.identifier,
                      volume: formatVolume(
                        level0Resource?.yield - totalWaterClassesVolume
                      ),
                    })}
                  </li>
                  <li>{t("water_class.info.enter_valid_volume")}</li>
                </>
              ) : (
                <>
                  <li>
                    {t("water_class.info.enter_volume")}
                    <div className="flex gap-2 mt-2">
                      <InformationCircleIcon className="text-blue-600 w-5 h-5 shrink-0" />
                      <span>{t("water_class.info.enter_volume_info")}</span>
                    </div>
                  </li>
                  <li>{t("water_class.info.enter_allocation")}</li>
                  <li>{t("water_class.info.enter_priority")}</li>
                  <li>{t("water_class.info.enter_existing_priority")}</li>
                </>
              )}
            </ul>

            <div className="space-y-4">
              {waterClasses?.length ? (
                <WaterClassInfoTable
                  data={waterClasses}
                  totalVolume={totalWaterClassesVolume}
                />
              ) : (
                <div className="flex gap-2">
                  <CheckCircleIcon className="text-green-600 w-5 h-5 flex-shrink-0" />
                  <p>
                    {t("water_class.info.no_water_class", {
                      level0ResourceName: level0Resource?.identifier,
                    })}
                  </p>
                </div>
              )}
            </div>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={2}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
          info={<StepInfo data={getEvidencesInfo().body} />}
        >
          {t("water_class.upload_evidence")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          referenceTable="water_classes"
          referenceTableId={details.id}
          onNext={(activatedEvidenceIds) => {
            setDetails((prevState: any) => ({
              ...prevState,
              activatedEvidenceIds: activatedEvidenceIds,
            }));
            stepHelpers.goToNextStep();
          }}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={navigateForCancel}
          activatedEvidences={details.activatedEvidenceIds}
          customDropboxTitle={t("water_class.create.step_3.title") as string}
          newUploadDescription={
            t("water_class.create.step_3.description", {
              waterClassName: details.name,
            }) as string
          }
        />
      ),
      infoPanel: (
        <InfoPanel>
          <ul className="space-y-4">
            <li>{t("water_class.info.select_file")}</li>
            <li>{t("water_class.info.evidence")}</li>
          </ul>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={3}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
          forceSuccess={workflowCompleted}
        >
          {t("water_class.ledger")}
        </StepButton>
      ),
      panel: workflowCompleted ? (
        <WaterClassWorkflowCompleted />
      ) : (
        <WaterClassLedger />
      ),
      infoPanel: (
        <InfoPanel
          successes={[t("water_class.info.definition_complete")]}
          actions={[
            ...(workflowCompleted
              ? [
                  {
                    label: t("water_class.create.title"),
                    action: () => {
                      window.location.reload();
                    },
                  },
                ]
              : []),
          ]}
        >
          <div className="space-y-4">
            <p>{t("information.edit_info_intro")}</p>
            <p className="pl-2">
              <Trans i18nKey="water_class.create.step_4.class_details_info" />
            </p>
            <p className="pl-2">
              <Trans i18nKey="water_class.create.step_4.class_vol_details_info" />
            </p>
            <p className="pl-2">
              <Trans i18nKey="information.edit_info_evidence" />
            </p>
            <p>{t("information.edit_conclusion")}</p>
          </div>
        </InfoPanel>
      ),
    },
  ];

  return <WorkflowPanels selectedStep={currentStep} steps={steps} />;
};

export default UpdateWaterClassWorkflow;

import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import EventTransactionPaginatedTable from "@components/table/EventTransactionPaginatedTable";
import EndButtonModal from "@components/shared/EndButton";
import { useAmalgamateOrSubdivideExtractionRightsContext } from "@context/AmalgamateOrSubdivideExtractionRightContext";

const AmalgamateOrSubdivideWorkflowCompleted = () => {
  const { t } = useTranslation();
  const { workflowInstance } =
    useAmalgamateOrSubdivideExtractionRightsContext();

  return (
    <>
      <div className="flex flex-col gap-6 p-6 pb-0 grow justify-between">
        <Heading light>
          {t("extraction_right.create.workflow_complete.title")}
        </Heading>
        <EventTransactionPaginatedTable
          references={[workflowInstance?.id]}
          stickyHeader={true}
        />
      </div>
      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <EndButtonModal />
      </footer>
    </>
  );
};

export default AmalgamateOrSubdivideWorkflowCompleted;

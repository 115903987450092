import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Link, generatePath } from "react-router-dom";
import classNames from "classnames";
import get from "lodash/get";

import Pagination from "./Pagination";
import Loading from "@components/shared/Loading";
import { t } from "i18next";

type TableMultiSeletionProps = {
  containerClassName?: string;
  tableHeaderClassName?: string;
  className?: string;
  fields: Array<{
    title: string;
    name: string;
    linkTo?: string;
  }>;
  data: Array<Record<string, any>>;
  noRowsText?: ReactNode | null;
  pageSize?: number;
  selectionKey?: string;
  selectedKeys?: any[];
  clearSelectionOnDataChanged?: boolean;
  onSelectionChange?: (data: any[]) => void;
  stickyHeader?: boolean;
  loading?: boolean;
};

const TableMultiSelection: React.FunctionComponent<TableMultiSeletionProps> = ({
  containerClassName,
  tableHeaderClassName,
  className,
  fields,
  data,
  noRowsText = t("common.no_data"),
  pageSize = 100,
  selectionKey,
  selectedKeys,
  onSelectionChange,
  stickyHeader = false,
  loading = false,
  clearSelectionOnDataChanged = false,
}) => {
  const [pageNo, setPageNo] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [selectedRecordKeys, setSelectedRecordKeys] = useState<any[]>(
    selectedKeys || []
  );
  const [selectAll, setSelectAll] = useState(false);
  const tableWrapperRef = useRef<HTMLDivElement>(null);

  const handleDataSelectionClick = (record: any) => {
    const i = selectedRecordKeys.indexOf(record[selectionKey ?? ""]);
    const updatedSelectedRecords = [...selectedRecordKeys];

    if (i !== -1) {
      updatedSelectedRecords.splice(i, 1);
    } else {
      updatedSelectedRecords.push(record[selectionKey as string]);
    }
    setSelectedRecordKeys(updatedSelectedRecords);

    if (onSelectionChange) onSelectionChange(updatedSelectedRecords);
  };

  const handleSelectAllClick = () => {
    const updatedSelectAll = !selectAll;
    let updatedSelectedRecords = updatedSelectAll
      ? data.map((record) => record[selectionKey as string])
      : [];

    setSelectedRecordKeys(updatedSelectedRecords);
    setSelectAll(updatedSelectAll);

    if (onSelectionChange) onSelectionChange(updatedSelectedRecords);
  };

  const handlePageChange = (page: number) => {
    setPageNo(page);
    if (tableWrapperRef.current) {
      tableWrapperRef.current.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (data?.length && pageSize) {
      const newLastPage = Math.ceil(data?.length / pageSize);
      setLastPage(newLastPage);
      if (data?.length <= pageSize) {
        setPageNo(0);
      } else if (newLastPage < pageNo) {
        setPageNo(newLastPage - 1);
      }
    } else if (!data?.length && clearSelectionOnDataChanged) {
      setSelectedRecordKeys([]);
    }
  }, [pageSize, pageNo, data, clearSelectionOnDataChanged]);

  const headerClassNames = classNames(
    "backdrop-blur-none table-cell backdrop-filter text-left font-semibold px-2 py-4 first-of-type:pl-4 bg-opacity-100 bg-gray-50 border-b-opacity-100",
    {
      "sticky top-0 z-0": stickyHeader,
    }
  );

  return (
    <div className="flex flex-col grow">
      <div
        ref={tableWrapperRef}
        className={classNames(
          "md:rounded relative overflow-auto grow border border-gray-200",
          {
            "h-0": stickyHeader,
          },
          containerClassName
        )}
      >
        <table
          className={classNames(
            "table-auto min-w-full divide-y divide-gray-200 text-sm",
            className
          )}
        >
          <thead className={classNames("bg-gray-50", tableHeaderClassName)}>
            <tr>
              {selectionKey && (
                <th scope="col" className={headerClassNames}>
                  <input
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 mt-1"
                    checked={selectAll}
                    onChange={handleSelectAllClick}
                  />
                </th>
              )}
              {fields?.map((th, i) => (
                <th
                  key={`${th.name}:${i}`}
                  scope="col"
                  className={headerClassNames}
                >
                  {th.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {loading ? (
              <tr>
                <td colSpan={fields.length} className="pt-10">
                  <Loading />
                </td>
              </tr>
            ) : data?.length ? (
              [...(data || [])]
                ?.reverse()
                ?.sort((a: any, b: any) => {
                  if (selectionKey && selectedKeys?.includes(b[selectionKey]))
                    return 1;

                  return -1;
                })
                ?.slice(
                  pageSize ? pageNo * pageSize : 0,
                  pageSize ? pageNo * pageSize + pageSize : data?.length
                )
                ?.map((td, i) => (
                  <tr key={`tbody-row--${i}`}>
                    {selectionKey && (
                      <td
                        key={`td-checkbox--${i}`}
                        className="whitespace-nowrap px-2 py-3 first-of-type:pl-4"
                      >
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 mt-1"
                          checked={selectedRecordKeys.includes(
                            td[selectionKey]
                          )}
                          onChange={() => handleDataSelectionClick(td)}
                        />
                      </td>
                    )}
                    {fields.map((field, j) => (
                      <td
                        key={`${i}_${j}`}
                        className="whitespace-nowrap px-2 py-3 first-of-type:pl-4"
                      >
                        {field.linkTo ? (
                          <Link
                            to={generatePath(field.linkTo, td)}
                            className="text-indigo-700 underline"
                          >
                            {get(td, field.name)}
                          </Link>
                        ) : (
                          get(td, field.name)
                        )}
                      </td>
                    ))}
                  </tr>
                ))
            ) : (
              <tr>
                <td
                  colSpan={selectionKey ? fields.length + 1 : fields.length}
                  className="py-6 whitespace-nowrap text-gray-400 text-center"
                >
                  {noRowsText}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {data?.length > pageSize && (
        <Pagination
          pageNo={pageNo}
          lastPage={lastPage}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default TableMultiSelection;

import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import UserForm from "@components/form/user/UserForm";
import { useUserContext } from "@context/UserContext";

const EnterUserDetails = () => {
  const { t } = useTranslation();
  const { level1ResourceId, stepHelpers, user, setUser, cancel, isPrimary } =
    useUserContext();

  const handleSubmit = (data: any) => {
    setUser(data);
    stepHelpers.goToNextStep();
  };

  return (
    <div className="flex flex-col grow p-6">
      <Heading light className="mb-8">
        {t("user.details")}
      </Heading>

      <UserForm
        defaultValues={user}
        onSubmit={handleSubmit}
        onCancel={cancel}
        level1ResourceId={level1ResourceId}
        isPrimary={isPrimary}
      />
    </div>
  );
};

export default EnterUserDetails;

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Layout from "@components/layout/Layout";
import UpdateExtractionPointWorkflow from "@components/form/extraction_point/UpdateExtractionPointWorkflow";
import { useLevel0Resource } from "@hooks/query/useLevel0Resource";
import { useSubscriber } from "@hooks/query/useSubscriber";
import { useExtractionPoint } from "@hooks/query/useExtractionPoint";

const UpdateExtractionPoint = () => {
  const { t } = useTranslation();
  const {
    id: subscriberId = "",
    level0ResourceId = "",
    extractionPointId = "",
  } = useParams();
  const { data: subscriber } = useSubscriber(subscriberId);
  const { data: level0Resource } = useLevel0Resource(level0ResourceId);
  const { data: extractionPoint } = useExtractionPoint(extractionPointId);

  return (
    <Layout
      permissions={["UpdateExtractionPoint"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("common.subscribers"),
          href: "/polestar/subscribers",
        },
        {
          label: subscriber?.name,
          href: `/polestar/subscribers/${subscriberId}`,
        },

        {
          label: level0Resource?.identifier,
          href: `/polestar/subscribers/${subscriberId}?level0ResourceId=${level0ResourceId}`,
        },
        {
          label: t("common.extraction_points"),
          href: `/polestar/subscribers/${subscriberId}?level0ResourceId=${level0ResourceId}&extractionPointId=random`,
        },
        {
          label: extractionPoint?.name,
          href: `/polestar/subscribers/${subscriberId}?level0ResourceId=${level0ResourceId}&extractionPointId=${extractionPointId}`,
        },
        {
          label: t("extraction_point.edit"),
        },
      ]}
      title={t("extraction_point.edit")}
      accountingPeriodLevel1ResourceId={subscriber?.level1ResourceId}
    >
      <UpdateExtractionPointWorkflow />
    </Layout>
  );
};

export default UpdateExtractionPoint;

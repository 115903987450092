import React from "react";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import SelectMultipleExtractionPointTable from "@components/table/SelectMultipleExtractionPointTable";
import { usePermanentTransferContext } from "@context/PermanentTransferContext";
import { useStepContext } from "@context/shared/StepContext";

type SelectExtractionPointsProps = any;

const SelectExtractionPoints: React.FunctionComponent<
  SelectExtractionPointsProps
> = () => {
  const { t } = useTranslation();
  const { stepHelpers } = useStepContext();
  const {
    handleCancel,
    seller,
    level0Resource,
    selectedExtractionPoints,
    setSelectedExtractionPoints,
  } = usePermanentTransferContext();

  return (
    <>
      <div className="flex flex-col grow gap-6 p-6">
        <Heading light>
          {t("approval.permanent_trades.select_extraction_point")}
        </Heading>
        <SelectMultipleExtractionPointTable
          subscriber={seller}
          onSelect={(values) => {
            setSelectedExtractionPoints(
              values.map((i) => ({
                id: i.id,
                name: i.name,
              }))
            );
          }}
          selectedKeys={selectedExtractionPoints.map((i) => i.id)}
          level0ResourceId={level0Resource.id}
        />
      </div>

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>
        <button
          type="button"
          className="btn-primary"
          onClick={stepHelpers.goToNextStep}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={handleCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </>
  );
};

export default SelectExtractionPoints;

import Label from "@components/form/Label";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import MapView from "./MapView";

type SelectMapLocationProps = {
  locationCoordinates: { lat: string; lng: string };
  setLocationCoordinates: (location: { lat: string; lng: string }) => void;
};

const SelectMapLocation: React.FunctionComponent<SelectMapLocationProps> = ({
  setLocationCoordinates,
  locationCoordinates,
}) => {
  const { t } = useTranslation();
  const [center] = useState<google.maps.LatLngLiteral>({
    lat: isNaN(parseFloat(locationCoordinates.lat))
      ? -27.619841404315526
      : parseFloat(locationCoordinates.lat),
    lng: isNaN(parseFloat(locationCoordinates.lng))
      ? 152.75642671041598
      : parseFloat(locationCoordinates.lng),
  });

  const onClick = (e: any) => {
    setLocationCoordinates({ lat: e.lat.toString(), lng: e.lng.toString() });
  };

  return (
    <div className="space-y-4 mt-4">
      <div>
        <Label className="mb-2">{t("shared.map.ask_drop_pin")}</Label>
        <div className="h-96 max-w-md mt-4">
          <MapView
            center={center}
            onClick={(e: any) => onClick(e)}
            zoomLevel={14}
            markers={[
              {
                lat: Number(locationCoordinates.lat),
                lng: Number(locationCoordinates.lng),
              },
            ]}
          />
        </div>
      </div>
      {locationCoordinates.lat && locationCoordinates.lng && (
        <div className="space-y-4">
          <ul className="mt-2 space-y-2 text-gray-600 text-sm">
            <li>
              <strong>{t("common.latitude")}:</strong> {locationCoordinates.lat}
            </li>
            <li>
              <strong>{t("common.longitude")}:</strong>{" "}
              {locationCoordinates.lng}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default SelectMapLocation;

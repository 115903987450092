import { useTranslation } from "react-i18next";

import LockIcon from "@components/icons/LockIcon";

const OnChain = () => {
  const { t } = useTranslation();

  return (
    <div className="flex gap-1 items-center text-sm text-gray-600 font-bold">
      <LockIcon className="w-5 h-5" />
      {t("common.on_chain")}
    </div>
  );
};

export default OnChain;

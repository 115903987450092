import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import { useTranslation } from "react-i18next";

type CoordinatesInputProps = {
  locationCoordinates: { lat: string; lng: string };
  setLocationCoordinates: (location: { lat: string; lng: string }) => void;
};

const CoordinatesInput: React.FunctionComponent<CoordinatesInputProps> = ({
  locationCoordinates,
  setLocationCoordinates,
}) => {
  const { t } = useTranslation();
  const handleLatitudeChange = (e: any) => {
    const value = e.target.value;
    setLocationCoordinates({
      ...locationCoordinates,
      lat: value,
    });
  };

  const handleLongitudeChange = (e: any) => {
    const value = e.target.value;
    setLocationCoordinates({
      ...locationCoordinates,
      lng: value,
    });
  };

  const isValidLatitude = (value: string): boolean => {
    if (value === "") return true;
    const latitude = parseFloat(value);
    return (
      Number.isFinite(latitude) &&
      !isNaN(latitude) &&
      latitude >= -90 &&
      latitude <= 90
    );
  };

  const isValidLongitude = (value: string): boolean => {
    if (value === "") return true;
    const longitude = parseFloat(value);
    return (
      Number.isFinite(longitude) &&
      !isNaN(longitude) &&
      longitude >= -180 &&
      longitude <= 180
    );
  };

  return (
    <fieldset className="space-y-4">
      <div>
        <Label htmlFor="latitude">{t("common.latitude")}</Label>
        <TextInput
          className="max-w-md mt-2"
          type="text"
          value={locationCoordinates.lat}
          onChange={(e) => handleLatitudeChange(e)}
          placeholder="Latitude"
          errorMessage={
            isValidLatitude(locationCoordinates.lat)
              ? ""
              : (t("location.invalid_latitude") as string)
          }
        />
      </div>
      <div>
        <Label htmlFor="maximum_volume">{t("common.longitude")}</Label>
        <TextInput
          className="max-w-md"
          type="text"
          value={locationCoordinates.lng}
          onChange={(e) => handleLongitudeChange(e)}
          placeholder="Longitude"
          errorMessage={
            isValidLongitude(locationCoordinates.lng)
              ? ""
              : (t("location.invalid_longitude") as string)
          }
        />
      </div>
    </fieldset>
  );
};

export default CoordinatesInput;

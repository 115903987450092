import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { useAdministrativeApproval } from "@hooks/query/zenith/useAdministrativeApproval";
import { AdministrativeApprovalNumericType } from "@services/administrativeApprovals";
import SeasonalAssignmentModal from "@components/modal/SeasonalAssignmentModal";
import PermanentTransferModal from "@components/modal/PermanentTransferModal";
import ForwardDrawModal from "@components/modal/ForwardDrawModal";
import StockAndDomesticModal from "@components/modal/StockAndDomesticModal";
import AdminTransferModal from "./AdminTransferModal";

type AdministrativeApprovalModalProps = {
  id: string;
};

const AdministrativeApprovalModal: React.FunctionComponent<
  AdministrativeApprovalModalProps
> = ({ id }) => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading } = useAdministrativeApproval(id);

  if (isLoading) return null;

  const handleClose = () => {
    searchParams.delete("id");
    setSearchParams();
  };

  const modalProps = {
    data,
    onClose: handleClose,
    actionButtons: (
      <button className="btn-default" onClick={handleClose}>
        {t("common.close")}
      </button>
    ),
  };

  switch (data?.type) {
    case AdministrativeApprovalNumericType.SWT:
      return <SeasonalAssignmentModal {...modalProps} />;

    case AdministrativeApprovalNumericType.PT:
      return <PermanentTransferModal {...modalProps} />;

    case AdministrativeApprovalNumericType.FD:
      return <ForwardDrawModal {...modalProps} />;

    case AdministrativeApprovalNumericType.SD:
      return <StockAndDomesticModal {...modalProps} />;

    case AdministrativeApprovalNumericType.AT:
      return <AdminTransferModal {...modalProps} />;

    default:
      return null;
  }
};

export default AdministrativeApprovalModal;

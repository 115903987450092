import { useTranslation } from "react-i18next";
import HandleGoBackOrClose from "@components/shared/HandleGoBackOrClose";

type AccountingPeriodChecklistFooterProps = {
  isLastStep?: boolean;
  disabled?: boolean;
};

const AccountingPeriodChecklistFooter: React.FunctionComponent<
  AccountingPeriodChecklistFooterProps
> = ({ isLastStep, disabled }) => {
  const { t } = useTranslation();
  const handleGoBackOrClose = HandleGoBackOrClose();

  const handleCancelClick = handleGoBackOrClose;

  return (
    <>
      <footer className="flex gap-4 p-6 border-t border-gray-200">
        {!isLastStep && (
          <button type="submit" className="btn-primary" disabled={disabled}>
            {t("common.continue")}
          </button>
        )}
        <button className="btn-outline-primary" onClick={handleCancelClick}>
          {t("common.cancel")}
        </button>
      </footer>
    </>
  );
};

export default AccountingPeriodChecklistFooter;

import React from "react";
import { useTranslation } from "react-i18next";

import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import Heading from "@components/layout/Heading";
import Table from "@components/layout/Table";
import Tag from "@components/shared/Tag";

type Subscriber = Record<string, any>;

type SelectSubscriberProps = {
  title: string;
  onSelect: (item: Subscriber) => void;
  selected?: Subscriber;
  children?: React.ReactNode;
  subscribers: any;
};

const SelectSubscriber: React.FunctionComponent<SelectSubscriberProps> = ({
  title,
  onSelect,
  selected,
  children,
  subscribers,
}) => {
  const { t } = useTranslation();
  const [filter, setFilter] = React.useState("");
  const tableFields = [
    {
      title: "",
      name: "radio",
    },
    {
      title: t("subscriber.account_number"),
      name: "customerId",
    },
    {
      title: t("common.level1wrs"),
      name: "scheme",
    },
    {
      title: t("subscriber.is_active"),
      name: "status",
    },

    {
      title: t("subscriber.name"),
      name: "customerName",
    },
  ];

  const tableData = subscribers
    .filter((subscriber: any) => {
      const matcher = filter.toLowerCase();
      return (
        subscriber?.accountNumber?.toString()?.includes(matcher) ||
        subscriber?.name?.toLowerCase().includes(matcher)
      );
    })
    .map((subscriber: any) => {
      return {
        id: subscriber.id,
        radio: (
          <input
            type="radio"
            name="customer_id"
            checked={selected?.id === subscriber.id}
            onChange={() => onSelect(subscriber)}
          />
        ),
        customerId: subscriber.accountNumber,
        customerName: subscriber.name,
        scheme: subscriber.level1WRS.name,
        status: <Tag status="success">Active</Tag>,
      };
    });

  return (
    <div className="p-6 pb-0 flex flex-col gap-4 grow">
      <div className="flex justify-between">
        <Heading light>{title}</Heading>
        {children}
      </div>
      <form className="max-w-xs">
        <Label htmlFor="subscriberName">{t("subscriber.filter_name_id")}</Label>
        <SearchInput
          id="subscriberName"
          onChange={(e) => setFilter(e.target.value)}
        />
      </form>
      <Table fields={tableFields} data={tableData} stickyHeader></Table>
    </div>
  );
};

export default SelectSubscriber;

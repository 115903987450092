import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import { useSeasonalTransferContext } from "@context/SeasonalTransferContext";
import EventTransactionPaginatedTable from "@components/table/EventTransactionPaginatedTable";
import EndButtonModal from "@components/shared/EndButton";

const SeasonalTransferWorkflowCompleted = () => {
  const { t } = useTranslation();
  const { workflowInstances } = useSeasonalTransferContext();

  return (
    <>
      <div className="flex flex-col gap-2 p-6 pb-0 grow">
        <Heading light>{t("level0wrs.create.workflow_complete")}</Heading>
        <EventTransactionPaginatedTable
          references={workflowInstances.map(
            (workflowInstance) => workflowInstance.id,
          )}
          stickyHeader={true}
        />
      </div>
      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <EndButtonModal />
      </footer>
    </>
  );
};

export default SeasonalTransferWorkflowCompleted;

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { createMeter } from "@services/meter";
import { isArray } from "lodash";

export function useCreateMeter(options: any = {}) {
  const queryClient = useQueryClient();

  return useMutation(createMeter, {
    onMutate: async (newMeter: any) => {
      await queryClient.cancelQueries({ queryKey: ["meters"] });
      const previousMeter = queryClient.getQueryData(["meters"]);
      queryClient.setQueryData(["meters"], (old: any) => {
        if (isArray(old)) {
          return [newMeter, ...old];
        }

        return [newMeter];
      });
      return { previousMeter };
    },
    onError: (_err: any, _newMeter: any, context: any) => {
      queryClient.setQueryData(["meters"], context.previousMeter);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["meters"],
      });
    },
    ...options,
  });
}

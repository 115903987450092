import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isFunction } from "lodash";

import { createForwardDraw } from "@services/administrativeApprovals";

export function useCreateForwardDraw(options: any = {}) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;

  return useMutation<any, any, any>({
    mutationKey: ["create_forward_draw"],
    mutationFn: createForwardDraw,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["forward_draws"] });

      if (isFunction(onSuccess)) {
        onSuccess();
      }
    },
    ...restOptions,
  });
}

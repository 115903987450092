import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Layout from "@components/layout/Layout";
import WorkflowPanels from "@components/form/WorkflowPanels";
import StepButton from "@components/shared/StepButton";
import StepInfo from "@components/shared/StepInfo";
import {
  AdminTransferProvider,
  useAdminTransferContext,
} from "@context/AdminTransferContext";
import InfoPanel from "@components/form/InfoPanel";
import AdminTransferSelectLevel1WRS from "@components/form/admin_approvals/create_admin_transfer/AdminTransferSelectLevel1WRS";
import AdminTransferSelectSubscriber from "@components/form/admin_approvals/create_admin_transfer/AdminTransferSelectSubscriber";
import AdminTransferSelectExtractionRight from "@components/form/admin_approvals/create_admin_transfer/AdminTransferSelectExtractionRight";
import AdminTransferSelectExtractionPoint from "@components/form/admin_approvals/create_admin_transfer/AdminTransferSelectExtractionPoint";
import AdminTransferLedger from "@components/form/admin_approvals/create_admin_transfer/AdminTransferLedger";
import AdminTransferWorkflowCompleted from "@components/form/admin_approvals/create_admin_transfer/AdminTransferWorkflowCompleted";
import {
  EvidenceProvider,
  useEvidenceContext,
} from "@context/shared/EvidenceContext";
import UploadEvidencesWorkflowForm from "@components/form/UploadEvidenceWorkflowForm";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";

const CreateAdminTransfer = () => {
  return (
    <EvidenceProvider>
      <AdminTransferProvider>
        <Consumer />
      </AdminTransferProvider>
    </EvidenceProvider>
  );
};

const Consumer = () => {
  const { t } = useTranslation();
  const {
    currentStep,
    stepHelpers,
    details,
    handleChangeDetails,
    workflowCompleted,
    getInfoMessages,
    navigateForCancel,
    confirmData,
  } = useAdminTransferContext();
  const { evidences } = useEvidenceContext();
  const [searchParams] = useSearchParams();
  const level1ResourceId = searchParams.get("level1ResourceId") || "";
  const { data: level1Resource } = useGetLevel1Resource(level1ResourceId, {
    enabled: Boolean(level1ResourceId),
    onSuccess: (item: any) => handleChangeDetails("level1wrs", item),
  });

  const steps = [
    {
      label: (
        <StepButton
          index={0}
          currentStep={currentStep}
          info={<StepInfo data={confirmData.level1Resource.body} />}
          isOnChain={workflowCompleted}
        >
          {t("approval.admin_transfer.create.step_1.select_level1wrs")}
        </StepButton>
      ),
      panel: <AdminTransferSelectLevel1WRS />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-2">
            <p>{t("approval.admin_transfer.create.step_1.info_intro")}</p>
            <p>{t("approval.admin_transfer.create.step_1.info_conclusion")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={1}
          currentStep={currentStep}
          info={<StepInfo data={confirmData.subscriber.body} />}
          isOnChain={workflowCompleted}
        >
          {t("approval.admin_transfer.create.step_2.select_subscriber")}
        </StepButton>
      ),
      panel: <AdminTransferSelectSubscriber />,
      infoPanel: (
        <InfoPanel
          actions={[
            {
              label: t("approval.admin_transfer.create.step_2.action_1", {
                level1wrsName: details.level1wrs.name,
              }),
              action: () => {
                window.open(`/polestar/subscribers/create`, "_blank");
              },
            },
          ]}
        >
          <div className="space-y-2">
            <p>{t("approval.admin_transfer.create.step_2.info_intro")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={2}
          currentStep={currentStep}
          info={<StepInfo data={confirmData.extractionRight.body} />}
          isOnChain={workflowCompleted}
        >
          {t("approval.admin_transfer.create.step_3.select_right")}
        </StepButton>
      ),
      panel: <AdminTransferSelectExtractionRight />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-2">
            <p>{t("approval.admin_transfer.create.step_3.info_intro")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={3}
          currentStep={currentStep}
          info={<StepInfo data={confirmData.extractionPoint.body} />}
          isOnChain={workflowCompleted}
        >
          {t("approval.admin_transfer.create.step_4.select_point")}
        </StepButton>
      ),
      panel: <AdminTransferSelectExtractionPoint />,
      infoPanel: (
        <InfoPanel
          successes={getInfoMessages("success", 3)}
          errors={getInfoMessages("error", 3)}
          warnings={getInfoMessages("warning", 3)}
          actions={
            getInfoMessages("error", 3).length > 0
              ? [
                  {
                    label: t(
                      "approval.admin_transfer.create.step_4.error_action_1"
                    ),
                    action: () => {
                      handleChangeDetails("extractionRight", { id: "" });
                      handleChangeDetails("extractionPoint", { id: "" });
                      stepHelpers.goToPrevStep();
                    },
                  },
                ]
              : []
          }
        >
          <div className="space-y-2">
            <p>{t("approval.admin_transfer.create.step_4.info_intro")}</p>
          </div>
        </InfoPanel>
      ),
    },

    {
      label: (
        <StepButton
          index={4}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
          info={
            evidences?.length ? (
              <ul className="text-sm text-gray-600 mt-1 space-y-1">
                {evidences?.map((file: any) => (
                  <li key={file.name}>
                    <strong>{t("common.file_uploaded.file_name")}:</strong>{" "}
                    {file.name}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-sm text-gray-600 mt-1">
                {t("evidence.no_new_supporting")}
              </p>
            )
          }
        >
          {t("approval.admin_transfer.create.step_5.upload_evidence")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          referenceTable="administrative_approvals"
          referenceTableId={details.id}
          onNext={() => {
            stepHelpers.goToNextStep();
          }}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={() => navigateForCancel()}
          customDropboxTitle={
            t("approval.admin_transfer.create.step_5.title") as string
          }
          newUploadDescription={
            t("approval.admin_transfer.create.step_5.description", {
              extractionRightName: details.extractionRight.name,
              extractionPointName: details.extractionPoint.name,
            }) as string
          }
        ></UploadEvidencesWorkflowForm>
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-2">
            <p>{t("approval.admin_transfer.create.step_5.info_intro")}</p>
            <p>{t("approval.admin_transfer.create.step_5.info_conclusion")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={5}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
          forceSuccess={workflowCompleted}
        >
          {t("approval.admin_transfer.create.step_6.ledger_menu_title")}
        </StepButton>
      ),
      panel: workflowCompleted ? (
        <AdminTransferWorkflowCompleted />
      ) : (
        <AdminTransferLedger />
      ),
      infoPanel: workflowCompleted ? (
        <InfoPanel
          successes={[
            t("approval.admin_transfer.create.workflow_complete.success_1", {
              customerName: details.subscriber?.name,
            }),
          ]}
          warnings={[
            `${t(
              "approval.admin_transfer.create.workflow_complete.warning_1"
            )}`,
          ]}
        >
          <div className="space-y-2">
            <p>
              {t("approval.admin_transfer.create.workflow_complete.info_intro")}
            </p>
          </div>
        </InfoPanel>
      ) : (
        <InfoPanel
          successes={[t("approval.admin_transfer.create.step_6.success_1")]}
        >
          <div className="space-y-2">
            <p>{t("approval.admin_transfer.create.step_6.info_intro")}</p>
          </div>
        </InfoPanel>
      ),
    },
  ];

  return (
    <Layout
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        ...(level1Resource
          ? [
              {
                label: level1Resource?.name,
                href: `/polestar/level1wrs/${level1ResourceId}`,
              },
            ]
          : []),
        {
          label: t("approval.title"),
          href: level1ResourceId
            ? `/polestar/level1wrs/${level1ResourceId}#6`
            : "/polestar/administrative_approvals#tab=4",
        },
        {
          label: t("approval.admin_transfer.create_title"),
        },
      ]}
      accountingPeriodLevel1ResourceId={details.level1wrs.id}
      title={t("approval.admin_transfer.create_title")}
    >
      <WorkflowPanels selectedStep={currentStep} steps={steps} />
    </Layout>
  );
};

export default CreateAdminTransfer;

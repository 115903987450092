import React from "react";
import classNames from "classnames";
import { Popover, Transition } from "@headlessui/react";
import { startCase } from "lodash";
import { CheckIcon, TableCellsIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

import type { Table } from "@tanstack/react-table";

type ColumnVisibilityProps = { table: Table<any> };

const ColumnVisibility: React.FunctionComponent<ColumnVisibilityProps> = ({
  table,
}) => {
  const { t } = useTranslation();

  return (
    <Popover className="relative text-sm">
      {({ open }) => (
        <>
          <Popover.Button className="flex-inline items-center btn-secondary rounded">
            <TableCellsIcon
              className={classNames(
                {
                  "text-opacity-70": open,
                },
                "mr-2 h-5 w-5 transition duration-150 ease-in-out group-hover:text-opacity-80"
              )}
              aria-hidden="true"
            />
            <span>{t("shared.table.columns")}</span>
          </Popover.Button>

          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-20 mt-3 transform px-4 sm:px-0">
              <div className="w-52 relative bg-white overflow-hidden rounded shadow ring-1 ring-black ring-opacity-5 p-2">
                <button
                  className="group flex w-full items-center rounded p-2 pl-8 pr-6 hover:bg-gray-50"
                  onClick={table.getToggleAllColumnsVisibilityHandler()}
                >
                  <span>
                    {table.getIsAllColumnsVisible()
                      ? t("shared.table.hide_all")
                      : t("shared.table.show_all")}
                  </span>
                </button>

                {table.getAllLeafColumns().map((column: any) => (
                  <button
                    key={column.id}
                    onClick={column.getToggleVisibilityHandler()}
                    className="group flex w-full items-center rounded p-2 pr-6 hover:bg-gray-50"
                  >
                    <CheckIcon
                      className={classNames("mr-2 h-4 w-4 shrink-0", {
                        "opacity-0": !column.getIsVisible(),
                      })}
                      aria-hidden="true"
                    />
                    <span>{startCase(column.id)}</span>
                  </button>
                ))}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default ColumnVisibility;

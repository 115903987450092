import { useMutation } from "@tanstack/react-query";

import { checkIdentifier } from "@services/level1Resource";

export function useValidateLevel1ResourceIdentifier(options: any = {}) {
  return useMutation<any, any, { identifier: string }>({
    mutationKey: ["check_level_1_resource_identifier"],
    mutationFn: checkIdentifier,
    ...options,
  });
}

import React from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import { useLevel0WRSContext } from "@context/Level0WRSContext";
import { generateIdentifier } from "@utils/generateIdentifier";
import { toastError } from "@utils/toast";
import { useValidateLevel0ResourceName } from "@hooks/mutation/useValidateLevel0ResourceName";
import { useValidateLevel0ResourceIdentifier } from "@hooks/mutation/useValidateLevel0ResourceIdentifier";
import { Level0WRSSource } from "@services/level0Resource";

const Level0WRSDetails = () => {
  const { t } = useTranslation();
  const {
    stepHelpers,
    details,
    handleChangeDetails,
    navigateForCancel,
    updatingLevel0WRS,
    modifyStatuses,
  } = useLevel0WRSContext();

  const { mutateAsync: checkLevel0ResourceNameMutation } =
    useValidateLevel0ResourceName({
      onError: () => {
        toastError(t("level0wrs.create.toast.check_name_failure"));
      },
    });
  const { mutateAsync: checkLevel0ResourceIdentifierMutation } =
    useValidateLevel0ResourceIdentifier({
      onError: () => {
        toastError(t("level0wrs.create.toast.check_identifier_failure"));
      },
    });

  const validateName = async (name: string, parentId: string) => {
    const trimName = name.trim();
    if (trimName === "") {
      return false;
    }

    if (trimName.toLowerCase() === updatingLevel0WRS?.name.toLowerCase()) {
      return true;
    }

    const exists = await checkLevel0ResourceNameMutation({
      name: trimName,
      parentId: parentId.trim(),
    });
    return !exists;
  };

  const validateIdentifier = async (
    identifier: string,
    level1ResourceId: string,
  ): Promise<boolean> => {
    const trimIdentifier = identifier.trim();
    if (trimIdentifier === "") {
      return false;
    }

    if (
      trimIdentifier.toLowerCase() ===
      updatingLevel0WRS?.identifier.toLowerCase()
    ) {
      return true;
    }

    const exists = await checkLevel0ResourceIdentifierMutation({
      identifier: trimIdentifier,
      parentId: level1ResourceId.trim(),
    });
    return !exists;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const level1ResourceId = details.level1WRS?.id;
    const validName = await validateName(details.name, level1ResourceId);
    modifyStatuses({
      stepNumber: 0,
      fieldName: "name",
      message: validName
        ? ""
        : (t("level0wrs.create.info_panel.step_1.error_duplicate_name", {
            name: details.name,
          }) as string),
      infoType: validName ? undefined : "error",
    });

    const validIdentifier = await validateIdentifier(
      details.identifier,
      level1ResourceId,
    );
    modifyStatuses({
      stepNumber: 0,
      fieldName: "identifier",
      message: validIdentifier
        ? ""
        : (t("level0wrs.create.info_panel.step_1.error_duplicate_identifier", {
            identifier: details.identifier,
          }) as string),
      infoType: validIdentifier ? undefined : "error",
    });

    if (validName && validIdentifier) {
      stepHelpers.goToNextStep();
    }
  };

  const sourceOptions = Object.values(Level0WRSSource).map((source) => ({
    label: t(`level0wrs.source_of_water.${source}`),
    value: source,
  }));

  return (
    <form className="flex flex-col gap-6 p-6 grow" onSubmit={handleSubmit}>
      <Heading light>{t("level0wrs.create.details")}</Heading>

      <fieldset className="space-y-4 max-w-md">
        <div>
          <Label htmlFor="name">{t("level0wrs.create.name")}</Label>
          <TextInput
            id="name"
            value={details.name}
            onChange={(e) => {
              handleChangeDetails("name", e.target.value);
            }}
            onBlur={(e) => {
              if (details.identifier !== "") return;

              handleChangeDetails(
                "identifier",
                generateIdentifier(e.target.value).toUpperCase(),
              );
            }}
            required
          />
        </div>
        <div>
          <Label htmlFor="identifier">{t("level0wrs.create.identifier")}</Label>
          <TextInput
            id="identifier"
            value={details.identifier}
            onChange={(e) => handleChangeDetails("identifier", e.target.value)}
            required
          />
        </div>
        <div>
          <Label htmlFor="source">{t("level0wrs.source")}</Label>
          <Select
            inputId="source"
            options={sourceOptions}
            value={sourceOptions.find((i) => i.value === details.source)}
            onChange={(e) => handleChangeDetails("source", e?.value ?? "")}
            openMenuOnFocus
          />
        </div>
      </fieldset>
      <div className="grow" />

      <footer className="flex gap-4 -mx-6 p-6 pb-0 border-t border-gray-200">
        <button
          type="submit"
          className="btn-primary"
          disabled={!details.name || !details.identifier || !details.source}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default Level0WRSDetails;

import { useTranslation } from "react-i18next";

import Layout from "@components/layout/Layout";
import CreateMeterWorkflow from "@components/form/meter/CreateMeterWorkflow";
import { useSearchParams } from "react-router-dom";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";
import Loading from "@components/shared/Loading";

const CreateMeterEOY: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const level1ResourceId = searchParams.get("level1ResourceId") || "";
  const { data: level1Resource, isLoading } = useGetLevel1Resource(
    level1ResourceId,
    {
      enabled: Boolean(level1ResourceId),
    }
  );

  if (Boolean(level1ResourceId) && isLoading) {
    return (
      <div className="py-20">
        <Loading />
      </div>
    );
  }

  return (
    <Layout
      permissions={["CreateMeters"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        ...(level1Resource
          ? [
              {
                label: level1Resource?.name,
                href: `/polestar/level1wrs/${level1ResourceId}`,
              },
            ]
          : []),
        {
          label: t("meter.adding_meter.title"),
        },
      ]}
      title={t("meter.adding_meter.title")}
    >
      <CreateMeterWorkflow />
    </Layout>
  );
};

export default CreateMeterEOY;

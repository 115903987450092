import { ReactNode, useEffect, useState } from "react";
import classNames from "classnames";

import TabNav from "./TabNav";
import TabMenu from "./TabMenu";
import ExpandInfoPanelButton from "@components/shared/ExpandInfoPanelButton";
import { useAppContext } from "@context/AppContext";
import { useHashValue } from "hooks/useHashValue";

type TabPanelsProps = {
  selectedTab?: number;
  tabs: Array<{
    title: ReactNode;
    panel: ReactNode;
    tabInfoPanel?: ReactNode;
  }>;
  className?: string;
  keepSelectionOnRefresh?: boolean;
};

const TabPanels: React.FunctionComponent<TabPanelsProps> = ({
  selectedTab,
  tabs,
  className,
  keepSelectionOnRefresh,
}) => {
  const { hash, replaceHash } = useHashValue();
  const [tabNo, setTabNo] = useState(selectedTab || 0);
  const [loadedTab, setLoadedTab] = useState<number[]>([tabNo]);
  const { isExpandInfoPanel } = useAppContext();
  const infoPanel = tabs[tabNo]?.tabInfoPanel;

  const handleSelectTab = (i: number) => {
    setTabNo(i);
    if (loadedTab.indexOf(i) === -1) setLoadedTab([...loadedTab, i]);
  };

  useEffect(() => {
    if (keepSelectionOnRefresh) {
      const loadTabNo = +hash.tab || 0;
      setTabNo(loadTabNo);
      setLoadedTab([...loadedTab, loadTabNo]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keepSelectionOnRefresh]);

  return (
    <div className="flex grow gap-4 h-full relative">
      <div
        className={classNames(
          "flex flex-col grow bg-white border",
          className,
          tabs[tabNo]?.tabInfoPanel ? "w-3/4" : "w-full"
        )}
      >
        <TabNav>
          {tabs.map((t: any, i) => {
            return (
              <TabMenu
                key={t.title}
                isActive={tabNo === i}
                onClick={() => {
                  handleSelectTab(i);
                  if (keepSelectionOnRefresh) replaceHash({ tab: i });
                }}
              >
                {t.title}
              </TabMenu>
            );
          })}
        </TabNav>
        <div className="flex grow p-6 relative">
          {tabs.map((t, i) => {
            if (loadedTab.indexOf(i) === -1) return null;

            return tabNo === i ? (
              <div className="flex flex-col grow gap-4 max-w-full" key={i}>
                {t.panel}
              </div>
            ) : null;
          })}
        </div>
        {infoPanel && !isExpandInfoPanel && <ExpandInfoPanelButton />}
      </div>

      {infoPanel && isExpandInfoPanel && (
        <div className="flex w-1/4">{infoPanel}</div>
      )}
    </div>
  );
};

export default TabPanels;

import { useMutation } from "@tanstack/react-query";
import { replaceMeter, ReplaceMeterPayload } from "@services/meter";

export const useReplaceMeter = (options: any = {}) => {
  return useMutation<any, any, ReplaceMeterPayload>({
    mutationKey: ["replace_meter"],
    mutationFn: replaceMeter,
    ...options,
  });
};

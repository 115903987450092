import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import EnterAdjustDeclarationDetails from "./adjust/EnterAdjustDeclarationDetails";
import ConfirmAdjustDeclaration from "./adjust/ConfirmAdjustDeclaration";
import { DeclarationProvider } from "@context/DeclarationContext";
import {
  EvidenceProvider,
  useEvidenceContext,
} from "@context/shared/EvidenceContext";
import { StepProvider, useStepContext } from "@context/shared/StepContext";
import StepButton from "@components/shared/StepButton";
import StepInfo from "@components/shared/StepInfo";
import WorkflowPanels from "@components/form/WorkflowPanels";
import InfoPanel from "@components/form/InfoPanel";
import UploadEvidencesWorkflowForm from "@components/form/UploadEvidenceWorkflowForm";
import {
  AdjustDeclarationProvider,
  useAdjustDeclarationContext,
} from "@context/AdjustDeclarationContext";
import { useAdjustMeterReading } from "@hooks/mutation/useAdjustMeterReading";
import { useAdjustUsage } from "@hooks/mutation/useAdjustUsage";
import { DBTables } from "@utils/constants/dbTables";
import { convertMLToLiter } from "@utils/convertUnits";

const AdjustDeclarationWorkflow = () => {
  return (
    <StepProvider maxStep={6}>
      <DeclarationProvider>
        <EvidenceProvider>
          <AdjustDeclarationProvider>
            <Consumer />
          </AdjustDeclarationProvider>
        </EvidenceProvider>
      </DeclarationProvider>
    </StepProvider>
  );
};

const Consumer: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentStep, stepHelpers } = useStepContext();
  const { getEvidencesInfo, uploadEvidences } = useEvidenceContext();
  const { info, details } = useAdjustDeclarationContext();
  const [workflowInstance, setWorkflowInstance] = React.useState<any>();
  const { mutateAsync: adjustMeterReading } = useAdjustMeterReading();
  const { mutateAsync: adjustUsage } = useAdjustUsage();

  const handleSubmit = async () => {
    let referenceId = "";

    if (details?.type === "reading") {
      const [res, workflowInstance] = await adjustMeterReading({
        subscriberId: details.subscriberId,
        level0ResourceId: details.level0ResourceId,
        declarationId: details.declarationId,
        reading: convertMLToLiter(Number(details.newReading)),
        notes: details.note,
      });
      referenceId = res.id;
      setWorkflowInstance(workflowInstance);
    } else if (details?.type === "usage") {
      const [res, workflowInstance] = await adjustUsage({
        subscriberId: details.subscriberId,
        meterId: details.meterId,
        usage: convertMLToLiter(Number(details.newUsage)),
        notes: details.note,
      });
      referenceId = res.id;
      setWorkflowInstance(workflowInstance);
    }

    if (referenceId) {
      await uploadEvidences({
        description: t("balance_adjustment.create.evidence_saved"),
        referenceId: referenceId,
        referenceTable: DBTables.BalanceAdjustments,
      });
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const isComplete = Boolean(workflowInstance);

  const steps = [
    {
      label: (
        <StepButton
          index={0}
          currentStep={currentStep}
          info={<StepInfo data={info.details.body} />}
          isOnChain={isComplete}
        >
          {t("balance_adjustment.meter_reading.reset")}
        </StepButton>
      ),
      panel: (
        <EnterAdjustDeclarationDetails
          onNext={stepHelpers.goToNextStep}
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("balance_adjustment.meter_reading.info.reset")}:</p>
            <ol className="list-decimal list-inside space-y-4">
              <li className="space-y-2">
                <h5 className="inline-block">
                  {t("balance_adjustment.meter_reading.info.meter_read.title")}:
                </h5>
                <ul className="list-disc list-inside space-y-2 pl-4">
                  <li>
                    {t(
                      "balance_adjustment.meter_reading.info.meter_read.step1"
                    )}
                  </li>
                  <li>
                    {t(
                      "balance_adjustment.meter_reading.info.meter_read.step2"
                    )}
                  </li>
                  <li>
                    {t(
                      "balance_adjustment.meter_reading.info.meter_read.step3"
                    )}
                  </li>
                </ul>
              </li>
              <li className="space-y-2">
                <h5 className="inline-block">
                  {t("balance_adjustment.meter_reading.info.usage.title")}:
                </h5>
                <ul className="list-disc list-inside space-y-2 pl-4">
                  <li>
                    {t("balance_adjustment.meter_reading.info.usage.step1")}
                  </li>
                  <li>
                    {t("balance_adjustment.meter_reading.info.usage.step2")}
                  </li>
                  <li>
                    {t("balance_adjustment.meter_reading.info.usage.step3")}
                  </li>
                </ul>
              </li>
            </ol>
            <p>{t("balance_adjustment.meter_reading.info.note")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={1}
          currentStep={currentStep}
          info={<StepInfo data={getEvidencesInfo().body} />}
          isOnChain={isComplete}
        >
          {t("common.enter_evidence")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          onNext={stepHelpers.goToNextStep}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("declaration.info.evidence")}</p>
            <p>{t("evidence.supported")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton index={2} currentStep={currentStep} isOnChain={isComplete}>
          {t("balance_adjustment.meter_reading.confirm.title")}
        </StepButton>
      ),
      panel: (
        <ConfirmAdjustDeclaration
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          confirmData={[info.details, getEvidencesInfo()]}
          workflowInstance={workflowInstance}
        />
      ),
      infoPanel: (
        <InfoPanel
          successes={
            isComplete
              ? [
                  t("balance_adjustment.meter_reading.info.success", {
                    serialNo: details?.serialNo,
                  }),
                ]
              : [t("balance_adjustment.meter_reading.info.confirm")]
          }
        >
          <div className="space-y-6">
            {isComplete ? (
              t("balance_adjustment.meter_reading.info.end")
            ) : (
              <ul className="space-y-4">
                <li>{t("common.info.verify")}</li>
                <li>
                  <strong>{t("declaration.reading_details")}</strong>{" "}
                  {t("balance_adjustment.meter_reading.info.details")}
                </li>
                <li>
                  <strong>{t("evidence.supporting")}:</strong>{" "}
                  {t("evidence.info.verify")}
                </li>
                <li>{t("common.info.changes")}</li>
              </ul>
            )}
          </div>
        </InfoPanel>
      ),
    },
  ];

  return <WorkflowPanels selectedStep={currentStep} steps={steps} />;
};

export default AdjustDeclarationWorkflow;

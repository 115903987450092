import Select from "react-select";
import { noop } from "lodash";

import ErrorMessage from "./ErrorMessage";
import { useAllLevel0Resources } from "@hooks/query/useAllLevel0Resources";

type SelectProps = React.ComponentProps<Select>;

type SelectLevel0ResourceProps = Exclude<SelectProps, "value" | "onChange"> & {
  value?: string;
  onChange?: (e: any) => void;
  level1ResourceId?: string;
  errorMessage?: string;
  excludeIds?: string[];
};

const SelectLevel0Resource: React.FunctionComponent<
  SelectLevel0ResourceProps
> = ({
  value,
  onChange = noop,
  level1ResourceId,
  errorMessage,
  excludeIds = [],
  ...restProps
}) => {
  const { isLoading, data: options = [] } = useAllLevel0Resources({
    params: {
      parentId: level1ResourceId,
    },
    select: (data: any) => {
      return data
        .filter((i: any) => {
          return !excludeIds.includes(i.id);
        })
        .map((i: any) => {
          const hasClasses = i.waterClasses.length > 0;
          return {
            label: i.identifier,
            value: i.id,
            yield: i.yield,
            source: i.source,
            hasClasses,
          };
        });
    },
    refetchOnWindowFocus: true,
  });

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Select
        options={options}
        value={
          options.find((i: any) => {
            return i.value === value || i.label === value;
          }) || null
        }
        onChange={onChange}
        openMenuOnFocus
        {...restProps}
      />

      {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
    </>
  );
};

export default SelectLevel0Resource;

import { useQuery } from "@tanstack/react-query";
import { getAllRightFractions } from "@services/rightFraction";

export function useAllRightFractions({
  params,
  options,
}: {
  params?: Record<string, any>;
  options?: Record<string, any>;
} = {}) {
  return useQuery<any>({
    queryKey: ["right_fractions", { params }],
    queryFn: () => getAllRightFractions(params),
    ...options,
  });
}

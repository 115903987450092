import Heading from "@components/layout/Heading";
import EventTransactionPaginatedTable from "@components/table/EventTransactionPaginatedTable";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import EndButtonModal from "@components/shared/EndButton";

type DeleteExtractionRightCompletedProps = {
  workflowInstance: any;
};

const DeleteExtractionRightCompleted: FunctionComponent<
  DeleteExtractionRightCompletedProps
> = ({ workflowInstance }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col grow p-6">
      <div className="flex flex-col grow space-y-4">
        <Heading light>
          {t("extraction_right.create.workflow_complete.title")}
        </Heading>
        <EventTransactionPaginatedTable
          references={[workflowInstance?.id]}
          stickyHeader={true}
        />
      </div>
      <footer className="flex gap-4 -mx-2 mt-6 p-6 pb-0 border-t border-gray-200">
        <EndButtonModal />
      </footer>
    </div>
  );
};

export default DeleteExtractionRightCompleted;

import { useTranslation } from "react-i18next";
import React from "react";
import { formatVolume } from "../../../../utils/formatVolume";
import EditIcon from "../../../icons/EditIcon";
import Heading from "../../../layout/Heading";
import TextInput from "../../TextInput";

type UpdateThresholdStep2Props = {
  meter: any;
  onSave: () => void;
  changeMeter: () => void;
  isSaveDisabled: boolean;
  changedThreshold: number | undefined;
  setChangedThreshold: (value: number) => void;
};

const UpdateThresholdStep2: React.FunctionComponent<
  UpdateThresholdStep2Props
> = ({
  onSave,
  changeMeter,
  meter,
  isSaveDisabled,
  changedThreshold,
  setChangedThreshold,
}) => {
  const { t } = useTranslation();

  const handleSave = (e: any) => {
    e.preventDefault();
    onSave();
  };

  const handleChange = (e: any) => {
    const isNumber = !isNaN(parseInt(e.target.value));
    if (isNumber) setChangedThreshold(+e.target.value);
  };

  return (
    <form className="space-y-8" onSubmit={handleSave}>
      <div className="p-6">
        <div>
          <Heading className="text-xl leading-6" light>
            {t("meter.current_meter_threshold")}
          </Heading>
        </div>
        <div className="mt-6 flex flex-col gap-4">
          <div className="grid grid-cols-1 gap-x-4 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <div className="mt-1 text-sm text-gray-500">
                <span>{t("declaration.form.current_threshold_id")}: </span>
                {meter?.serialNo}
              </div>
              <div className="mt-1 text-xl text-gray-500">
                {formatVolume(meter?.threshold, "")}
              </div>
            </div>
            <button
              type="button"
              className="pt-4 sm:col-span-2 text-left flex gap-1 items-center font-bold text-sm text-primary-3"
              onClick={changeMeter}
            >
              <EditIcon /> {t("meter.change_meter")}
            </button>
          </div>
          <div className="grid grid-cols-1 gap-x-4 sm:grid-cols-2">
            <div className="w-96">
              <label htmlFor="threshold" className="block text-gray-600">
                {t("meter.form.new_meter_threshold")}
              </label>
              <div className="mt-1 flex rounded-md">
                <TextInput
                  type="number"
                  name="threshold"
                  placeholder={t("meter.form.input_placeholder") as string}
                  min={0}
                  pattern="[0-9]*"
                  inputMode="numeric"
                  required
                  value={changedThreshold}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border-t w-full border-gray-200 mt-5 p-6 flex gap-3">
        <button
          type="submit"
          className="btn-primary text-sm font-semibold bg-primary-1"
          disabled={isSaveDisabled || typeof changedThreshold === "undefined"}
          onClick={onSave}
        >
          {t("common.continue")}
        </button>
        <button
          type="button"
          className="btn-default text-sm font-semibold text-primary-1 border-primary-1"
          onClick={changeMeter}
        >
          {t("common.cancel")}
        </button>
      </div>
    </form>
  );
};

export default UpdateThresholdStep2;

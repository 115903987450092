import { useMutation } from "@tanstack/react-query";
import {
  AmalgamationOrSubdivisionPayload,
  requestAmalgamationOrSubdivision,
} from "@services/administrativeApprovals";

export const useRequestAmalgamationOrSubdivision = (options: any = {}) => {
  return useMutation<any, any, AmalgamationOrSubdivisionPayload>({
    mutationKey: ["request_amalgamation"],
    mutationFn: requestAmalgamationOrSubdivision,
    ...options,
  });
};

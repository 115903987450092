import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Layout from "@components/layout/Layout";
import ReplaceMeterWorkflow from "@components/form/meter/replace/ReplaceMeterWorkflow";
import { useSubscriber } from "@hooks/query/useSubscriber";
import { useLevel0Resource } from "@hooks/query/useLevel0Resource";

const SubscriberReplaceMeter = () => {
  const { t } = useTranslation();
  const { id: subscriberId = "", level0ResourceId = "" } = useParams();
  const { data: subscriber = {} } = useSubscriber(subscriberId);
  const { data: level0Resource = {} } = useLevel0Resource(level0ResourceId);

  return (
    <Layout
      permissions={[
        "UpdateExtractionPoint",
        "UpdateMeters",
        "CreateDeclarations",
      ]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("common.subscribers"),
          href: "/polestar/subscribers",
        },
        {
          label: subscriber.name,
          href: `/polestar/subscribers/${subscriberId}`,
        },
        {
          label: level0Resource.identifier,
          href: `/polestar/subscribers/${subscriberId}?level0ResourceId=${level0Resource.id}`,
        },
        {
          label: `${t("common.extraction_points")} ${t("common.and")} ${t(
            "common.meters"
          )}`,
          href: `/polestar/subscribers/${subscriber?.id}?level0ResourceId=${level0Resource?.id}&extractionPointId=random`,
        },
        {
          label: t("meter.replacement.title"),
        },
      ]}
      title={t("meter.replacement.title")}
      accountingPeriodLevel1ResourceId={subscriber.level1ResourceId}
    >
      <ReplaceMeterWorkflow />
    </Layout>
  );
};

export default SubscriberReplaceMeter;

import React, { useState } from "react";
import { capitalize, groupBy, orderBy } from "lodash";
import { useTranslation } from "react-i18next";

import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import Table from "@components/layout/Table";
import { formatVolume } from "@utils/formatVolume";
import { formatDate } from "@utils/formatDate";
import { useAllExtractionPoints } from "@hooks/query/useAllExtractionPoints";
import { useAllDeclarations } from "@hooks/query/useAllDeclarations";

type Declaration = Record<string, any>;

type SelectExtractionPointWithLastDeclarationTableProps = {
  level0ResourceId?: string;
  definedByWalletId?: string;
  onSelect: (items: Declaration[]) => void;
  selected?: Declaration[];
  multipleSelection?: boolean;
};

const SelectExtractionPointWithLastDeclarationTable: React.FunctionComponent<
  SelectExtractionPointWithLastDeclarationTableProps
> = ({
  level0ResourceId,
  definedByWalletId,
  onSelect,
  selected,
  multipleSelection = false,
}) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState("");
  const { data: extractionPoints = [], isLoading } = useAllExtractionPoints({
    params: {
      level0ResourceId,
      definedByWalletId,
      isActive: true,
    },
  });

  const { data: declarations = [], isLoading: isDeclarationsLoading } =
    useAllDeclarations({
      params: {
        extractionPointIds: extractionPoints.map((i: any) => i.id),
      },
      enabled: extractionPoints.length > 0,
    });

  const groupByPoint = groupBy(declarations, "extractionPointId");
  const lastDeclaration: Record<string, any> = {};
  for (let [pointId, declarations] of Object.entries(groupByPoint)) {
    lastDeclaration[pointId] = orderBy(
      declarations,
      ["createdAt"],
      ["desc"],
    )?.[0];
  }

  const tableFields = [
    {
      title: t("extraction_point.name"),
      name: "extractionPointName",
    },
    {
      title: t("meter.serial_no"),
      name: "serialNo",
    },
    {
      title: t("common.level0wrs"),
      name: "level0Resource",
    },
    {
      title: t("extraction_point.source"),
      name: "source",
    },
    {
      title: t("declaration.last_read"),
      name: "reading",
    },
    {
      title: t("declaration.volume"),
      name: "volume",
    },
    {
      title: t("declaration.read_at"),
      name: "readAt",
    },
  ];

  const tableData = extractionPoints
    ?.filter((ep: any) => {
      const matcher = filter.toLowerCase();

      return ep?.meter?.serialNo?.toLowerCase()?.includes(matcher);
    })
    .map((ep: any) => {
      const declaration = lastDeclaration[ep.id];
      return {
        id: ep.meter?.id,
        extractionPointName: ep.name,
        serialNo: ep.meter?.serialNo,
        level0Resource: ep?.level0WRS?.identifier,
        source: capitalize(ep?.source),
        reading: formatVolume(declaration?.reading ?? 0, ""),
        volume: formatVolume(declaration?.volume ?? 0, ""),
        readAt: declaration?.readAt
          ? formatDate(new Date(declaration.readAt))
          : "-",
      };
    });

  return (
    <>
      <div className="pb-0 flex flex-col gap-4 grow">
        <fieldset className="max-w-xs">
          <Label>{t("meter.filter_serial_no")}</Label>
          <SearchInput onChange={e => setFilter(e.target.value)} />
        </fieldset>
        <Table
          fields={tableFields}
          data={tableData}
          stickyHeader
          loading={isLoading || isDeclarationsLoading}
          noRowsText={t("common.no_data")}
          selectionKey={"id"}
          multiple={!!multipleSelection}
          selectedKeys={selected?.map(i => i?.meter?.id)}
          onSelectionChange={meterIds => {
            onSelect(
              multipleSelection
                ? extractionPoints
                    .filter((ep: any) => meterIds.includes(ep.meter?.id))
                    .map((ep: any) => ({
                      ...lastDeclaration[ep.id],
                      meter: ep.meter,
                      isInitial: lastDeclaration[ep.id]?.volume === 0,
                    }))
                : extractionPoints
                    .filter(
                      (ep: any) =>
                        meterIds[meterIds.length - 1] === ep.meter?.id,
                    )
                    .map((ep: any) => ({
                      ...lastDeclaration[ep.id],
                      meter: ep.meter,
                      isInitial: lastDeclaration[ep.id]?.volume === 0,
                    })),
            );
          }}
        />
      </div>
    </>
  );
};

export default SelectExtractionPointWithLastDeclarationTable;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isFunction } from "lodash";

import { createAdministrativeTransfer } from "@services/zenith/administrativeApprovals";

export function useCreateAdministrativeTransfer(options: any = {}) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;

  return useMutation<any, any, any>({
    mutationKey: ["create_admin_transfer"],
    mutationFn: createAdministrativeTransfer,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["administrativeApprovals"] });

      if (isFunction(onSuccess)) {
        onSuccess();
      }
    },
    ...restOptions,
  });
}

import React from "react";
import { t } from "i18next";

import AccountingPeriodChecklistFooter from "./AccountingPeriodChecklistFooter";
import { useAppContext } from "@context/AppContext";
import SelectSingleLevel1WRSTable from "@components/table/SelectSingleLevel1WRSTable";
import Heading1 from "@components/layout/Heading";
import { useAccountingPeriodChecksContext } from "@context/AccountingPeriodChecksContext";

const SelectLevel1WRSChecklist: React.FunctionComponent = () => {
  const { loggedInInfo } = useAppContext();
  const { selectedLevel1ResourceId, setSelectedLevel1ResourceId } =
    useAccountingPeriodChecksContext();

  return (
    <>
      <div className="flex flex-col p-4 grow">
        <Heading1 light>
          {t("approval.admin_transfer.create.step_1.select_level1wrs")}
        </Heading1>
        <form className="flex flex-col grow gap-4">
          <SelectSingleLevel1WRSTable
            onSelect={(item: any) => {
              setSelectedLevel1ResourceId(item.id);
            }}
            selected={{ id: selectedLevel1ResourceId }}
            disableSelection={Boolean(selectedLevel1ResourceId)}
          />
        </form>
      </div>

      <AccountingPeriodChecklistFooter
        stepData={{
          level1ResourceId: selectedLevel1ResourceId,
          level1wrsCheckedAt: new Date(),
          level1wrsCheckedByUserId: loggedInInfo?.userDetails?.id,
        }}
      />
    </>
  );
};

export default SelectLevel1WRSChecklist;

import DeleteExtractionRightWorkflow from "@components/form/extraction_right/DeleteExtractionRightWorkflow";
import Layout from "@components/layout/Layout";
import { useExtractionRight } from "@hooks/query/useExtractionRight";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";
import { useLevel0Resource } from "@hooks/query/useLevel0Resource";
import { useSubscriber } from "@hooks/query/useSubscriber";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";

const DeleteExtractionRight: FunctionComponent = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { id = "", extractionRightId = "" } = useParams();
  const subscriberId = searchParams.get("subscriberId") || "";
  const level0ResourceId = searchParams.get("level0ResourceId") || "";
  const { data: level1Resource } = useGetLevel1Resource(id, {
    enabled: Boolean(id),
  });
  const { data: subscriber } = useSubscriber(subscriberId, {
    enabled: Boolean(subscriberId),
  });

  const { data: level0Resource } = useLevel0Resource(level0ResourceId, {
    enabled: Boolean(level0ResourceId),
  });

  const { data: extractionRight } = useExtractionRight(extractionRightId, {
    enabled: Boolean(extractionRightId),
  });

  return (
    <Layout
      permissions={["DeleteExtractionRight"]}
      breadcrumb={[
        { label: t("dashboard.dashboard"), href: "/polestar" },
        ...(subscriber
          ? [
              {
                label: t("common.subscribers"),
                href: "/polestar/subscribers",
              },
              {
                label: subscriber?.name,
                href: `/polestar/subscribers/${subscriber?.id}`,
              },
              {
                label: level0Resource?.identifier,
                href: `/polestar/subscribers/${subscriber?.id}?level0ResourceId=${level0Resource?.id}`,
              },
              {
                label: t("common.extraction_rights"),
                href: `/polestar/subscribers/${subscriber?.id}?level0ResourceId=${level0Resource?.id}&extractionRightId=random`,
              },
              {
                label: extractionRight?.name,
                href: `/polestar/subscribers/${subscriber?.id}?level0ResourceId=${extractionRight?.level0ResourceId}&extractionRightId=${extractionRight?.id}`,
              },
            ]
          : [
              {
                label: level1Resource?.name,
                href: `/polestar/level1wrs/${level1Resource?.id}`,
              },
              {
                label: t("common.extraction_rights"),
                href: `/polestar/level1wrs/${level1Resource?.id}#4`,
              },
              {
                label: extractionRight?.name,
                href: `/polestar/level1wrs/${level1Resource?.id}?extractionRightName=${extractionRight?.name}#3`,
              },
            ]),
        {
          label: t("extraction_right.delete.title"),
        },
      ]}
      title={t("extraction_right.delete.title")}
      accountingPeriodLevel1ResourceId={level1Resource?.id}
    >
      <DeleteExtractionRightWorkflow />
    </Layout>
  );
};

export default DeleteExtractionRight;

import React from "react";
import Select from "react-select/creatable";
import { noop } from "lodash";

import ErrorMessage from "@components/form/ErrorMessage";
import { useGetWaterCharges } from "@hooks/query/useGetWaterCharges";

type SelectOrCreateItemNoProps = {
  billingGroupId: string;
  id?: string;
  value?: string;
  part: "fixedItemNo" | "varItemNo";
  className?: string;
  onChange?: (value: any) => void;
  onBlur?: any;
  errorMessage?: string;
  isClearable?: boolean;
};

const SelectOrCreateItemNo: React.FunctionComponent<
  SelectOrCreateItemNoProps
> = ({
  billingGroupId,
  id,
  value,
  part,
  className,
  onChange = noop,
  onBlur = noop,
  errorMessage,
  isClearable = false,
}) => {
  const { data: options = [], isLoading } = useGetWaterCharges({
    params: { billingGroupId },
    options: {
      select: (data: any) => {
        if (!billingGroupId) return [];

        return data
          .map((wc: any) => ({
            label: part === "fixedItemNo" ? wc.fixedItemNo : wc.varItemNo,
            value: part === "fixedItemNo" ? wc.fixedItemNo : wc.varItemNo,
          }))
          .filter(
            (item: any, i: number, self: any[]) =>
              self.findIndex((n) => n.value === item.value) === i
          )
          .sort((a: any, b: any) => a.value.localeCompare(b.value));
      },
    },
  });

  if (isLoading) {
    return null;
  }

  return (
    <div className="flex flex-col w-full">
      <Select
        id={id}
        options={options}
        value={
          options.find((i: any) => i.value === value) || { value, label: value }
        }
        className={className}
        onChange={onChange}
        onBlur={onBlur}
        isClearable={isClearable}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
};

export default SelectOrCreateItemNo;

import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { Transition } from "@headlessui/react";

import ChevronDownIcon from "@components/icons/ChevronDownIcon";
import { useAppContext } from "@context/AppContext";
import { useOutsideClick } from "@hooks/useOutsideClick";

type MenuItemType = {
  label: string;
  href?: string;
  permissions?: string[];
  disabled?: boolean;
};

type MenuItemProps = {
  isOpen?: boolean;
  item: MenuItemType & { items?: MenuItemType[] };
  onClick: () => void;
  onClose: () => void;
};

const MenuItem: React.FunctionComponent<MenuItemProps> = ({
  isOpen,
  item,
  onClick,
  onClose,
}) => {
  const { checkPermissions } = useAppContext();
  const ref = useOutsideClick<HTMLDivElement>(onClose);

  const subMenus = item.items?.length
    ? item.items.filter((i) => checkPermissions(i.permissions ?? []))
    : [];

  if (
    !checkPermissions(item.permissions || []) ||
    (item.items?.length && subMenus.length === 0)
  ) {
    return null;
  }

  return (
    <div className="text-sm text-gray-500">
      {item.href ? (
        <NavLink
          to={item.href}
          onClick={onClick}
          className="inline-flex p-2 mr-5 hover:text-gray-900"
        >
          {item.label}
        </NavLink>
      ) : (
        <button
          type="button"
          className={classNames(
            "group inline-flex items-center gap-2 rounded-md p-2 focus:outline-none hover:text-gray-900",
            {
              "bg-gray-200 text-gray-600": isOpen,
            }
          )}
          aria-expanded="false"
          onClick={onClick}
        >
          <span>{item.label}</span>
          <ChevronDownIcon
            className={classNames(
              "h-5 w-5 group-hover:text-gray-500",
              isOpen ? "text-gray-600" : "text-gray-400"
            )}
          />
        </button>
      )}

      {subMenus.length > 0 && (
        <Transition
          show={isOpen}
          as="div"
          className="absolute z-10 mt-3 w-screen max-w-xs px-2"
          enter="transition ease-out duration-75"
          enterFrom="transform opacity-0 translate-y-1"
          enterTo="transform opacity-100 translate-y-0"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 translate-y-0"
          leaveTo="transform opacity-0 translate-y-1"
        >
          <div
            ref={ref}
            className="overflow-hidden rounded-sm shadow-md ring-1 ring-black ring-opacity-5"
          >
            <div className="relative bg-white">
              {subMenus.map((subItem) => {
                if (subItem.disabled) {
                  return (
                    <button
                      key={subItem.label}
                      type="button"
                      className="block px-4 py-3 disabled:opacity-50"
                      disabled
                    >
                      {subItem.label}
                    </button>
                  );
                }

                return (
                  <NavLink
                    key={subItem.label}
                    to={subItem.href ?? ""}
                    className="block px-4 py-3 transition duration-75 ease-in-out hover:bg-gray-100 hover:text-gray-900"
                    onClick={onClose}
                  >
                    {subItem.label}
                  </NavLink>
                );
              })}
            </div>
          </div>
        </Transition>
      )}
    </div>
  );
};

export default MenuItem;

import { useTranslation } from "react-i18next";

import ModalInformationIcon from "@components/icons/ModalInformationIcon";
import Heading from "@components/layout/Heading";
import Modal from "@components/layout/Modal";
import { formatDateTime } from "@utils/formatDateTime";
import { formatVolume } from "@utils/formatVolume";

type Props = {
  data: any;
  open: boolean;
  onCancel: () => void;
};

const DeclarationExistWarningModal: React.FunctionComponent<Props> = ({
  data,
  open,
  onCancel,
}: any) => {
  const { t } = useTranslation();

  const details = [
    {
      title: t("common.extraction_point"),
      value: data?.extractionPoint.name,
    },
    {
      title: t("meter.serial_no"),
      value: data?.meter.serialNo,
    },
    {
      title: t("declaration.reading"),
      value: formatVolume(data?.reading),
    },
    {
      title: t("declaration.read_at"),
      value: formatDateTime(new Date(data?.readAt)),
    },
    {
      title: t("declaration.declared_by"),
      value: data?.createdByUser?.name ?? "-",
    },
  ];

  return (
    <Modal open={open}>
      <div className="py-8 px-4 flex gap-4">
        <ModalInformationIcon className="shrink-0 h-12 w-12" />

        <div className="text-gray-500">
          <Heading className="text-inherit mb-4">
            {t("declaration.modal.already_exist")}
          </Heading>

          <dl className="space-y-1 text-sm">
            {details.map(i => {
              return (
                <div key={i.title} className="flex gap-2 leading-6">
                  <dt className="font-semibold">{i.title}: </dt>
                  <dl>{i.value}</dl>
                </div>
              );
            })}
          </dl>
        </div>
      </div>

      <div className="border-t p-4 flex justify-end gap-4">
        <button
          type="button"
          className="btn-primary text-sm rounded"
          onClick={onCancel}
        >
          {t("common.close")}
        </button>
      </div>
    </Modal>
  );
};

export default DeclarationExistWarningModal;

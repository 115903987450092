import { useMutation } from "@tanstack/react-query";

import {
  createLevel1Resource,
  CreateLevel1ResourcePayload,
} from "@services/level1Resource";

export function useCreateLevel1WRS(options: any = {}) {
  return useMutation<any, any, CreateLevel1ResourcePayload>({
    mutationKey: ["create_level_1_resource"],
    mutationFn: createLevel1Resource,
    ...options,
  });
}

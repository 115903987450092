import React from "react";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import { useCreateLevel1WRSContext } from "@context/CreateLevel1WRSContext";
import Legend from "@components/form/Legend";
import BoundaryInputs from "@components/shared/BoundaryInputs";

const Level1WRSBoundary = () => {
  const { t } = useTranslation();
  const {
    stepHelpers,
    locationCoordinates,
    setLocationCoordinates,
    saveGeographyDetails,
    locationFiles,
    setLocationFiles,
    handleCancel,
  } = useCreateLevel1WRSContext();

  return (
    <form className="flex flex-col gap-6 grow justify-between">
      <div className="space-y-4 p-6">
        <Heading light>{t("level1wrs.create.boundary")}</Heading>

        <fieldset className="space-y-4">
          <Legend>{t("level1wrs.create.enter_coordinates")}</Legend>

          <BoundaryInputs
            locationCoordinates={locationCoordinates}
            setLocationCoordinates={setLocationCoordinates}
            files={locationFiles}
            setFiles={setLocationFiles}
          />
        </fieldset>
      </div>
      <footer className="flex gap-4 mt-6 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>

        <button
          type="button"
          className="btn-primary"
          onClick={() => {
            saveGeographyDetails();
            stepHelpers.goToNextStep();
          }}
        >
          {t("common.next_step")}
        </button>

        <button
          type="button"
          className="btn-outline-primary"
          onClick={handleCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default Level1WRSBoundary;

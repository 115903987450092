import { useMutation, useQueryClient } from "@tanstack/react-query";

import { createExtractionRight } from "@services/extractionRight";
import { isFunction } from "lodash";

export const useCreateExtractionRight = (options: Record<string, any> = {}) => {
  const queryClient = useQueryClient();

  return useMutation(createExtractionRight, {
    ...options,
    onSuccess: (res: any) => {
      queryClient.invalidateQueries({
        queryKey: ["extractionRights"],
      });
      if (isFunction(options.onSuccess)) {
        options.onSuccess(res);
      }
    },
  });
};

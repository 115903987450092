import React from "react";
import { sum } from "lodash";
import { useTranslation } from "react-i18next";

import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import Heading from "@components/layout/Heading";
import Table from "@components/layout/Table";
import Tag from "@components/shared/Tag";
import { formatVolume } from "@utils/formatVolume";
import { formatDate } from "@utils/formatDate";
import { convertLiterToML } from "@utils/convertUnits";

import { getStatusAndText } from "@utils/administrativeApproval";

type SelectExtractionRightsProps = {
  extractionRights: any[];
  selectedExtractionRights: any;
  setSelectedExtractionRights: any;
  disabledRightIds?: any;
};

const SelectExtractionRights: React.FunctionComponent<
  SelectExtractionRightsProps
> = ({
  selectedExtractionRights,
  setSelectedExtractionRights,
  extractionRights,
}) => {
  const { t } = useTranslation();
  const [filter, setFilter] = React.useState("");

  const disabledExtractionRightIds = extractionRights
    ?.filter((right: any) => right.forwardDraw)
    ?.map((right: any) => right?.id);

  const tableFields = [
    {
      title: "",
      name: "status",
    },
    {
      title: t("extraction_right.name"),
      name: "name",
    },
    {
      title: t("extraction_right.allocation_type"),
      name: "allocationType",
    },
    {
      title: t("common.water_class"),
      name: "priority",
    },
    {
      title: t("common.level0wrs"),
      name: "zone",
    },
    {
      title: `${t("extraction_right.volume")} (${t("common.volume_unit")})`,
      name: "nominalVolume",
    },
    {
      title: t("extraction_right.start_at"),
      name: "startAt",
    },
    {
      title: t("extraction_right.end_at"),
      name: "endAt",
    },
  ];

  const tableData = extractionRights
    ?.filter((row: any) => row.name?.toString().includes(filter))
    .filter((row: any) => row?.level0Resource?.source === "ground")
    .map((right: any) => {
      const rightFractionsBalances = right?.rightFractions.map(
        (i: any) => +i.balance,
      );
      const h2wVolume = sum(rightFractionsBalances);

      const { status, text } = getStatusAndText(
        right?.forwardDrawRequest?.status as number,
      );

      return {
        id: right.id,
        status: right?.forwardDraw ? (
          <Tag status={status ?? "default"}>{text}</Tag>
        ) : null,
        purpose: right?.purpose,
        allocationType: "WA",
        name: right?.name,
        source: right?.level0Resource?.source,
        nominalVolume: formatVolume(right?.volume, ""),
        volume: right?.volume,
        priority: right?.waterClass?.name,
        zone: right?.level0Resource?.identifier,
        startAt: right?.startAt ? formatDate(right.startAt) : "-",
        endAt: right?.endAt ? formatDate(right.endAt) : "-",
        billingGroup: right?.billingGroup?.name,
        freq: right?.subscriber?.billingFrequency,
        cumulativeAllocated: right?.cumulativeAllocated ?? 0,
        transferVolume: convertLiterToML(right.volume),
        transferPercentage: 100,
        h2wVolume,
        transferH2WVolume: convertLiterToML(h2wVolume),
        isIncludeH2W: false,
        isTransferExtractionPoint: false,
      };
    });

  const handleSelect = (values: string[]) => {
    const selected = values?.map(selectedId => {
      return extractionRights?.find(({ id }: any) => id === selectedId);
    });
    setSelectedExtractionRights(selected);
  };

  return (
    <div className="p-6 flex flex-col gap-4 grow">
      <Heading light>
        {t("approval.forward_draws.approve.select_entitlements")}
      </Heading>

      <form className="max-w-xs">
        <Label htmlFor="extractionRight">
          {t("extraction_right.filter_extraction_right_number")}
        </Label>
        <SearchInput
          id="extractionRight"
          onChange={e => {
            setFilter(e.target.value);
          }}
        />
      </form>

      <Table
        fields={tableFields}
        data={tableData}
        stickyHeader
        selectionKey="id"
        selectedKeys={selectedExtractionRights?.map((i: any) => i?.id)}
        onSelectionChange={handleSelect}
        disabledKeys={disabledExtractionRightIds}
      />
    </div>
  );
};

export default SelectExtractionRights;

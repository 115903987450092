import React from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { sumBy } from "lodash";

import { useMeter } from "@hooks/query/useMeter";
import { convertLiterToML } from "@utils/convertUnits";
import { useAllDeclarations } from "@hooks/query/useAllDeclarations";
import { useAllAccountingPeriods } from "@hooks/query/useAllAccountingPeriods";
import { type ConfirmData } from "@components/shared/ConfirmationDetail";

type Details = {
  type: "reading" | "usage";
  subscriberId: string;
  level1ResourceId: string;
  level0ResourceId: string;
  declarationId: string;
  tag: string;
  currentReading: string;
  newReading: string;
  currentUsage: string;
  newUsage: string;
  readAt: Date;
  serialNo: string;
  meterId: string;
  extractionPointId: string;
  note: string;
};

type ContextValue = {
  details: Details;
  setDetails: React.Dispatch<React.SetStateAction<Details>>;
  info: Record<string, ConfirmData>;
};

const AdjustDeclarationContext = React.createContext<ContextValue | undefined>(
  undefined,
);

const AdjustDeclarationProvider: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [details, setDetails] = React.useState<Details>({
    type: "reading",
    subscriberId: "",
    level1ResourceId: "",
    level0ResourceId: "",
    declarationId: "",
    tag: "",
    currentUsage: "",
    newUsage: "",
    currentReading: "",
    newReading: "",
    readAt: new Date(),
    serialNo: "",
    meterId: "",
    extractionPointId: "",
    note: "",
  });

  const meterId = searchParams.get("meterId") ?? "";
  useMeter(meterId, {
    onSuccess: (res: any) => {
      if (Boolean(details.meterId)) {
        return;
      }

      const currentReading = res.lastDeclaration?.reading
        ? convertLiterToML(res.lastDeclaration.reading)
        : "0";
      const volume = res.lastDeclaration?.volume
        ? convertLiterToML(res.lastDeclaration.volume)
        : "0";
      setDetails(prev => ({
        ...prev,
        subscriberId: res.subscriberId,
        level1ResourceId: res.level1Resource?.id,
        level0ResourceId: res.extractionPoint?.level0ResourceId,
        declarationId: res.lastDeclaration?.id,
        tag: res.lastDeclaration?.tag,
        volume,
        meterId: res.id,
        serialNo: res.serialNo,
        newReading: currentReading,
        currentReading,
        readAt: new Date(res.lastDeclaration.readAt),
        extractionPointId: res.extractionPointId,
      }));
    },
  });

  const { data: accountingPeriod } = useAllAccountingPeriods({
    params: {
      level1ResourceId: details.level1ResourceId,
      isActive: true,
    },
    options: {
      enabled: Boolean(details.level1ResourceId),
      select: (data: any) => {
        if (data?.length) {
          return {
            periodStart: new Date(data[0].periodStart),
            periodEnd: new Date(data[0].periodEnd),
          };
        }
      },
    },
  });

  useAllDeclarations({
    params: {
      periodStart: accountingPeriod?.periodStart,
      periodEnd: accountingPeriod?.periodEnd,
      extractionPointId: details.extractionPointId,
      subscriberId: details.subscriberId,
    },
    enabled:
      Boolean(accountingPeriod) &&
      Boolean(details.extractionPointId) &&
      Boolean(details.subscriberId),
    onSuccess: (res: any) => {
      if (details.newUsage !== "") {
        return;
      }
      const currentUsage = convertLiterToML(
        sumBy(res, (i: any) => Number(i.volume))
      );
      setDetails((prev) => ({
        ...prev,
        newUsage: currentUsage,
        currentUsage,
      }));
    },
  });

  const info = {
    details: {
      title: t("declaration.reading_details"),
      body: [
        {
          key: t("meter.serial_no"),
          value: details.serialNo,
        },
        ...(details.type === "reading"
          ? [
              {
                key: t("balance_adjustment.meter_reading.new_reading"),
                value: details.newReading,
              },
            ]
          : []),
        ...(details.type === "usage"
          ? [
              {
                key: t("balance_adjustment.meter_reading.new_usage"),
                value: `${details.newUsage} ${t("common.volume_unit")}`,
              },
            ]
          : []),
        {
          key: t("common.note"),
          value: details.note || "-",
        },
      ],
    },
  };

  const value: ContextValue = {
    details,
    setDetails,
    info,
  };

  return (
    <AdjustDeclarationContext.Provider value={value}>
      {children}
    </AdjustDeclarationContext.Provider>
  );
};

const useAdjustDeclarationContext = () => {
  const context = React.useContext(AdjustDeclarationContext);
  if (context === undefined) {
    throw new Error(
      "useAdjustDeclarationContext must be used within a AdjustDeclarationProvider",
    );
  }
  return context;
};

export { AdjustDeclarationProvider, useAdjustDeclarationContext };

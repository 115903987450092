import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { FunctionComponent, Suspense, lazy } from "react";
import { useAppContext } from "../../../../context/AppContext";
import { getAllLevel1Resources } from "../../../../services/level1Resource";
import Heading from "../../../layout/Heading";
import Layout from "../../../layout/Layout";
import Level1ResourceAddWidget from "./Level1ResourceAddWidget";
import Level1ResourceWidget from "./Level1ResourceWidget";
import NotificationWidget from "./NotificationWidget";
import PendingApplicationWidget from "./PendingApplicationWidget";
import ReportWidget from "./ReportWidget";
import Loading from "@components/shared/Loading";
const TransactionsWidget = lazy(() => import("./TransactionsWidget"));

const Dashboard: FunctionComponent = () => {
  const { t } = useTranslation();
  const { loggedInInfo } = useAppContext();
  const { data: level1Resources, isLoading } = useQuery(
    ["level1resources"],
    getAllLevel1Resources,
    {
      keepPreviousData: true,
      select: (data) => {
        return data?.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name);
        });
      },
    }
  );

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <Layout>
      <header className="mb-5">
        <p className="font-semibold text-primary-3">
          {t("common.greeting")}, {loggedInInfo?.userDetails?.name}
        </p>
        <Heading className="text-xl sm:truncate" light={true}>
          {t("dashboard.title")}
        </Heading>
      </header>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <NotificationWidget />
        <PendingApplicationWidget />
        <ReportWidget />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
        {level1Resources?.map((scheme: any) => (
          <Level1ResourceWidget level1Resource={scheme} key={scheme.id} />
        ))}
        <Level1ResourceAddWidget />
      </div>
      <div className="grid grid-cols-1 gap-6 mt-6">
        <Suspense>
          <TransactionsWidget />
        </Suspense>
      </div>
    </Layout>
  );
};

export default Dashboard;

import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Tag from "@components/shared/Tag";
import ActionsButton from "@components/shared/DataTable/ActionsButton";
import DataTableRenderer from "@components/shared/DataTable/DataTableRenderer";
import AdministrativeApprovalModal from "@components/modal/AdministrativeApprovalModal";
import { usePaginationState } from "@components/shared/DataTable/Pagination";
import { useAllAdministrativeApprovals } from "@hooks/query/zenith/useAllAdministrativeApprovals";
import { AdministrativeApprovalNumericStatus } from "@services/administrativeApprovals";
import { convertLiterToML } from "@utils/convertUnits";
import { getActionTimestamp, getType } from "@utils/administrativeApproval";

type AdministrativeApprovalTableProps = {
  onSelect: (value: any) => void;
  selected: any;
  filterId?: string;
};

const AdministrativeApprovalTable: React.FunctionComponent<
  AdministrativeApprovalTableProps
> = ({ onSelect, selected, filterId }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [pagination, setPagination] = usePaginationState(0);
  const approvalId = searchParams.get("id");

  const {
    data: { administrativeApprovals, meta } = {
      administrativeApprovals: [],
      meta: {},
    },
    isLoading,
    isFetching,
  } = useAllAdministrativeApprovals({
    params: {
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      status: AdministrativeApprovalNumericStatus.UnderReview,
    },
  });

  const columns = React.useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: "select",
        cell: ({ row }) => {
          return (
            <input
              type="radio"
              checked={selected?.id === row.id}
              onChange={() => onSelect(row.original)}
            />
          );
        },
      },
      {
        header: t(
          "zenith.response_approval_info_request.from_subscriber"
        ) as string,
        cell: (info) => {
          return info.row.original.seller?.name ?? "-";
        },
      },
      {
        accessorKey: "buyer.name",
        header: t(
          "zenith.response_approval_info_request.to_subscriber"
        ) as string,
      },
      {
        header: t("common.level0wrs") as string,
        cell: (info) => {
          const { extractionRights = [] } = info.row.original;
          return extractionRights.length
            ? extractionRights[0].level0Resource?.identifier
            : "-";
        },
      },
      {
        header: t("common.extraction_rights") as any,
        cell: (info) => {
          return info.row.original.extractionRights
            .map((i: any) => i.name)
            .join(", ");
        },
      },
      {
        header: t("common.volume") as string,
        cell: (info) => {
          const { volume = "0" } = info.row.original;
          return convertLiterToML(+volume);
        },
      },
      {
        accessorKey: "type",
        header: t("common.type") as string,
        cell: (info) => {
          const type = info.getValue() as number;
          return <Tag status="info">{getType(type)}</Tag>;
        },
      },
      {
        header: t("common.actioned_at") as string,
        cell: ({ row }) => {
          return getActionTimestamp(row.original);
        },
      },
      {
        id: "actions",
        header: t("common.actions") as string,
        cell: ({ row }) => {
          return (
            <ActionsButton
              menus={[
                {
                  label: t("common.view"),
                  to: `${window.location.pathname}${window.location.hash}?id=${row.id}`,
                },
              ]}
            />
          );
        },
      },
    ],
    [t, selected?.id, onSelect]
  );

  const tableData = filterId
    ? administrativeApprovals.filter((i: any) => i.id === filterId)
    : administrativeApprovals;

  return (
    <>
      <DataTableRenderer
        name={t("zenith.approval.title")}
        columns={columns}
        data={tableData}
        pagination={pagination}
        onPaginationChange={setPagination}
        pageCount={meta.lastPage ?? -1}
        isLoading={isLoading}
        isFetching={isFetching}
        stickyHeader
      />

      {approvalId ? <AdministrativeApprovalModal id={approvalId} /> : null}
    </>
  );
};

export default AdministrativeApprovalTable;

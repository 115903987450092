import classNames from "classnames";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { orderBy, sumBy } from "lodash";
import { PresentationChartLineIcon } from "@heroicons/react/24/solid";

import Card from "@components/layout/Card";
import Tag from "@components/shared/Tag";
import AddLevel0Resource from "./AddLevel0Resource";
import Loading from "@components/shared/Loading";
import { useAllSubscriberLevel0Resources } from "@hooks/query/useAllSubscriberLevel0Resources";
import { formatVolume } from "@utils/formatVolume";
import { formatDate } from "@utils/formatDate";
import {
  calculateWaterClassVolumeStatus,
  prepareWaterClassVolumeStatusPayload,
} from "@utils/waterClassSummeryExtras";

type SubscriberLevel0WRSBalanceWidgetProps = {
  subscriberId: string;
  level1ResourceId: string;
};

const SubscriberLevel0WRSBalanceWidget: React.FunctionComponent<
  SubscriberLevel0WRSBalanceWidgetProps
> = ({ subscriberId, level1ResourceId }) => {
  const { t } = useTranslation();
  const {
    data: level0Balances = [],
    isLoading,
    refetch,
  } = useAllSubscriberLevel0Resources(subscriberId, {
    select: (res: any[] = []) => orderBy(res, ["identifier"], ["asc"]),
  });

  if (isLoading) {
    return (
      <div className="pt-20">
        <Loading />
      </div>
    );
  }

  return (
    <Card
      header={
        <div className="flex justify-between">
          <h3 className="flex gap-3">
            <PresentationChartLineIcon className="w-6 h-6" />{" "}
            {t("subscriber.level0wrs_volumes")}
          </h3>

          <AddLevel0Resource
            subscriberId={subscriberId}
            level1ResourceId={level1ResourceId}
            excludeIds={level0Balances.map((i: any) => i.id)}
            onSuccess={refetch}
          />
        </div>
      }
    >
      {level0Balances.length <= 0 && (
        <h4 className="font-light text-primary-2 text-center text-xl my-20">
          {t("level0wrs.no_data")}
        </h4>
      )}

      <div className="flex flex-row gap-4 overflow-scroll scrollbar-hide">
        {level0Balances.map((level0: any) => {
          const level0wrsClassVolumeStatuses = level0?.waterClasses.map(
            (i: any) =>
              calculateWaterClassVolumeStatus(
                prepareWaterClassVolumeStatusPayload(i),
              ),
          );

          const totalBalance = sumBy(
            level0wrsClassVolumeStatuses,
            "remainingBalance",
          );
          const hasWaterClass = level0?.waterClasses?.length > 0;
          return (
            <div
              key={level0.id}
              className={classNames(
                "flex flex-col items-start p-4 rounded-lg border shrink-0",
                {
                  "w-48": !hasWaterClass,
                },
              )}
            >
              <header className="flex-1 mb-2">
                <div className="text-xs text-gray-500">
                  {t("common.level0wrs")}
                </div>
                <div className="text-xl">{level0.identifier}</div>
              </header>
              {hasWaterClass ? (
                <>
                  <div className="pb-12 flex flex-row gap-4">
                    <div>
                      <h6 className="mb-2 text-xs text-gray-500">
                        {t("common.status")}
                      </h6>
                      <Tag status="success">{t("common.active")}</Tag>
                    </div>
                    <div>
                      <h6 className="mb-2 text-xs text-gray-500">
                        {t("subscriber.volume_remain", {
                          today: formatDate(new Date()),
                        })}
                      </h6>
                      <span
                        className={classNames(
                          totalBalance < 0 ? "text-red-500" : "text-primary-3",
                        )}
                      >
                        {formatVolume(totalBalance)}
                      </span>
                    </div>
                  </div>
                  <Link
                    className="btn-secondary px-3 py-1.5 text-xs rounded"
                    to={`./level0_resources/${level0.id}`}
                  >
                    {t("common.view")}
                  </Link>
                </>
              ) : (
                <Link
                  className="btn-secondary px-3 py-1.5 text-xs rounded"
                  to={`/polestar/level1wrs/${level1ResourceId}/extraction_rights/create?subscriberId=${subscriberId}&level0ResourceId=${level0?.id}`}
                >
                  {t("extraction_right.create.title")}
                </Link>
              )}
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export default SubscriberLevel0WRSBalanceWidget;

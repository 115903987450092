import { useTranslation } from "react-i18next";

import Layout from "@components/layout/Layout";

import EventTransactionPaginatedTable from "@components/table/EventTransactionPaginatedTable";

const AuditTrail: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Layout
      permissions={["ViewEventTransactions"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("audit_trail.audit_trail"),
        },
      ]}
      title={t("audit_trail.audit_trail")}
    >
      <EventTransactionPaginatedTable
        stickyHeader={true}
        showDateFilters
        showEventFilters
      />
    </Layout>
  );
};

export default AuditTrail;

import { useQuery } from "@tanstack/react-query";

import { getAllExtractionRights } from "@services/zenith/extractionRight";

export const useAllExtractionRights = ({
  params,
  ...options
}: {
  params?: {
    assertedByWalletId?: string;
    level0ResourceId?: string;
    typeIds?: number[];
    isActive?: boolean;
    isDeleted?: boolean;
  };
  [key: string]: any;
} = {}) => {
  return useQuery<any>({
    queryKey: ["extraction_rights", params],
    queryFn: () => getAllExtractionRights(params),
    ...options,
  });
};

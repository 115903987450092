import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Layout from "@components/layout/Layout";
import UpdateSubscriberWorkflow from "@components/form/subscriber/update/UpdateSubscriberWorkflow";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";
import { useSubscriber } from "@hooks/query/useSubscriber";

const Level1WRSUpdateSubscriber = () => {
  const { t } = useTranslation();
  const { id: level1ResourceId = "", subscriberId = "" } = useParams();
  const { data: level1Resource } = useGetLevel1Resource(level1ResourceId);
  const { data: subscriber } = useSubscriber(subscriberId);

  return (
    <Layout
      permissions={["UpdateSubscribers"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: level1Resource?.name,
          href: `/polestar/level1wrs/${level1ResourceId}`,
        },
        {
          label: t("common.subscribers"),
          href: `/polestar/level1wrs/${level1ResourceId}#2`,
        },
        {
          label: `${subscriber?.name}`,
          href: `/polestar/subscribers/${subscriber?.id}`,
        },
        {
          label: t("subscriber.edit.title"),
        },
      ]}
      title={t("subscriber.edit.title")}
      accountingPeriodLevel1ResourceId={level1ResourceId}
    >
      <UpdateSubscriberWorkflow />
    </Layout>
  );
};

export default Level1WRSUpdateSubscriber;

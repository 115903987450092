import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

import { downloadAsCSV } from "@utils/downloadAsCSV";
import { toastError, toastSuccess } from "@utils/toast";

type ExportCSVButtonProps = {
  data: any[];
  fileName?: string;
};

const ExportCSVButton: React.FunctionComponent<ExportCSVButtonProps> = ({
  data,
  fileName,
}) => {
  const { t } = useTranslation();

  const handleClick = () => {
    try {
      downloadAsCSV({
        data,
        fileName,
      });
      toastSuccess(t("shared.table.toast.export.success"));
    } catch (error: any) {
      toastError(
        t("shared.table.toast.export.failed", {
          error: error?.response?.data?.message ?? error?.message,
        })
      );
    }
  };

  return (
    <button
      type="button"
      className="btn-secondary inline-flex gap-1 items-center text-sm rounded"
      onClick={handleClick}
    >
      <ArrowDownTrayIcon className="w-5 h-5" />
      {t("shared.table.export_selected")}
    </button>
  );
};

export default ExportCSVButton;

import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Layout from "@components/layout/Layout";
import UpdateExtractionPointWorkflow from "@components/form/extraction_point/UpdateExtractionPointWorkflow";
import { useExtractionPoint } from "@hooks/query/useExtractionPoint";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";

const Level1WRSUpdateExtractionPoint = () => {
  const { t } = useTranslation();
  const { id: level1ResourceId = "", extractionPointId = "" } = useParams();
  const [searchParams] = useSearchParams();
  const subscriberId = searchParams.get("subscriberId") ?? "";
  const { data: level1Resource = {} } = useGetLevel1Resource(level1ResourceId);
  const { data: extractionPoint } = useExtractionPoint(extractionPointId);

  return (
    <Layout
      permissions={["UpdateExtractionPoint"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: level1Resource?.name,
          href: `/polestar/level1wrs/${level1ResourceId}`,
        },
        {
          label: t("common.extraction_points"),
          href: `/polestar/level1wrs/${level1ResourceId}#5`,
        },
        {
          label: extractionPoint?.name,
          href: `/polestar/subscribers/${subscriberId}?level0ResourceId=${extractionPoint?.level0ResourceId}&extractionPointId=${extractionPointId}`,
        },
        {
          label: t("extraction_point.edit"),
        },
      ]}
      title={t("extraction_point.edit")}
      accountingPeriodLevel1ResourceId={level1ResourceId}
    >
      <UpdateExtractionPointWorkflow />
    </Layout>
  );
};

export default Level1WRSUpdateExtractionPoint;

import classNames from "classnames";
import { useTranslation } from "react-i18next";

type CreateSubscriberStep2LabelProps = {
  isSelected?: boolean;
};

const ExtractionPointLinkToRightStep3Label: React.FunctionComponent<
  CreateSubscriberStep2LabelProps
> = ({ isSelected }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-full text-left flex items-center text-sm">
      <span
        className={classNames(
          "rounded-full h-5 w-5 font-bold text-center flex justify-center items-center m-2",
          {
            "bg-primary-2 text-white": isSelected,
            "bg-gray-500 text-white": !isSelected,
          }
        )}
      >
        3
      </span>
      <span>{t("common.confirm")}</span>
    </div>
  );
};

export default ExtractionPointLinkToRightStep3Label;

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { responseInfoRequest } from "@services/zenith/administrativeApprovals";
import { isFunction } from "lodash";

export const useResponseApprovalInfoRequest = (
  options: Record<string, any> = {}
) => {
  const queryClient = useQueryClient();
  const { onSucces, ...restOptions } = options;

  return useMutation({
    mutationFn: responseInfoRequest,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["administrativeApprovals"],
      });

      if (isFunction(onSucces)) {
        onSucces(res);
      }
    },
    ...restOptions,
  });
};

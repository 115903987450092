import React from "react";

const ZoneAccountIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#004A88"
        d="M12.4452 0.367187C11.9569 -0.121094 11.1639 -0.121094 10.6757 0.367187L5.98816 5.05469C5.49988 5.54297 5.49988 6.33594 5.98816 6.82422L6.61316 7.44922C7.10144 7.9375 7.89441 7.9375 8.38269 7.44922L8.53894 7.29297L12.7108 11.4609L12.5546 11.6172C12.0663 12.1055 12.0663 12.8984 12.5546 13.3867L13.1796 14.0117C13.6678 14.5 14.4608 14.5 14.9491 14.0117L19.6366 9.32422C20.1249 8.83594 20.1249 8.04297 19.6366 7.55469L19.0116 6.92969C18.5233 6.44141 17.7303 6.44141 17.2421 6.92969L17.0858 7.08594L12.9139 2.91406L13.0702 2.75781C13.5585 2.26953 13.5585 1.47656 13.0702 0.988281L12.4452 0.363281V0.367187ZM6.50769 11.6172C6.01941 11.1289 5.22644 11.1289 4.73816 11.6172L0.363159 15.9922C-0.125122 16.4805 -0.125122 17.2734 0.363159 17.7617L2.23816 19.6367C2.72644 20.125 3.51941 20.125 4.00769 19.6367L8.38269 15.2617C8.87097 14.7734 8.87097 13.9805 8.38269 13.4922L8.328 13.4375L10.6249 11.1445L8.85535 9.375L6.56238 11.668L6.50769 11.6133V11.6172Z"
      />
    </svg>
  );
};

export default ZoneAccountIcon;

import api from "./api";

export type GetBalanceAdjustmentsParams = {
  type: number;
  declarationIds: string[];
};

export enum BalanceAdjustmentType {
  WATER_HARVESTING = 1,
  METER_READING = 2,
}

export const getBalanceAdjustments = async (
  params?: Partial<GetBalanceAdjustmentsParams>,
): Promise<any> => {
  const { data } = await api.get("/admin/balanceAdjustments", { params });

  return data.balanceAdjustments;
};

export const createBalanceAdjustment = async (balanceAdjustmentData: {
  subscriberId: string;
  level0ResourceId: string;
  extractionRightId: string;
  declarations: any[];
  type: BalanceAdjustmentType;
  notes?: string;
  effectiveDate: Date;
}) => {
  const { data } = await api.post(
    "/admin/balanceAdjustments",
    balanceAdjustmentData,
  );

  return [data.balanceAdjustment, data.workflowInstance];
};

export const adjustDeclaration = async (payload: {
  subscriberId: string;
  level0ResourceId: string;
  declarationId: string;
  newReading: number;
  newUsage: number;
  notes?: string;
}) => {
  const { data } = await api.post(
    "/admin/balanceAdjustments/declaration",
    payload,
  );

  return [data.balanceAdjustment, data.workflowInstance];
};

import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import SearchInput from "@components/form/SearchInput";
import Label from "@components/form/Label";
import SelectLevel1Resource from "@components/form/SelectLevel1Resource";
import { useNotificationContext } from "@context/NotificationContext";
import { useAllLevel0Resources } from "@hooks/query/useAllLevel0Resources";
import { formatVolume } from "@utils/formatVolume";
import { Level0WRSSource } from "@services/level0Resource";
import Table from "@components/layout/Table";
import { useAllSubscribers } from "@hooks/query/useAllSubscribers";

const CreateNotificationStep2SelectLevel0WRS: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { details, stepHelpers, navigateForCancel, handleChangeDetails } =
    useNotificationContext();
  const [searchedValue, setSearchedValue] = useState("");

  const { data: subscribers } = useAllSubscribers();
  const { data: level0Resources, isLoading } = useAllLevel0Resources();

  React.useEffect(() => {
    const selectedSubscribers = [];
    if (!subscribers) return;
    for (const subscriber of subscribers) {
      const inSelectedZone = subscriber.extractionRights.some((er: any) =>
        details.level0wrsIds.includes(er.level0ResourceId),
      );
      if (inSelectedZone) selectedSubscribers.push(subscriber);
    }
    handleChangeDetails(
      "subscriberIds",
      selectedSubscribers.map((subscriber: any) => subscriber.id),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details.level0wrsIds]);

  const handleSave = (e: any) => {
    e.preventDefault();
    stepHelpers.goToNextStep();
  };

  const tableFields = [
    {
      title: t("level0wrs.name"),
      name: "name",
    },
    {
      title: t("level0wrs.identifier"),
      name: "identifier",
    },
    {
      title: t("level0wrs.volume"),
      name: "volume",
    },
    {
      title: t("common.level1wrs"),
      name: "level1wrs",
    },
  ];

  const tableData = level0Resources?.map((item: any) => {
    return {
      id: item?.id,
      name: item?.name ?? "",
      identifier: item?.identifier ?? "",
      description: item?.description ?? "",
      volume: item?.yield ? formatVolume(item.yield) : 0,
      level1wrs: item?.parent?.name,
      level1wrsId: item?.parentId,
    };
  });

  const disabledKeys = level0Resources
    ?.filter(
      (level0Resource: any) =>
        level0Resource.source !== Level0WRSSource.Surface,
    )
    ?.map((level0Resource: any) => level0Resource.id);

  return (
    <>
      <div className="p-6 pb-0 flex flex-col grow">
        <Heading className="text-xl leading-6 mb-6" light={true}>
          {t("water_class.select_level0_resource")}
        </Heading>

        <header className="grid grid-cols-1 gap-2 md:grid-cols-4 md:gap-3 mb-4">
          <div>
            <Label htmlFor="level1Resource">{t("common.level1wrs")}</Label>
            <SelectLevel1Resource
              inputId="level1Resource"
              value={details.level1ResourceId}
              onChange={e => {
                handleChangeDetails("level1ResourceId", e?.value);
              }}
              isClearable
              openMenuOnFocus
            />
          </div>
          <div>
            <Label htmlFor="level0Resource">
              {t("level0wrs.filter.level0")}
            </Label>
            <SearchInput
              id="level0Resource"
              onChange={e => {
                setSearchedValue(e.target.value);
              }}
            />
          </div>
        </header>

        <Table
          containerClassName="rounded-none md:rounded-none"
          fields={tableFields}
          data={tableData
            ?.filter((td: any) => {
              const queryString = searchedValue.toString().toLowerCase();
              return (
                !searchedValue.length ||
                td.name.toString().toLowerCase().includes(queryString) ||
                td.identifier.toString().toLowerCase().includes(queryString)
              );
            })
            ?.filter(
              (td: any) =>
                !details.level1ResourceId ||
                td.level1wrsId === details.level1ResourceId,
            )}
          selectionKey="id"
          selectedKeys={details.level0wrsIds}
          disabledKeys={disabledKeys}
          onSelectionChange={data => handleChangeDetails("level0wrsIds", data)}
          pageSize={50}
          stickyHeader
          loading={isLoading}
        />
      </div>

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>

        <button
          className="btn-primary"
          disabled={!details.level0wrsIds.length}
          onClick={handleSave}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary text-sm font-semibold"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </>
  );
};

export default CreateNotificationStep2SelectLevel0WRS;

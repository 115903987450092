import { useTranslation } from "react-i18next";
import Tag from "./Tag";

type ExtractionPointStatusTagsProps = {
  point: { isActive: boolean; meter: any };
};

const ExtractionPointStatusTags: React.FunctionComponent<
  ExtractionPointStatusTagsProps
> = ({ point }) => {
  const { t } = useTranslation();
  const hasMeter = point?.meter !== null;
  return (
    <div className="flex flex-row items-center gap-2">
      <Tag status={point?.isActive ? "success" : "error"}>
        {point?.isActive ? t("common.active") : t("common.inactive")}
      </Tag>
      <Tag status={hasMeter ? "info" : "error"}>
        {hasMeter ? t("common.meter") : t("meter.unmetered")}
      </Tag>
    </div>
  );
};

export default ExtractionPointStatusTags;

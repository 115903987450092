import { Route, Routes } from "react-router-dom";

import SubscriberDashboard from "@components/form/subscriber/dashboard/SubscriberDashboard";
import CreateExtractionPoint from "@components/pages/polestar/extraction_point/CreateExtractionPoint";
import UpdateExtractionPoint from "@components/pages/polestar/extraction_point/UpdateExtractionPoint";
import CreateMeter from "@components/pages/polestar/meters/CreateMeter";
import LinkToExtractionRights from "@components/pages/polestar/subscribers/extraction_points/link_to_rights/LinkToExtractionRights";
import SubscriberCreate from "@components/pages/polestar/subscribers/SubscriberCreate";
import SubscriberCreateDeclaration from "@components/pages/polestar/subscribers/SubscriberCreateDeclaration";
import SubscriberDashboardLayout from "@components/pages/polestar/subscribers/SubscriberDashboardLayout";
import SubscriberDecommissionMeter from "@components/pages/polestar/subscribers/SubscriberDecommissionMeter";
import SubscriberLinkMeter from "@components/pages/polestar/subscribers/SubscriberLinkMeter";
import SubscriberList from "@components/pages/polestar/subscribers/SubscriberList";
import SubscriberReplaceMeter from "@components/pages/polestar/subscribers/SubscriberReplaceMeter";
import SubscriberUpdate from "@components/pages/polestar/subscribers/SubscriberUpdate";
import UserCreate from "@components/pages/polestar/users/UserCreate";
import UserUpdate from "@components/pages/polestar/users/UserUpdate";
import SubscriberAuditTrailsAndEvidences from "@components/form/subscriber/dashboard/SubscriberAuditTrailsAndEvidences";
import SubscriberLevel0WRSLayout from "@components/form/subscriber/dashboard/level0accounts/SubscriberLevel0WRSLayout";
import Level0WRSSummaryTab from "@components/form/subscriber/dashboard/level0accounts/Level0WRSSummaryTab";
import NotFound from "@components/error/NotFound";
import ExtractionPointTab from "@components/form/subscriber/dashboard/level0accounts/ExtractionPointTab";
import TransactionHistoryTab from "@components/form/subscriber/dashboard/level0accounts/TransactionHistoryTab";
import ExtractionRightTab from "@components/form/subscriber/dashboard/level0accounts/ExtractionRightTab";

const SubscriberRoutes = () => {
  return (
    <Routes>
      <Route index element={<SubscriberList />} />
      <Route path="create" element={<SubscriberCreate />} />

      <Route path=":id">
        <Route element={<SubscriberDashboardLayout />}>
          <Route index element={<SubscriberDashboard />} />
          <Route path="level0_resources">
            <Route
              path=":level0ResourceId?"
              element={<SubscriberLevel0WRSLayout />}
            >
              <Route index element={<Level0WRSSummaryTab />} />
              <Route
                path="extraction_rights"
                element={<ExtractionRightTab />}
              />
              <Route
                path="meters_extraction_points"
                element={<ExtractionPointTab />}
              />
              <Route path="transactions" element={<TransactionHistoryTab />} />
            </Route>
          </Route>
          <Route
            path="audit_trails"
            element={<SubscriberAuditTrailsAndEvidences />}
          />
        </Route>

        <Route path="edit" element={<SubscriberUpdate />} />
        <Route path="level0_resources/:level0ResourceId">
          <Route path="extraction_points">
            <Route path="link" element={<LinkToExtractionRights />} />
            <Route path="link_meter" element={<SubscriberLinkMeter />} />
            <Route path="create" element={<CreateExtractionPoint />} />
            <Route
              path=":extractionPointId/edit"
              element={<UpdateExtractionPoint />}
            />
          </Route>
          <Route path="meters">
            <Route path="create" element={<CreateMeter />} />
            <Route path="replace" element={<SubscriberReplaceMeter />} />
            <Route
              path="Decommission"
              element={<SubscriberDecommissionMeter />}
            />
          </Route>
          <Route path="declarations">
            <Route path="create" element={<SubscriberCreateDeclaration />} />
          </Route>
        </Route>

        <Route path="users">
          <Route path="create" element={<UserCreate />} />
          <Route path=":userId/edit" element={<UserUpdate />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default SubscriberRoutes;

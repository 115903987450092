import React from "react";
import { twMerge } from "tailwind-merge";

interface ICardProps {
  children: React.ReactNode;
  className?: string;
  header?: React.ReactNode;
}

const Card: React.FunctionComponent<ICardProps> = ({
  children,
  className,
  header,
}) => {
  return (
    <div
      className={twMerge(
        "border border-gray-100 rounded-lg shadow-none p-4 bg-white",
        className
      )}
    >
      {header && (
        <div className="flex justify-between mb-2">
          <div className="w-full my-2 font-semibold  text-primary-2">
            {header}
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default Card;

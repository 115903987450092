import { useState } from "react";
import { useTranslation } from "react-i18next";

import Heading1 from "@components/layout/Heading";
import BalanceAdjustmentTable from "@components/table/BalanceAdjustmentTable";
import AccountingPeriodChecklistFooter from "./AccountingPeriodChecklistFooter";
import { useAppContext } from "@context/AppContext";
import { useAccountingPeriodChecksContext } from "@context/AccountingPeriodChecksContext";
import { BalanceAdjustmentType } from "@services/balanceAdjustment";

const WaterHarvestingsChecklist: React.FunctionComponent = () => {
  const { loggedInInfo } = useAppContext();
  const { t } = useTranslation();
  const { currentAccountingPeriodCheck } = useAccountingPeriodChecksContext();
  const [data, setData] = useState<any[]>([]);

  return (
    <>
      <div className="flex flex-col p-4 grow gap-4">
        <Heading1 light>
          {t("accounting_period.checklist.water_harvestings")}
          <strong className="ml-6">
            {
              data
                ?.filter(
                  (row: { level0Resource: any }) =>
                    currentAccountingPeriodCheck?.level1ResourceId ===
                      undefined ||
                    row.level0Resource?.parentId ===
                      currentAccountingPeriodCheck?.level1ResourceId,
                )
                ?.filter(
                  (row: { declarations: any }) =>
                    (currentAccountingPeriodCheck?.accountingPeriod
                      ?.periodStart === undefined &&
                      currentAccountingPeriodCheck?.accountingPeriod
                        ?.periodEnd === undefined) ||
                    row.declarations?.some(
                      (x: any) =>
                        (new Date(
                          currentAccountingPeriodCheck?.accountingPeriod
                            ?.periodStart!,
                        ) <= new Date(x.startReadAt) &&
                          new Date(x.startReadAt) <=
                            new Date(
                              currentAccountingPeriodCheck?.accountingPeriod
                                ?.periodEnd!,
                            )) ||
                        row.declarations?.some(
                          (x: any) =>
                            new Date(
                              currentAccountingPeriodCheck?.accountingPeriod
                                ?.periodStart!,
                            ) <= new Date(x.endReadAt) &&
                            new Date(x.endReadAt) <=
                              new Date(
                                currentAccountingPeriodCheck?.accountingPeriod
                                  ?.periodEnd!,
                              ),
                        ),
                    ),
                ).length
            }
          </strong>
        </Heading1>
        <BalanceAdjustmentTable
          level1ResourceId={currentAccountingPeriodCheck?.level1ResourceId}
          periodStart={
            currentAccountingPeriodCheck?.accountingPeriod?.periodStart &&
            new Date(currentAccountingPeriodCheck.accountingPeriod.periodStart)
          }
          periodEnd={
            currentAccountingPeriodCheck?.accountingPeriod?.periodEnd &&
            new Date(currentAccountingPeriodCheck.accountingPeriod.periodEnd)
          }
          openNewWindow={true}
          onLoaded={data => setData(data)}
          type={BalanceAdjustmentType.WATER_HARVESTING}
        />
      </div>
      <AccountingPeriodChecklistFooter
        stepData={{
          waterHarvestingsCheckedAt: new Date(),
          waterHarvestingsCheckedByUserId: loggedInInfo?.userDetails?.id,
        }}
      />
    </>
  );
};

export default WaterHarvestingsChecklist;

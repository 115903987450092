import React from "react";
import { useTranslation } from "react-i18next";

import { useAdminTransferContext } from "@context/AdminTransferContext";
import Heading1 from "@components/layout/Heading";
import SelectSingleExtractionPoint from "@components/table/SelectSingleExtractionPointTable";

const AdminTransferSelectExtractionPoint = () => {
  const { t } = useTranslation();
  const {
    stepHelpers,
    details,
    handleChangeDetails,
    getInfoMessages,
    navigateForCancel,
  } = useAdminTransferContext();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    stepHelpers.goToNextStep();
  };

  return (
    <form
      className="flex flex-col gap-6 p-6 grow justify-between"
      onSubmit={handleSubmit}
    >
      <div className="space-y-4">
        <Heading1 light>
          {t("approval.admin_transfer.create.step_4.select_point")}
        </Heading1>
      </div>

      <SelectSingleExtractionPoint
        level1ResourceId={details.level1wrs.id}
        definedByWalletId={details.subscriber.walletId}
        onSelect={(item: any) => handleChangeDetails("extractionPoint", item)}
        selected={details.extractionPoint}
      />

      <footer className="flex gap-4 -mx-2 mt-2 p-6 pb-0 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>

        <button
          type="button"
          className="btn-primary"
          onClick={() => stepHelpers.goToNextStep()}
          disabled={
            details.extractionPoint?.id === "" ||
            getInfoMessages("error", 3).length > 0
          }
        >
          {t("common.next_step")}
        </button>

        <button
          type="button"
          className="btn-outline-primary"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default AdminTransferSelectExtractionPoint;

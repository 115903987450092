import { useTranslation } from "react-i18next";

import Layout from "@components/layout/Layout";
import CreateStockAndDomesticWorkflow from "@components/form/admin_approvals/create_stock_and_domestic/CreateStockAndDomesticWorkflow";

const CreateStockAndDomestic = () => {
  const { t } = useTranslation();

  return (
    <Layout
      permissions={["CreateApprovals"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("common.administrative_approvals"),
          href: `/polestar/administrative_approvals#tab=3`,
        },
        {
          label: t("approval.stock_and_domestic.application"),
        },
      ]}
      title={t("approval.stock_and_domestic.application")}
    >
      <CreateStockAndDomesticWorkflow />
    </Layout>
  );
};

export default CreateStockAndDomestic;

import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

import WorkflowPanels from "@components/form/WorkflowPanels";
import StepButton from "@components/shared/StepButton";
import InfoPanel from "@components/form/InfoPanel";
import DeleteExtractionRightLedger from "./DeleteExtractionRightLedger";
import DeleteExtractionRightCompleted from "./DeleteExtractionRightCompleted";
import { useStep } from "@hooks/useStep";

const DeleteExtractionRightWorkflow: FunctionComponent = () => {
  const { t } = useTranslation();
  const [currentStep, stepHelpers] = useStep(2);
  const [workflowInstance, setWorkflowInstance] = useState<any>(null);

  const steps = [
    {
      label: (
        <StepButton index={0} currentStep={currentStep}>
          {t("extraction_right.delete.step1.title")}
        </StepButton>
      ),
      panel: (
        <DeleteExtractionRightLedger
          onSuccess={(instance) => {
            setWorkflowInstance(instance);
            stepHelpers.goToNextStep();
          }}
        />
      ),
      infoPanel: (
        <InfoPanel warnings={[t("extraction_right.delete.step1.info.warning")]}>
          <ul className="space-y-4">
            <li>{t("extraction_right.delete.step1.info.description")}</li>
            <li>
              <strong>{t("common.active")}</strong>
              {" - "}
              {t("extraction_right.delete.step1.info.active")}
            </li>
            <li>
              <strong>{t("common.inactive")}</strong>
              {" - "}
              {t("extraction_right.delete.step1.info.inactive")}
            </li>
            <li>
              <strong>{t("extraction_right.delete.step1.deleted")}</strong>
              {" - "}
              {t("extraction_right.delete.step1.info.deleted")}
            </li>
          </ul>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton index={1} currentStep={currentStep}>
          {t("extraction_right.delete.step2.title")}
        </StepButton>
      ),
      panel: (
        <DeleteExtractionRightCompleted workflowInstance={workflowInstance} />
      ),
      infoPanel: (
        <InfoPanel successes={[t("extraction_right.delete.step2.info")]} />
      ),
    },
  ];

  return <WorkflowPanels selectedStep={currentStep} steps={steps} />;
};

export default DeleteExtractionRightWorkflow;

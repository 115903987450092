import React from "react";
import classNames from "classnames";
import { PaginationState, Table } from "@tanstack/react-table";
import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

import SpinnerIcon from "@components/icons/SpinnerIcon";

type PaginationProps = {
  table: Table<any>;
  className?: string;
  isFetching?: boolean;
  totalSelectionRows: number;
  showNumberOfSelectedRows?: boolean;
};

const PAGE_SIZES = [25, 50, 100];

const Pagination: React.FunctionComponent<PaginationProps> = ({
  table,
  className,
  isFetching = false,
  totalSelectionRows,
  showNumberOfSelectedRows = true,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        "flex items-center justify-betwee px-4 py-3 sm:px-6",
        className
      )}
    >
      {/* Mobile nav */}
      <div className="flex flex-1 justify-between sm:hidden text-sm">
        <button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
          className={classNames("btn-default rounded", {
            "opacity-60": !table.getCanPreviousPage(),
          })}
        >
          {t("shared.table.pagination.previous")}
        </button>

        <button
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
          className={classNames("btn-default rounded", {
            "opacity-60": !table.getCanNextPage(),
          })}
        >
          {t("shared.table.pagination.next")}
        </button>
      </div>

      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between text-sm text-gray-500">
        {showNumberOfSelectedRows && (
          <div>
            {totalSelectionRows > 0 ? (
              <div>
                {totalSelectionRows}{" "}
                {t("shared.table.pagination.rows_selected")}
              </div>
            ) : null}
          </div>
        )}

        <div className="flex items-center gap-4">
          {isFetching && (
            <div className="flex items-center gap-2 text-gray-400 text-xs">
              <SpinnerIcon width={20} height={20} />
              {t("common.loading")}
            </div>
          )}

          <select
            className="bg-white block rounded shadow-sm border py-1.5 px-2 focus:ring-2 focus:ring-primary-1"
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {PAGE_SIZES.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize} / {t("shared.table.pagination.page")}
              </option>
            ))}
          </select>
          <div className="flex items-center gap-1">
            <span>{t("shared.table.pagination.goto_page")}:</span>
            <input
              type="number"
              value={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              min={1}
              max={table.getPageCount()}
              className="border p-1 rounded w-16 focus:ring-2 shadow-sm focus:ring-primary-1"
            />
            <span>of {table.getPageCount()}</span>
          </div>

          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              className={classNames(
                "relative inline-flex items-center rounded-l-md px-2 py-2 ring-1 ring-inset ring-gray-300  focus:z-20 focus:outline-offset-0",
                table.getCanPreviousPage() ? "hover:bg-gray-50" : "opacity-50"
              )}
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              <span className="sr-only">
                {t("shared.table.pagination.first")}
              </span>
              <ChevronDoubleLeftIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </button>
            <button
              className={classNames(
                "relative inline-flex items-center px-2 py-2 ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0",
                table.getCanPreviousPage() ? "hover:bg-gray-50" : "opacity-50"
              )}
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <span className="sr-only">
                {t("shared.table.pagination.previous")}
              </span>
              <ChevronLeftIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </button>
            <button
              className={classNames(
                "relative inline-flex items-center px-2 py-2 ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0",
                table.getCanNextPage() ? "hover:bg-gray-50" : "opacity-50"
              )}
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <span className="sr-only">
                {t("shared.table.pagination.next")}
              </span>
              <ChevronRightIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </button>
            <button
              className={classNames(
                "relative inline-flex items-center rounded-r-md px-2 py-2 ring-1 ring-inset ring-gray-300  focus:z-20 focus:outline-offset-0",
                table.getCanNextPage() ? "hover:bg-gray-50" : "opacity-50"
              )}
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              <span className="sr-only">
                {t("shared.table.pagination.last")}
              </span>
              <ChevronDoubleRightIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export const usePaginationState = (pageIndex = 0, pageSize = PAGE_SIZES[0]) => {
  return React.useState<PaginationState>({
    pageIndex,
    pageSize,
  });
};

export default Pagination;

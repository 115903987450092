import { Route, Routes } from "react-router-dom";

import CreateDeclaration from "@components/pages/polestar/declarations/CreateDeclaration";
import LinkMeter from "@components/pages/polestar/extraction_point/LinkMeter";
import UnlinkExtractionPoint from "@components/pages/polestar/extraction_point/UnlinkExtractionPoint";
import CreateExtractionRight from "@components/pages/polestar/extraction_right/CreateExtractionRight";
import DeleteExtractionRight from "@components/pages/polestar/extraction_right/DeleteExtractionRight";
import UpdateExtractionRight from "@components/pages/polestar/extraction_right/UpdateExtractionRight";
import CreateLevel0WRS from "@components/pages/polestar/level0wrs/CreateLevel0WRS";
import UpdateLevel0WRS from "@components/pages/polestar/level0wrs/UpdateLevel0WRS";
import BulkMeterUpload from "@components/pages/polestar/level1wrs/BulkMeterUpload";
import CreateLevel1WRS from "@components/pages/polestar/level1wrs/CreateLevel1WRS";
import Level1WRSCreateExtractionPoint from "@components/pages/polestar/level1wrs/Level1WRSCreateExtractionPoint";
import Level1WRSCreateSubscriber from "@components/pages/polestar/level1wrs/Level1WRSCreateSubscriber";
import Level1WRSCreateUser from "@components/pages/polestar/level1wrs/Level1WRSCreateUser";
import Level1WRSIndex from "@components/pages/polestar/level1wrs/Level1WRSIndex";
import Level1WRSUpdateExtractionPoint from "@components/pages/polestar/level1wrs/Level1WRSUpdateExtractionPoint";
import Level1WRSUpdateSubscriber from "@components/pages/polestar/level1wrs/Level1WRSUpdateSubscriber";
import DecommissionMeter from "@components/pages/polestar/meters/DecommissionMeter";
import ReplaceMeter from "@components/pages/polestar/meters/ReplaceMeter";
import ReplaceMeterCapsule from "@components/pages/polestar/meters/ReplaceMeterCapsule";
import CreateWaterClass from "@components/pages/polestar/water_classes/CreateWaterClass";
import UpdateWaterClass from "@components/pages/polestar/water_classes/UpdateWaterClass";
import NotFound from "@components/error/NotFound";

const Level1WRSRoutes = () => {
  return (
    <Routes>
      <Route path="create" element={<CreateLevel1WRS />} />
      <Route path=":id">
        <Route index element={<Level1WRSIndex />} />
        <Route path="declarations">
          <Route path="create" element={<CreateDeclaration />} />
        </Route>
        <Route path="extraction_rights">
          <Route path="create" element={<CreateExtractionRight />} />
          <Route path=":extractionRightId">
            <Route path="edit" element={<UpdateExtractionRight />} />
            <Route path="delete" element={<DeleteExtractionRight />} />
            <Route path="updateStatus" element={<DeleteExtractionRight />} />
          </Route>
        </Route>

        <Route path="extraction_points">
          <Route path="create" element={<Level1WRSCreateExtractionPoint />} />
          <Route path="link_meter" element={<LinkMeter />} />
          <Route path="unlink" element={<UnlinkExtractionPoint />} />
          <Route
            path=":extractionPointId/edit"
            element={<Level1WRSUpdateExtractionPoint />}
          />
        </Route>
        <Route path="subscribers">
          <Route path="create" element={<Level1WRSCreateSubscriber />} />
          <Route
            path=":subscriberId/edit"
            element={<Level1WRSUpdateSubscriber />}
          />
        </Route>
        <Route path="users/create" element={<Level1WRSCreateUser />} />
        <Route path="bulk_meter_upload" element={<BulkMeterUpload />} />
        <Route path="water_classes">
          <Route path="create" element={<CreateWaterClass />} />
          <Route path=":waterClassId/edit" element={<UpdateWaterClass />} />
        </Route>
        <Route path="level0wrs">
          <Route path="create" element={<CreateLevel0WRS />} />
          <Route path=":level0wrsId/edit" element={<UpdateLevel0WRS />} />
        </Route>
        <Route path="meters">
          <Route path="replace" element={<ReplaceMeter />} />
          <Route path="decommission" element={<DecommissionMeter />} />
          <Route
            path=":meterId/replace_capsule"
            element={<ReplaceMeterCapsule />}
          />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Level1WRSRoutes;

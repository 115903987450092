import { useTranslation } from "react-i18next";

import Login from "@components/pages/Login";
import AuthLayout from "@components/layout/AuthLayout";

export default function PolestarLogin() {
  const { t } = useTranslation();

  return (
    <AuthLayout>
      <div className="flex min-h-full flex-col justify-center bg-primary-2 rounded-lg">
        <div className="sm:mx-auto w-full px-6 py-10 text-white text-xl text-center">
          {t("auth.login_title")}
        </div>

        <div className="sm:mx-auto w-full">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="py-8 px-4 sm:px-10">
              <Login />
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

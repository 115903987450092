import { useQuery } from "@tanstack/react-query";

import { getExtractionRight } from "@services/extractionRight";

export const useExtractionRight = (
  id: string,
  options: Record<string, any> = {}
) => {
  return useQuery<any>({
    queryKey: ["extractionRights", id],
    queryFn: () => getExtractionRight(id),
    enabled: !!id,
    ...options,
  });
};

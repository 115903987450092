import React from "react";
import sumBy from "lodash/sumBy";
import { useTranslation } from "react-i18next";

import AreaChart from "./AreaChart";
import Stats, { type Stat } from "./Strats";
import { getMonthlyData, getYearlyData } from "./mockData";

const monthlyData = getMonthlyData();
const yearlyData = getYearlyData();

const TemporaryTradeChart = () => {
  const { t } = useTranslation();
  const [data, setData] = React.useState([...monthlyData]);

  const totalVolume = sumBy(data, (i: any) => i.value).toLocaleString();

  const stats: Stat[] = [
    {
      name: `${t("zenith.exchanges.chart.average_price")} (${t(
        "common.currency"
      )}/${t("common.volume_unit")})`,
      value: `${t("common.currency")}56.25`,
      change: "+6.82%",
      changeType: "positive",
    },
    {
      name: `${t("zenith.exchanges.chart.total_volume")} (${t(
        "common.currency"
      )}/${t("common.volume_unit")})`,
      value: `${totalVolume} ${t("common.volume_unit")}`,
      change: "+14.02%",
      changeType: "positive",
    },
  ];

  return (
    <div className="relative rounded-lg overflow-hidden bg-white shadow-sm">
      <AreaChart
        title={t("zenith.exchanges.chart.temporary", {
          level0ResourceName: "CLV",
        })}
        data={data}
        onChange={(e) => {
          setData(e.target.value === "monthly" ? monthlyData : yearlyData);
        }}
      />

      <Stats data={stats} />
    </div>
  );
};

export default TemporaryTradeChart;

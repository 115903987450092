import classNames from "classnames";
import { useEffect, useState } from "react";
type StackScaleProps = {
  className?: string;
  legends: Array<{
    title: string;
    color: string;
  }>;
  dataset: Array<{
    title: string;
    values: Array<{
      legend: string;
      value: number;
    }>;
  }>;
};
const StackScale: React.FunctionComponent<StackScaleProps> = ({
  className,
  legends,
  dataset,
}) => {
  const [highestValue, setHighestValue] = useState(0);
  const [scales, setScales] = useState<number[]>([]);

  useEffect(() => {
    let newHighest = 0;
    let newScales = [];

    for (const data of dataset) {
      for (const value of data.values) {
        if (value.value > newHighest) {
          newHighest = value.value;
        }
      }
    }
    if (newHighest) {
      for (let i = newHighest; i >= 0; i -= newHighest / 4) {
        const tempScale = parseFloat(i.toFixed(3));
        if (tempScale !== 0) newScales.push(parseFloat(i.toFixed(3)));
      }
    }
    newScales.push(0);
    setScales(newScales);
    setHighestValue(newHighest);
  }, [dataset]);

  return (
    <div className="flex flex-col p-5">
      <div className="flex justify-end flex-wrap gap-4">
        {legends.map((l, index) => (
          <div
            key={`${l.title}--${index}`}
            className="text-sm text-gray-400 flex gap-2 items-center"
          >
            <p
              className="w-5 h-5 rounded-full"
              style={{ backgroundColor: l.color }}
            ></p>{" "}
            {l.title}
          </div>
        ))}
      </div>
      <div
        className={classNames(
          "p-2 flex justify-center items-center",
          className
        )}
      >
        <div className="flex flex-col justify-between pr-10 pb-4 h-full flex-auto">
          {scales.map((s, index) => (
            <div
              className="text-sm text-gray-400 text-right"
              key={`scale--${index}`}
            >
              {s}
            </div>
          ))}
        </div>
        <div className="w-full h-full flex justify-between">
          {dataset?.map((data: any, i: number) => (
            <div
              className="flex flex-col justify-between items-start"
              key={`column--${i}`}
            >
              <div className="bg-transparent relative h-full rounded-lg">
                {data.values
                  .sort((a: any, b: any) => b.value - a.value)
                  .map((value: any, idx: number) => (
                    <div
                      className="absolute rounded-lg bottom-0 w-3 cursor-pointer"
                      style={{
                        backgroundColor: legends.find(
                          (l) => l.title === value.legend
                        )?.color,
                        height: `${(value.value * 100) / highestValue}%`,
                      }}
                      title={`${value.legend}: ${value.value}`}
                      key={`bar--${idx}`}
                    >
                      &nbsp;
                    </div>
                  ))}
              </div>
              <div className="text-gray-400 text-sm flex-shrink">
                {data.title}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default StackScale;

import { noop } from "lodash";
import { twMerge } from "tailwind-merge";

const TabMenu: React.FunctionComponent<{
  children: React.ReactNode;
  isActive: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}> = ({ children, isActive, onClick = noop }) => {
  return (
    <button
      className={twMerge(
        "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm",
        isActive
          ? "border-primary-1 text-primary-1"
          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default TabMenu;

import React from "react";
import pickBy from "lodash/pickBy";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

import { StepProvider, useStepContext } from "@context/shared/StepContext";

import { useSignUp } from "@hooks/mutation/zenith/useSignUp";

import SignUpSubscriberForm from "@components/form/zenith/signup/SignUpSubscriberForm";
import SignUpAddressForm from "@components/form/zenith/signup/SignUpAddressForm";
import SignUpUserForm from "@components/form/zenith/signup/SignUpUserForm";
import BaseSignUpForm from "@components/form/zenith/signup/BaseSignUpForm";
import { toastError, toastSuccess } from "@utils/toast";
import { isValidationError } from "@utils/formError";
import Alert from "@components/shared/Alert";

const SignUp = () => {
  return (
    <StepProvider maxStep={5}>
      <Consumer />
    </StepProvider>
  );
};

const levels = ["free", "starter", "pro", "plus"].map((val) => {
  return {
    value: val,
    label: t(`zenith.signup.levels.${val}.label`),
    description: t(`zenith.signup.levels.${val}.description`),
  };
});

const Consumer = () => {
  const navigate = useNavigate();
  const { stepHelpers } = useStepContext();
  const [formData, setFormData] = React.useState({
    subscriber: {
      level1ResourceName: "",
      name: "",
      abn: "",
    },
    address: {
      street: "",
      town: "",
      region: "",
      postcode: "",
      country: "",
    },
    user: {
      name: "",
      email: "",
      tel: "",
      password: "",
      confirmPassword: "",
    },
  });
  const [selectLevel, setSelectLevel] = React.useState(() => levels[0].value);
  const [agree, setAgree] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState<string[]>([]);

  const { mutateAsync: signUpMutation, isLoading: isSignUpLoading } =
    useSignUp();

  const hasAddress = (address: Record<string, any>) => {
    let result = false;
    for (const [, value] of Object.entries(address)) {
      if (value) {
        result = true;
      }
    }
    return result;
  };

  const handleNextStep = (key: keyof typeof formData) => (values: any) => {
    setFormData((state) => ({
      ...state,
      [key]: {
        ...state[key],
        ...values,
      },
    }));

    stepHelpers.goToNextStep();
  };

  const handleSignUp = async () => {
    const { subscriber, address, user } = formData;
    try {
      const newUser = await signUpMutation({
        subscriber: {
          ...subscriber,
        },
        user,
        address: hasAddress(address) ? pickBy(address, Boolean) : undefined,
      });

      toastSuccess(t("zenith.signup.toast.success"));

      navigate("../confirm_signup", {
        state: {
          email: newUser.email,
        },
      });
    } catch (error: any) {
      const { data } = error?.response;
      const messages = isValidationError(error)
        ? data?.errors.map((i: any) => i.message)
        : [data?.message];
      setErrorMessages(messages);
      toastError(
        t("zenith.signup.toast.failure", {
          error: error?.response?.data?.message || error?.message,
        })
      );
      stepHelpers.setStep(0);
    }
  };

  return (
    <div>
      {errorMessages?.length ? (
        <Alert type="error" className="mb-6" closeButton>
          <ul className="pl-2 list-disc list-inside">
            {errorMessages.map((message) => (
              <li key={message}>{message}</li>
            ))}
          </ul>
        </Alert>
      ) : null}

      <SignUpSubscriberForm
        values={formData.subscriber}
        onSubmit={handleNextStep("subscriber")}
      />

      <SignUpAddressForm
        values={formData.address}
        onSubmit={handleNextStep("address")}
      />

      <SignUpUserForm
        values={formData.user}
        onSubmit={handleNextStep("user")}
      />

      {/* Subscription Plan */}
      <BaseSignUpForm
        title={t("zenith.signup.select_level")}
        step={3}
        onSubmit={stepHelpers.goToNextStep}
      >
        {levels.map((level, index) => {
          return (
            <div className="relative flex items-start" key={level.value}>
              <div className="flex h-6 items-center">
                <input
                  type="radio"
                  name="level"
                  id={level.value}
                  value={level.value}
                  checked={selectLevel === level.value}
                  onChange={(e) => {
                    setSelectLevel(e.target.value);
                  }}
                  aria-describedby={`${level.value}-description`}
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor={level.value}>{level.label}</label>
                <p id={`${level.value}-description`} className="text-gray-500">
                  {level.description}
                </p>
              </div>
            </div>
          );
        })}
      </BaseSignUpForm>

      {/* Agreement */}
      <BaseSignUpForm
        onSubmit={handleSignUp}
        title={t("zenith.signup.term.title")}
        step={4}
        submitText={t("auth.signup")}
        isSubmitting={isSignUpLoading}
      >
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              type="checkbox"
              id="agree"
              aria-describedby="agree-description"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              checked={agree}
              onChange={(e) => {
                setAgree(e.target.checked);
              }}
              required
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label htmlFor="agree">{t("zenith.signup.term.agree")}</label>
            <p id="agree-description" className="text-gray-500">
              {t("zenith.signup.term.description")}{" "}
              <a
                className="text-primary-2 hover:underline"
                href="https://waterledger.com"
                target="_blank"
                rel="noopener noreferrer"
                title={t("zenith.signup.term.title") as string}
              >
                {`${t("zenith.signup.term.title")}`.toLowerCase()}
              </a>
            </p>
          </div>
        </div>
      </BaseSignUpForm>
    </div>
  );
};

export default SignUp;

const WaterCircleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <rect width="30" height="30" fill="#004A88" rx="15"></rect>
      <path
        fill="url(#paint0_linear_834_34392)"
        d="M14.518 9.67a.77.77 0 01.964 0c.586.444 1.302.747 2.018.747.701 0 1.444-.309 2.017-.747a.772.772 0 011.02.049c.376.34.847.601 1.319.721.448.115.726.607.622 1.1-.104.493-.552.8-1 .685a4.743 4.743 0 01-1.516-.716c-.755.446-1.602.741-2.462.741-.83 0-1.578-.283-2.094-.54a8.29 8.29 0 01-.406-.222 5.749 5.749 0 01-.406.221c-.516.258-1.264.541-2.095.541-.86 0-1.706-.295-2.461-.739-.349.241-.878.553-1.516.716-.448.115-.896-.191-1-.684-.104-.493.174-.985.622-1.1A3.213 3.213 0 009.46 9.72a.775.775 0 011.02-.05c.58.439 1.319.748 2.02.748.716 0 1.432-.303 2.018-.747zm.964 8.248c.586.444 1.302.748 2.018.748.701 0 1.444-.31 2.017-.748a.772.772 0 011.02.05c.376.34.847.6 1.319.72.448.115.726.608.622 1.1-.104.493-.552.8-1 .685a4.738 4.738 0 01-1.516-.716c-.755.447-1.602.742-2.462.742-.83 0-1.578-.284-2.094-.541a8.295 8.295 0 01-.406-.22 5.792 5.792 0 01-.406.22c-.516.257-1.264.54-2.095.54-.86 0-1.706-.294-2.461-.738-.349.24-.878.553-1.516.716-.448.115-.896-.192-1-.684-.104-.493.174-.986.622-1.1.472-.12.943-.381 1.318-.722a.778.778 0 011.021-.049c.576.436 1.316.745 2.016.745.717 0 1.433-.304 2.02-.748a.769.769 0 01.963 0zm0-4.124c.586.444 1.302.748 2.018.748.701 0 1.444-.31 2.017-.748a.772.772 0 011.02.049c.376.34.847.601 1.319.722.448.114.726.607.622 1.1-.104.492-.552.798-1 .684a4.74 4.74 0 01-1.516-.716c-.755.447-1.602.742-2.462.742-.83 0-1.578-.284-2.094-.542a8.295 8.295 0 01-.406-.22 5.75 5.75 0 01-.406.22c-.516.258-1.264.542-2.095.542-.86 0-1.706-.295-2.461-.74-.349.241-.878.554-1.516.717-.448.114-.896-.192-1-.685-.104-.492.174-.985.622-1.1.472-.12.943-.38 1.318-.721a.775.775 0 011.021-.049c.576.435 1.316.745 2.016.745.717 0 1.433-.304 2.02-.748a.769.769 0 01.963 0z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_834_34392"
          x1="7.918"
          x2="24.078"
          y1="9.5"
          y2="16.727"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F3F4F6"></stop>
          <stop offset="0.339" stopColor="#fff"></stop>
          <stop offset="0.693" stopColor="#F3F4F6"></stop>
          <stop offset="1" stopColor="#fff"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default WaterCircleIcon;

import { useMutation, useQuery } from "@tanstack/react-query";
import { formatDistance } from "date-fns";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { toast } from "react-toastify";
import { useAppContext } from "@context/AppContext";
import { addSubscriberNote, getSubscriberNotes } from "@services/subscriber";
import Card from "@components/layout/Card";
import ENV from "@config/env";
import TextArea from "@components/form/TextArea";
import Modal from "@components/layout/Modal";
import ProcessingIcon from "@components/icons/ProcessingIcon";
import Heading1 from "@components/layout/Heading";

type SubscriberNotesProps = {
  subscriberId: string;
};

const SubscriberNotes: React.FunctionComponent<SubscriberNotesProps> = ({
  subscriberId,
}) => {
  const { loggedInInfo } = useAppContext();
  const { t } = useTranslation();
  const [note, setNote] = useState("");
  const [attachment, setAttachment] = useState<File>();
  const { data: subscriberNotes, refetch: getNotes } = useQuery(
    ["subscriberNotes", subscriberId],
    () => getSubscriberNotes({ subscriberId }),
    {
      keepPreviousData: true,
    }
  );
  const {
    mutateAsync: addNote,
    isLoading,
    isError,
  } = useMutation<any, any>(
    () =>
      addSubscriberNote({
        subscriberId,
        note,
        attachment,
      }),
    {
      onSuccess: () => {
        setNote("");
        setAttachment(undefined);

        toast.success(t("subscriber.save_note_succeed"), {
          className: "toast toast-success",
        });

        getNotes();
      },
    }
  );

  const handleAddNote = async (e: any) => {
    e.preventDefault();

    await addNote();
  };

  return (
    <>
      <Card className="border-none shadow-none rounded-none px-0">
        <div className="flex flex-col ">
          <ul className="border-b border-gray-200 divide-y divide-gray-200">
            {subscriberNotes?.map((note: any) => (
              <li key={note.id} className="py-4 px-6">
                <div className="flex space-x-3">
                  <div className="flex justify-center items-center rounded-full w-8 h-8 bg-blue-800 text-white text-base text-center uppercase font-extrabold focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    {note.postedByUser.name[0]}
                  </div>
                  <div className="flex-1 space-y-3">
                    <h3 className="text-sm text-gray-500">
                      {note.postedByUser.name}
                    </h3>
                    <p className="text-sm whitespace-pre-wrap">{note.note}</p>
                    {note.evidences?.[0] && (
                      <a
                        href={`${ENV.API_HOST}/evidences/${note.evidences?.[0]?.id}/download`}
                        className="text-sm text-gray-500"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("subscriber.note_evidence", {
                          file: note.evidences?.[0]?.fileName,
                        })}
                      </a>
                    )}
                    <p className="text-sm text-gray-500">
                      {t("subscriber.note_posted_at")}{" "}
                      {formatDistance(new Date(note.createdAt), new Date(), {
                        addSuffix: true,
                      })}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <form className="flex px-6 py-4 gap-2" onSubmit={handleAddNote}>
            <div className="flex justify-center items-center rounded-full w-8 h-8 bg-gray-800 text-white text-base text-center uppercase font-extrabold focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
              {loggedInInfo?.userDetails?.name[0]}
            </div>
            <div className="flex flex-col gap-2 flex-grow">
              <TextArea
                required
                value={note}
                onChange={(e) => setNote(e.target.value)}
                errorMessage={
                  isError ? (t("subscriber.add_note_error") as string) : ""
                }
              />
              <div className="flex w-full gap-1 items-center">
                <button
                  type="submit"
                  className="btn-secondary text-sm"
                  disabled={isLoading}
                >
                  {t("subscriber.add_note")}
                </button>
                {attachment ? (
                  <>
                    <button
                      type="button"
                      className="btn-secondary text-sm"
                      onClick={() => setAttachment(undefined)}
                      disabled={isLoading}
                    >
                      {t("subscriber.remove_attachment", {
                        name: attachment.name,
                      })}
                    </button>
                  </>
                ) : (
                  <>
                    <label
                      htmlFor="note-attachment"
                      className="btn-secondary text-sm"
                    >
                      {t("subscriber.add_attachment")}
                    </label>
                    <input
                      type="file"
                      id="note-attachment"
                      className="opacity-0"
                      onChange={(e) => setAttachment(e.target.files?.[0])}
                      disabled={isLoading}
                    />
                  </>
                )}
              </div>
            </div>
          </form>
        </div>
      </Card>
      <Modal open={isLoading}>
        <div className="py-8 px-4 flex items-center gap-4">
          <div className="mx-auto flex h-12 w-12">
            <ProcessingIcon />
          </div>
          <Heading1 className="text-xl font-medium leading-6 text-primary-2">
            {t("subscriber.saving_note_message")}
          </Heading1>
        </div>
      </Modal>
    </>
  );
};

export default SubscriberNotes;

import React from "react";

type Props = {
  className?: string;
};

const ModalSuccessIcon: React.FunctionComponent<Props> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M23.5 2.9375C12.1447 2.9375 2.9375 12.1447 2.9375 23.5C2.9375 34.8553 12.1447 44.0625 23.5 44.0625C34.8553 44.0625 44.0625 34.8553 44.0625 23.5C44.0625 12.1447 34.8553 2.9375 23.5 2.9375ZM23.5 40.5742C14.0725 40.5742 6.42578 32.9275 6.42578 23.5C6.42578 14.0725 14.0725 6.42578 23.5 6.42578C32.9275 6.42578 40.5742 14.0725 40.5742 23.5C40.5742 32.9275 32.9275 40.5742 23.5 40.5742Z"
        fill="#10B981"
      />
      <path
        opacity="0.1"
        d="M23.5 6.42578C14.0725 6.42578 6.42578 14.0725 6.42578 23.5C6.42578 32.9275 14.0725 40.5742 23.5 40.5742C32.9275 40.5742 40.5742 32.9275 40.5742 23.5C40.5742 14.0725 32.9275 6.42578 23.5 6.42578ZM32.3768 16.7851L22.7105 30.1874C22.5754 30.376 22.3973 30.5296 22.191 30.6356C21.9847 30.7416 21.756 30.7969 21.5241 30.7969C21.2921 30.7969 21.0635 30.7416 20.8571 30.6356C20.6508 30.5296 20.4727 30.376 20.3376 30.1874L14.6187 22.2562C14.4442 22.0129 14.6187 21.6732 14.917 21.6732H17.0696C17.5424 21.6732 17.983 21.9027 18.2584 22.2837L21.5264 26.8185L28.7416 16.8126C29.017 16.4271 29.4622 16.2021 29.9304 16.2021H32.083C32.3813 16.2021 32.5558 16.5418 32.3768 16.7851Z"
        fill="#FFF1F0"
      />
      <path
        d="M32.0812 16.2021H29.9286C29.4604 16.2021 29.0152 16.4271 28.7398 16.8126L21.5245 26.8185L18.2566 22.2837C17.9812 21.9027 17.5405 21.6732 17.0678 21.6732H14.9151C14.6168 21.6732 14.4424 22.0129 14.6168 22.2562L20.3358 30.1874C20.4709 30.376 20.649 30.5296 20.8553 30.6356C21.0616 30.7416 21.2903 30.7969 21.5222 30.7969C21.7542 30.7969 21.9828 30.7416 22.1892 30.6356C22.3955 30.5296 22.5736 30.376 22.7087 30.1874L32.375 16.7851C32.554 16.5418 32.3795 16.2021 32.0812 16.2021Z"
        fill="#52C41A"
      />
    </svg>
  );
};

export default ModalSuccessIcon;

import React from "react";
import { useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import Tag from "@components/shared/Tag";
import Heading from "@components/layout/Heading";
import SearchInput from "@components/form/SearchInput";
import Label from "@components/form/Label";
import { useAllMeters } from "@hooks/query/useAllMeters";
import { formatVolume } from "@utils/formatVolume";

type MeterTableProps = {
  title: string;
  value?: {
    id: string;
    serialNo: string;
  };
  onChange?: (value: any) => void;
  children?: React.ReactNode;
  disableSelection?: boolean;
};

const MeterTable: React.FunctionComponent<MeterTableProps> = ({
  title,
  value = {
    id: "",
    serialNo: "",
  },
  onChange,
  children,
  disableSelection = false,
}) => {
  const { t } = useTranslation();
  const [filter, setFilter] = React.useState("");
  const { data: meters = [] } = useAllMeters({
    params: {
      extractionPointId: -1,
      isActive: false,
      isDelete: false,
    },
  });

  const tableFields = [
    ...(onChange
      ? [
          {
            title: "",
            name: "radio",
          },
        ]
      : []),
    {
      title: t("meter.serial_no"),
      name: "serialNo",
    },
    {
      title: t("common.status"),
      name: "status",
    },
    {
      title: t("common.type"),
      name: "type",
    },
    {
      title: t("meter.click_over"),
      name: "clickOver",
    },
  ];

  const tableData = meters.map((item: any) => {
    return {
      ...item,
      ...(onChange && {
        radio: (
          <input
            type="radio"
            name="meter"
            onChange={() =>
              onChange({
                id: item.id,
                serialNo: item.serialNo,
              })
            }
            checked={item.id === value?.id}
            disabled={disableSelection}
          />
        ),
      }),
      status: (
        <Tag status={item.isActive ? "success" : "error"}>
          {item.isActive ? t("common.active") : t("common.inactive")}
        </Tag>
      ),
      clickOver: formatVolume(+item.clickOver),
    };
  });

  const filterData = tableData.filter((item: any) => {
    return item.serialNo.includes(filter);
  });

  return (
    <div className="p-6 flex flex-col grow space-y-6">
      <div className="flex flex-row justify-between items-center">
        {title && <Heading light>{title}</Heading>}
        {children}
      </div>
      <div className="max-w-sm">
        <Label htmlFor="serialNo">{t("meter.filter_serial_no")}</Label>
        <SearchInput
          id="serialNo"
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>
      <Table fields={tableFields} data={filterData} stickyHeader />
    </div>
  );
};

export default MeterTable;

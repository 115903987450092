import { useQuery } from "@tanstack/react-query";
import { getAdministrativeApproval } from "@services/administrativeApprovals";

export const useGetAdministrativeApproval = (
  id: string,
  options?: Record<string, any>,
) => {
  return useQuery<any>({
    queryKey: ["administrative_approvals", id],
    queryFn: () => getAdministrativeApproval(id),
    enabled: Boolean(id),
    ...options,
  });
};

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isFunction } from "lodash";

import { updateUser } from "@services/user";

export const useUpdateUser = ({
  onSuccess,
  ...options
}: Record<string, any> = {}) => {
  const queryClient = useQueryClient();

  return useMutation(updateUser, {
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      if (isFunction(onSuccess)) {
        onSuccess(res);
      }
    },
    ...options,
  });
};

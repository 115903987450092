import React from "react";
import { useTranslation } from "react-i18next";

import SelectSubscriber from "./SelectSubscriber";
import { usePermanentTransferContext } from "@context/PermanentTransferContext";
import { useStepContext } from "@context/shared/StepContext";

type SelectBuyerProps = {
  subscribers: any;
};

const SelectBuyer: React.FunctionComponent<SelectBuyerProps> = ({
  subscribers,
}) => {
  const { t } = useTranslation();
  const { stepHelpers } = useStepContext();
  const { buyer, setBuyer, handleCancel, canTransferPoints } =
    usePermanentTransferContext();

  return (
    <>
      <SelectSubscriber
        title={t("permanent_trade.select_buyer")}
        onSelect={(value: any) => {
          setBuyer({
            id: value.id,
            name: value.name,
            accountNumber: value.accountNumber,
            walletId: value.walletId,
          });
        }}
        selected={buyer}
        subscribers={subscribers}
      />

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={() => {
            stepHelpers.goToPrevStep();

            if (!canTransferPoints) {
              stepHelpers.goToPrevStep();
            }
          }}
        >
          {t("common.prev_step")}
        </button>
        <button
          type="button"
          className="btn-primary"
          onClick={() => {
            stepHelpers.goToNextStep();
          }}
          disabled={!buyer.id}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={handleCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </>
  );
};

export default SelectBuyer;

import { formatDate } from "./formatDate";

export const formatAccountingPeriod = (accountingPeriod: {
  periodStart: string;
  periodEnd: string;
}) => {
  if (!accountingPeriod?.periodStart || !accountingPeriod?.periodEnd) {
    return "-";
  }

  return `${formatDate(new Date(accountingPeriod.periodStart))} → ${formatDate(new Date(accountingPeriod.periodEnd))}`;
};

import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { formatVolume } from "../../../utils/formatVolume";
import Card from "../../layout/Card";
import LineScale from "../../layout/LineScale";

type Level0WRSWidgetProps = {
  level0Resource: any;
};

const Level0WRSWidget: React.FunctionComponent<Level0WRSWidgetProps> = ({
  level0Resource,
}) => {
  const { t } = useTranslation();
  const [noOfCustomers, setNoOfCustomers] = useState(0);

  useEffect(() => {
    const subsciberWallets = new Set();

    if (level0Resource) {
      level0Resource.extractionRights.forEach((entitlement: any) =>
        subsciberWallets.add(entitlement.assertedByWalletId)
      );

      setNoOfCustomers(subsciberWallets.size);
    }
  }, [level0Resource]);

  return (
    <Card
      className="shadow-none w-full"
      header={<h4 className="text-gray-900 -mb-4">{level0Resource?.name}</h4>}
    >
      <div className="flex flex-col gap-2">
        <h1 className="flex gap-1 text-3xl items-baseline">
          {noOfCustomers}{" "}
          <span className="text-sm">{t("level1wrs.subscribers")}</span>
        </h1>
        <ul className="flex flex-col gap-2 mt-2">
          {level0Resource?.waterClasses?.map((waterClass: any) => (
            <li className="flex flex-col" key={waterClass.id}>
              <div className="flex justify-between text-sm">
                <p>{waterClass.name}</p>
                <p className="text-green-500">
                  {formatVolume(
                    waterClass.extractionHistory?.walletAccount?.h2WBalance || 0
                  )}
                </p>
              </div>
              <LineScale
                scale={
                  waterClass.extractionHistory?.walletAccount?.h2WBalance /
                  waterClass.volume
                }
                bgColor="#E2FBD7"
                borderColor="#34B53A"
              />
            </li>
          ))}
        </ul>
      </div>
    </Card>
  );
};

export default Level0WRSWidget;

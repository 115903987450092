import { useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import TabPanels from "@components/form/TabPanels";
import InfoPanel from "@components/form/InfoPanel";
import UserTable from "@components/table/UserTable";
import SubscriberTable from "@components/table/SubscriberTable";
import { useAppContext } from "@context/AppContext";

type Props = {
  level1Resource: any;
};

const Level1WRSSubscribersAndUsers: React.FunctionComponent<Props> = ({
  level1Resource,
}) => {
  const { checkPermissions } = useAppContext();
  const { t } = useTranslation();
  const { id: level1ResourceId = "" } = useParams();

  return (
    <div className="flex grow gap-4">
      <div className="grow">
        <TabPanels
          className="bg-white border"
          tabs={[
            {
              title: t("level1wrs.subscribers"),
              panel: (
                <SubscriberTable
                  level1Resource={level1Resource}
                  showActions
                  showView
                />
              ),
              tabInfoPanel: (
                <InfoPanel className="h-full">
                  <ul className="flex flex-col gap-5 list-disc list-outside">
                    <li>
                      <Trans
                        i18nKey="subscriber.info.customer.dashboard"
                        values={{
                          level1ResourceName: level1Resource?.name,
                        }}
                      />
                    </li>
                    <li>{t("subscriber.info.customer.create")}</li>
                    <li>{t("subscriber.info.customer.update")}</li>
                  </ul>
                </InfoPanel>
              ),
            },
            ...(checkPermissions(["ViewWaterClasses"])
              ? [
                  {
                    title: t("common.users"),
                    panel: <UserTable level1ResourceId={level1ResourceId} />,
                    tabInfoPanel: (
                      <InfoPanel className="h-full">
                        <ul className="flex flex-col gap-5 list-disc list-outside">
                          <li>
                            <Trans
                              i18nKey="subscriber.info.user.dashboard"
                              values={{
                                level1ResourceName: level1Resource?.name,
                              }}
                            />
                          </li>
                          <li>{t("subscriber.info.user.create")}</li>
                          <li>{t("subscriber.info.user.update")}</li>
                        </ul>
                      </InfoPanel>
                    ),
                  },
                ]
              : []),
          ]}
        />
      </div>
    </div>
  );
};

export default Level1WRSSubscribersAndUsers;

import { useQuery } from "@tanstack/react-query";
import { getLevel1Resource } from "@services/level1Resource";

export const useGetLevel1Resource = (
  id: string,
  options?: Record<string, any>
) => {
  return useQuery({
    queryKey: ["level1Resources", id],
    queryFn: () => getLevel1Resource(id),
    enabled: Boolean(id),
    ...options,
  });
};

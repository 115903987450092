import { useQuery } from "@tanstack/react-query";

import { getAllLevel3Resources } from "@services/level3Resource";

type UseAllLevel3ResourcesParams = {
  params: Parameters<typeof getAllLevel3Resources>[0];
  [key: string]: any;
};

export const useAllLevel3Resources = ({
  params,
  ...options
}: Partial<UseAllLevel3ResourcesParams> = {}) => {
  return useQuery<any>({
    queryKey: ["level3Resources", params],
    queryFn: () => getAllLevel3Resources(params),
    ...options,
  });
};

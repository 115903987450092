import ENV from "@config/env";
import GoogleMapReact from "google-map-react";
import { FunctionComponent, useEffect, useState } from "react";

type GoogleMapProps = {
  zoomLevel?: number;
  zoomControl?: boolean;
  center?: { lat: number; lng: number };
  markers?: Array<{ lat: number; lng: number }>;
  onClick?: (e: { lat: number; lng: number }) => void;
  onMarkerClick?: (e: { lat: number; lng: number }) => void;
};

const libraries = ["places"];

const GoogleMap: FunctionComponent<GoogleMapProps> = ({
  center,
  markers,
  zoomLevel = 13,
  zoomControl = true,
  onClick,
  onMarkerClick,
}) => {
  const [map, setMap] = useState<any>(null);
  const [mapsApi, setMapsApi] = useState<any>(null);
  const [renderedMarkers, setRenderedMarkers] = useState<Array<any>>([]);

  useEffect(() => {
    if (map && mapsApi && markers?.length) {
      for (let marker of renderedMarkers) {
        marker.setMap(null);
      }
      setRenderedMarkers([]);

      let newRenderedMarkers = [];
      for (const marker of markers) {
        const newMarker = new mapsApi.Marker({
          position: { lat: marker.lat, lng: marker.lng },
          map,
          onClick: () => {},
        });
        newMarker.addListener("click", (e: any) => {
          if (onMarkerClick) {
            onMarkerClick({ lat: e.latLng.lat(), lng: e.latLng.lng() });
          }
        });
        newRenderedMarkers.push(newMarker);
      }

      setRenderedMarkers(newRenderedMarkers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, mapsApi, markers]);

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: `${ENV.GOOGLE_MAPS_API_KEY}`,
        libraries,
      }}
      yesIWantToUseGoogleMapApiInternals={true}
      defaultCenter={{
        lat: center?.lat ?? 0,
        lng: center?.lng ?? 0,
      }}
      defaultZoom={zoomLevel}
      onGoogleApiLoaded={({ map, maps }) => {
        setMap(map);
        setMapsApi(maps);
      }}
      options={{ fullscreenControl: false, zoomControl }}
      onClick={(e) => {
        if (onClick) onClick(e);
      }}
    />
  );
};

export default GoogleMap;

import { useTranslation } from "react-i18next";

import InformationCircleIcon from "@components/icons/InformationCircleIcon";

const BoundarySampleInformation = () => {
  const { t } = useTranslation();

  return (
    <>
      <ul className="pl-2 space-y-2">
        <li>
          <strong>{t("information.sample_location.option1_title")}</strong>{" "}
          {t("information.sample_location.option1_description")}
        </li>
        <li>
          <strong>{t("information.sample_location.option2_title")}</strong>{" "}
          {t("information.sample_location.option2_description")}
        </li>
      </ul>
      <div className="flex items-center py-2 pt-10 gap-2">
        <InformationCircleIcon className="text-blue-600 w-5 h-5 shrink-0" />
        <div className="text-sm bg-tray-200 rounded-md">
          {t("information.sample_location.sample_file")}{" "}
          <strong>{t("information.sample_location.sample_file_name")}</strong>
        </div>
        <button className="text-blue-500">{t("common.copy")}</button>
      </div>
      <textarea
        className="w-full h-64 bg-gray-100 p-4 font-mono text-xs leading-relaxed"
        typeof="application/json"
        defaultValue={`{ "type": "FeatureCollection",
"features": [
{ "type": "Feature",
  "geometry": {"type": "Point", "coordinates": [102.0, 0.5]},
  "properties": {"prop0": "value0"}
  },
{ "type": "Feature",
  "geometry": {
    "type": "LineString",
    "coordinates": [
      [102.0, 0.0], [103.0, 1.0], [104.0, 0.0], [105.0, 1.0]
      ]
    },
  "properties": {
    "prop0": "value0",
    "prop1": 0.0
    }
  },
{ "type": "Feature",
   "geometry": {
     "type": "Polygon",
     "coordinates": [
       [ [100.0, 0.0], [101.0, 0.0], [101.0, 1.0],
         [100.0, 1.0], [100.0, 0.0] ]
       ]

   },
   "properties": {
     "prop0": "value0",
     "prop1": {"this": "that"}
     }
   }
]
}`}
        readOnly
      />
    </>
  );
};

export default BoundarySampleInformation;

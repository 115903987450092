import React from "react";
import { useTranslation } from "react-i18next";

import { useAdminTransferContext } from "@context/AdminTransferContext";
import Heading1 from "@components/layout/Heading";
import SelectSingleExtractionRightTable from "@components/table/SelectSingleExtractionRightTable";

const AdminTransferSelectExtractionRight = () => {
  const { t } = useTranslation();
  const { stepHelpers, details, handleChangeDetails, navigateForCancel } =
    useAdminTransferContext();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    stepHelpers.goToNextStep();
  };

  return (
    <form
      className="flex flex-col gap-6 p-6 grow justify-between"
      onSubmit={handleSubmit}
    >
      <div className="space-y-4">
        <Heading1 light>
          {t("approval.admin_transfer.create.step_3.select_right")}
        </Heading1>
      </div>

      <SelectSingleExtractionRightTable
        onSelect={(item: any) => {
          handleChangeDetails("extractionRight", item);
          handleChangeDetails("extractionPoint", { id: "" });
        }}
        level1ResourceId={details.level1wrs?.id!}
        subscriberId={details.subscriber?.id!}
        selected={details.extractionRight}
      />

      <footer className="flex gap-4 -mx-2 mt-2 p-6 pb-0 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>

        <button
          type="button"
          className="btn-primary"
          onClick={() => stepHelpers.goToNextStep()}
          disabled={details.extractionRight?.id === ""}
        >
          {t("common.next_step")}
        </button>

        <button
          type="button"
          className="btn-outline-primary"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default AdminTransferSelectExtractionRight;

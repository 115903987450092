import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import BoundarySelected from "@components/shared/BoundarySelected";
import ConfirmationDetail from "@components/shared/ConfirmationDetail";
import ConfirmModal from "@components/shared/ConfirmModal";
import { useCreateLevel1WRSContext } from "@context/CreateLevel1WRSContext";
import { useCreateLevel1WRS } from "@hooks/mutation/useCreateLevel1WRS";
import { toastError, toastSuccess } from "@utils/toast";
import { useEvidenceContext } from "@context/shared/EvidenceContext";
import MapView from "@components/shared/MapView";

const Level1WRSLedger = () => {
  const { t } = useTranslation();
  const {
    stepHelpers,
    details,
    locationCoordinates,
    photoFiles,
    setWorkflowCompleted,
    handleChangeDetails,
    setNetworkErrors,
    currentStep,
    getLevel1WRSDetails,
    handleCancel,
    setWorkflowInstance,
  } = useCreateLevel1WRSContext();
  const { getEvidencesInfo } = useEvidenceContext();
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const { mutateAsync: createLevel1WRSMutation, isLoading } =
    useCreateLevel1WRS();

  useEffect(() => {
    setNetworkErrors([]);
  }, [currentStep, setNetworkErrors]);

  const confirmData = [
    getLevel1WRSDetails(),
    {
      title: t("level1wrs.create.boundary"),
      body: [
        {
          key: "",
          value: (
            <>
              <BoundarySelected selected={details.geography !== ""} />
              {details.geography !== "" && (
                <>
                  {locationCoordinates.lat !== "" ? (
                    <div className="space-y-1.5 h-96 max-w-md">
                      <MapView
                        markers={[
                          {
                            lat: Number(locationCoordinates.lat),
                            lng: Number(locationCoordinates.lng),
                          },
                        ]}
                        zoomLevel={14}
                      />
                    </div>
                  ) : (
                    <div className="space-y-1.5">
                      <strong>{t("location.coordinate_file")}:</strong>{" "}
                      {details.geography}
                    </div>
                  )}
                </>
              )}
            </>
          ),
        },
      ],
    },
    getEvidencesInfo(),
  ];

  const handleOnConfirm = async () => {
    try {
      const { level2WRS, ...rest } = details;
      const [res, workflowInstance] = await createLevel1WRSMutation({
        ...rest,
        periodStart: rest.periodStart!,
        periodEnd: rest.periodEnd!,
        level2ResourceId: level2WRS.id,
        ...(photoFiles?.length > 0 && { image: photoFiles[0] }),
      });

      handleChangeDetails("name", res.name);
      handleChangeDetails("createdAt", res.createdAt);
      handleChangeDetails("id", res.id);

      setWorkflowInstance(workflowInstance);
      setShowConfirmModal(false);
      setWorkflowCompleted(true);
      toastSuccess(t("level1wrs.create.toast.success", { name: res.name }));
    } catch (error: any) {
      setShowConfirmModal(false);
      toastError(
        t("level1wrs.create.toast.failure", {
          error: error?.response?.data?.message || error?.message,
        })
      );
      const errors =
        error?.response?.data?.errors?.reduce(
          (accumulator: any, error: any) => {
            if (error.field) {
              accumulator.push(`${error.field}: ${error.message}`);
            }
            return accumulator;
          },
          []
        ) ?? [];
      setNetworkErrors(errors);
    }
  };

  return (
    <>
      <div className="p-6 space-y-4">
        <Heading light>{t("level1wrs.create.completed")}</Heading>

        <ConfirmationDetail data={confirmData} onEdit={stepHelpers.setStep} />
      </div>

      <div className="grow" />

      <footer className="flex gap-4 mt-6 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>
        <button
          type="button"
          className="btn-primary"
          onClick={() => setShowConfirmModal(true)}
        >
          {t("common.ledger")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={handleCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>

      <ConfirmModal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleOnConfirm}
        isSubmitting={isLoading}
        confirmText={t("common.ledger") as string}
      >
        {t("level1wrs.create.modal.confirm_title")}
      </ConfirmModal>
    </>
  );
};

export default Level1WRSLedger;

import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import Label from "@components/form/Label";
import ReadOnlyInput from "@components/form/ReadOnlyInput";
import SelectSubscriber from "@components/form/SelectSubscriber";
import SelectExtractionPoint from "@components/form/SelectExtractionPoint";
import { useStepContext } from "@context/shared/StepContext";
import { useLinkMeterContext } from "@context/LinkMeterContext";

type ExtractionPointDetailsProps = {
  onCancel: () => void;
};

const ExtractionPointDetails: React.FunctionComponent<
  ExtractionPointDetailsProps
> = ({ onCancel }) => {
  const { t } = useTranslation();
  const { stepHelpers } = useStepContext();
  const { details, setDetails } = useLinkMeterContext();
  const [searchParams] = useSearchParams();
  const subscriberId = searchParams.get("subscriberId") ?? "";
  const extractionPointId = searchParams.get("extractionPointId") ?? "";

  return (
    <form
      className="flex flex-col p-6 grow gap-6"
      onSubmit={(e) => {
        e.preventDefault();

        stepHelpers.goToNextStep();
      }}
    >
      <Heading light>
        {t("extraction_point.link_meter.extraction_point_details")}
      </Heading>

      <fieldset className="space-y-4">
        <div className="max-w-lg">
          <Label htmlFor="subscriber">
            {t("declaration.form.select_subscriber")}
          </Label>
          {subscriberId && details.subscriber.name ? (
            <ReadOnlyInput>{details.subscriber.name}</ReadOnlyInput>
          ) : (
            <SelectSubscriber
              level1ResourceId={details.subscriber.level1ResourceId}
              value={details.subscriber.id}
              onChange={(selected: any) => {
                setDetails((prev: any) => {
                  return {
                    ...prev,
                    subscriber: {
                      id: selected?.value ?? "",
                      name: selected?.label ?? "",
                      walletId: selected?.walletId ?? "",
                    },
                    extractionPoint: {
                      id: "",
                      name: "",
                      level1ResourceId: "",
                    },
                    meter: {
                      id: "",
                      serialNo: "",
                    },
                  };
                });
              }}
              required
            />
          )}
        </div>
        <div className="max-w-lg">
          <Label>{t("declaration.form.select_point")}</Label>
          {extractionPointId && details.extractionPoint.name ? (
            <ReadOnlyInput>{details.extractionPoint.name}</ReadOnlyInput>
          ) : (
            <SelectExtractionPoint
              key={details.extractionPoint.id}
              isActive={true}
              definedByWalletId={details.subscriber.walletId}
              value={details.extractionPoint.id}
              onChange={(selected: any) => {
                setDetails((prev: any) => {
                  return {
                    ...prev,
                    extractionPoint: {
                      id: selected?.id ?? "",
                      name: selected?.value ?? "",
                      level1ResourceId: selected?.level1ResourceId ?? "",
                      isActive: selected?.isActive,
                      oldMeter: {
                        id: selected?.meter?.id ?? "",
                        serialNo: selected?.meter?.serialNo ?? "",
                      },
                    },
                  };
                });
              }}
              isDisabled={!Boolean(details.subscriber.walletId)}
              required
            />
          )}
        </div>
      </fieldset>

      <div className="grow" />

      <footer className="flex gap-4 p-6 pb-0 -mx-6 border-t border-gray-200">
        <button type="submit" className="btn-primary">
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default ExtractionPointDetails;

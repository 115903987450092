import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Layout from "@components/layout/Layout";
import EditMeterWorkflow from "@components/form/meter/EditMeterWorkflow";

const EditMeter = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <Layout
      permissions={["UpdateMeters"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        ...(location?.state?.breadcrumb ?? []),
        {
          label: t("meter.edit.title"),
        },
      ]}
      title={t("meter.edit.title")}
    >
      <EditMeterWorkflow />
    </Layout>
  );
};

export default EditMeter;

import { useParams } from "react-router-dom";

import SubscriberNotificationWidget from "./SubscriberNotificationWidget";
import SubscriberLevel0WRSBalanceWidget from "./Level0WRSBalanceWidget";
import SubscriberDeclarationChartWidget from "./SubscriberDeclarationChartWidget";
import SubscriberAccountHoldersWidget from "./SubscriberAccountHoldersWidget";
import Loading from "@components/shared/Loading";
import { useSubscriber } from "@hooks/query/useSubscriber";

const SubscriberDashboard: React.FunctionComponent = () => {
  const { id: subscriberId = "" } = useParams();
  const { data: subscriber, isLoading } = useSubscriber(subscriberId);

  if (isLoading) {
    return (
      <div className="pt-20">
        <Loading />
      </div>
    );
  }

  return (
    <div className="grid grid-cols-6 gap-4 w-full">
      <div className="col-span-2">
        <SubscriberNotificationWidget walletId={subscriber?.walletId} />
      </div>
      <div className="col-span-4">
        <SubscriberLevel0WRSBalanceWidget
          level1ResourceId={subscriber.level1ResourceId}
          subscriberId={subscriberId}
        />
      </div>
      <div className="col-span-3">
        <SubscriberDeclarationChartWidget subscriberId={subscriberId} />
      </div>
      <div className="col-span-3">
        <SubscriberAccountHoldersWidget subscriberId={subscriberId} />
      </div>
    </div>
  );
};

export default SubscriberDashboard;

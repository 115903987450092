import { useTranslation } from "react-i18next";

import ErrorLayout from "@components/layout/ErrorLayout";
import { getRootPath } from "@utils/getRootPath";

const InternalServerError = () => {
  const { t } = useTranslation();

  return (
    <ErrorLayout
      statusCode="500"
      title={t("error.internal_server.title")}
      description={t("error.internal_server.description")}
    >
      <button
        type="button"
        className="btn-secondary rounded"
        onClick={() => {
          window.location.reload();
        }}
      >
        {t("error.reload")}
      </button>

      <a href={getRootPath(window.location.pathname)}>
        {t("error.go_to_dashboard")}
        <span className="inline-block ml-2" aria-hidden="true">
          &rarr;
        </span>
      </a>
    </ErrorLayout>
  );
};

export default InternalServerError;

import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { getWaterClass } from "@services/waterClass";

export const useWaterClass = (id: string, options?: UseQueryOptions) => {
  return useQuery<any>({
    queryKey: ["waterClasses", { id }],
    queryFn: () => getWaterClass(id),
    enabled: !!id,
    ...options,
  });
};

import EditIcon from "@components/icons/EditIcon";
import Heading1 from "@components/layout/Heading";
import { useTranslation } from "react-i18next";
import React from "react";

type ExtractionPointLinkToRightStep3Label = {
  extractionPointName: any;
  onSave: () => void;
  onEditExtractionRight: () => void;
  onEditExtractionPoint: () => void;
  isSaveDisabled: boolean;
  extractionRight: any;
};

const ExtractionPointLinkToRightStep3: React.FunctionComponent<
  ExtractionPointLinkToRightStep3Label
> = ({
  extractionPointName,
  onSave,
  onEditExtractionRight,
  onEditExtractionPoint,
  isSaveDisabled,
  extractionRight,
}) => {
  const { t } = useTranslation();

  const handleSave = (e: any) => {
    e.preventDefault();
    onSave();
  };

  return (
    <form className="p-6 flex flex-col grow" onSubmit={handleSave}>
      <div>
        <Heading1 className="text-xl leading-6" light>
          {t("extraction_point.link.confirm")}
        </Heading1>
      </div>

      <div className="mt-6 font-bold">
        {t("extraction_point.link.offtake_to_link")}
      </div>

      <div className="mt-4 flex flex-col gap-2">
        <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1 flex items-center">
            <p className="block text-sm mt-1 text-gray-600">
              <strong>{t("extraction_point.link.offtake_id")}:</strong>
              {"  "}
              {extractionPointName}
            </p>
          </div>
        </div>

        <button
          type="button"
          className="sm:col-span-2 text-left flex gap-1 items-center font-bold text-sm text-primary-3"
          onClick={onEditExtractionPoint}
        >
          <EditIcon /> {t("common.edit")}
        </button>
      </div>

      <div className="mt-6 font-bold">{t("common.entitlement")}</div>

      <div className="mt-4 flex flex-col gap-2">
        <div>
          {extractionRight ? (
            <p className="block text-sm mt-1 text-gray-600">
              <strong>{t("extraction_right.number")}:</strong>{" "}
              {extractionRight.name}
            </p>
          ) : null}
        </div>

        <button
          type="button"
          className="sm:col-span-2 text-left flex gap-1 items-center font-bold text-sm text-primary-3"
          onClick={onEditExtractionRight}
        >
          <EditIcon /> {t("common.edit")}
        </button>
        <dl className="grid grid-cols-1 gap-x-4 sm:grid-cols-2"></dl>
      </div>

      <div className="grow" />

      <footer className="border-t border-gray-200 -mx-6 p-6 pb-0 flex gap-3">
        <button
          type="submit"
          className="btn-primary"
          disabled={isSaveDisabled}
          onClick={onSave}
        >
          {t("common.continue")}
        </button>
        <button
          type="button"
          className="btn-default"
          onClick={onEditExtractionRight}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default ExtractionPointLinkToRightStep3;

import React from "react";
import { useTranslation } from "react-i18next";
import { UseStepHelpers, useStep } from "@hooks/useStep";
import { useValidateLevel1ResourceName } from "@hooks/mutation/useValidateLevel1ResourceName";
import { useValidateLevel1ResourceIdentifier } from "@hooks/mutation/useValidateLevel1ResourceIdentifier";
import { toastError } from "@utils/toast";
import { generateIdentifier } from "@utils/generateIdentifier";
import type { ConfirmData } from "@components/shared/ConfirmationDetail";
import {
  GetInfoMessagesFunction,
  ModifyStatusesFunction,
  useStepStatuses,
} from "@hooks/useStepStatuses";
import { formatDate } from "@utils/formatDate";
import HandleGoBackOrClose from "@components/shared/HandleGoBackOrClose";

type Details = typeof initialDetails;

type Location = typeof initialLocation;

type HandleChangeDetails = <TValue, TKeys extends keyof Details>(
  key: TKeys,
  value: TValue,
  subKey?: "id" | "name"
) => void;

type ValidateIdentifier = (identifier: string) => Promise<void>;

type ValidateName = (name: string) => Promise<void>;

type ContextValue = {
  currentStep: number;
  stepHelpers: UseStepHelpers;
  details: Details;
  handleChangeDetails: HandleChangeDetails;
  locationCoordinates: Location;
  setLocationCoordinates: React.Dispatch<React.SetStateAction<Location>>;
  saveGeographyDetails: () => void;
  locationFiles: any;
  setLocationFiles: React.Dispatch<React.SetStateAction<any>>;
  photoFiles: any[];
  setPhotoFiles: React.Dispatch<React.SetStateAction<File[]>>;
  workflowCompleted: boolean;
  setWorkflowCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  validateName: ValidateName;
  validateIdentifier: ValidateIdentifier;
  networkErrors: string[];
  setNetworkErrors: React.Dispatch<React.SetStateAction<string[]>>;
  getLevel1WRSDetails: () => ConfirmData;
  handleCancel: () => void;
  getInfoMessages: GetInfoMessagesFunction;
  modifyStatuses: ModifyStatusesFunction;
  workflowInstance: any;
  setWorkflowInstance: React.Dispatch<any>;
};

type Props = {
  children: React.ReactNode;
};

const CreateLevel1WRSContext = React.createContext<ContextValue | undefined>(
  undefined
);

type Level1WRSDetails = {
  id: string;
  name: string;
  identifier: string;
  description: string;
  platform: number;
  level2WRS: Record<string, any>;
  level3WRS: Record<string, any>;
  level4WRS: Record<string, any>;
  geography: string;
  image: undefined;
  createdAt?: Date;
  periodStart?: Date;
  periodEnd?: Date;
};

const initialDetails: Level1WRSDetails = {
  id: "",
  name: "",
  identifier: "",
  description: "",
  platform: 0,
  level2WRS: { id: "", name: "" },
  level3WRS: { id: "", name: "" },
  level4WRS: { id: "", name: "" },
  geography: "",
  image: undefined,
};

const initialLocation = { lat: "", lng: "" };

const CreateLevel1WRSProvider = ({ children }: Props) => {
  const { t } = useTranslation();
  const handleGoBackOrClose = HandleGoBackOrClose();
  const maxStep = 4;
  const [currentStep, stepHelpers] = useStep(maxStep);
  const { getInfoMessages, modifyStatuses } = useStepStatuses(maxStep);
  const [details, setDetails] = React.useState(initialDetails);
  const [locationCoordinates, setLocationCoordinates] =
    React.useState(initialLocation);
  const [locationFiles, setLocationFiles] = React.useState<File[]>([]);
  const [networkErrors, setNetworkErrors] = React.useState<string[]>([]);
  const [photoFiles, setPhotoFiles] = React.useState<File[]>([]);
  const [workflowCompleted, setWorkflowCompleted] = React.useState(false);
  const { mutateAsync: checkLevel1ResourceNameMutation } =
    useValidateLevel1ResourceName();
  const { mutateAsync: checkLevel1ResourceIdentifierMutation } =
    useValidateLevel1ResourceIdentifier();
  const [workflowInstance, setWorkflowInstance] = React.useState<any>();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const handleChangeDetails: HandleChangeDetails = (key, value, subKey) => {
    setDetails((prevState) => {
      const updatedDetails: any = { ...prevState };
      if (subKey) {
        updatedDetails[key] = {
          ...(prevState[key] as object),
          [subKey]: value,
        };
      } else {
        updatedDetails[key] = value;
      }
      return updatedDetails;
    });
    if (key === "name" && typeof value === "string") {
      setDetails((prevState: any) => {
        const updatedDetails: any = { ...prevState };
        updatedDetails["identifier"] = generateIdentifier(value).toUpperCase();
        return updatedDetails;
      });
    }
  };

  const validateIdentifier: ValidateIdentifier = async (identifier) => {
    if (identifier.trim() === "") {
      modifyStatuses({
        stepNumber: 0,
        fieldName: "identifier",
      });
      return;
    }

    try {
      const exists = await checkLevel1ResourceIdentifierMutation({
        identifier: identifier.trim(),
      });

      if (exists) {
        modifyStatuses({
          stepNumber: 0,
          fieldName: "identifier",
          message: t(
            "level1wrs.create.info_panel.step_1.error_duplicate_identifier"
          ) as string,
          infoType: "error",
        });
      } else {
        modifyStatuses({
          stepNumber: 0,
          fieldName: "identifier",
          message: t(
            "level1wrs.create.info_panel.step_1.success_duplicate_identifier"
          ) as string,
          infoType: "success",
        });
      }
    } catch (error) {
      toastError(t("level1wrs.create.toast.check_identifier_failure"));
    }
  };

  const validateName: ValidateName = async (name) => {
    if (name.trim() === "") {
      modifyStatuses({
        stepNumber: 0,
        fieldName: "name",
      });
      return;
    }

    try {
      const exists = await checkLevel1ResourceNameMutation({
        name: name.trim(),
      });

      if (exists) {
        modifyStatuses({
          stepNumber: 0,
          fieldName: "name",
          message: t(
            "level1wrs.create.info_panel.step_1.error_duplicate_name"
          ) as string,
          infoType: "error",
        });
      } else {
        modifyStatuses({
          stepNumber: 0,
          fieldName: "name",
          message: t(
            "level1wrs.create.info_panel.step_1.success_duplicate_name"
          ) as string,
          infoType: "success",
        });
      }
    } catch (error) {
      toastError(t("level1wrs.create.toast.check_name_failure"));
    }
  };

  const saveGeographyDetails = () => {
    setDetails((prevState) => {
      const { lat, lng } = locationCoordinates;
      const geography =
        lat !== ""
          ? `${lat},${lng}`
          : locationFiles && locationFiles.length > 0
          ? locationFiles[0].name
          : "";
      return { ...prevState, geography };
    });
  };

  const getLevel1WRSDetails = () => {
    return {
      title: t("level1wrs.create.details"),
      body: [
        {
          key: t("level1wrs.create.name"),
          value: details?.name,
        },
        {
          key: t("level1wrs.create.identifier"),
          value: details?.identifier,
        },
        {
          key: t("level1wrs.create.description"),
          value: details?.description || "-",
        },
        {
          key: t("level1wrs.create.level4WRS"),
          value: details?.level4WRS?.name,
        },
        {
          key: t("level1wrs.create.level3WRS"),
          value: details?.level3WRS?.name,
        },
        {
          key: t("level1wrs.create.level2WRS"),
          value: details?.level2WRS?.name,
        },
        {
          key: t("level0wrs.create.accounting_period"),
          value: `${formatDate(details?.periodStart as any)} - ${formatDate(
            details?.periodEnd as any
          )}`,
        },
      ],
    };
  };

  const handleCancel = handleGoBackOrClose;

  const value: ContextValue = {
    currentStep,
    stepHelpers,
    details,
    handleChangeDetails,
    locationCoordinates,
    setLocationCoordinates,
    saveGeographyDetails,
    locationFiles,
    setLocationFiles,
    photoFiles,
    setPhotoFiles,
    workflowCompleted,
    setWorkflowCompleted,
    validateName,
    validateIdentifier,
    networkErrors,
    setNetworkErrors,
    getLevel1WRSDetails,
    handleCancel,
    modifyStatuses,
    getInfoMessages,
    workflowInstance,
    setWorkflowInstance,
  };

  return (
    <CreateLevel1WRSContext.Provider value={value}>
      {children}
    </CreateLevel1WRSContext.Provider>
  );
};

const useCreateLevel1WRSContext = () => {
  const context = React.useContext(CreateLevel1WRSContext);
  if (context === undefined) {
    throw new Error(
      "useCreateLevel1WRSContext must be used within a CreateLevel1WRSProvider"
    );
  }
  return context;
};

export { CreateLevel1WRSProvider, useCreateLevel1WRSContext };

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Layout from "@components/layout/Layout";
import CreateExtractionPointWorkflow from "@components/form/extraction_point/CreateExtractionPointWorkflow";
import { useSubscriber } from "@hooks/query/useSubscriber";
import { useLevel0Resource } from "@hooks/query/useLevel0Resource";

const CreateExtractionPoint = () => {
  const { t } = useTranslation();
  const { id: subscriberId = "", level0ResourceId = "" } = useParams();
  const { data: subscriber } = useSubscriber(subscriberId);
  const { data: level0Resource } = useLevel0Resource(level0ResourceId);

  return (
    <Layout
      permissions={["CreateExtractionPoint"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("common.subscribers"),
          href: "/polestar/subscribers",
        },
        {
          label: subscriber?.name,
          href: `/polestar/subscribers/${subscriberId}`,
        },
        {
          label: level0Resource?.identifier,
          href: `/polestar/subscribers/${subscriberId}?level0ResourceId=${level0ResourceId}`,
        },
        {
          label: t("common.extraction_points"),
          href: `/polestar/subscribers/${subscriberId}?level0ResourceId=${level0ResourceId}&extractionPointId=random`,
        },
        {
          label: t("extraction_point.create.title"),
        },
      ]}
      title={t("extraction_point.create.title")}
      accountingPeriodLevel1ResourceId={level0Resource?.parentId}
    >
      <CreateExtractionPointWorkflow />
    </Layout>
  );
};

export default CreateExtractionPoint;

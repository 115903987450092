import React, { lazy, Suspense, useState } from "react";
import classNames from "classnames";
import { add } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import ArrowRightCalendarIcon from "@components/icons/ArrowRightCalendarIcon";
import Card from "@components/layout/Card";
import Layout from "@components/layout/Layout";
import Loading from "@components/shared/Loading";
import { useAllAccountingPeriods } from "@hooks/query/useAllAccountingPeriods";
import { formatDate } from "@utils/formatDate";
import { getWaterAccountsReport } from "@services/reports";

const WaterAccountsReport: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [renderers, setRenderers] = useState<any>({});
  const [SelectedReport, setSelectedReport] = useState<any>();
  const [reportProps, setReportProps] = useState<{
    report?: any;
    fromDate?: Date;
    toDate?: Date;
    runAt?: Date;
  }>({});
  const [filter, setFilter] = useState<{
    report?: any;
    fromDate?: Date;
    toDate?: Date;
  }>({});
  useAllAccountingPeriods({
    options: {
      refetchOnWindowFocus: false,
      onSuccess: (data: any) => {
        const fromDate = data[0]
          ? new Date(data[0].periodStart)
          : new Date(new Date().getFullYear(), 1, 1);
        let toDate = data[0]
          ? new Date(data[0].periodEnd)
          : new Date(data[0].getFullYear, 12, 31);

        toDate = add(
          add(toDate, { minutes: toDate.getTimezoneOffset(), days: 1 }),
          { seconds: -1 }
        );

        setFilter({
          ...filter,
          fromDate,
          toDate,
        });
      },
    },
  });

  useQuery(["reports", "water_accounts"], getWaterAccountsReport, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!filter.fromDate && !!filter.toDate,
    onSuccess: (report) => {
      const updatedRenderers = { ...renderers };
      updatedRenderers[report.id] = lazy(
        () => import(`@components/reports/${report.type}`)
      );

      setRenderers(updatedRenderers);
      setSelectedReport(undefined);

      if (report) {
        setFilter({ ...filter, report });
      }
    },
  });

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const handleRenderReport = (e: any) => {
    e.preventDefault();

    if (renderers[filter.report?.id]) {
      setSelectedReport(renderers[filter.report?.id]);
      setReportProps({
        report: filter.report,
        fromDate: filter.fromDate,
        toDate: filter.toDate,
        runAt: new Date(),
      });
    }
  };

  return (
    <Layout
      permissions={["ViewReports"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("reporting.title"),
        },
      ]}
      title={t("reporting.new_water_accounts_report")}
    >
      <Card className="flex flex-col rounded-none p-0 grow">
        <div className="flex flex-col grow gap-4 divide-y">
          <form
            className="flex items-end gap-2 p-4"
            onSubmit={handleRenderReport}
          >
            <div className="w-1/3">
              <Label htmlFor="from-date">{t("reporting.filter_dates")}</Label>
              <div className="flex items-center gap-1">
                <TextInput
                  type="date"
                  placeholder="From"
                  value={
                    filter.fromDate && formatDate(filter.fromDate, "yyyy-MM-dd")
                  }
                  onChange={(e) => {
                    handleFilterChange(
                      "fromDate",
                      new Date(`${e.target.value}T00:00:00`)
                    );
                  }}
                  className={classNames({
                    "disabled:text-gray-400": !filter.report,
                  })}
                  disabled={!filter.report}
                />
                <ArrowRightCalendarIcon className="w-10 h-10" />
                <TextInput
                  type="date"
                  placeholder="To"
                  value={
                    filter.toDate && formatDate(filter.toDate, "yyyy-MM-dd")
                  }
                  min={
                    filter.fromDate && formatDate(filter.fromDate, "yyyy-MM-dd")
                  }
                  onChange={(e) => {
                    handleFilterChange(
                      "toDate",
                      new Date(`${e.target.value}T23:59:59`)
                    );
                  }}
                  className={classNames({
                    "disabled:text-gray-400": !filter.report,
                  })}
                  disabled={!filter.report}
                />
              </div>
            </div>
            <div className="w-1/3 sm:w-1/5">
              <button
                type="submit"
                className="btn-secondary"
                disabled={!filter.report || !filter.fromDate || !filter.toDate}
              >
                {t("reporting.get_data")}
              </button>
            </div>
          </form>
          {SelectedReport && (
            <div className="p-4 flex flex-col grow">
              <Suspense fallback={<Loading />}>
                <SelectedReport {...reportProps} />
              </Suspense>
            </div>
          )}
        </div>
      </Card>
    </Layout>
  );
};

export default WaterAccountsReport;

import React from "react";
import { useTranslation } from "react-i18next";

import Loading from "@components/shared/Loading";
import { downloadEvidence } from "@services/evidence";
import { toastError } from "@utils/toast";

type Props = {
  evidence: any;
  className?: string;
};

const PreviewEvidenceImage: React.FunctionComponent<Props> = ({
  evidence,
  className,
}) => {
  const { t } = useTranslation();
  const [imgSrc, setImgSrc] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const setup = async () => {
      try {
        const file = await downloadEvidence(evidence?.id);
        setImgSrc(URL.createObjectURL(file));
      } catch (err) {
        toastError(t("evidence.toast.preview_error"));
      }
      setLoading(false);
    };

    setup();
  }, [evidence, t]);

  if (loading || !imgSrc) {
    return (
      <div className="py-10">
        <Loading />
      </div>
    );
  }

  return (
    <div className={className}>
      <img src={imgSrc} alt={evidence?.fileName} />
    </div>
  );
};

export default PreviewEvidenceImage;

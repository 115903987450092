import React from "react";
import { useTranslation } from "react-i18next";
import { snakeCase } from "lodash";

import Heading from "@components/layout/Heading";
import { useLevel0WRSContext } from "@context/Level0WRSContext";
import Legend from "@components/form/Legend";
import Label from "@components/form/Label";
import CheckboxInputWithLabel from "@components/form/CheckboxInputWithLabel";

import type { AdministrationApprovals } from "@context/Level0WRSContext";

const administrationApprovals: Array<keyof AdministrationApprovals> = [
  "stockAndDomestic",
  "forwardDraw",
  "seasonalTransfer",
  "permanentTrade",
  "waterHarvesting",
  "specialAnnouncement",
];

const Level0WRSAdministration = () => {
  const { t } = useTranslation();
  const { stepHelpers, details, handleChangeDetails, navigateForCancel } =
    useLevel0WRSContext();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    stepHelpers.goToNextStep();
  };

  const isCheckedAll = Object.entries(details?.administration ?? [])
    .map((i) => i[1])
    .every(Boolean);

  const toggleAllCheckboxes = () => {
    administrationApprovals.forEach((option) => {
      handleChangeDetails("administration", !isCheckedAll, option);
    });
  };

  return (
    <form className="flex flex-col gap-6 grow p-6" onSubmit={handleSubmit}>
      <Heading light>{t("level0wrs.create.administration")}</Heading>

      <fieldset className="space-y-4">
        <Legend>{t("topnav.approvals")}</Legend>

        <div className="space-y-2">
          <Label htmlFor="approvals">
            {t("level0wrs.create.select_approvals")}
          </Label>

          <button
            type="button"
            className="text-primary-1 text-sm hover:underline"
            onClick={toggleAllCheckboxes}
          >
            {isCheckedAll ? t("common.clear_all") : t("common.select_all")}
          </button>

          {administrationApprovals.map((item, index) => (
            <CheckboxInputWithLabel
              htmlFor={item}
              key={index}
              label={t(`level0wrs.create.approval.${snakeCase(item)}`)}
              checked={details.administration[item]}
              onChange={(e) =>
                handleChangeDetails("administration", e.target.checked, item)
              }
            />
          ))}
        </div>
      </fieldset>

      <div className="grow" />

      <footer className="flex gap-4 -mx-6 mt-6 p-6 pb-0 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>

        <button type="submit" className="btn-primary">
          {t("common.next_step")}
        </button>

        <button
          type="button"
          className="btn-outline-primary"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default Level0WRSAdministration;

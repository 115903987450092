import React from "react";
import _sumBy from "lodash/sumBy";
import { Trans, useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import { formatVolume } from "@utils/formatVolume";
import SubHeading from "@components/layout/SubHeading";
import { useAllExtractionRights } from "@hooks/query/useAllExtractionRights";

type WaterClassVolumeRemainingInfoProps = {
  waterClass: any;
  level0wrsName?: string;
};

const WaterClassVolumeRemainingInfo: React.FunctionComponent<
  WaterClassVolumeRemainingInfoProps
> = ({ waterClass, level0wrsName }) => {
  const { t } = useTranslation();
  const { data: extractionRights = [] } = useAllExtractionRights({
    params: { waterClassId: waterClass.id },
    options: { enabled: Boolean(waterClass.id) },
  });
  const cumulativeRightNominalVolume = extractionRights.length
    ? _sumBy(extractionRights, (i: any) => +i.volume)
    : 0;
  const tableFields = [
    {
      title: t("common.class"),
      name: "name",
    },
    {
      title: t("common.volume"),
      name: "volume",
    },
    {
      title: t("extraction_right.cumulative_nominal_volume"),
      name: "cumulativeNominalVolume",
    },
  ];

  const tableData = [
    {
      name: waterClass.name,
      volume: formatVolume(+waterClass.volume),
      cumulativeNominalVolume: formatVolume(cumulativeRightNominalVolume),
    },
  ];

  return (
    <>
      <SubHeading>
        <Trans
          i18nKey="water_class.info.volume_info"
          context={level0wrsName ? "title_with_level0wrs_name" : "title"}
          values={{ waterClassName: waterClass.name, level0wrsName }}
        />
      </SubHeading>
      <Table fields={tableFields} data={tableData} />

      <p>
        <strong>{t("extraction_right.total_number")}:</strong>{" "}
        {extractionRights.length}
      </p>
    </>
  );
};

export default WaterClassVolumeRemainingInfo;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isFunction } from "lodash";

import { updateSubscriber } from "@services/subscriber";

export const useUpdateSubscriber = (options: Record<string, any> = {}) => {
  const queryClient = useQueryClient();

  return useMutation(updateSubscriber, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries({ queryKey: ["subscribers"] });
      if (isFunction(options.onSuccess)) {
        options.onSuccess(data);
      }
    },
    ...options,
  });
};

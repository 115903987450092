import React from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import Label from "@components/form/Label";
import Legend from "@components/form/Legend";
import TextInput from "@components/form/TextInput";
import DecimalInput from "@components/form/DecimalInput";
import ReadOnlyInput from "@components/form/ReadOnlyInput";
import SelectSubscriber from "@components/form/SelectSubscriber";
import SelectExtractionPoint from "@components/form/SelectExtractionPoint";
import DeclarationMethodModal from "@components/modal/DeclarationMethodModal";
import UpdateMeterClickOverModal from "@components/modal/UpdateMeterClickOverModal";
import DeclarationExistWarningModal from "@components/modal/DeclarationExistWarningModal";
import ConfirmHistoricalDeclarationModal from "@components/modal/ConfirmHistoricalDeclarationModal";
import Alert from "@components/shared/Alert";
import ConfirmModal from "@components/shared/ConfirmModal";
import {
  READING_TYPES,
  useDeclarationContext,
} from "@context/DeclarationContext";
import { useAppContext } from "@context/AppContext";
import { useStepContext } from "@context/shared/StepContext";
import { formatVolume } from "@utils/formatVolume";
import { formatDatetimeInput } from "@utils/formatDate";
import { convertLiterToML, convertMLToLiter } from "@utils/convertUnits";
import { useAllDeclarations } from "@hooks/query/useAllDeclarations";
import { useAllAccountingPeriods } from "@hooks/query/useAllAccountingPeriods";

export const DECLARATION_VARIANTS = {
  initial: "initial",
  newReading: "newReading",
  final: "final",
} as const;

type Variant = (typeof DECLARATION_VARIANTS)[keyof typeof DECLARATION_VARIANTS];

type DeclarationFormProps = {
  onCancel: () => void;
  variant?: Variant;
  minReadAt?: string;
};

const DeclarationForm: React.FunctionComponent<DeclarationFormProps> = ({
  variant = DECLARATION_VARIANTS.newReading,
  onCancel,
  minReadAt,
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const subscriberId = searchParams.get("subscriberId") ?? "";
  const extractionPointId = searchParams.get("extractionPointId") ?? "";
  const paramLevel1ResourceId = searchParams.get("level1ResourceId") ?? "";
  const { checkPermissions } = useAppContext();
  const {
    declaration,
    setDeclaration,
    handleInputChange,
    usage,
    lastReading,
    meter,
    isUnmetered,
    isHistoricalDeclaration,
    availableWater,
  } = useDeclarationContext();
  const { stepHelpers } = useStepContext();
  const [showDeclarationMethodModal, setShowDeclarationMethodModal] =
    React.useState(false);
  const [showUpdateModal, setShowUpdateModal] = React.useState(false);
  const [showUsageWarningModal, setShowUsageWarningModal] =
    React.useState(false);
  const [showExistingWarningModal, setShowExistingWarningModal] =
    React.useState(false);
  const [showConfirmHistoricalModal, setShowConfirmHistoricalnModal] =
    React.useState(false);
  const [showOverusedModal, setShowOverusedModal] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const level1ResourceId =
    declaration.subscriber.level1ResourceId || paramLevel1ResourceId;

  const { data: currentAccountingPeriod } = useAllAccountingPeriods({
    options: {
      enabled: Boolean(level1ResourceId),
      select: (data: any) => data?.[0],
    },
    params: {
      level1ResourceId,
      isActive: true,
    },
  });

  const titleText: Record<Variant, string> = {
    initial: t("declaration.declare_initial_meter_reading"),
    final: t("meter.decommission.declare_final_read"),
    newReading: t("declaration.enter_read"),
  };
  const enterReadingText: Record<Variant, string> = {
    initial: t("declaration.enter_initial_read"),
    final: t("declaration.enter_final_read"),
    newReading: t("declaration.enter_read"),
  };

  const isInitialReading = variant === DECLARATION_VARIANTS.initial;
  const isFinalReading = variant === DECLARATION_VARIANTS.final;
  const currentUsage = isInitialReading ? 0 : usage;

  const readingTypes = [
    {
      value: READING_TYPES.ACTUAL,
      label: t("declaration.actual"),
    },
    {
      value: READING_TYPES.ESTIMATED,
      label: t("declaration.estimated"),
    },
  ];
  const { data: existingDeclarations = [], isFetching } = useAllDeclarations({
    params: {
      meterId: declaration.meter.id,
      readAt: new Date(declaration.readAt),
    },
    enabled: Boolean(declaration.meter.id) && Boolean(declaration.readAt),
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      !(isInitialReading || isFinalReading || lastReading === undefined) &&
      !Boolean(+declaration.reading)
    ) {
      setErrorMessage(t("declaration.form.meter_reading_required") as string);
      return;
    }

    if (
      declaration.meter.clickOver > 0 &&
      convertMLToLiter(+declaration.reading) > declaration.meter.clickOver
    ) {
      setErrorMessage(t("declaration.form.meter_reading_error") as string);
      return;
    }

    if (existingDeclarations.length > 0 && !isFetching) {
      setShowExistingWarningModal(true);
      return;
    }

    if (isHistoricalDeclaration) {
      setShowConfirmHistoricalnModal(true);
      return;
    }

    if (!isInitialReading && currentUsage < 0) {
      declaration.meter.clickOver > 0
        ? setShowDeclarationMethodModal(true)
        : setShowUsageWarningModal(true);
      return;
    }

    if (currentUsage > availableWater) {
      setShowOverusedModal(true);
      return;
    }

    stepHelpers.goToNextStep();
  };

  return (
    <>
      <form
        className="flex flex-col grow p-6 space-y-6"
        onSubmit={handleSubmit}
      >
        {declaration.hasRight === false ? (
          <Alert type="error">
            {t("declaration.inactive_extraction_rights")}
          </Alert>
        ) : null}

        <Heading light>{titleText[variant]}</Heading>

        <fieldset className="space-y-4">
          <Legend>{t("declaration.meter_read_details")}</Legend>
          <div className="max-w-lg">
            <Label htmlFor="subscriber">
              {t("declaration.form.select_subscriber")}
            </Label>
            {subscriberId ? (
              <ReadOnlyInput>{declaration.subscriber.name}</ReadOnlyInput>
            ) : (
              <SelectSubscriber
                level1ResourceId={level1ResourceId}
                value={declaration.subscriber.id}
                onChange={(selected: any) => {
                  setDeclaration(prev => {
                    return {
                      ...prev,
                      subscriber: {
                        id: selected?.value ?? "",
                        name: selected?.label ?? "",
                        walletId: selected?.walletId ?? "",
                        level1ResourceId: selected?.level1ResourceId ?? "",
                      },
                      extractionPoint: {
                        id: "",
                        name: "",
                        level0ResourceId: "",
                      },
                      meter: {
                        id: "",
                        serialNo: "",
                        clickOver: 0,
                      },
                      calculation: "",
                    };
                  });
                }}
                required
                isDisabled={Boolean(subscriberId)}
              />
            )}
          </div>
          <div className="max-w-lg">
            <Label>{t("declaration.form.select_point")}</Label>
            {extractionPointId ? (
              <ReadOnlyInput>{declaration.extractionPoint.name}</ReadOnlyInput>
            ) : (
              <SelectExtractionPoint
                isActive={true}
                definedByWalletId={declaration.subscriber.walletId}
                value={declaration.extractionPoint.id}
                onChange={(selected: any) => {
                  setDeclaration(prev => {
                    return {
                      ...prev,
                      extractionPoint: {
                        id: selected?.id ?? "",
                        name: selected?.value ?? "",
                        level0ResourceId: selected?.level0ResourceId ?? "",
                      },
                      meter: {
                        id: selected?.meter?.id ?? "",
                        serialNo: selected?.meter?.serialNo ?? "unmetered",
                        clickOver: selected?.meter?.clickOver
                          ? +selected.meter.clickOver
                          : 0,
                      },
                      type: selected?.meter?.serialNo
                        ? prev.type
                        : READING_TYPES.ESTIMATED,
                      calculation: "",
                    };
                  });
                }}
                isDisabled={!Boolean(declaration.subscriber.walletId)}
                isClearable={true}
                required
              />
            )}
          </div>
          <div className="max-w-lg">
            <Label>{t("declaration.form.meter_id")}</Label>
            <ReadOnlyInput>{declaration.meter.serialNo}</ReadOnlyInput>
          </div>
          <div className="max-w-lg">
            <Label htmlFor="readAt">
              {t("declaration.form.timestamp_read")}
            </Label>
            <TextInput
              type="datetime-local"
              id="readAt"
              value={declaration.readAt}
              min={
                minReadAt || currentAccountingPeriod?.periodStart
                  ? formatDatetimeInput(
                      new Date(currentAccountingPeriod?.periodStart),
                    )
                  : undefined
              }
              max={
                currentAccountingPeriod?.periodEnd
                  ? formatDatetimeInput(
                      new Date(currentAccountingPeriod?.periodEnd),
                    )
                  : undefined
              }
              onChange={e =>
                handleInputChange(
                  "readAt",
                  formatDatetimeInput(new Date(e.target.value)),
                )
              }
              required
            />
          </div>
        </fieldset>

        <fieldset className="space-y-4">
          <Legend>{enterReadingText[variant]}</Legend>

          <div className="max-w-lg">
            <Label htmlFor="clickOver">{t("meter.click_over")}</Label>

            <ReadOnlyInput>
              {convertLiterToML(declaration.meter.clickOver)}
            </ReadOnlyInput>
          </div>

          <div>
            <p className="mb-2">{t("declaration.type_of_meter_reading")}</p>
            <div className="flex gap-6">
              {readingTypes.map(item => (
                <Label
                  key={item.value}
                  className="flex items-center gap-2 cursor-pointer"
                >
                  <input
                    type="radio"
                    value={item.value}
                    checked={declaration.type === item.value}
                    onChange={() => handleInputChange("type", item.value)}
                    disabled={isUnmetered}
                  />
                  {item.label}
                </Label>
              ))}
            </div>
          </div>

          <div className="flex gap-6 items-start justify-start text-sm">
            <div>
              <p>{t("declaration.form.last_read")}</p>
              <p className="mt-1 font-bold text-2xl">
                {!isInitialReading && lastReading !== undefined
                  ? formatVolume(lastReading)
                  : "-"}
              </p>
            </div>

            <div>
              <Label htmlFor="reading">{enterReadingText[variant]}</Label>
              <DecimalInput
                id="reading"
                value={declaration.reading}
                onChange={value => {
                  handleInputChange("reading", value);
                  setErrorMessage("");
                }}
                errorMessage={errorMessage}
              />
            </div>
            <div>
              <p>{t("declaration.form.usage_calculation")}</p>
              <p className="mt-1 font-bold text-2xl">
                {formatVolume(currentUsage)}
              </p>
            </div>
          </div>

          {declaration.meter.id &&
          checkPermissions(["ViewThreshold", "UpdateThreshold"]) ? (
            <div className="max-w-lg">
              <Label>{t("declaration.form.current_threshold")}</Label>
              <TextInput value={formatVolume(meter.threshold ?? 0)} readOnly />
            </div>
          ) : null}
        </fieldset>

        <div className="grow" />

        <footer className="flex gap-4 p-6 pb-0 -mx-6 border-t border-gray-200">
          <button
            type="submit"
            className="btn-primary"
            disabled={!declaration.hasRight || isFetching}
          >
            {t("common.next_step")}
          </button>
          <button
            type="button"
            className="btn-outline-primary"
            onClick={onCancel}
          >
            {t("common.cancel")}
          </button>
        </footer>
      </form>

      <DeclarationMethodModal
        open={showDeclarationMethodModal}
        data={declaration}
        onChange={value => handleInputChange("calculation", value)}
        onSubmit={() => {
          if (declaration.calculation === "update") {
            setShowUpdateModal(true);
            return;
          }
          stepHelpers.goToNextStep();
        }}
        onCancel={() => setShowDeclarationMethodModal(false)}
      />

      <UpdateMeterClickOverModal
        open={showUpdateModal}
        data={declaration}
        onSubmit={clickOver => {
          setDeclaration((prev: any) => ({
            ...prev,
            meter: {
              ...prev.meter,
              clickOver,
            },
            calculation: "",
          }));
        }}
        onCancel={() => {
          setShowUpdateModal(false);
          setShowDeclarationMethodModal(true);
        }}
      />

      <DeclarationExistWarningModal
        open={showExistingWarningModal}
        data={existingDeclarations[0]}
        onCancel={() => {
          setShowExistingWarningModal(false);
        }}
      />

      <ConfirmHistoricalDeclarationModal
        open={showConfirmHistoricalModal}
        onCancel={() => {
          setShowConfirmHistoricalnModal(false);
        }}
      />

      <ConfirmModal
        open={showUsageWarningModal}
        onConfirm={() => setShowUsageWarningModal(false)}
        confirmText={t("common.close") as string}
      >
        {t("declaration.modal.negative_usage.title")}
      </ConfirmModal>

      <ConfirmModal
        open={showOverusedModal}
        onClose={() => setShowOverusedModal(false)}
        onConfirm={stepHelpers.goToNextStep}
        confirmText={t("common.continue") as string}
      >
        {t("declaration.modal.confirm_overused")}
      </ConfirmModal>
    </>
  );
};

export default DeclarationForm;

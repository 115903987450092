import { useTranslation } from "react-i18next";

import Breadcrumb from "@components/layout/Breadcrumb";
import Heading1 from "@components/layout/Heading";
import EventTransactionTable from "@components/table/EventTransactionTable";
import { useAppContext } from "@context/AppContext";

const History: React.FunctionComponent = () => {
  const { loggedInInfo } = useAppContext();
  const { t } = useTranslation();

  const breadcrumb = [
    {
      label: t("dashboard.dashboard"),
      href: "/zenith",
    },
    {
      label: t("audit_trail.audit_trail"),
    },
  ];

  return (
    <>
      <Breadcrumb items={breadcrumb} />
      <header className="mt-3">
        <Heading1>{t("audit_trail.audit_trail")}</Heading1>
      </header>
      <div className="flex flex-col grow gap-4">
        <EventTransactionTable
          eventGroups={["User", "Accounting", "Exchange"]}
          walletId={loggedInInfo?.userDetails?.walletId}
          showLogo={true}
          limit={-1}
          stickyHeader
        />
      </div>
    </>
  );
};

export default History;

import { useQuery } from "@tanstack/react-query";

import { getAllLevel1Resources } from "@services/level1Resource";

export const useAllLevel1Resources = (options?: Record<string, any>) => {
  return useQuery<any>({
    queryKey: ["level1Resources"],
    queryFn: getAllLevel1Resources,
    ...options,
  });
};

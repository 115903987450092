import { useQuery } from "@tanstack/react-query";

import { getAllAdministrativeApprovals } from "@services/administrativeApprovals";

type UseAllAdministrativeApprovalsParams = {
  params: Record<string, any>;
  [key: string]: any;
};

export const useAllAdministrativeApprovals = ({
  params,
  options,
}: Partial<UseAllAdministrativeApprovalsParams> = {}) => {
  return useQuery<any>({
    queryKey: ["administrative_approvals", params],
    queryFn: () => getAllAdministrativeApprovals(params),
    ...options,
  });
};

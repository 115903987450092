import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import AuditTrail from "@components/pages/polestar/AuditTrail";
import Dashboard from "@components/pages/polestar/dashboard/Dashboard";
import CreateExchangeRate from "@components/pages/polestar/exchange_rates/CreateExchangeRate";
import SingleSignOn from "@components/pages/polestar/SingleSignOn";
import PolestarLogin from "@components/pages/polestar/PolestarLogin";
import Logout from "@components/pages/Logout";
import ForgotPassword from "@components/pages/ForgotPassword";
import Notifications from "@components/pages/polestar/notifications/Notifications";
import UpdateThreshold from "@components/pages/polestar/meters/UpdateThreshold";
import CreateAccountingPeriodCheck from "@components/pages/polestar/accounting_period_checks/CreateAccountingPeriodCheck";
import CreateDistributionLoss from "@components/pages/polestar/distribution_losses/CreateDistributionLoss";
import CreateMeterEOY from "@components/pages/polestar/meters/eoy/CreateMeterEOY";
import CreateBillingGroup from "@components/pages/polestar/billing_groups/CreateBillingGroup";
import EditBillingGroup from "@components/pages/polestar/billing_groups/EditBillingGroup";
import CreateWaterCharge from "@components/pages/polestar/water_charges/CreateWaterCharge";
import EditWaterCharge from "@components/pages/polestar/water_charges/EditWaterCharge";
import NotFound from "@components/error/NotFound";
import ErrorBoundary from "@components/error/ErrorBoundary";
import EditMeter from "@components/pages/polestar/meters/EditMeter";
import CreateDeclaration from "@components/pages/polestar/declarations/CreateDeclaration";
import CreateBalanceAdjustment from "@components/pages/polestar/balance_adjustment/CreateBalanceAdjustment";
import AdjustDeclaration from "@components/pages/polestar/balance_adjustment/AdjustDeclaration";
import CreateNotification from "@components/pages/polestar/notifications/CreateNotification";
import ProfileIndex from "@components/pages/polestar/profile/ProfileIndex";
import FileVerification from "@components/pages/FileVerification";

import Level1WRSRoutes from "./Level1WRSRoutes";
import SubscriberRoutes from "./SubscriberRoutes";
import ReportRoutes from "./ReportRoutes";
import AdministrativeApprovalsRoutes from "./AdministrativeApprovalsRoutes";
import ZenithApp from "@components/pages/zenith/ZenithApp";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" errorElement={<ErrorBoundary />}>
      <Route index element={<Navigate to="polestar" />} />

      <Route path="polestar">
        <Route index element={<Dashboard />} />
        <Route path="forgotPassword" element={<ForgotPassword />} />
        <Route path="sso" element={<SingleSignOn />} />
        <Route path="login" element={<PolestarLogin />} />
        <Route path="logout" element={<Logout />} />

        <Route
          path="administrative_approvals/*"
          element={<AdministrativeApprovalsRoutes />}
        />
        <Route path="level1wrs/*" element={<Level1WRSRoutes />} />
        <Route path="reports/*" element={<ReportRoutes />} />
        <Route path="subscribers/*" element={<SubscriberRoutes />}></Route>
        <Route path="profile">
          <Route index element={<ProfileIndex />} />
        </Route>
        <Route path="exchange_rates">
          <Route path="create" element={<CreateExchangeRate />} />
        </Route>
        <Route path="distribution_losses">
          <Route path="create" element={<CreateDistributionLoss />} />
        </Route>
        <Route path="notifications">
          <Route path="create" element={<CreateNotification />} />
          <Route index element={<Notifications />} />
        </Route>
        <Route path="meters">
          <Route path="create" element={<CreateMeterEOY />} />
          <Route path="update" element={<UpdateThreshold />} />
          <Route path=":meterId/edit" element={<EditMeter />} />
        </Route>
        <Route path="declarations">
          <Route path="create" element={<CreateDeclaration />} />
        </Route>
        <Route path="audit_trail" element={<AuditTrail />} />
        <Route path="balance_adjustments">
          <Route path="create" element={<CreateBalanceAdjustment />} />
          <Route path="declaration" element={<AdjustDeclaration />} />
        </Route>
        <Route path="accounting_period_checks">
          <Route path="create" element={<CreateAccountingPeriodCheck />} />
        </Route>
        <Route path="billing_groups">
          <Route path="create" element={<CreateBillingGroup />} />
          <Route path=":id/edit" element={<EditBillingGroup />} />
        </Route>
        <Route path="water_charges">
          <Route path="create" element={<CreateWaterCharge />} />
          <Route path=":id/edit" element={<EditWaterCharge />} />
        </Route>
      </Route>

      <Route path="fileVerification" element={<FileVerification />} />
      <Route path="zenith/*" element={<ZenithApp />} />
      <Route path="*" element={<NotFound />} />
    </Route>,
  ),
);

export default router;

import { useQuery } from "@tanstack/react-query";

import { generateExtractionPointSequence } from "@services/extractionPoints";

export const useGenerateExtractionPointSequence = ({
  params,
  ...options
}: {
  [key: string]: any;
  params: {
    level1ResourceId: string;
    group: string;
  };
}) => {
  return useQuery<number>({
    queryKey: ["extraction_point_seq_no"],
    queryFn: () => generateExtractionPointSequence(params),
    enabled: Boolean(params.level1ResourceId) && Boolean(params.group),
    ...options,
  });
};

import { useQuery } from "@tanstack/react-query";
import { getEventTransactions } from "@services/eventTransactions";

export const useAllEventTransactions = (options: Record<string, any> = {}) => {
  const { params, ...restOptions } = options;
  return useQuery<any>({
    queryKey: ["eventTransactions", params],
    queryFn: () => getEventTransactions(params),
    ...restOptions,
  });
};

import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { getZoneAccountById } from "@services/zenith/zoneAccount";

export function useGetZoneAccountById(id: string, options?: UseQueryOptions) {
  return useQuery<any>({
    queryKey: ["zone_accounts", id],
    queryFn: () => getZoneAccountById(id),
    ...options,
  });
}

import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { generateSubscriberAccountNumber } from "@services/subscriber";

export function useGenerateSubscriberAccountNumber(options?: UseQueryOptions) {
  return useQuery<any>({
    queryKey: ["subscribers-new-account-number"],
    queryFn: () => generateSubscriberAccountNumber(),
    ...options,
  });
}

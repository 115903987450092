import { useQuery } from "@tanstack/react-query";
import { getAllPublicLevel1Resources } from "@services/zenith/level1Resource";

export const useAllPublicLevel1Resources = (
  options: Record<string, any> = {}
) => {
  return useQuery<any>({
    queryKey: ["publicLevel1Resources"],
    queryFn: getAllPublicLevel1Resources,
    ...options,
  });
};

import { useQuery } from "@tanstack/react-query";

import { getAllLevel4Resources } from "@services/level4Resource";

export const useAllLevel4Resources = (options: Record<string, any> = {}) => {
  return useQuery<any>({
    queryKey: ["level4Resources"],
    queryFn: getAllLevel4Resources,
    ...options,
  });
};

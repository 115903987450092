import React from "react";
import { useTranslation } from "react-i18next";

import { useAdminTransferContext } from "@context/AdminTransferContext";
import Heading1 from "@components/layout/Heading";
import ConfirmationDetail from "@components/shared/ConfirmationDetail";
import ConfirmModal from "@components/shared/ConfirmModal";
import { useCreateAdminTransfer } from "@hooks/mutation/useCreateAdminTransfer";
import { toastError } from "@utils/toast";
import { useEvidenceContext } from "@context/shared/EvidenceContext";
import { DBTables } from "@utils/constants/dbTables";

const AdminTransferLedger = () => {
  const { t } = useTranslation();
  const {
    stepHelpers,
    details,
    setDetails,
    setWorkflowCompleted,
    setWorkflowInstance,
    navigateForCancel,
    confirmData,
  } = useAdminTransferContext();
  const { uploadEvidences, isSubmitting, getEvidencesInfo } =
    useEvidenceContext();
  const { mutateAsync: createAdminTransferMutation, isLoading } =
    useCreateAdminTransfer();
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);

  const handleOnConfirm = async () => {
    try {
      const {
        extractionPoint: { id: extractionPointId = "" },
        extractionRight: { id: extractionRightId = "" },
        subscriber: { id: subscriberId = "" },
      } = details;

      const [res, workflowInstance] = await createAdminTransferMutation({
        subscriberId,
        extractionPointId,
        extractionRightId,
      });

      await uploadEvidences({
        description: t("approval.admin_transfer.create.step_5.description", {
          extractionRightName: details.extractionRight.name,
          extractionPointName: details.extractionPoint.name,
        }),
        references: [
          {
            referenceId: res.id,
            referenceTable: DBTables.AdministrativeApproval,
          },
          {
            referenceId: subscriberId,
            referenceTable: DBTables.Subscribers,
          },
          {
            referenceId: res.level1ResourceId,
            referenceTable: DBTables.WRSHierarchies,
          },
          {
            referenceId: workflowInstance?.id,
            referenceTable: DBTables.WorkflowInstances,
          },
        ],
      });

      setDetails({ ...details, id: res.id });
      setWorkflowInstance(workflowInstance);
      setWorkflowCompleted(true);
    } catch (error: any) {
      toastError(
        t("common.network_error", {
          error: error?.response?.data?.message || error?.message,
        })
      );
    }
    setShowConfirmModal(false);
  };

  return (
    <>
      <div className="flex flex-col gap-6 p-6 grow justify-between">
        <div className="space-y-4">
          <Heading1 light>
            {t("approval.admin_transfer.create.step_6.ledger_title")}
          </Heading1>

          <ConfirmationDetail
            data={[
              confirmData.level1Resource,
              confirmData.subscriber,
              confirmData.extractionRight,
              confirmData.extractionPoint,
              getEvidencesInfo(),
            ]}
            onEdit={stepHelpers.setStep}
          />
        </div>

        <footer className="flex gap-4 -mx-2 mt-2 p-6 pb-0 border-t border-gray-200">
          <button
            type="button"
            className="btn-outline-primary"
            onClick={stepHelpers.goToPrevStep}
          >
            {t("common.prev_step")}
          </button>

          <button
            type="button"
            className="btn-primary"
            onClick={() => setShowConfirmModal(true)}
            disabled={isSubmitting || isLoading}
          >
            {t("common.ledger")}
          </button>

          <button
            type="button"
            className="btn-outline-primary"
            onClick={navigateForCancel}
          >
            {t("common.cancel")}
          </button>
        </footer>
      </div>
      <ConfirmModal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={() => handleOnConfirm()}
        isSubmitting={isLoading || isSubmitting}
        confirmText={t("common.ledger") as string}
      >
        {t("approval.admin_transfer.create.step_6.confirm_text")}
      </ConfirmModal>
    </>
  );
};

export default AdminTransferLedger;

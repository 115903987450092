import SlideOver from "@components/layout/SlideOver";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import NotificationList from "./NotificationList";
import NotificationMessageViewModal from "./NotificationMessageViewModal";

type NotificationListSlideOverProps = {
  open: boolean;
  notifications: any[];
  onClose: () => void;
  onViewMessageModalClose: () => void;
};

const NotificationListSlideOver: React.FunctionComponent<
  NotificationListSlideOverProps
> = ({ open, notifications, onClose, onViewMessageModalClose }) => {
  const { t } = useTranslation();
  const [selectedNotification, setSelectedNotification] = useState<any>();

  return (
    <>
      <NotificationMessageViewModal
        message={selectedNotification?.relatedMessage}
        notification={selectedNotification}
        onClose={() => {
          setSelectedNotification(undefined);
          onViewMessageModalClose?.();
        }}
      />
      <SlideOver
        open={open}
        onClose={onClose}
        manualClose={!!selectedNotification}
        title={t("notification.subscriber_widget_title")}
      >
        <div className="flex flex-col justify-between gap-4 h-full">
          <div className="flex justify-left gap-4">
            <a className="btn-primary" href="/polestar/notifications">
              {t("common.view_all")}
            </a>
          </div>
          <div className="flex flex-col grow items-center h-auto justify-center">
            {notifications?.length > 0 ? (
              <NotificationList
                notifications={notifications}
                onView={(notification) => {
                  setSelectedNotification(notification);
                  onClose();
                }}
                showTags={true}
              />
            ) : (
              t("notification.no_notifications")
            )}
          </div>
        </div>
      </SlideOver>
    </>
  );
};

export default NotificationListSlideOver;

import api from "./api";

export enum SpecialClassCategory {
  DistributionLoss = "Distribution Loss",
  WaterHarvesting = "Water Harvesting",
  SpecialAnnouncement = "Special Announcement",
  StockAndDomestic = "Stock & Domestic",
  ForwardDraw = "Forward Draw",
}

export const getAllClassCategories = async (params?: any) => {
  const { data } = await api.get("/admin/classCategories", { params });
  return data.classCategories ?? [];
};

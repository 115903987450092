import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import MeterForm from "./MeterForm";
import ConfirmMeter from "./ConfirmMeter";
import Heading from "@components/layout/Heading";
import StepButton from "@components/shared/StepButton";
import StepInfo from "@components/shared/StepInfo";
import InfoPanel from "@components/form/InfoPanel";
import UploadEvidencesWorkflowForm from "@components/form/UploadEvidenceWorkflowForm";

import WorkflowPanels from "@components/form/WorkflowPanels";
import Loading from "@components/shared/Loading";
import ConfirmModal from "@components/shared/ConfirmModal";
import { StepProvider, useStepContext } from "@context/shared/StepContext";
import {
  MeterCreateOrEditProvider,
  useMeterCreateOrEditContext,
} from "@context/MeterCreateOrEditContext";
import {
  EvidenceProvider,
  useEvidenceContext,
} from "@context/shared/EvidenceContext";
import { useUpdateMeter } from "@hooks/mutation/useUpdateMeter";
import { convertMLToLiter } from "@utils/convertUnits";
import ENV from "@config/env";
import { DBTables } from "@utils/constants/dbTables";

const EditMeterWorkflow = () => {
  return (
    <StepProvider maxStep={3}>
      <MeterCreateOrEditProvider>
        <EvidenceProvider>
          <Consumer />
        </EvidenceProvider>
      </MeterCreateOrEditProvider>
    </StepProvider>
  );
};

const Consumer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { meterId = "" } = useParams();
  const { state } = useLocation();
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const { currentStep, stepHelpers } = useStepContext();
  const { getEvidencesInfo, uploadEvidences } = useEvidenceContext();
  const {
    meterDetails,
    setMeterDetails,
    getMeterInfo,
    setIsComplete,
    isComplete,
    workflowInstance,
    setWorkflowInstance,
  } = useMeterCreateOrEditContext();

  const { mutateAsync: updateMeterMutation } = useUpdateMeter();

  const handleCancel = () =>
    state?.from ? navigate(state.from) : navigate("/polestar");

  const handleConfirm = async () => {
    const [res, workflowInstance] = await updateMeterMutation({
      id: meterId,
      extractionPointId: "",
      serialNo: meterDetails.serialNo,
      unit: +meterDetails.unit,
      verifiedAt: meterDetails.verifiedAt
        ? new Date(meterDetails.verifiedAt)
        : undefined,
      type: meterDetails.type,
      ipAddress: meterDetails.ipAddress,
      clickOver: meterDetails?.clickOver
        ? convertMLToLiter(+meterDetails?.clickOver)
        : 0,
      threshold: meterDetails?.threshold
        ? convertMLToLiter(+meterDetails?.threshold)
        : 0,
    });

    await uploadEvidences({
      description: t("meter.adding_meter.evidence_data.description", {
        serialNo: res.serialNo,
      }),
      references: [
        {
          referenceId: res.id,
          referenceTable: DBTables.Meters,
        },
        {
          referenceId: res.subscriberId,
          referenceTable: DBTables.Subscribers,
        },
        {
          referenceId: res.extractionPoint?.subscriber?.level1ResourceId,
          referenceTable: DBTables.WRSHierarchies,
        },
        {
          referenceId: workflowInstance?.id,
          referenceTable: DBTables.WorkflowInstances,
        },
      ],
    });

    setIsComplete(true);
    setWorkflowInstance(workflowInstance);
  };

  const steps = [
    {
      label: (
        <StepButton
          index={0}
          currentStep={currentStep}
          isOnChain={isComplete}
          info={<StepInfo data={getMeterInfo().body} />}
        >
          {t("meter.adding_meter.enter_details")}
        </StepButton>
      ),
      panel: (
        <div className="p-6 space-y-6">
          <Heading light>{t("meter.adding_meter.enter_details")}</Heading>
          {meterId && !meterDetails.serialNo ? (
            <div className="py-20">
              <Loading />
            </div>
          ) : (
            <>
              <MeterForm
                defaultValues={meterDetails}
                onSubmit={(value) => {
                  setMeterDetails(value);
                  if (value.originalSerialNo === value.serialNo) {
                    stepHelpers.goToNextStep();
                    return;
                  }
                  setShowConfirmModal(true);
                }}
                onCancel={handleCancel}
                submitText={t("common.next_step") as string}
              />
              <ConfirmModal
                open={showConfirmModal}
                onClose={() => setShowConfirmModal(false)}
                onConfirm={stepHelpers.goToNextStep}
                confirmText={t("common.confirm") as string}
              >
                {t("meter.edit.modal.confirm_name", {
                  currentSerialNo: meterDetails.originalSerialNo,
                  newSerialNo: meterDetails.serialNo,
                })}
              </ConfirmModal>
            </>
          )}
        </div>
      ),
      infoPanel: (
        <InfoPanel
          warnings={[
            ENV.CLIENT_ID === "seqwater"
              ? t("meter.warning.update_serial_no")
              : undefined,
          ]}
        >
          <div className="space-y-6">
            <p>{t("meter.info.details")}</p>
            <p>{t("meter.info.units")}</p>
            <p>{t("meter.info.clickover")}</p>
            <p>{t("meter.info.verified_date")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={1}
          currentStep={currentStep}
          isOnChain={isComplete}
          info={<StepInfo data={getEvidencesInfo().body} />}
        >
          {t("common.enter_evidence")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          onNext={stepHelpers.goToNextStep}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("meter.info.evidence")}</p>
            <p>{t("evidence.supported")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton index={2} currentStep={currentStep} isOnChain={isComplete}>
          {t("meter.adding_meter.confirm")}
        </StepButton>
      ),
      panel: (
        <ConfirmMeter
          workflowInstanceIds={[workflowInstance?.id]}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          onPrevious={stepHelpers.goToPrevStep}
          onEdit={stepHelpers.setStep}
          data={[getMeterInfo(), getEvidencesInfo()]}
          isComplete={isComplete}
        />
      ),
      infoPanel: (
        <InfoPanel
          successes={
            isComplete
              ? [t("meter.info.success", { serialNo: meterDetails?.serialNo })]
              : [t("meter.info.confirm")]
          }
        >
          {isComplete ? (
            t("user.info.end_workflow")
          ) : (
            <ul className="space-y-4">
              <li>{t("common.info.verify")}</li>
              <li>
                <strong>{t("meter.adding_meter.details")}</strong>{" "}
                {t("meter.info.verify.details")}
              </li>
              <li>
                <strong>{t("meter.adding_meter.units_and_click_over")}</strong>{" "}
                {t("meter.info.verify.units")}
              </li>
              <li>
                <strong>{t("common.location")}</strong>{" "}
                {t("meter.info.verify.location")}
              </li>
              <li>
                <strong>{t("evidence.supporting")}:</strong>{" "}
                {t("evidence.info.verify")}
              </li>
              <li>{t("common.info.changes")}</li>
            </ul>
          )}
        </InfoPanel>
      ),
    },
  ];

  return <WorkflowPanels selectedStep={currentStep} steps={steps} />;
};

export default EditMeterWorkflow;

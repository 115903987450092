import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import OtpInput from "react18-input-otp";
import { resetPasswordConfirm as authResetPasswordConfirm } from "../../../services/auth";
import TextInput from "../TextInput";

type ResetPasswordConfirmationFormProps = {
  email: string;
  onConfirmed: () => void;
  onResendCode: () => void;
};

const ResetPasswordConfirmationForm: React.FunctionComponent<
  ResetPasswordConfirmationFormProps
> = ({ email, onConfirmed, onResendCode }) => {
  const { t } = useTranslation();
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const {
    mutateAsync: resetPasswordConfirm,
    isLoading,
    error,
  } = useMutation<any, any>(() =>
    authResetPasswordConfirm({ email, password, code })
  );

  const handleVerifyClick = async (e: any) => {
    e.preventDefault();

    await resetPasswordConfirm();

    onConfirmed();
  };

  const validatePassword = (password: string) => {
    return (
      !password ||
      new RegExp(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/
      ).test(password)
    );
  };

  return (
    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <form className="space-y-6" onSubmit={handleVerifyClick}>
        {error && (
          <p className="mt-2 text-sm text-red-600">
            {error?.response?.data?.message}
          </p>
        )}
        <div className="mt-2">
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              {t("auth.new_password")}
            </label>
            <div className="mt-1">
              <TextInput
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                errorMessage={
                  !validatePassword(password)
                    ? t("auth.new_password_validation_message")
                    : error && " "
                }
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="repassword"
              className="block text-sm font-medium text-gray-700"
            >
              {t("auth.new_password_confirm")}
            </label>
            <div className="mt-1">
              <TextInput
                type="password"
                required
                value={rePassword}
                onChange={(e) => setRePassword(e.target.value)}
                errorMessage={
                  rePassword && password !== rePassword
                    ? t("auth.new_password_confirm_validation_message")
                    : error && " "
                }
              />
            </div>
          </div>
          <p className="text-sm mt-3">
            {t("auth.reset_password_email_sent", { email })}
          </p>
          <OtpInput
            value={code}
            onChange={(value: any) => setCode(value)}
            numInputs={6}
            inputStyle="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none text-5xl 2xl:text-6xl text-center"
            containerStyle="w-full flex gap-4 mt-3 justify-center"
            errorStyle="border-red-300"
            isInputNum={true}
            hasErrored={!!error}
            autoComplete={"off"}
            isDisabled={isLoading}
          />
        </div>
        {!isLoading && (
          <div className="flex items-center justify-between">
            <div className="text-sm">
              <button
                type="button"
                className="font-semibold text-primary-3 hover:text-primary-2"
                onClick={onResendCode}
              >
                {t("auth.resend_code")}
              </button>
            </div>
          </div>
        )}
        <div>
          <button
            type="submit"
            className="btn-primary text-sm font-semibold w-full"
            disabled={
              isLoading ||
              !code ||
              !password ||
              !rePassword ||
              !validatePassword(password) ||
              password !== rePassword
            }
          >
            {t("auth.change_password")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ResetPasswordConfirmationForm;

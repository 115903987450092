import { useQuery } from "@tanstack/react-query";

import {
  getAllDeclarations,
  type GetAllDeclarationsParams,
} from "@services/zenith/declarations";

type QueryParams = GetAllDeclarationsParams & {
  limit: number;
  page: number;
};

type Params = {
  params: Partial<QueryParams>;
  [key: string]: any;
};

export const useAllDeclarations = ({
  params,
  ...options
}: Partial<Params> = {}) => {
  return useQuery<any>({
    queryKey: ["declarations", params],
    queryFn: () => getAllDeclarations(params),
    ...options,
  });
};

import { useMutation } from "@tanstack/react-query";

import api from "@services/api";

const mutation = async (payload: {
  subscriberId: string;
  meterId: string;
  usage: number;
  notes?: string;
}) => {
  const { data } = await api.post("/admin/balanceAdjustments/usage", payload);

  return [data.balanceAdjustment, data.workflowInstance];
};

export const useAdjustUsage = (options: Record<string, any> = {}) => {
  return useMutation(mutation, options);
};

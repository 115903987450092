import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import type { CellContext } from "@tanstack/react-table";

const ExpandableCell: React.FunctionComponent<CellContext<any, any>> = ({
  row,
  getValue,
}) => (
  <div
    className="inline-flex items-center gap-2"
    style={{
      paddingLeft: `${row.depth * 2}rem`,
    }}
  >
    <>
      {row.getCanExpand() ? (
        <button type="button" onClick={row.getToggleExpandedHandler()}>
          {row.getIsExpanded() ? (
            <ChevronDownIcon className="w-4 h-4" />
          ) : (
            <ChevronRightIcon className="w-4 h-4" />
          )}
        </button>
      ) : null}
      {getValue()}
    </>
  </div>
);

export default ExpandableCell;

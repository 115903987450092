import { Suspense, lazy, useState } from "react";
import classNames from "classnames";
import Select from "react-select";
import { add } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import ArrowRightCalendarIcon from "@components/icons/ArrowRightCalendarIcon";
import Card from "@components/layout/Card";
import Layout from "@components/layout/Layout";
import Loading from "@components/shared/Loading";
import { useAllAccountingPeriods } from "@hooks/query/useAllAccountingPeriods";
import { getMeterReadSequenceReports } from "@services/reports";
import { formatDate } from "@utils/formatDate";
import SelectLevel1Resource from "@components/form/SelectLevel1Resource";

const MeterReadSequenceReport = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [renderers, setRenderers] = useState<any>({});
  const [SelectedReport, setSelectedReport] = useState<any>();
  const [reportProps, setReportProps] = useState<{
    report?: any;
    fromDate?: Date;
    toDate?: Date;
    runAt?: Date;
    level1ResourceId?: string;
  }>({});
  const [filter, setFilter] = useState<{
    level1ResourceId?: string;
    report?: any;
    fromDate?: Date;
    toDate?: Date;
  }>({});
  useAllAccountingPeriods({
    options: {
      enabled: Boolean(filter?.level1ResourceId),
      refetchOnWindowFocus: false,
      onSuccess: (data: any) => {
        const fromDate = data[0]
          ? new Date(data[0].periodStart)
          : new Date(new Date().getFullYear(), 1, 1);
        let toDate = data[0]
          ? new Date(data[0].periodEnd)
          : new Date(data[0].getFullYear, 12, 31);

        toDate = add(
          add(toDate, { minutes: toDate.getTimezoneOffset(), days: 1 }),
          { seconds: -1 }
        );

        setFilter({
          ...filter,
          fromDate,
          toDate,
        });
      },
    },
    params: { level1ResourceId: filter?.level1ResourceId },
  });
  const { data: reports } = useQuery(
    ["reports", "meterReadSequence"],
    getMeterReadSequenceReports,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const updatedRenderers = { ...renderers };
        for (const report of data) {
          updatedRenderers[report.id] = lazy(
            () => import(`@components/reports/${report.type}`)
          );
        }

        setRenderers(updatedRenderers);
        setSelectedReport(undefined);

        const selectedReport = data.find((r: any) => r.id === id);
        if (selectedReport) {
          setFilter({ ...filter, report: selectedReport });
        }
      },
    }
  );

  const getReportOptions = () => {
    return reports?.map((report: any) => ({
      label: report.name,
      value: report.id,
    }));
  };

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const handleRenderReport = (e: any) => {
    e.preventDefault();

    if (renderers[filter.report?.id]) {
      setSelectedReport(renderers[filter.report?.id]);
      setReportProps({
        report: filter.report,
        fromDate: filter.fromDate,
        toDate: filter.toDate,
        runAt: new Date(),
        level1ResourceId: filter.level1ResourceId,
      });
    }
  };

  return (
    <Layout
      permissions={["ViewReports"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("reporting.title"),
        },
      ]}
      title={t("reporting.new_meter_read_sequence_report")}
    >
      <Card className="flex flex-col rounded-none p-0 grow">
        <div className="flex flex-col grow gap-4 divide-y">
          <form
            className="items-end gap-2 p-4 grid grid-cols-1 md:gird-cols-2 xl:grid-cols-4"
            onSubmit={handleRenderReport}
          >
            <div className="w-auto">
              <Label htmlFor="report">{t("reporting.filter_report")}</Label>
              <Select
                options={getReportOptions()}
                className="w-full"
                value={getReportOptions()?.find(
                  (e: any) => e.value === filter.report?.id
                )}
                onChange={(e: any) => {
                  handleFilterChange(
                    "report",
                    reports?.find((report: any) => report.id === e?.value)
                  );
                }}
              />
            </div>
            <div className="w-auto">
              <Label>{t("reporting.filter_level1wrs")}</Label>
              <SelectLevel1Resource
                value={filter?.level1ResourceId}
                onChange={(e: any) => {
                  handleFilterChange("level1ResourceId", e?.value);
                }}
                isClearable
              />
            </div>
            <div className="w-auto">
              <Label htmlFor="from-date">{t("reporting.filter_dates")}</Label>
              <div className="flex items-center gap-1">
                <TextInput
                  type="date"
                  placeholder="From"
                  value={
                    filter.fromDate
                      ? formatDate(filter.fromDate, "yyyy-MM-dd")
                      : ""
                  }
                  onChange={(e) => {
                    handleFilterChange(
                      "fromDate",
                      e.target.value
                        ? new Date(`${e.target.value}T00:00:00`)
                        : undefined
                    );
                  }}
                  className={classNames({
                    "disabled:text-gray-400": !filter.report,
                  })}
                  disabled={!filter.report || !filter.level1ResourceId}
                />
                <ArrowRightCalendarIcon className="w-10 h-10" />
                <TextInput
                  type="date"
                  placeholder="To"
                  value={
                    filter.toDate ? formatDate(filter.toDate, "yyyy-MM-dd") : ""
                  }
                  min={
                    filter.fromDate
                      ? formatDate(filter.fromDate, "yyyy-MM-dd")
                      : undefined
                  }
                  onChange={(e) => {
                    handleFilterChange(
                      "toDate",
                      e.target.value
                        ? new Date(`${e.target.value}T23:59:59`)
                        : undefined
                    );
                  }}
                  className={classNames({
                    "disabled:text-gray-400": !filter.report,
                  })}
                  disabled={!filter.report || !filter.level1ResourceId}
                />
              </div>
            </div>
            <div className="w-auto">
              <button
                type="submit"
                className="btn-secondary"
                disabled={!filter.report || !filter.fromDate || !filter.toDate}
              >
                {t("reporting.get_data")}
              </button>
            </div>
          </form>
          {SelectedReport && (
            <div className="p-4 flex flex-col grow">
              <Suspense fallback={<Loading />}>
                <SelectedReport {...reportProps} />
              </Suspense>
            </div>
          )}
        </div>
      </Card>
    </Layout>
  );
};

export default MeterReadSequenceReport;

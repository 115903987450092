import { getAllAdministrativeApprovals } from "@services/administrativeApprovals";
import { useQuery } from "@tanstack/react-query";
import { formatDate } from "@utils/formatDate";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../../../context/AppContext";
import DocumentIcon from "../../icons/DocumentIcon";
import Card from "../../layout/Card";
import Table from "../../layout/Table";

const PendingApplicationWidget: React.FunctionComponent = () => {
  const { checkPermissions } = useAppContext();
  const { t } = useTranslation();
  const [approvals, setApprovals] = useState<any[]>([]);
  useQuery(["exhangeApprovals"], () => getAllAdministrativeApprovals(), {
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      const latestSeasonalTransfer = data
        .filter((t: any) => t.type === 2)
        .sort(
          (a: any, b: any) =>
            new Date(a.lodgedAt).valueOf() - new Date(b.lodgedAt).valueOf()
        )?.[0];

      const latestPermanentTransfer = data
        .filter((t: any) => t.type === 1)
        .sort(
          (a: any, b: any) =>
            new Date(a.lodgedAt).valueOf() - new Date(b.lodgedAt).valueOf()
        )?.[0];

      setApprovals([
        {
          ...latestSeasonalTransfer,
          approvalTitle: t("approval.seasonal_water_assignments.title"),
        },
        {
          ...latestPermanentTransfer,
          approvalTitle: t("approval.permanent_trades.title"),
        },
      ]);
    },
  });

  if (!checkPermissions(["ViewApprovals"])) return null;

  return (
    <Card
      className="flex-grow"
      header={
        <p className="flex gap-3">
          <DocumentIcon /> {t("approval.widget_title")}
        </p>
      }
    >
      <Table
        className="text-xs"
        paginationClassName="bg-inherit"
        fields={[
          {
            title: t("approval.application"),
            name: "approvalTitle",
          },
          {
            title: t("approval.applied_at"),
            name: "createdAt",
          },
          {
            title: t("common.action"),
            name: "action",
          },
        ]}
        data={approvals?.map((app) => ({
          ...app,
          createdAt: formatDate(new Date(app.lodgedAt)),
          action: (
            <div className="flex gap-1 items-center w-full">
              <Link
                to={`/polestar/administrative_approvals#tab=${
                  app.type === 1 && 1
                }`}
                className="btn-default text-sm"
              >
                {t("common.view")}
              </Link>
            </div>
          ),
        }))}
        noRowsText={" "}
        pageSize={3}
      />
    </Card>
  );
};

export default PendingApplicationWidget;

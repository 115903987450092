import { FunctionComponent } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Legend from "@components/form/Legend";
import DropzoneBox from "@components/shared/DropzoneBox";
import { useMessageContext } from "@context/MessageContext";

type CreateNotificationStep2Props = {
  onCancel: () => void;
  onSubmit: () => void;
};

const CreateNotificationStep2: FunctionComponent<
  CreateNotificationStep2Props
> = ({ onCancel, onSubmit }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message, setMessage } = useMessageContext();
  const accept = {
    "image/jpeg": [".jpeg", ".jpg"],
    "image/png": [".png"],
    "application/pdf": [".pdf"],
    "application/msword": [".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
      ".xlsx",
    ],
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files: any) => setMessage({ ...message, evidence: files?.[0] }),
    accept: accept,
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSubmit?.();
  };

  return (
    <form className="flex flex-col grow" onSubmit={handleSubmit}>
      <div className="flex flex-col grow gap-10">
        <Legend>{t("common.attachment")}</Legend>
        <div className="mt-2">
          <DropzoneBox
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            files={message?.evidence ? [message.evidence] : []}
            multiple={false}
            onDelete={index => {
              setMessage({
                ...message,
                evidence: undefined,
              });
            }}
            acceptedFileTypes={accept}
          />
        </div>
      </div>
      <footer className="p-4 flex gap-2">
        <button
          type="button"
          className="btn-outline-primary text-sm font-semibold"
          onClick={onCancel}
        >
          {t("common.prev_step")}
        </button>
        <button type="submit" className="btn-primary text-sm font-semibold">
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-default text-sm font-semibold"
          onClick={() => navigate("/zenith/notifications")}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default CreateNotificationStep2;

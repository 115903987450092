import CreateEditBillingGroupCompleted from "@components/form/billing_group/CreateEditBillingGroupCompleted";
import CreateEditBillingGroupStep1 from "@components/form/billing_group/CreateEditBillingGroupStep1";
import CreateEditBillingGroupStep2 from "@components/form/billing_group/CreateEditBillingGroupStep2";
import WorkflowPanels from "@components/form/WorkflowPanels";
import Layout from "@components/layout/Layout";
import ConfirmModal from "@components/shared/ConfirmModal";
import StepButton from "@components/shared/StepButton";
import {
  BillingAndChargeProvider,
  useBillingAndChargeContext,
} from "@context/BillingAndChargeContext";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";
import { updateBillingGroup } from "@services/billingGroup";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

const EditBillingGroup: React.FunctionComponent = () => {
  return (
    <BillingAndChargeProvider>
      <MainComponent />
    </BillingAndChargeProvider>
  );
};

const MainComponent: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { id: billingGroupId } = useParams();
  const level1ResourceId = searchParams.get("level1ResourceId");

  const [currentStep, setCurrentStep] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const {
    billingGroupData,
    setBillingGroupData,
    workflowInstance,
    setWorkflowInstance,
  } = useBillingAndChargeContext();
  const { data: level1Resource } = useGetLevel1Resource(level1ResourceId || "");
  const { mutateAsync: saveBillingGroupData, isLoading } = useMutation<
    any,
    any,
    {
      id: string;
      name: string;
      level1ResourceId: string;
    }
  >((billingGroup) => updateBillingGroup(billingGroup));

  const handleStep1Save = (step1Data: any) => {
    setBillingGroupData({
      ...billingGroupData,
      billingGroup: step1Data.billingGroup,
    });

    setCurrentStep(1);
  };

  const handleStep2Save = () => {
    setIsModalOpen(true);
  };

  const handleSave = async () => {
    const { billingGroup } = billingGroupData;
    const { id, name, level1Resource } = billingGroup;

    try {
      const [, workflowInstance] = await saveBillingGroupData({
        id,
        name,
        level1ResourceId: level1Resource?.id,
      });

      setWorkflowInstance(workflowInstance);

      setIsModalOpen(false);
      setSuccess(true);
    } catch (e: any) {
      toast.error(
        <>
          <p>{t("billing_groups.save_failed")}</p>
          <p>{e.message}</p>
        </>,
        {
          className: "toast toast-error",
        }
      );

      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    if (billingGroupId) {
      setBillingGroupData({
        ...billingGroupData,
        billingGroup: { ...billingGroupData.billingGroup, id: billingGroupId },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingGroupId]);

  return (
    <Layout
      permissions={["UpdateBillingGroups"]}
      breadcrumb={
        level1ResourceId
          ? [
              {
                label: t("dashboard.dashboard"),
                href: "/polestar",
              },
              {
                label: level1Resource?.name,
                href: `/polestar/level1wrs/${level1ResourceId}`,
              },
              {
                label: t("billing_groups.edit_billing_group"),
              },
            ]
          : [
              {
                label: t("dashboard.dashboard"),
                href: "/polestar",
              },
              {
                label: t("billing_groups.edit_billing_group"),
              },
            ]
      }
      title={t("billing_groups.edit_billing_group")}
    >
      <WorkflowPanels
        contentClassName="border-none bg-transparent"
        selectedStep={currentStep}
        steps={[
          {
            label: (
              <StepButton
                index={0}
                currentStep={currentStep}
                isOnChain={success}
                info={
                  <div className="w-full pl-2 flex flex-col gap-2">
                    <dl className="grid grid-cols-1 gap-x-4">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-bold text-gray-500">
                          {t("billing_groups.field_name")}:
                        </dt>
                        <dd className="mt-1 text-sm text-gray-500">
                          {billingGroupData.billingGroup?.name}
                        </dd>
                      </div>
                    </dl>
                    <dl className="grid grid-cols-1 gap-x-4">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-bold text-gray-500">
                          {t("billing_groups.field_level1_resource")}:
                        </dt>
                        <dd className="mt-1 text-sm text-gray-500 truncate">
                          {billingGroupData.billingGroup?.level1Resource?.name}
                        </dd>
                      </div>
                    </dl>
                  </div>
                }
              >
                {t("billing_groups.create_step1_title")}
              </StepButton>
            ),
            panel: (
              <CreateEditBillingGroupStep1
                level1Resource={level1Resource}
                billingGroup={billingGroupData?.billingGroup}
                onSave={handleStep1Save}
              />
            ),
          },
          {
            label: (
              <StepButton
                index={1}
                currentStep={success ? 2 : currentStep}
                isOnChain={success}
              >
                {t("billing_groups.create_step2_title")}
              </StepButton>
            ),
            panel: success ? (
              <CreateEditBillingGroupCompleted
                billingGroup={billingGroupData?.billingGroup}
                workflowInstance={workflowInstance}
              />
            ) : (
              <CreateEditBillingGroupStep2
                level1Resource={level1Resource}
                billingGroup={billingGroupData?.billingGroup}
                onSave={handleStep2Save}
                onEditDetails={() => setCurrentStep(0)}
              />
            ),
          },
        ]}
      />

      <ConfirmModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleSave}
        confirmText={t("common.ledger") as string}
        isSubmitting={isLoading}
      >
        {t("billing_groups.modal_confirmation")}
      </ConfirmModal>
    </Layout>
  );
};

export default EditBillingGroup;

import React from "react";
import { noop } from "lodash";

import Label from "@components/form/Label";
import TextArea from "@components/form/TextArea";
import ConfirmModal from "@components/shared/ConfirmModal";
import { useActivateExtractionRight } from "@hooks/mutation/useActivateExtractionRight.ts";
import { toastSuccess, toastError } from "@utils/toast";
import { useTranslation } from "react-i18next";

type ConfirmUpdateExtractionRightStatusModalProps = {
  open: boolean;
  extractionRight: {
    id: string;
    name: string;
    isActive: boolean;
  };
  onClose: () => void;
  onSuccess?: () => void;
};
const ConfirmUpdateExtractionRightStatusModal: React.FunctionComponent<
  ConfirmUpdateExtractionRightStatusModalProps
> = ({ open, extractionRight, onClose, onSuccess = noop }) => {
  const { t } = useTranslation();
  const [note, setNote] = React.useState("");

  const { mutateAsync: activateExtractionRightMutation, isLoading } =
    useActivateExtractionRight();

  const status = extractionRight.isActive ? "deactivate" : "activate";
  const handleOnConfirm = async () => {
    try {
      await activateExtractionRightMutation({
        extractionRightId: extractionRight?.id,
        active: !extractionRight.isActive,
        note,
      });
      setNote("");
      toastSuccess(
        t(`extraction_right.update_status.${status}.toast.success`, {
          name: extractionRight?.name,
        }),
      );
      onSuccess();
    } catch (error: any) {
      toastError(t(`extraction_right.update_status.${status}.toast.failed`));
    }

    onClose();
  };

  return (
    <ConfirmModal
      open={open}
      onConfirm={async () => {
        await handleOnConfirm();
      }}
      onClose={onClose}
      isSubmitting={isLoading}
    >
      {t(`extraction_right.update_status.${status}.modal.confirm`, {
        name: extractionRight.name,
      })}
      <div className="font-normal mt-2 text-gray-700">
        <Label htmlFor="note" optional>
          {t("extraction_right.update_status.enter_note")}
        </Label>
        <TextArea
          id="note"
          value={note}
          rows={4}
          onChange={(e) => {
            setNote(e.target.value);
          }}
        />
      </div>
    </ConfirmModal>
  );
};

export default ConfirmUpdateExtractionRightStatusModal;

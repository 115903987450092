import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Layout from "@components/layout/Layout";
import DecommissionWorkFlow from "@components/form/meter/decommission/DecommissionWorkflow";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";

const DecommissionMeter = () => {
  const { t } = useTranslation();
  const { id: level1ResourceId = "" } = useParams();

  const { data: level1Resource = {} } = useGetLevel1Resource(level1ResourceId, {
    enabled: Boolean(level1ResourceId),
  });

  return (
    <Layout
      permissions={[
        "UpdateExtractionPoint",
        "CreateDeclarations",
        "DecommissionMeters",
      ]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: level1Resource?.name,
          href: `/polestar/level1wrs/${level1ResourceId}`,
        },
        {
          label: t("level1wrs.extraction_points"),
          href: `/polestar/level1wrs/${level1ResourceId}#5`,
        },
        {
          label: t("meter.decommission.title"),
        },
      ]}
      title={t("meter.decommission.title")}
      accountingPeriodLevel1ResourceId={level1ResourceId}
    >
      <DecommissionWorkFlow />
    </Layout>
  );
};

export default DecommissionMeter;

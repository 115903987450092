import { useQuery } from "@tanstack/react-query";

import { getMessageTemplates } from "@services/messageTemplates";

type Params = {
  names?: string[];
};

type UseGetMessageTemplatesParams = {
  params?: Partial<Params>;
  [key: string]: any;
};

export const useMessageTemplates = ({
  params,
  ...options
}: UseGetMessageTemplatesParams = {}) => {
  return useQuery<any>({
    queryKey: ["messageTemplates", params],
    queryFn: () => getMessageTemplates(params),
    ...options,
  });
};

import {
  createAccountingPeriod,
  AccountingPeriod,
} from "@services/accountingPeriod";
import { useMutation } from "@tanstack/react-query";

export const useCreateAccountingPeriod = (options: any = {}) => {
  return useMutation<any, any, AccountingPeriod>({
    mutationKey: ["create_accounting_period"],
    mutationFn: createAccountingPeriod,
    ...options,
  });
};

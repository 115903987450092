import React from "react";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import ConfirmModal from "@components/shared/ConfirmModal";
import ConfirmationDetail from "@components/shared/ConfirmationDetail";
import { useAmalgamateOrSubdivideExtractionRightsContext } from "@context/AmalgamateOrSubdivideExtractionRightContext";
import { useEvidenceContext } from "@context/shared/EvidenceContext";
import { toastError, toastSuccess } from "@utils/toast";
import { isValidationError } from "@utils/formError";
import { DBTables } from "@utils/constants/dbTables";
import { useUpdateAmalgamationOrSubdivisionRequest } from "@hooks/mutation/useUpdateAmalgamationOrSubdivisionRequest";

const AmalgamateOrSubdivideLedger = () => {
  const { t } = useTranslation();
  const {
    details,
    inputExtractionRights,
    context,
    stepHelpers,
    setWorkflowInstance,
    setWorkflowCompleted,
    outputExtractionRightTable,
    inputExtractionRightTable,
    setCancelIndex,
    setNetworkErrors,
  } = useAmalgamateOrSubdivideExtractionRightsContext();
  const { reset, uploadEvidences, getEvidencesInfo } = useEvidenceContext();
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { mutateAsync: updateAmalgamationOrSubdivisionRequestMutation } =
    useUpdateAmalgamationOrSubdivisionRequest();

  const handleOnConfirm = async () => {
    const { id: administrativeApprovalId } = details;
    setIsLoading(true);
    try {
      const { workflowInstance } =
        await updateAmalgamationOrSubdivisionRequestMutation({
          administrativeApprovalId,
          pending: true,
        });
      setWorkflowInstance(workflowInstance);

      await uploadEvidences({
        description: t(
          "approval.subdivide_and_amalgamate.create.step_5.description",
          {
            extractionRightNames: inputExtractionRights
              .map(er => er.name)
              .join(", "),
            context,
          },
        ) as string,
        references: [
          {
            referenceId: details.id,
            referenceTable: DBTables.AdministrativeApproval,
          },
          {
            referenceId: details.subscriber.id,
            referenceTable: DBTables.Subscribers,
          },
          {
            referenceId: details.level1wrs.id,
            referenceTable: DBTables.WRSHierarchies,
          },
          {
            referenceId: workflowInstance.id,
            referenceTable: DBTables.WorkflowInstances,
          },
        ],
      });

      toastSuccess(
        t("approval.subdivide_and_amalgamate.create.toast.create_success", {
          context,
        }),
      );
      reset();
      setWorkflowCompleted(true);
    } catch (error: any) {
      let errorMessages = [];
      if (isValidationError(error)) {
        const { errors = [] } = error?.response?.data;
        errorMessages = errors.map((i: any) => i.message);
      } else {
        errorMessages = [error?.message];
      }
      setNetworkErrors(errorMessages);
      toastError(
        <>
          <p>
            {t("approval.subdivide_and_amalgamate.create.toast.error", {
              context,
            })}
          </p>
          {errorMessages.length > 0 && (
            <ul className="list-disc pl-4">
              {errorMessages.map((text: any) => {
                return <li key={text}>{text}</li>;
              })}
            </ul>
          )}
        </>,
      );
    }

    setIsLoading(false);
    setShowConfirmModal(false);
  };

  const confirmData = [
    {
      title: t("level1wrs.create.details"),
      body: [
        { key: t("level1wrs.create.name"), value: details.level1wrs?.name },
        {
          key: t("level1wrs.create.identifier"),
          value: details.level1wrs?.identifier,
        },
      ],
      onChain: true,
    },
    {
      title: t("subscriber.create.subscriber_details"),
      body: [
        {
          key: t("subscriber.create.subscriber_name"),
          value: details.subscriber?.name,
        },
        {
          key: t("subscriber.identifier"),
          value: details.subscriber?.accountNumber,
        },
      ],
      onChain: true,
    },
    {
      title: t("extraction_right.create.step_2.details"),
      body: [
        {
          key: t("common.extraction_rights"),
          value: inputExtractionRights.map(er => er?.name).join(", "),
        },
        {
          key: t("common.level0wrs"),
          value: details.level0wrs?.identifier,
        },
        {
          key: t("common.single_water_class"),
          value: inputExtractionRights[0]?.waterClass?.name,
        },
      ],
      onChain: true,
    },
    {
      title: t("approval.subdivide_and_amalgamate.create.step_4.step_name", {
        context,
      }),
      body: [
        {
          key: t("approval.subdivide_and_amalgamate.create.step_4.new", {
            context,
          }),
          value: outputExtractionRightTable,
        },
        {
          key: t("approval.subdivide_and_amalgamate.create.step_4.original", {
            context,
          }),
          value: inputExtractionRightTable,
        },
      ],
    },
    getEvidencesInfo(),
  ];

  return (
    <>
      <div className="flex flex-col gap-6 p-6 grow justify-between">
        <Heading light>
          {t("approval.subdivide_and_amalgamate.create.step_6.title", {
            context,
          })}
        </Heading>
        <ConfirmationDetail data={confirmData} onEdit={stepHelpers.setStep} />
      </div>

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>
        <button
          type="button"
          className="btn-primary"
          onClick={() => setShowConfirmModal(true)}
        >
          {t("common.ledger")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={() => setCancelIndex(0)}
        >
          {t("common.cancel")}
        </button>
      </footer>

      <ConfirmModal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleOnConfirm}
        isSubmitting={isLoading}
        confirmText={t("common.ledger")}
      >
        {t("approval.subdivide_and_amalgamate.create.confirm", { context })}
      </ConfirmModal>
    </>
  );
};

export default AmalgamateOrSubdivideLedger;

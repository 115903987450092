import api from "./api";

export type AccountingPeriod = {
  level1ResourceId: string;
  periodStart: Date;
  periodEnd: Date;
};

export const createAccountingPeriod = async (
  accountingPeriod: AccountingPeriod,
): Promise<any> => {
  const { data } = await api.post(`/admin/accountingPeriods`, accountingPeriod);

  return [data.accountingPeriod, data.workflowInstance];
};

export type GetAllAccountingPeriodsParams = {
  level1ResourceId: string;
  querySummary: boolean;
  isActive: boolean;
};

export const getAllAccountingPeriods = async (
  params?: Partial<GetAllAccountingPeriodsParams>,
): Promise<any> => {
  const { data } = await api.get("admin/accountingPeriods", { params });

  return data.accountingPeriods;
};
export type updateYearPayload = {
  periodId: string;
  periodStart: Date;
  periodEnd: Date;
};

export const updateAccountingPeriod = async (payload: updateYearPayload) => {
  const { periodId, ...year } = payload;
  const { data } = await api.patch(`/admin/accountingPeriods/${periodId}`, {
    ...year,
  });

  return data.accountingPeriod;
};

import React from "react";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import DropzoneBox from "@components/shared/DropzoneBox";
import { useEvidenceContext } from "@context/shared/EvidenceContext";

type UploadEvidencesProps = {
  onPrevious: () => void;
  onSubmit: () => void;
  onCancel: () => void;
};

const UploadEvidences: React.FunctionComponent<UploadEvidencesProps> = ({
  onPrevious,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();

  const { evidences, getRootProps, getInputProps, handleDeleteFile } =
    useEvidenceContext();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form className="flex flex-col grow" onSubmit={handleSubmit}>
      <Heading light className="mb-8">
        {t("evidence.upload")}
      </Heading>

      <DropzoneBox
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        files={evidences}
        onDelete={handleDeleteFile}
      />

      <div className="grow" />

      <footer className="py-4 flex gap-2">
        <button
          type="button"
          className="btn-outline-primary text-sm font-semibold"
          onClick={onPrevious}
        >
          {t("common.prev_step")}
        </button>
        <button type="submit" className="btn-primary text-sm font-semibold">
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-default text-sm font-semibold"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default UploadEvidences;

import { useMutation } from "@tanstack/react-query";

import { checkAdminTransferRelationship } from "@services/administrativeApprovals";

export function useValidateAdminTransferRelationship(options: any = {}) {
  return useMutation<
    any,
    any,
    { extractionRightId: string; extractionPointId: string }
  >({
    mutationKey: ["check_admin_transfer_relationship"],
    mutationFn: checkAdminTransferRelationship,
    ...options,
  });
}

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { orderBy } from "lodash";

import Modal from "@components/layout/Modal";
import SelectLevel1Resource from "@components/form/SelectLevel1Resource";
import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import { formatDateInput } from "@utils/formatDate";
import Legend from "@components/form/Legend";
import { useAllAccountingPeriods } from "@hooks/query/useAllAccountingPeriods";
import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";
import InformationCircleIcon from "@components/icons/InformationCircleIcon";
import { toastError, toastSuccess } from "@utils/toast";
import ConfirmModal from "@components/shared/ConfirmModal";
import { useCreateAccountingPeriod } from "@hooks/mutation/useCreateAccountingPeriod";
import { AccountingPeriod } from "@services/accountingPeriod";
import { isValidationError } from "@utils/formError";

type CreateAccountingPeriodModalProps = {
  open: boolean;
  level1Resource?: any;
  onClose: () => void;
};

const CreateAccountingPeriodModal: React.FunctionComponent<
  CreateAccountingPeriodModalProps
> = ({ open, level1Resource, onClose }: CreateAccountingPeriodModalProps) => {
  const { t } = useTranslation();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const level1ResourceId = level1Resource?.id || "";

  const [accountingPeriodDetails, setAccountingPeriodDetails] =
    useState<AccountingPeriod>({
      level1ResourceId: level1ResourceId,
      periodStart: new Date(),
      periodEnd: new Date(),
    });

  const { mutateAsync: createAccountingPeriodMutation, isLoading } =
    useCreateAccountingPeriod();

  const { data: latestAccountingPeriod = {}, refetch } =
    useAllAccountingPeriods({
      options: {
        enabled: Boolean(level1ResourceId),
        select: (data: any) => {
          const sortedData = orderBy(data, "periodEnd", "desc");
          return sortedData[0];
        },
        onSuccess: (data: any) => {
          const latestEndDate = new Date(data.periodEnd);

          const periodStart = new Date(latestEndDate);
          periodStart.setDate(periodStart.getDate() + 1);

          const periodEnd = new Date(latestEndDate);
          periodEnd.setFullYear(periodEnd.getFullYear() + 1);

          setAccountingPeriodDetails({
            ...accountingPeriodDetails,
            periodStart,
            periodEnd,
          });
        },
      },
      params: { level1ResourceId },
    });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setConfirmOpen(true);
  };

  const ledgerAccountingPeriod = async () => {
    try {
      await createAccountingPeriodMutation({
        ...accountingPeriodDetails,
      });
      setConfirmOpen(false);
      refetch();
      onClose();
      toastSuccess(t("accounting_period.create.toast.success"));
    } catch (error: any) {
      const { data } = error?.response;
      const errorMessages = isValidationError(error)
        ? data?.errors?.map((i: any) => i.message)
        : [data?.message];

      toastError(
        <>
          <p>{t("accounting_period.create.toast.failed")}</p>
          {errorMessages?.length ? (
            <ul className="list-disc pl-4">
              {errorMessages.map((text: any) => {
                return <li key={text}>{text}</li>;
              })}
            </ul>
          ) : null}
        </>,
      );
      setConfirmOpen(false);
    }
  };

  const handleChangeDetails = (key: keyof AccountingPeriod, value: any) => {
    setAccountingPeriodDetails(prevState => {
      const updatedDetails = { ...prevState };
      updatedDetails[key] = value;
      return updatedDetails;
    });
  };

  return (
    <>
      <Modal open={open}>
        <header className="border-b p-4">
          <h3 className="text-lg font-bold leading-6 text-gray-900">
            {level1ResourceId
              ? t("accounting_period.create.modal.title_level1wrs", {
                  level1wrsName: level1Resource?.name,
                })
              : t("accounting_period.create.modal.title")}
          </h3>
        </header>
        <form className="flex flex-col" onSubmit={handleSubmit}>
          <fieldset className="space-y-1 p-6 mt-4">
            <Legend light>{t("level1wrs.select")}</Legend>
            <Label htmlFor="periodStart">{t("level1wrs.select")}</Label>
            <SelectLevel1Resource
              className="max-w-md"
              isDisabled={Boolean(level1ResourceId)}
              value={level1Resource?.id}
              onChange={e => handleChangeDetails("level1ResourceId", e?.value)}
            />
          </fieldset>
          <fieldset className="space-y-4 p-6">
            <Legend light>{t("accounting_period.create.modal.set")}</Legend>
            {latestAccountingPeriod ? (
              <div className="flex items-start gap-2">
                <InformationCircleIcon className="text-yellow-600 w-5 h-5 shrink-0" />
                <span className="text-sm">
                  {t("common.latest_accounting_period")}:{" "}
                  {formatAccountingPeriod(latestAccountingPeriod)}
                </span>
              </div>
            ) : null}

            <div>
              <Label htmlFor="periodStart">
                {t("accounting_period.create.modal.enter_period_start")}
              </Label>
              <TextInput
                id="period_start"
                type="date"
                className="max-w-md"
                value={formatDateInput(accountingPeriodDetails.periodStart)}
                onChange={e =>
                  handleChangeDetails("periodStart", new Date(e.target.value))
                }
                min={
                  latestAccountingPeriod?.periodEnd
                    ? formatDateInput(
                        new Date(
                          new Date(
                            new Date(latestAccountingPeriod.periodEnd).setDate(
                              new Date(
                                latestAccountingPeriod.periodEnd,
                              ).getDate() + 1,
                            ),
                          ).setHours(0, 0, 0, 0), // Set time to start of the day
                        ),
                      )
                    : ""
                }
                disabled={!Boolean(latestAccountingPeriod)}
                required
              />
            </div>

            <div>
              <Label htmlFor="periodEnd">
                {t("accounting_period.create.modal.enter_period_end")}
              </Label>
              <TextInput
                id="period_end"
                type="date"
                className="max-w-md"
                value={formatDateInput(accountingPeriodDetails.periodEnd)}
                onChange={e =>
                  handleChangeDetails("periodEnd", new Date(e.target.value))
                }
                disabled={!Boolean(latestAccountingPeriod)}
                min={formatDateInput(accountingPeriodDetails.periodStart)}
                required
              />
            </div>
          </fieldset>
          <div className="mt-5 border-t p-4 flex flex-row-reverse gap-3 text-sm">
            <button onClick={onClose} className="btn-primary">
              {t("common.cancel")}
            </button>
            <button type="submit" className="btn-outline-primary rounded">
              {t("common.confirm")}
            </button>
          </div>
        </form>
      </Modal>
      <ConfirmModal
        open={confirmOpen}
        onConfirm={() => ledgerAccountingPeriod()}
        confirmText={t("common.ledger")}
        isSubmitting={isLoading}
        onClose={() => setConfirmOpen(false)}
      >
        {t("accounting_period.create.modal.confirm", {})}
      </ConfirmModal>
    </>
  );
};

export default CreateAccountingPeriodModal;

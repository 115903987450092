import classNames from "classnames";

type Props = {
  children: React.ReactNode;
  light?: boolean;
  className?: string;
};

const Legend: React.FunctionComponent<Props> = ({
  children,
  light = false,
  className,
}) => {
  return (
    <legend
      className={classNames(
        "block w-full pb-3 font-bold text-primary-1 text-xl border-b border-gray-200",
        light ? "text-primary-3 font-thin pb-1" : "",
        className,
      )}
    >
      {children}
    </legend>
  );
};

export default Legend;

import React from "react";
import Select from "react-select";

import ErrorMessage from "@components/form/ErrorMessage";
import { useAllSubscriberExtractionPoints } from "@hooks/query/zenith/useAllExtractionPoints";

type SelectProps = React.ComponentProps<Select>;

type Props = Exclude<SelectProps, "value"> & {
  value: string;
  errorMessage?: string;
  definedByWalletId: string;
};

const SelectSubscriberExtractionPoint: React.FunctionComponent<Props> = ({
  errorMessage,
  value,
  definedByWalletId,
  ...props
}) => {
  const { data: options = [], isLoading } = useAllSubscriberExtractionPoints({
    params: { definedByWalletId },
    options: {
      select: (data: any) => {
        return data.map((ep: any) => ({
          label: ep.name,
          value: ep.id,
          data: ep,
        }));
      },
    },
  });

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Select
        {...props}
        options={options}
        value={value ? options.find((i: any) => i.value === value) : undefined}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};

export default SelectSubscriberExtractionPoint;

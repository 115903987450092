import React from "react";
import { flatten } from "lodash";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import Legend from "@components/form/Legend";
import CheckboxInputWithLabel from "@components/form/CheckboxInputWithLabel";
import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import AdvSection from "@components/form/AdvSelect";
import { useLevel0WRSContext } from "@context/Level0WRSContext";
import { useAllLevel1Resources } from "@hooks/query/useAllLevel1Resources";
import ENV from "@config/env";

const Level0WRSTradingLinks = () => {
  const { t } = useTranslation();
  const { stepHelpers, details, handleChangeDetails, navigateForCancel } =
    useLevel0WRSContext();

  const { data: level1Resources = [] } = useAllLevel1Resources();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    stepHelpers.goToNextStep();
  };

  const groupOptions = level1Resources?.map((item: any) => {
    return {
      label: item.name,
      options: item.level0WRSMembers.map((zone: any) => ({
        label: zone.identifier,
        value: zone.id,
      })),
    };
  });

  const filterGroupOptionsByValues = (
    groupOptions: any[],
    selectedValues: string[] = []
  ) => {
    const result: any[] = [];

    groupOptions.forEach((item) => {
      const filteredOptions = item?.options?.filter((opt: any) =>
        selectedValues.includes(opt.value)
      );
      if (filteredOptions?.length) {
        result.push({
          ...item,
          options: filteredOptions,
        });
      }
    });

    return result;
  };

  return (
    <form className="flex flex-col gap-6 grow p-6" onSubmit={handleSubmit}>
      <Heading light>
        {t("level0wrs.create.trading_from_zone_title", {
          identifier: details?.identifier,
        })}
      </Heading>
      <fieldset className="space-y-4">
        <Legend>
          {t("level0wrs.create.inside_zone", {
            identifier: details?.identifier,
          })}
        </Legend>
        <div>
          <CheckboxInputWithLabel
            htmlFor="is_internal_trade_allowed"
            label={
              <strong>{t("level0wrs.create.is_internal_trade_allowed")}</strong>
            }
            checked={details.trade.isInternalTradeAllowed}
            onChange={(e) =>
              handleChangeDetails(
                "trade",
                e.target.checked,
                "isInternalTradeAllowed"
              )
            }
          />
          <p className="ml-7 text-sm text-gray-500">
            {t("level0wrs.create.is_internal_trade_allowed_info", {
              identifier: details?.identifier,
            })}
          </p>
        </div>
        {details.trade.isInternalTradeAllowed && (
          <div>
            <CheckboxInputWithLabel
              htmlFor="is_internal_trade_limited"
              label={
                <strong>
                  {t("level0wrs.create.is_internal_trade_limited")}
                </strong>
              }
              checked={details.trade.isInternalTradeLimited}
              onChange={(e) =>
                handleChangeDetails(
                  "trade",
                  e.target.checked,
                  "isInternalTradeLimited"
                )
              }
            />
            <p className="ml-7 text-sm text-gray-500">
              {t("level0wrs.create.is_internal_trade_limited_info")}
            </p>
          </div>
        )}
        {details.trade.isInternalTradeLimited && (
          <div>
            <Label htmlFor="internal_trade_limit">
              {t("level0wrs.create.enter_internal_trade_limit")}
            </Label>
            <TextInput
              name="internal_trade_limit"
              className="max-w-md"
              onChange={(e) =>
                handleChangeDetails(
                  "trade",
                  e.target.value,
                  "internalTradeLimit"
                )
              }
              value={details.trade.internalTradeLimit}
              placeholder="0.000"
              suffix={t("common.volume_unit")}
              required
            />
          </div>
        )}
      </fieldset>

      {ENV.CLIENT_ID !== "seqwater" && (
        <fieldset className="space-y-4">
          <Legend>{t("level0wrs.create.between_zones")}</Legend>
          <div className="flex flex-col md:flex-row gap-2">
            <div className="w-full md:w-1/2 space-y-4">
              <div>
                <CheckboxInputWithLabel
                  htmlFor="is_inbound_trade_allowed"
                  label={
                    <strong>
                      {t("level0wrs.create.is_inbound_trade_allowed")}
                    </strong>
                  }
                  checked={details.trade.isInboundTradeAllowed}
                  onChange={(e) => {
                    handleChangeDetails(
                      "trade",
                      e.target.checked,
                      "isInboundTradeAllowed"
                    );

                    if (!e.target.checked) {
                      handleChangeDetails(
                        "trade",
                        false,
                        "isInboundTradeLimited"
                      );
                    }
                  }}
                />
                <p className="ml-7 text-sm text-gray-500">
                  {t("level0wrs.create.is_inbound_trade_allowed_info", {
                    identifier: details?.identifier,
                  })}
                </p>
              </div>
              {details.trade.isInboundTradeAllowed && (
                <div>
                  <CheckboxInputWithLabel
                    htmlFor="is_inbound_trade_limited"
                    label={
                      <strong>
                        {t("level0wrs.create.is_inbound_trade_limited")}
                      </strong>
                    }
                    checked={details.trade.isInboundTradeLimited}
                    onChange={(e) =>
                      handleChangeDetails(
                        "trade",
                        e.target.checked,
                        "isInboundTradeLimited"
                      )
                    }
                  />
                  <p className="ml-7 text-sm text-gray-500">
                    {t("level0wrs.create.is_inbound_trade_limited_info")}
                  </p>
                </div>
              )}
              {details.trade.isInboundTradeLimited && (
                <div>
                  <Label htmlFor="inbound_trade_limit">
                    {t("level0wrs.create.enter_inbound_trade_limit")}
                  </Label>
                  <TextInput
                    name="inbound_trade_limit"
                    className="max-w-md"
                    onChange={(e) =>
                      handleChangeDetails(
                        "trade",
                        e.target.value,
                        "inboundTradeLimit"
                      )
                    }
                    value={details.trade.inboundTradeLimit}
                    placeholder="0.000"
                    suffix={t("common.volume_unit")}
                    required
                  />
                </div>
              )}
            </div>

            <div className="w-full md:w-1/2 space-y-4">
              <div>
                <CheckboxInputWithLabel
                  htmlFor="is_outbound_trade_allowed"
                  label={
                    <strong>
                      {t("level0wrs.create.is_outbound_trade_allowed")}
                    </strong>
                  }
                  checked={details.trade.isOutboundTradeAllowed}
                  onChange={(e) => {
                    handleChangeDetails(
                      "trade",
                      e.target.checked,
                      "isOutboundTradeAllowed"
                    );

                    if (!e.target.checked) {
                      handleChangeDetails(
                        "trade",
                        false,
                        "isOutboundTradeLimited"
                      );
                    }
                  }}
                />
                <p className="ml-7 text-sm text-gray-500">
                  {t("level0wrs.create.is_outbound_trade_allowed_info", {
                    identifier: details?.identifier,
                  })}
                </p>
              </div>
              {details.trade.isOutboundTradeAllowed && (
                <div>
                  <CheckboxInputWithLabel
                    htmlFor="is_outbound_trade_limited"
                    label={
                      <strong>
                        {t("level0wrs.create.is_outbound_trade_limited")}
                      </strong>
                    }
                    checked={details.trade.isOutboundTradeLimited}
                    onChange={(e) =>
                      handleChangeDetails(
                        "trade",
                        e.target.checked,
                        "isOutboundTradeLimited"
                      )
                    }
                  />
                  <p className="ml-7 text-sm text-gray-500">
                    {t("level0wrs.create.is_outbound_trade_limited_info")}
                  </p>
                </div>
              )}
              {details.trade.isOutboundTradeLimited && (
                <div>
                  <Label htmlFor="outbound_trade_limit">
                    {t("level0wrs.create.enter_outbound_trade_limit")}
                  </Label>
                  <TextInput
                    name="outbound_trade_limit"
                    className="max-w-md"
                    onChange={(e) =>
                      handleChangeDetails(
                        "trade",
                        e.target.value,
                        "outboundTradeLimit"
                      )
                    }
                    value={details.trade.outboundTradeLimit}
                    placeholder="0.000"
                    suffix={t("common.volume_unit")}
                    required
                  />
                </div>
              )}
            </div>
          </div>
        </fieldset>
      )}

      {details?.trade.isInboundTradeAllowed && (
        <fieldset className="space-y-4">
          <Legend>{t("level0wrs.create.inbound_trading")}</Legend>
          {details?.trade.isOutboundTradeAllowed && (
            <div>
              <CheckboxInputWithLabel
                htmlFor="is_reciprocal_trading"
                label={
                  <strong>{t("level0wrs.create.is_reciprocal_trading")}</strong>
                }
                checked={details.trade.isReciprocalTrading}
                onChange={(e) =>
                  handleChangeDetails(
                    "trade",
                    e.target.checked,
                    "isReciprocalTrading"
                  )
                }
              />
              <p className="ml-7 text-sm text-gray-500">
                {t("level0wrs.create.is_reciprocal_trading_info")}
              </p>
            </div>
          )}
          <AdvSection
            groupOptions={groupOptions}
            labelRight={t("level0wrs.create.inbound_trading_to_zone", {
              identifier: details?.identifier,
            })}
            value={filterGroupOptionsByValues(
              groupOptions,
              details?.trade?.inboundZoneExchanges
            )}
            onChange={(selectedZones) => {
              const zoneIds = flatten(
                selectedZones.map((i) => i.options.map((o) => o.value))
              );
              handleChangeDetails("trade", zoneIds, "inboundZoneExchanges");

              if (details.trade.isReciprocalTrading) {
                handleChangeDetails("trade", zoneIds, "outboundZoneExchanges");
              }
            }}
            filterLabel={t("level0wrs.filter.level0")}
          />
        </fieldset>
      )}
      {details?.trade.isOutboundTradeAllowed && (
        <fieldset className="space-y-4">
          <Legend>{t("level0wrs.create.outbound_trading")}</Legend>
          <AdvSection
            groupOptions={groupOptions}
            labelRight={t("level0wrs.create.outbound_trading_from_zone", {
              identifier: details?.identifier,
            })}
            value={filterGroupOptionsByValues(
              groupOptions,
              details?.trade.outboundZoneExchanges
            )}
            onChange={(selectedZones) => {
              const zoneIds = flatten(
                selectedZones.map((i) => i.options.map((o) => o.value))
              );
              handleChangeDetails("trade", zoneIds, "outboundZoneExchanges");

              if (details.trade.isReciprocalTrading) {
                handleChangeDetails("trade", zoneIds, "inboundZoneExchanges");
              }
            }}
            filterLabel={t("level0wrs.filter.level0")}
          />
        </fieldset>
      )}
      <div className="grow" />
      <footer className="flex gap-4 -mx-6 p-6 pb-0 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>
        <button type="submit" className="btn-primary">
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default Level0WRSTradingLinks;

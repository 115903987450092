import classNames from "classnames";
import { useTranslation } from "react-i18next";

import CheckCircleOutlineIcon from "@components/icons/CheckCircleOutlineIcon";

type UpdateThresholdStep1LabelProps = {
  meter?: { serialNo: string };
  isSelected?: boolean;
};

const UpdateThresholdStep1Label: React.FunctionComponent<
  UpdateThresholdStep1LabelProps
> = ({ meter, isSelected }) => {
  const { t } = useTranslation();

  if (meter) {
    return (
      <div className="flex flex-col">
        <div className="w-full h-full text-left flex items-center text-sm text-green-600">
          <span className="m-2">
            <CheckCircleOutlineIcon />
          </span>
          <span>{t("meter.select_meter")}</span>
        </div>

        <div className="w-full pl-2 flex gap-2">
          <div className="text-sm font-bold text-gray-500">
            {t("meter.serial_no")}:
          </div>
          <div className="text-sm text-gray-500">{meter.serialNo}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-full text-left flex items-center text-sm">
      <span
        className={classNames(
          "rounded-full h-5 w-5 font-bold text-center flex justify-center items-center m-2",
          {
            "bg-primary-2 text-white": isSelected,
            "bg-gray-500 text-white": !isSelected,
          }
        )}
      >
        1
      </span>
      <span>{t("meter.select_meter")}</span>
    </div>
  );
};

export default UpdateThresholdStep1Label;

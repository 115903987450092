import { NavLink } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";

type MenuItemMobileProps = {
  item: {
    label: string;
    href?: string;
    permissions?: string[];
    items?: Array<{
      label: string;
      href?: string;
      permissions?: string[];
      disabled?: boolean;
    }>;
  };
};

const MenuItemMobile: React.FunctionComponent<MenuItemMobileProps> = ({
  item,
}) => {
  const { checkPermissions } = useAppContext();

  if (
    !checkPermissions(item.permissions || []) ||
    item?.items?.every((i) => !checkPermissions(i.permissions || []))
  )
    return null;

  if (item.href)
    return (
      <NavLink
        to={item.href}
        className="block round-md px-3 py-2 text-base font-medium text-gray-600 hover:bg-gray-200"
      >
        {item.label}
      </NavLink>
    );

  return (
    <>
      <div className="block round-md px-3 py-2 text-base font-medium text-gray-600 hover:bg-gray-200">
        {item.label}
      </div>
      {item.items?.map((subItem) => {
        if (!checkPermissions(subItem.permissions || [])) return null;

        if (subItem.disabled) {
          return (
            <button
              key={subItem.label}
              type="button"
              className="block round-md px-5 py-2 text-base text-gray-500 hover:bg-gray-200 disabled:opacity-50 w-full text-left"
              disabled
            >
              {subItem.label}
            </button>
          );
        }

        return (
          <NavLink
            key={subItem.label}
            to={subItem.href ?? ""}
            className="block round-md px-5 py-2 text-base text-gray-500 hover:bg-gray-200"
          >
            {subItem.label}
          </NavLink>
        );
      })}
    </>
  );
};

export default MenuItemMobile;

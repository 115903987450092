import { useMutation } from "@tanstack/react-query";

import {
  archiveUnselected,
  ArchiveUnselectedPayload,
} from "@services/evidence";

export const useArchiveUnselectedEvidence = (options: any = {}) => {
  return useMutation<any, any, ArchiveUnselectedPayload>({
    mutationKey: ["archive_unselected_evidences"],
    mutationFn: archiveUnselected,
    ...options,
  });
};

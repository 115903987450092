import { useTranslation } from "react-i18next";

import LinkMeterWorkflow from "@components/form/extraction_point/link_meter/LinkMeterWorkflow";
import Layout from "@components/layout/Layout";
import { useSubscriber } from "@hooks/query/useSubscriber";
import { useLevel0Resource } from "@hooks/query/useLevel0Resource";
import { useParams } from "react-router-dom";

const SubscriberLinkMeter = () => {
  const { t } = useTranslation();
  const { id: subscriberId = "", level0ResourceId = "" } = useParams();
  const { data: subscriber = {} } = useSubscriber(subscriberId);
  const { data: level0Resource = {} } = useLevel0Resource(level0ResourceId);

  return (
    <Layout
      breadcrumb={[
        {
          label: t("common.home"),
          href: "/polestar",
        },
        {
          label: t("common.subscribers"),
          href: "/polestar/subscribers",
        },
        {
          label: subscriber.name,
          href: `/polestar/subscribers/${subscriber.id}`,
        },
        {
          label: level0Resource.identifier,
          href: `/polestar/subscribers/${subscriberId}?level0ResourceId=${level0Resource.id}`,
        },
        {
          label: `${t("common.extraction_points")} ${t("common.and")} ${t(
            "common.meters"
          )}`,
          href: `/polestar/subscribers/${subscriber?.id}?level0ResourceId=${level0Resource?.id}&extractionPointId=random`,
        },
        {
          label: t("extraction_point.link_meter.title"),
        },
      ]}
      title={t("extraction_point.link_meter.title")}
      accountingPeriodLevel1ResourceId={subscriber.level1ResourceId}
    >
      <LinkMeterWorkflow />
    </Layout>
  );
};

export default SubscriberLinkMeter;

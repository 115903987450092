import { useMutation } from "@tanstack/react-query";

import { unlink } from "@services/extractionPoints";

export const useUnlinkExtractionPoint = (options: Record<string, any> = {}) => {
  return useMutation({
    mutationKey: ["unlink_extraction_point"],
    mutationFn: unlink,
    ...options,
  });
};

import { useQuery } from "@tanstack/react-query";

import { getAdministrativeApproval } from "@services/zenith/administrativeApprovals";

export const useAdministrativeApproval = (
  id: string,
  options: Record<string, any> = {}
) => {
  return useQuery<any>({
    queryKey: ["administrativeApprovals", id],
    queryFn: () => getAdministrativeApproval(id),
    enabled: Boolean(id),

    ...options,
  });
};

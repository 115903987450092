type Props = {
  className?: string;
};

const BidIcon: React.FunctionComponent<Props> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      width="20"
      height="20"
      fill="none"
      className={className}
    >
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          d="M12.445.367a1.252 1.252 0 0 0-1.77 0L5.989 5.055a1.252 1.252 0 0 0 0 1.77l.625.624a1.252 1.252 0 0 0 1.77 0l.156-.156 4.172 4.168-.156.156a1.252 1.252 0 0 0 0 1.77l.625.625a1.252 1.252 0 0 0 1.77 0l4.687-4.688a1.252 1.252 0 0 0 0-1.77l-.625-.624a1.252 1.252 0 0 0-1.77 0l-.156.156-4.172-4.172.156-.156a1.252 1.252 0 0 0 0-1.77l-.625-.625v.004Zm-5.937 11.25a1.252 1.252 0 0 0-1.77 0L.363 15.992a1.252 1.252 0 0 0 0 1.77l1.875 1.875a1.252 1.252 0 0 0 1.77 0l4.375-4.375a1.252 1.252 0 0 0 0-1.77l-.055-.055 2.297-2.292-1.77-1.77-2.293 2.293-.054-.055v.004Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="currentColor" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BidIcon;

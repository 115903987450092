import { isFunction } from "lodash";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "@services/api";

const createStockAndDomestic = async (payload: {
  extractionRightId: string;
  volume: number;
  note: string;
}) => {
  const { data } = await api.post(`/admin/stockAndDomestics`, payload);

  return [data.administrativeApproval, data.workflowInstance];
};

export const useCreateStockAndDomestic = (
  options: Record<string, any> = {},
) => {
  const queryClient = useQueryClient();

  return useMutation(createStockAndDomestic, {
    onSuccess: res => {
      queryClient.invalidateQueries({
        queryKey: ["administrative_approvals"],
      });

      if (isFunction(options?.onSuccess)) {
        options.onSuccess(res);
      }
    },
    ...options,
  });
};

import { useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import ExternalLinkIcon from "@components/icons/ExternalLinkIcon";
import { useAllSubscriberLevel0Resources } from "@hooks/query/useAllSubscriberLevel0Resources";
import { formatVolume } from "@utils/formatVolume";

type SelectLevel0ResouceTableProps = {
  subscriberId: string;
  value: any;
  onSelect: (value: any) => void;
  filter: {
    level0ResourceId: string;
    waterClassName: string;
  };
  filterByWaterClassName: string;
};

type ExtractionRight = {
  id: string;
  name: string;
};

type WaterClass = {
  id: string;
  name: string;
  balance: number;
  extractionRights: ExtractionRight[];
};

const SelectLevel0ResouceTable: React.FunctionComponent<
  SelectLevel0ResouceTableProps
> = ({ subscriberId, value, onSelect, filter }) => {
  const { t } = useTranslation();
  const { data = [], isLoading } = useAllSubscriberLevel0Resources(
    subscriberId,
    {
      select: (
        res: {
          id: string;
          identifier: string;
          waterClasses: WaterClass[];
        }[]
      ) => {
        return res
          .map(({ id: level0ResourceId, identifier, waterClasses }) => {
            return waterClasses
              .filter(
                (i: any) =>
                  i.id !== filter.level0ResourceId &&
                  i.waterClass.name === filter.waterClassName
              )
              .map(
                ({
                  id: waterClassId,
                  name: waterClassName,
                  balance,
                  extractionRights,
                }: any) => ({
                  level0Resource: {
                    id: level0ResourceId,
                    identifier,
                  },
                  waterClass: {
                    id: waterClassId,
                    name: waterClassName,
                  },
                  balance,
                  formatBalance: formatVolume(balance),
                  radio: (
                    <input
                      type="radio"
                      checked={
                        value.waterClass.id === waterClassId &&
                        value.level0Resource.id === level0ResourceId &&
                        value.extractionRights.length
                      }
                      onChange={() => {
                        onSelect({
                          level0Resource: {
                            id: level0ResourceId,
                            identifier,
                          },
                          waterClass: {
                            id: waterClassId,
                            name: waterClassName,
                          },
                          extractionRights,
                        });
                      }}
                    />
                  ),
                  action: (
                    <div className="flex">
                      <a
                        href={`/polestar/subscribers/${subscriberId}`}
                        title={t("common.view") as string}
                        className="btn-default flex items-center gap-3 w-min"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <ExternalLinkIcon className="w-4 h-4" />
                        {t("common.view")}
                      </a>
                    </div>
                  ),
                })
              );
          })
          .flat();
      },
    }
  );

  const tableFields = [
    {
      title: "",
      name: "radio",
    },
    {
      title: t(
        "approval.seasonal_water_assignments.create.select_level0_resource.table.level0_resource"
      ),
      name: "level0Resource.identifier",
    },
    {
      title: t("common.water_class"),
      name: "waterClass.name",
    },
    {
      title: t(
        "approval.seasonal_water_assignments.create.select_level0_resource.table.balance"
      ),
      name: "formatBalance",
    },
    {
      title: "",
      name: "action",
    },
  ];

  return (
    <Table fields={tableFields} data={data} stickyHeader loading={isLoading} />
  );
};

export default SelectLevel0ResouceTable;

type Props = {
  className?: string;
};

const AlertIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 47 47"
      className={className}
    >
      <path
        fill="currentColor"
        d="M23.5 2.938c-11.355 0-20.563 9.207-20.563 20.562S12.146 44.063 23.5 44.063c11.355 0 20.563-9.208 20.563-20.563 0-11.355-9.208-20.563-20.563-20.563zm0 37.636c-9.428 0-17.074-7.646-17.074-17.074S14.072 6.426 23.5 6.426 40.574 14.072 40.574 23.5 32.928 40.574 23.5 40.574z"
      ></path>
      <path
        fill="#fff"
        d="M23.5 6.426c-9.428 0-17.074 7.646-17.074 17.074S14.072 40.574 23.5 40.574 40.574 32.928 40.574 23.5 32.928 6.426 23.5 6.426zm-1.469 7.16c0-.202.166-.367.367-.367h2.204c.201 0 .367.165.367.367V26.07a.368.368 0 01-.367.367h-2.204a.368.368 0 01-.367-.367V13.586zM23.5 33.78a2.203 2.203 0 010-4.406 2.204 2.204 0 010 4.406z"
        opacity="0.1"
      ></path>
      <path
        fill="currentColor"
        d="M22.398 26.438h2.204a.368.368 0 00.367-.368V13.586a.368.368 0 00-.367-.367h-2.204a.368.368 0 00-.367.367V26.07c0 .202.166.367.367.367zm-1.101 5.14a2.203 2.203 0 104.406 0 2.203 2.203 0 00-4.406 0z"
      ></path>
    </svg>
  );
};

export default AlertIcon;

import Heading from "@components/layout/Heading";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useBalanceAdjustmentContext } from "@context/BalanceAdjustmentContext";
import SelectSingleExtractionRightTable from "@components/table/SelectSingleExtractionRightTable";
import {
  extractionRightTypes,
  getExtractionRightTypeIdentifier,
} from "@services/extractionRight";
import { Level0WRSSource } from "@services/level0Resource";

type CreateBalanceAdjustmentSelectExtractionRightProps = {
  onSave: () => void;
  onGoBack: () => void;
};

const CreateBalanceAdjustmentSelectExtractionRight: FunctionComponent<
  CreateBalanceAdjustmentSelectExtractionRightProps
> = ({ onSave, onGoBack }) => {
  const { t } = useTranslation();
  const { balanceAdjustmentData, setBalanceAdjustmentData, navigateForCancel } =
    useBalanceAdjustmentContext();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSave();
  };

  return (
    <form className="pb-4 flex flex-col h-full" onSubmit={handleSubmit}>
      <div className="p-6 grow flex flex-col">
        <div className="space-y-4">
          <Heading light>
            {t("balance_adjustment.select_surface_extraction_right")}
          </Heading>
        </div>
        <SelectSingleExtractionRightTable
          onSelect={(item: any) => {
            setBalanceAdjustmentData({
              ...balanceAdjustmentData,
              extractionRight: item,
            });
          }}
          onLoad={(data: any[]) => {
            return data
              .filter(
                item => item.level0Resource?.source === Level0WRSSource.Surface,
              )
              .filter(item => {
                const rightTypes = [
                  extractionRightTypes.WA,
                  extractionRightTypes.WSA,
                ];
                const rightTypeIdentifiers = rightTypes.map(rt =>
                  getExtractionRightTypeIdentifier(rt),
                );
                return rightTypeIdentifiers.includes(item.type?.identifier);
              });
          }}
          level1ResourceId={balanceAdjustmentData.level1Resource?.id!}
          subscriberId={balanceAdjustmentData.subscriber?.id!}
          selected={balanceAdjustmentData.extractionRight}
        />
      </div>

      <footer className="flex gap-4 -mx-2 mt-2 p-6 pb-0 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={onGoBack}
        >
          {t("common.prev_step")}
        </button>
        <button
          type="submit"
          className="btn-primary"
          disabled={!balanceAdjustmentData?.extractionRight?.id}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default CreateBalanceAdjustmentSelectExtractionRight;

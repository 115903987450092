import Select, { ActionMeta } from "react-select";
import { noop } from "lodash";

import ErrorMessage from "@components/form/ErrorMessage";
import { useAllLevel1Resources } from "@hooks/query/useAllLevel1Resources";

type OverrideProps<T, TOverridden> = Omit<T, keyof TOverridden> & TOverridden;

type Option = {
  label: string;
  value: string;
  identifier: string;
};

type SelectLevel1ResourceProps = {
  className?: string;
  errorMessage?: string;
} & OverrideProps<
  React.ComponentProps<Select>,
  {
    value?: string;
    onChange?: (value: Option, actionMeta: ActionMeta<Option>) => void;
  }
>;

const SelectLevel1Resource: React.FunctionComponent<
  SelectLevel1ResourceProps
> = ({ errorMessage, value, className, onChange = noop, ...props }) => {
  const { isLoading, data: options = [] } = useAllLevel1Resources({
    select: (data: Record<string, any>[]) => {
      return data
        .map((i) => ({
          label: i.name,
          value: i.id,
          identifier: i.identifier,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    },
  });

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Select
        options={options}
        value={options.find((i: any) => i.value === value || i.label === value)}
        className={className}
        onChange={onChange}
        openMenuOnFocus
        {...props}
      />

      {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
    </>
  );
};

export default SelectLevel1Resource;

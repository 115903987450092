import React from "react";
import { Trans, useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import { formatVolume } from "@utils/formatVolume";
import SubHeading from "@components/layout/SubHeading";
import { formatDate } from "@utils/formatDate";
import LockIcon from "@components/icons/LockIcon";
import { ClockIcon } from "@heroicons/react/24/solid";
import Tag from "@components/shared/Tag";

type DistributionLossHistoryTableProps = {
  previousDeclarations: any[];
};

const DistributionLossHistoryTable: React.FunctionComponent<
  DistributionLossHistoryTableProps
> = ({ previousDeclarations }) => {
  const { t } = useTranslation();
  const tableFields = [
    {
      title: t("common.effective_date"),
      name: "readAt",
    },
    {
      title: t("common.volume"),
      name: "volume",
    },
    {
      title: t("common.on_chain"),
      name: "onChain",
    },
  ];

  const tableData = previousDeclarations.map((item: any) => {
    return {
      id: item.id,
      volume: formatVolume(+item.volume),
      readAt: formatDate(new Date(item.readAt)),
      onChain: item.accountedAt ? (
        <Tag status="success">
          <div className="flex gap-1 items-center">
            <LockIcon className="w-4 h-4" />
            {t("common.on_chain")}
          </div>
        </Tag>
      ) : (
        <Tag status="warning">
          <div className="flex gap-1 items-center">
            <ClockIcon className="w-4 h-4" />
            {t("common.scheduled")}
          </div>
        </Tag>
      ),
    };
  });

  return (
    <div className="flex flex-col mb-4 overflow-hidden">
      <SubHeading>
        <Trans i18nKey="distribution_losses.info.history_table_title" />
      </SubHeading>
      <Table fields={tableFields} data={tableData} />
    </div>
  );
};

export default DistributionLossHistoryTable;

import { useTranslation } from "react-i18next";

import MeterTable from "@components/table/MeterTable";
import { useStepContext } from "@context/shared/StepContext";
import { useLinkMeterContext } from "@context/LinkMeterContext";

type Props = {
  onCancel: () => void;
};

const SelectMeter: React.FunctionComponent<Props> = ({ onCancel }) => {
  const { t } = useTranslation();
  const { stepHelpers } = useStepContext();
  const { details, setDetails } = useLinkMeterContext();

  const setSelectedMeter = (value: any) => {
    setDetails((prev: any) => ({
      ...prev,
      meter: value,
    }));
  };

  return (
    <>
      <MeterTable
        title={t("extraction_point.link_meter.title")}
        value={details.meter}
        onChange={setSelectedMeter}
      />

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>
        <button
          type="button"
          className="btn-primary"
          onClick={stepHelpers.goToNextStep}
          disabled={!Boolean(details.meter.id)}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </>
  );
};

export default SelectMeter;

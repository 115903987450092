import MapView from "@components/shared/MapView";
import AnnouncedAllocationWidget from "./AnnouncedAllocationWidget";
import Level0WRSWidget from "./Level0WRSWidget";
import NotificationWidget from "./NotificationWidget";
import PendingApplicationWidget from "./PendingApplicationWidget";
import SummaryWidget from "./SummaryWidget";

type Level1WRSDashboardProps = {
  level1Resource: any;
};

const Level1WRSDashboard: React.FunctionComponent<Level1WRSDashboardProps> = ({
  level1Resource,
}) => {
  return (
    <>
      <header
        className="w-full h-24 rounded-lg bg-center bg-no-repeat bg-cover"
        style={{
          backgroundImage: `url('${level1Resource?.photo}')`,
          backgroundColor: "#004A88",
        }}
      >
        {level1Resource?.geography && (
          <MapView
            className="h-24 w-full rounded-lg -z-0 bg-gradient-to-t from-black to-transparent bg-opacity-10"
            zoomLevel={6}
            zoomControl={false}
            center={{
              lat: +level1Resource?.geography?.split(",")?.[0],
              lng: +level1Resource?.geography?.split(",")?.[1],
            }}
          />
        )}
      </header>
      <div className="flex gap-4 mt-4 md:grid-cols-2">
        <SummaryWidget level1Resource={level1Resource} />
        <AnnouncedAllocationWidget level1Resource={level1Resource} />
        <NotificationWidget level1Resource={level1Resource} />
        <PendingApplicationWidget />
      </div>
      <div className="grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 gap-4 mt-4">
        {level1Resource?.level0WRSMembers.map((zone: any) => (
          <Level0WRSWidget key={zone.identifier} level0Resource={zone} />
        ))}
      </div>
    </>
  );
};

export default Level1WRSDashboard;

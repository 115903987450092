import { useTranslation } from "react-i18next";

import ErrorLayout from "@components/layout/ErrorLayout";
import { getRootPath } from "@utils/getRootPath";
import ENV from "@config/env";

const AccessDenied = () => {
  const { t } = useTranslation();

  return (
    <ErrorLayout
      statusCode="403"
      title={t("error.access_denied.title")}
      description={t("error.access_denied.description")}
    >
      <a
        href={getRootPath(window.location.pathname)}
        className="inline-block btn-secondary rounded"
      >
        {t("error.go_to_dashboard")}
        <span className="inline-block ml-2" aria-hidden="true">
          &rarr;
        </span>
      </a>

      <a href={ENV.HELP_CENTER_URL} target="_blank" rel="noreferrer">
        {t("error.contact_support")}
      </a>
    </ErrorLayout>
  );
};

export default AccessDenied;

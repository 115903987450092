import AuthLayout from "@components/layout/AuthLayout";

type Props = {
  statusCode: string;
  title: string;
  description: string;
  children?: React.ReactNode;
};

const ErrorLayout: React.FunctionComponent<Props> = ({
  statusCode,
  title,
  description,
  children,
}) => {
  return (
    <AuthLayout>
      <div className="flex min-h-full flex-col justify-center">
        <div className="sm:mx-auto w-full">
          <div className="bg-white shadow sm:rounded-lg py-12 px-8">
            <p className="font-semibold text-primary-1">{statusCode}</p>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {title}
            </h1>
            <p className="mt-2 text-base text-gray-500">{description}</p>

            <div className="flex gap-4 text-sm mt-6 items-center">
              {children}
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default ErrorLayout;

import React, { useEffect } from "react";
import i18n from "i18n";

import { useLocalStorage } from "@hooks/useLocalStorage";
import { useAllAccountingPeriods } from "@hooks/query/zenith/useAllAccoutingPeriods";
import type { LoggedInInfo, AccountingPeriod } from "../types";

type AppContextProps = {
  loggedInInfo: LoggedInInfo;
  notifications: any[];
  setLoggedInInfo: (info: LoggedInInfo) => void;
  setNotifications: (notifications: any[]) => void;
  clearLoggedInInfo: () => void;
  checkPermissions: (permissions: string[]) => boolean;
  isExpandSidebar: boolean;
  setIsExpandSidebar: any;
  isExpandInfoPanel: boolean;
  setIsExpandInfoPanel: any;
  infoPanel: React.ReactNode;
  setInfoPanel: any;
  setLanguage: (language: string) => void;
  currentAccountingPeriod: AccountingPeriod;
};

const AppContext = React.createContext<AppContextProps | undefined>(undefined);

const AppContextProvider: React.FunctionComponent<{
  children: React.ReactNode;
}> = props => {
  const [notifications, setNotifications] = React.useState<any[]>([]);
  const [loggedInInfo, setLoggedInInfo] = React.useState<LoggedInInfo>({
    isLoggedIn: false,
  });
  const [isExpandSidebar, setIsExpandSidebar] = useLocalStorage(
    "isExpandSidebar",
    true,
  );
  const [isExpandInfoPanel, setIsExpandInfoPanel] = useLocalStorage(
    "isExpandInfoPanel",
    true,
  );
  const [currentAccountingPeriod, setCurrentAccountingPeriod] = React.useState({
    id: "",
    periodStart: new Date(),
    periodEnd: new Date(),
  });

  const [infoPanel, setInfoPanel] = React.useState<React.ReactNode>();

  const checkPermissions = (permissions: string[]): boolean => {
    const userPermissions =
      loggedInInfo?.userDetails?.permissions?.map(
        (permission: any) => permission.name,
      ) || [];

    if (!permissions?.length) return true;

    return (
      userPermissions.length > 0 &&
      permissions.every(
        permission => userPermissions.indexOf(permission) !== -1,
      )
    );
  };

  const setLanguage = (language: string) => {
    setLoggedInInfo({
      ...loggedInInfo,
      userDetails: {
        ...loggedInInfo?.userDetails,
        language,
      },
    });
  };

  const clearLoggedInInfo = () => {
    setLoggedInInfo({
      isLoggedIn: false,
    });
    setNotifications([]);
  };

  useEffect(() => {
    if (loggedInInfo?.userDetails?.language) {
      i18n.changeLanguage(loggedInInfo?.userDetails?.language);
    }
  }, [loggedInInfo?.userDetails?.language]);

  useAllAccountingPeriods({
    params: {
      isActive: true,
      level1ResourceId: loggedInInfo?.userDetails?.subscriber?.level1ResourceId,
    },
    enabled:
      loggedInInfo.isLoggedIn &&
      Boolean(loggedInInfo?.userDetails?.subscriber?.level1ResourceId),
    onSuccess: (data: any) => {
      if (data.length) {
        setCurrentAccountingPeriod({
          id: data[0].id,
          periodStart: new Date(data[0].periodStart),
          periodEnd: new Date(data[0].periodEnd),
        });
      }
    },
  });

  return (
    <AppContext.Provider
      value={{
        loggedInInfo,
        setLoggedInInfo,
        notifications,
        setNotifications,
        clearLoggedInInfo,
        checkPermissions,
        isExpandSidebar,
        setIsExpandSidebar,
        isExpandInfoPanel,
        setIsExpandInfoPanel,
        infoPanel,
        setInfoPanel,
        setLanguage,
        currentAccountingPeriod,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within a AppContextProvider");
  }
  return context;
};

export default AppContextProvider;

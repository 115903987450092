import React from "react";

type Props = {
  className?: string;
};

const ExternalLinkIcon: React.FunctionComponent<Props> = ({ className }) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.5 18.5C1.1 18.5 0.75 18.35 0.45 18.05C0.15 17.75 0 17.4 0 17V2C0 1.6 0.15 1.25 0.45 0.95C0.75 0.65 1.1 0.5 1.5 0.5H8.475V2H1.5V17H16.5V10.025H18V17C18 17.4 17.85 17.75 17.55 18.05C17.25 18.35 16.9 18.5 16.5 18.5H1.5ZM6.55 13.025L5.5 11.95L15.45 2H9.975V0.5H18V8.525H16.5V3.075L6.55 13.025Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ExternalLinkIcon;

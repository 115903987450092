import { useRouteError } from "react-router-dom";

import AccessDenied from "./AccessDenied";
import InternalServerError from "./InternalServerError";

const ErrorBoundary = () => {
  const error: any = useRouteError();

  if (error?.response?.status === 403) {
    return <AccessDenied />;
  }

  return <InternalServerError />;
};

export default ErrorBoundary;

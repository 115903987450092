import { useTranslation } from "react-i18next";
import Heading from "@components/layout/Heading";
import { useLevel0WRSContext } from "@context/Level0WRSContext";
import EventTransactionPaginatedTable from "@components/table/EventTransactionPaginatedTable";
import EndButtonModal from "@components/shared/EndButton";

const Level0WRSWorkflowCompleted = () => {
  const { t } = useTranslation();
  const { workflowInstance } = useLevel0WRSContext();

  return (
    <>
      <div className="flex flex-col gap-4 grow p-6">
        <Heading light>{t("level0wrs.create.workflow_complete")}</Heading>
        <EventTransactionPaginatedTable
          references={[workflowInstance?.id]}
          stickyHeader={true}
        />
      </div>
      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <EndButtonModal />
      </footer>
    </>
  );
};

export default Level0WRSWorkflowCompleted;

import { Trans, useTranslation } from "react-i18next";
import { useState } from "react";

import TabPanels from "@components/form/TabPanels";
import InfoPanel from "@components/form/InfoPanel";
import Level1WRSExtractionRightList from "./Level1WRSExtractionRightList";
import { CanAmalgamate } from "@utils/getExtractionRightsCanAmalgamate";

type Props = {
  level1Resource: any;
};

const Level1WRSExtractionRights: React.FunctionComponent<Props> = ({
  level1Resource,
}) => {
  const { t } = useTranslation();
  const [canAmalgamate, setCanAmalgamate] = useState<CanAmalgamate>("");

  const generateWarningMessages = (canAmalgamate: CanAmalgamate): string[] => {
    if (["", "yes"].includes(canAmalgamate)) return [];
    return [
      t("level1wrs.tab_info_panel.extraction_right.warning", {
        context: canAmalgamate,
      }),
    ];
  };

  return (
    <TabPanels
      className="bg-white border"
      tabs={[
        {
          title: t("common.extraction_rights"),
          panel: (
            <Level1WRSExtractionRightList
              level1Resource={level1Resource}
              canAmalgamate={canAmalgamate}
              setCanAmalgamate={setCanAmalgamate}
            />
          ),
          tabInfoPanel: (
            <InfoPanel
              warnings={generateWarningMessages(canAmalgamate)}
              successes={[
                ...(canAmalgamate === "yes"
                  ? [t("level1wrs.tab_info_panel.extraction_right.success")]
                  : []),
              ]}
            >
              <div className="space-y-2">
                <ul className="flex flex-col gap-5 list-disc list-outside">
                  <li>
                    <Trans
                      i18nKey="level1wrs.tab_info_panel.extraction_right.intro"
                      values={{ level1ResourceName: level1Resource?.name }}
                    />
                  </li>
                  <li>{t("level1wrs.tab_info_panel.extraction_right.body")}</li>
                  <li>
                    {t("level1wrs.tab_info_panel.extraction_right.conclusion")}
                  </li>
                </ul>
              </div>
            </InfoPanel>
          ),
        },
      ]}
    />
  );
};

export default Level1WRSExtractionRights;

type Props = {
  className?: string;
};

const DropzoneIcon: React.FunctionComponent<Props> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className={className}
    >
      <path d="M41.4934 20.9198L41.484 20.8823L36.2247 7.51826C35.9903 6.76357 35.2919 6.24326 34.4997 6.24326H13.1809C12.384 6.24326 11.6762 6.77294 11.4512 7.53701L6.53404 20.7651L6.51998 20.7979L6.51061 20.8354C6.44967 21.0651 6.43092 21.2995 6.46373 21.5292C6.45904 21.6042 6.45436 21.6792 6.45436 21.7542V38.9058C6.45559 39.6612 6.75626 40.3854 7.29047 40.9196C7.82468 41.4538 8.54887 41.7545 9.30436 41.7558H38.7044C40.2747 41.7558 41.5544 40.4761 41.559 38.9058V21.7542C41.559 21.6933 41.559 21.6323 41.5544 21.5808C41.5731 21.3511 41.5544 21.1308 41.4934 20.9198ZM27.6278 18.9042L27.6137 19.6401C27.5762 21.7448 26.1231 23.1604 23.9997 23.1604C22.9637 23.1604 22.0731 22.8276 21.4309 22.1948C20.7887 21.562 20.4372 20.6808 20.4184 19.6401L20.4044 18.9042H10.7575L14.484 9.84326H33.1965L37.0262 18.9042H27.6278ZM10.0497 22.5042H17.4231C18.5622 25.1808 20.9856 26.7604 24.0044 26.7604C25.584 26.7604 27.0512 26.3198 28.2372 25.4854C29.2778 24.7542 30.0887 23.7323 30.6137 22.5042H37.9497V38.1558H10.0497V22.5042Z" />
    </svg>
  );
};

export default DropzoneIcon;

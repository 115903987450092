import { useTranslation } from "react-i18next";

import Tag from "@components/shared/Tag";

type TrialRunTagProps = {
  status: "success" | "warning" | "error";
};

const TrialRunTag: React.FunctionComponent<TrialRunTagProps> = ({ status }) => {
  const { t } = useTranslation();
  return (
    <Tag status={status}>
      {status === "error"
        ? t("common.error")
        : status === "warning"
        ? t("common.warning")
        : t("common.success")}
    </Tag>
  );
};

export default TrialRunTag;

type TooltipProps = {
  children: React.ReactNode;
  message: string;
};

const Tooltip: React.FunctionComponent<TooltipProps> = ({
  children,
  message,
}) => {
  return (
    <div className="relative flex flex-col items-center group">
      {children}
      <div className="absolute top-2 flex-col items-center hidden mt-6 w-32 group-hover:flex">
        <div className="w-3 h-3 mt-2 rotate-45 bg-gray-600"></div>
        <span className="relative -top-2 z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-600 shadow-lg rounded-md">
          {message}
        </span>
      </div>
    </div>
  );
};

export default Tooltip;

import React from "react";
import { useParams, Navigate, NavLink, Outlet } from "react-router-dom";
import { orderBy } from "lodash";
import { useTranslation } from "react-i18next";

import Loading from "@components/shared/Loading";
import TabNav from "@components/form/TabNav";
import TabMenu from "@components/form/TabMenu";
import ExpandInfoPanelButton from "@components/shared/ExpandInfoPanelButton";
import { useAppContext } from "@context/AppContext";
import { useSubscriber } from "@hooks/query/useSubscriber";

const SubscriberLevel0WRSLayout: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { infoPanel, isExpandInfoPanel } = useAppContext();
  const { id: subscriberId = "", level0ResourceId = "" } = useParams();
  const { data: subscriber = {}, isLoading: isSubscriberLoading } =
    useSubscriber(subscriberId);

  const level0Resources = orderBy(
    subscriber.level0Resources,
    ["identifier"],
    ["asc"],
  );

  if (isSubscriberLoading) {
    return (
      <div className="w-full pt-10">
        <Loading />
      </div>
    );
  }

  if (!level0ResourceId && level0Resources?.[0]?.id) {
    return (
      <Navigate
        to={`/polestar/subscribers/${subscriberId}/level0_resources/${level0Resources[0].id}`}
      />
    );
  }

  const path = `/polestar/subscribers/${subscriberId}/level0_resources/${level0ResourceId}`;

  const tabs: {
    label: string;
    href: string;
  }[] = [
    {
      label: t("subscriber.level0wrs_summary"),
      href: path,
    },
    {
      label: t("subscriber.extraction_rights"),
      href: `${path}/extraction_rights`,
    },
    {
      label: t("level1wrs.extraction_points"),
      href: `${path}/meters_extraction_points`,
    },
    {
      label: t("subscriber.audit_trail"),
      href: `${path}/transactions`,
    },
  ];

  return (
    <div className="flex grow gap-4">
      <div className="flex flex-col grow bg-white border relative">
        <TabNav>
          {tabs.map(i => {
            return (
              <NavLink to={i.href} key={i.label} end>
                {({ isActive }) => (
                  <TabMenu isActive={isActive}>{i.label}</TabMenu>
                )}
              </NavLink>
            );
          })}
        </TabNav>

        <div className="flex flex-col grow p-6">
          <Outlet />
          {infoPanel && !isExpandInfoPanel && <ExpandInfoPanelButton />}
        </div>
      </div>
      {infoPanel && isExpandInfoPanel && (
        <div className="flex w-1/4">{infoPanel}</div>
      )}
    </div>
  );
};

export default SubscriberLevel0WRSLayout;

import React from "react";
import {
  XAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart as ReactAreaChart,
  Area,
  YAxis,
} from "recharts";
import { useTranslation } from "react-i18next";

import CustomChartTooltip from "./CustomChartTooltip";
import classNames from "classnames";
import Heading from "@components/layout/Heading";

type AreaChartProps = {
  title: string;
  data: any;
  dark?: boolean;
  dataKey?: string;
  xAxisDataKey?: string;
  yAxisDataKey?: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

const AreaChart: React.FunctionComponent<AreaChartProps> = ({
  title,
  data,
  dark = false,
  dataKey = "value",
  xAxisDataKey = "name",
  yAxisDataKey = "value",
  onChange,
}) => {
  const { t } = useTranslation();

  const theme = {
    dark: {
      bgColor: "bg-primary-1",
      color: "text-white",
      fillColor: "#fff",
    },
    light: {
      bgColor: "bg-gray-100",
      color: "text-primary-1",
      fillColor: "#00385A",
    },
  };

  const themeKey = dark ? "dark" : "light";
  const { bgColor, color, fillColor } = theme[themeKey];

  return (
    <>
      <header className="absolute inset-x-0 top-0 flex justify-between items-center p-6">
        <Heading className={classNames("tracking-wide", color)}>
          {title}
        </Heading>

        <select
          onChange={onChange}
          className="block w-32 rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary-1 sm:text-sm sm:leading-6 z-10"
        >
          <option value="monthly">{t("zenith.exchanges.chart.monthly")}</option>
          <option value="yearly">{t("zenith.exchanges.chart.yearly")}</option>
        </select>
      </header>

      <div
        className={classNames(
          "w-full aspect-[5/3] bg-gradient-to-r from-black/0 to-black/20",
          bgColor
        )}
      >
        <ResponsiveContainer width="100%" height="100%">
          <ReactAreaChart
            data={data}
            margin={{
              top: 80,
              right: 32,
              left: 16,
              bottom: 16,
            }}
          >
            <Area
              type="natural"
              dataKey={dataKey}
              stroke={fillColor}
              strokeOpacity={0.8}
              strokeWidth={3}
              strokeLinecap="round"
              fillOpacity={0.1}
              fill={fillColor}
              animationDuration={400}
            />
            <Tooltip content={<CustomChartTooltip />} />
            <YAxis dataKey={yAxisDataKey} strokeWidth={1} stroke={fillColor} />
            <XAxis dataKey={xAxisDataKey} strokeWidth={1} stroke={fillColor} />
          </ReactAreaChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default AreaChart;

import classNames from "classnames";
import { useState } from "react";

type DropDownMenuProps = {
  className?: string;
  placeHolder?: string;
  items: Array<{
    label: string;
    onClick: () => void;
    hidden?: boolean;
    disabled?: boolean;
  }>;
};

const DropDownMenu: React.FunctionComponent<DropDownMenuProps> = ({
  className,
  placeHolder,
  items,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className={classNames(
            "inline-flex w-full justify-center rounded-full border border-gray-300 px-1 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50",
            className
          )}
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={() => setOpen(!open)}
          onBlur={() => setTimeout(() => setOpen(false), 200)}
        >
          {placeHolder || "Select"}
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      {open && (
        <div
          className={classNames(
            "absolute right-0 z-50 mt-2 w-auto origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
            {
              "transition ease-out duration-100 transform opacity-100 scale-100":
                open,
              "transition ease-in duration-75 transform opacity-0 scale-95":
                !open,
            }
          )}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex={-1}
        >
          <div className="py-1" role="none">
            {items
              .filter((item) => !item.hidden)
              .map((item: any, i) => (
                <button
                  key={i}
                  type="button"
                  disabled={item.disabled}
                  className={classNames(
                    "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900 w-full text-left whitespace-nowrap",
                    {
                      "text-gray-300 hover:text-gray-300": item.disabled,
                    }
                  )}
                  role="menuitem"
                  onClick={item.onClick}
                >
                  {item.label}
                </button>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropDownMenu;

import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Pagination from "./Pagination";

type ListProps = {
  containerClassName?: string;
  items: Array<React.ReactNode>;
  noItemsText?: string;
  pageSize?: number;
};

const List: React.FunctionComponent<ListProps> = ({
  containerClassName,
  items,
  noItemsText,
  pageSize,
}) => {
  const [pageNo, setPageNo] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  useEffect(() => {
    if (items?.length && pageSize) {
      const newLastPage = Math.ceil(items?.length / pageSize);
      setLastPage(newLastPage);
      if (items?.length <= pageSize) {
        setPageNo(0);
      } else if (newLastPage < pageNo) {
        setPageNo(newLastPage - 1);
      }
    }
  }, [pageSize, pageNo, items]);

  return (
    <div
      className={classNames(
        "flex flex-col w-full h-full gap-4",
        containerClassName
      )}
    >
      {items?.length === 0 && noItemsText ? (
        <div className="h-full w-full flex items-center justify-center">
          {noItemsText}
        </div>
      ) : (
        items?.slice(
          pageSize ? pageNo * pageSize : 0,
          pageSize ? pageNo * pageSize + pageSize : items?.length
        )
      )}
      {items?.length > 0 && pageSize && items?.length > pageSize && (
        <div className="w-full">
          <Pagination
            pageNo={pageNo}
            lastPage={lastPage}
            onPageChange={(i) => setPageNo(i)}
          />
        </div>
      )}
    </div>
  );
};

export default List;

type Props = {
  className?: string;
};

const ArrowRightCalendarIcon: React.FunctionComponent<Props> = ({
  className,
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="currentColor"
        d="M15.348 10.48l-2.883-3.656a.562.562 0 00-.442-.215h-1.139a.141.141 0 00-.11.229l2.536 3.217H2.672a.141.141 0 00-.14.14v1.055c0 .077.063.14.14.14h12.232a.563.563 0 00.444-.91z"
      ></path>
    </svg>
  );
};

export default ArrowRightCalendarIcon;

import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import { useAppContext } from "@context/AppContext";

type AppInformationPanelProps = {
  children?: React.ReactNode;
};

const AppInformationPanel: React.FunctionComponent<
  AppInformationPanelProps
> = ({ children }) => {
  const { t } = useTranslation();
  const { isExpandInfoPanel, setIsExpandInfoPanel } = useAppContext();

  return (
    <Transition
      className="app-sidebar hidden xl:fixed xl:right-0 xl:top-16 xl:w-90 xl:block xl:overflow-y-auto p-6 bg-white space-y-6 text-gray-500 leading-6"
      show={isExpandInfoPanel}
      enter="transition-transform duration-75"
      enterFrom="translate-x-full"
      enterTo="translate-x-0"
      leave="transition-transform duration-150"
      leaveFrom="translate-x-0"
      leaveTo="translate-x-full"
    >
      <div className="flex item-center justify-between">
        <Heading light>{t("common.information")}</Heading>

        <button
          type="button"
          className="group inline-flex p-2 rounded-full hover:bg-gray-50"
          onClick={() => {
            setIsExpandInfoPanel(false);
          }}
        >
          <XMarkIcon className="w-6 h-6 text-gray-400 group-hover:text-primary-1" />
        </button>
      </div>

      {children}
    </Transition>
  );
};

export default AppInformationPanel;

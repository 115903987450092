import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import OtpInput from "react18-input-otp";
import { verifyCode as authVerifyCode } from "../../../services/auth";

type VerifyFormProps = {
  email: string;
  password: string;
  tel: string;
  mfaMethod: string;
  onVerified: (userData: any, token: string) => void;
  onResendCode: () => void;
};

const VerifyCodeForm: React.FunctionComponent<VerifyFormProps> = ({
  email,
  password,
  tel,
  mfaMethod,
  onVerified,
  onResendCode,
}) => {
  const { t } = useTranslation();
  const [code, setCode] = useState("");
  const {
    mutateAsync: verifyCode,
    isLoading,
    error,
  } = useMutation<any, any>(() =>
    authVerifyCode({ email, password, mfaMethod, code })
  );

  const handleVerifyClick = async (e: any) => {
    e.preventDefault();

    const { user, token } = await verifyCode();

    onVerified(user, token);
  };

  return (
    <>
      <p className="text-sm">
        {mfaMethod === "phone_number" && t("auth.verify_code_to_tel", { tel })}
        {mfaMethod === "email" && t("auth.verify_code_to_email", { email })}
      </p>
      <form className="space-y-6" onSubmit={handleVerifyClick}>
        <div className="mt-2">
          <OtpInput
            value={code}
            onChange={(value: any) => setCode(value)}
            numInputs={6}
            inputStyle="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none text-5xl 2xl:text-6xl text-center"
            containerStyle="w-full flex gap-4 mt-3 justify-center"
            errorStyle="border-red-300"
            isInputNum={true}
            hasErrored={!!error}
            onSubmit={handleVerifyClick}
            isDisabled={isLoading}
          />
          {error && (
            <p className="mt-2 text-sm text-red-600">
              {error?.response?.data?.message}
            </p>
          )}
        </div>
        {!isLoading && (
          <div className="flex items-center justify-between">
            <div className="text-sm">
              <button
                type="button"
                className="font-semibold text-primary-3 hover:text-primary-2"
                onClick={onResendCode}
              >
                {t("auth.resend_code")}
              </button>
            </div>
          </div>
        )}
        <div>
          <button
            type="submit"
            className="btn-primary text-sm font-semibold w-full"
            disabled={isLoading || !code}
          >
            {t("auth.verify_code")}
          </button>
        </div>
      </form>
    </>
  );
};

export default VerifyCodeForm;

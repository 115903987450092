import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Layout from "@components/layout/Layout";
import LinkMeterWorkflow from "@components/form/extraction_point/link_meter/LinkMeterWorkflow";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";

const LinkMeter = () => {
  const { t } = useTranslation();
  const { id: level1ResourceId = "" } = useParams();
  const { data: level1Resource = {} } = useGetLevel1Resource(level1ResourceId, {
    enabled: Boolean(level1ResourceId),
  });

  return (
    <Layout
      permissions={[
        "UpdateExtractionPoint",
        "CreateDeclarations",
        "UpdateMeters",
      ]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: level1Resource?.name,
          href: `/polestar/level1wrs/${level1ResourceId}`,
        },
        {
          label: t("level1wrs.extraction_points"),
          href: `/polestar/level1wrs/${level1ResourceId}#5`,
        },
        {
          label: t("extraction_point.link_meter.title"),
        },
      ]}
      title={t("extraction_point.link_meter.title")}
      accountingPeriodLevel1ResourceId={level1ResourceId}
    >
      <LinkMeterWorkflow />
    </Layout>
  );
};

export default LinkMeter;

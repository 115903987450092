import { Popover } from "@headlessui/react";
import classNames from "classnames";
import { ArrowUpIcon } from "@heroicons/react/24/outline";
import { ArrowDownIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import { formatVolume } from "@utils/formatVolume";
import { formatDateTime } from "@utils/formatDateTime";
import { formatDate } from "@utils/formatDate";
import Tag from "@components/shared/Tag";

type TransactionHistoryTableProps = {
  className?: string;
  eventTransactions: any[];
  stickyHeader?: boolean;
};

const TransactionHistoryTable: React.FunctionComponent<
  TransactionHistoryTableProps
> = ({ className, eventTransactions, stickyHeader = false }) => {
  const { t } = useTranslation();

  return (
    <Table
      containerClassName={classNames("rounded-none", className)}
      className={className}
      noRowsText={t("common.no_transaction_data")}
      fields={[
        {
          title: t("audit_trail.timestamp"),
          name: "timestamp",
        },
        {
          title: t("audit_trail.type"),
          name: "type",
        },
        {
          title: t("common.details"),
          name: "details",
        },
        {
          title: t("audit_trail.volume"),
          name: "transactionAmount",
        },
        {
          title: t("common.effective_date"),
          name: "effectiveAt",
        },
      ]}
      data={eventTransactions?.map((log: any) => ({
        ...log,
        type: log.eventType.name,
        transactionAmount: (
          <Tag
            status={
              log.transactionAmount > 0
                ? "success"
                : log.transactionAmount < 0
                ? "error"
                : "default"
            }
            className="inline-flex items-center gap-1 py-1 pl-2 pr-3"
          >
            <span>
              {log.transactionAmount > 0 && (
                <ArrowUpIcon className="w-4 h-4 shrink-0" />
              )}
              {log.transactionAmount < 0 && (
                <ArrowDownIcon className="w-4 h-4 shrink-0" />
              )}
            </span>
            {formatVolume(log.transactionAmount)}
          </Tag>
        ),
        details: (
          <Popover className="static">
            <Popover.Button className="text-sm text-primary-2 underline">
              {t("common.details")}
            </Popover.Button>

            <Popover.Overlay className="absolute">
              <div className="flex justify-center items-center h-min bg-black">
                <div className=" rounded shadow-lg bg-black">
                  <div className="relative flex justify-left">
                    <div className="bg-black absolute w-3 h-3 transform rotate-45 -mt-1 ml-3"></div>
                  </div>
                  <div className="px-6 py-4 text-sm relative text-center">
                    {log.calculation ? (
                      <>
                        <p className="text-white font-thin text-center">
                          {log.calculation.split(" ").slice(0, 5).join(" ")}
                        </p>
                        <p className="text-white font-thin text-center">
                          {log.calculation.split(" ").slice(5).join(" ")}
                        </p>
                      </>
                    ) : (
                      <p className="text-white font-thin text-center">
                        {log.description}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </Popover.Overlay>
          </Popover>
        ),
        timestamp: formatDateTime(new Date(log.createdAt)),
        effectiveAt: log.effectiveAt
          ? formatDate(new Date(log.effectiveAt))
          : "-",
      }))}
      stickyHeader={stickyHeader}
    />
  );
};

export default TransactionHistoryTable;

import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import WorkflowSteps from "@components/form/WorkflowSteps";
import SelectApproval from "@components/form/workflows/response_approval_info_request/SelectApproval";
import EnterMessage from "@components/form/workflows/response_approval_info_request/EnterMessage";
import UploadEvidences from "@components/form/workflows/response_approval_info_request/UploadEvidences";
import ConfirmApprovalInfoRequest from "@components/form/workflows/response_approval_info_request/ConfirmApprovalInfoRequest";
import ResponseApprovalInfoRequestCompleted from "@components/form/workflows/response_approval_info_request/ResponseApprovalInfoRequestCompleted";
import { StepProvider, useStepContext } from "@context/shared/StepContext";
import {
  EvidenceProvider,
  useEvidenceContext,
} from "@context/shared/EvidenceContext";
import {
  ResponseApprovalInfoRequestProvider,
  useResponseApprovalInfoRequestContext,
} from "@context/ResponseApprovalInfoRequestContext";
import { useAdministrativeApproval } from "@hooks/query/zenith/useAdministrativeApproval";
import { useResponseApprovalInfoRequest } from "@hooks/mutation/zenith/useResponseApprovalInfoRequest";
import { getEvidenceDescription } from "@utils/administrativeApproval";
import { DBTables } from "@utils/constants/dbTables";

const WorkflowResponseApprovalInfoRequest: React.FunctionComponent = () => {
  return (
    <StepProvider maxStep={5}>
      <EvidenceProvider>
        <ResponseApprovalInfoRequestProvider>
          <MainComponent />
        </ResponseApprovalInfoRequestProvider>
      </EvidenceProvider>
    </StepProvider>
  );
};

export const MainComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const approvalId = searchParams.get("selectedId") ?? "";

  const { setApproval, approval, message, setWorkflowInstance } =
    useResponseApprovalInfoRequestContext();
  const { uploadEvidences } = useEvidenceContext();
  const { currentStep, stepHelpers } = useStepContext();
  const { mutateAsync: responseInfoRequestMutation } =
    useResponseApprovalInfoRequest();

  useAdministrativeApproval(approvalId, {
    refetchOnWindowFocus: false,
    onSuccess: (data: any) => {
      setApproval(data);
    },
  });

  const handleConfirm = async () => {
    const [res, workflowInstance] = await responseInfoRequestMutation({
      id: approval?.id,
      subject: message.subject,
      body: message.body,
    });

    await uploadEvidences({
      description: getEvidenceDescription(approval),
      references: [
        {
          referenceId: res.id,
          referenceTable: DBTables.AdministrativeApproval,
        },
        {
          referenceId: res.sellerId,
          referenceTable: DBTables.Subscribers,
        },
        {
          referenceId: res.buyerId,
          referenceTable: DBTables.Subscribers,
        },
        {
          referenceId: workflowInstance?.id,
          referenceTable: DBTables.WorkflowInstances,
        },
      ],
    });

    setWorkflowInstance(workflowInstance);
    stepHelpers.goToNextStep();
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <WorkflowSteps
      title={t("zenith.workflows.response_approval_info_request")}
      selectedStep={currentStep}
      onStepButtonClick={stepHelpers.setStep}
      disableNavigation={stepHelpers.canGoToNextStep}
      steps={[
        {
          panel: (
            <SelectApproval
              onSubmit={stepHelpers.goToNextStep}
              onCancel={handleCancel}
            />
          ),
        },
        {
          panel: (
            <EnterMessage
              onSubmit={stepHelpers.goToNextStep}
              onPrevious={stepHelpers.goToPrevStep}
              onCancel={handleCancel}
            />
          ),
        },
        {
          panel: (
            <UploadEvidences
              onSubmit={stepHelpers.goToNextStep}
              onPrevious={stepHelpers.goToPrevStep}
              onCancel={handleCancel}
            />
          ),
        },
        {
          panel: (
            <ConfirmApprovalInfoRequest
              onSubmit={handleConfirm}
              onPrevious={stepHelpers.goToPrevStep}
              onCancel={handleCancel}
              onEdit={stepHelpers.setStep}
            />
          ),
        },
        {
          panel: <ResponseApprovalInfoRequestCompleted />,
        },
      ]}
    />
  );
};

export default WorkflowResponseApprovalInfoRequest;

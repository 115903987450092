import React from "react";
import { useTranslation } from "react-i18next";

import { useAppContext } from "./AppContext";
import { useMessageTemplates } from "@hooks/query/useMessageTemplates";
import { AdministrativeApprovalNumericType } from "@services/administrativeApprovals";

const initialMessage = {
  messageTemplate: null,
  subject: "",
  body: "",
};

type Message = typeof initialMessage;

type ContextValue = {
  approval: any;
  setApproval: React.Dispatch<any>;
  message: Message;
  setMessage: React.Dispatch<React.SetStateAction<Message>>;
  workflowInstance: any;
  setWorkflowInstance: React.Dispatch<any>;
  workflowCompleted: boolean;
  setWorkflowCompleted: React.Dispatch<boolean>;
};

const Context = React.createContext<ContextValue | undefined>(undefined);

const ResponseApprovalInfoRequestProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const {
    loggedInInfo: { userDetails },
  } = useAppContext();

  const [approval, setApproval] = React.useState<any>();
  const [message, setMessage] = React.useState({ ...initialMessage });
  const [workflowInstance, setWorkflowInstance] = React.useState<any>();
  const [workflowCompleted, setWorkflowCompleted] =
    React.useState<boolean>(false);

  const { data: msgTemplates = [], isLoading } = useMessageTemplates({
    enabled: Boolean(userDetails),
  });

  React.useEffect(() => {
    if (!approval?.type || isLoading) {
      return;
    }

    const extractionRightName = approval?.extractionRights?.[0]?.name ?? "";

    const mapper: {
      [key: number]: {
        id: string;
        values: Record<string, any>;
      };
    } = {
      [AdministrativeApprovalNumericType.SWT]: {
        id: "ResponseSeasonalTransferInfoRequest",
        values: {
          level0WRSIdentifier:
            approval?.extractionRights?.[0].level0Resource.identifier,
        },
      },
      [AdministrativeApprovalNumericType.PT]: {
        id: "ResponsePermanentTransferInfoRequest",
        values: {
          extractionRightNames: approval?.extractionRights
            ?.map((i: any) => i.name)
            ?.join(", "),
        },
      },
      [AdministrativeApprovalNumericType.FD]: {
        id: "ResponseForwardDrawInfoRequest",
        values: {
          extractionRightName,
        },
      },
      [AdministrativeApprovalNumericType.SD]: {
        id: "ResponseStockAndDomesticInfoRequest",
        values: {
          extractionRightName,
        },
      },
      [AdministrativeApprovalNumericType.AT]: {
        id: "ResponseAdminTransferInfoRequest",
        values: {
          extractionRightName,
          extractionPointName: approval?.extractionPoint?.name,
        },
      },
    };

    const { id, values } = mapper[approval.type] ?? {
      id: "",
      values: {},
    };
    const template = msgTemplates.find((i: any) => i.identifier === id);

    if (template) {
      const data = {
        messageTemplate: template,
        subject: t(template.subjectTextTemplate, values),
        body: t(template.bodyTextTemplate, {
          recipientName: t("common.administrators"),
          senderName: userDetails?.subscriber.name,
          senderAccountNumber: userDetails?.subscriber?.accountNumber,
        }),
      };
      setMessage(data);
    }
  }, [
    isLoading,
    msgTemplates,
    t,
    approval?.extractionPoint?.name,
    approval?.extractionRights,
    approval?.type,
    userDetails?.subscriber?.accountNumber,
    userDetails?.subscriber?.name,
  ]);

  const value = {
    approval,
    setApproval,
    message,
    setMessage,
    workflowInstance,
    setWorkflowInstance,
    workflowCompleted,
    setWorkflowCompleted,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

const useResponseApprovalInfoRequestContext = () => {
  const context = React.useContext(Context);
  if (context === undefined) {
    throw new Error(
      "useResponseApprovalInfoRequestContext must be used within a ResponseApprovalInfoRequestProvider"
    );
  }
  return context;
};

export {
  useResponseApprovalInfoRequestContext,
  ResponseApprovalInfoRequestProvider,
};

import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { getLevel0Resource } from "@services/level0Resource";

export function useLevel0Resource(id: string, options?: UseQueryOptions) {
  return useQuery<any>({
    queryKey: ["level0Resources", id],
    queryFn: () => getLevel0Resource(id),
    enabled: !!id,
    ...options,
  });
}

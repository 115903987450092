import UserProfileForm from "@components/form/profile/UserProfileForm";
import Layout from "@components/layout/Layout";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

const ProfileIndex: FunctionComponent = () => {
  const { t } = useTranslation();

  const breadcrumb = [
    {
      label: t("common.home"),
      href: "/polestar",
    },
    {
      label: t("profile.title"),
    },
  ];

  return (
    <Layout
      permissions={["ViewLevel1Resource"]}
      breadcrumb={breadcrumb}
      title={t("profile.title")}
    >
      <UserProfileForm />
    </Layout>
  );
};

export default ProfileIndex;

import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";

export interface UseStepHelpers {
  goToNextStep: () => void;
  goToPrevStep: () => void;
  goToLastStep: () => void;
  reset: () => void;
  canGoToNextStep: boolean;
  canGoToPrevStep: boolean;
  setStep: Dispatch<SetStateAction<number>>;
}

type setStepCallbackType = (step: number | ((step: number) => number)) => void;

export function useStep(maxStep: number): [number, UseStepHelpers] {
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const canGoToNextStep = useMemo(
    () => currentStep + 1 < maxStep,
    [currentStep, maxStep]
  );

  const canGoToPrevStep = useMemo(() => currentStep - 1 >= 0, [currentStep]);

  const setStep = useCallback<setStepCallbackType>(
    (step) => {
      const newStep = step instanceof Function ? step(currentStep) : step;

      if (newStep >= 0 && newStep <= maxStep) {
        setCurrentStep(newStep);
        return;
      }

      throw new Error("Step not valid");
    },
    [maxStep, currentStep]
  );

  const goToNextStep = useCallback(() => {
    if (canGoToNextStep) {
      setCurrentStep((step) => step + 1);
    }
  }, [canGoToNextStep]);

  const goToPrevStep = useCallback(() => {
    if (canGoToPrevStep) {
      setCurrentStep((step) => step - 1);
    }
  }, [canGoToPrevStep]);

  const goToLastStep = useCallback(() => {
    setCurrentStep(maxStep - 1);
  }, [maxStep]);

  const reset = useCallback(() => {
    setCurrentStep(1);
  }, []);

  return [
    currentStep,
    {
      goToNextStep,
      goToPrevStep,
      goToLastStep,
      canGoToNextStep,
      canGoToPrevStep,
      setStep,
      reset,
    },
  ];
}

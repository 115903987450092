import { updateLevel0Resource } from "@services/level0Resource";
import { useMutation } from "@tanstack/react-query";

export const useUpdateLevel0wrs = (options: any = {}) => {
  return useMutation<any, any, any>({
    mutationKey: ["update_level_0_wrs"],
    mutationFn: updateLevel0Resource,
    ...options,
  });
};

import React from "react";

const FaucetIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <g clipPath="url(#clip0_1586_43517)">
        <path
          fill="#004A88"
          d="M17.875 20.625c0 .757.618 1.375 1.375 1.375s1.375-.618 1.375-1.375-1.375-2.75-1.375-2.75-1.375 1.994-1.375 2.75zM15.125 8.25h-1.656c-.677-.58-1.528-.993-2.469-1.203V5.006l-1.375-.15-1.375.15v2.04c-.941.216-1.792.628-2.47 1.204H.686A.685.685 0 000 8.938v4.124c0 .383.306.688.688.688h3.986c.886 1.624 2.76 2.75 4.951 2.75s4.064-1.128 4.95-2.75h.55c.757 0 1.375.614 1.375 1.371s.619 1.379 1.375 1.379h2.75c.757 0 1.375-.618 1.375-1.375a6.873 6.873 0 00-6.875-6.875zM3.508 4.12l6.117-.645 6.119.645c.408.043.756-.29.756-.72V2.1c0-.429-.35-.762-.757-.719L11 1.88V.688A.685.685 0 0010.312 0H8.938a.685.685 0 00-.688.688V1.88L3.508 1.38c-.409-.043-.758.29-.758.72V3.4c0 .43.35.763.758.72z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1586_43517">
          <path fill="#fff" d="M0 0H22V22H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default FaucetIcon;

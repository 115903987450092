import { getIsExtractionRightLinkableWithPoint } from "@utils/getIsExtractionRightLinkableWithPoint";
import Tag from "./Tag";
import { useTranslation } from "react-i18next";

type Props = {
  extractionRight: any;
  extractionPointId: string;
  administrativeApprovals: any[];
};

const ExtractionRightLinkableWithPointTag = ({
  extractionRight,
  extractionPointId,
  administrativeApprovals,
}: Props) => {
  const { t } = useTranslation();
  const checkIsLinkable = getIsExtractionRightLinkableWithPoint(
    extractionRight,
    extractionPointId,
    administrativeApprovals
  );

  return (
    <>
      {checkIsLinkable.reason ? (
        <Tag status="default">{checkIsLinkable.reason}</Tag>
      ) : checkIsLinkable.isLinkable ? (
        <Tag status="success">{t("extraction_point.link.already_linked")}</Tag>
      ) : (
        ""
      )}
    </>
  );
};

export default ExtractionRightLinkableWithPointTag;

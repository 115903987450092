import Label from "@components/form/Label";
import PhoneInput from "@components/form/PhoneInput";
import TextInput from "@components/form/TextInput";
import Card from "@components/layout/Card";
import Alert from "@components/shared/Alert";
import { useAppContext } from "@context/AppContext";
import { zodResolver } from "@hookform/resolvers/zod";
import { checkEmail, checkTel, updateCurrentUser } from "@services/user";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FunctionComponent, createElement, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import DropDownMenu from "../DropDownMenu";
import Modal from "@components/layout/Modal";
import ProcessingIcon from "@components/icons/ProcessingIcon";
import Heading from "@components/layout/Heading";
import { downloadAsCSV } from "@utils/downloadAsCSV";
import { downloadAsPDF } from "@utils/downloadAsPDF";
import ReactDOMServer from "react-dom/server";
import UserProfilePrint from "./UserProfilePrint";

const UserProfileForm: FunctionComponent = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [emailInvalid, setEmailInvalid] = useState("");
  const [telInvalid, setTelInvalid] = useState("");
  const [isExporting, setIsExporting] = useState(false);
  const {
    loggedInInfo: { userDetails },
  } = useAppContext();
  const userSchema = z
    .object({
      name: z.string().min(1, t("common.required.text") as string),
      email: z
        .string()
        .email(t("common.required.email") as string)
        .min(1, t("common.required.email") as string),
      tel: z.string().min(1, t("common.required.text") as string),
      password: z.string().optional(),
      passwordConfirmation: z.string().optional(),
    })
    .refine(
      (data) =>
        !data.password ||
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/.test(
          data.password
        ),
      {
        message: t("auth.new_password_validation_message") as string,
        path: ["password"],
      }
    )
    .refine((data) => data.password === data.passwordConfirmation, {
      message: t("common.required.confirm_password") as string,
      path: ["passwordConfirmation"],
    });
  const {
    mutateAsync: updateCurrentUserMutation,
    isLoading,
    isSuccess,
    isError,
  } = useMutation(updateCurrentUser);
  const { mutateAsync: validateEmail } = useMutation(checkEmail);
  const { mutateAsync: validateTel } = useMutation(checkTel);

  const {
    register,
    handleSubmit,
    control,
    resetField,
    formState: { errors },
  } = useForm<z.infer<typeof userSchema>>({
    resolver: zodResolver(userSchema),
    values: {
      name: userDetails?.name,
      email: userDetails?.email,
      tel: userDetails?.tel,
      password: "",
      passwordConfirmation: "",
    },
  });

  const onSubmit = async (data: any) => {
    setEmailInvalid("");
    setTelInvalid("");
    const emailExists = await validateEmail({
      email: data.email,
      id: userDetails?.id,
    });

    if (emailExists) {
      setEmailInvalid(t("common.required.email_exists") as string);
      return;
    }

    const telExists = await validateTel({
      tel: data.tel,
      id: userDetails?.id,
    });

    if (telExists) {
      setTelInvalid(t("common.required.tel_exists") as string);
      return;
    }

    await updateCurrentUserMutation(data);

    queryClient.invalidateQueries(["refreshToken"]);

    resetField("password");
    resetField("passwordConfirmation");
  };

  const exportCSV = () => {
    setIsExporting(true);

    downloadAsCSV({
      data: [
        {
          [t("profile.name_field")]: userDetails?.name,
          [t("profile.email_field")]: userDetails?.email,
          [t("profile.tel_field")]: String(userDetails?.tel),
          [t("common.type")]: userDetails?.isPrimary
            ? t("user.primary")
            : t("user.general"),
        },
      ],
      fileName: `${userDetails?.email}-${Date.now().toString()}`,
    });

    setIsExporting(false);
  };

  const exportPDF = async () => {
    setIsExporting(true);

    const element = createElement(UserProfilePrint, {
      userDetails: userDetails!,
    });
    const printElement = ReactDOMServer.renderToString(element);
    await downloadAsPDF({
      data: printElement,
      fileName: `${userDetails?.email}-${Date.now().toString()}`,
    });

    setIsExporting(false);
  };

  return (
    <Card>
      {isSuccess && <Alert type="success">{t("profile.saved_success")}</Alert>}
      {isError && <Alert type="error">{t("profile.saved_failure")}</Alert>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full lg:w-1/3">
          <div>
            <Label htmlFor="name">{t("profile.name_field")}</Label>
            {
              <TextInput
                {...register("name")}
                errorMessage={errors.name?.message}
              />
            }
          </div>
          <div>
            <Label htmlFor="email">{t("profile.email_field")}</Label>
            <TextInput
              {...register("email")}
              errorMessage={errors?.email?.message || emailInvalid}
            />
          </div>
          <div>
            <Label htmlFor="tel">{t("profile.tel_field")}</Label>
            <Controller
              name="tel"
              control={control}
              render={({ field: { onChange, value, name }, fieldState }) => (
                <PhoneInput
                  id="tel"
                  name={name}
                  onChange={onChange}
                  value={value}
                  errorMessage={fieldState.error?.message || telInvalid}
                />
              )}
            />
          </div>
          <div>
            <Label htmlFor="password">{t("profile.password_field")}</Label>
            <TextInput
              type="password"
              placeholder={t("profile.password_placeholder") as string}
              {...register("password")}
              errorMessage={errors?.password?.message}
            />
          </div>
          <div>
            <Label htmlFor="passwordConfirmation">
              {t("profile.confirm_password_field")}
            </Label>
            <TextInput
              type="password"
              placeholder={t("profile.confirm_password_placeholder") as string}
              {...register("passwordConfirmation")}
              errorMessage={errors?.passwordConfirmation?.message}
            />
          </div>
        </div>
        <footer className="mt-4 flex gap-2 justify-between">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={isLoading}
          >
            {t("common.save")}
          </button>
          <DropDownMenu
            className="!text-base flex justify-center py-2 px-4 rounded-md border border-transparent focus:outline-none disabled:opacity-25 disabled:cursor-not-allowed transition text-primary-2 hover:text-white hover:bg-primary-2 border-primary-2"
            placeHolder={t("profile.export") as string}
            items={[
              {
                label: t("profile.export_csv"),
                onClick: exportCSV,
              },
              {
                label: t("profile.export_pdf"),
                onClick: exportPDF,
              },
            ]}
          />
        </footer>
      </form>
      <Modal open={isExporting}>
        <div className="py-8 px-4 flex justify-center items-center gap-4">
          <div className="flex h-12 w-12">
            <ProcessingIcon />
          </div>
          <Heading className="text-xl font-medium leading-6 text-primary-2">
            {t("common.loading")}
          </Heading>
        </div>
      </Modal>
    </Card>
  );
};

export default UserProfileForm;

import React from "react";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import ConfirmModal from "@components/shared/ConfirmModal";
import WorkflowComplete from "@components/shared/WorkflowComplete";
import { toastSuccess, toastError } from "@utils/toast";
import { usePermanentTransferContext } from "@context/PermanentTransferContext";
import { useStepContext } from "@context/shared/StepContext";
import { useEvidenceContext } from "@context/shared/EvidenceContext";
import { isValidationError } from "@utils/formError";
import ConfirmationDetail from "@components/shared/ConfirmationDetail";

type ConfirmPermanentTransferProps = {
  onSubmit: () => Promise<void>;
};

const ConfirmPermanentTransfer: React.FunctionComponent<
  ConfirmPermanentTransferProps
> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const { stepHelpers } = useStepContext();
  const {
    handleCancel,
    isTransferComplete,
    setIsTransferComplete,
    workflowInstance,
    info,
  } = usePermanentTransferContext();
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const { getEvidencesInfo } = useEvidenceContext();

  const handleConfirmTransfer = async () => {
    try {
      setIsLoading(true);
      await onSubmit();
      setIsTransferComplete(true);
      toastSuccess(t("permanent_trade.transfer_success"));
    } catch (error: any) {
      const { data } = error?.response;
      const errorMessages = isValidationError(error)
        ? data?.errors?.map((i: any) => i.message)
        : [data?.message];

      toastError(
        <>
          <p>
            {t("permanent_trade.error_message.transfer", {
              error: error?.response?.data?.message || error?.message,
            })}
          </p>
          {errorMessages?.length ? (
            <ul className="list-disc pl-4">
              {errorMessages.map((text: any) => {
                return <li key={text}>{text}</li>;
              })}
            </ul>
          ) : null}
        </>
      );
    } finally {
      setIsLoading(false);
      setShowConfirmModal(false);
    }
  };

  if (isTransferComplete && workflowInstance) {
    return <WorkflowComplete references={[workflowInstance?.id]} />;
  }

  return (
    <>
      <div className="p-6 space-y-4">
        <Heading light>{t("permanent_trade.confirm_transfer")}</Heading>

        <ConfirmationDetail
          data={[
            info.getLevel1Resource(),
            info.getSeller(),
            info.getExtractionRights(),
            info.getVolumes(),
            info.getExtractionPoints(),
            info.getBuyer(),
            info.getDetails(),
            info.getPriceInfo(),
            getEvidencesInfo(),
          ]}
          onEdit={stepHelpers.setStep}
        />
      </div>

      <div className="grow" />

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>

        <button
          className="btn-primary"
          onClick={() => setShowConfirmModal(true)}
        >
          {t("common.ledger")}
        </button>
        <button className="btn-outline-primary" onClick={handleCancel}>
          {t("common.cancel")}
        </button>
      </footer>

      <ConfirmModal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleConfirmTransfer}
        isSubmitting={isLoading}
      >
        {t("permanent_trade.confirm_modal")}
      </ConfirmModal>
    </>
  );
};

export default ConfirmPermanentTransfer;

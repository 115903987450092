import { useTranslation } from "react-i18next";

import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import { formatDateInput } from "@utils/formatDate";
import { useAllAccountingPeriods } from "@hooks/query/useAllAccountingPeriods";

type TimestampInputProps = {
  type?: "date" | "datetime";
  id?: string;
  level1ResourceId: string;
  label?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
};

const formatISODateInput = (iso: string) => {
  const [y, m, d] = iso.split("T")[0].split("-");

  return `${y}-${m}-${d}`;
};

const TimestampInput: React.FunctionComponent<TimestampInputProps> = ({
  type = "date",
  id,
  level1ResourceId,
  label,
  value,
  onChange,
  required = false,
}) => {
  const { t } = useTranslation();

  const { data: accountingPeriod = {}, isLoading } = useAllAccountingPeriods({
    params: {
      isActive: true,
      level1ResourceId,
    },
    options: {
      enabled: Boolean(level1ResourceId),
      select: (res: any) => res?.[0],
    },
  });

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Label htmlFor={id}>{label ?? t("common.effective_date")}</Label>
      <TextInput
        type={type}
        id={id}
        min={formatISODateInput(
          new Date(accountingPeriod?.periodStart).toISOString(),
        )}
        max={formatISODateInput(
          new Date(accountingPeriod?.periodEnd).toISOString(),
        )}
        value={formatDateInput(new Date(value))}
        onChange={onChange}
        required={required}
      />
    </>
  );
};

export default TimestampInput;

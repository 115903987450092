import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import HandleGoBackOrClose from "@components/shared/HandleGoBackOrClose";
import Layout from "@components/layout/Layout";
import StepButton from "@components/shared/StepButton";
import StepInfo from "@components/shared/StepInfo";
import WorkflowComplete from "@components/shared/WorkflowComplete";
import WorkflowPanels from "@components/form/WorkflowPanels";
import InfoPanel from "@components/form/InfoPanel";
import UploadEvidencesForm from "@components/form/UploadEvidencesForm";
import ForwardDrawSelectLevel1WRS from "@components/form/admin_approvals/create_forward_draw/ForwardDrawSelectLevel1Resource";
import ForwardDrawSelectSubscribers from "@components/form/admin_approvals/create_forward_draw/ForwardDrawSelectSubscribers";
import ForwardDrawSelectExtractionRights from "@components/form/admin_approvals/create_forward_draw/ForwardDrawSelectExtractionRights";
import ForwardDrawLedger from "@components/form/admin_approvals/create_forward_draw/ForwardDrawLedger";
import { StepProvider, useStepContext } from "@context/shared/StepContext";
import {
  EvidenceProvider,
  useEvidenceContext,
} from "@context/shared/EvidenceContext";
import { useAllSubscribers } from "@hooks/query/useAllSubscribers";
import { useAllExtractionRights } from "@hooks/query/useAllExtractionRights";
import { useAllLevel1Resources } from "@hooks/query/useAllLevel1Resources";
import { useManualCreateForwardDraw } from "@hooks/mutation/useManualCreateForwardDraw";
import { extractionRightTypes } from "@services/extractionRight";
import { DBTables } from "@utils/constants/dbTables";
import VolumeInput from "@components/form/VolumeInput";
import { convertMLToLiter } from "@utils/convertUnits";

const CreateForwardDraw = () => {
  return (
    <StepProvider maxStep={6}>
      <EvidenceProvider>
        <Consumer />
      </EvidenceProvider>
    </StepProvider>
  );
};

const Consumer = () => {
  const { t } = useTranslation();
  const handleGoBackOrClose = HandleGoBackOrClose();
  const [searchParams] = useSearchParams();
  const level1ResourceId = searchParams.get("level1ResourceId") ?? "";
  const subscriberId = searchParams.get("subscriberId") ?? "";
  const { currentStep, stepHelpers } = useStepContext();
  const { getEvidencesInfo, uploadEvidences } = useEvidenceContext();
  const [selectedLevel1Resource, setSelectedLevel1Resource] = useState<any>();
  const [selectedSubscribers, setSelectedSubscribers] = useState<
    Record<string, any>[]
  >([]);
  const [selectedExtractionRights, setSelectedExtractionRights] = useState<
    Record<string, any>[]
  >([]);
  const [isWorkflowComplete, setIsWorkflowComplete] = useState(false);
  const [workflowInstance, setWorkflowInstance] = useState<any>();
  const [forwardDrawVolumes, setForwardDrawVolumes] = useState<number[]>([]);

  useAllLevel1Resources({
    enabled: Boolean(level1ResourceId) && !selectedLevel1Resource,
    onSuccess: (data: any[]) => {
      const level1Resource = data.find(l => l.id === level1ResourceId);
      if (level1Resource) {
        setSelectedLevel1Resource(level1Resource);
        stepHelpers.setStep(1);
      }
    },
  });

  const { data: subscribers = [] } = useAllSubscribers({
    params: { level1ResourceId: selectedLevel1Resource?.id },
    options: {
      enabled: Boolean(selectedLevel1Resource?.id),
      onSuccess: (data: any[]) => {
        const subscriber = data.find(s => s.id === subscriberId);
        if (selectedSubscribers.length === 0 && subscriber) {
          setSelectedLevel1Resource(subscriber.level1WRS);
          setSelectedSubscribers([subscriber]);
          stepHelpers.setStep(2);
        }
      },
    },
  });

  const { data: extractionRights = [] } = useAllExtractionRights({
    params: {
      subscriberIds: selectedSubscribers.map(s => s?.id),
      types: [extractionRightTypes.WA, extractionRightTypes.WSA],
      isActive: true,
    },
    options: {
      enabled: selectedSubscribers.length > 0,
    },
  });

  const { mutateAsync: manualCreateForwardDrawMutation } =
    useManualCreateForwardDraw();

  const handleConfirm = async () => {
    const [res, workflowInstance] = await manualCreateForwardDrawMutation({
      extractionRightIds: selectedExtractionRights.map((er: any) => er.id),
      volumes: forwardDrawVolumes.map(v => Number(convertMLToLiter(v))),
      lodgedAt: new Date(),
    });
    await uploadEvidences({
      description: t("approval.forward_draws.evidence_description", {
        extractionRightNames: selectedExtractionRights
          .map((i: any) => i.name)
          .join(", "),
      }),
      references: [
        {
          referenceId: res[0].id,
          referenceTable: DBTables.AdministrativeApproval,
        },
        {
          referenceId: selectedExtractionRights?.[0]?.subscriber?.id,
          referenceTable: DBTables.Subscribers,
        },
        {
          referenceId: workflowInstance?.id,
          referenceTable: DBTables.WorkflowInstances,
        },
      ],
    });
    setIsWorkflowComplete(true);
    setWorkflowInstance(workflowInstance);
  };

  const handleCancel = handleGoBackOrClose;

  const info = {
    level1Resource: {
      title: t("approval.forward_draws.approve.selected_level1wrs"),
      body: [
        {
          key: t("common.level1wrs"),
          value: `${selectedLevel1Resource?.name} (${selectedLevel1Resource?.identifier})`,
        },
      ],
    },
    subscribers: {
      title: t("approval.forward_draws.approve.selected_subscribers"),
      body: [
        {
          key: t("common.subscriber"),
          value: `${selectedSubscribers?.[0]?.name} (${selectedSubscribers?.[0]?.accountNumber})`,
        },
      ],
    },
    extractionRights: {
      title: t("approval.forward_draws.approve.select_entitlements"),
      body: [
        {
          key: t("common.extraction_rights"),
          value: selectedExtractionRights.map((er: any) => er?.name).join(", "),
        },
      ],
    },
    volumes: {
      title: t("approval.forward_draws.approve.input_volumes"),
      body: [
        {
          key: t("approval.forward_draws.approve.volumes"),
          value: forwardDrawVolumes.join(", "),
        },
      ],
    },
  };

  return (
    <Layout
      permissions={["CreateApprovals"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("common.administrative_approvals"),
          href: `/polestar/administrative_approvals#tab=2`,
        },
        {
          label: t("approval.forward_draws.approve.title"),
        },
      ]}
      title={t("approval.forward_draws.approve.title")}
      accountingPeriodLevel1ResourceId={selectedLevel1Resource?.id}
    >
      <WorkflowPanels
        selectedStep={currentStep}
        steps={[
          {
            label: (
              <StepButton
                index={0}
                currentStep={currentStep}
                info={<StepInfo data={info.level1Resource.body} />}
                isOnChain={isWorkflowComplete}
              >
                {t("approval.forward_draws.approve.select_level1wrs")}
              </StepButton>
            ),
            panel: (
              <ForwardDrawSelectLevel1WRS
                selectedLevel1Resource={selectedLevel1Resource}
                onSelect={(selected: any) => {
                  setSelectedLevel1Resource(selected);
                  setSelectedSubscribers([]);
                  setSelectedExtractionRights([]);
                }}
                onCancel={handleCancel}
              />
            ),
            infoPanel: (
              <InfoPanel>
                <div className="space-y-6">
                  <p>
                    {t("approval.forward_draws.approve.info.select_level1wrs")}
                  </p>
                </div>
              </InfoPanel>
            ),
          },
          {
            label: (
              <StepButton
                index={1}
                currentStep={currentStep}
                info={<StepInfo data={info.subscribers.body} />}
                isOnChain={isWorkflowComplete}
              >
                {t("approval.forward_draws.approve.select_subscribers")}
              </StepButton>
            ),
            panel: (
              <ForwardDrawSelectSubscribers
                subscribers={subscribers}
                selectedSubscribers={selectedSubscribers}
                onSelect={(selected: any) => {
                  setSelectedSubscribers(selected);
                  setSelectedExtractionRights([]);
                }}
                onCancel={handleCancel}
              />
            ),
            infoPanel: (
              <InfoPanel>
                <div className="space-y-6">
                  <p>
                    {t("approval.forward_draws.approve.info.select_subscriber")}
                  </p>
                </div>
              </InfoPanel>
            ),
          },
          {
            label: (
              <StepButton
                index={2}
                currentStep={currentStep}
                info={<StepInfo data={info.extractionRights.body} />}
                isOnChain={isWorkflowComplete}
              >
                {t("approval.forward_draws.approve.select_entitlements")}
              </StepButton>
            ),
            panel: (
              <ForwardDrawSelectExtractionRights
                extractionRights={extractionRights}
                selectedExtractionRights={selectedExtractionRights}
                onSelect={setSelectedExtractionRights}
                onCancel={handleCancel}
              />
            ),
            infoPanel: (
              <InfoPanel>
                <div className="space-y-6">
                  <p>
                    {t(
                      "approval.forward_draws.approve.info.select_extraction_right",
                    )}
                  </p>
                </div>
              </InfoPanel>
            ),
          },
          {
            label: (
              <StepButton
                index={3}
                currentStep={currentStep}
                info={<StepInfo data={info.volumes.body} />}
                isOnChain={isWorkflowComplete}
              >
                {t("approval.forward_draws.approve.input_volumes")}
              </StepButton>
            ),
            panel: (
              <VolumeInput
                selectedExtractionRights={selectedExtractionRights}
                onVolumeChange={setForwardDrawVolumes}
                onNext={stepHelpers.goToNextStep}
                onPrevious={stepHelpers.goToPrevStep}
                onCancel={handleCancel}
                volumes={forwardDrawVolumes}
                fixedRate={true}
              />
            ),
            infoPanel: (
              <InfoPanel>
                <ul className="pl-2 space-y-8">
                  <li>
                    {t(
                      "approval.forward_draw.forward_draw_volume_introduction",
                    )}
                  </li>
                  <li>{t("approval.forward_draw.respective_volume")}</li>
                  <li>
                    {t("approval.forward_draw.forward_draw_default_volume")}
                  </li>
                </ul>
              </InfoPanel>
            ),
          },
          {
            label: (
              <StepButton
                index={4}
                currentStep={currentStep}
                info={<StepInfo data={getEvidencesInfo().body} />}
                isOnChain={isWorkflowComplete}
              >
                {t("common.enter_evidence")}
              </StepButton>
            ),
            panel: (
              <UploadEvidencesForm
                onNext={stepHelpers.goToNextStep}
                onPrevious={stepHelpers.goToPrevStep}
                onCancel={handleCancel}
              />
            ),
            infoPanel: (
              <InfoPanel>
                <div className="space-y-6">
                  <p>{t("approval.forward_draws.approve.info.evidence")}</p>
                  <p>{t("evidence.supported")}</p>
                </div>
              </InfoPanel>
            ),
          },
          {
            label: (
              <StepButton
                index={5}
                currentStep={currentStep}
                isOnChain={isWorkflowComplete}
              >
                {t("approval.forward_draws.approve.review_application")}
              </StepButton>
            ),
            panel:
              isWorkflowComplete && workflowInstance ? (
                <WorkflowComplete references={[workflowInstance?.id]} />
              ) : (
                <ForwardDrawLedger
                  data={[
                    info.level1Resource,
                    info.subscribers,
                    info.extractionRights,
                    info.volumes,
                    getEvidencesInfo(),
                  ]}
                  onConfirm={handleConfirm}
                  onCancel={handleCancel}
                />
              ),
            infoPanel: (
              <InfoPanel
                successes={[
                  isWorkflowComplete
                    ? t("approval.forward_draws.approve.info.success")
                    : t("approval.forward_draws.approve.info.confirm"),
                ]}
              >
                <div className="space-y-6">
                  {isWorkflowComplete ? (
                    <p>
                      {t("approval.forward_draws.approve.info.end_workflow")}
                    </p>
                  ) : (
                    <>
                      <p>{t("approval.forward_draws.approve.info.approved")}</p>
                      <p>{t("common.info.changes")}</p>
                    </>
                  )}
                </div>
              </InfoPanel>
            ),
          },
        ]}
      />
    </Layout>
  );
};

export default CreateForwardDraw;

import XCircleIcon from "../icons/XCircleIcon";
import ExclamationTriangleIcon from "../icons/ExclamationTriangleIcon";
import CheckCircleIcon from "../icons/CheckCircleIcon";
import InformationCircleIcon from "../icons/InformationCircleIcon";
import React from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";

type Type = "success" | "error" | "info" | "warning";

type Props = {
  type: Type;
  children: React.ReactNode;
  className?: string;
  closeButton?: boolean;
};

const colors: Record<Type, string> = {
  success: "green",
  info: "blue",
  error: "red",
  warning: "yellow",
};

const icons: Record<Type, any> = {
  success: CheckCircleIcon,
  info: InformationCircleIcon,
  error: XCircleIcon,
  warning: ExclamationTriangleIcon,
};

export default function Alert({
  type,
  children,
  className,
  closeButton,
}: Props) {
  const [show, setShow] = React.useState(true);
  const color = colors[type] ?? colors.info;
  const Icon = icons[type];

  return show ? (
    <div
      className={classNames(
        className,
        `border-l-4 border-${color}-400 bg-${color}-50 p-4`
      )}
    >
      <div className="flex justify-between gap-2">
        <div className="flex-shrink-0">
          <Icon className={`h-5 w-5 text-${color}-400`} aria-hidden="true" />
        </div>
        <div className="flex-1">
          <div className={`text-sm text-${color}-700`}>{children}</div>
        </div>
        {closeButton ? (
          <button
            type="button"
            className="flex-shrink-0 inline-flex w-6 h-6 hover:bg-white rounded-full"
            onClick={() => {
              setShow(false);
            }}
          >
            <XMarkIcon
              className={classNames(`text-${color}-700`, "w-5 h-5 m-auto")}
            />
          </button>
        ) : null}
      </div>
    </div>
  ) : null;
}

import HeartIcon from "@components/icons/HeartIcon";
import WorkflowDeclarationIcon from "@components/icons/WorkflowDeclarationIcon";
import WorkflowUserIcon from "@components/icons/WorkflowUserIcon";
import Card from "@components/layout/Card";
import { useToggleFavoriteWorkflow } from "@hooks/mutation/useToggleFavoriteWorkflow";
import { useQueryClient } from "@tanstack/react-query";
import { formatDateTime } from "@utils/formatDateTime";
import classNames from "classnames";
import { t } from "i18next";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

type WorkflowGroupProps = { identifier: string; workflows: any[] };

const WorkflowGroup: FunctionComponent<WorkflowGroupProps> = ({
  identifier,
  workflows,
}) => {
  const icons: {
    [key: string]: FunctionComponent<any>;
  } = {
    favorites: HeartIcon,
    user: WorkflowUserIcon,
    water_declaration: WorkflowDeclarationIcon,
  };
  const WorkflowGroupIcon = icons[identifier];
  const queryClient = useQueryClient();
  const { mutateAsync: toggleFavoriteWorkflow } = useToggleFavoriteWorkflow({
    onSuccess: () => {
      queryClient.invalidateQueries(["workflowGroups"]);
    },
  });

  const handleToggleFavorite = async (workflowId: string) => {
    await toggleFavoriteWorkflow(workflowId);
  };

  if (!WorkflowGroupIcon) return null;

  return (
    <>
      <header className="flex gap-2 text-xl font-bold items-center">
        <WorkflowGroupIcon />
        {t(`zenith.workflows.workflow_groups.${identifier}`)}
      </header>
      <section className="grid grid-cols-4 gap-2">
        {workflows?.map(workflow => {
          const WorkflowIcon = icons[workflow.identifier];

          if (!WorkflowIcon) return null;

          return (
            <Link to={`/zenith/workflows/${workflow.identifier}`}>
              <Card className="flex flex-col shadow-lg gap-6 p-6">
                <div className="w-full flex justify-center">
                  <div className="max-h-10">
                    <WorkflowIcon className="w-12 h-12 text-primary-3" />
                  </div>
                </div>
                <p className="text-base">
                  {t(`zenith.workflows.${workflow.identifier}`)}
                </p>
                <p className="text-gray-600 text-base">
                  {t("zenith.workflows.last_run")}:{" "}
                  {workflow.lastInstanceRunAt
                    ? formatDateTime(new Date(workflow.lastInstanceRunAt))
                    : "-"}
                </p>
                <p className="flex justify-between">
                  <button
                    type="button"
                    className={classNames({
                      "text-gray-500 hover:text-gray-700": !workflow.isFavorite,
                      "text-red-500 hover:text-red-700": workflow.isFavorite,
                    })}
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();

                      handleToggleFavorite(workflow.id);
                    }}
                  >
                    <HeartIcon />
                  </button>
                </p>
              </Card>
            </Link>
          );
        })}
      </section>
    </>
  );
};

export default WorkflowGroup;

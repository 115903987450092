import classNames from "classnames";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { groupBy, sumBy } from "lodash";

import Table from "@components/layout/Table";
import Heading from "@components/layout/Heading";
import TextInput from "@components/form/TextInput";
import ExternalLinkIcon from "@components/icons/ExternalLinkIcon";
import { useSeasonalTransferContext } from "@context/SeasonalTransferContext";
import { convertLiterToML } from "@utils/convertUnits";
import { useAllExtractionRights } from "@hooks/query/useAllExtractionRights";
import { extractionRightTypes } from "@services/extractionRight";

const SeasonalTransferSelectLevel0WRSClassWithVolume = () => {
  const { t } = useTranslation();
  const {
    stepHelpers,
    navigateForCancel,
    details,
    handleChangeDetails,
    getInfoMessages,
    validateVolume,
    resetBuyer,
    resetModifyStatus,
  } = useSeasonalTransferContext();

  const {
    data: extractionRights = [],
    isLoading: isQueryExtractionRightsLoading,
  } = useAllExtractionRights({
    params: {
      assertedByWalletId: details.from.subscriber.walletId,
      types: [
        extractionRightTypes.WA,
        extractionRightTypes.WSA,
        extractionRightTypes.Q,
      ],
      isActive: true,
    },
    options: {
      enabled: Boolean(details.from.subscriber.walletId),
    },
  });

  const groupExtractionRightsByLevel0Resource = groupBy(
    extractionRights,
    "level0Resource.identifier"
  );

  const handleSelect = (selectedIds: string[]) => {
    resetBuyer();
    resetModifyStatus(2);

    const selectedLevel0wrsWaterClass = zoneAccounts.find(
      (i) => selectedIds[0] === i.id
    );

    if (selectedLevel0wrsWaterClass) {
      const { waterClass, level0Resource } = selectedLevel0wrsWaterClass;

      const updated = {
        waterClass: {
          id: waterClass.id,
          name: waterClass.name,
        },
        level0Resource: {
          id: level0Resource.id,
          identifier: level0Resource.identifier,
        },
        extractionRights: [],
      };

      handleChangeDetails("from", {
        subscriber: details.from.subscriber,
        ...updated,
      });
      handleChangeDetails("to", {
        subscriber: details.to.subscriber,
        ...updated,
      });
      handleChangeDetails("transferVolume", "");
    }
  };

  const zoneAccounts: {
    id: string;
    waterClass: {
      id: string;
      name: string;
    };
    level0Resource: {
      id: string;
      identifier: string;
    };
    balance: number;
    formatBalance: string;
    extractionRightsName: string;
    balanceAssign: React.ReactNode;
    view: React.ReactNode;
  }[] = [];

  for (let level0Identifier in groupExtractionRightsByLevel0Resource) {
    const groupByWaterClass = groupBy(
      groupExtractionRightsByLevel0Resource[level0Identifier],
      "waterClass.name"
    );
    const level0ResourceId =
      groupExtractionRightsByLevel0Resource[level0Identifier]?.[0]
        .level0ResourceId;

    for (let waterClassName in groupByWaterClass) {
      const balance = sumBy(
        groupByWaterClass[waterClassName],
        (i) => i.remainingBalance
      );
      const formatBalance = convertLiterToML(balance);
      const waterClassId = groupByWaterClass[waterClassName][0].waterClassId;
      const data = {
        id: `${level0ResourceId}--${waterClassId}`,
        waterClass: {
          id: waterClassId,
          name: waterClassName,
        },
        level0Resource: {
          id: level0ResourceId,
          identifier: level0Identifier,
        },
        balance,
        formatBalance,
        extractionRightsName: groupByWaterClass[waterClassName]
          .map((i) => i.name)
          .join(", "),
      };

      const isSelected =
        details.from.level0Resource.id === level0ResourceId &&
        details.from.waterClass.id === waterClassId;
      const errorClass =
        isSelected && getInfoMessages("error", 2).length
          ? "border-red-300 focus:outline-red-500"
          : "";
      const successClass =
        isSelected && getInfoMessages("success", 2).length
          ? "border-green-300 focus:outline-green-500"
          : "";

      zoneAccounts.push({
        balanceAssign: (
          <TextInput
            className={classNames(
              "w-40",
              "appearance-none",
              "rounded-sm",
              "border",
              "focus:border-0",
              errorClass,
              successClass
            )}
            type="number"
            step="0.001"
            min={0}
            max={convertLiterToML(balance)}
            onChange={(e) => {
              resetBuyer();
              const volume = e.target.value;
              handleChangeDetails("transferVolume", volume);

              const validateType =
                +volume > +formatBalance
                  ? "Volume"
                  : +volume <= 0
                  ? "Invalid"
                  : "Success";

              validateVolume(
                data.id,
                validateType,
                +formatBalance,
                data.extractionRightsName,
                data.level0Resource.identifier,
                data.waterClass.name
              );
            }}
            value={isSelected ? details.transferVolume : ""}
            suffix={t("common.volume_unit")}
            placeholder="0.000"
            disabled={!isSelected}
            required
          />
        ),
        view: (
          <Link
            to={`/polestar/subscribers/${details.from.subscriber.id}?level0ResourceId=${level0ResourceId}`}
            target="_blank"
            rel="noopener noreferrer"
            className="btn-default flex items-center gap-3 w-min"
          >
            <ExternalLinkIcon className="w-4 h-4" />
            {t("common.view")}
          </Link>
        ),
        ...data,
      });
    }
  }

  const tableFields = [
    {
      title: t("common.level0wrs"),
      name: "level0Resource.identifier",
    },
    {
      title: t("common.water_class"),
      name: "waterClass.name",
    },
    {
      title: t("extraction_right.numbers"),
      name: "extractionRightsName",
    },
    {
      title: `${t(
        "approval.seasonal_water_assignments.create.step_3.available"
      )} (${t("common.volume_unit")})`,
      name: "formatBalance",
    },
    {
      title: `${t(
        "approval.seasonal_water_assignments.create.step_3.assignable_volume"
      )} (${t("common.volume_unit")})`,
      name: "balanceAssign",
    },
    {
      title: "",
      name: "view",
    },
  ];

  return (
    <>
      <div className="p-6 flex flex-col gap-4 grow">
        <Heading light>
          {t("approval.seasonal_water_assignments.create.step_3.title")}
        </Heading>
        <Table
          selectionKey="id"
          onSelectionChange={handleSelect}
          selectedKeys={[
            `${details.from.level0Resource.id}--${details.from.waterClass.id}`,
          ]}
          fields={tableFields}
          data={zoneAccounts.filter((i) => i.balance > 0)}
          loading={isQueryExtractionRightsLoading}
          stickyHeader
          multiple={false}
        />
      </div>

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>

        <button
          type="button"
          className="btn-primary"
          onClick={stepHelpers.goToNextStep}
          disabled={
            !details.transferVolume || getInfoMessages("error", 2).length > 0
          }
        >
          {t("common.next_step")}
        </button>

        <button
          type="button"
          className="btn-outline-primary"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </>
  );
};

export default SeasonalTransferSelectLevel0WRSClassWithVolume;

import { Routes, Route, Navigate } from "react-router-dom";

import AuthTabs from "./AuthTabs";
import SignUp from "./SignUp";
import ConfirmSignUp from "./ConfirmSignUp";
import Login from "../Login";
import Logout from "../Logout";
import ForgotPassword from "../ForgotPassword";

import AppLayout from "@components/layout/zenith/AppLayout";
import Dashboard from "./dashboard/Dashboard";
import ZoneAccount from "./zone_accounts/ZoneAccount";
import History from "./History";
import Notifications from "./notifications/Notifications";
import CreateNotification from "./notifications/CreateNotification";
import ExchangesDashboard from "./exchanges/ExchangesDashboard";
import WorkflowIndex from "./workflows/WorkflowIndex";
import WorkflowWaterDeclaration from "./workflows/WorkflowWaterDeclaration";
import AuditTrailIndex from "./audit_trail/AuditTrailIndex";
import WorkflowCreateForwardDraw from "./workflows/WorkflowCreateForwardDraw";
import WorkflowResponseApprovalInfoRequest from "./workflows/WorkflowResponseApprovalInfoRequest";

import ApprovalTabs from "@components/layout/zenith/ApprovalTabs";
import SeasonalTransferIndex from "./approvals/SeasonalTransferIndex";
import PermanentTransferIndex from "./approvals/PermanentTransferIndex";
import ForwardDrawIndex from "./approvals/ForwardDrawIndex";
import AdminTransferIndex from "./approvals/AdminTransferIndex";
import StockAndDomesticIndex from "./approvals/StockAndDomesticIndex";
import WorkflowCreateAdminTransfer from "./workflows/WorkflowCreateAdminTransfer";
import AccountBalances from "./account_balances/AccountBalances";
import MyProfile from "./profile/MyProfile";
import UserSubscriber from "./profile/UserSubscriber";

const ZenithApp = () => {
  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route index element={<Dashboard />} />
        <Route path="profile">
          <Route path="me" element={<MyProfile />} />
          <Route path="subscriber" element={<UserSubscriber />} />
          <Route index element={<Navigate to="/zenith/profile/me" />} />
        </Route>
        <Route path="zone_accounts/:id" element={<ZoneAccount />} />
        <Route path="history" element={<History />} />
        <Route path="notifications">
          <Route path="new" element={<CreateNotification />} />
          <Route index element={<Notifications />} />
        </Route>
        <Route path="exchanges" element={<ExchangesDashboard />} />
        <Route path="workflows">
          <Route
            path="water_declaration"
            element={<WorkflowWaterDeclaration />}
          />
          <Route
            path="create_forward_draw"
            element={<WorkflowCreateForwardDraw />}
          />
          <Route
            path="create_admin_transfer"
            element={<WorkflowCreateAdminTransfer />}
          />
          <Route
            path="response_approval_info_request"
            element={<WorkflowResponseApprovalInfoRequest />}
          />
          <Route index element={<WorkflowIndex />} />
        </Route>
        <Route path="audit_trail" element={<AuditTrailIndex />} />
        <Route path="balances" element={<AccountBalances />} />

        <Route path="approvals" element={<ApprovalTabs />}>
          <Route index element={<Navigate to="seasonals" replace />} />
          <Route path="seasonals" element={<SeasonalTransferIndex />} />
          <Route path="permanents" element={<PermanentTransferIndex />} />
          <Route path="forward_draws" element={<ForwardDrawIndex />} />
          <Route
            path="stock_and_domestics"
            element={<StockAndDomesticIndex />}
          />
          <Route path="admin_transfers" element={<AdminTransferIndex />} />
        </Route>
      </Route>

      <Route path="logout" element={<Logout />} />
      <Route path="forgotPassword" element={<ForgotPassword />} />
      <Route element={<AuthTabs />}>
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<SignUp />} />
      </Route>
      <Route path="confirm_signup" element={<ConfirmSignUp />} />

      <Route path="*" element={<Navigate to="/zenith" />} />
    </Routes>
  );
};

export default ZenithApp;

import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import { formatVolume } from "@utils/formatVolume";
import { usePermanentTransferContext } from "@context/PermanentTransferContext";
import { useStepContext } from "@context/shared/StepContext";
import { useAppContext } from "@context/AppContext";

const EnterTransferExtractionRightsVolume: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { checkPermissions } = useAppContext();
  const { stepHelpers } = useStepContext();
  const {
    selectedExtractionRights,
    setSelectedExtractionPoints,
    changeTransferRightVolume,
    changeTransferRightPercentage,
    changeIsTransferH2W,
    changeTransferH2W,
    changeTransferH2WPercentage,
    handleCancel,
    canTransferPoints,
  } = usePermanentTransferContext();

  const thClassNames = "w-52 pb-2 text-left text-sm font-semibold";
  const tdClassNames = "pb-2 pl-3 text-sm";

  return (
    <>
      <div className="p-6 flex flex-col gap-6 grow">
        <Heading light>{t("permanent_trade.enter_volume")}</Heading>

        {selectedExtractionRights.map((selectedRight) => {
          const canTransferH2W = selectedRight.remainingBalance > 0;

          return (
            <div
              key={selectedRight.id}
              className="border-b border-gray-100 pb-4 last-of-type:border-0"
            >
              <h3 className="mb-3 text-lg">
                {t("extraction_right.number")}: {selectedRight.name}
              </h3>
              <table>
                <tbody>
                  <tr>
                    <th className={thClassNames}>
                      {t("extraction_right.volume")}:
                    </th>
                    <td className={tdClassNames}>
                      {formatVolume(selectedRight.volume)}
                    </td>
                  </tr>
                  {checkPermissions(["TransferExtractionRightEnterVolume"]) && (
                    <>
                      <tr>
                        <th className={thClassNames}>
                          {t("permanent_trade.transfer_volume")}:
                        </th>
                        <td className={tdClassNames}>
                          <TextInput
                            type="number"
                            name="transfer_volume"
                            min={0}
                            className="inline-block w-36"
                            value={selectedRight.transferRightVolume}
                            onChange={(e) =>
                              changeTransferRightVolume(
                                selectedRight.id,
                                e.target.value
                              )
                            }
                            errorMessage={
                              selectedRight.isRightVolumeError
                                ? (t(
                                    "permanent_trade.error_message.h2right"
                                  ) as string)
                                : ""
                            }
                            suffix={t("common.volume_unit")}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className={thClassNames}>
                          {t("permanent_trade.transfer_percentage")}:
                        </th>
                        <td className={tdClassNames}>
                          <TextInput
                            type="number"
                            name="transfer_percentage"
                            min={0}
                            max={100}
                            className="inline-block w-36"
                            value={selectedRight.transferRightPercentage}
                            onChange={(e) =>
                              changeTransferRightPercentage(
                                selectedRight.id,
                                +e.target.value
                              )
                            }
                            suffix="%"
                          />
                        </td>
                      </tr>
                    </>
                  )}

                  <tr
                    className={classNames({
                      "opacity-50": !canTransferH2W,
                    })}
                  >
                    <th className={thClassNames}>
                      <Label
                        htmlFor={`include_physical_water__${selectedRight.name}`}
                        className="text-inherit"
                      >
                        {t("permanent_trade.include_h2w")}:
                      </Label>
                    </th>
                    <td className={tdClassNames}>
                      <input
                        type="checkbox"
                        id={`include_physical_water__${selectedRight.name}`}
                        onChange={(e) => {
                          changeIsTransferH2W(
                            selectedRight.id,
                            e.target.checked
                          );
                        }}
                        checked={selectedRight.isTransferH2W}
                        className="mt-2"
                        disabled={!canTransferH2W}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className={thClassNames}>
                      {t("approval.permanent_trades.available_balance")}
                    </th>
                    <td className={tdClassNames}>
                      {formatVolume(selectedRight.remainingBalance)}
                    </td>
                  </tr>

                  {selectedRight.isTransferH2W ? (
                    <>
                      <tr>
                        <th className={thClassNames}>
                          {t("permanent_trade.h2w")}:
                        </th>
                        <td className={tdClassNames}>
                          <TextInput
                            type="number"
                            name="physical_water_volume"
                            min={0}
                            className="inline-block w-36"
                            value={selectedRight.transferH2W}
                            onChange={(e) =>
                              changeTransferH2W(
                                selectedRight.id,
                                e.target.value
                              )
                            }
                            suffix={t("common.volume_unit")}
                            errorMessage={
                              selectedRight.isH2WError
                                ? (t(
                                    "permanent_trade.error_message.h2w"
                                  ) as string)
                                : ""
                            }
                          />
                        </td>
                      </tr>
                      {checkPermissions([
                        "TransferExtractionRightEnterH2WPercentage",
                      ]) && (
                        <tr>
                          <th className={thClassNames}>
                            {t("permanent_trade.h2w_percentage")}:
                          </th>
                          <td className={tdClassNames}>
                            <TextInput
                              type="number"
                              name="physical_water_percentage"
                              min={0}
                              max={100}
                              className="inline-block w-36"
                              value={selectedRight.transferH2WPercentage}
                              onChange={(e) =>
                                changeTransferH2WPercentage(
                                  selectedRight.id,
                                  +e.target.value
                                )
                              }
                              suffix="%"
                            />
                          </td>
                        </tr>
                      )}
                    </>
                  ) : null}
                </tbody>
              </table>
            </div>
          );
        })}
      </div>

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>
        <button
          type="button"
          className="btn-primary"
          onClick={() => {
            stepHelpers.goToNextStep();

            if (!canTransferPoints) {
              setSelectedExtractionPoints([]);
              stepHelpers.goToNextStep();
            }
          }}
          disabled={selectedExtractionRights.some(
            (i) => i.isRightVolumeError || i.isH2WError
          )}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={handleCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </>
  );
};

export default EnterTransferExtractionRightsVolume;

import api from "@services/api";

export const trialRunBulkMeters = async (payload: {
  level1ResourceId: string;
  bulkMeterReadings: Record<string, any>[];
}) => {
  const { data } = await api.post("/admin/trialRunBulkMeter", payload);

  return data.trialRun;
};

type BulkMeterUploadParams = {
  level1ResourceId: string;
  bulkMeters: {
    extractionPointName: string;
    serialNo: string;
    reading: number;
    readAt: Date;
    calculation: string;
  }[];
  fileName: string;
};

export const bulkMeterUpload = async ({
  level1ResourceId,
  bulkMeters,
  fileName,
}: BulkMeterUploadParams) => {
  const declarationData = bulkMeters.map((meter) => {
    return {
      "Declaration/extraction_point_name": meter.extractionPointName,
      "Declaration/meter_id": meter.serialNo,
      "Declaration/reading": meter.reading,
      "Declaration/read_at": meter.readAt,
      "Declaration/struck_at": meter.readAt,
      "Declaration/is_opening": false,
      "Declaration/calculation": meter.calculation,
    };
  });

  const { data } = await api.post("/admin/meterUploads", {
    level1ResourceId,
    fileName,
    declarationData,
  });

  return [data.meterUpload, data.workflowInstance];
};

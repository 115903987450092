import React from "react";
import classNames from "classnames";
import { useAppContext } from "@context/AppContext";
import { groupBy, sum, sumBy } from "lodash";
import { useTranslation } from "react-i18next";

import Card from "@components/layout/Card";
import Heading from "@components/layout/Heading";
import Loading from "@components/shared/Loading";
import Label from "@components/form/Label";
import DropWaterIcon from "@components/icons/DropWaterIcon";
import { useAllExtractionRights } from "@hooks/query/zenith/useAllExtractionRights";
import { useAllAdministrativeApprovals } from "@hooks/query/zenith/useAllAdministrativeApprovals";
import { extractionRightTypes } from "@services/extractionRight";
import {
  AdministrativeApprovalNumericType,
  AdministrativeApprovalNumericStatus,
} from "@services/administrativeApprovals";
import { convertLiterToML } from "@utils/convertUnits";
import { percentage } from "@utils/percentage";

type Info = {
  name: string;
  availableWater: number;
  usage: number;
  transferOut: number;
  transferIn: number;
};

type ViewBy = "summary" | "water_class" | "level0wrs";

const totalUsage = (extractionRights: any[]) => {
  const usages = extractionRights.map((i: any) => {
    const usageArray = i.rightFractions.map((f: any) => Number(f.usage));
    return sum(usageArray);
  });
  return sum(usages);
};

const totalAvailableWater = (extractionRights: any[]) =>
  sumBy(extractionRights, (i: any) => +i.volume * i.cumulativeAllocated);

const totalTransferVolume = (approvals: any[]) =>
  sumBy(approvals, (i: any) => +i.volume);

const AvailableWaterWidget: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { loggedInInfo } = useAppContext();
  const subscriberId = loggedInInfo?.userDetails?.subscriberId ?? "";
  const [viewBy, setViewBy] = React.useState<ViewBy>("summary");

  const { data: extractionRights = [], isLoading: isExtractionRightsLoading } =
    useAllExtractionRights({
      params: {
        typeIds: [
          extractionRightTypes.WA,
          extractionRightTypes.WSA,
          extractionRightTypes.SD,
        ],
        isActive: true,
      },
    });

  const {
    data: { administrativeApprovals } = {
      administrativeApprovals: [],
    },
    isLoading: isApprovalsLoading,
  } = useAllAdministrativeApprovals({
    params: {
      types: [
        AdministrativeApprovalNumericType.SWT,
        AdministrativeApprovalNumericType.PT,
      ],
      status: AdministrativeApprovalNumericStatus.Approved,
    },
  });

  const sellingApprovals = React.useMemo(() => {
    return administrativeApprovals.filter(
      (i: any) => i.sellerId === subscriberId
    );
  }, [administrativeApprovals, subscriberId]);

  const buyingApprovals = React.useMemo(() => {
    return administrativeApprovals.filter(
      (i: any) => i.buyerId === subscriberId
    );
  }, [administrativeApprovals, subscriberId]);

  const infos: Info[] = React.useMemo(() => {
    switch (viewBy) {
      case "water_class": {
        const groupByWaterClass = groupBy(extractionRights, "waterClass.name");
        const result = Object.entries(groupByWaterClass).map(
          ([name, extractionRights]) => {
            const ids = extractionRights.map((i: any) => i.waterClassId);
            const sellings = sellingApprovals.filter((i: any) =>
              ids.includes(i.waterClassId)
            );
            const buyings = buyingApprovals.filter((i: any) =>
              ids.includes(i.waterClassId)
            );

            return {
              name,
              availableWater: totalAvailableWater(extractionRights),
              usage: totalUsage(extractionRights),
              transferOut: totalTransferVolume(sellings),
              transferIn: totalTransferVolume(buyings),
            };
          }
        );
        return result;
      }
      case "level0wrs": {
        const groupByLevel0 = groupBy(
          extractionRights,
          "level0Resource.identifier"
        );
        const result = Object.entries(groupByLevel0).map(
          ([name, extractionRights]) => {
            const currentLevel0ResourceId =
              extractionRights?.[0].level0ResourceId;
            const sellings = sellingApprovals.filter(
              (i: any) => i.level0ResourceId === currentLevel0ResourceId
            );
            const buyings = buyingApprovals.filter(
              (i: any) => i.level0ResourceId === currentLevel0ResourceId
            );
            return {
              name,
              availableWater: totalAvailableWater(extractionRights),
              usage: totalUsage(extractionRights),
              transferOut: totalTransferVolume(sellings),
              transferIn: totalTransferVolume(buyings),
            };
          }
        );
        return result;
      }
      default: {
        return [
          {
            name: "",
            availableWater: totalAvailableWater(extractionRights),
            usage: totalUsage(extractionRights),
            transferOut: totalTransferVolume(sellingApprovals),
            transferIn: totalTransferVolume(buyingApprovals),
          },
        ];
      }
    }
  }, [buyingApprovals, extractionRights, sellingApprovals, viewBy]);

  if (isExtractionRightsLoading || isApprovalsLoading) {
    return (
      <div className="flex justify-center pt-16">
        <Loading />
      </div>
    );
  }

  const viewByButtons: {
    label: string;
    value: ViewBy;
  }[] = [
    {
      label: t("common.summary"),
      value: "summary",
    },
    {
      label: t("water_class.water_class_header"),
      value: "water_class",
    },
    {
      label: t("zenith.dashboard.management_area"),
      value: "level0wrs",
    },
  ];

  return (
    <Card
      header={
        <h2 className="flex gap-3 item-centers text-inherit">
          <DropWaterIcon className="w-5 h-5" />
          {t("zenith.balances.available_water")}
        </h2>
      }
    >
      <header className="space-y-4">
        <div className="text-sm">
          <Label>{t("common.view_by")}:</Label>
          <nav className="flex gap-3">
            {viewByButtons.map(({ label, value }) => (
              <button
                key={value}
                type="button"
                className={classNames(
                  "rounded",
                  value === viewBy ? "btn-primary" : "btn-default"
                )}
                onClick={(e) => {
                  e.preventDefault();
                  setViewBy(value);
                }}
              >
                {label}
              </button>
            ))}
          </nav>
        </div>
      </header>

      <div className="space-y-10 divide-y">
        {infos.map((i) => (
          <React.Fragment key={i?.name || "available-water"}>
            <DisplayInfo data={i} />
          </React.Fragment>
        ))}
      </div>
    </Card>
  );
};

const DisplayInfo: React.FunctionComponent<{
  data: Info;
}> = ({ data }) => {
  const { t } = useTranslation();
  const { name, availableWater, usage, transferIn, transferOut } = data;
  const trades = transferIn - transferOut;
  const total = availableWater + trades;
  const remaningBalance = total - usage;
  const usagePercentage = percentage(usage, total);
  const tradesPercentage = percentage(trades, total);

  return (
    <div className="pt-10">
      {name ? <Heading className="mb-4">{name}</Heading> : null}

      {/* Remaining balance */}
      <div className="flex mb-8">
        <div className="flex-none w-60 flex gap-3 items-center font-semibold text-lg">
          {t("zenith.balances.remaining")}
          <span className="inline-block rounded-lg text-white bg-blue-500 px-2 py-1 tracking-wide text-2xl">
            {convertLiterToML(remaningBalance)}
          </span>
        </div>
        <div className="flex-1">
          <div className="block bg-blue-100 rounded-full h-3 mt-6 mb-4">
            <span
              className={classNames(
                "flex bg-blue-500 h-full rounded-full relative",
                usagePercentage > 3 ? "justify-end" : "justify-start"
              )}
              style={{
                width: `${usagePercentage}%`,
              }}
            >
              <span className="absolute -top-6">{usagePercentage}%</span>
            </span>
          </div>
        </div>
      </div>

      {/* Total balance */}
      <div className="flex">
        <div className="flex-none w-60 flex gap-3 items-center font-semibold text-lg">
          <div>{t("zenith.balances.of_total")}</div>
          <span className="inline-block rounded-lg bg-blue-100 px-2 py-1 tracking-wide text-2xl">
            {convertLiterToML(total)}
          </span>
        </div>
        <div className="flex-1">
          <div className="relative">
            <div className="flex grow h-20 text-lg bg-gray-100">
              {availableWater > 0 ? (
                <span className="flex items-end flex-1 pl-2 bg-teal-300 h-full relative hover:bg-teal-400 transition:color duration-150">
                  {convertLiterToML(availableWater)}
                </span>
              ) : null}
              {trades > 0 ? (
                <span
                  className="flex items-end justify-end pr-2 bg-purple-300 h-full relative shrink-0 hover:bg-purple-400 transition:color duration-150"
                  style={{
                    width: `${tradesPercentage}%`,
                  }}
                >
                  {convertLiterToML(trades)}
                </span>
              ) : null}
            </div>

            {usage > 0 ? (
              <div className="w-full absolute top-0 left-0">
                <span
                  className="flex justify-start items-center rounded-br-full rounded-tr-full bg-white/50 text-gray-900 py-0.5 text-sm whitespace-nowrap"
                  style={{
                    width: `${usagePercentage}%`,
                  }}
                >
                  <span className="inline-block pl-2">
                    {convertLiterToML(usage)} {t("zenith.balances.extracted")}
                  </span>
                </span>
              </div>
            ) : null}
          </div>

          <ul className="flex gap-4 mt-4">
            {total > 0 ? (
              <li className="flex items-center gap-2">
                <div className="w-4 h-4 rounded-full bg-teal-300" />
                {t("common.extraction_rights")}
              </li>
            ) : null}
            {trades > 0 ? (
              <li className="flex items-center gap-2">
                <div className="w-4 h-4 rounded-full bg-purple-300" />
                {t("common.trades")}
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AvailableWaterWidget;

import React from "react";

const HistoryIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#004A88"
        d="M1.06252 3.6874H11.6875V1.5624C11.6875 1.13407 11.9465 0.745598 12.3449 0.579583C12.7434 0.413567 13.1983 0.506536 13.5037 0.808684L16.6912 3.99618C16.8905 4.1954 17.0033 4.46435 17.0033 4.74657C17.0033 5.0288 16.8905 5.29775 16.6912 5.49697L13.5037 8.68447C13.1983 8.98993 12.7434 9.07958 12.3449 8.91357C11.9465 8.74755 11.6875 8.3624 11.6875 7.93075V5.8124H1.06252C0.474829 5.8124 2.44142e-05 5.33759 2.44142e-05 4.7499C2.44142e-05 4.1622 0.474829 3.6874 1.06252 3.6874ZM15.9375 12.1874C16.5252 12.1874 17 12.6622 17 13.2499C17 13.8376 16.5252 14.3124 15.9375 14.3124H5.31252V16.4374C5.31252 16.8657 5.05354 17.2542 4.6551 17.4202C4.25667 17.5862 3.80178 17.4933 3.49631 17.1911L0.308813 14.0036C0.109595 13.8044 -0.0032959 13.5354 -0.0032959 13.2532C-0.0032959 12.971 0.109595 12.702 0.308813 12.5028L3.49631 9.31532C3.80178 9.00986 4.25667 8.92021 4.6551 9.08622C5.05354 9.25224 5.31252 9.6374 5.31252 10.069V12.194H15.9375V12.1874Z"
      />
    </svg>
  );
};

export default HistoryIcon;

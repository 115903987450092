import { FunctionComponent } from "react";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import leaflet, { LeafletMouseEvent } from "leaflet";
import classNames from "classnames";

import "leaflet/dist/leaflet.css";

type OpenStreetMapProps = {
  zoomLevel?: number;
  zoomControl?: boolean;
  center?: { lat: number; lng: number };
  markers?: Array<{ lat: number; lng: number }>;
  className?: string;
  onClick?: (e: { lat: number; lng: number }) => void;
  onMarkerClick?: (e: { lat: number; lng: number }) => void;
};

type MapEventsProps = {
  onClick?: (e: { lat: number; lng: number }) => void;
};

const markerIcon = new leaflet.Icon({
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  iconAnchor: [12.5, 41],
  popupAnchor: [0, -41],
  iconSize: [25, 41],
  shadowSize: [41, 41],
});

const MapEvents: FunctionComponent<MapEventsProps> = ({ onClick }) => {
  useMapEvents({
    click: (e: LeafletMouseEvent) => {
      if (onClick) {
        onClick({
          lat: e.latlng.lat,
          lng: e.latlng.lng,
        });
      }
    },
  });

  return null;
};

const OpenStreetMap: FunctionComponent<OpenStreetMapProps> = ({
  center,
  markers,
  className,
  zoomLevel = 13,
  zoomControl = true,
  onClick,
  onMarkerClick,
  ...props
}) => {
  const handleMarkerClick = (e: any) => {
    if (onMarkerClick)
      onMarkerClick({
        lat: e.latlng.lat,
        lng: e.latlng.lng,
      });
  };

  return (
    <MapContainer
      center={center}
      zoom={zoomLevel}
      zoomControl={zoomControl}
      className={classNames("w-full h-full -z-0", className)}
      {...props}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {markers &&
        markers.map((marker, index) => (
          <Marker
            position={marker}
            icon={markerIcon}
            eventHandlers={{ click: handleMarkerClick }}
            key={`marker--${index}`}
          />
        ))}
      <MapEvents onClick={onClick} />
    </MapContainer>
  );
};

export default OpenStreetMap;

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Card from "@components/layout/Card";
import Table from "@components/layout/Table";
import Tag from "@components/shared/Tag";
import UserTableActions from "@components/table/UserTableActions";
import { UserGroupIcon } from "@heroicons/react/24/solid";
import { useAllUsers } from "@hooks/query/useAllUsers";

type SubscriberAccountHoldersWidgetProps = {
  subscriberId: string;
};
const SubscriberAccountHoldersWidget: React.FunctionComponent<
  SubscriberAccountHoldersWidgetProps
> = ({ subscriberId }) => {
  const { t } = useTranslation();
  const { data: users = [], refetch } = useAllUsers({
    params: { subscriberId },
    enabled: Boolean(subscriberId),
  });

  const primaryUser = users.filter((user: any) => user.isPrimary);
  const generalUser = users.filter((user: any) => !user.isPrimary);

  const tableData = [...primaryUser, ...generalUser].map(
    (user: Record<string, any>) => {
      return {
        ...user,
        type: (
          <Tag status={user.isPrimary ? "info" : "warning"}>
            {user.isPrimary ? t("user.primary") : t("user.general")}
          </Tag>
        ),
        actions: <UserTableActions user={user} refetch={refetch} />,
      };
    }
  );

  return (
    <Card
      className="h-full"
      header={
        <div className="flex items-center justify-between gap-3">
          <p className="inline-flex gap-3">
            <UserGroupIcon className="w-6 h-6" />{" "}
            {t("subscriber.accounts_widget_title")}
          </p>
          <Link
            to={`/polestar/subscribers/${subscriberId}/users/create?subscriberId=${subscriberId}`}
            className="btn-secondary text-sm rounded font-normal"
          >
            {t("common.add_new")}
          </Link>
        </div>
      }
    >
      <div className="flex flex-col gap-4">
        <Table
          containerClassName="rounded-none md:rounded-none scrollbar-hide text-sm"
          tableHeaderClassName="relative z-10"
          fields={[
            {
              title: t("user.username"),
              name: "name",
            },
            {
              title: t("user.email"),
              name: "email",
            },
            {
              title: t("user.tel"),
              name: "tel",
            },
            {
              title: t("common.type"),
              name: "type",
            },
            {
              title: t("common.actions"),
              name: "actions",
            },
          ]}
          data={tableData}
        />
      </div>
    </Card>
  );
};

export default SubscriberAccountHoldersWidget;

import React from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import Legend from "@components/form/Legend";
import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import TextArea from "@components/form/TextArea";
import SelectLevel0Resource from "@components/form/SelectLevel0Resource";
import SelectExtractionPointGroup from "@components/form/SelectExtractionPointGroup";
import ReadOnlyInput from "@components/form/ReadOnlyInput";
import ConfirmModal from "@components/shared/ConfirmModal";
import { useAppContext } from "@context/AppContext";
import { useExtractionPointContext } from "@context/ExtractionPointContext";
import { getAllExtractionPoints } from "@services/extractionPoints";

const ExtractionPointDetail = () => {
  const { checkPermissions } = useAppContext();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const level0ResourceId = searchParams.get("level0ResourceId") || "";
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [showConfirmUpdateNameModal, setShowConfirmUpdateNameModal] =
    React.useState(false);
  const {
    stepHelpers,
    details,
    handleChangeDetails,
    updatingPoint,
    navigateForCancel,
    checkHasEntitlementInSameZone,
  } = useExtractionPointContext();
  const [errors, setErrors] = React.useState({
    name: "",
    sequence: "",
  });

  const nameValidation = async (): Promise<boolean> => {
    let errorMessage = "";

    if (updatingPoint.id && updatingPoint.name === details.name) {
      errorMessage = "";
    } else if (details.name) {
      const points = await getAllExtractionPoints({
        params: {
          level1ResourceId: details.level1wrs.id,
          name: details.name,
        },
      });
      errorMessage =
        points.length > 0
          ? t("extraction_point.create.validation.name", {
              name: details.name,
              level1ResourceName: details.level1wrs.name,
            })
          : "";
    }

    setErrors(prev => ({
      ...prev,
      name: errorMessage,
    }));

    return errorMessage === "";
  };

  const seqValidation = async (): Promise<boolean> => {
    let errorMessage = "";

    if (!checkPermissions(["ExtractionPointSequence"])) {
      return true;
    }

    if (
      updatingPoint?.sequence !== undefined &&
      updatingPoint.sequence === +details.sequence
    ) {
      errorMessage = "";
    } else if (details.sequence !== "") {
      const points = await getAllExtractionPoints({
        params: {
          level1ResourceId: details.level1wrs.id,
          group: details.group,
          sequence: details.sequence,
        },
      });
      errorMessage =
        points.length > 0
          ? t("extraction_point.create.validation.sequence", {
              sequence: details.sequence,
              group: details.group,
            })
          : "";
    }

    setErrors(prev => ({
      ...prev,
      sequence: errorMessage,
    }));

    return errorMessage === "";
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const isValidName = await nameValidation();
    const isValidSeq = await seqValidation();

    if (!isValidName || !isValidSeq) {
      return;
    }

    if (updatingPoint.id && updatingPoint.name !== details.name) {
      setShowConfirmUpdateNameModal(true);
      return;
    }

    if (!updatingPoint.id && !checkHasEntitlementInSameZone()) {
      setShowConfirmModal(true);
      return;
    }

    stepHelpers.goToNextStep();
  };

  return (
    <>
      <form className="flex flex-col p-6 grow gap-6" onSubmit={handleSubmit}>
        <Heading light>{t("extraction_point.create.enter.details")}</Heading>

        <fieldset className="space-y-4">
          <div className="max-w-md">
            <Label htmlFor="name">{t("extraction_point.name")}</Label>
            <TextInput
              id="name"
              value={details.name}
              onChange={e => handleChangeDetails("name", e.target.value)}
              required
              errorMessage={errors.name}
              disabled={
                Boolean(updatingPoint?.id) &&
                !checkPermissions(["UpdateExtractionPointName"])
              }
            />
          </div>

          <div className="max-w-md">
            <Label htmlFor="description" optional>
              {t("extraction_point.description")}
            </Label>
            <TextArea
              id="description"
              value={details.description || ""}
              onChange={e => handleChangeDetails("description", e.target.value)}
            />
          </div>

          {checkPermissions(["ExtractionPointGroup"]) && (
            <div className="max-w-md">
              <Label htmlFor="group" optional>
                {t("extraction_point.group")}
              </Label>
              <SelectExtractionPointGroup
                id="group"
                level1ResourceId={details.level1wrs.id}
                onChange={e => {
                  handleChangeDetails("group", e?.value ?? "");
                  handleChangeDetails("sequence", "");
                }}
                value={details.group}
                isClearable
              />
            </div>
          )}

          {checkPermissions(["ExtractionPointSequence"]) && (
            <div className="max-w-md">
              <Label htmlFor="sequence" optional>
                {t("extraction_point.sequence")}
              </Label>
              <TextInput
                id="sequence"
                type="number"
                min={0}
                step={0.001}
                placeholder="0.000"
                value={details.sequence || ""}
                onChange={e => handleChangeDetails("sequence", e.target.value)}
                disabled={!details.group}
                errorMessage={errors.sequence}
              />
            </div>
          )}
        </fieldset>

        {checkPermissions([
          "ExtractionPointMaxFlowRate",
          "ExtractionPointMaxVolume",
        ]) && (
          <fieldset className="space-y-4">
            <Legend>
              {t("extraction_point.create.enter.operational_limits")}
            </Legend>
            <div className="max-w-md">
              <Label htmlFor="maximum_flow_rate" optional>
                {t("extraction_point.create.enter.maximum_flow_rate")}
              </Label>
              <TextInput
                id="maximum_flow_rate"
                min={0}
                type="number"
                step="0.001"
                value={details.maxFlow}
                suffix={t("common.volume_unit")}
                placeholder="0.000"
                onChange={e => handleChangeDetails("maxFlow", e.target.value)}
              />
            </div>
            <div className="max-w-md">
              <Label htmlFor="maximum_volume" optional>
                {t("extraction_point.create.enter.maximum_volume")}
              </Label>
              <TextInput
                id="maximum_volume"
                type="number"
                step="0.001"
                min={0}
                value={details.volLimit}
                suffix={t("common.volume_unit")}
                placeholder="0.000"
                onChange={e => handleChangeDetails("volLimit", e.target.value)}
              />
            </div>
          </fieldset>
        )}

        <fieldset className="space-y-4">
          <Legend>{t("extraction_point.create.enter.source_of_water")}</Legend>

          <div className="max-w-md">
            <Label htmlFor="level0wrs">
              {t("extraction_right.create.step_2.select_level0wrs")}
            </Label>
            {level0ResourceId || updatingPoint.id ? (
              <ReadOnlyInput>{details.level0wrs.identifier}</ReadOnlyInput>
            ) : (
              <SelectLevel0Resource
                id="level0wrs"
                level1ResourceId={details.level1wrs.id}
                onChange={e => {
                  handleChangeDetails("level0wrs", {
                    id: e?.value ?? "",
                    identifier: e?.label ?? "",
                  });
                  handleChangeDetails("source", e?.source ?? "");
                }}
                value={details.level0wrs.id}
                required
              />
            )}
          </div>

          {Boolean(details.level0wrs?.identifier) && (
            <div className="max-w-md">
              <Label>
                {t("extraction_point.create.enter.source_of_water")}
              </Label>
              <ReadOnlyInput>{details.source}</ReadOnlyInput>
            </div>
          )}
        </fieldset>

        <div className="grow" />

        <footer className="flex gap-4 -mx-6 mt-6 p-6 pb-0 border-t border-gray-200">
          {!updatingPoint.id && (
            <button
              type="button"
              className="btn-outline-primary"
              onClick={stepHelpers.goToPrevStep}
            >
              {t("common.prev_step")}
            </button>
          )}
          <button
            type="submit"
            className="btn-primary"
            disabled={!details.name || !details.level0wrs.id}
          >
            {t("common.next_step")}
          </button>
          <button
            type="button"
            className="btn-outline-primary"
            onClick={navigateForCancel}
          >
            {t("common.cancel")}
          </button>
        </footer>
      </form>

      <ConfirmModal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={stepHelpers.goToNextStep}
        confirmText={t("common.confirm") as string}
      >
        {t("extraction_point.create.step_2.warning_modal", {
          subscriberName: details.subscriber?.name,
        })}
      </ConfirmModal>

      <ConfirmModal
        open={showConfirmUpdateNameModal}
        onClose={() => setShowConfirmUpdateNameModal(false)}
        onConfirm={stepHelpers.goToNextStep}
        confirmText={t("common.confirm") as string}
      >
        {t("extraction_point.update.modal.update_name", {
          currentName: updatingPoint.name,
          newName: details.name,
        })}
      </ConfirmModal>
    </>
  );
};

export default ExtractionPointDetail;

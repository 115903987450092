import classNames from "classnames";
import React from "react";

type IndeterminateCheckboxProps = {
  indeterminate?: boolean;
} & React.HTMLProps<HTMLInputElement>;

const IndeterminateCheckbox: React.FunctionComponent<
  IndeterminateCheckboxProps
> = ({ type, indeterminate, className = "", ...restProps }) => {
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !restProps.checked && indeterminate;
    }
  }, [ref, indeterminate, restProps.checked]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={classNames("cursor-pointer", className)}
      {...restProps}
    />
  );
};

export default IndeterminateCheckbox;

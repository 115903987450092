import React, { FunctionComponent } from "react";

const DoubleCaretRight: FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      fill="none"
      viewBox="0 0 17 17"
    >
      <path
        stroke="#111827"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 1.5l7 7-7 7m-8-14l7 7-7 7"
      ></path>
    </svg>
  );
};

export default DoubleCaretRight;

import React from "react";
import { Table } from "@tanstack/react-table";

export const useCacheRows = <T = Record<string, any>>(
  table: Table<T>,
  isFetching: boolean
) => {
  const [cacheRows, setCacheRows] = React.useState<Record<string, T>>({});

  React.useEffect(() => {
    const paginationRowModel = table.getPaginationRowModel().rowsById;
    if (!isFetching) {
      const cacheValuesById = Object.entries(paginationRowModel).reduce(
        (acc: any, [id, row]) => {
          acc[id] = row.original;
          return acc;
        },
        {}
      );

      setCacheRows((prevState) =>
        Object.assign({}, prevState, cacheValuesById)
      );
    }
  }, [table, isFetching]);

  return [cacheRows];
};

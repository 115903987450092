import ReplaceMeterCapsuleWorkflow from "@components/form/meter/replace_capsule/ReplaceMeterCapsuleWorkflow";
import Layout from "@components/layout/Layout";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const ReplaceMeterCapsule = () => {
  const { t } = useTranslation();
  const { id: level1ResourceId = "" } = useParams();
  const { data: level1Resource = {} } = useGetLevel1Resource(level1ResourceId, {
    enabled: Boolean(level1ResourceId),
  });

  return (
    <Layout
      permissions={["CreateDeclarations"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: level1Resource?.name,
          href: `/polestar/level1wrs/${level1ResourceId}`,
        },
        {
          label: t("level1wrs.extraction_points"),
          href: `/polestar/level1wrs/${level1ResourceId}#5`,
        },
        {
          label: t("meter.replace_capsule.title"),
        },
      ]}
      title={t("meter.replace_capsule.title")}
      accountingPeriodLevel1ResourceId={level1ResourceId}
    >
      <ReplaceMeterCapsuleWorkflow />
    </Layout>
  );
};

export default ReplaceMeterCapsule;

import { useAppContext } from "@context/AppContext";
import { CheckIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { FunctionComponent, ReactNode, useEffect, useState } from "react";

type WorkflowStepsProps = {
  title?: ReactNode;
  selectedStep: number;
  onStepButtonClick: (step: number) => void;
  disableNavigation?: boolean;
  steps: Array<{
    label?: string;
    panel?: ReactNode;
    infoPanel?: ReactNode;
  }>;
};

const WorkflowSteps: FunctionComponent<WorkflowStepsProps> = ({
  title,
  selectedStep,
  onStepButtonClick,
  disableNavigation = false,
  steps,
}) => {
  const { setInfoPanel } = useAppContext();
  const [stepNo, setStepNo] = useState(selectedStep || 0);
  const handleSelectStep = (i: number) => {
    setStepNo(i);
    onStepButtonClick(i);
  };

  useEffect(() => {
    setStepNo(selectedStep || 0);
  }, [selectedStep]);

  useEffect(() => {
    if (steps[stepNo].infoPanel) {
      setInfoPanel(steps[stepNo].infoPanel);
    }
    // eslint-disable-next-line
  }, [stepNo, setInfoPanel]);

  return (
    <div className="flex flex-col grow gap-4 border border-gray-200 rounded-xl bg-white">
      <header className="flex flex-col bg-blue-50 rounded-t-xl p-4">
        <h1 className="w-full font-bold">{title}</h1>
        <nav aria-label="Progress" className="w-full">
          <ol className="flex justify-center">
            {steps.map((step, stepIdx) => (
              <li
                key={stepIdx}
                className={classNames(
                  stepIdx !== steps.length - 1 ? "pr-8 sm:pr-20" : "",
                  "relative"
                )}
              >
                {stepNo > stepIdx ? (
                  <>
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="h-0.5 w-full bg-primary-1" />
                    </div>
                    {disableNavigation ? (
                      <span
                        className="relative flex h-8 w-8 items-center justify-center rounded-full bg-primary-1 hover:bg-indigo-900"
                        aria-current="step"
                      >
                        <CheckIcon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    ) : (
                      <button
                        type="button"
                        onClick={() => {
                          handleSelectStep(stepIdx);
                        }}
                        className="relative flex h-8 w-8 items-center justify-center rounded-full bg-primary-1 hover:bg-indigo-900"
                      >
                        <CheckIcon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    )}
                  </>
                ) : stepNo === stepIdx ? (
                  <>
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="h-0.5 w-full bg-gray-200" />
                    </div>
                    <span
                      className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-primary-1 bg-white"
                      aria-current="step"
                    >
                      <p>{stepIdx + 1}</p>
                    </span>
                  </>
                ) : (
                  <>
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="h-0.5 w-full bg-gray-200" />
                    </div>
                    <span className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400">
                      <p>{stepIdx + 1}</p>
                    </span>
                  </>
                )}
              </li>
            ))}
          </ol>
        </nav>
      </header>
      <div className="flex flex-col grow w-full p-4">
        {steps[stepNo]?.panel}
      </div>
    </div>
  );
};

export default WorkflowSteps;

import { useTranslation } from "react-i18next";
import CheckCircleOutlineIcon from "@components/icons/CheckCircleOutlineIcon";

type ExtractionPointLinkToRightStep1LabelProps = {
  extractionPointName: any;
};

const ExtractionPointLinkToRightStep1Label: React.FunctionComponent<
  ExtractionPointLinkToRightStep1LabelProps
> = ({ extractionPointName }: ExtractionPointLinkToRightStep1LabelProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <div className="w-full h-full text-left flex items-center text-sm text-green-600">
        <span className="m-2">
          <CheckCircleOutlineIcon />
        </span>
        <span>{t("extraction_point.link.choose_offtake")}</span>
      </div>

      <div className="w-full pl-2 gap-2 flex align-items-center">
        <div className="text-sm font-bold text-gray-500">
          {t("extraction_point.link.offtake_id")}:
        </div>
        <div className="text-sm text-gray-500">{extractionPointName}</div>
      </div>
    </div>
  );
};

export default ExtractionPointLinkToRightStep1Label;

export function parseLatLng(latLng: string) {
  if (!latLng) return { isValid: false };

  const lat = parseFloat(latLng.split(",")[0]);
  const lng = parseFloat(latLng.split(",")[1]);

  if (isNaN(lat) || isNaN(lng)) {
    return { isValid: false };
  }

  const isLatitude = isFinite(lat) && Math.abs(lat) <= 90;
  const isLongitude = isFinite(lng) && Math.abs(lng) <= 180;
  return { isValid: isLatitude && isLongitude, lat, lng };
}

import { useQuery } from "@tanstack/react-query";
import { getAllAccountingPeriods } from "@services/zenith/accountingPeriod";

export const useAllAccountingPeriods = ({
  params,
  ...options
}: {
  params?: Record<string, any>;
  [key: string]: any;
} = {}) => {
  return useQuery<any>({
    queryKey: ["accountingPeriods", { params }],
    queryFn: () => getAllAccountingPeriods(params),
    ...options,
  });
};

import Select from "react-select";
import { t } from "i18next";

import ErrorMessage from "@components/form/ErrorMessage";

type Props = {
  onChange?: (value: any) => void;
  value?: string;
  className?: string;
  name?: string;
  errorMessage?: string;
};

const meterTypes = [
  { value: "analog", label: t("meter.analog") },
  { value: "digital", label: t("meter.digital") },
];

export const getMeterTypeOption = (value: string) => {
  return meterTypes.find((item) => item.value === value)?.value ?? "";
};

const SelectMeterType: React.FunctionComponent<Props> = ({
  onChange,
  value,
  className,
  name,
  errorMessage,
}) => {
  return (
    <>
      <Select
        options={meterTypes}
        onChange={onChange}
        value={meterTypes.find((i) => i.value === value)}
        name={name}
        className={className}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};

export default SelectMeterType;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isFunction } from "lodash";

import api from "@services/api";

type PermanentTransferPayload = {
  sellerId: string;
  buyerId: string;
  extractionRights: {
    id: string;
    transferH2W: number;
  }[];
  extractionPointIds: string[];
  price: number;
  description?: string;
};

const createPermanentTransfer = async (payload: PermanentTransferPayload) => {
  const { data } = await api.post(
    "/admin/administrativeApprovals/permanentTransfer",
    payload,
  );

  return [data.administrativeApproval, data.workflowInstance];
};

export const useCreatePermanentTransfer = ({
  onSuccess,
  ...options
}: Record<string, any> = {}) => {
  const queryClient = useQueryClient();

  return useMutation(createPermanentTransfer, {
    onSuccess: res => {
      queryClient.invalidateQueries({ queryKey: ["administrative_approvals"] });
      if (isFunction(onSuccess)) {
        onSuccess(res);
      }
    },
    ...options,
  });
};

import HistoryIcon from "@components/icons/HistoryIcon";
import Card from "@components/layout/Card";
import Heading1 from "@components/layout/Heading";
import OnChain from "@components/shared/OnChain";
import EventTransactionPaginatedTable from "@components/table/EventTransactionPaginatedTable";
import { useAdminTransferContext } from "@context/AdminTransferContext";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { formatDateTime } from "@utils/formatDateTime";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import EndButtonModal from "@components/shared/EndButton";

const WorkflowCreateAdminApprovalCompleted: FunctionComponent = () => {
  const { t } = useTranslation();

  const { details, message, supportingEvidence, workflowInstance } =
    useAdminTransferContext();

  return (
    <>
      <div className="flex flex-col">
        <div className="flex gap-4">
          <Card className="w-1/2 flex flex-col gap-4 p-4">
            <h1 className="w-full text-green-800 flex items-center">
              <CheckCircleIcon className="w-6 mr-2" /> {t("common.success")}
            </h1>
            <OnChain />
            <dl className="w-full">
              <div className="grid grid-cols-2 gap-2">
                <dt className="text-sm font-bold text-gray-500">
                  {t("workflow.workflow_instance_id")}:
                </dt>
                <dd className="text-sm text-gray-500">
                  {workflowInstance?.id}
                </dd>
                <dt className="text-sm font-bold text-gray-500">
                  {t("workflow.started_at")}:
                </dt>
                <dd className="text-sm text-gray-500">
                  {workflowInstance?.startedAt &&
                    formatDateTime(new Date(workflowInstance?.startedAt))}
                </dd>
                <dt className="text-sm font-bold text-gray-500">
                  {t("common.status")}:
                </dt>
                <dd className="text-sm text-gray-500">{t("common.pending")}</dd>
                {details.extractionRight && (
                  <>
                    <dt className="text-sm font-bold text-gray-500">
                      {t("extraction_right.name")}:
                    </dt>
                    <dd className="text-sm text-gray-500">
                      {details.extractionRight?.name}
                    </dd>
                  </>
                )}
                {details.extractionPoint && (
                  <>
                    <dt className="text-sm font-bold text-gray-500">
                      {t("extraction_point.name")}:
                    </dt>
                    <dd className="text-sm text-gray-500">
                      {details.extractionPoint?.name}
                    </dd>
                  </>
                )}
              </div>
            </dl>
          </Card>
          <Card className="w-1/2 flex flex-col gap-2">
            <h1 className="w-full text-green-800 flex items-center">
              <CheckCircleIcon className="w-6 mr-2" />
              {t("notification.message")}
            </h1>
            <OnChain />
            <dl className="w-full">
              <div className="grid grid-cols-2 gap-2">
                <dt className="text-sm font-bold text-gray-500">
                  {t("notification.subject")}:
                </dt>
                <dd className="text-sm text-gray-500">{message?.subject}</dd>
                <dt className="text-sm font-bold text-gray-500">
                  {t("notification.body")}:
                </dt>
                <dd className="text-sm text-gray-500">
                  <blockquote className="border-l-4 pl-4 whitespace-pre-wrap">
                    {message?.body}
                  </blockquote>
                </dd>
                <dt className="text-sm font-bold text-gray-500">
                  {t("evidence.supporting")}:
                </dt>
                <dd className="text-sm text-gray-500">
                  {supportingEvidence?.name || "-"}
                </dd>
              </div>
            </dl>
          </Card>
        </div>
        <footer className="p-4 flex gap-2">
          <EndButtonModal />
        </footer>
      </div>
      <div className="grow border-t pt-1">
        <Heading1 light className="flex gap-2 items-center">
          <HistoryIcon /> {t("audit_trail.audit_trail")}
        </Heading1>
        <EventTransactionPaginatedTable
          references={[workflowInstance?.id]}
          stickyHeader={false}
        />
      </div>
    </>
  );
};

export default WorkflowCreateAdminApprovalCompleted;

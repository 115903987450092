import { Route, Routes } from "react-router-dom";

import BillingReports from "@components/pages/polestar/reports/BillingReports";
import GeneralReports from "@components/pages/polestar/reports/GeneralReports";
import MeterReadSequenceReport from "@components/pages/polestar/reports/MeterReadSequenceReport";
import RegulatoryReports from "@components/pages/polestar/reports/RegulatoryReports";
import WaterAccountsReport from "@components/pages/polestar/reports/WaterAccountsReport";
import NotFound from "@components/error/NotFound";

const ReportRoutes = () => {
  return (
    <Routes>
      <Route path="regulatory">
        <Route index element={<RegulatoryReports />} />
        <Route path=":id" element={<RegulatoryReports />} />
      </Route>
      <Route path="billing">
        <Route index element={<BillingReports />} />
        <Route path=":id" element={<BillingReports />} />
      </Route>
      <Route path="meter_read_sequence">
        <Route index element={<MeterReadSequenceReport />} />
        <Route path=":id" element={<MeterReadSequenceReport />} />
      </Route>
      <Route path="general" element={<GeneralReports />} />
      <Route path="water_accounts" element={<WaterAccountsReport />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default ReportRoutes;

import { useMutation } from "@tanstack/react-query";

import { createMeterOnlyDeclaration } from "@services/declarations";

export const useCreateMeterOnlyDeclaration = (
  options: Record<string, any> = {}
) => {
  return useMutation({
    mutationFn: createMeterOnlyDeclaration,
    ...options,
  });
};

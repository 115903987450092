import React from "react";
import { startCase } from "lodash";
import { t as translate } from "i18next";
import { useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import { Link } from "react-router-dom";
import ExternalLinkIcon from "@components/icons/ExternalLinkIcon";
import { formatVolume } from "@utils/formatVolume";
import { useAllExtractionRights } from "@hooks/query/useAllExtractionRights";

type Props = {
  subscriber: any;
  onSelect: (values: Record<string, any>[]) => void;
  selectedKeys?: string[];
  noRowText?: string;
};

const SelectMultipleExtractionRightTable: React.FunctionComponent<Props> = ({
  subscriber,
  selectedKeys = [],
  onSelect,
  noRowText = translate("common.no_data"),
}) => {
  const { t } = useTranslation();
  const { data: extractionRights = [], isLoading } = useAllExtractionRights({
    params: {
      subscriberIds: [subscriber?.id ?? ""],
    },
    options: {
      enabled: Boolean(subscriber?.id),
      queryKey: [
        "allExtractionRights",
        { subscriberIds: [subscriber?.walletId] },
      ],
    },
  });

  const handleSelect = (ids: string[]) => {
    const selectedExtractionRights = extractionRights?.filter((i: any) =>
      ids.some((id) => i.id === id)
    );
    onSelect(selectedExtractionRights);
  };

  const tableFields = [
    {
      title: t("extraction_right.name"),
      name: "name",
    },
    {
      title: t("common.level0wrs"),
      name: "zone",
    },
    {
      title: t("common.water_class"),
      name: "priority",
    },
    {
      title: t("extraction_right.volume"),
      name: "nominalVolume",
    },
    {
      title: "",
      name: "view",
    },
  ];

  const tableData = extractionRights.map((right: Record<string, any>) => {
    return {
      id: right?.id,
      name: right?.name,
      zone: right?.level0Resource?.identifier,
      allocationType: "WA",
      source: startCase(right?.level0Resource?.source),
      priority: right?.waterClass?.name,
      purpose: right?.purpose,
      nominalVolume: formatVolume(right?.volume),
      view: (
        <Link
          to={`/polestar/subscribers/${subscriber.id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="btn-default flex items-center gap-3 w-min"
        >
          <ExternalLinkIcon className="w-4 h-4" />
          {t("common.view")}
        </Link>
      ),
    };
  });

  return (
    <>
      <form className="pb-0 flex flex-col gap-4 grow">
        <Table
          fields={tableFields}
          data={tableData}
          selectionKey="id"
          selectedKeys={[...selectedKeys]}
          onSelectionChange={handleSelect}
          stickyHeader
          noRowsText={noRowText}
          loading={isLoading}
        />
      </form>
    </>
  );
};

export default SelectMultipleExtractionRightTable;

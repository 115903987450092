import { InputHTMLAttributes, ReactNode } from "react";
import classNames from "classnames";
import ErrorMessage from "./ErrorMessage";

interface IRadioInputProps extends InputHTMLAttributes<HTMLInputElement> {
  errorMessage?: string;
  suffix?: React.ReactNode;
  inline?: boolean;
  inputClassName?: string;
  htmlFor?: string;
  label: ReactNode;
  labelDescription?: string;
}

const RadioInput: React.FunctionComponent<IRadioInputProps> = ({
  className,
  errorMessage,
  suffix,
  inline = false,
  inputClassName,
  htmlFor,
  label,
  labelDescription,
  ...inputProps
}) => {
  return (
    <div
      className={classNames(
        "sm:text-sm",
        {
          "w-full": !inline,
        },
        className
      )}
    >
      <div className="relative flex items-start">
        <div className="flex h-6 items-center">
          <input
            className={classNames(
              "h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600",
              {
                "border-red-300": !!errorMessage,
              }
            )}
            {...inputProps}
            type="radio"
          />
          <label
            htmlFor={htmlFor || inputProps.id}
            className="ml-3 block text-sm font-medium leading-6 text-gray-900"
          >
            {label}
          </label>
        </div>
      </div>

      {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
    </div>
  );
};

export default RadioInput;

export type CanAmalgamate =
  | ""
  | "yes"
  | "require_multiple_rights"
  | "require_different_subscribers"
  | "require_different_level0wrs"
  | "require_different_class";

export const getExtractionRightsCanAmalgamate = (
  selected: any
): CanAmalgamate => {
  if (selected.length === 0) {
    return "";
  } else if (selected.length < 2) {
    return "require_multiple_rights";
  } else {
    const firstItem = selected[0];
    const notSameSubscriber = selected.some(
      (item: any) => item.subscriber?.id !== firstItem.subscriber?.id
    );
    const notSameLevel0wrs = selected.some(
      (item: any) => item.level0ResourceId !== firstItem.level0ResourceId
    );
    const notSameClass = selected.some(
      (item: any) => item.waterClassId !== firstItem.waterClassId
    );

    if (notSameSubscriber) {
      return "require_different_subscribers";
    } else if (notSameLevel0wrs) {
      return "require_different_level0wrs";
    } else if (notSameClass) {
      return "require_different_class";
    } else {
      return "yes";
    }
  }
};

import SpinnerIcon from "@components/icons/SpinnerIcon";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

type Props = {
  size?: number;
  className?: string;
  loadingText?: string;
};

export default function Loading(props: Props) {
  const { t } = useTranslation();
  const {
    size = 40,
    className = "",
    loadingText = t("common.loading"),
  } = props;
  return (
    <div className="flex flex-col items-center">
      <SpinnerIcon
        width={size}
        height={size}
        className={classNames("text-primary-1", className)}
      />

      <span className="text-xs text-gray-400 mt-2">{loadingText}</span>
    </div>
  );
}

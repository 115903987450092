import React from "react";
import { uniq } from "lodash";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import Table from "@components/layout/Table";
import SpecialAnnouncementModal from "@components/modal/SpecialAnnouncementModal";
import { useNotificationContext } from "@context/NotificationContext";
import { useAllSpecialAnnouncements } from "@hooks/query/useAllSpecialAnnouncements";
import { formatDateTime } from "@utils/formatDateTime";

const CreateNotificationStep2SelectLevelSpecialAnnouncements: React.FunctionComponent =
  () => {
    const { t } = useTranslation();
    const { details, stepHelpers, navigateForCancel, handleChangeDetails } =
      useNotificationContext();
    const { data: specialAnnouncements, isLoading } =
      useAllSpecialAnnouncements({
        onSuccess: (specialAnnouncements: any) => {
          onChange(details.specialAnnouncementIds, specialAnnouncements);
        },
      });
    const [selectedAnnouncement, setSelectedAnnouncement] =
      React.useState<any>();

    const handleSave = (e: any) => {
      e.preventDefault();
      stepHelpers.goToNextStep();
    };

    const onChange = (data: Array<string>, loadSpecialAnnouncements?: any) => {
      const allSpecialAnnouncements = loadSpecialAnnouncements
        ? loadSpecialAnnouncements
        : specialAnnouncements;
      const selectedSpecialAnnouncements = allSpecialAnnouncements.filter(
        (sa: any) => data.includes(sa.id)
      );

      const allSubscriberIds = selectedSpecialAnnouncements.flatMap((sa: any) =>
        sa.subscribers.map((sub: any) => sub.id)
      );

      handleChangeDetails("subscriberIds", uniq(allSubscriberIds));
      handleChangeDetails("specialAnnouncementIds", data);
    };

    const tableFields = [
      {
        title: t("notification.started_at"),
        name: "startAt",
      },
      {
        title: t("common.subscribers"),
        name: "subscribers",
      },
      {
        title: t("common.level0wrs_s"),
        name: "level0Resources",
      },
      {
        title: t("common.action"),
        name: "action",
      },
    ];

    const tableData = specialAnnouncements
      ?.filter((item: any) => !item.hasEnded && !item.endAt)
      ?.map((item: any) => {
        const subscriberNames = item?.subscribers.map(
          (subscriber: any) => subscriber.name
        );
        const level0ResourceIdentifiers = item?.level0Resources.map(
          (level0Resource: any) => level0Resource.identifier
        );

        return {
          id: item.id,
          startAt: formatDateTime(new Date(item.startAt)),
          subscribers:
            subscriberNames.length > 2
              ? `${subscriberNames.slice(0, 2).join(", ")} ${t(
                  "common.and_more",
                  { count: subscriberNames.length - 2 }
                )}`
              : subscriberNames.join(", "),
          level0Resources:
            level0ResourceIdentifiers.length > 2
              ? `${level0ResourceIdentifiers.slice(0, 2).join(", ")} ${t(
                  "common.and_more",
                  { count: level0ResourceIdentifiers.length - 2 }
                )}`
              : level0ResourceIdentifiers.join(", "),
          action: (
            <div className="flex gap-1 items-center w-full">
              <button
                type="button"
                onClick={() => {
                  setSelectedAnnouncement(item);
                }}
                className="btn-default text-sm"
              >
                {t("common.view")}
              </button>
            </div>
          ),
        };
      });

    return (
      <>
        <div className="p-6 pb-0 flex flex-col grow">
          <Heading className="text-xl leading-6 mb-6" light={true}>
            {t("notification.select_special_announcements_to_end")}
          </Heading>
          <Table
            containerClassName="rounded-none md:rounded-none"
            fields={tableFields}
            data={tableData}
            selectionKey="id"
            selectedKeys={details.specialAnnouncementIds}
            onSelectionChange={(data) => {
              onChange(data);
            }}
            pageSize={50}
            stickyHeader
            loading={isLoading}
          />
        </div>

        <footer className="flex gap-4 p-6 border-t border-gray-200">
          <button
            type="button"
            className="btn-outline-primary"
            onClick={stepHelpers.goToPrevStep}
          >
            {t("common.prev_step")}
          </button>

          <button
            className="btn-primary"
            disabled={!details.specialAnnouncementIds.length}
            onClick={handleSave}
          >
            {t("common.next_step")}
          </button>
          <button
            type="button"
            className="btn-outline-primary text-sm font-semibold"
            onClick={navigateForCancel}
          >
            {t("common.cancel")}
          </button>
        </footer>
        <SpecialAnnouncementModal
          data={selectedAnnouncement}
          onClose={() => setSelectedAnnouncement(undefined)}
          showSpecialAction={false}
        />
      </>
    );
  };

export default CreateNotificationStep2SelectLevelSpecialAnnouncements;

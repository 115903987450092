import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAppContext } from "../../context/AppContext";
import LoginForm from "../form/auth/LoginForm";
import SendCodeForm from "../form/auth/SendCodeForm";
import VerifyCodeForm from "../form/auth/VerifyCodeForm";

import encryptedStorage from "../../services/encryptedStorage";

const Login = () => {
  const navigate = useNavigate();
  const appContext = useAppContext();
  const [userData, setUserData] = useState({
    email: "",
    password: "",
    tel: "",
    mfaMethod: "email",
  });

  const [step, setStep] = useState(1);

  const handleLogin = (userData: any) => {
    if (userData) {
      setUserData(userData);
      if (userData.tokenData) {
        handleVerify(userData, userData.tokenData.token);
        return;
      }
      setStep(2);
    }
  };

  const handleSendCode = (userData: any) => {
    if (userData) {
      setUserData(userData);
      setStep(3);
    }
  };

  const handleVerify = (userData: any, token: string) => {
    appContext.setLoggedInInfo({
      isLoggedIn: true,
      token: token,
      userDetails: userData,
    });

    encryptedStorage.setItem("token", token);
    encryptedStorage.setItem("isLoggedIn", "true");
    encryptedStorage.setItem("userDetails", JSON.stringify(userData));

    const rootPath = userData.isAdmin ? "/polestar" : "/zenith";
    navigate(`${rootPath}?welcome=1`);
  };

  return (
    <>
      {step === 1 && <LoginForm onLogin={handleLogin} />}
      {step === 2 && (
        <SendCodeForm
          email={userData?.email}
          password={userData?.password}
          tel={userData?.tel}
          onCodeSent={handleSendCode}
        />
      )}
      {step === 3 && (
        <VerifyCodeForm
          email={userData?.email}
          password={userData?.password}
          tel={userData?.tel}
          mfaMethod={userData?.mfaMethod}
          onVerified={handleVerify}
          onResendCode={() => setStep(2)}
        />
      )}
    </>
  );
};

export default Login;

import React from "react";
import { useTranslation } from "react-i18next";

import { ConfirmData } from "@components/shared/ConfirmationDetail";
import { formatDateTime } from "@utils/formatDateTime";

type ContextValue = {
  declaration: any;
  setDeclaration: React.Dispatch<any>;
  workflowInstance: any;
  setWorkflowInstance: React.Dispatch<any>;
  isComplete: boolean;
  setIsComplete: React.Dispatch<boolean>;
  getDeclarationInfo: (options?: { keysToIgnore?: string[] }) => ConfirmData;
};

type Props = {
  children: React.ReactNode;
};

const MeterDeclarationContext = React.createContext<ContextValue | undefined>(
  undefined
);

const MeterDeclarationProvider = ({ children }: Props) => {
  const { t } = useTranslation();
  const [declaration, setDeclaration] = React.useState<any>();
  const [workflowInstance, setWorkflowInstance] = React.useState<any>();
  const [isComplete, setIsComplete] = React.useState(false);

  const getDeclarationInfo: ContextValue["getDeclarationInfo"] = (
    options = {}
  ) => {
    const { keysToIgnore = [] } = options;
    const body = [
      {
        key: t("declaration.form.meter_id"),
        value: declaration?.serialNo,
      },
      {
        key: t("declaration.read_at"),
        value: formatDateTime(new Date(declaration?.readAt)),
      },
      {
        key: t("declaration.type_of_meter_reading"),
        value:
          declaration?.type === "estimated"
            ? t("declaration.estimated")
            : t("declaration.actual"),
      },
      {
        key: t("declaration.reading"),
        value: declaration?.reading,
      },
    ];

    const filteredBody = body.filter(
      (item) => !keysToIgnore.includes(item.key)
    );

    return {
      title: t("declaration.meter_read_details"),
      body: filteredBody,
    };
  };

  const contextValues = {
    declaration,
    setDeclaration,
    workflowInstance,
    setWorkflowInstance,
    isComplete,
    setIsComplete,
    getDeclarationInfo,
  };

  return (
    <MeterDeclarationContext.Provider value={contextValues}>
      {children}
    </MeterDeclarationContext.Provider>
  );
};

function useMeterDeclarationContext() {
  const context = React.useContext(MeterDeclarationContext);
  if (context === undefined) {
    throw new Error(
      "useMeterDeclarationContext must be used within a MeterDeclarationProvider"
    );
  }
  return context;
}

export { MeterDeclarationProvider, useMeterDeclarationContext };

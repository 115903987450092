import React from "react";
import { useStep, UseStepHelpers } from "@hooks/useStep";

type ContextValue = {
  currentStep: number;
  stepHelpers: UseStepHelpers;
};

type Props = {
  children: React.ReactNode;
  maxStep: number;
};

const Context = React.createContext<ContextValue | undefined>(undefined);

function StepProvider({ children, maxStep }: Props) {
  const [currentStep, stepHelpers] = useStep(maxStep);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const contextValue = {
    currentStep,
    stepHelpers,
  };

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

function useStepContext() {
  const context = React.useContext(Context);
  if (context === undefined) {
    throw new Error("useStepContext must be used within a StepProvider");
  }
  return context;
}

export { StepProvider, useStepContext };

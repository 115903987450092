import { useTranslation } from "react-i18next";

import Layout from "@components/layout/Layout";
import { EvidenceProvider } from "@context/shared/EvidenceContext";
import {
  NotificationProvider,
  NotificationType,
  SpecialAnnouncementType,
  useNotificationContext,
} from "@context/NotificationContext";
import CreateNotificationWorkflow from "@components/form/notification/CreateNotificationWorkflow";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

const CreateNotification = () => {
  return (
    <NotificationProvider>
      <EvidenceProvider>
        <Consumer />
      </EvidenceProvider>
    </NotificationProvider>
  );
};

const Consumer: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { handleChangeDetails, setFromSpecialAnnouncement } =
    useNotificationContext();
  const subscriberIds = searchParams.get("subscriberIds")?.split(",") || [];
  const notificationId = searchParams.get("notificationId") || "";
  const specialAnnouncement = searchParams.get("specialAnnouncement") || "";

  useEffect(() => {
    handleChangeDetails("subscriberIds", subscriberIds);
    handleChangeDetails("notificationId", notificationId);
    if (Boolean(specialAnnouncement)) {
      handleChangeDetails(
        "message",
        NotificationType.SpecialAnnouncement,
        "type"
      );
      setFromSpecialAnnouncement(specialAnnouncement);
      if (specialAnnouncement === "new") return;
      handleChangeDetails(
        "message",
        SpecialAnnouncementType.End,
        "specialAnnouncementType"
      );
      handleChangeDetails("specialAnnouncementIds", [specialAnnouncement]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      permissions={["CreateNotifications", "CreateMessages"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("notification.new_notification"),
        },
      ]}
      title={t("notification.new_notification")}
    >
      <CreateNotificationWorkflow />
    </Layout>
  );
};

export default CreateNotification;

import { getAllExchangeRates } from "@services/exchangeRate";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

interface Options extends UseQueryOptions {
  params?: Record<string, any>;
}

export const useAllExchangeRates = (options: Options = {}) => {
  const { params, ...rest } = options;
  return useQuery<any>({
    queryKey: ["exchange_rates", params],
    queryFn: () => getAllExchangeRates(params),
    ...rest,
  });
};

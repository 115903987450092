import { twMerge } from "tailwind-merge";

type Status = "success" | "info" | "warning" | "error" | "default";

type Props = {
  children: React.ReactNode;
  status?: Status;
  className?: string;
};

const statusStyles: Record<Status, string> = {
  success: "bg-green-50 text-green-500 border-green-500",
  info: "bg-blue-50 text-blue-500 border-blue-500",
  error: "bg-red-50 text-red-500 border-red-500",
  warning: "bg-yellow-50 text-yellow-500 border-yellow-500",
  default: "bg-gray-50 text-gray-500 border-gray-500",
};

export default function Tag({ children, status, className }: Props) {
  const style = statusStyles[status ?? "default"];

  return (
    <span
      className={twMerge(
        "inline-block px-2 py-0.5 leading-5 text-xs border rounded-sm",
        style,
        className
      )}
    >
      {children}
    </span>
  );
}

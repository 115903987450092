import React from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";

import TextInput from "@components/form/TextInput";
import classNames from "classnames";

type DebouncedInputProps = {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">;

const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  className,
  ...props
}: DebouncedInputProps) => {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce, value]);

  const isSearchInput = props.type === "search";

  return (
    <div className="relative mt-2 rounded-md shadow-xs">
      <TextInput
        {...props}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        inputClassName={classNames({
          "pl-10": isSearchInput,
        })}
      />

      {isSearchInput ? (
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
      ) : null}
    </div>
  );
};

export default DebouncedInput;

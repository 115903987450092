import api from "./api";

export const verifyFile = async ({
  file,
  isEncrypted = false,
}: {
  file: File;
  isEncrypted?: boolean;
}) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("isEncrypted", String(isEncrypted));

  const { data } = await api.post("/fileVerification", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return data;
};

import { useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import { useAllExtractionRights } from "@hooks/query/useAllExtractionRights";
import { formatVolume } from "@utils/formatVolume";
import { Link } from "react-router-dom";
import ExternalLinkIcon from "@components/icons/ExternalLinkIcon";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import { useState } from "react";

type ExtractionRight = Record<string, any>;

type SelectSingleExtractionRightTableProps = {
  level1ResourceId?: string;
  subscriberId?: string;
  onSelect: (item: ExtractionRight) => void;
  selected?: ExtractionRight;
  onLoad?: (rights: ExtractionRight[]) => ExtractionRight[];
};

const SelectSingleExtractionRightTable: React.FunctionComponent<
  SelectSingleExtractionRightTableProps
> = ({ level1ResourceId, subscriberId, onSelect, selected, onLoad }) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState("");
  const { data: extractionRights, isLoading } = useAllExtractionRights({
    params: {
      level1ResourceId,
      subscriberIds: subscriberId ? [subscriberId] : undefined,
      isActive: true,
    },
    options: {
      select: (data: any[]) => {
        if (onLoad) {
          return onLoad(data);
        }

        return data;
      },
    },
  });

  const tableFields = [
    {
      title: "",
      name: "radio",
    },
    {
      title: t("extraction_right.number"),
      name: "name",
    },
    {
      title: t("common.level0wrs"),
      name: "level0wrs",
    },
    {
      title: t("water_class.water_class_header"),
      name: "waterClass",
    },
    {
      title: t("extraction_right.volume"),
      name: "nominalVolume",
    },
    {
      title: "",
      name: "view",
    },
  ];

  const tableData = extractionRights
    ?.filter((extractionRight: any) => {
      const matcher = filter.toLowerCase();

      return extractionRight?.name?.toLowerCase()?.includes(matcher);
    })
    ?.map((extractionRight: any) => {
      return {
        id: extractionRight.id,
        radio: (
          <input
            type="radio"
            name="level1wrs_id"
            checked={selected?.id === extractionRight?.id}
            onChange={() => onSelect(extractionRight)}
          />
        ),
        name: extractionRight?.name,
        nominalVolume: formatVolume(extractionRight?.volume),
        waterClass: extractionRight?.waterClass?.name,
        level0wrs: extractionRight?.level0Resource?.identifier,
        view: (
          <Link
            to={`/polestar/subscribers/${subscriberId}?level0ResourceId=${extractionRight?.level0Resource?.id}&extractionRightId=${extractionRight?.id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="btn-default flex items-center gap-3 w-min"
          >
            <ExternalLinkIcon className="w-4 h-4" />
            {t("common.view")}
          </Link>
        ),
      };
    });

  return (
    <>
      <div className="pb-0 flex flex-col gap-4 grow">
        <fieldset className="max-w-xs">
          <Label>{t("extraction_right.filter_extraction_right_number")}</Label>
          <SearchInput onChange={e => setFilter(e.target.value)} />
        </fieldset>
        <Table
          fields={tableFields}
          data={tableData}
          stickyHeader
          loading={isLoading}
          noRowsText={t("subscriber.has_no_extraction_rights")}
        ></Table>
      </div>
    </>
  );
};

export default SelectSingleExtractionRightTable;

import ENV from "@config/env";
import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import Table from "@components/layout/Table";
import Heading from "@components/layout/Heading";
import ConfirmModal from "@components/shared/ConfirmModal";
import WorkflowComplete from "@components/shared/WorkflowComplete";
import DeclarationTag from "@components/shared/DeclarationTag";
import TrialRunTag from "./TrialRunTag";
import TrialRunDetailsModal from "./TrialRunDetailsModal";
import { useBulkMeterUploadContext } from "@context/BulkMeterUploadContext";
import { bulkMeterUpload, trialRunBulkMeters } from "@services/bulkMeterUpload";
import { useStepContext } from "@context/shared/StepContext";
import { useAppContext } from "@context/AppContext";
import { toastError, toastSuccess } from "@utils/toast";
import { useEvidenceContext } from "@context/shared/EvidenceContext";
import { convertLiterToML, convertMLToLiter } from "@utils/convertUnits";
import { useMutation } from "@tanstack/react-query";
import { formatDate } from "@utils/formatDate";
import { formatVolume } from "@utils/formatVolume";
import { downloadAsCSV } from "@utils/downloadAsCSV";
import { READING_CALCULATION } from "@services/declarations";
import { DBTables } from "@utils/constants/dbTables";

type TrialRunError = {
  group: boolean;
  seq: boolean;
  accountNumber: boolean;
  extractionPointName: boolean;
  extractionPointInactive: boolean;
  extractionPointNotFound: boolean;
  serialNo: boolean;
  duplicate: boolean;
  requireManualIntervention: boolean;
};

type TrialRunResult = {
  group: string;
  seq: number;
  accountNumber: string;
  subscriberName: string;
  extractionPointName: string;
  serialNo: string;
  lastReading: number;
  readAt?: string;
  volumeExtracted: number;
  clickOver: number;
  error: TrialRunError;
  calculation: string;
};

const IS_SEQWATER = ENV.CLIENT_ID === "seqwater";

const TrialRun: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { isExpandInfoPanel } = useAppContext();
  const [submitting, setSubmitting] = React.useState(false);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [trialRunData, setTrialRunData] = React.useState<TrialRunResult[]>([]);
  const [viewDetailIndex, setViewDetailIndex] = React.useState(-1);
  const [workflowInstance, setWorkflowInstance] = React.useState<any>();
  const { stepHelpers } = useStepContext();
  const { evidences: files, uploadEvidences } = useEvidenceContext();
  const { level1WRS, meterReadings, handleCancel, isComplete, setIsComplete } =
    useBulkMeterUploadContext();

  const { mutate, isLoading } = useMutation({
    mutationFn: () =>
      trialRunBulkMeters({
        level1ResourceId: level1WRS?.id,
        bulkMeterReadings: meterReadings.map((i) => {
          return {
            ...i,
            reading: i.reading ? convertMLToLiter(+i.reading) : 0,
          };
        }),
      }),
    onSuccess(res: any[]) {
      const result = res.map((i, index) => {
        const meterRead = meterReadings[index];
        let initialError: TrialRunError = {
          group: false,
          seq: false,
          accountNumber: false,
          extractionPointName: false,
          extractionPointInactive: false,
          extractionPointNotFound: false,
          serialNo: false,
          duplicate: false,
          requireManualIntervention: false,
        };

        const data: TrialRunResult = {
          group: i.group,
          seq: i.seq,
          accountNumber: i.accountNumber,
          subscriberName: i.subscriberName,
          extractionPointName: i.extractionPointName,
          serialNo: i.serialNo,
          lastReading: i.lastReading,
          readAt: i.readAt,
          volumeExtracted: i.volumeExtracted,
          clickOver: i.clickOver,
          calculation: "",
          error: initialError,
        };

        if (!i.extractionPointName) {
          return {
            ...data,
            error: {
              ...data.error,
              extractionPointNotFound: true,
            },
          };
        }

        const isExtractionPointNameMatch =
          meterRead.extractionPointName === i.extractionPointName;
        const isSerialNumberMatch = meterRead.serialNo === i.serialNo;
        const isAccountNumberMatch =
          meterRead.accountNumber === i.accountNumber;

        const error: TrialRunError = {
          ...data.error,
          group: meterRead.group !== i.group,
          seq: meterRead.seq !== i.seq,
          accountNumber: !isAccountNumberMatch,
          extractionPointName: !isExtractionPointNameMatch,
          extractionPointInactive: !i.isActive,
          duplicate: i.isDuplicate,
          serialNo: !isSerialNumberMatch,
          requireManualIntervention:
            isAccountNumberMatch &&
            isSerialNumberMatch &&
            isExtractionPointNameMatch &&
            i.isActive &&
            i.requireManualIntervention,
        };

        return {
          ...data,
          error,
          volumeExtracted:
            isExtractionPointNameMatch && isSerialNumberMatch
              ? i.volumeExtracted
              : 0,
        };
      });

      setTrialRunData(result);
    },
  });

  React.useEffect(() => {
    mutate();
  }, [mutate]);

  const handleManualIntervention = (params: {
    calculation: string;
    volumeExtracted: number;
    index: number;
  }) => {
    const { calculation, volumeExtracted, index } = params;
    setTrialRunData((prev) => {
      return prev.map((i, currIdx) => {
        if (currIdx !== index) return i;

        return {
          ...i,
          calculation,
          volumeExtracted,
          error: {
            ...i.error,
            requireManualIntervention: false,
          },
        };
      });
    });
  };

  const handleConfirm = async () => {
    setSubmitting(true);
    try {
      const payload = trialRunData
        .map((i, index) => {
          const meterRead = meterReadings[index];
          return {
            extractionPointName: meterRead.extractionPointName,
            serialNo: meterRead.serialNo,
            reading: convertMLToLiter(meterRead.reading),
            readAt: meterRead.readAt,
            calculation: i.calculation,
            status: getStatus(i.error),
          };
        })
        .filter((i) => i.status !== "error");

      const [data, workflowInstance] = await bulkMeterUpload({
        fileName: files?.[0].name,
        level1ResourceId: level1WRS?.id,
        bulkMeters: payload,
      });

      await uploadEvidences({
        description: t("bulk_meter_upload.upload_description", {
          level1WRSName: level1WRS?.name,
        }),
        references: [
          {
            referenceId: data?.id,
            referenceTable: DBTables.MeterUploads,
          },
          {
            referenceId: level1WRS?.id,
            referenceTable: DBTables.WRSHierarchies,
          },
          {
            referenceId: workflowInstance?.id,
            referenceTable: DBTables.WorkflowInstances,
          },
        ],
      });

      setWorkflowInstance(workflowInstance);
      setIsComplete(true);
      toastSuccess(t("bulk_meter_upload.message.success"));
    } catch (error: any) {
      toastError(
        t("bulk_meter_upload.message.error", {
          error: error?.response?.data?.message || error?.message,
        })
      );
    }
    setShowConfirmModal(false);
    setSubmitting(false);
  };

  const tableFields = [
    ...(IS_SEQWATER
      ? [
          {
            title: t("bulk_meter_upload.table.group"),
            name: "group",
          },
          {
            title: t("bulk_meter_upload.table.seq"),
            name: "seq",
          },
        ]
      : []),
    {
      title: t("subscriber.account_number"),
      name: "accountNumber",
    },
    {
      title: t("subscriber.name"),
      name: "subscriberName",
    },
    {
      title: t("bulk_meter_upload.table.extraction_point_name"),
      name: "extractionPointName",
    },
    {
      title: t("bulk_meter_upload.table.meter_serial_no"),
      name: "serialNo",
    },
    {
      title: t("bulk_meter_upload.table.meter_read"),
      name: "reading",
    },
    {
      title: t("bulk_meter_upload.table.date"),
      name: "readAt",
    },
    {
      title: t("bulk_meter_upload.table.volume_extracted"),
      name: "volumeExtracted",
    },
    {
      title: t("bulk_meter_upload.table.status"),
      name: "tag",
    },
    {
      title: "",
      name: "view",
    },
  ];

  const tableData = trialRunData.map((i: any, index) => {
    const errorClassName = "bg-red-50";
    const warningClassName = "bg-yellow-50";
    const { error } = i;
    const uploadData = meterReadings[index];
    const volumeExtracted = formatVolume(i.volumeExtracted ?? 0);

    return {
      group: uploadData.group,
      seq: uploadData.seq,
      accountNumber: uploadData.accountNumber,
      subscriberName: i.subscriberName,
      extractionPointName: uploadData.extractionPointName,
      serialNo: uploadData.serialNo,
      reading: uploadData.reading,
      readAt: formatDate(uploadData.readAt),
      rawVolumeExtracted: volumeExtracted,
      volumeExtracted: (
        <div className="flex gap-2 items-center">
          <span>{volumeExtracted}</span>
          {i.calculation ? <DeclarationTag value={i.calculation} /> : null}
        </div>
      ),
      tag: <TrialRunTag status={getStatus(i.error)} />,
      lastReading: i.lastReading,
      clickOver: i.clickOver,
      calculation: i.calculation || "",
      view: (
        <button
          className="btn-outline-primary text-sm rounded px-3 py-1"
          onClick={() => {
            setViewDetailIndex(index);
          }}
        >
          {t("common.view")}
        </button>
      ),
      cellClassName: {
        volumeExtracted: error.requireManualIntervention
          ? errorClassName
          : "bg-gray-50",
        group: error.group ? warningClassName : "",
        seq: error.seq ? warningClassName : "",
        accountNumber: error.accountNumber ? errorClassName : "",
        extractionPointName:
          error.extractionPointName ||
          error.extractionPointInactive ||
          error.extractionPointNotFound
            ? errorClassName
            : "",
        serialNo: error.serialNo ? errorClassName : "",
        reading:
          error.duplicate || error.requireManualIntervention
            ? errorClassName
            : "",
        readAt: error.duplicate ? errorClassName : "",
      },
    };
  });

  if (isComplete) {
    return <WorkflowComplete references={[workflowInstance?.id]} />;
  }

  const handleCloseModal = () => {
    setViewDetailIndex(-1);
  };

  const details =
    viewDetailIndex > -1
      ? {
          upload: meterReadings[viewDetailIndex],
          ledger: trialRunData[viewDetailIndex],
        }
      : undefined;

  return (
    <>
      <div className="flex flex-col grow gap-6 p-6">
        <div className="flex justify-between items-center">
          <Heading light>
            {t("bulk_meter_upload.title.trial_run")} {trialRunData.length}{" "}
            {t("common.records")}
          </Heading>
          {trialRunData.length > 0 ? (
            <button
              className={classNames("btn-primary text-sm rounded", {
                "mr-6": !isExpandInfoPanel,
              })}
              onClick={() => {
                exportData(meterReadings, trialRunData, t);
              }}
            >
              {t("bulk_meter_upload.download_trial_run")}
            </button>
          ) : null}
        </div>

        <Table
          fields={tableFields}
          data={tableData}
          loading={isLoading}
          stickyHeader
        />
      </div>

      <footer className="flex gap-4 border-t border-gray-300 p-6">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>
        <button
          className="btn-primary"
          onClick={() => setShowConfirmModal(true)}
        >
          {t("common.ledger")}
        </button>
        <button className="btn-outline-primary" onClick={handleCancel}>
          {t("common.cancel")}
        </button>
      </footer>

      <ConfirmModal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleConfirm}
        isSubmitting={submitting}
      >
        {t("bulk_meter_upload.confirm_message")}
      </ConfirmModal>

      {details ? (
        <TrialRunDetailsModal
          onClose={handleCloseModal}
          onClick={(calculation) => {
            const volumeExtracted =
              calculation === READING_CALCULATION.CLICK_OVER
                ? details.ledger?.clickOver -
                  details.ledger?.lastReading +
                  convertMLToLiter(details.upload?.reading)
                : 0;

            handleManualIntervention({
              calculation,
              volumeExtracted,
              index: viewDetailIndex,
            });
            handleCloseModal();
          }}
          data={details}
          status={getStatus(details?.ledger?.error)}
        />
      ) : null}
    </>
  );
};

const getStatus = (error: Record<string, boolean>) => {
  if (
    error.accountNumber ||
    error.extractionPointName ||
    error.extractionPointInactive ||
    error.extractionPointNotFound ||
    error.serialNo ||
    error.duplicate ||
    error.requireManualIntervention
  ) {
    return "error";
  }

  if (error.group || error.seq) {
    return "warning";
  }

  return "success";
};

const exportData = (upload: any[], ledger: any[], t: any) => {
  const result = upload.map((u, index) => {
    const l = ledger[index] ?? {};
    return {
      ...(IS_SEQWATER
        ? {
            [t("extraction_point.group")]: u.group,
            [t("bulk_meter_upload.ledger.group")]: l.group,
            [t("extraction_point.sequence")]: u.seq,
            [t("bulk_meter_upload.ledger.seq")]: l.seq,
          }
        : {}),

      [t("subscriber.account_number")]: u.accountNumber,
      [t("bulk_meter_upload.ledger.subscriber.account_number")]:
        l.accountNumber,
      [t("bulk_meter_upload.ledger.subscriber.name")]: l.subscriberName,
      [t("common.extraction_point")]: u.extractionPointName,
      [t("bulk_meter_upload.ledger.extraction_point")]: l.extractionPointName,

      [t("meter.serial_no")]: u.serialNo,
      [t("bulk_meter_upload.ledger.serial_no")]: l.serialNo,
      [t("bulk_meter_upload.table.meter_read")]: u.reading,
      [t("bulk_meter_upload.table.date")]: formatDate(u.readAt),

      [t("bulk_meter_upload.ledger.reading")]: convertLiterToML(l.lastReading),
      [t("bulk_meter_upload.table.volume_extracted")]: formatVolume(
        l.volumeExtracted
      ),
      [t("bulk_meter_upload.ledger.date")]: formatDate(new Date(l.readAt)),

      [t("bulk_meter_upload.manual_intervention")]: l.calculation,
      [t("bulk_meter_upload.table.status")]: getStatus(l.error),
      [t("common.error")]: getError(l.error, t),
    };
  });

  downloadAsCSV({
    data: result,
    fileName: "bulk_meter_upload_trial_run_" + Date.now(),
  });
};

const getError = (error: TrialRunError, t: any) => {
  const result: string[] = [];

  if (IS_SEQWATER && error.group) {
    result.push(t("bulk_meter_upload.validation.mismatch.group"));
  }
  if (IS_SEQWATER && error.seq) {
    result.push(t("bulk_meter_upload.validation.mismatch.seq"));
  }
  if (error.accountNumber) {
    result.push(t("bulk_meter_upload.validation.mismatch.subscriber"));
  }
  if (error.extractionPointName) {
    result.push(t("bulk_meter_upload.validation.mismatch.extraction_point"));
  }
  if (error.extractionPointInactive) {
    result.push(t("bulk_meter_upload.validation.extraction_point.inactive"));
  }
  if (error.extractionPointNotFound) {
    result.push(t("bulk_meter_upload.validation.extraction_point.not_found"));
  }
  if (error.serialNo) {
    result.push(t("bulk_meter_upload.validation.mismatch.meter"));
  }
  if (error.duplicate) {
    result.push(t("bulk_meter_upload.validation.duplicate"));
  }
  if (error.requireManualIntervention) {
    result.push(t("bulk_meter_upload.validation.manual_intervention"));
  }

  return result.join(", ");
};

export default TrialRun;

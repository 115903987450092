import { Fragment, FunctionComponent } from "react";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import { t } from "i18next";

import { useAppContext } from "@context/AppContext";
import { useSetUserLanguage } from "@hooks/mutation/useSetUserLanguage";
import languages from "@config/languages";
import ENV from "@config/env";

const supportLanguages: any[] = languages
  .filter((l) => ENV.SUPPORTED_LANGUAGES.split(",").includes(l.languageCode))
  .map((l) => ({
    flag: l.flagIcon,
    label: t(`languages.${l.languageCode}`),
    value: l.languageCode,
  }));

const LanguageSwitcher: FunctionComponent = () => {
  const { loggedInInfo } = useAppContext();
  const { mutateAsync: setLanguage } = useSetUserLanguage();

  const selectedLanguage =
    supportLanguages.find(
      (item) => item.value === loggedInInfo?.userDetails?.language
    ) ?? supportLanguages[0];

  if (supportLanguages.length <= 1) {
    return null;
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button>
          <selectedLanguage.flag className="mt-1 w-6 rounded-sm" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="p-1">
            {supportLanguages.map((item) => {
              return (
                <Menu.Item key={item.label}>
                  {({ active }) => (
                    <button
                      className={classNames(
                        { "bg-gray-100": active },
                        "group flex w-full items-center rounded-md px-2 py-2 text-sm"
                      )}
                      onClick={() => setLanguage(item.value)}
                    >
                      <item.flag className="mr-2 w-6 rounded-sm" />
                      {item.label}
                    </button>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default LanguageSwitcher;

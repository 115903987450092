import { FunctionComponent } from "react";
import InfoPanel from "@components/form/InfoPanel";
import { useTranslation } from "react-i18next";

type Props = {
  workflowTitle: string;
};

const AdministrativeApprovalEffectiveDateInformation: FunctionComponent<
  Props
> = ({ workflowTitle }) => {
  const { t } = useTranslation();

  return (
    <InfoPanel>
      <ul className="pl-2 space-y-2">
        <li>
          {t("date.effective.info_panel_by_trade.intro", { workflowTitle })}
        </li>
        <li>{t("date.effective.info_panel_by_trade.default")}</li>
        <li>
          {t("date.effective.info_panel_by_trade.past_present_future")}
          <ul className="pl-4 space-y-2 list-inside list-disc">
            <li>{t("date.effective.info_panel_by_trade.past_present")}</li>
            <li>{t("date.effective.info_panel_by_trade.future")}</li>
          </ul>
        </li>
        <li>{t("date.effective.info_panel_by_trade.note")}</li>
      </ul>
    </InfoPanel>
  );
};

export default AdministrativeApprovalEffectiveDateInformation;

import classNames from "classnames";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

import AppNavigation from "@components/layout/zenith/AppNavigation";
import { useAppContext } from "@context/AppContext";

const AppSidebar = () => {
  const { t } = useTranslation();
  const { loggedInInfo, isExpandSidebar, setIsExpandSidebar } = useAppContext();

  return (
    <aside
      className={classNames(
        "app-sidebar hidden lg:fixed lg:top-16 lg:flex lg:grow lg:flex-col gap-y-5 overflow-y-auto pt-4 px-4 bg-white z-30",
        isExpandSidebar ? "lg:w-64" : "lg:w-14"
      )}
    >
      <div
        className={classNames("flex", {
          "gap-2": isExpandSidebar,
          "flex-col-reverse items-center gap-4": !isExpandSidebar,
        })}
      >
        <div
          className={classNames(
            "relative flex items-center grow space-x-4 rounded-lg text-gray-500",
            {
              "px-4 bg-gray-50 shadow-sm py-3": isExpandSidebar,
            }
          )}
        >
          <div className="flex items-center justify-center flex-shrink-0 h-10 w-10 rounded-full bg-gray-200 text-primary-2 text-lg">
            {loggedInInfo?.userDetails?.name[0]}
          </div>
          <div
            className={classNames({
              "sr-only": !isExpandSidebar,
            })}
          >
            {loggedInInfo?.userDetails?.name}
            <span className="block text-sm">
              {loggedInInfo?.userDetails?.isPrimary
                ? t("user.primary_user")
                : t("user.general_user")}
            </span>
          </div>
        </div>
        <button
          type="button"
          className="shrink-0 text-gray-400 hover:text-gray-600 transition-colors duration-150"
          onClick={() => {
            setIsExpandSidebar((isExpand: boolean) => !isExpand);
          }}
        >
          {isExpandSidebar ? (
            <ChevronDoubleLeftIcon className="w-5 h-5" />
          ) : (
            <ChevronDoubleRightIcon className="w-5 h-5" />
          )}
        </button>
      </div>

      <AppNavigation />
    </aside>
  );
};

export default AppSidebar;

import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import ApprovalTag from "@components/shared/ApprovalTag";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import SelectLevel1Resource from "@components/form/SelectLevel1Resource";
import SelectAdminApprovalStatus from "@components/form/SelectAdminApprovalStatus";
import StockAndDomesticModal from "@components/modal/StockAndDomesticModal";
import SelectAccountingPeriod from "@components/form/SelectAccountingPeriod";
import { useAllAdministrativeApprovals } from "@hooks/query/useAllAdministrativeApprovals";
import { useAppContext } from "@context/AppContext";
import { getActionTimestamp } from "@utils/administrativeApproval";
import { AdministrativeApprovalType } from "@services/administrativeApprovals";
import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";
import { formatDate } from "@utils/formatDate";

type StockAndDomesticTableProps = {
  level1Resource?: any;
};

const StockAndDomesticTable: React.FunctionComponent<
  StockAndDomesticTableProps
> = ({ level1Resource }) => {
  const { t } = useTranslation();
  const { checkPermissions } = useAppContext();
  const [selectedApproval, setSelectedApproval] = React.useState<any>();
  const [filter, setFilter] = React.useState<{
    accountingPeriod?: {
      id: string;
      periodStart: Date;
      periodEnd: Date;
    };
    customerName?: string;
    status?: string;
    level1wrsId?: string;
  }>({ accountingPeriod: level1Resource?.activeAccountingPeriod });

  const { data: approvals = [], isLoading } = useAllAdministrativeApprovals({
    params: {
      type: AdministrativeApprovalType.SD,
    },
  });

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  return (
    <>
      <header className="flex justify-between items-end gap-3">
        <form className="grid grid-cols-1 gap-2 md:grid-cols-4 md:gap-3 2xl:w-2/1 relative z-20 justify-between items-end">
          {!level1Resource && (
            <div>
              <Label htmlFor="level1Resource">{t("common.level1wrs")}</Label>
              <SelectLevel1Resource
                inputId="level1Resource"
                onChange={e => {
                  handleFilterChange("level1wrsId", e?.value);
                }}
                isClearable
              />
            </div>
          )}

          {level1Resource || filter.level1wrsId ? (
            <div>
              <Label htmlFor="accountingPeriod">
                {t("common.accounting_period")}
              </Label>
              <SelectAccountingPeriod
                inputId="accountingPeriod"
                level1ResourceId={filter.level1wrsId}
                value={filter.accountingPeriod}
                onChange={(e: any) => {
                  handleFilterChange("accountingPeriod", {
                    id: e?.value?.id,
                    periodStart: e?.value?.perideStart
                      ? new Date(e?.value?.periodStart)
                      : "",
                    periodEnd: e?.value?.periodEnd
                      ? new Date(e?.value?.periodEnd)
                      : "",
                  });
                }}
              />
            </div>
          ) : null}

          <div>
            <Label htmlFor="customerName">
              {t("subscriber.filter_subscriber_name")}
            </Label>
            <SearchInput
              id="customerName"
              onChange={e => handleFilterChange("customerName", e.target.value)}
            />
          </div>
          <div>
            <Label>{t("common.status")}</Label>
            <SelectAdminApprovalStatus
              onValueChange={value => handleFilterChange("status", value)}
              adminApprovalsAndTransfers={approvals}
            />
          </div>
        </form>
        {checkPermissions(["ViewApprovals"]) && (
          <Link
            to="/polestar/administrative_approvals/stock_and_domestic"
            className="btn-secondary text-sm rounded whitespace-nowrap"
          >
            {t("approval.stock_and_domestic.add_new")}
          </Link>
        )}
      </header>

      <Table
        fields={[
          {
            title: t("common.level1wrs"),
            name: "level1Resource",
          },
          {
            title: t("common.accounting_period"),
            name: "accountingPeriod",
          },
          {
            title: t("approval.forward_draws.customer"),
            name: "buyerName",
          },
          {
            title: t("extraction_point.link.entitlement_id"),
            name: "extractionRightName",
          },
          {
            title: t("common.status"),
            name: "status",
          },
          {
            title: t("approval.forward_draws.timestamp"),
            name: "timestamp",
          },
          {
            title: t("common.effective_date"),
            name: "effectiveDate",
          },
          {
            title: t("common.action"),
            name: "action",
          },
        ]}
        data={approvals
          ?.filter(
            (row: { level1Resource: any }) =>
              level1Resource === undefined ||
              row.level1Resource?.id === level1Resource?.id,
          )
          ?.filter(
            (row: { level1Resource: any }) =>
              filter.level1wrsId === undefined ||
              row.level1Resource?.id === filter.level1wrsId,
          )
          ?.filter(
            (row: { accountingPeriod: any }) =>
              filter.accountingPeriod === undefined ||
              row.accountingPeriod?.id === filter.accountingPeriod?.id,
          )
          ?.filter(
            (row: { status: any }) =>
              filter.status === undefined || row.status === filter.status,
          )
          ?.filter(
            (row: { seller: any; buyer: any }) =>
              !filter?.customerName?.length ||
              row.seller?.name
                .toString()
                .toLowerCase()
                .includes(filter?.customerName.toString().toLowerCase()) ||
              row.buyer?.name
                .toString()
                .toLowerCase()
                .includes(filter?.customerName.toString().toLowerCase()),
          )
          ?.map((i: any) => {
            return {
              ...i,
              level1Resource: i.level1Resource?.identifier,
              buyerName: i.buyer.name,
              accountingPeriod: formatAccountingPeriod(i.accountingPeriod),
              extractionRightName: i.extractionRights[0]?.name,
              status: (
                <ApprovalTag
                  isAccounted={Boolean(i?.accountedAt)}
                  status={i?.status}
                />
              ),
              timestamp: getActionTimestamp(i),
              effectiveDate:
                i.effectiveDate && formatDate(new Date(i.effectiveDate)),
              action: (
                <div className="flex gap-1 items-center w-full">
                  <button
                    type="button"
                    onClick={() => setSelectedApproval(i)}
                    className="btn-default text-sm"
                  >
                    {t("common.view")}
                  </button>
                </div>
              ),
            };
          })}
        stickyHeader
        loading={isLoading}
        noRowsText={t("common.no_data")}
      />

      <StockAndDomesticModal
        data={selectedApproval}
        onClose={() => setSelectedApproval(undefined)}
      />
    </>
  );
};

export default StockAndDomesticTable;

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAllAccountingPeriods } from "@hooks/query/useAllAccountingPeriods";
import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";

export type BreadcrumObject = {
  label: string;
  href?: string;
};

type BreadcrumbProps = {
  items: BreadcrumObject[];
  accountingPeriodLevel1ResourceId?: string;
};

const Breadcrumb: React.FunctionComponent<BreadcrumbProps> = ({
  items,
  accountingPeriodLevel1ResourceId,
}) => {
  const { t } = useTranslation();
  const { data: currentAccountingPeriod } = useAllAccountingPeriods({
    options: {
      enabled: Boolean(accountingPeriodLevel1ResourceId),
      select: (data: any) => data?.[0],
    },
    params: {
      level1ResourceId: accountingPeriodLevel1ResourceId,
      isActive: true,
    },
  });

  return (
    <nav className="w-full text-sm text-gray-400 flex justify-between">
      <ul className="flex items-center gap-1">
        {items.map((item, index) => {
          return (
            <li
              key={`${item.label}--${index}`}
              className="last-of-type:text-gray-700"
            >
              {item.href ? (
                <Link
                  to={item.href}
                  className="hover:text-gray-700 transition-colors"
                >
                  {item.label}
                </Link>
              ) : (
                <span>{item.label}</span>
              )}
              {index !== items.length - 1 && (
                <svg
                  className="inline-block h-4 w-4 text-gray-400 ml-1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
              )}
            </li>
          );
        })}
      </ul>

      {accountingPeriodLevel1ResourceId ? (
        <ul>
          {t("common.current_accounting_period")}:{" "}
          {formatAccountingPeriod(currentAccountingPeriod)}
        </ul>
      ) : null}
    </nav>
  );
};

export default Breadcrumb;

import classNames from "classnames";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSearchParams } from "react-router-dom";
import { t as translate } from "i18next";
import { useTranslation } from "react-i18next";

import Legend from "@components/form/Legend";
import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import CheckboxInputWithLabel from "@components/form/CheckboxInputWithLabel";
import PhoneInput from "@components/form/PhoneInput";
import SelectSubscriber from "@components/form/SelectSubscriber";
import { type User, schema } from "@context/UserContext";

type UserFormProps = {
  onSubmit: (data: User) => void;
  onCancel: () => void;
  defaultValues: User;
  submitText?: string | null;
  level1ResourceId?: string;
  isPrimary: boolean;
};

const UserForm: React.FunctionComponent<UserFormProps> = ({
  onSubmit,
  onCancel,
  defaultValues,
  submitText = translate("common.next_step"),
  level1ResourceId,
  isPrimary,
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const subscriberId = searchParams.get("subscriberId") ?? "";
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  });

  return (
    <form
      className="flex flex-col grow space-y-8"
      onSubmit={handleSubmit(onSubmit)}
    >
      <fieldset className="space-y-4">
        <div className="max-w-md">
          <Label htmlFor="subscriberId">{t("common.subscriber")}</Label>
          <Controller
            name="subscriberId"
            control={control}
            render={({ field: { onChange, value, name }, fieldState }) => (
              <SelectSubscriber
                name={name}
                onChange={(e: any) => {
                  onChange(e?.value ?? "");
                }}
                value={value}
                errorMessage={fieldState.error?.message}
                level1ResourceId={level1ResourceId}
                isDisabled={Boolean(subscriberId)}
              />
            )}
          />
        </div>

        <div className="max-w-md">
          <Label htmlFor="name">{t("user.username")}</Label>
          <TextInput
            {...register("name")}
            id="name"
            placeholder={t("user.username_placeholder") as string}
            errorMessage={errors?.name?.message as string}
          />
        </div>

        <div
          className={classNames({
            "opacity-50 cursor-not-allowed": isPrimary,
          })}
        >
          <Controller
            name="isPrimary"
            control={control}
            render={({ field: { onChange, value, name }, fieldState }) => (
              <CheckboxInputWithLabel
                name={name}
                htmlFor={name}
                placeholder={t("user.username_placeholder") as string}
                label={t("user.set_as_primary_user")}
                checked={value}
                disabled={isPrimary}
                onChange={(e: any) => {
                  onChange(e.target.checked);
                }}
                errorMessage={fieldState.error?.message}
              />
            )}
          />
        </div>
      </fieldset>

      <fieldset className="space-y-4">
        <Legend>{t("user.contact_details")}</Legend>

        <div className="max-w-md">
          <Label htmlFor="email">{t("user.email")}</Label>
          <TextInput
            {...register("email")}
            type="email"
            id="email"
            placeholder={
              t("subscriber.create.subscriber_email_placeholder") as string
            }
            errorMessage={errors?.email?.message as string}
          />
        </div>

        <div className="max-w-md">
          <Label htmlFor="tel">{t("user.tel")}</Label>
          <Controller
            name="tel"
            control={control}
            render={({ field: { onChange, value, name }, fieldState }) => (
              <PhoneInput
                id="tel"
                name={name}
                onChange={onChange}
                value={value}
                errorMessage={fieldState.error?.message}
              />
            )}
          />
        </div>
      </fieldset>

      <div className="grow" />

      <footer className="flex gap-4 -mx-6 mt-6 p-6 pb-0 border-t border-gray-200">
        <button type="submit" className="btn-primary">
          {submitText}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default UserForm;

import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { getAllSubscriberExtractionPoints } from "@services/zenith/extractionPoint";

export const useAllSubscriberExtractionPoints = ({
  params,
  options,
}: {
  params?: Record<string, any>;
  options?: UseQueryOptions;
}) => {
  return useQuery<any>({
    queryKey: ["extractionPoints", params],
    queryFn: () => getAllSubscriberExtractionPoints({ params }),
    ...options,
  });
};

import { useState } from "react";
import Label from "@components/form/Label";
import { useTranslation } from "react-i18next";
import DropzoneBox from "./DropzoneBox";
import { useDropzone } from "react-dropzone";
import DrawGeography from "./DrawGeography";
import { toastError } from "@utils/toast";
import _ from "lodash";
import MapView from "./MapView";

enum MapInputOptions {
  CoordinatesFile,
  Map,
}

type BoundaryInputsProps = {
  locationCoordinates: { lat: string; lng: string };
  setLocationCoordinates: (location: { lat: string; lng: string }) => void;
  files: any;
  setFiles: (files: any) => void;
};

const BoundaryInputs: React.FunctionComponent<BoundaryInputsProps> = ({
  locationCoordinates,
  setLocationCoordinates,
  files,
  setFiles,
}) => {
  const { t } = useTranslation();
  const [inputOption, setInputOption] = useState<MapInputOptions>(
    MapInputOptions.Map
  );

  const accept = {
    "application/json": [".json"],
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => {
      setLocationCoordinates({ lat: "", lng: "" });
      setFiles(files);

      if (files?.length > 0) {
        setInputOption(MapInputOptions.CoordinatesFile);

        let reader = new FileReader();

        reader.onload = function (e) {
          try {
            let json = JSON.parse(e.target?.result?.toString() || "");

            if (!json.geometries || json.geometries.length === 0) {
              toastError(t("location.invalid_file"));
              return;
            } else {
              if (_.isArray(json.geometries[0].coordinates[0])) {
                setLocationCoordinates({
                  lat: json.geometries[0].coordinates[0][1],
                  lng: json.geometries[0].coordinates[0][0],
                });
              } else {
                setLocationCoordinates({
                  lat: json.geometries[0].coordinates[1],
                  lng: json.geometries[0].coordinates[0],
                });
              }
            }
          } catch (error) {
            toastError(t("location.invalid_file"));
          }
        };

        reader.readAsText(files[0]);
      } else if (
        locationCoordinates.lat !== "" &&
        locationCoordinates.lng !== ""
      ) {
        setInputOption(MapInputOptions.Map);
      } else {
        setInputOption(MapInputOptions.Map);
      }
    },
    accept: accept,
    multiple: false,
  });

  function handleDelete(index: number) {
    setFiles((prevState: any) => {
      return prevState.filter((_: any, idx: number) => idx !== index);
    });
    setLocationCoordinates({ lat: "", lng: "" });
  }

  return (
    <div>
      <Label className="mb-2" optional>
        {t("level1wrs.create.how_enter_coordinates")}
      </Label>
      <div className="flex mb-2">
        <label className="mx-2">
          <input
            type="radio"
            name="geography-input"
            value="coordinates_file"
            onClick={() => {
              setInputOption(MapInputOptions.CoordinatesFile);
              setLocationCoordinates({ lat: "", lng: "" });
              setFiles([]);
            }}
            checked={inputOption === MapInputOptions.CoordinatesFile}
            readOnly
          />{" "}
          {t("location.coordinates_form.upload_coordinates_file")}
        </label>
        <label className="mx-2">
          <input
            type="radio"
            name="geography-input"
            value="map"
            onClick={() => {
              setInputOption(MapInputOptions.Map);
              setLocationCoordinates({ lat: "", lng: "" });
              setFiles([]);
            }}
            checked={inputOption === MapInputOptions.Map}
            readOnly
          />{" "}
          {t("location.coordinates_form.identify_boundary_map")}
        </label>
      </div>
      {inputOption === MapInputOptions.CoordinatesFile && (
        <div className="max-w-2xl flex gap-4">
          {locationCoordinates.lat && locationCoordinates.lng && (
            <div className="w-1/2 h-80">
              <MapView
                zoomLevel={6}
                markers={[
                  {
                    lat: Number(locationCoordinates.lat),
                    lng: Number(locationCoordinates.lng),
                  },
                ]}
              />
            </div>
          )}
          <div className="w-full">
            <DropzoneBox
              title={t("location.coordinates_form.upload_file_title") as string}
              subtitle={
                t("location.coordinates_form.upload_file_subtitle") as string
              }
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              files={files}
              onDelete={handleDelete}
              acceptedFileTypes={accept}
            />
          </div>
        </div>
      )}
      {inputOption === MapInputOptions.Map && (
        <DrawGeography
          locationCoordinates={locationCoordinates}
          setLocationCoordinates={setLocationCoordinates}
          setFiles={setFiles}
        />
      )}
    </div>
  );
};

export default BoundaryInputs;

import React from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PermanentTransferTable from "@components/table/zenith/PermanentTransferTable";
import { useApprovalTabsContext } from "@components/layout/zenith/ApprovalTabs";

import { useAdministrativeApproval } from "@hooks/query/zenith/useAdministrativeApproval";
import PermanentTransferModal from "@components/modal/PermanentTransferModal";

const PermanentTransferIndex = () => {
  const { t } = useTranslation();
  const { setBreadcrumbs } = useApprovalTabsContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const approvalId = searchParams.get("id") ?? "";
  const { data } = useAdministrativeApproval(approvalId);

  React.useEffect(() => {
    setBreadcrumbs([
      {
        label: t("approval.permanent_trades.title"),
      },
    ]);
  }, [t, setBreadcrumbs]);

  const handleClose = () => {
    searchParams.delete("id");
    setSearchParams();
  };

  return (
    <>
      <PermanentTransferTable />
      {approvalId && data ? (
        <PermanentTransferModal
          data={data}
          onClose={handleClose}
          actionButtons={
            <button className="btn-default" onClick={handleClose}>
              {t("common.close")}
            </button>
          }
        />
      ) : null}
    </>
  );
};

export default PermanentTransferIndex;

import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import Loading from "@components/shared/Loading";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import { useAppContext } from "@context/AppContext";
import { formatDate } from "@utils/formatDate";
import { getMeterUploadsByLevel1WRS } from "@services/meter";
import ENV from "@config/env";

type Level1WRSUploadListProps = {
  level1Resource: Record<string, any>;
};

const Level1WRSUploadList: React.FunctionComponent<
  Level1WRSUploadListProps
> = ({ level1Resource }) => {
  const { checkPermissions } = useAppContext();
  const { t } = useTranslation();
  const { data: meterUploadHistory, isLoading } = useQuery(
    ["meterUploads", level1Resource.id],
    () => level1Resource && getMeterUploadsByLevel1WRS(level1Resource.id)
  );

  const [filter, setFilter] = useState({ fileName: "" });

  if (isLoading) {
    return (
      <div className="pt-20">
        <Loading />
      </div>
    );
  }

  const tableFields = [
    {
      title: t("common.file_uploaded.file"),
      name: "title",
    },
    {
      title: t("bulk_meter_upload.history.uploaded_at"),
      name: "uploadedAt",
    },
    {
      title: t("common.action"),
      name: "action",
    },
  ];

  const tableData = meterUploadHistory
    ?.filter(
      (item: any) =>
        !filter.fileName?.length ||
        item.evidences?.[0]?.fileName
          ?.toLowerCase()
          .includes(filter.fileName.toLowerCase())
    )
    .map((item: any) => ({
      title: item.evidences?.[0]?.fileName,
      uploadedAt: formatDate(new Date(item.createdAt), "dd-MM-yyyy HH:mm:ss"),
      action: (
        <a
          className="btn-secondary inline-block rounded-sm text-sm"
          href={`${ENV.API_HOST}/evidences/${item.evidences?.[0]?.id}/download`}
          download
        >
          {t("common.view")}
        </a>
      ),
    }));

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  return (
    <>
      <div className="flex justify-between items-end">
        <form className="grid grid-cols-1 gap-2 md:grid-cols-3 md:gap-3 2xl:w-2/3 relative z-20">
          <div>
            <Label htmlFor="fileName">
              {t("bulk_meter_upload.filter.file_name")}
            </Label>
            <SearchInput
              id="fileName"
              onChange={(e) => handleFilterChange("fileName", e.target.value)}
            />
          </div>
        </form>

        {checkPermissions(["BulkUploadMeterReadings"]) && (
          <Link
            className="btn-secondary text-sm rounded"
            to={`/polestar/level1wrs/${level1Resource?.id}/bulk_meter_upload`}
            state={{ level1Resource }}
          >
            {t("extraction_point.upload_bulk_meter")}
          </Link>
        )}
      </div>
      <Table
        containerClassName="rounded-none md:rounded-none text-sm"
        fields={tableFields}
        data={tableData}
        stickyHeader
      />
    </>
  );
};

export default Level1WRSUploadList;

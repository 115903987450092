import React from "react";
import { useTranslation } from "react-i18next";

import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import Heading from "@components/layout/Heading";
import Table from "@components/layout/Table";
import Tag from "@components/shared/Tag";

type SelectedSubscribersProps = {
  title: string;
  subscribers: any;
  selectedSubscribers: any;
  setSelectedSubscribers: any;
};

const SelectSubscribers: React.FunctionComponent<SelectedSubscribersProps> = ({
  title,
  subscribers,
  selectedSubscribers,
  setSelectedSubscribers,
}) => {
  const { t } = useTranslation();
  const [filter, setFilter] = React.useState("");
  const tableFields = [
    {
      title: t("subscriber.account_number"),
      name: "customerId",
    },
    {
      title: t("common.level1wrs"),
      name: "scheme",
    },
    {
      title: t("subscriber.is_active"),
      name: "status",
    },

    {
      title: t("subscriber.name"),
      name: "customerName",
    },
  ];

  const tableData = subscribers
    ?.filter(
      (row: any) =>
        row.name
          .toString()
          .toLowerCase()
          .includes(filter.toString().toLowerCase()) ||
        row.accountNumber?.toString().includes(filter)
    )
    .map((subscriber: any) => {
      return {
        id: subscriber.id,
        customerId: subscriber.accountNumber,
        customerName: subscriber.name,
        scheme: subscriber.level1WRS.name,
        status: <Tag status="success">Active</Tag>,
      };
    });

  function handleSelect(values: string[]) {
    const selected = values.map((selectedId) => {
      return subscribers.find(({ id }: any) => id === selectedId);
    });
    setSelectedSubscribers(selected);
  }

  return (
    <>
      <div className="p-6 flex flex-col gap-4 grow">
        <Heading light>{title}</Heading>

        <form className="max-w-xs">
          <Label htmlFor="customer-id">{t("subscriber.filter_name_id")}</Label>
          <SearchInput
            onChange={(e) => {
              setFilter(e.target.value);
            }}
          />
        </form>

        <Table
          fields={tableFields}
          data={tableData}
          stickyHeader
          selectionKey="id"
          selectedKeys={selectedSubscribers?.map((i: any) => i?.id)}
          onSelectionChange={handleSelect}
          multiple={false}
        />
      </div>
    </>
  );
};

export default SelectSubscribers;

import React from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import Table from "@components/layout/Table";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import UserTableActions from "@components/table/UserTableActions";
import Loading from "@components/shared/Loading";
import Tag from "@components/shared/Tag";
import { useAppContext } from "@context/AppContext";
import { useAllUsers } from "@hooks/query/useAllUsers";
import { formatDate } from "@utils/formatDate";

const transformUsers = (users: Record<string, any>[], refetch: () => void) => {
  return users.map((item: any) => ({
    accountNumber: item?.subscriber.accountNumber ?? "",
    name: item?.name ?? "",
    email: item?.email ?? "",
    tel: item?.tel ?? "",
    createdAt: item.createdAt ? formatDate(new Date(item.createdAt)) : "",
    isPrimary: (
      <Tag status={item.isPrimary ? "info" : "warning"}>
        {item.isPrimary ? t("user.primary") : t("user.general")}
      </Tag>
    ),
    type: item.isPrimary ? "primary" : "general",
    actions: <UserTableActions user={item} refetch={refetch} />,
  }));
};

type UserTableProps = {
  level1ResourceId: string;
};

const UserTable: React.FunctionComponent<UserTableProps> = ({
  level1ResourceId,
}) => {
  const { t } = useTranslation();
  const { checkPermissions } = useAppContext();
  const [filter, setFilter] = React.useState({
    accountNumber: "",
    name: "",
    email: "",
    type: "",
  });

  const {
    data = [],
    isLoading,
    refetch,
  } = useAllUsers({
    params: { level1ResourceId },
  });
  const users = React.useMemo(
    () => transformUsers(data, refetch),
    [data, refetch]
  );

  if (isLoading) {
    return (
      <div className="py-20">
        <Loading />
      </div>
    );
  }

  const tableFields = [
    {
      title: t("subscriber.account_number"),
      name: "accountNumber",
    },
    {
      title: t("user.username"),
      name: "name",
    },
    {
      title: t("user.email"),
      name: "email",
    },
    {
      title: t("user.tel"),
      name: "tel",
    },
    {
      title: t("subscriber.created_at"),
      name: "createdAt",
    },
    {
      title: t("common.type"),
      name: "isPrimary",
    },
    {
      title: t("common.actions"),
      name: "actions",
    },
  ];

  const tableData = users.filter((item: any) => {
    const matchesAccountNumber =
      filter.accountNumber.length === 0 ||
      item.accountNumber?.toString().includes(filter.accountNumber);
    const matchesName =
      filter.name.length === 0 ||
      item.name?.toLowerCase().includes(filter.name.toLowerCase());
    const matchesEmail =
      filter.email.length === 0 ||
      item.email?.toLowerCase().includes(filter.email.toLowerCase());

    if (
      filter.type.length &&
      !item.type?.toLowerCase().includes(filter.type.toLowerCase())
    ) {
      return false;
    }

    return matchesAccountNumber || matchesName || matchesEmail;
  });

  const typeOptions = [
    { label: t("user.primary"), value: "primary" },
    { label: t("user.general"), value: "general" },
  ];

  const handleFilterChange = (key: string, value: string) => {
    setFilter((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <>
      <header className="flex flex-col items-start 2xl:flex-row 2xl:items-end gap-4 z-20">
        <form className="flex gap-4">
          <div className="max-w-sm">
            <Label htmlFor="accountFilter">{t("user.filter.general")}</Label>
            <SearchInput
              id="accountFilter"
              value={filter.accountNumber}
              onChange={(e) => {
                handleFilterChange("accountNumber", e?.target.value);
                handleFilterChange("name", e?.target.value);
                handleFilterChange("email", e?.target.value);
              }}
            />
          </div>
          <div>
            <Label>{t("user.filter.type")}</Label>
            <Select
              options={typeOptions}
              value={typeOptions.find(({ value }) => value === filter.type)}
              onChange={(e) => {
                handleFilterChange("type", e?.value || "");
              }}
              isClearable
            />
          </div>
        </form>

        {checkPermissions(["CreateUsers"]) ? (
          <Link
            className="2xl:ml-auto btn-secondary text-sm rounded"
            to={`/polestar/level1wrs/${level1ResourceId}/users/create`}
          >
            {t("user.create.title")}
          </Link>
        ) : null}
      </header>

      <Table
        fields={tableFields}
        data={tableData}
        tableHeaderClassName="relative z-10"
        stickyHeader
      />
    </>
  );
};

export default UserTable;

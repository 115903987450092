import MagnifyingGlassIcon from "@components/icons/MagnifyingGlassIcon";
import TextInput from "@components/form/TextInput";

const SearchInput: React.FunctionComponent<
  React.ComponentProps<typeof TextInput>
> = ({ ...props }) => {
  return (
    <TextInput
      type="search"
      suffix={<MagnifyingGlassIcon className="w-4 h-4" />}
      {...props}
    />
  );
};

export default SearchInput;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { startCase } from "lodash";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";

import PaperClipIcon from "@components/icons/PaperClipIcon";
import Modal from "@components/layout/Modal";
import { formatDateTime } from "@utils/formatDateTime";
import ENV from "config/env";

type NotificationMessageViewModalProps = {
  message: any;
  notification: any;
  onClose: () => void;
  showRecipient?: boolean;
};

const NotificationMessageViewModal: React.FunctionComponent<
  NotificationMessageViewModalProps
> = ({ message, notification, onClose, showRecipient = false }) => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);
  const navigate = useNavigate();

  const performNotificationAction = (action: string) => {
    if (action === "ForwardDrawRequest") {
      navigate("/polestar/administrative_approvals#tab=2");
    } else if (action === "StockDomesticRequest") {
      navigate(
        `/polestar/administrative_approvals/approve_stock_domestic?subscriberId=${message?.sender?.subscriberId}`,
      );
    } else if (action === "AdministrativeTransferRequest") {
      navigate("/polestar/administrative_approvals#tab=2");
    } else {
      window.location.href = `/polestar/notifications/create?notificationId=${
        notification.id
      }&subscriberIds=${notification.relatedSubscribers.map((s: any) => s.id)}`;
    }
  };

  return (
    <Modal open={!!message}>
      <div className="flex flex-col">
        <div className="border-b p-4">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {startCase(message?.type)}
          </h3>
        </div>
        <div className="p-4 h-96 overflow-auto">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            {message?.type === "Shutdown" && (
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  {t("notification.shutdown_dates")}:
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {formatDateTime(new Date(message?.fromDate))} -{" "}
                  {formatDateTime(new Date(message?.toDate))}
                </dd>
              </div>
            )}
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">
                {t("notification.subject")}:
              </dt>
              <dd className="mt-1 text-sm text-gray-900">{message?.subject}</dd>
            </div>
            {showRecipient && message?.recipients.length > 1 && (
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  {t("notification.recipients")}:
                </dt>
                <ol>
                  {message?.recipients
                    ?.slice(0, showAll ? message?.recipients.length : 5)
                    .map((recipient: any, index: any) => (
                      <li key={index} className="mt-1 text-sm text-gray-900">
                        {index + 1}. {recipient?.recipientWallet?.name}
                      </li>
                    ))}
                </ol>
                {message?.recipients?.length > 5 && (
                  <dd className="mt-1 text-sm text-gray-900">
                    <button onClick={() => setShowAll(!showAll)}>
                      {showAll ? t("common.see_less") : t("common.see_more")}
                    </button>
                  </dd>
                )}
              </div>
            )}
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">
                {t("notification.body")}:
              </dt>
              <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">
                <div
                  dangerouslySetInnerHTML={{
                    __html: message?.body
                      ? DOMPurify.sanitize(message.body)
                      : "-",
                  }}
                />
              </dd>
            </div>
            {message?.evidences?.[0] && (
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  {t("notification.evidence")}:
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                    <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                      <div className="flex w-0 flex-1 items-center">
                        <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" />
                        <span className="ml-2 w-0 flex-1 truncate">
                          {message?.evidences?.[0]?.fileName}
                        </span>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <a
                          href={`${ENV.API_HOST}/evidences/${message?.evidences?.[0]?.id}/download`}
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                          download
                        >
                          {t("common.download")}
                        </a>
                      </div>
                    </li>
                  </ul>
                </dd>
              </div>
            )}
          </dl>
        </div>
        <div className="mt-5 border-t px-4 py-2 flex gap-4 justify-end">
          {notification?.type?.actionTextTemplate && (
            <button
              type="button"
              className="btn-outline-primary text-base font-medium sm:col-start-1 sm:text-sm"
              onClick={e => {
                e.stopPropagation();

                performNotificationAction(notification?.type?.name);
              }}
            >
              {t(notification?.type?.actionTextTemplate)}
            </button>
          )}
          <button
            type="button"
            className="btn-primary text-base font-medium sm:col-start-1 sm:text-sm"
            onClick={onClose}
          >
            {t("common.close")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default NotificationMessageViewModal;

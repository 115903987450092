import api from "./api";

export const getMeterUploadsByLevel1WRS = async (
  level1ResourceId: string
): Promise<any> => {
  const { data } = await api.get("/admin/meterUploads", {
    params: {
      level1ResourceId,
    },
  });

  return data.meterUploads;
};

export const bulkUpload = async (
  level1ResourceId: string,
  fileName: string
): Promise<any> => {
  const { data } = await api.post("/admin/meterUploads", {
    level1ResourceId,
    fileName,
  });

  return data.meterUploads;
};

export type UpdateMeterPayload = Partial<CreateMeterPayload> & {
  id: string;
};

export const updateMeter = async ({ id, ...payload }: UpdateMeterPayload) => {
  const { data } = await api.patch(`/admin/meters/${id}`, payload);

  return [data.meter, data.workflowInstance];
};

type GetAllMetersParams = {
  extractionPointId: string;
  isActive: boolean;
  subscriberId: string;
  level1ResourceId: string;
  level0ResourceId: string;
  showDeclarations: string;
  serialNo: string;
  isDelete: boolean;
};
export const getAllMeters = async (
  params?: Partial<GetAllMetersParams>
): Promise<any> => {
  const { data } = await api.get("/admin/meters", { params });

  return data.meters;
};

export const getMeterById = async (id: string) => {
  const { data } = await api.get(`/admin/meters/${id}`);

  return data.meter;
};

export type CreateMeterPayload = {
  subscriberId?: string;
  extractionPointId?: string;
  serialNo: string;
  unit: number;
  verifiedAt?: Date;
  type?: string;
  ipAddress?: string;
  clickOver?: number;
  threshold?: number;
};

export const createMeter = async (
  meter: CreateMeterPayload
): Promise<any[]> => {
  const { data } = await api.post(`/admin/meters`, meter);

  return [data.meter, data.workflowInstance];
};

export type DecommissionPayload = {
  subscriberId: string;
  extractionPointId: string;
  meterId: string;
};

export const decomission = async (payload: DecommissionPayload) => {
  const { data } = await api.delete("/admin/meters/decomission", {
    data: payload,
  });

  return [data.meter, data.workflowInstance];
};

export type ReplaceMeterPayload = {
  subscriberId: string;
  oldMeterId: string;
  replacementMeterId: string;
  extractionPointId: string;
};

export const replaceMeter = async (payload: ReplaceMeterPayload) => {
  const { data } = await api.post("/admin/meters/replace", payload);

  return [data.meter, data.workflowInstance];
};

export type LinkMeterToExtractionPointPayload = {
  subscriberId: string;
  meterId: string;
  extractionPointId: string;
};

export const linkMeterToExtractionPoint = async (
  payload: LinkMeterToExtractionPointPayload
) => {
  const { data } = await api.post("/admin/meters/linkExtractionPoint", payload);

  return [data.meter, data.workflowInstance];
};

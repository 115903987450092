import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import TrashIcon from "@components/icons/TrashIcon";
import Heading1 from "@components/layout/Heading";
import { useGeneralReportContext } from "@context/GeneralReportContext";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Select from "react-select";
import GeneralReportFooter from "./GeneralReportFooter";

const GeneralReportStep3: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { saveData, goToNextStep, reportData } = useGeneralReportContext();
  const [filterEnabled, setFilterEnabled] = useState<boolean>(
    reportData.filters?.length > 0
  );
  const [filters, setFilters] = useState<any[]>(reportData.filters || []);

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    saveData({ filters });
    goToNextStep();
  };

  const handleToggleFilterEnabled = (enabled: boolean) => {
    setFilterEnabled(enabled);

    if (enabled) {
      handleNewFilterClick();
    } else {
      setFilters([]);
    }
  };

  const handleNewFilterClick = () => {
    setFilters([
      ...filters,
      {
        field: reportData.selectedFields[0].name,
        value: "",
      },
    ]);
  };

  const handleFilterFieldNameChanged = (index: number, value: any) => {
    setFilters(
      filters.map((f, i) => (i === index ? { ...f, field: value } : f))
    );
  };

  const handleFilterFieldValueChanged = (index: number, value: any) => {
    setFilters(
      filters.map((f, i) => (i === index ? { ...f, value: value } : f))
    );
  };

  const handleRemoveFilterClick = (filter: any) => {
    setFilters(filters.filter((f) => f.field !== filter.field));
  };

  return (
    <>
      <form onSubmit={handleSave} className="grow flex flex-col">
        <div className="p-4 grow flex flex-col">
          <Heading1 light>{t("reporting.general_step3_title")}</Heading1>
          <div className="text-sm">{t("reporting.general_step3_subtitle")}</div>
          <fieldset className="mt-4 space-y-4 flex flex-col">
            <div className="w-2/3 flex gap-2">
              <div className="w-1/2">
                <Label>{t("reporting.filter_enabled")}</Label>
                <div className="flex gap-10">
                  <Label htmlFor="filter_enabled">
                    <input
                      name="filter_enabled"
                      type="radio"
                      className="h-4 w-4 mr-2 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      defaultChecked={filterEnabled}
                      onChange={(e) =>
                        e.target.checked && handleToggleFilterEnabled(true)
                      }
                    />
                    {t("common.yes")}
                  </Label>
                  <Label htmlFor="filter_enabled">
                    <input
                      name="filter_enabled"
                      type="radio"
                      className="h-4 w-4 mr-2 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      defaultChecked={!filterEnabled}
                      onChange={(e) =>
                        e.target.checked && handleToggleFilterEnabled(false)
                      }
                    />
                    {t("common.no")}
                  </Label>
                </div>
              </div>
            </div>
            {filterEnabled && (
              <div className="w-full flex flex-col gap-2">
                {filters.map((filter, i) => (
                  <div key={i} className="flex gap-2 items-center">
                    <button
                      type="button"
                      className="text-3xl"
                      onClick={handleNewFilterClick}
                    >
                      +
                    </button>
                    <Select
                      className="w-1/3"
                      options={reportData.selectedFields?.map((f) => ({
                        label: f.label,
                        value: f.name,
                      }))}
                      value={reportData.selectedFields
                        ?.map((f) => ({
                          label: f.label,
                          value: f.name,
                        }))
                        .find((f) => f.value === filter.field)}
                      onChange={(e: any) =>
                        handleFilterFieldNameChanged(i, e.value)
                      }
                    />
                    <span className="text-3xl">=</span>
                    <TextInput
                      className="w-1/3"
                      required
                      value={filter.value}
                      onChange={(e) =>
                        handleFilterFieldValueChanged(i, e.target.value)
                      }
                    />
                    <button
                      type="button"
                      title="Remove this filter"
                      onClick={() => handleRemoveFilterClick(filter)}
                    >
                      <TrashIcon className="w-5 h-5 text-gray-500" />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </fieldset>
        </div>
        <GeneralReportFooter
          disabled={filterEnabled && filters.some((f) => !f.value)}
        />
      </form>
    </>
  );
};

export default GeneralReportStep3;

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Layout from "@components/layout/Layout";
import CreateMeterWorkflow from "@components/form/meter/CreateMeterWorkflow";
import Loading from "@components/shared/Loading";
import { useSubscriber } from "@hooks/query/useSubscriber";
import { useLevel0Resource } from "@hooks/query/useLevel0Resource";

const CreateMeter = () => {
  const { t } = useTranslation();
  const { id: subscriberId = "", level0ResourceId = "" } = useParams();
  const { data: subscriber } = useSubscriber(subscriberId);
  const { data: level0Resource } = useLevel0Resource(level0ResourceId);

  if (!subscriber || !level0Resource) {
    return (
      <div className="py-20">
        <Loading />
      </div>
    );
  }

  return (
    <Layout
      permissions={["CreateMeters"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("common.subscribers"),
          href: "/polestar/subscribers",
        },
        {
          label: subscriber?.name,
          href: `/polestar/subscribers/${subscriberId}`,
        },
        {
          label: level0Resource?.identifier,
          href: `/polestar/subscribers/${subscriberId}?level0ResourceId=${level0ResourceId}`,
        },
        {
          label: t("meter.adding_meter.title"),
        },
      ]}
      title={t("meter.adding_meter.title")}
    >
      <CreateMeterWorkflow
        level0Resource={level0Resource}
        subscriber={subscriber}
      />
    </Layout>
  );
};

export default CreateMeter;

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateMeter } from "@services/meter";
import { isFunction } from "lodash";

export const useUpdateMeter = ({
  onSuccess,
  ...options
}: Record<string, any> = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["update_meter"],
    mutationFn: updateMeter,
    onSuccess: (res: any) => {
      queryClient.invalidateQueries({ queryKey: ["meters", res[0].id] });
      if (isFunction(onSuccess)) {
        onSuccess(res);
      }
    },
    ...options,
  });
};

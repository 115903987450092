import { ArrowLeftIcon } from "@heroicons/react/24/solid";

import { useAppContext } from "@context/AppContext";

const ExpandInfoPanelButton = () => {
  const { setIsExpandInfoPanel } = useAppContext();

  return (
    <button
      className="absolute top-0 right-0 p-4 cursor-pointer hover:opacity-75 transition-opacity"
      onClick={() => {
        setIsExpandInfoPanel(true);
      }}
    >
      <ArrowLeftIcon className="w-5 h-5" />
    </button>
  );
};

export default ExpandInfoPanelButton;

import { useAppContext } from "@context/AppContext";
import NotificationIcon from "../../../icons/NotificationIcon";
import Card from "../../../layout/Card";
import NotificationListCompact from "@components/form/notification/NotificationListCompact";
import { useTranslation } from "react-i18next";

const NotificationWidget: React.FunctionComponent = () => {
  const { notifications } = useAppContext();
  const { t } = useTranslation();

  return (
    <Card
      className="bg-inherit border-none p-0"
      header={
        <p className="flex gap-3 h-8 text-lg">
          <div className="relative rounded-full px-1 text-gray-400 hover:text-white focus:outline-none">
            <NotificationIcon />
            {notifications?.filter((n) => !n.viewedAt)?.length > 0 && (
              <p className="absolute right-0 top-0 bg-red-600 text-white text-xs font-bold rounded-full h-5 w-5 flex justify-center items-center">
                {notifications?.filter((n) => !n.viewedAt)?.length >= 10
                  ? "9+"
                  : notifications?.filter((n) => !n.viewedAt).length}
              </p>
            )}
          </div>
          {t("notification.widget_title", {
            newNotification: notifications?.filter(
              (notification) => !notification.viewedAt
            )?.length,
          })}
        </p>
      }
    >
      <NotificationListCompact
        notifications={notifications?.filter(
          (notification) => !notification.viewedAt
        )}
        isTable
      />
    </Card>
  );
};

export default NotificationWidget;

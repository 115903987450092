import { useTranslation } from "react-i18next";

const LocationInformation = () => {
  const { t } = useTranslation();

  return (
    <>
      <ul className="pl-2 space-y-2">
        <li>
          <strong>{t("information.sample_location.option1a_title")}</strong>{" "}
          {t("information.sample_location.option1a_description")}
        </li>
        <li>
          <strong>{t("information.sample_location.option2a_title")}</strong>{" "}
          {t("information.sample_location.option2a_description")}
        </li>
        <li>
          <strong>{t("information.sample_location.option3a_title")}</strong>{" "}
          {t("information.sample_location.option3a_description")}
        </li>
      </ul>
    </>
  );
};

export default LocationInformation;

import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { refreshToken } from "@services/auth";

export function useRefreshToken(options?: UseQueryOptions) {
  return useQuery({
    queryKey: ["refreshToken"],
    queryFn: refreshToken,
    ...options,
  });
}

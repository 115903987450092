import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { formatPercentage } from "../../../utils/formatPercentage";
import PercentageIcon from "../../icons/PercentageIcon";
import Card from "../../layout/Card";
import CircleScale from "../../layout/CircleScale";
import DropDownMenu from "../DropDownMenu";

type AnnouncedAllocationWidgetProps = {
  level1Resource: any;
};

const AnnouncedAllocationWidget: React.FunctionComponent<
  AnnouncedAllocationWidgetProps
> = ({ level1Resource }) => {
  const { t } = useTranslation();
  const [selectedLevel0WRS, setSelectedLevel0WRS] = useState(
    level1Resource?.level0WRSMembers?.[0]
  );
  const [waterClasses, setWaterClasses] = useState<Record<string, any>>({});

  useEffect(() => {
    const waterClassRates: Record<string, any> = {};

    if (level1Resource?.level0WRSMembers) {
      for (const zone of level1Resource?.level0WRSMembers) {
        waterClassRates[zone.identifier] = {};
        for (const waterClass of zone?.waterClasses) {
          if (waterClassRates[zone.identifier][waterClass.name] === undefined)
            waterClassRates[zone.identifier][waterClass.name] =
              waterClass.activeExchangeRate?.rate;
        }
      }

      setSelectedLevel0WRS(level1Resource?.level0WRSMembers[0]);
      setWaterClasses(waterClassRates);
    }
  }, [level1Resource]);

  const getColors = (percentage: number) => {
    let fontColor: string, bgColor: string, borderColor: string;

    if (percentage >= 0.75) {
      fontColor = "#000";
      bgColor = "#CCF8FE";
      borderColor = "#006EB3";
    } else if (percentage >= 0.5) {
      fontColor = "#34B53A";
      bgColor = "#E2FBD7";
      borderColor = "#34B53A";
    } else if (percentage > 0 && percentage < 0.5) {
      fontColor = "#000";
      bgColor = "#FFE5D3";
      borderColor = "#FF3A29";
    } else {
      fontColor = "#000";
      bgColor = "#eee";
      borderColor = "#000";
    }

    return {
      fontColor,
      bgColor,
      borderColor,
    };
  };

  return (
    <Card
      className="w-1/2 lg:w-1/5 shadow-none"
      header={
        <p className="flex gap-3">
          <PercentageIcon /> {t("exchange_rate.widget_title")}
        </p>
      }
    >
      <div className="flex flex-col gap-1">
        <div className="w-full text-center">
          <DropDownMenu
            className="border-none shadow-none p-0 hover:bg-transparent -mt-2 text-sm"
            items={level1Resource?.level0WRSMembers
              .sort((a: any, b: any) =>
                a.identifier.localeCompare(b.identifier)
              )
              .map((zone: any) => ({
                label: zone.identifier,
                onClick: () => setSelectedLevel0WRS(zone),
              }))}
            placeHolder={selectedLevel0WRS?.identifier}
          />
        </div>
        <div className="border-gray-200 p-4 flex flex-col">
          <div className="flex flex-row flex-wrap gap-3">
            {waterClasses[selectedLevel0WRS?.identifier]
              ? Object.keys(waterClasses[selectedLevel0WRS.identifier])
                  .sort((a, b) => a.length - b.length)
                  .map((waterClass: string) => {
                    const { fontColor, bgColor, borderColor } = getColors(
                      waterClasses[selectedLevel0WRS.identifier][waterClass]
                    );

                    return (
                      <div
                        key={waterClass}
                        className="text-xs flex flex-col text-center gap-2"
                      >
                        <p>{waterClass}</p>
                        <div className="w-20 h-20">
                          <CircleScale
                            size={60}
                            borderSize={15}
                            scale={
                              waterClasses[selectedLevel0WRS.identifier][
                                waterClass
                              ]
                            }
                            borderColor={borderColor}
                            bgColor={bgColor}
                          >
                            <h4
                              className="text-lg"
                              style={{ color: fontColor }}
                            >
                              {!isNaN(
                                waterClasses[selectedLevel0WRS.identifier][
                                  waterClass
                                ]
                              )
                                ? formatPercentage(
                                    waterClasses[selectedLevel0WRS.identifier][
                                      waterClass
                                    ]
                                  )
                                : "-"}
                            </h4>
                          </CircleScale>
                        </div>
                      </div>
                    );
                  })
              : "-"}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default AnnouncedAllocationWidget;

import React, { FunctionComponent } from "react";

const DoubleCaretLeft: FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      fill="none"
      viewBox="0 0 17 17"
    >
      <path
        stroke="#111827"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8 15.5l-7-7 7-7m8 14l-7-7 7-7"
      ></path>
    </svg>
  );
};

export default DoubleCaretLeft;

import { FunctionComponent, useState } from "react";
import classNames from "classnames";
import Select from "react-select";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import Modal from "@components/layout/Modal";
import Table from "@components/layout/Table";
import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import SelectLevel0Resource from "@components/form/SelectLevel0Resource";
import Tag from "@components/shared/Tag";
import ArrowRightCalendarIcon from "@components/icons/ArrowRightCalendarIcon";
import LedgerWorksLogo from "@components/icons/LedgerWorksIcon";
import ExternalLink from "@components/icons/ExternalLink";
import HederaLogo from "@components/icons/HederaLogo";
import LockOutlineIcon from "@components/icons/LockOutlineIcon";
import WaterLedgerLogo from "@components/icons/WaterLedgerLogo";
import { useAllEventTransactions } from "@hooks/query/useAllEventTransactions";
import { useAllEventTypes } from "@hooks/query/useAllEventTypes";
import { formatDate } from "@utils/formatDate";
import { formatDateTime } from "@utils/formatDateTime";
import { getEventGroups } from "@services/eventTransactions";
import waterledgerLogo from "@images/waterledgerLogo.svg";
import ENV from "@config/env";

type EventTransactionTableProps = {
  className?: string;
  eventGroups?: string[];
  eventTypes?: string[];
  references?: string[];
  walletId?: string;
  level0ResourceId?: string;
  level1ResourceId?: string;
  limit: number;
  stickyHeader?: boolean;
  showLogo?: boolean;
  showFilters?: Array<"event_group" | "event_type" | "dates"> | boolean;
  includeChildren?: boolean;
};

const EventTransactionTable: FunctionComponent<EventTransactionTableProps> = ({
  className,
  eventGroups,
  eventTypes,
  references,
  walletId,
  level0ResourceId,
  level1ResourceId,
  limit,
  stickyHeader = false,
  showLogo = false,
  showFilters = ["event_group", "event_type", "dates"],
  includeChildren = true,
}) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState<{ isOpen: boolean; data?: any }>({
    isOpen: false,
  });
  const [filter, setFilter] = useState<{
    level0ResourceId?: string;
    eventGroupId?: string;
    eventTypeId?: string;
    fromDate?: Date;
    toDate?: Date;
  }>({});

  const { data: eventTransactions = { eventTransactions: [] }, isLoading } =
    useAllEventTransactions({
      params: {
        eventGroups,
        eventTypes,
        references,
        walletId,
        level0ResourceId,
        level1ResourceId,
        limit,
        includeParentId: true,
      },
      select: (data: any) => {
        const eventTransactions: any[] = [];

        for (const item of data.eventTransactions) {
          eventTransactions.push(item);
          if (includeChildren && item.childEventTransactions) {
            for (const childItem of item.childEventTransactions) {
              eventTransactions.push(childItem);
            }
          }
        }

        if (limit) {
          return { eventTransactions: eventTransactions.slice(0, limit) };
        }
        return { eventTransactions };
      },
    });

  const { data: eventTypesData } = useAllEventTypes({
    refetchOnWindowFocus: false,
  });

  const { data: eventGroupsData } = useQuery(["eventGroups"], getEventGroups, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const getEventTypeOptions = () => {
    return eventTypesData?.map((e: any) => ({
      label: e.name,
      value: e.id,
    }));
  };

  const getEventGroupOptions = () => {
    return eventGroupsData?.map((e: any) => ({
      label: e.name,
      value: e.id,
    }));
  };

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const transactionLink = (hasHCS: string) => {
    if (!hasHCS.includes("@")) {
      return hasHCS;
    }
    const hcsId = hasHCS.split("@")[0];
    const timestamp = hasHCS.split("@")[1];
    const newTimestamp = timestamp.replace(".", "-");
    return `${hcsId}-${newTimestamp}`;
  };

  return (
    <>
      <div className="flex flex-col grow gap-4">
        {Array.isArray(showFilters) && (
          <div className="flex items-center gap-3 justify-between">
            <div className="flex gap-3">
              {level1ResourceId && (
                <div>
                  <Label htmlFor="event-group">{t("common.level0wrs")}</Label>
                  <SelectLevel0Resource
                    level1ResourceId={level1ResourceId}
                    value={filter?.level0ResourceId}
                    onChange={(e: any) => {
                      handleFilterChange("level0ResourceId", e?.value);
                    }}
                    isClearable={true}
                    className="w-48"
                  />
                </div>
              )}
              {showFilters.includes("event_group") && (
                <div>
                  <Label htmlFor="event-group">
                    {t("audit_trail.filter_group")}
                  </Label>
                  <Select
                    options={getEventGroupOptions()
                      ?.filter(
                        (e: any) =>
                          !eventGroups?.length || eventGroups?.includes(e.label)
                      )
                      ?.filter(
                        (e: any) =>
                          !eventTypes?.length ||
                          eventTypesData
                            ?.filter((t: any) => eventTypes.includes(t.name))
                            ?.map((t: any) => t.eventGroupId)
                            .includes(e.value)
                      )}
                    className="w-60"
                    isClearable={true}
                    value={getEventGroupOptions()?.find(
                      (e: any) => e.value === filter?.eventGroupId
                    )}
                    onChange={(e: any) => {
                      handleFilterChange("eventGroupId", e?.value);
                    }}
                  />
                </div>
              )}
              {showFilters.includes("event_type") && (
                <div>
                  <Label htmlFor="event-type">
                    {t("audit_trail.filter_type")}
                  </Label>
                  <Select
                    options={getEventTypeOptions()
                      ?.filter(
                        (e: any) =>
                          !eventTypes?.length || eventTypes?.includes(e.label)
                      )
                      ?.filter(
                        (e: any) =>
                          !eventGroups?.length ||
                          eventTypesData
                            ?.filter((t: any) =>
                              eventGroups.includes(t.eventGroup.name)
                            )
                            ?.map((t: any) => t.id)
                            .includes(e.value)
                      )}
                    className="w-72"
                    isClearable={true}
                    value={getEventTypeOptions()?.find(
                      (e: any) => e.value === filter?.eventTypeId
                    )}
                    onChange={(e: any) => {
                      handleFilterChange("eventTypeId", e?.value);
                    }}
                  />
                </div>
              )}
              {showFilters.includes("dates") && (
                <div>
                  <Label htmlFor="from-date">
                    {t("audit_trail.filter_dates")}
                  </Label>
                  <div className="flex items-center gap-1">
                    <TextInput
                      type="date"
                      id="from-date"
                      placeholder="From"
                      value={
                        filter.fromDate &&
                        formatDate(filter.fromDate, "yyyy-MM-dd")
                      }
                      onChange={(e) => {
                        handleFilterChange(
                          "fromDate",
                          new Date(`${e.target.value}T00:00:00`)
                        );
                      }}
                    />
                    <ArrowRightCalendarIcon className="w-10 h-10" />
                    <TextInput
                      type="date"
                      placeholder="To"
                      value={
                        filter.toDate && formatDate(filter.toDate, "yyyy-MM-dd")
                      }
                      onChange={(e) => {
                        handleFilterChange(
                          "toDate",
                          new Date(`${e.target.value}T23:59:59`)
                        );
                      }}
                    />
                  </div>
                </div>
              )}
            </div>

            {showLogo && (
              <div className="hidden xl:block">
                <div className="flex justify-end items-center gap-2 text-xs">
                  <span>{t("common.powered_by")}</span>
                  <img
                    src={waterledgerLogo}
                    alt="Water Ledger logo"
                    className="h-6"
                  />
                  <span className="font-bold text-sm">
                    {t("common.waterledger")}
                  </span>
                </div>
              </div>
            )}
          </div>
        )}

        <Table
          pageSize={100}
          containerClassName={classNames("rounded-none", className)}
          className={className}
          fields={[
            {
              title: t("audit_trail.group"),
              name: "group",
            },
            {
              title: t("audit_trail.type"),
              name: "type",
            },
            {
              title: t("audit_trail.description"),
              name: "description",
            },
            {
              title: t("audit_trail.timestamp"),
              name: "timestamp",
            },
            {
              title: t("common.status"),
              name: "status",
            },
            {
              title: "",
              name: "view",
            },
          ]}
          data={eventTransactions.eventTransactions
            ?.filter((record: any) => {
              return (
                !filter.level0ResourceId ||
                record.impactLevel0ResourceId === filter.level0ResourceId
              );
            })
            ?.filter((record: any) => {
              return (
                !filter.eventGroupId ||
                record.eventType.eventGroupId === filter.eventGroupId
              );
            })
            ?.filter((record: any) => {
              return (
                !filter.eventTypeId || record.eventTypeId === filter.eventTypeId
              );
            })
            ?.filter((record: any) => {
              if (
                filter.fromDate instanceof Date &&
                !isNaN(filter.fromDate.valueOf())
              )
                return new Date(record.createdAt) >= new Date(filter.fromDate);

              return true;
            })
            .filter((record: any) => {
              if (
                filter.toDate instanceof Date &&
                !isNaN(filter.toDate.valueOf())
              )
                return new Date(record.createdAt) <= new Date(filter.toDate);

              return true;
            })
            ?.map((record: any) => ({
              ...record,
              group: record.eventType.eventGroup.name,
              type: record.eventType.name,
              timestamp: formatDateTime(new Date(record.createdAt)),
              description: (
                <div className="w-96 text-ellipsis overflow-hidden whitespace-nowrap">
                  {record.description}
                </div>
              ),
              status: record.hcsId ? (
                <Tag status="success">{t("common.success")}</Tag>
              ) : (
                <Tag status="error">{t("common.failed")}</Tag>
              ),
              view: (
                <button
                  type="button"
                  className="btn-primary text-sm flex"
                  onClick={() => setModalOpen({ isOpen: true, data: record })}
                >
                  <LockOutlineIcon className="w-5 h-5" />
                  {t("common.view")}
                </button>
              ),
            }))}
          stickyHeader={stickyHeader}
          loading={isLoading}
        />
      </div>

      <Modal open={modalOpen.isOpen}>
        <div className="px-6 py-4 flex gap-2 border-b">
          <div className="flex h-auto w-8">
            <WaterLedgerLogo />
          </div>
          <div className="font-medium text-primary-2 text-xl flex-grow">
            {t("audit_trail.modal_title")}
          </div>
        </div>
        <div className="px-6 py-8 flex gap-2 boarder-2 border-b">
          <div className="flex h-auto w-8">
            <LockOutlineIcon className="w6 h-6 text-green-500" />
          </div>
          <div className="text-black-900 font-light flex-grow">
            {t("audit_trail.modal_subtitle")}
          </div>
        </div>
        <div className="flex p-4 gap-2 boarder-2 boarder-t relative">
          <div className="flex-none h-auto w-auto">
            <HederaLogo />
          </div>
          <div className="grow font-bold text-gray-600 scroll-auto">
            <div className="p-2">{t("audit_trail.details")}</div>
            <ul className="p-2">
              <li>
                <dt className="text-sm text-gray-800 lg:mt-0 whitespace-normal break-words">
                  {t("audit_trail.event_group")}
                </dt>
                <dd className="text-sm font-medium text-gray-800 whitespace-normal break-words pb-2">
                  {modalOpen.data?.eventType?.eventGroup?.name}
                </dd>
              </li>
              <li>
                <dt className="text-sm text-gray-800 lg:mt-0 whitespace-normal break-words">
                  {t("audit_trail.event_type")}
                </dt>
                <dd className="text-sm font-medium text-gray-800 whitespace-normal break-words pb-2">
                  {modalOpen.data?.eventType?.name}
                </dd>
              </li>
              <li>
                <dt className="text-sm text-gray-800 lg:mt-0 whitespace-normal break-words">
                  {t("audit_trail.description")}
                </dt>
                <dd className="text-sm font-medium text-gray-800 whitespace-normal break-words pb-2">
                  {modalOpen.data?.description}
                </dd>
              </li>
              {modalOpen.data?.calculation && (
                <li>
                  <dt className="text-sm text-gray-800 lg:mt-0 whitespace-normal break-words">
                    {t("audit_trail.calculation")}
                  </dt>
                  <dd className="text-sm font-medium text-gray-800 whitespace-normal break-words pb-2">
                    {modalOpen.data?.calculation}
                  </dd>
                </li>
              )}
              <li>
                <dt className="text-sm text-gray-800 lg:mt-0 whitespace-normal break-words">
                  {t("common.created_by")}
                </dt>
                <dd className="text-sm font-medium text-gray-800 whitespace-normal break-words pb-2">
                  {modalOpen.data?.createdByUser?.name || "System"}
                </dd>
              </li>
              {modalOpen.data?.ip && (
                <li>
                  <dt className="text-sm text-gray-800 lg:mt-0 whitespace-normal break-words">
                    {t("audit_trail.ip_address")}
                  </dt>
                  <dd className="text-sm font-medium text-gray-800 whitespace-normal break-words pb-2">
                    {modalOpen.data?.ip}
                  </dd>
                </li>
              )}
              {modalOpen.data?.impactLevel0Resource && (
                <li>
                  <dt className="text-sm text-gray-800 lg:mt-0 whitespace-normal break-words">
                    {t("common.level0wrs")}
                  </dt>
                  <dd className="text-sm font-medium text-gray-800 whitespace-normal break-words pb-2">
                    {`${modalOpen.data?.impactLevel0Resource?.name} (${modalOpen.data?.impactLevel0Resource?.identifier})`}
                  </dd>
                </li>
              )}
              {modalOpen.data?.effectiveAt && (
                <li>
                  <dt className="text-sm text-gray-800 lg:mt-0 whitespace-normal break-words">
                    {t("common.effective_date")}
                  </dt>
                  <dd className="text-sm font-medium text-gray-800 whitespace-normal break-words pb-2">
                    {formatDateTime(new Date(modalOpen?.data?.effectiveAt))}
                  </dd>
                </li>
              )}
              <li>
                <dt className="text-sm text-gray-800 lg:mt-0 whitespace-normal break-words">
                  {t("common.created_at")}
                </dt>
                <dd className="text-sm font-medium text-gray-800 whitespace-normal break-words pb-2">
                  {formatDateTime(new Date(modalOpen?.data?.createdAt))}
                </dd>
              </li>
              <li>
                <dt className="text-sm text-gray-800 lg:mt-0 whitespace-normal break-words">
                  {t("common.status")}
                </dt>
                <dd className="text-sm font-medium text-gray-800 whitespace-normal break-words pb-2">
                  {modalOpen.data?.hcsId ? (
                    <Tag status="success">{t("common.success")}</Tag>
                  ) : (
                    <Tag status="error">{t("common.failed")}</Tag>
                  )}
                </dd>
              </li>
            </ul>
            {modalOpen?.data?.hcsId && (
              <div className="grow font-bold text-gray-600 scroll-auto">
                <div className="p-2">{t("audit_trail.on_chain_data")}</div>
                <ul className="p-2">
                  <li>
                    <dt className="text-sm text-gray-800 lg:mt-0 whitespace-normal break-words">
                      {t("audit_trail.hcs_id")}
                    </dt>
                    <dd className="text-sm font-medium text-gray-800 whitespace-normal break-words pb-2">
                      {modalOpen?.data?.hcsId}
                    </dd>
                  </li>
                  <li>
                    <dt className="text-sm text-gray-800 lg:mt-0 whitespace-normal break-words">
                      {t("audit_trail.struck_at")}
                    </dt>
                    <dd className="text-sm font-medium text-gray-800 whitespace-normal break-words pb-2">
                      {formatDateTime(new Date(modalOpen?.data?.struckAt))}
                    </dd>
                  </li>
                  <li>
                    <dt className="text-sm text-gray-800 lg:mt-0 whitespace-normal break-words">
                      {t("audit_trail.hash")}
                    </dt>
                    <dd className="text-sm font-medium text-gray-800 whitespace-normal break-words pb-2">
                      {modalOpen?.data?.hcsHash?.substring(0, 50)}...
                    </dd>
                  </li>
                </ul>
              </div>
            )}
          </div>
          {modalOpen?.data?.hcsId && (
            <div className="w-max absolute top-2 right-4">
              <a
                className="inline-flex text-xs underline text-primary-2"
                href={`${ENV.LEDGER_WORKS_URL}/transactions/${transactionLink(
                  modalOpen?.data?.hcsId
                )}`}
                target="_blank"
                rel="noreferrer"
              >
                <span className="pr-1 h-6 w-6">
                  <LedgerWorksLogo />
                </span>
                {t("audit_trail.see_details")}
                <span className="pl-2">
                  <ExternalLink />
                </span>
              </a>
            </div>
          )}
        </div>

        <div className="mt-5 border-t px-4 py-2 flex flex-row-reverse gap-4">
          <button
            type="button"
            className="btn-default text-base font-medium sm:col-start-1 sm:text-sm"
            onClick={() => setModalOpen({ isOpen: false })}
          >
            {t("common.close")}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default EventTransactionTable;

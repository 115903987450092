import { getSubscriberDeclarations } from "@services/subscriber";
import { useQuery } from "@tanstack/react-query";

export function useSubscriberDeclarations({ ...options } = {}) {
  return useQuery<any>({
    queryKey: ["subscriberDeclarations"],
    queryFn: () => getSubscriberDeclarations(),
    ...options,
  });
}

import React from "react";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import ConfirmationDetail from "@components/shared/ConfirmationDetail";
import ConfirmModal from "@components/shared/ConfirmModal";
import WorkflowComplete from "@components/shared/WorkflowComplete";
import { useStepContext } from "@context/shared/StepContext";
import { toastError, toastSuccess } from "@utils/toast";
import { isValidationError } from "@utils/formError";

type ConfirmAdjustDeclarationProps = {
  onSubmit: () => Promise<void>;
  onCancel: () => void;
  workflowInstance: any;
  confirmData: any;
};

const ConfirmAdjustDeclaration: React.FunctionComponent<
  ConfirmAdjustDeclarationProps
> = ({ onSubmit, onCancel, confirmData, workflowInstance }) => {
  const { t } = useTranslation();
  const { stepHelpers } = useStepContext();
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      await onSubmit();
      toastSuccess(t("balance_adjustment.meter_reading.toast.success"));
    } catch (error: any) {
      if (isValidationError(error)) {
        const { errors = [] } = error?.response?.data;
        const messages = errors.map((i: any) => i.message);
        toastError(
          <>
            <p>{t("balance_adjustment.meter_reading.toast.failed")}</p>
            {messages.length ? (
              <ul className="list-disc pl-4">
                {messages.map((text: any) => {
                  return <li key={text}>{text}</li>;
                })}
              </ul>
            ) : null}
          </>
        );
      } else {
        toastError(t("shared.toast.error"));
      }
    }

    setShowConfirmModal(false);
    setIsLoading(false);
  };

  if (workflowInstance?.id) {
    return <WorkflowComplete references={[workflowInstance.id]} />;
  }

  return (
    <>
      <div className="flex flex-col gap-4 grow">
        <div className="p-6 space-y-4">
          <Heading light>
            {t("balance_adjustment.meter_reading.confirm.title")}
          </Heading>
          <ConfirmationDetail data={confirmData} onEdit={stepHelpers.setStep} />
        </div>

        <div className="grow" />

        <footer className="flex gap-4 p-6 border-t border-gray-200">
          <button
            type="button"
            className="btn-outline-primary"
            onClick={stepHelpers.goToPrevStep}
          >
            {t("common.prev_step")}
          </button>
          <button
            type="button"
            className="btn-primary"
            onClick={() => {
              setShowConfirmModal(true);
            }}
          >
            {t("common.ledger")}
          </button>
          <button
            type="button"
            className="btn-outline-primary"
            onClick={onCancel}
          >
            {t("common.cancel")}
          </button>
        </footer>
      </div>

      <ConfirmModal
        open={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
        }}
        onConfirm={handleConfirm}
        isSubmitting={isLoading}
      >
        {t("balance_adjustment.meter_reading.confirm.body")}
      </ConfirmModal>
    </>
  );
};

export default ConfirmAdjustDeclaration;

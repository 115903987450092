import { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import Heading1 from "@components/layout/Heading";
import Table from "@components/layout/Table";
import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import ArrowRightCalendarIcon from "@components/icons/ArrowRightCalendarIcon";
import ExternalLinkIcon from "@components/icons/ExternalLinkIcon";
import AccountingPeriodChecklistFooter from "./AccountingPeriodChecklistFooter";
import { useAccountingPeriodChecksContext } from "@context/AccountingPeriodChecksContext";
import { useAppContext } from "@context/AppContext";
import { formatCurrency } from "@utils/formatCurrency";
import { formatDate } from "@utils/formatDate";
import { getWaterCharges } from "@services/waterCharge";

const WaterChargesChecklist: React.FunctionComponent = () => {
  const { loggedInInfo } = useAppContext();
  const { currentAccountingPeriodCheck } = useAccountingPeriodChecksContext();
  const { t } = useTranslation();
  const [data, setData] = useState<any[]>([]);
  const [filter, setFilter] = useState<{
    fromDate?: Date;
    toDate?: Date;
  }>({
    fromDate: new Date(
      currentAccountingPeriodCheck?.accountingPeriod?.periodStart,
    ),
    toDate:
      currentAccountingPeriodCheck?.accountingPeriod?.periodEnd &&
      new Date(currentAccountingPeriodCheck.accountingPeriod.periodEnd),
  });
  const [tableFields, setTableFields] = useState<any[]>([]);
  const [changeWaterCharge, setChangeWaterCharge] = useState<string>();

  const { isLoading, refetch } = useQuery(
    ["waterCharges", filter],
    () =>
      getWaterCharges({
        fromDate: filter?.fromDate,
        toDate: filter?.toDate,
      }),
    {
      enabled: !!filter?.fromDate && !!filter?.toDate,
      onSuccess: response => {
        const data: any = [];
        const fixedFields = [
          { title: t("water_charges.field_timestamp"), name: "timestamp" },
          {
            title: t("water_charges.field_billing_group"),
            name: "billingGroup",
          },
        ];
        const fields: any[] = [];

        response?.forEach((record: any) => {
          const existingRecord = data.findIndex(
            (d: any) => d.billingGroup === record.billingGroup.name,
          );

          if (!fields.some(f => f.name === record.fixedName)) {
            fields.push({
              title: `${record.fixedName} ($)`,
              name: record.fixedName,
            });
          }

          if (!fields.some(f => f.name === record.varName)) {
            fields.push({
              title: `${record.varName} ($/${t("common.volume_unit")})`,
              name: record.varName,
            });
          }

          if (record.itemNo !== "STCKDOM") {
            if (existingRecord !== -1) {
              if (!Object.hasOwn(existingRecord, record.fixedName))
                data[existingRecord][record.fixedName] = formatCurrency(
                  +record.fixedValue,
                );

              if (!Object.hasOwn(existingRecord, record.varName))
                data[existingRecord][record.varName] = formatCurrency(
                  +record.varValue,
                );

              data[existingRecord].timestamp = formatDate(
                new Date(record.effectiveAt),
              );
            } else {
              data.push({
                timestamp: formatDate(new Date(record.effectiveAt)),
                billingGroup: record.billingGroup.name,
                [record.fixedName]: formatCurrency(+record.fixedValue),
                [record.varName]: formatCurrency(+record.varValue),
                id: record.id,
              });
            }
          }
        });

        setData(
          data.sort((a: any, b: any) =>
            a.billingGroup.localeCompare(b.billingGroup),
          ),
        );
        setTableFields([
          ...fixedFields,
          ...fields.sort((a: any, b: any) => a.name.localeCompare(b.name)),
        ]);
      },
    },
  );

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  return (
    <>
      <div className="flex flex-col grow gap-4 p-4">
        <header className="flex justify-between items-center">
          <Heading1 light>
            {t("accounting_period.checklist.water_charges")}
          </Heading1>
        </header>

        <form className="flex flex-wrap gap-3 justify-start">
          <div className="w-full sm:w-auto">
            <Label htmlFor="from-date">{t("reporting.filter_dates")}</Label>
            <div className="flex flex-col sm:flex-row gap-1">
              <div className="max-w-max">
                <TextInput
                  type="date"
                  placeholder="From"
                  value={
                    filter.fromDate && formatDate(filter.fromDate, "yyyy-MM-dd")
                  }
                  onChange={e => {
                    handleFilterChange(
                      "fromDate",
                      new Date(`${e.target.value}T00:00:00`),
                    );
                  }}
                />
              </div>
              <ArrowRightCalendarIcon className="w-10 h-10 hidden sm:block" />
              <div className="max-w-max">
                <TextInput
                  type="date"
                  placeholder="To"
                  value={
                    filter.toDate && formatDate(filter.toDate, "yyyy-MM-dd")
                  }
                  min={
                    filter.fromDate && formatDate(filter.fromDate, "yyyy-MM-dd")
                  }
                  onChange={e => {
                    handleFilterChange(
                      "toDate",
                      new Date(`${e.target.value}T23:59:59`),
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex items-end sm:w-auto">
            <button
              type="button"
              className="btn-default"
              onClick={() => refetch()}
            >
              {t("common.refresh")}
            </button>
          </div>
          <div className="flex items-end w-full ml-auto sm:w-auto">
            <Link
              className="btn-secondary flex items-center gap-1"
              to="/polestar/water_charges/create?newWindow=true"
              target="_blank"
            >
              <ExternalLinkIcon className="w-4 h-4" />
              {t("accounting_period.checklist.add_water_charge")}
            </Link>
          </div>
          <div className="flex items-end w-full sm:w-auto">
            <button
              className="btn-secondary flex items-center gap-1"
              disabled={changeWaterCharge === undefined}
              onClick={e => {
                e.preventDefault();
                const selectedWaterCharge = data.find(
                  d => d.id === changeWaterCharge,
                );
                window.open(
                  `/polestar/water_charges/${selectedWaterCharge.id}/edit?level1ResourceId=${currentAccountingPeriodCheck.level1ResourceId}&newWindow=true`,
                  "_blank",
                );
              }}
            >
              <ExternalLinkIcon className="w-4 h-4" />
              {t("accounting_period.checklist.edit_water_charge")}
            </button>
          </div>
        </form>

        <Table
          selectionKey="id"
          multiple={false}
          onSelectionChange={keys => {
            if (keys.length) setChangeWaterCharge(keys[0]);
          }}
          selectedKeys={[changeWaterCharge]}
          fields={tableFields}
          data={data}
          pageSize={100}
          loading={isLoading}
          stickyHeader
        />
      </div>
      <AccountingPeriodChecklistFooter
        stepData={{
          waterChargesCheckedAt: new Date(),
          waterChargesCheckedByUserId: loggedInInfo?.userDetails?.id,
        }}
      />
    </>
  );
};

export default WaterChargesChecklist;

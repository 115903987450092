import { FunctionComponent } from "react";
import ENV from "@config/env";
import GoogleMap from "./GoogleMap";
import OpenStreetMap from "./OpenStreetMap";

type MapViewProps = {
  zoomLevel?: number;
  zoomControl?: boolean;
  center?: { lat: number; lng: number };
  markers?: Array<{ lat: number; lng: number }>;
  className?: string;
  onClick?: (e: { lat: number; lng: number }) => void;
  onMarkerClick?: (e: { lat: number; lng: number }) => void;
};

export enum MapEngines {
  GOOGLE = "google",
  OPENSTREETMAP = "openstreetmap",
}

const MapView: FunctionComponent<MapViewProps> = (props) => {
  let mapCenter = props.center;

  if (!mapCenter && props.markers && props.markers.length > 0) {
    mapCenter = {
      lat:
        props.markers.reduce((sum, coord) => sum + coord.lat, 0) /
        props.markers.length,
      lng:
        props.markers.reduce((sum, coord) => sum + coord.lng, 0) /
        props.markers.length,
    };
  }

  switch (ENV.MAP_ENGINE) {
    case MapEngines.GOOGLE:
      return <GoogleMap {...props} center={mapCenter} />;
    case MapEngines.OPENSTREETMAP:
    default:
      return <OpenStreetMap {...props} center={mapCenter} />;
  }
};

export default MapView;

import React from "react";
import classNames from "classnames";
import {
  flexRender,
  PaginationState,
  type Table,
  type Row,
} from "@tanstack/react-table";
import {
  ChevronDownIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

import Loading from "@components/shared/Loading";
import { noop } from "lodash";

type DataTableProps = {
  table: Table<any>;
  pagination: PaginationState;
  rowSelection?: Record<string, boolean>;
  isLoading?: boolean;
  isFetching?: boolean;
  stickyHeader?: boolean;
  hideHeader?: boolean;
  onRowClick?: (row: Row<any>) => void;
};

const DataTable: React.FunctionComponent<DataTableProps> = ({
  table,
  isLoading = false,
  hideHeader = false,
  onRowClick = noop,
}: any) => {
  const { t } = useTranslation();
  const hasData = table.getRowModel().rows.length;

  const totalVisibleColumns = table
    .getAllLeafColumns()
    .filter((col: any) => col.getIsVisible()).length;

  return (
    <div className="flex flex-col flex-1 bg-white">
      <table className="min-w-full border-separate border-spacing-0">
        {hideHeader ? null : (
          <thead>
            {table.getHeaderGroups().map((headerGroup: any) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header: any) => (
                  <th
                    key={header.id}
                    className="sticky top-0 z-10 border-b  bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold  backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: classNames(
                              "inline-flex items-center gap-1",
                              {
                                "cursor-pointer select-none":
                                  header.column.getCanSort(),
                              }
                            ),
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}

                          {header.column.getCanFilter()
                            ? {
                                asc: <ChevronUpIcon className="w-4 h-4" />,
                                desc: <ChevronDownIcon className="w-4 h-4" />,
                              }[header.column.getIsSorted() as string] ?? (
                                <ChevronUpDownIcon className="w-4 h-4" />
                              )
                            : null}
                        </div>
                      </>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        )}

        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={totalVisibleColumns}>
                <div className="flex items-center justify-center py-16">
                  <Loading />
                </div>
              </td>
            </tr>
          ) : hasData ? (
            table.getRowModel().rows.map((row: any) => (
              <tr
                key={row.id}
                className={
                  row.getIsSelected() ? "bg-gray-50" : "hover:bg-gray-50"
                }
                onClick={() => onRowClick(row)}
              >
                {row.getVisibleCells().map((cell: any, index: number) => (
                  <td
                    key={cell.id}
                    className="relative py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                  >
                    {row.getIsSelected() && index === 0 ? (
                      <div className="absolute inset-y-0 left-0 w-0.5 bg-primary-1" />
                    ) : null}
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={totalVisibleColumns}>
                <div className="text-center py-16 text-gray-400">
                  {t("common.no_data")}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;

import React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SelectMeterReplacement from "./SelectMeterReplacement";
import ConfirmMeterReplacement from "./ConfirmMeterReplacement";
import WorkflowPanels from "@components/form/WorkflowPanels";
import InfoPanel from "@components/form/InfoPanel";
import DeclarationForm from "@components/form/declaration/DeclarationForm";
import UploadEvidencesWorkflowForm from "@components/form/UploadEvidenceWorkflowForm";
import ConfirmDeclaration from "@components/form/declaration/ConfirmDeclaration";
import StepButton from "@components/shared/StepButton";
import StepInfo from "@components/shared/StepInfo";
import Loading from "@components/shared/Loading";
import {
  ReplaceMeterProvider,
  useReplaceMeterContext,
} from "@context/ReplaceMeterContext";
import { StepProvider, useStepContext } from "@context/shared/StepContext";
import {
  DeclarationProvider,
  READING_TYPES,
  useDeclarationContext,
} from "@context/DeclarationContext";
import {
  EvidenceProvider,
  useEvidenceContext,
} from "@context/shared/EvidenceContext";
import { useReplaceMeter } from "@hooks/mutation/useReplaceMeter";
import { useCreateDeclaration } from "@hooks/mutation/useCreateDeclaration";
import { useMeter } from "@hooks/query/useMeter";
import { useCreateInitialDeclaration } from "@hooks/mutation/useCreateInitialDeclaration";
import { convertMLToLiter } from "@utils/convertUnits";
import { DBTables } from "@utils/constants/dbTables";

const ReplaceMeterWorkflow = () => {
  return (
    <StepProvider maxStep={9}>
      <DeclarationProvider>
        <EvidenceProvider>
          <ReplaceMeterProvider>
            <Consumer />
          </ReplaceMeterProvider>
        </EvidenceProvider>
      </DeclarationProvider>
    </StepProvider>
  );
};

const Consumer: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const {
    details,
    setDetails,
    isComplete: isReplaceComplete,
    setIsComplete: setIsReplaceComplete,
    getMeterReplacementInfo,
    workflowInstance: replaceMeterWorkflowInstance,
    setWorkflowInstance: setReplaceMeterWorkflowInstance,
    handleSelecteMeter,
  } = useReplaceMeterContext();
  const { currentStep, stepHelpers } = useStepContext();
  const {
    getEvidencesInfo,
    uploadEvidences,
    reset: resetEvidences,
  } = useEvidenceContext();
  const {
    declaration,
    setDeclaration,
    getDeclarationInfo,
    resetReading,
    isComplete: isInitialDeclarationComplete,
    setIsComplete: setIsInitialDeclarationComplete,
    workflowInstance: initialDeclarationWorkflow,
    setWorkflowInstance: setInitialDeclarationWorkflow,
  } = useDeclarationContext();
  const { mutateAsync: createDeclarationMutation } = useCreateDeclaration();
  const { mutateAsync: replaceMeterMutation } = useReplaceMeter();
  const { mutateAsync: createInitialDeclarationMutation } =
    useCreateInitialDeclaration();

  const [finalDeclarationInfo, setFinalDeclarationInfo] = React.useState<any>();
  const [finalDeclarationEvidencesInfo, setFinalDeclarationEvidencesInfo] =
    React.useState<any>();
  const [finalDeclarationWorkflow, setFinalDeclarationWorkflow] =
    React.useState<any>();
  const isFinalDeclarationComplete = Boolean(finalDeclarationWorkflow);
  const [replaceMeterEvidencesInfo, setReplaceMeterEvidencesInfo] =
    React.useState<any>();

  const replacementMeterId = searchParams.get("replacementMeterId") || "";

  const { data: replacementMeter = {}, isLoading } = useMeter(
    replacementMeterId,
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(replacementMeterId),
      onSuccess: (data: any) =>
        handleSelecteMeter({ id: data.id, serialNo: data.serialNo }),
    }
  );

  const hasPredefinedReplacementMeter = replacementMeter.id;

  if (Boolean(replacementMeterId) && isLoading) {
    return (
      <div className="py-20">
        <Loading />
      </div>
    );
  }

  const handleCancel = () =>
    !!searchParams.get("newWindow")
      ? window.close()
      : navigate(state?.from ?? "/polestar");

  const handleCreateFinalDeclaration = async () => {
    const [res, workflowInstance] = await createDeclarationMutation({
      reading: convertMLToLiter(+declaration.reading),
      readAt: new Date(declaration.readAt),
      isOpening: false,
      meterId: declaration.meter.serialNo,
      extractionPointName: declaration.extractionPoint.name,
      isEstimated: declaration.type === READING_TYPES.ESTIMATED,
      calculation: declaration.calculation,
    });
    await uploadEvidences({
      description: t("declaration.evidence_data.description", {
        serialNo: declaration.meter.serialNo,
        readAt: declaration.readAt,
        extractionPointName: declaration.extractionPoint.name,
      }),
      references: [
        {
          referenceId: res.id,
          referenceTable: DBTables.Declarations,
        },
        {
          referenceId: declaration.subscriber?.id,
          referenceTable: DBTables.Subscribers,
        },
        {
          referenceId: declaration.subscriber?.level1ResourceId,
          referenceTable: DBTables.WRSHierarchies,
        },
        {
          referenceId: workflowInstance?.id,
          referenceTable: DBTables.WorkflowInstances,
        },
      ],
    });

    setDetails((prev: any) => {
      return {
        ...prev,
        oldMeter: {
          id: declaration.meter.id,
          serialNo: declaration.meter.serialNo,
          reading: declaration.reading,
        },
        subscriber: {
          id: declaration.subscriber.id,
          name: declaration.subscriber.name,
          walletId: declaration.subscriber.walletId,
        },
        extractionPoint: {
          id: declaration.extractionPoint.id,
          name: declaration.extractionPoint.name,
        },
      };
    });
    setFinalDeclarationInfo(getDeclarationInfo().body);
    setFinalDeclarationEvidencesInfo(getEvidencesInfo().body);
    setFinalDeclarationWorkflow(workflowInstance);
    resetEvidences();
    resetReading();
    stepHelpers.goToNextStep();
  };

  const handleMeterReplacement = async () => {
    const [res, workflowInstance] = await replaceMeterMutation({
      subscriberId: details.subscriber.id,
      extractionPointId: details.extractionPoint.id,
      oldMeterId: details.oldMeter.id,
      replacementMeterId: details.replacementMeter.id,
    });
    await uploadEvidences({
      description: t("meter.replacement.evidence_description", {
        extractionPointName: details.extractionPoint.name,
        oldMeterSerialNo: details.oldMeter.serialNo,
        newMeterSerialNo: details.replacementMeter.serialNo,
      }),
      references: [
        {
          referenceId: details.extractionPoint.id,
          referenceTable: DBTables.ExtractionPoints,
        },
        {
          referenceId: details.subscriber?.id,
          referenceTable: DBTables.Subscribers,
        },
        {
          referenceId: workflowInstance?.id,
          referenceTable: DBTables.WorkflowInstances,
        },
      ],
    });
    setReplaceMeterWorkflowInstance(workflowInstance);
    setIsReplaceComplete(true);
    setReplaceMeterEvidencesInfo(getEvidencesInfo().body);
    resetEvidences();
    setDeclaration((prev) => ({
      ...prev,
      meter: {
        id: res.id,
        serialNo: res.serialNo,
        clickOver: +res.clickOver,
      },
    }));
    stepHelpers.goToNextStep();
  };

  const handleCreateInitialDeclaration = async () => {
    const [res, workflowInstance] = await createInitialDeclarationMutation({
      subscriberId: declaration.subscriber.id,
      extractionPointName: declaration.extractionPoint.name,
      serialNo: declaration.meter.serialNo,
      reading: declaration.reading
        ? convertMLToLiter(+declaration?.reading)
        : 0,
      readAt: new Date(declaration.readAt),
      isEstimated: declaration.type === READING_TYPES.ESTIMATED,
    });
    await uploadEvidences({
      description: t("declaration.evidence_data.description", {
        serialNo: declaration.meter.serialNo,
        readAt: declaration.readAt,
        extractionPointName: declaration.extractionPoint.name,
      }),
      referenceId: res.id,
      referenceTable: DBTables.Declarations,
    });

    setInitialDeclarationWorkflow(workflowInstance);
    setIsInitialDeclarationComplete(true);
  };

  const steps = [
    {
      label: (
        <StepButton
          index={0}
          currentStep={currentStep}
          info={
            <StepInfo
              data={finalDeclarationInfo ?? getDeclarationInfo("final").body}
            />
          }
          isOnChain={isFinalDeclarationComplete}
        >
          {t("meter.decommission.declare_final_read")}
        </StepButton>
      ),
      panel: <DeclarationForm variant="final" onCancel={handleCancel} />,
      infoPanel: (
        <InfoPanel
          actions={
            declaration.meter.id
              ? [
                  {
                    label: t("meter.edit.title"),
                    action: () => {
                      window.open(
                        `/polestar/meters/${declaration.meter.id}/edit`
                      );
                    },
                  },
                ]
              : []
          }
        >
          <div className="space-y-6">
            <p>{t("declaration.info.details")}</p>
            <p>{t("declaration.info.timestamp")}</p>
            <p>{t("declaration.info.is_estimated")}</p>
            <p>{t("declaration.info.enter_reading")}</p>
            <p>{t("declaration.info.usage")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={1}
          currentStep={currentStep}
          info={
            <StepInfo
              data={finalDeclarationEvidencesInfo ?? getEvidencesInfo().body}
            />
          }
          isOnChain={isFinalDeclarationComplete}
        >
          {t("common.enter_evidence")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          onNext={stepHelpers.goToNextStep}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("declaration.info.evidence")}</p>
            <p>{t("evidence.supported")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={2}
          currentStep={currentStep}
          isOnChain={isFinalDeclarationComplete}
        >
          {t("declaration.form.confirm_final_read")}
        </StepButton>
      ),
      panel: (
        <ConfirmDeclaration
          title={t("declaration.form.confirm_final_read")}
          onCancel={handleCancel}
          onSubmit={handleCreateFinalDeclaration}
          variant="final"
          workflowInstanceIds={[]}
        />
      ),
      infoPanel: (
        <InfoPanel successes={[t("declaration.info.confirm")]}>
          <ul className="space-y-4">
            <li>{t("common.info.verify")}</li>
            <li>
              <strong>{t("declaration.meter_read_details")}</strong>{" "}
              {t("declaration.info.verify.details")}
            </li>
            <li>
              <strong>{t("evidence.supporting")}:</strong>{" "}
              {t("evidence.info.verify")}
            </li>
            <li>{t("common.info.changes")}</li>
          </ul>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={3}
          currentStep={currentStep}
          info={<StepInfo data={getMeterReplacementInfo().body} />}
          isOnChain={isReplaceComplete}
        >
          {t("meter.replacement.choose_meter")}
        </StepButton>
      ),
      panel: (
        <SelectMeterReplacement
          onCancel={handleCancel}
          hasPredefinedReplacementMeter={hasPredefinedReplacementMeter}
        />
      ),
      infoPanel: (
        <InfoPanel
          warnings={[
            ...(hasPredefinedReplacementMeter
              ? [t("meter.replacement.info.already_selected_meter_warning")]
              : []),
          ]}
        >
          <div className="space-y-6">
            <p>{t("meter.replacement.info.select_meter")}</p>
            <p>{t("meter.replacement.info.create")}</p>
            <p>{t("meter.replacement.info.replaced")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={4}
          currentStep={currentStep}
          info={
            <StepInfo
              data={replaceMeterEvidencesInfo ?? getEvidencesInfo().body}
            />
          }
          isOnChain={isReplaceComplete}
        >
          {t("common.enter_evidence")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          onNext={stepHelpers.goToNextStep}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("meter.replacement.info.evidence")}</p>
            <p>{t("evidence.supported")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={5}
          currentStep={currentStep}
          isOnChain={isReplaceComplete}
        >
          {t("meter.replacement.confirm")}
        </StepButton>
      ),
      panel: (
        <ConfirmMeterReplacement
          startAtIndex={3}
          workflowInstanceIds={[]}
          onSubmit={handleMeterReplacement}
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <InfoPanel successes={[t("meter.replacement.info.confirm")]}>
          {
            <ul className="space-y-4">
              <li>{t("common.info.verify")}</li>
              <li>
                <strong>{t("declaration.meter_read_details")}</strong>{" "}
                {t("meter.replacement.info.verify.details")}
              </li>
              <li>
                <strong>{t("evidence.supporting")}:</strong>{" "}
                {t("evidence.info.verify")}
              </li>
              <li>{t("common.info.changes")}</li>
            </ul>
          }
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={6}
          currentStep={currentStep}
          info={<StepInfo data={getDeclarationInfo("initial").body} />}
          isOnChain={isInitialDeclarationComplete}
        >
          {t("declaration.declare_initial_meter_reading")}
        </StepButton>
      ),
      panel: <DeclarationForm variant="initial" onCancel={handleCancel} />,
      infoPanel: (
        <InfoPanel
          actions={
            declaration.meter.id
              ? [
                  {
                    label: t("meter.edit.title"),
                    action: () => {
                      window.open(
                        `/polestar/meters/${declaration.meter.id}/edit`
                      );
                    },
                  },
                ]
              : []
          }
        >
          <div className="space-y-6">
            <p>{t("declaration.info.is_estimated")}</p>
            <p>{t("declaration.info.initial_reading")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={7}
          currentStep={currentStep}
          info={<StepInfo data={getEvidencesInfo().body} />}
          isOnChain={isInitialDeclarationComplete}
        >
          {t("common.enter_evidence")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          onNext={stepHelpers.goToNextStep}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("declaration.info.evidence")}</p>
            <p>{t("evidence.supported")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={8}
          currentStep={currentStep}
          isOnChain={isInitialDeclarationComplete}
        >
          {t("declaration.form.confirm_initial_read")}
        </StepButton>
      ),
      panel: (
        <ConfirmDeclaration
          title={t("declaration.form.confirm_initial_read")}
          onCancel={handleCancel}
          onSubmit={handleCreateInitialDeclaration}
          variant="initial"
          workflowInstanceIds={[
            finalDeclarationWorkflow?.id,
            replaceMeterWorkflowInstance?.id,
            initialDeclarationWorkflow?.id,
          ]}
        />
      ),
      infoPanel: (
        <InfoPanel
          successes={
            isInitialDeclarationComplete
              ? [
                  t("declaration.info.success", {
                    serialNo: declaration?.meter?.serialNo,
                    reading: declaration?.reading,
                  }),
                ]
              : [t("declaration.info.confirm")]
          }
        >
          {isInitialDeclarationComplete ? (
            t("user.info.end_workflow")
          ) : (
            <ul className="space-y-4">
              <li>{t("common.info.verify")}</li>
              <li>
                <strong>{t("declaration.meter_read_details")}</strong>{" "}
                {t("declaration.info.verify.details")}
              </li>
              <li>
                <strong>{t("evidence.supporting")}:</strong>{" "}
                {t("evidence.info.verify")}
              </li>
              <li>{t("common.info.changes")}</li>
            </ul>
          )}
        </InfoPanel>
      ),
    },
  ];

  return <WorkflowPanels selectedStep={currentStep} steps={steps} />;
};

export default ReplaceMeterWorkflow;

import Legend from "@components/form/Legend";
import Alert from "@components/shared/Alert";
import SelectSingleExtractionPointForZenith from "@components/table/SelectSingleExtractionPointTableForZenith";
import { useAdminTransferContext } from "@context/AdminTransferContext";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type WorkflowCreateAdminApprovalStep2Props = {
  onSubmit: () => void;
  onCancel: () => void;
};

const WorkflowCreateAdminApprovalStep2: FunctionComponent<
  WorkflowCreateAdminApprovalStep2Props
> = ({ onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { details, handleChangeDetails, existsRelationship } =
    useAdminTransferContext();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form className="flex flex-col grow" onSubmit={handleSubmit}>
      <fieldset className="w-full flex flex-col grow gap-2">
        <Legend>
          {t("approval.admin_transfer.create.step_4.select_point")}
        </Legend>
        {existsRelationship && (
          <>
            <Alert type="error">
              {t("approval.admin_transfer.create.step_4.error_1")}
            </Alert>
            <Alert type="warning">
              {t("approval.admin_transfer.create.step_4.error_1_warn")}
            </Alert>
          </>
        )}
        <SelectSingleExtractionPointForZenith
          definedByWalletId={details.subscriber.walletId}
          subscriberId={details.subscriber.id}
          onSelect={(item: any) => handleChangeDetails("extractionPoint", item)}
          selected={details.extractionPoint}
        />
      </fieldset>
      <footer className="p-4 flex gap-2">
        <button
          type="button"
          className="btn-outline-primary text-sm font-semibold"
          onClick={onCancel}
        >
          {t("common.prev_step")}
        </button>
        <button
          type="submit"
          className="btn-primary text-sm font-semibold"
          disabled={!details?.extractionPoint?.id || existsRelationship}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-default text-sm font-semibold"
          onClick={() => navigate("/zenith/workflows")}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default WorkflowCreateAdminApprovalStep2;

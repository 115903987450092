import { useMutation } from "@tanstack/react-query";

import { checkName } from "@services/level1Resource";

export function useValidateLevel1ResourceName(options: any = {}) {
  return useMutation<any, any, { name: string }>({
    mutationKey: ["check_level_1_resource_name"],
    mutationFn: checkName,
    ...options,
  });
}

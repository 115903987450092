import classNames from "classnames";
import { t } from "i18next";

import EditIcon from "@components/icons/EditIcon";

type Props = {
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
};

const EditButton: React.FunctionComponent<Props> = ({
  children = t("common.edit"),
  onClick,
  className,
}) => {
  return (
    <button
      type="button"
      className={classNames("font-bold text-primary-2", className)}
      onClick={onClick}
    >
      <EditIcon className="inline-block mr-2 items align-baseline" />
      <span className="inline-block underline">{children}</span>
    </button>
  );
};

export default EditButton;

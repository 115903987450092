type Props = {
  className?: string;
};

const EditIcon: React.FunctionComponent<Props> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12"
      fill="none"
      viewBox="0 0 13 12"
      className={className}
    >
      <path
        fill="currentColor"
        d="M9.549.548a1.7 1.7 0 012.404 2.404l-.674.674-2.404-2.404.674-.674zM7.673 2.424L.55 9.546v2.404h2.404l7.122-7.122-2.404-2.404z"
      ></path>
    </svg>
  );
};

export default EditIcon;

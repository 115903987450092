import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { t } from "i18next";

import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";

import BaseSignUpForm from "./BaseSignUpForm";

const schema = z.object({
  street: z.string().optional(),
  town: z.string().optional(),
  region: z.string().optional(),
  postcode: z.string().optional(),
  country: z.string().optional(),
});

type FormValues = z.infer<typeof schema>;

const defaultValues: FormValues = {
  street: "",
  town: "",
  region: "",
  postcode: "",
  country: "",
};

type SignUpAddressFormProps = {
  onSubmit: (values: FormValues) => void;
  values: FormValues;
};

const SignUpAddressForm: React.FunctionComponent<SignUpAddressFormProps> = ({
  onSubmit,
  values,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: Object.assign({}, defaultValues, values),
  });

  return (
    <BaseSignUpForm
      title={t("zenith.signup.enter_address")}
      step={1}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    >
      <div>
        <Label htmlFor="street" optional>
          {t("subscriber.create.subscriber_street")}
        </Label>
        <TextInput
          {...register("street")}
          id="street"
          errorMessage={errors?.street?.message as any}
        />
      </div>
      <div>
        <Label htmlFor="town" optional>
          {t("subscriber.create.subscriber_town")}
        </Label>
        <TextInput
          {...register("town")}
          id="town"
          errorMessage={errors?.town?.message as any}
        />
      </div>
      <div>
        <Label htmlFor="region" optional>
          {t("subscriber.create.subscriber_region")}
        </Label>
        <TextInput
          {...register("region")}
          id="region"
          errorMessage={errors?.region?.message as any}
        />
      </div>
      <div>
        <Label htmlFor="postcode" optional>
          {t("subscriber.create.subscriber_postcode")}
        </Label>
        <TextInput
          {...register("postcode")}
          id="postcode"
          errorMessage={errors?.postcode?.message as any}
        />
      </div>
    </BaseSignUpForm>
  );
};

export default SignUpAddressForm;

import { toggleFavoriteWorkflow } from "@services/workflow";
import { useMutation } from "@tanstack/react-query";

export function useToggleFavoriteWorkflow(options: any = {}) {
  return useMutation<any, any, any>({
    mutationKey: ["toggle_favorite_workflow"],
    mutationFn: toggleFavoriteWorkflow,
    ...options,
  });
}

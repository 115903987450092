import { useTranslation } from "react-i18next";

import Modal from "@components/layout/Modal";
import { formatPercentage } from "@utils/formatPercentage";
import { formatDate } from "@utils/formatDate";
import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";

type Props = {
  data?: any;
  onClose: () => void;
  showSpecialAction?: boolean;
};
const ExchangeRateModal: React.FunctionComponent<Props> = ({
  data,
  onClose,
  showSpecialAction = true,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal open={data}>
      <div className="flex flex-col">
        <div className="border-b p-4">
          <h3 className="text-lg font-bold leading-6 text-gray-900 capitalize">
            {t("exchange_rate.modal.title")}
          </h3>
        </div>
        <div className="p-4">
          <div className="border rounded-lg p-2 flex flex-col gap-3">
            <h3 className="font-semibold">
              {t("exchange_rate.modal.details_1_title")}
            </h3>
            <dl className="grid grid-cols-2 gap-x-4 gap-y-2 sm:grid-cols-1 mb-4">
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt>{t("common.level0wrs")}:</dt>
                <dd className="text-gray-500">
                  {data?.waterClass?.level0Resource?.identifier}
                </dd>
              </div>
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt>{t("water_class.name")}:</dt>
                <dd className="text-gray-500">{data?.waterClass?.name}</dd>
              </div>
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt>{t("common.allocation")}:</dt>
                <dd className="text-gray-500">
                  {formatPercentage(data?.rate)}
                </dd>
              </div>
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt>{t("common.startAt")}:</dt>
                <dd className="text-gray-500">
                  {formatDate(new Date(data?.startAt))}
                </dd>
              </div>
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt>{t("common.accounting_period")}:</dt>
                <dd className="text-gray-500">
                  {formatAccountingPeriod(data?.accountingPeriod)}
                </dd>
              </div>
            </dl>
          </div>
        </div>

        <div className="mt-5 border-t p-4 flex flex-row-reverse gap-3 text-sm">
          <button onClick={onClose} className="btn-primary">
            {t("common.cancel")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ExchangeRateModal;

import { getSubscriberUsers } from "@services/subscriber";
import { useQuery } from "@tanstack/react-query";

export const useSubscriberUsers = ({ ...options } = {}) => {
  return useQuery<any>({
    queryKey: ["subscriberUsers"],
    queryFn: () => getSubscriberUsers(),
    ...options,
  });
};

import React from "react";
import { sumBy } from "lodash";
import { useTranslation } from "react-i18next";

import Heading1 from "@components/layout/Heading";
import { useAmalgamateOrSubdivideExtractionRightsContext } from "@context/AmalgamateOrSubdivideExtractionRightContext";
import SelectMultipleExtractionRightTable from "@components/table/SelectMultipleExtractionRightTable";
import SelectSingleExtractionRightTable from "@components/table/SelectSingleExtractionRightTable";
import ConfirmModal from "@components/shared/ConfirmModal";
import { useRequestAmalgamationOrSubdivision } from "@hooks/mutation/useRequestAmalgamation";
import {
  AdministrativeApprovalType,
  ExtractionRightApprovalType,
} from "@services/administrativeApprovals";

const AmalgamateOrSubdivideSelectExtractionRights = () => {
  const { t } = useTranslation();
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const {
    stepHelpers,
    handleChangeDetails,
    details,
    navigateForCancel,
    validateAmalgamation,
    context,
    modifyStatuses,
    getInfoMessages,
    inputExtractionRights,
    setInputExtractionRights,
  } = useAmalgamateOrSubdivideExtractionRightsContext();

  const { mutateAsync: requestAmalgamationMutation, isLoading } =
    useRequestAmalgamationOrSubdivision();

  const handleOnConfirm = async () => {
    try {
      const administrativeApproval = await requestAmalgamationMutation({
        level1ResourceId: details.level1wrs?.id,
        extractionRightIds: inputExtractionRights.map((er) => er.id),
        level0ResourceId: inputExtractionRights[0]?.level0ResourceId,
        waterClassId: inputExtractionRights[0]?.waterClassId,
        buyerId: details.subscriber.id,
        volume: sumBy(inputExtractionRights, "volume"),
        type:
          context === ExtractionRightApprovalType.Amalgamate
            ? AdministrativeApprovalType.AME
            : AdministrativeApprovalType.SDE,
      });
      handleChangeDetails("id", administrativeApproval.id);
    } catch (error) {}
    stepHelpers.goToNextStep();
  };

  return (
    <>
      <div className="flex flex-col gap-6 p-6 grow justify-between">
        <div className="space-y-4">
          <Heading1 light>
            {t("approval.admin_transfer.create.step_3.select_right")}
          </Heading1>
        </div>
        {context === "amalgamate" ? (
          <SelectMultipleExtractionRightTable
            onSelect={(selectedRights: any) => {
              setInputExtractionRights(selectedRights);
              validateAmalgamation(selectedRights);
            }}
            subscriber={details.subscriber}
            selectedKeys={inputExtractionRights.map((er) => er.id)}
          />
        ) : (
          <SelectSingleExtractionRightTable
            onSelect={(selectedRight: any) => {
              setInputExtractionRights([selectedRight]);
            }}
            level1ResourceId={details.level1wrs?.id}
            subscriberId={details.subscriber?.id}
            selected={inputExtractionRights[0] ?? []}
          />
        )}

        <footer className="flex gap-4 -mx-6 mt-2 p-6 pb-0 border-t border-gray-200">
          <button
            type="button"
            className="btn-outline-primary"
            onClick={() => {
              modifyStatuses({
                stepNumber: 2,
                fieldName: "input_extraction_rights",
              });
              stepHelpers.goToPrevStep();
            }}
          >
            {t("common.prev_step")}
          </button>
          <button
            type="button"
            className="btn-primary"
            onClick={() => setShowConfirmModal(true)}
            disabled={
              inputExtractionRights.length === 0 ||
              getInfoMessages("error", 2).length > 0
            }
          >
            {t("common.next_step")}
          </button>
          <button
            type="button"
            className="btn-outline-primary"
            onClick={navigateForCancel}
          >
            {t("common.cancel")}
          </button>
        </footer>
      </div>

      <ConfirmModal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleOnConfirm}
        isSubmitting={isLoading}
      >
        {t(
          "approval.subdivide_and_amalgamate.create.step_3.confirm_modal.title",
          { context }
        )}
      </ConfirmModal>
    </>
  );
};

export default AmalgamateOrSubdivideSelectExtractionRights;

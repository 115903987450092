import { useState } from "react";
import Modal from "@components/layout/Modal";
import { useTranslation } from "react-i18next";

import ModalInformationIcon from "@components/icons/ModalInformationIcon";
import Heading1 from "@components/layout/Heading";

type ExtractionPointLinkToRightDecisionModalProps = {
  open: boolean;
  onClose: () => void;
  onConfirmOption: (optionChosen: string) => void;
};

const ExtractionPointLinkToRightDecisionModal: React.FunctionComponent<
  ExtractionPointLinkToRightDecisionModalProps
> = ({ onClose, open, onConfirmOption }) => {
  const { t } = useTranslation();
  const [chosenOption, setChosenOption] = useState("");
  const decisionTypes = [
    {
      label: t(
        "extraction_point.link.modal.decision_alternative_extraction_point"
      ),
      value: "0",
    },
    {
      label: t(
        "extraction_point.link.modal.decision_redefine_extraction_point"
      ),
      value: "1",
    },
    {
      label: t("extraction_point.link.modal.decision_seek_approval"),
      value: "2",
    },
  ];

  return (
    <Modal open={open}>
      <div className="flex justify-between items-start gap-3 p-6 pb-10">
        <ModalInformationIcon className="w-12 h-12" />
        <div className="flex-1">
          <Heading1 className="text-lg font-bold">
            {t("extraction_point.link.modal.decision_confirmation")}
          </Heading1>
          <div className="flex flex-col gap-3 text-gray-700 mt-5">
            <div>
              {decisionTypes.map((type) => (
                <div
                  key={type.value}
                  className="relative flex items-start mb-2"
                >
                  <div className="flex h-5 items-center">
                    <input
                      id={`report-export-type-${type.value}`}
                      name="report-export-type"
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      value={type.value}
                      onChange={(e) => {
                        if (e.target.checked) setChosenOption(type.value);
                      }}
                      checked={chosenOption === type.value}
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-gray-700">{type.label}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <footer className="flex flex-row justify-end gap-3 px-6 py-4 border-t border-gray-200">
        <button
          className="btn-default py-1 rounded-sm"
          onClick={() => {
            setChosenOption("");
            onClose();
          }}
        >
          {t("common.cancel")}
        </button>
        <button
          className="btn-secondary py-1 rounded-sm"
          onClick={() => onConfirmOption(chosenOption)}
          disabled={chosenOption === ""}
        >
          {t("common.confirm")}
        </button>
      </footer>
    </Modal>
  );
};

export default ExtractionPointLinkToRightDecisionModal;

import { useTranslation } from "react-i18next";
import Card from "@components/layout/Card";
import Tag from "@components/shared/Tag";
import { formatDate } from "@utils/formatDate";
import { formatVolume } from "@utils/formatVolume";
import classNames from "classnames";
import { orderBy, sumBy } from "lodash";
import { Link } from "react-router-dom";
import Loading from "@components/shared/Loading";
import { useSubscriberLevel0Resources } from "@hooks/query/zenith/useSubscriberLevel0Resources";

const AccountBalancesWidget = () => {
  const { t } = useTranslation();
  const { data: level0Balances = [], isLoading } = useSubscriberLevel0Resources(
    {
      select: (res: any[] = []) => orderBy(res, ["identifier"], ["asc"]),
    },
  );

  if (isLoading) {
    return (
      <div className="pt-20">
        <Loading />
      </div>
    );
  }

  return (
    <Card
      className="p-6 col-span-3"
      header={
        <h2 className="flex gap-3 item-centers text-inherit">
          {t("subscriber.level0wrs_volumes")}
        </h2>
      }
    >
      {level0Balances.length <= 0 && (
        <h4 className="font-light text-primary-2 text-center text-xl my-20">
          {t("level0wrs.no_data")}
        </h4>
      )}

      <div className="flex flex-row gap-4 overflow-scroll scrollbar-hide">
        {level0Balances.map((level0: any) => {
          const totalBalance =
            sumBy(level0?.waterClasses, (i: any) => +i.balance) -
            level0.overusedBalance;
          const hasWaterClass = level0?.waterClasses?.length > 0;

          return (
            <div
              key={level0.id}
              className={classNames(
                "flex flex-col items-start p-4 rounded-lg border shrink-0",
                {
                  "w-48": !hasWaterClass,
                },
              )}
            >
              <header className="flex-1 mb-2">
                <div className="text-xs text-gray-500">
                  {t("common.level0wrs")}
                </div>
                <div className="text-xl">{level0.identifier}</div>
              </header>
              <div className="pb-12 flex flex-row gap-4">
                <div>
                  <h6 className="mb-2 text-xs text-gray-500">
                    {t("common.status")}
                  </h6>
                  <Tag status="success">{t("common.active")}</Tag>
                </div>
                <div>
                  <h6 className="mb-2 text-xs text-gray-500">
                    {t("subscriber.volume_remain", {
                      today: formatDate(new Date()),
                    })}
                  </h6>
                  <span
                    className={classNames(
                      totalBalance < 0 ? "text-red-500" : "text-primary-3",
                    )}
                  >
                    {formatVolume(totalBalance)}
                  </span>
                </div>
              </div>
              <Link
                className="btn-secondary px-3 py-1.5 text-xs rounded"
                to={`./zone_accounts/${level0.id}`}
              >
                {t("common.view")}
              </Link>
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export default AccountBalancesWidget;

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Select from "react-select";

import ExternalLinkIcon from "@components/icons/ExternalLinkIcon";
import Table from "@components/layout/Table";
import { useAllExtractionPoints } from "@hooks/query/useAllExtractionPoints";
import { useState } from "react";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import SelectLevel1Resource from "@components/form/SelectLevel1Resource";
import SelectLevel0Resource from "@components/form/SelectLevel0Resource";
import ExtractionPointStatusTags from "@components/shared/ExtractionPointStatusTags";

type ExtractionPoint = Record<string, any>;

type SelectSingleExtractionPointTableProps = {
  level1ResourceId?: string;
  definedByWalletId?: string;
  onSelect: (item: ExtractionPoint) => void;
  selected?: ExtractionPoint;
  onFetchSuccess?: (items: ExtractionPoint[]) => void;
};

const SelectSingleExtractionPoint: React.FunctionComponent<
  SelectSingleExtractionPointTableProps
> = ({
  level1ResourceId,
  definedByWalletId,
  onSelect,
  selected,
  onFetchSuccess,
}) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<{
    extractionPointName: string;
    status?: boolean;
    level0ResourceId?: string;
    level1ResourceId?: string;
  }>({ extractionPointName: "", level0ResourceId: "", level1ResourceId: "" });
  const { data: extractionPoints, isLoading } = useAllExtractionPoints({
    params: { level1ResourceId, definedByWalletId },
    options: {
      onSuccess: (data: any) => {
        onFetchSuccess && onFetchSuccess(data);
      },
    },
  });

  const tableFields = [
    {
      title: "",
      name: "radio",
    },
    {
      title: t("extraction_point.name"),
      name: "name",
    },
    {
      title: t("common.level0wrs"),
      name: "level0wrs",
    },
    {
      title: t("common.status"),
      name: "status",
    },
    {
      title: "",
      name: "view",
    },
  ];

  const tableData = extractionPoints
    ?.filter((extractionPoint: any) => {
      const matcher = filter.extractionPointName.toLowerCase();
      return extractionPoint?.name?.toLowerCase()?.includes(matcher);
    })
    ?.filter(
      (row: { isActive: boolean }) =>
        filter.status === undefined || row.isActive === filter.status
    )
    ?.filter(
      (row: any) =>
        !filter?.level0ResourceId ||
        row.level0ResourceId === filter?.level0ResourceId
    )
    ?.filter(
      (row: any) =>
        !filter?.level1ResourceId ||
        row.level0WRS?.parentId === filter?.level1ResourceId
    )
    .map((extractionPoint: any) => {
      return {
        id: extractionPoint.id,
        radio: (
          <input
            type="radio"
            name="level1wrs_id"
            checked={selected?.id === extractionPoint?.id}
            onChange={() => onSelect(extractionPoint)}
          />
        ),
        name: extractionPoint?.name,
        level0wrs: extractionPoint?.level0WRS?.identifier,
        status: <ExtractionPointStatusTags point={extractionPoint} />,
        view: (
          <Link
            to={`/polestar/subscribers/${extractionPoint.subscriber.id}?level0ResourceId=${extractionPoint?.level0WRS?.id}&extractionPointId=${extractionPoint?.id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="btn-default flex items-center gap-3 w-min"
          >
            <ExternalLinkIcon className="w-4 h-4" />
            {t("common.view")}
          </Link>
        ),
      };
    });

  const handleFilterChange = (field: string, value: any) => {
    if (field === "level1ResourceId") {
      setFilter({
        ...filter,
        level1ResourceId: value,
        level0ResourceId: "",
      });
    } else {
      setFilter({
        ...filter,
        [field]: value,
      });
    }
  };

  const activeOptions = [
    { label: t("common.active"), value: true },
    { label: t("common.inactive"), value: false },
  ];

  return (
    <form className="pb-0 flex flex-col gap-4 grow">
      <fieldset className="grid grid-cols-1 md:grid-cols-3 gap-4 ">
        <div className="w-full">
          <Label htmlFor="extractionPointId">
            {t("extraction_point.filter_name")}
          </Label>
          <SearchInput
            id="extractionPointId"
            onChange={(e) =>
              handleFilterChange("extractionPointName", e.target.value)
            }
          />
        </div>
        <div className="w-full">
          <Label htmlFor="status">
            {t("subscriber.filter_subscriber_status")}
          </Label>
          <Select
            id="status"
            options={activeOptions}
            value={activeOptions.find(
              (activeOption) => activeOption.value === filter.status
            )}
            onChange={(e) => handleFilterChange("status", e?.value)}
            isClearable
          />
        </div>

        <div className="w-full">
          <Label htmlFor="level1wrs">{t("level1wrs.select")}</Label>
          <SelectLevel1Resource
            onChange={(selected) => {
              handleFilterChange("level1ResourceId", selected?.value || "");
            }}
            value={level1ResourceId}
            isDisabled={Boolean(level1ResourceId)}
            isClearable
          />
        </div>

        {level1ResourceId || filter.level1ResourceId ? (
          <div className="w-full">
            <Label htmlFor="level0wrs">{t("level0wrs.filter_level0wrs")}</Label>
            <SelectLevel0Resource
              level1ResourceId={level1ResourceId || filter.level1ResourceId}
              isClearable
              onChange={(e) => {
                handleFilterChange("level0ResourceId", e?.value || "");
              }}
              value={filter?.level0ResourceId}
            />
          </div>
        ) : (
          <></>
        )}
      </fieldset>
      <div className="pb-0 flex flex-col gap-4 grow">
        <Table
          fields={tableFields}
          data={tableData}
          stickyHeader
          loading={isLoading}
          noRowsText={t("subscriber.has_no_extraction_points")}
        />
      </div>
    </form>
  );
};

export default SelectSingleExtractionPoint;

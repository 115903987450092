import { useQuery } from "@tanstack/react-query";
import { getAllUsages } from "@services/zenith/rightFractionHistory";

export const useAllRightFractionUsageHistories = ({
  params,
  ...options
}: {
  params?: Record<string, any>;
  [key: string]: any;
} = {}) => {
  return useQuery<any>({
    queryKey: ["right_fraction_usage_histories", params],
    queryFn: () => getAllUsages(params),
    ...options,
  });
};

import { getAllAdministrativeApprovals } from "@services/administrativeApprovals";
import { useQuery } from "@tanstack/react-query";
import { formatDate } from "@utils/formatDate";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../../../../context/AppContext";
import DocumentIcon from "../../../icons/DocumentIcon";
import Card from "../../../layout/Card";
import Table from "../../../layout/Table";

const PendingApplicationWidget: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { checkPermissions } = useAppContext();
  const [approvals, setApprovals] = useState<any[]>([]);
  const { isLoading } = useQuery(
    ["exhangeApprovals"],
    () => getAllAdministrativeApprovals(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      onSuccess: (data) => {
        const latestSeasonalTransfer = data
          .filter((t: any) => t.type === 2)
          .sort(
            (a: any, b: any) =>
              new Date(a.lodgedAt).valueOf() - new Date(b.lodgedAt).valueOf()
          )?.[0];

        const latestPermanentTransfer = data
          .filter((t: any) => t.type === 1)
          .sort(
            (a: any, b: any) =>
              new Date(a.lodgedAt).valueOf() - new Date(b.lodgedAt).valueOf()
          )?.[0];

        setApprovals([
          {
            ...latestSeasonalTransfer,
            approvalTitle: t("approval.seasonal_water_assignments.title"),
          },
          {
            ...latestPermanentTransfer,
            approvalTitle: t("approval.permanent_trades.title"),
          },
        ]);
      },
    }
  );

  if (!checkPermissions(["ViewApprovals"])) return null;

  return (
    <Card
      className="bg-inherit border-none p-0"
      header={
        <p className="flex gap-3 h-8 text-lg">
          <DocumentIcon /> {t("approval.widget_title")}
        </p>
      }
    >
      <Table
        className="text-xs rounded-lg"
        containerClassName="border border-gray-400 border-2 shadow-xl md:rounded-xl rounded-lg h-46"
        paginationClassName="bg-inherit"
        fields={[
          {
            title: t("approval.application"),
            name: "approvalTitle",
          },
          {
            title: t("approval.applied_at"),
            name: "createdAt",
          },
          {
            title: t("common.action"),
            name: "action",
          },
        ]}
        data={approvals?.map((app) => ({
          ...app,
          approvalTitle: (
            <div className="overflow-hidden whitespace-normal line-clamp-2">
              {app.approvalTitle}
            </div>
          ),
          createdAt: formatDate(new Date(app.lodgedAt)),
          action: (
            <div className="flex gap-1 items-center w-full">
              <Link
                to={`/polestar/administrative_approvals#tab=${
                  app.type === 1 && 1
                }`}
                className="btn-primary py-2 px-10"
              >
                {t("common.view")}
              </Link>
            </div>
          ),
        }))}
        noRowsText={
          isLoading
            ? t("approval.application_loading")
            : t("approval.no_applications")
        }
        pageSize={2}
      />
    </Card>
  );
};

export default PendingApplicationWidget;

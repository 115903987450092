import React from "react";

const UserCircleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <rect width="30" height="30" fill="#004A88" rx="15"></rect>
      <path
        fill="#fff"
        d="M10 21s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H10zM15 15a3 3 0 100-6 3 3 0 000 6z"
      ></path>
    </svg>
  );
};

export default UserCircleIcon;

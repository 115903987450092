import { useMutation } from "@tanstack/react-query";
import {
  linkMeterToExtractionPoint,
  LinkMeterToExtractionPointPayload,
} from "@services/meter";

export const useLinkMeterToExtractionPoint = (options: any = {}) => {
  return useMutation<any, any, LinkMeterToExtractionPointPayload>({
    mutationKey: ["link_meter_to_extraction_point"],
    mutationFn: linkMeterToExtractionPoint,
    ...options,
  });
};

import { useQuery } from "@tanstack/react-query";
import { getAllMeters } from "@services/meter";

type UseAllMetersParams = {
  params?: Record<string, any>;
  options?: Record<string, any>;
};

export const useAllMeters = ({ params, options }: UseAllMetersParams = {}) => {
  return useQuery<any>({
    queryKey: ["meters", params],
    queryFn: () => getAllMeters(params),
    ...options,
  });
};

import Label from "@components/form/Label";
import ENV from "@config/env";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { useTranslation } from "react-i18next";
import { useLoadScript } from "@react-google-maps/api";

type SearchLocationInputProps = {
  locationCoordinates: { lat: string; lng: string };
  setLocationCoordinates: (location: { lat: string; lng: string }) => void;
};

const SearchLocationInput: React.FunctionComponent<
  SearchLocationInputProps
> = ({ setLocationCoordinates, locationCoordinates }) => {
  const { t } = useTranslation();
  const handleAddress = (label: string) => {
    geocodeByAddress(label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setLocationCoordinates({ lat: lat.toString(), lng: lng.toString() });
      });
  };
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${ENV.GOOGLE_MAPS_API_KEY}`,
    libraries: ["places"],
  });
  return (
    <div className="space-y-4 mt-4">
      <div>
        <Label className="my-2">{t("shared.map.ask_search")}</Label>

        {isLoaded && (
          <GooglePlacesAutocomplete
            apiKey={ENV.GOOGLE_MAPS_API_KEY}
            selectProps={{
              placeholder: "Address",
              name: "address",
              className: "max-w-md mt-2",
              onChange: (place) => {
                handleAddress(place.label);
              },
              isOptionSelected(option, selectValue) {
                return option === selectValue;
              },
            }}
          />
        )}
      </div>
      {locationCoordinates.lat && locationCoordinates.lng && (
        <div className="space-y-4">
          <ul className="mt-2 space-y-2 text-gray-600 text-sm">
            <li>
              <strong>{t("common.latitude")}:</strong> {locationCoordinates.lat}
            </li>
            <li>
              <strong>{t("common.longitude")}:</strong>{" "}
              {locationCoordinates.lng}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchLocationInput;

import { useParams } from "react-router-dom";
import { t } from "i18next";

import Layout from "@components/layout/Layout";
import UpdateSubscriberWorkflow from "@components/form/subscriber/update/UpdateSubscriberWorkflow";
import { useSubscriber } from "@hooks/query/useSubscriber";

const SubscriberUpdate = () => {
  const { id: subscriberId = "" } = useParams();
  const { data: subscriber } = useSubscriber(subscriberId);

  return (
    <Layout
      permissions={["UpdateSubscribers"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("common.subscribers"),
          href: "/polestar/subscribers",
        },
        {
          label: `${subscriber?.name}`,
          href: `/polestar/subscribers/${subscriber?.id}`,
        },
        {
          label: t("subscriber.edit.title"),
        },
      ]}
      title={t("subscriber.edit.title")}
      accountingPeriodLevel1ResourceId={subscriber?.level1ResourceId}
    >
      <UpdateSubscriberWorkflow />
    </Layout>
  );
};

export default SubscriberUpdate;

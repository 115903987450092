import { useMutation } from "@tanstack/react-query";

import { createUnmeteredUsage } from "@services/declarations";

export const useCreateUnmeteredUsage = (options: Record<string, any> = {}) => {
  return useMutation({
    mutationFn: createUnmeteredUsage,
    ...options,
  });
};

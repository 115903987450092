import { ReactNode, useEffect, useState } from "react";
import classNames from "classnames";
import Loading from "@components/shared/Loading";

type ModalProps = {
  open: boolean;
  children: ReactNode;
  className?: string;
  isLoading?: boolean;
};

const Modal: React.FunctionComponent<ModalProps> = ({
  open,
  children,
  className,
  isLoading = false,
}) => {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  if (!open) return null;

  return (
    <div
      className="relative z-[60]"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className={classNames(
          "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity backdrop-blur-sm",
          isOpen
            ? "ease-out duration-300 opacity-100"
            : "ease-in duration-200 opacity-0",
        )}
      />

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center text-center sm:items-center">
          <div
            className={classNames(
              "relative transform overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl",
              isOpen
                ? "ease-out duration-300 opacity-100 translate-y-0 sm:scale-100"
                : "ease-in duration-200 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
              className,
            )}
          >
            {isLoading ? (
              <div className="grid place-items-center py-20">
                <Loading />
              </div>
            ) : (
              children
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;

import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";

import Layout from "@components/layout/Layout";
import Modal from "@components/layout/Modal";
import DropzoneIcon from "@components/icons/DropzoneIcon";
import { createEvidence } from "@services/evidence";
import ModalSuccessIcon from "@components/icons/ModalSuccessIcon";
import ProcessingIcon from "@components/icons/ProcessingIcon";
import { useSubscriber } from "@hooks/query/useSubscriber";
import { useLevel0Resource } from "@hooks/query/useLevel0Resource";
import ExtractionPointLinkToRightStep1Label from "@components/form/subscriber/dashboard/level0accounts/link_to_rights/ExtractionPointLinkToRightStep1Label";
import ExtractionPointLinkToRightStep2 from "@components/form/subscriber/dashboard/level0accounts/link_to_rights/ExtractionPointLinkToRightStep2";
import ExtractionPointLinkToRightStep3Label from "@components/form/subscriber/dashboard/level0accounts/link_to_rights/ExtractionPointLinkToRightStep3Label";
import ExtractionPointLinkToRightStep3 from "@components/form/subscriber/dashboard/level0accounts/link_to_rights/ExtractionPointLinkToRightStep3";
import WorkflowPanels from "@components/form/WorkflowPanels";
import Heading1 from "@components/layout/Heading";
import ExtractionPointLinkToRightStep1 from "@components/form/subscriber/dashboard/level0accounts/link_to_rights/ExtractionPointLinkToRightStep1";
import {
  getExtractionPointById,
  linkToExtractionRights,
} from "@services/extractionPoints";
import ConfirmModal from "@components/shared/ConfirmModal";
import ExtractionPointLinkToRightStep2Label from "@components/form/subscriber/dashboard/level0accounts/link_to_rights/ExtractionPointLinkToRightStep2Label";
import ExtractionPointLinkToRightDecisionModal from "@components/form/subscriber/dashboard/level0accounts/link_to_rights/ExtractionPointLinkToRightDecisionModal";
import UpdateExtractionPointSlideOver from "@components/form/subscriber/dashboard/level0accounts/link_to_rights/UpdateExtractionPointSlideOver";
import { requestAdminTransfer } from "@services/administrativeApprovals";
import { useAllExtractionRights } from "@hooks/query/useAllExtractionRights";
import { toastError, toastSuccess } from "@utils/toast";
import { DBTables } from "@utils/constants/dbTables";

const LinkToExtractionRights: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { id: subscriberId, level0ResourceId } = useParams();
  const { data: subscriber } = useSubscriber(subscriberId ?? "");
  const { data: level0Resource } = useLevel0Resource(level0ResourceId ?? "");
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [
    isAdministrativeApprovalConfirmModalOpen,
    setIsAdministrativeApprovalConfirmModalOpen,
  ] = useState(false);
  const [isDecisionModalOpen, setIsDecisionModalOpen] = useState(false);
  const [selectedExtractionRight, setSelectedExtractionRight] =
    useState<Record<string, any>>();
  const [checkedExtractionPoint, setCheckedExtractionPoint] = useState<string>(
    searchParams.get("extractionPointId") ?? ""
  );
  const [showUpdateExtractionPoint, setShowUpdateExtractionPoint] =
    useState(false);

  const { data: extractionPoint, refetch: refetchExtractionPoint } = useQuery(
    ["extractionPoint", checkedExtractionPoint],
    () => getExtractionPointById(checkedExtractionPoint),
    { enabled: !!checkedExtractionPoint }
  );

  const { data: extractionRights = [], refetch: refetchExtractionRights } =
    useAllExtractionRights({
      params: {
        level0ResourceId: level0Resource?.id,
        subscriberIds: subscriberId ? [subscriberId] : undefined,
        isActive: true,
      },
      options: {
        enabled: !!level0Resource,
      },
    });

  const { mutateAsync: linkExtractionPointToExtractionRights, isLoading } =
    useMutation<
      any,
      any,
      {
        extractionRightIds: string[];
        extractionPointId: string;
      }
    >((linkToExtractionRightsData) =>
      linkToExtractionRights(linkToExtractionRightsData)
    );

  const {
    mutateAsync: createAdminTransferMutation,
    isLoading: isAdminTransferCreationLoading,
  } = useMutation<
    any,
    any,
    {
      subscriberId: string;
      extractionPointId: string;
      extractionRightId: string;
    }
  >((adminTransferData) => requestAdminTransfer(adminTransferData));

  const [isUploadEvidenceOpen, setIsUploadEvidenceOpen] = useState(false);
  const [attachment, setAttachment] = useState<any>(undefined);

  const { mutateAsync: saveEvidence, isLoading: isLoadingEvidence } =
    useMutation<
      any,
      any,
      {
        title: string;
        description: string;
        references: Array<{
          referenceId: string;
          referenceTable: string;
        }>;
        isPublic: boolean;
        isEncrypted: boolean;
        attachment: File;
      }
    >((evidence) => createEvidence(evidence));

  const handleStep1Save = () => {
    setCurrentStep(1);
  };

  const handleStep2Save = () => {
    setCurrentStep(2);
  };

  const fallsUnderSameZone = (): boolean => {
    const pointZone = extractionPoint?.level0WRS.identifier;
    const rightZone = selectedExtractionRight?.level0Resource.identifier;
    return rightZone === pointZone;
  };

  const handleStep3Save = async () => {
    if (!fallsUnderSameZone()) {
      setIsDecisionModalOpen(true);
      return;
    }
    setIsConfirmationModalOpen(true);
  };

  const handleSave = async () => {
    try {
      await linkExtractionPointToExtractionRights({
        extractionPointId: checkedExtractionPoint,
        extractionRightIds: [selectedExtractionRight?.id],
      });

      toastSuccess(t("extraction_point.link.toast.success"));
      navigate(`/polestar/subscribers/${subscriberId}`);
    } catch (e: any) {
      const errors = e?.response?.data?.errors?.reduce(
        (accumulator: any, error: any) => {
          if (error.field) {
            accumulator.push(`${error.field}: ${error.message}`);
          }
          return accumulator;
        },
        []
      );
      toastError(
        <>
          <p>
            {t("extraction_point.link.toast.error", {
              error: e?.response?.data?.message || e?.message,
            })}
          </p>
          <ul>
            {errors?.map((error: any) => {
              return <li>{error}</li>;
            })}
          </ul>
        </>
      );
      setIsConfirmationModalOpen(false);
    }
  };

  const onDropZoneSaveEvidence = async (file: any) => {
    try {
      if (!file?.name) throw Error("File not supported");
      const evidence = await saveEvidence({
        title: `${file.name}`,
        description: `${t("subscriber.evidence_data.description", {
          name: subscriber.name,
        })}`,
        references: [
          {
            referenceId: `${subscriber.id}`,
            referenceTable: DBTables.Subscribers,
          },
        ],
        isPublic: false,
        isEncrypted: true,
        attachment: file,
      });

      setAttachment(evidence);
      toastSuccess(t("subscriber.toast.save_success_evidence"));
    } catch (e: any) {
      toastError(
        <>
          <p>{t("subscriber.toast.save_failure_evidence")}</p>
          <p>{e.message}</p>
        </>
      );
    }
  };

  const handleEvidenceUploadSave = async () => {
    setIsUploadEvidenceOpen(false);
    setCurrentStep(0);
  };

  return (
    <Layout
      permissions={["LinkExtractionPoint"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("common.subscribers"),
          href: "/polestar/subscribers",
        },
        {
          label: subscriber?.name,
          href: `/polestar/subscribers/${subscriberId}`,
        },
        {
          label: level0Resource?.identifier,
          href: `/polestar/subscribers/${subscriberId}?level0ResourceId=${level0Resource?.id}`,
        },
        {
          label: t("extraction_point.link.description"),
        },
      ]}
      title={t("extraction_point.link.description")}
    >
      <WorkflowPanels
        itemClassName="border-t"
        selectedStep={currentStep}
        steps={[
          {
            label: (
              <ExtractionPointLinkToRightStep1Label
                extractionPointName={extractionPoint?.name}
              />
            ),
            panel: (
              <ExtractionPointLinkToRightStep1
                onSave={handleStep1Save}
                definedByWalletId={subscriber?.walletId}
                level0ResourceId={level0Resource?.id}
                subscriberId={subscriber?.id}
                setCheckedExtractionPoint={setCheckedExtractionPoint}
                checkedExtractionPoint={checkedExtractionPoint}
              />
            ),
          },
          {
            label: (
              <ExtractionPointLinkToRightStep2Label
                isSelected={currentStep === 1}
                extractionRight={selectedExtractionRight}
              />
            ),
            panel: (
              <ExtractionPointLinkToRightStep2
                onSave={handleStep2Save}
                subscriberId={subscriber?.id}
                level0WRSId={level0Resource?.id}
                previousStep={() => {
                  setCurrentStep(0);
                }}
                extractionRights={extractionRights}
                setSelectedExtractionRight={setSelectedExtractionRight}
                selectedExtractionRight={selectedExtractionRight}
                extractionPoint={extractionPoint}
              />
            ),
          },
          {
            label: (
              <ExtractionPointLinkToRightStep3Label
                isSelected={currentStep === 2}
              />
            ),
            panel: (
              <ExtractionPointLinkToRightStep3
                onSave={handleStep3Save}
                onEditExtractionRight={() => {
                  setCurrentStep(1);
                }}
                onEditExtractionPoint={() => {
                  setCurrentStep(0);
                }}
                isSaveDisabled={isLoading}
                extractionRight={selectedExtractionRight}
                extractionPointName={extractionPoint?.name}
              />
            ),
          },
        ]}
      />
      <Modal open={isUploadEvidenceOpen}>
        <div className="pt-8 px-4 flex items-center gap-4">
          <div>
            <ModalSuccessIcon className="h-12 w-12" />
          </div>
          <Heading1
            className="text-lg font-medium leading-6 text-green-700"
            light
          >
            {t("subscriber.modal.evidence_title")}
          </Heading1>
        </div>
        <div className="p-4 items-center gap-4">
          {!attachment ? (
            <>
              <Dropzone
                multiple={false}
                onDrop={async ([file]: Array<any>) => {
                  onDropZoneSaveEvidence(file);
                }}
              >
                {({ getRootProps, getInputProps }: any) => (
                  <section className="bg-gray-100 p-10 text-center cursor-pointer border-dashed border-2">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="justify-center">
                        <>
                          <div className="flex justify-center">
                            <DropzoneIcon />
                          </div>
                          <div className="text-gray-600">
                            {t("common.upload_icon_message")}
                          </div>
                          {isLoadingEvidence ? (
                            <div className="mx-auto flex h-12 w-12">
                              <ProcessingIcon />
                            </div>
                          ) : (
                            <div>
                              <div className="text-gray-600 text-xs">
                                {t("subscriber.modal.upload_icon_sub_message")}
                              </div>
                            </div>
                          )}
                        </>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </>
          ) : (
            <div className="border p-2">
              <div className="pt-1">
                {t("common.file_uploaded.file")}{" "}
                <span className="font-mono text-gray-800 text-sm bg-gray-200 p-1">
                  {attachment.title}
                </span>{" "}
                {t("common.file_uploaded.uploaded")}
              </div>
            </div>
          )}
        </div>

        <div className="mt-5 border-t px-4 py-2 flex flex-row-reverse gap-4">
          <button
            type="button"
            className="btn-primary text-base font-medium sm:col-start-1 sm:text-sm"
            onClick={handleEvidenceUploadSave}
            disabled={isLoadingEvidence}
          >
            {t("common.go_to_customer_dashboard")}
          </button>
        </div>
      </Modal>
      <ConfirmModal
        open={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        onConfirm={handleSave}
        confirmText={t("common.continue") as string}
        isSubmitting={isLoading}
      >
        {t("extraction_point.link.modal.confirm")}
      </ConfirmModal>
      <ConfirmModal
        open={isAdministrativeApprovalConfirmModalOpen}
        onClose={() => {
          setIsAdministrativeApprovalConfirmModalOpen(false);
          navigate("/polestar/administrative_approvals#tab=2");
        }}
        onConfirm={() => navigate("/polestar")}
        cancelText={
          t("extraction_point.link.modal.confirmation_go_transfer") as string
        }
        isSubmitting={isAdminTransferCreationLoading}
        confirmText={
          t("extraction_point.link.modal.confirmation_go_dashboard") as string
        }
      >
        {t("extraction_point.link.modal.confirm_seek_approval")}
      </ConfirmModal>
      <ExtractionPointLinkToRightDecisionModal
        open={isDecisionModalOpen}
        onConfirmOption={(optionChosen: string) => {
          setIsDecisionModalOpen(false);
          switch (optionChosen) {
            case "0":
              setCurrentStep(0);
              break;
            case "1":
              setShowUpdateExtractionPoint(true);
              break;
            case "2":
              createAdminTransferMutation({
                subscriberId: subscriber.id,
                extractionPointId: extractionPoint.id,
                extractionRightId: selectedExtractionRight?.id,
              });
              setIsAdministrativeApprovalConfirmModalOpen(true);
              break;
            default:
              break;
          }
        }}
        onClose={() => setIsDecisionModalOpen(false)}
      />
      <UpdateExtractionPointSlideOver
        open={showUpdateExtractionPoint}
        onClose={() => {
          setShowUpdateExtractionPoint(false);
        }}
        onSuccess={() => {
          setCurrentStep(0);
          setSelectedExtractionRight(undefined);
          refetchExtractionPoint();
          refetchExtractionRights();
        }}
        extractionPoint={extractionPoint}
      />
    </Layout>
  );
};

export default LinkToExtractionRights;

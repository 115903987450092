import { checkName } from "@services/extractionRight";
import { useMutation } from "@tanstack/react-query";

export function useValidateExtractionRightName(options: any = {}) {
  return useMutation<any, any, { name: string }>({
    mutationKey: ["check_extraction_right_name"],
    mutationFn: checkName,
    ...options,
  });
}

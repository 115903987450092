import { useTranslation } from "react-i18next";

import Breadcrumb from "@components/layout/Breadcrumb";
import Heading from "@components/layout/Heading";

import EventTransactionPaginatedTable from "@components/table/EventTransactionPaginatedTable";
import { useAppContext } from "@context/AppContext";

const AuditTrailIndex = () => {
  const { t } = useTranslation();
  const { loggedInInfo } = useAppContext();

  return (
    <>
      <section className="flex flex-col flex-1 space-y-4">
        <Breadcrumb
          items={[
            {
              label: t("dashboard.dashboard"),
              href: "/zenith",
            },
            {
              label: t("audit_trail.audit_trail"),
            },
          ]}
        />
        <Heading>{t("audit_trail.audit_trail")}</Heading>
        <EventTransactionPaginatedTable
          walletId={loggedInInfo?.userDetails?.walletId}
          eventGroups={["User", "Accounting", "Exchange"]}
        />
      </section>
    </>
  );
};

export default AuditTrailIndex;

import React from "react";
import { useTranslation } from "react-i18next";

import Label from "@components/form/Label";
import Legend from "@components/form/Legend";
import TextArea from "@components/form/TextArea";
import TextInput from "@components/form/TextInput";
import { useResponseApprovalInfoRequestContext } from "@context/ResponseApprovalInfoRequestContext";

type EnterMessageProps = {
  onPrevious: () => void;
  onSubmit: () => void;
  onCancel: () => void;
};

const EnterMessage: React.FunctionComponent<EnterMessageProps> = ({
  onPrevious,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();

  const { message, setMessage } = useResponseApprovalInfoRequestContext();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form className="flex flex-col grow" onSubmit={handleSubmit}>
      <fieldset className="space-y-4">
        <Legend>{t("notification.message")}</Legend>
        <div>
          <Label>{t("notification.enter_subject")}</Label>
          <TextInput
            type="text"
            required
            value={message?.subject}
            onChange={(e) =>
              setMessage((prev: any) => ({ ...prev, subject: e.target.value }))
            }
          />
        </div>
        <div>
          <Label>{t("notification.enter_body")}</Label>
          <TextArea
            className="h-64"
            required
            value={message?.body}
            onChange={(e) =>
              setMessage((prev: any) => ({ ...prev, body: e.target.value }))
            }
          />
        </div>
      </fieldset>

      <div className="grow" />

      <footer className="py-4 flex gap-2">
        <button
          type="button"
          className="btn-outline-primary text-sm font-semibold"
          onClick={onPrevious}
        >
          {t("common.prev_step")}
        </button>
        <button type="submit" className="btn-primary text-sm font-semibold">
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-default text-sm font-semibold"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default EnterMessage;

import { useState } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import ArrowRightCalendarIcon from "@components/icons/ArrowRightCalendarIcon";
import ExternalLinkIcon from "@components/icons/ExternalLinkIcon";
import Heading1 from "@components/layout/Heading";
import Modal from "@components/layout/Modal";
import Table from "@components/layout/Table";
import Tag from "@components/shared/Tag";
import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import AccountingPeriodChecklistFooter from "./AccountingPeriodChecklistFooter";
import { useAppContext } from "@context/AppContext";
import { useAccountingPeriodChecksContext } from "@context/AccountingPeriodChecksContext";
import { formatDate, formatDatetimeInput } from "@utils/formatDate";
import { formatDateTime } from "@utils/formatDateTime";
import { getMeterEventTransactions } from "@services/eventTransactions";
import ENV from "@config/env";

type Filter = {
  fromLodgedAt: string;
  toLodgedAt: string;
  type: string;
};

type SelectedMeter = {
  eventType: string;
  serialNo: string;
  oldSerialNo?: string;
  newSerialNo?: string;
  extractionPointName?: string;
  subscriberName: string;
  createdAt: string;
  createdBy?: string;
};

const MeterAdjustmentsChecklist: React.FunctionComponent = () => {
  const { loggedInInfo } = useAppContext();
  const { currentAccountingPeriodCheck } = useAccountingPeriodChecksContext();
  const { t } = useTranslation();
  const [selectedMeter, setSelectedMeter] = useState<SelectedMeter>();
  const [evidences, setEvidences] = useState<any[]>([]);
  const [filter, setFilter] = useState<Partial<Filter>>({
    fromLodgedAt: "",
    toLodgedAt: "",
    type: "",
  });

  const eventTypeOptions = [
    {
      label: t("meter.adjustments.add_meter"),
      value: "MeterCreated",
    },
    {
      label: t("meter.adjustments.replace_meter"),
      value: "MeterLinked",
    },
    {
      label: t("meter.adjustments.decommission_meter"),
      value: "MeterDecommissioned",
    },
  ];

  const details: { label: string; key: keyof SelectedMeter }[] = [
    {
      label: t("meter.serial_no"),
      key: "serialNo",
    },
    {
      label: t("meter.replacement.old_serial_no"),
      key: "oldSerialNo",
    },
    {
      label: t("meter.replacement.replacement_serial_no"),
      key: "newSerialNo",
    },
    {
      label: t("extraction_point.name"),
      key: "extractionPointName",
    },
    {
      label: t("common.subscriber"),
      key: "subscriberName",
    },
    {
      label: t("common.created_at"),
      key: "createdAt",
    },
    {
      label: t("common.created_by"),
      key: "createdBy",
    },
  ];

  const tableFields = [
    { title: t("meter.adjustments.field_timestamp"), name: "timestamp" },
    {
      title: t("meter.adjustments.field_audit_trail_type"),
      name: "auditTrailType",
    },
    {
      title: t("meter.serial_no"),
      name: "meterId",
    },
    { title: t("common.subscriber"), name: "subscriber" },
    { title: t("extraction_point.name"), name: "extractionPointName" },
    { title: t("common.level0wrs"), name: "level0ResourceIdentifier" },
    { title: t("extraction_point.source"), name: "source" },
    { title: t("common.action"), name: "action" },
  ];

  const { data = [], isLoading } = useQuery(
    ["meterEventTransactions", filter],
    () =>
      getMeterEventTransactions({
        fromDate: currentAccountingPeriodCheck?.accountingPeriod?.periodStart,
        toDate: currentAccountingPeriodCheck?.accountingPeriod?.periodEnd,
        level1ResourceId: currentAccountingPeriodCheck?.level1ResourceId,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      enabled: Boolean(currentAccountingPeriodCheck?.accountingPeriod),
      select: res => {
        return res?.map((record: any) => {
          const isCreated = record.eventType.name === "MeterCreated";
          const isLinked = record.eventType.name === "MeterLinked";
          const isDecommissioned =
            record.eventType.name === "MeterDecommissioned";

          const extractionPoint = record.hcsMessage?.extractionPoint;

          return {
            ...record,
            timestamp: formatDateTime(new Date(record.createdAt)),
            meterId: record.meter?.serialNo,
            subscriber: record.impactWallet?.name,
            extractionPointName: extractionPoint?.name,
            level0ResourceIdentifier: record.impactLevel0Resource?.identifier,
            source: extractionPoint?.source,
            type: record?.eventType?.name,
            auditTrailType: isCreated ? (
              <Tag status="success">{t("meter.adjustments.add_meter")}</Tag>
            ) : isLinked ? (
              <Tag status="warning">{t("meter.adjustments.replace_meter")}</Tag>
            ) : isDecommissioned ? (
              <Tag status="error">
                {t("meter.adjustments.decommission_meter")}
              </Tag>
            ) : null,
            action: (
              <button
                type="button"
                className="btn-default"
                onClick={() => {
                  let data: SelectedMeter = {
                    eventType: record.eventType?.name,
                    serialNo: record.meter?.serialNo,
                    extractionPointName: extractionPoint?.name,
                    subscriberName: record.impactWallet?.name,
                    createdAt: formatDate(new Date(record.createdAt)),
                    createdBy: record.createdBy ?? "",
                  };
                  if (isLinked) {
                    data.serialNo = "";
                    data.newSerialNo = record.meter?.serialNo;
                    data.oldSerialNo =
                      record.hcsMessage?.oldMeter?.meter?.serialNo;
                  }

                  setSelectedMeter(data);
                  setEvidences(record.meter?.evidences ?? []);
                }}
              >
                {t("common.view")}
              </button>
            ),
          };
        });
      },
    },
  );

  const handleFilterChange = <TField extends keyof Filter>(
    field: TField,
    value: Filter[TField],
  ) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const tableData = data
    .filter((i: any) => {
      return (
        !filter.fromLodgedAt ||
        new Date(i.createdAt).getTime() >=
          new Date(filter.fromLodgedAt).getTime()
      );
    })
    .filter((i: any) => {
      return (
        !filter.toLodgedAt ||
        new Date(i.createdAt).getTime() <= new Date(filter.toLodgedAt).getTime()
      );
    })
    .filter((i: any) => {
      return !filter.type || filter.type === i.type;
    });

  return (
    <>
      <div className="flex flex-col p-4 grow">
        <Heading1 light>
          {t("accounting_period.checklist.meter_adjustments")}{" "}
          <strong className="ml-6">{data?.length}</strong>
        </Heading1>
        <div className="flex flex-col grow gap-4">
          <form className="flex flex-wrap gap-3 justify-start">
            <div className="w-full sm:w-1/2 md:w-auto">
              <Label htmlFor="from-date">{t("common.ledger_timestamp")}</Label>
              <div className="flex flex-col sm:flex-row gap-1">
                <div className="max-w-max">
                  <TextInput
                    type="datetime-local"
                    id="from-date"
                    placeholder={t("common.date_range.from") as string}
                    value={
                      filter.fromLodgedAt &&
                      formatDatetimeInput(new Date(filter.fromLodgedAt))
                    }
                    onChange={e => {
                      handleFilterChange("fromLodgedAt", e.target.value);
                    }}
                  />
                </div>
                <ArrowRightCalendarIcon className="w-10 h-10 hidden sm:block" />
                <div className="max-w-max">
                  <TextInput
                    type="datetime-local"
                    placeholder={t("common.date_range.to") as string}
                    value={
                      filter.toLodgedAt &&
                      formatDatetimeInput(new Date(filter.toLodgedAt))
                    }
                    onChange={e => {
                      handleFilterChange("toLodgedAt", e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="mt-4 w-1/2">
                <Label htmlFor="type">
                  {t("meter.adjustments.field_audit_trail_type")}
                </Label>
                <Select
                  inputId="type"
                  options={eventTypeOptions}
                  value={eventTypeOptions.find(
                    (i: any) => i.value === filter.type,
                  )}
                  onChange={e => {
                    handleFilterChange("type", e?.value ?? "");
                  }}
                  isClearable
                  openMenuOnFocus
                />
              </div>
            </div>
            <div className="flex items-end  w-full ml-auto sm:w-auto">
              <Link
                className="btn-secondary flex items-center gap-1"
                to={`/polestar/level1wrs/${currentAccountingPeriodCheck?.level1ResourceId}/meters/decommission?newWindow=true`}
                target="_blank"
              >
                <ExternalLinkIcon className="w-4 h-4" />
                {t("meter.adjustments.decommission_meter")}
              </Link>
            </div>
            <div className="flex items-end  w-full sm:w-auto">
              <Link
                className="btn-secondary flex items-center gap-1"
                to={`/polestar/level1wrs/${currentAccountingPeriodCheck?.level1ResourceId}/meters/replace?newWindow=true`}
                target="_blank"
              >
                <ExternalLinkIcon className="w-4 h-4" />
                {t("meter.adjustments.replace_meter")}
              </Link>
            </div>
            <div className="flex items-end  w-full sm:w-auto">
              <Link
                className="btn-secondary flex items-center gap-1"
                to="/polestar/meters/create"
                target="_blank"
              >
                <ExternalLinkIcon className="w-4 h-4" />
                {t("meter.adjustments.add_meter")}
              </Link>
            </div>
          </form>
          <Table
            fields={tableFields}
            data={tableData}
            pageSize={100}
            loading={isLoading}
            stickyHeader
          />
        </div>
      </div>

      {selectedMeter ? (
        <Modal open={true}>
          <div className="flex flex-col">
            <div className="border-b p-4">
              <h3 className="text-lg font-bold leading-6 text-gray-900">
                {selectedMeter.eventType === "MeterCreated"
                  ? t("meter.adjustments.add_meter")
                  : selectedMeter.eventType === "MeterLinked"
                    ? t("meter.adjustments.replace_meter")
                    : selectedMeter.eventType === "MeterDecommissioned"
                      ? t("meter.adjustments.decommission_meter")
                      : ""}
              </h3>
            </div>
            <div className="p-4">
              <dl className="space-y-2">
                {details.map(i => {
                  const value = selectedMeter[i.key];
                  return value ? (
                    <div className="flex gap-2" key={`meter-detail-${i.key}`}>
                      <dt className="font-medium text-gray-900">{i.label}:</dt>
                      <dd className="text-gray-500">{value}</dd>
                    </div>
                  ) : null;
                })}

                {evidences?.length > 0 ? (
                  <div className="flex gap-2">
                    <dt className="font-medium text-gray-900">
                      {t("common.attachments")}:
                    </dt>
                    <dd className="text-gray-500 space-y-2">
                      {evidences.map((i: any) => {
                        return (
                          <a
                            key={i.id}
                            href={`${ENV.API_HOST}/evidences/${i?.id}/download`}
                            download
                            className="block hover:underline"
                          >
                            {t("common.attachment_link", {
                              fileName: i?.fileName,
                            })}
                          </a>
                        );
                      })}
                    </dd>
                  </div>
                ) : null}
              </dl>
            </div>

            <div className="border-t px-4 py-2 flex justify-end gap-4">
              <button
                type="button"
                className="btn-primary text-base font-medium sm:col-start-1 sm:text-sm"
                onClick={() => {
                  setSelectedMeter(undefined);
                  setEvidences([]);
                }}
              >
                {t("common.close")}
              </button>
            </div>
          </div>
        </Modal>
      ) : null}

      <AccountingPeriodChecklistFooter
        stepData={{
          meterAdjustmentsCheckedAt: new Date(),
          meterAdjustmentsCheckedByUserId: loggedInInfo?.userDetails?.id,
        }}
      />
    </>
  );
};

export default MeterAdjustmentsChecklist;

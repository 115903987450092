import { useTranslation } from "react-i18next";

import ModalInformationIcon from "@components/icons/ModalInformationIcon";
import Heading from "@components/layout/Heading";
import Modal from "@components/layout/Modal";
import React from "react";
import { formatVolume } from "@utils/formatVolume";
import { formatDateTime } from "@utils/formatDateTime";
import { useDeclarationContext } from "@context/DeclarationContext";
import { useStepContext } from "@context/shared/StepContext";

type Props = {
  open: boolean;
  onCancel: () => void;
};

const ConfirmHistoricalDeclarationModal: React.FunctionComponent<Props> = ({
  open,
  onCancel,
}: any) => {
  const { t } = useTranslation();
  const [showWarning, setShowWarning] = React.useState(false);
  const { stepHelpers } = useStepContext();
  const { meter } = useDeclarationContext();

  const details = [
    {
      title: t("common.extraction_point"),
      value: meter.extractionPoint?.name,
    },
    {
      title: t("meter.serial_no"),
      value: meter.serialNo,
    },
    {
      title: t("declaration.reading"),
      value: formatVolume(meter.lastDeclaration?.reading),
    },
    {
      title: t("declaration.read_at"),
      value: formatDateTime(new Date(meter.lastDeclaration?.readAt)),
    },
    {
      title: t("declaration.declared_by"),
      value: meter.lastDeclaration?.createdByUser?.name ?? "-",
    },
  ];

  return (
    <Modal open={open}>
      <div className="py-8 px-4 flex gap-4">
        <ModalInformationIcon className="shrink-0 h-12 w-12" />

        <div className="text-gray-500">
          {showWarning ? (
            <Heading className="text-inherit">
              {t("declaration.modal.historical.usage_warning")}
            </Heading>
          ) : (
            <div className="space-y-4">
              <Heading className="text-inherit">
                {t("declaration.modal.historical.newer_exists")}
              </Heading>
              <div>
                <dl className="space-y-1">
                  {details.map((i) => {
                    return (
                      <div key={i.title} className="flex gap-2 leading-6">
                        <dt className="font-semibold">{i.title}: </dt>
                        <dl>{i.value}</dl>
                      </div>
                    );
                  })}
                </dl>
              </div>
              <Heading className="text-inherit">
                {t("declaration.modal.historical.continue")}
              </Heading>
            </div>
          )}
        </div>
      </div>

      <div className="border-t p-4 flex justify-end gap-4">
        <button
          type="button"
          className="btn-primary text-sm rounded"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </button>
        <button
          type="button"
          className="btn-default text-sm rounded"
          onClick={(e) => {
            if (showWarning) {
              onCancel();
              stepHelpers.goToNextStep();
              return;
            }

            setShowWarning(true);
          }}
        >
          {t("common.continue")}
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmHistoricalDeclarationModal;

import { useTranslation } from "react-i18next";

import Tag from "@components/shared/Tag";

type AccountingPeriodStatusTagProps = {
  indexAccountingPeriod: any;
  activeAccountingPeriod: any;
};

const AccountingPeriodStatusTag: React.FunctionComponent<
  AccountingPeriodStatusTagProps
> = ({ indexAccountingPeriod, activeAccountingPeriod }) => {
  const { t } = useTranslation();
  const indexIsActive = indexAccountingPeriod.isActive;

  const isPast =
    !indexIsActive &&
    new Date(indexAccountingPeriod.periodEnd) <
      new Date(activeAccountingPeriod.periodStart);

  const isFuture =
    !indexIsActive &&
    new Date(activeAccountingPeriod.periodEnd) <
      new Date(indexAccountingPeriod.periodStart);

  return (
    <div className="flex flex-row items-center gap-2 ml-1">
      <Tag status={indexIsActive ? "success" : "error"}>
        {indexAccountingPeriod.isActive
          ? t("common.active")
          : t("common.inactive")}
      </Tag>
      {isPast && <Tag status={"error"}>{t("common.past")}</Tag>}
      {isFuture && <Tag status={"warning"}>{t("common.future")}</Tag>}
    </div>
  );
};

export default AccountingPeriodStatusTag;

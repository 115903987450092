import type { ConfirmData } from "./ConfirmationDetail";

type Props = {
  data: ConfirmData["body"];
};

const StepInfo: React.FunctionComponent<Props> = ({ data }) => {
  return (
    <ul className="text-sm text-gray-600 mt-1 space-y-1">
      {data.map(({ key, value = "-" }: any) => {
        return (
          <li key={key}>
            {Boolean(key) && <strong>{key}: </strong>}
            {value}
          </li>
        );
      })}
    </ul>
  );
};

export default StepInfo;

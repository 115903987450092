import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import WorkflowPanels from "@components/form/WorkflowPanels";
import Layout from "@components/layout/Layout";
import Heading from "@components/layout/Heading";
import Modal from "@components/layout/Modal";
import AlertIcon from "@components/icons/AlertIcon";
import UpdateThresholdStep1 from "@components/form/meter/update/UpdateThresholdStep1";
import UpdateThresholdStep2Label from "@components/form/meter/update/UpdateThresholdStep2Label";
import UpdateThresholdStep2 from "@components/form/meter/update/UpdateThresholdStep2";
import UpdateThresholdStep1Label from "@components/form/meter/update/UpdateThresholdStep1Label";
import { updateMeter } from "@services/meter";

const UpdateThreshold: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [meterData, setMeterData] = useState<Record<string, any>>({});
  const [changedThreshold, setChangedThreshold] = useState<
    number | undefined
  >();
  const { mutateAsync: saveMeterData, isLoading } = useMutation<
    any,
    any,
    {
      id: string;
      threshold: number;
    }
  >((meterData) => updateMeter(meterData));

  const handleStep1Save = (data: { meter: any }) => {
    setMeterData({ meter: data.meter });

    setCurrentStep(1);
  };

  const handleStep2Save = async () => {
    setIsModalOpen(true);
  };

  const handleSave = async () => {
    try {
      await saveMeterData({
        id: meterData.meter?.id,
        threshold: changedThreshold
          ? changedThreshold * 1000000
          : meterData.meter?.threshold,
      });
      toast.success(t("meter.threshold_toast.save_success"), {
        className: "toast toast-success",
      });

      setIsModalOpen(false);
      setCurrentStep(0);
      setChangedThreshold(undefined);
      setMeterData({});
    } catch (e: any) {
      toast.error(
        <>
          <p>{t("declaration.toast.save_failure")}</p>
          <p>{e.message}</p>
        </>,
        {
          className: "toast toast-error",
        }
      );
      setIsModalOpen(false);
    }
  };

  return (
    <Layout
      permissions={["UpdateThreshold"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("meter.update_threshold"),
        },
      ]}
      title={t("meter.update_threshold")}
    >
      <WorkflowPanels
        itemClassName="border-t"
        selectedStep={currentStep}
        steps={[
          {
            label: (
              <UpdateThresholdStep1Label
                meter={meterData?.meter}
                isSelected={currentStep === 0}
              />
            ),
            panel: <UpdateThresholdStep1 onSave={handleStep1Save} />,
          },
          {
            label: <UpdateThresholdStep2Label isSelected={currentStep === 1} />,
            panel: (
              <UpdateThresholdStep2
                onSave={handleStep2Save}
                changeMeter={() => {
                  setCurrentStep(0);
                  setMeterData({});
                  setChangedThreshold(undefined);
                }}
                meter={meterData.meter}
                isSaveDisabled={isLoading}
                changedThreshold={changedThreshold}
                setChangedThreshold={setChangedThreshold}
              />
            ),
          },
        ]}
      />
      <Modal open={isModalOpen}>
        <div className="py-8 px-4 flex items-center gap-4  text-primary-2">
          <div className="mx-auto flex h-12 w-12">
            <AlertIcon className="w-12 h-12" />
          </div>
          <Heading className="text-xl font-medium leading-6">
            {t("meter.modal.confirm")}
          </Heading>
        </div>
        <div className="mt-5 border-t px-4 py-2 flex flex-row-reverse gap-4">
          <button
            type="button"
            className="btn-primary text-base font-medium sm:col-start-1 sm:text-sm"
            onClick={() => setIsModalOpen(false)}
          >
            {t("common.cancel")}
          </button>
          <button
            type="button"
            className="btn-default text-base font-medium shadow-sm sm:col-start-2 sm:text-sm"
            onClick={handleSave}
          >
            {t("common.confirm")}
          </button>
        </div>
      </Modal>
    </Layout>
  );
};

export default UpdateThreshold;

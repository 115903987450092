import { useQuery } from "@tanstack/react-query";
import { getEventTypes } from "@services/eventTransactions";

export const useAllEventTypes = (options: Record<string, any> = {}) => {
  const { params, ...restOptions } = options;
  return useQuery<any>({
    queryKey: ["getEventTypes", params],
    queryFn: () => getEventTypes(params),
    ...restOptions,
  });
};

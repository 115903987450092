import { t as translate } from "i18next";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import RadioInput from "@components/form/RadioInput";
import { usePermanentTransferContext } from "@context/PermanentTransferContext";

const zeroValueTradeReason: Array<{ [key: string]: string }> = [
  {
    A: translate(
      "approval.seasonal_water_assignments.create.step_5.price_option_a"
    ),
  },
  {
    B: translate(
      "approval.seasonal_water_assignments.create.step_5.price_option_b"
    ),
  },
  {
    C: translate(
      "approval.seasonal_water_assignments.create.step_5.price_option_c"
    ),
  },
  {
    D: translate(
      "approval.seasonal_water_assignments.create.step_5.price_option_d"
    ),
  },
  {
    E: translate(
      "approval.seasonal_water_assignments.create.step_5.price_option_e"
    ),
  },
];

type EnterPriceInformationProps = {
  onNext: () => void;
  onPrev: () => void;
  onCancel: () => void;
};

const EnterPriceInfomation: React.FunctionComponent<
  EnterPriceInformationProps
> = ({ onNext, onPrev, onCancel }) => {
  const { t } = useTranslation();
  const { price, setPrice, description, setDescription } =
    usePermanentTransferContext();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onNext();
  };

  return (
    <form
      className="flex flex-col p-6 grow justify-between"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col grow gap-6">
        <Heading light>
          {t("approval.seasonal_water_assignments.create.step_5.title")}
        </Heading>

        <fieldset className="space-y-6">
          <div className="max-w-xs">
            <Label htmlFor="price">
              {t(
                "approval.seasonal_water_assignments.create.step_5.trade_value"
              )}
            </Label>
            <TextInput
              type="number"
              min={0}
              step="0.01"
              id="price"
              value={price}
              onChange={(e) => {
                setPrice(e.target.value);
                if (+e.target.value > 0 && description) {
                  setDescription("");
                }
              }}
              suffix={t("common.currency")}
              placeholder="0"
            />
          </div>
          {+price === 0 && (
            <div>
              <Label htmlFor="price">
                {t(
                  "approval.seasonal_water_assignments.create.step_5.reason_zero_trade"
                )}
              </Label>
              <div className="space-y-3">
                {zeroValueTradeReason.map((value) => {
                  const key = Object.keys(value)[0];
                  return (
                    <RadioInput
                      htmlFor="zeroValueTradeReason"
                      label={`${key}: ${value[key]}`}
                      checked={description === value[key]}
                      onChange={() => {
                        setDescription(value[key]);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </fieldset>
      </div>

      <footer className="flex gap-4 -mx-6 mt-6 p-6 pb-0 border-t border-gray-200">
        <button type="button" className="btn-outline-primary" onClick={onPrev}>
          {t("common.prev_step")}
        </button>

        <button
          type="submit"
          className="btn-primary"
          disabled={(price === "" || +price <= 0) && description === ""}
        >
          {t("common.next_step")}
        </button>

        <button
          type="button"
          className="btn-outline-primary"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default EnterPriceInfomation;

import React from "react";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import Legend from "@components/form/Legend";
import BoundaryInputs from "@components/shared/BoundaryInputs";
import { useLevel0WRSContext } from "@context/Level0WRSContext";

const Level0WRSBoundary = () => {
  const { t } = useTranslation();
  const {
    stepHelpers,
    locationCoordinates,
    setLocationCoordinates,
    saveGeographyDetails,
    locationFiles,
    setLocationFiles,
    navigateForCancel,
  } = useLevel0WRSContext();

  return (
    <form className="flex flex-col gap-6 grow p-6">
      <Heading light>{t("level0wrs.create.boundary")}</Heading>

      <fieldset className="space-y-4">
        <Legend>{t("level0wrs.create.enter_coordinates")}</Legend>

        <BoundaryInputs
          locationCoordinates={locationCoordinates}
          setLocationCoordinates={setLocationCoordinates}
          files={locationFiles}
          setFiles={setLocationFiles}
        />
      </fieldset>

      <div className="grow" />

      <footer className="flex gap-4 -mx-6 p-6 pb-0 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>

        <button
          type="button"
          className="btn-primary"
          onClick={() => {
            saveGeographyDetails();
            stepHelpers.goToNextStep();
          }}
        >
          {t("common.next_step")}
        </button>

        <button
          type="button"
          className="btn-outline-primary"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default Level0WRSBoundary;

import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import { useCreateOrUpdateExtractionRightContext } from "@context/CreateOrUpdateExtractionRightContext";
import SelectMultipleExtractionPointTable from "@components/table/SelectMultipleExtractionPointTable";

const ExtractionRightLinkExtractionPoints = () => {
  const { t } = useTranslation();
  const {
    stepHelpers,
    details,
    handleChangeDetails,
    navigateForCancel,
    updatingRight,
    amalgamateOrSubdivideDetails,
    checkHasOutOfLevel0WRSRequest,
    checkHasInactivePointToLink,
  } = useCreateOrUpdateExtractionRightContext();

  const source = details?.level0wrs?.source;
  const isUpdating = Boolean(updatingRight);

  return (
    <form className="flex flex-col gap-6 p-6 grow justify-between">
      <div className="space-y-4">
        <Heading light>
          {t("extraction_right.create.step_4.link_extraction_points")}
        </Heading>
      </div>

      <SelectMultipleExtractionPointTable
        subscriber={details.subscriber}
        onSelect={(values) => {
          handleChangeDetails(
            "linkToExtractionPoints",
            values.map((v) => ({
              id: v.id,
              name: v.name,
              level0wrsId: v.level0ResourceId,
              isActive: v.isActive,
            }))
          );
        }}
        selectedKeys={details.linkToExtractionPoints.map((v: any) => v.id)}
        disableLevel0ResourceId={details.level0wrs?.id}
        disableSelection={!!amalgamateOrSubdivideDetails}
        source={source}
        noRowText={
          t("extraction_right.create.step_4.no_data_text", { source }) as string
        }
        showLinkageStatesWithRight={isUpdating ? details : undefined}
      />
      <footer className="flex gap-4 -mx-2 mt-6 p-6 pb-0 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>

        <button
          type="button"
          className="btn-primary"
          onClick={stepHelpers.goToNextStep}
          disabled={
            checkHasOutOfLevel0WRSRequest() || checkHasInactivePointToLink()
          }
        >
          {t("common.next_step")}
        </button>

        <button
          type="button"
          className="btn-outline-primary"
          onClick={() => navigateForCancel()}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default ExtractionRightLinkExtractionPoints;

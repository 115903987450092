import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { getZenithSubscriberById } from "@services/zenith/subscriber";

export const useGetSubscriber = ({
  id,
  options,
}: {
  id: string;
  options?: UseQueryOptions;
}) => {
  return useQuery<any>({
    queryKey: ["subscriber", id],
    queryFn: () => getZenithSubscriberById(id),
    ...options,
  });
};

import Label from "@components/form/Label";
import Legend from "@components/form/Legend";
import TextInput from "@components/form/TextInput";
import Heading from "@components/layout/Heading";
import { useLevel0WRSContext } from "@context/Level0WRSContext";
import { useAllExtractionRights } from "@hooks/query/useAllExtractionRights";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

const Level0WRSDistributionLoss: FunctionComponent = () => {
  const { t } = useTranslation();
  const { details, stepHelpers, handleChangeDetails, navigateForCancel } =
    useLevel0WRSContext();

  const { data: extractionRights = [] } = useAllExtractionRights({
    params: {
      level1ResourceId: details?.level1WRS?.id,
      name: details?.distributionLoss?.extractionRightName,
    },
    options: {
      refetchOnWindowFocus: false,
      enabled:
        !!details?.level1WRS?.id &&
        !!details?.distributionLoss?.extractionRightName,
      select: (data: any[]) => {
        return data.filter(
          (ep) => details.distributionLoss?.extractionRightId !== ep.id
        );
      },
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    stepHelpers.goToNextStep();
  };

  return (
    <>
      <form
        className="flex flex-col gap-6 p-6 grow justify-between"
        onSubmit={handleSubmit}
      >
        <div className="space-y-4">
          <Heading light>{t("level0wrs.create.distribution_loss")}</Heading>
          <fieldset className="space-y-4">
            <Legend>
              {t("level0wrs.create.select_distribution_loss_accounting")}
            </Legend>
            <div>
              <Label htmlFor="distribution_loss_extraction_right_name">
                <h3 className="font-semibold">
                  {t(
                    "level0wrs.create.enter_distribution_loss_extraction_right"
                  )}
                </h3>
                {t(
                  "level0wrs.create.enter_distribution_loss_extraction_right_description"
                )}
              </Label>
              <TextInput
                id="distribution_loss_extraction_right_name"
                placeholder={
                  t(
                    "level0wrs.create.enter_distribution_loss_extraction_right_name"
                  ) as string
                }
                className="max-w-md"
                value={details?.distributionLoss?.extractionRightName}
                onChange={(e) =>
                  handleChangeDetails("distributionLoss", {
                    ...details?.distributionLoss,
                    extractionRightName: e.target.value,
                  })
                }
                errorMessage={
                  extractionRights?.length > 0
                    ? (t(
                        "extraction_right.create.step_2.validate_name.error_duplicate_name"
                      ) as string)
                    : details.distributionLoss?.extractionRightId &&
                      !details.distributionLoss?.extractionRightName
                    ? (t("level0wrs.create.right_name_is_required") as string)
                    : ""
                }
              />
            </div>
          </fieldset>
          {(details.distributionLoss?.extractionRightName ||
            details.distributionLoss?.extractionRightId) && (
            <fieldset className="space-y-4">
              <Legend>{t("level0wrs.create.enter_distribution_loss")}</Legend>
              <div>
                <Label htmlFor="distribution_loss_volume">
                  {t("level0wrs.create.enter_distribution_loss_volume")}
                </Label>
                <TextInput
                  id="distribution_loss_volume"
                  type="number"
                  step="0.001"
                  min={0}
                  max={+details?.yield}
                  className="max-w-md"
                  placeholder="0.000"
                  suffix={t("common.volume_unit")}
                  value={details?.distributionLoss?.volume}
                  onChange={(e) =>
                    handleChangeDetails("distributionLoss", {
                      ...details?.distributionLoss,
                      volume: Number(e.target.value),
                    })
                  }
                  errorMessage={
                    (details.distributionLoss?.extractionRightName ||
                      details.distributionLoss?.percentage) &&
                    !details.distributionLoss?.volume
                      ? (t("level0wrs.create.volume_is_required") as string)
                      : ""
                  }
                  disabled={!!details?.distributionLoss?.extractionRightId}
                />
              </div>
              <div>
                <Label htmlFor="distribution_loss_percentage">
                  {t("level0wrs.create.enter_distribution_loss_percentage")}
                </Label>
                <TextInput
                  id="distribution_loss_percentage"
                  type="number"
                  step="1"
                  min={0}
                  max={100}
                  className="max-w-md"
                  placeholder="100"
                  suffix="%"
                  value={details?.distributionLoss?.percentage}
                  onChange={(e) =>
                    handleChangeDetails("distributionLoss", {
                      ...details?.distributionLoss,
                      percentage: Number(e.target.value),
                    })
                  }
                  errorMessage={
                    (details.distributionLoss?.extractionRightName ||
                      details.distributionLoss?.volume) &&
                    !details.distributionLoss?.percentage
                      ? (t("level0wrs.create.percentage_is_required") as string)
                      : ""
                  }
                  disabled={!!details?.distributionLoss?.extractionRightId}
                />
              </div>
            </fieldset>
          )}
        </div>
        <footer className="flex gap-4 -mx-6 mt-6 p-6 pb-0 border-t border-gray-200">
          <button
            type="button"
            className="btn-outline-primary"
            onClick={stepHelpers.goToPrevStep}
          >
            {t("common.prev_step")}
          </button>

          <button
            type="submit"
            className="btn-primary"
            disabled={
              (!!details.distributionLoss?.extractionRightName &&
                (!details.distributionLoss?.volume ||
                  !details.distributionLoss?.percentage)) ||
              extractionRights?.length > 0 ||
              (!!details?.distributionLoss?.extractionRightId &&
                !details?.distributionLoss?.extractionRightName)
            }
          >
            {t("common.next_step")}
          </button>

          <button
            type="button"
            className="btn-outline-primary"
            onClick={navigateForCancel}
          >
            {t("common.cancel")}
          </button>
        </footer>
      </form>
    </>
  );
};

export default Level0WRSDistributionLoss;

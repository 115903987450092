import { updateExtractionRight } from "@services/extractionRight";
import { useMutation } from "@tanstack/react-query";

export const useUpdateExtractionRight = (options: any = {}) => {
  return useMutation<any, any, any>({
    mutationKey: ["update_extraction_right"],
    mutationFn: updateExtractionRight,
    ...options,
  });
};

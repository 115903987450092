import Select, { ActionMeta } from "react-select";
import { noop } from "lodash";

import ErrorMessage from "@components/form/ErrorMessage";
import { useAllEventTypes } from "@hooks/query/useAllEventTypes";

type OverrideProps<T, TOverridden> = Omit<T, keyof TOverridden> & TOverridden;

type Option = { label: string; value: string };

type SelectEventTypeProps = {
  className?: string;
  errorMessage?: string;
} & OverrideProps<
  React.ComponentProps<Select>,
  {
    value?: string;
    onChange?: (value: Option, actionMeta: ActionMeta<Option>) => void;
  }
>;

const SelectEventType: React.FunctionComponent<SelectEventTypeProps> = ({
  errorMessage,
  value,
  className,
  onChange = noop,
  ...props
}) => {
  const { data: options = [] } = useAllEventTypes({
    refetchOnWindowFocus: false,
    select: (data: any[]) => {
      return data?.map((i) => ({
        label: i.name,
        value: i.id,
      }));
    },
  });

  return (
    <>
      <Select
        options={options}
        value={options.find((i: any) => i.label === value || i.value === value)}
        className={className}
        onChange={onChange}
        openMenuOnFocus
        {...props}
      />

      {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
    </>
  );
};

export default SelectEventType;

import { getNotification } from "@services/notification";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

export function useGetNotification(id: string, options?: UseQueryOptions) {
  return useQuery<any>({
    queryKey: ["notifications", { id }],
    queryFn: () => getNotification(id),
    refetchOnWindowFocus: false,
    ...options,
  });
}

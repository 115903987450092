import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "@context/AppContext";
import { setUserLanguage } from "@services/user";

export const useSetUserLanguage = (options: Record<string, any> = {}) => {
  const { setLanguage } = useAppContext();

  return useMutation<any, any, any>({
    mutationFn: setUserLanguage,
    onSuccess: (data: any) => {
      setLanguage(data.language);
    },
    ...options,
  });
};

import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet, useOutletContext } from "react-router-dom";

import Breadcrumb, {
  type BreadcrumObject,
} from "@components/layout/Breadcrumb";
import Heading from "@components/layout/Heading";

type ContextType = { setBreadcrumbs: any };

const ApprovalTabs = () => {
  const { t } = useTranslation();
  const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumObject[]>([]);

  const menus: any[] = [
    {
      name: t("approval.seasonal_water_assignments.short_title"),
      path: "seasonals",
      end: false,
    },
    {
      name: t("approval.permanent_trades.short_title"),
      path: "permanents",
      end: true,
    },
    {
      name: t("approval.forward_draws.title"),
      path: "forward_draws",
      end: true,
    },
    {
      name: t("approval.stock_and_domestic.title"),
      path: "stock_and_domestics",
      end: true,
    },
  ];

  return (
    <>
      <section className="flex flex-col grow gap-4 bg-white p-6">
        <Breadcrumb
          items={[
            {
              label: t("dashboard.dashboard"),
              href: "/zenith",
            },
            ...breadcrumbs,
          ]}
        />
        <Heading>{t("zenith.approval.title")}</Heading>

        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {menus.map((i: any) => (
              <NavLink
                key={i.name}
                to={`/zenith/approvals/${i.path}`}
                className={({ isActive }) =>
                  classNames(
                    isActive
                      ? "border-primary-1 text-primary-1"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 pb-4 px-1 text-sm font-medium"
                  )
                }
                end={i.end}
              >
                {i.name}
              </NavLink>
            ))}
          </nav>
        </div>

        <div className="flex flex-col grow">
          <Outlet
            context={
              {
                setBreadcrumbs,
              } satisfies ContextType
            }
          />
        </div>
      </section>
    </>
  );
};

export const useApprovalTabsContext = () => {
  return useOutletContext<ContextType>();
};

export default ApprovalTabs;

import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Link, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getAllExtractionPoints } from "@services/extractionPoints";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import Table from "@components/layout/Table";
import { formatVolume } from "@utils/formatVolume";
import Heading from "@components/layout/Heading";
import Tag from "@components/shared/Tag";
import { useAppContext } from "@context/AppContext";

type UpdateThresholdStep1Props = {
  onSave: (data: { meter: any }) => void;
};

const UpdateThresholdStep1: React.FunctionComponent<
  UpdateThresholdStep1Props
> = ({ onSave }: UpdateThresholdStep1Props) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { checkPermissions } = useAppContext();

  const [checkedMeter, setCheckedMeter] = useState<{
    id: string;
    serialNo: string;
  }>({
    id: searchParams.get("meterId") || "",
    serialNo: searchParams.get("meterName") || "",
  });

  const [filter, setFilter] = useState<{
    subscriberNameOrId?: string;
    extractionPointOrMeterName?: string;
  }>({
    extractionPointOrMeterName: searchParams.get("meterName") || undefined,
  });

  const { data: extractionPoints } = useQuery(
    ["extractionPoints"],
    () => getAllExtractionPoints(),
    {
      keepPreviousData: true,
    }
  );

  const handleSave = (e: any) => {
    e.preventDefault();

    onSave({
      meter: checkedMeter,
    });
  };

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  return (
    <>
      <section className="p-6 pb-0 flex flex-col grow">
        <Heading light className="mb-4">
          {t("meter.choose_meter")}
        </Heading>
        <div className="grid grid-cols-1 gap-2 md:grid-cols-3 md:gap-3 2xl:w-2/3 mb-4">
          <div>
            <Label htmlFor="subscriber-name">
              {t("subscriber.filter_name_id")}
            </Label>

            <SearchInput
              id="subscriber-name"
              onChange={(e) =>
                handleFilterChange("subscriberNameOrId", e.target.value)
              }
            />
          </div>
          <div>
            <Label htmlFor="extraction-point-id">
              {t("extraction_point.filter_name_with_meter")}
            </Label>
            <SearchInput
              id="extraction-point-id"
              onChange={(e) =>
                handleFilterChange("extractionPointOrMeterName", e.target.value)
              }
            />
          </div>
        </div>
        <Table
          pageSize={50}
          containerClassName="rounded-none md:rounded-none text-sm"
          noRowsText={t("meter.no_row_text") as string}
          fields={[
            {
              title: "",
              name: "radioButton",
            },
            {
              title: t("subscriber.account_number"),
              name: "accountNumber",
            },
            {
              title: t("subscriber.name"),
              name: "accountName",
            },
            {
              title: t("extraction_point.name"),
              name: "name",
            },
            {
              title: t("meter.serial_no"),
              name: "meterName",
            },
            {
              title: t("common.location"),
              name: "location",
            },
            {
              title: t("extraction_point.source"),
              name: "source",
            },
            ...(checkPermissions(["ViewThreshold"])
              ? [
                  {
                    title: t("meter.threshold"),
                    name: "meterThreshold",
                  },
                ]
              : []),
            {
              title: t("common.status"),
              name: "isActive",
            },
          ]}
          data={extractionPoints
            ?.filter((ep: { meter: any }) => !!ep.meter)
            ?.filter(
              (row: { subscriber: any }) =>
                !filter?.subscriberNameOrId?.length ||
                row.subscriber?.accountNumber
                  ?.toString()
                  ?.includes(filter?.subscriberNameOrId.toString()) ||
                row.subscriber?.name
                  ?.toString()
                  ?.toLowerCase()
                  ?.includes(
                    filter?.subscriberNameOrId.toString().toLowerCase()
                  )
            )
            ?.filter(
              (row: { meter: any; name: string }) =>
                !filter?.extractionPointOrMeterName?.length ||
                row.name
                  .toString()
                  .toLowerCase()
                  .includes(
                    filter?.extractionPointOrMeterName.toString().toLowerCase()
                  ) ||
                row.meter?.serialNo
                  .toString()
                  .toLowerCase()
                  .includes(
                    filter?.extractionPointOrMeterName.toString().toLowerCase()
                  )
            )
            ?.map((ep: any) => ({
              ...ep,
              radioButton: (
                <input
                  type="radio"
                  className="h-4 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                  checked={checkedMeter.id === ep.meter.id}
                  onChange={() =>
                    checkedMeter.id === ep.meter.id
                      ? setCheckedMeter({ id: "", serialNo: "" })
                      : setCheckedMeter({
                          id: ep.meter.id,
                          serialNo: ep.meter.serialNo,
                        })
                  }
                />
              ),
              accountNumber: ep.subscriber?.accountNumber,
              accountName: ep.subscriber?.name,
              isActive: (
                <Tag status={ep.meter?.isActive ? "success" : "error"}>
                  {ep.meter?.isActive
                    ? t("common.active")
                    : t("common.inactive")}
                </Tag>
              ),
              meterName: ep.meter?.serialNo,
              location: ep.address?.location,
              meterThreshold:
                ep.meter?.threshold && formatVolume(ep.meter?.threshold, ""),
            }))
            .sort((a: any, b: any) => +a.accountNumber - +b.accountNumber)}
          stickyHeader
        ></Table>
      </section>
      <footer className="border-t border-gray-200 py-4 px-6 flex gap-3">
        <button
          className="btn-primary text-sm font-semibold bg-primary-1"
          disabled={checkedMeter.id === ""}
          onClick={handleSave}
        >
          {t("common.continue")}
        </button>
        <Link
          to={`/polestar`}
          className="btn-default text-sm font-semibold text-primary-1 border-primary-1"
        >
          {t("common.cancel")}
        </Link>
      </footer>
    </>
  );
};

export default UpdateThresholdStep1;

import { useQuery } from "@tanstack/react-query";
import { getAllSubscribers } from "@services/subscriber";

export const useAllSubscribers = ({
  params,
  options,
}: {
  params?: Record<string, any>;
  options?: Record<string, any>;
} = {}) => {
  return useQuery<any>({
    queryKey: ["subscribers", params],
    queryFn: () => getAllSubscribers(params),
    refetchOnWindowFocus: false,
    ...options,
  });
};

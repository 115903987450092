import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ExtractionPointDetails from "./ExtractionPointDetails";
import SelectMeter from "./SelectMeter";
import ConfirmLinkMeter from "./ConfirmLinkMeter";
import WorkflowPanels from "@components/form/WorkflowPanels";
import InfoPanel from "@components/form/InfoPanel";
import StepButton from "@components/shared/StepButton";
import StepInfo from "@components/shared/StepInfo";
import UploadEvidencesWorkflowForm from "@components/form/UploadEvidenceWorkflowForm";
import DeclarationForm, {
  DECLARATION_VARIANTS,
} from "@components/form/declaration/DeclarationForm";
import ConfirmDeclaration from "@components/form/declaration/ConfirmDeclaration";
import {
  EvidenceProvider,
  useEvidenceContext,
} from "@context/shared/EvidenceContext";
import { StepProvider, useStepContext } from "@context/shared/StepContext";
import {
  LinkMeterProvider,
  useLinkMeterContext,
} from "@context/LinkMeterContext";
import {
  DeclarationProvider,
  READING_TYPES,
  useDeclarationContext,
} from "@context/DeclarationContext";
import { useLinkMeterToExtractionPoint } from "@hooks/mutation/useLinkMeterToExtractionPoint";
import { useCreateInitialDeclaration } from "@hooks/mutation/useCreateInitialDeclaration";
import { convertMLToLiter } from "@utils/convertUnits";
import { DBTables } from "@utils/constants/dbTables";

const LinkMeterWorkflow = () => {
  return (
    <StepProvider maxStep={7}>
      <EvidenceProvider>
        <LinkMeterProvider>
          <DeclarationProvider>
            <Consumer />
          </DeclarationProvider>
        </LinkMeterProvider>
      </EvidenceProvider>
    </StepProvider>
  );
};

const Consumer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { currentStep, stepHelpers } = useStepContext();
  const {
    getEvidencesInfo,
    uploadEvidences,
    reset: resetUploadEvidence,
  } = useEvidenceContext();
  const {
    details,
    getExtractionPointDetails,
    getMeterDetails,
    isComplete: isLinkMeterComplete,
    setIsComplete: setIsLinkMeterComplete,
    workflowInstance: linkMeterWorkflowInstance,
    setWorkflowInstance: setLinkMeterWorkflowInstance,
  } = useLinkMeterContext();
  const {
    declaration,
    setDeclaration,
    getDeclarationInfo,
    isComplete: isDeclarationComplete,
    setIsComplete: setIsDeclarationComplete,
    isEstimated,
    workflowInstance: declarationWorkflowInstance,
    setWorkflowInstance: setDeclareWorkflowIntance,
  } = useDeclarationContext();

  const handleCancel = () => navigate(state?.from ?? "/polestar");

  const { mutateAsync: linkMeterMutation } = useLinkMeterToExtractionPoint();
  const { mutateAsync: createInitialDeclarationMutation } =
    useCreateInitialDeclaration();

  const handleLinkMeter = async () => {
    const [res, workflowInstance] = await linkMeterMutation({
      subscriberId: details.subscriber.id,
      extractionPointId: details.extractionPoint.id,
      meterId: details.meter.id,
    });

    await uploadEvidences({
      description: t("extraction_point.link_meter.evidences.description", {
        extractionPointName: details.extractionPoint.name,
        serialNo: details.meter.serialNo,
      }),
      references: [
        {
          referenceId: res.id,
          referenceTable: DBTables.Meters,
        },
        {
          referenceId: details.subscriber.id,
          referenceTable: DBTables.Subscribers,
        },
        {
          referenceId: details.subscriber.level1ResourceId,
          referenceTable: DBTables.WRSHierarchies,
        },
        {
          referenceId: workflowInstance?.id,
          referenceTable: DBTables.WorkflowInstances,
        },
      ],
    });

    setDeclaration((prev: any) => ({
      ...prev,
      subscriber: {
        ...details.subscriber,
      },
      extractionPoint: {
        ...details.extractionPoint,
      },
      meter: {
        ...details.meter,
      },
      type: READING_TYPES.ACTUAL,
    }));
    setLinkMeterWorkflowInstance(workflowInstance);
    setIsLinkMeterComplete(true);
    resetUploadEvidence();
    stepHelpers.goToNextStep();
  };

  const handleInitialDeclaration = async () => {
    const [res, workflowInstance] = await createInitialDeclarationMutation({
      subscriberId: declaration.subscriber.id,
      extractionPointName: declaration.extractionPoint.name,
      serialNo: declaration.meter.serialNo,
      reading: declaration.reading
        ? convertMLToLiter(+declaration?.reading)
        : 0,
      readAt: new Date(declaration.readAt),
      isEstimated,
    });
    await uploadEvidences({
      description: t("declaration.evidence_data.description", {
        serialNo: declaration.meter.serialNo,
        readAt: declaration.readAt,
        extractionPointName: declaration.extractionPoint.name,
      }),
      references: [
        {
          referenceId: res.id,
          referenceTable: DBTables.Declarations,
        },
        {
          referenceId: workflowInstance?.id,
          referenceTable: DBTables.WorkflowInstances,
        },
      ],
    });

    setDeclareWorkflowIntance(workflowInstance);
    setIsDeclarationComplete(true);
  };

  const steps = [
    {
      label: (
        <StepButton
          index={0}
          currentStep={currentStep}
          info={<StepInfo data={getExtractionPointDetails().body} />}
          isOnChain={isLinkMeterComplete}
        >
          {t("extraction_point.link_meter.extraction_point_details")}
        </StepButton>
      ),
      panel: <ExtractionPointDetails onCancel={handleCancel} />,
      infoPanel: (
        <InfoPanel
          actions={
            details.subscriber.level1ResourceId
              ? [
                  {
                    label: t("meter.replace"),
                    action: () => {
                      window.open(
                        `/polestar/level1wrs/${details.subscriber.level1ResourceId}/meters/replace?subscriberId=${details.subscriber.id}`,
                        "_blank",
                      );
                    },
                  },
                  {
                    label: t("meter.deactivate"),
                    action: () => {
                      window.open(
                        `/polestar/level1wrs/${details.subscriber.level1ResourceId}/meters/decommission?subscriberId=${details.subscriber.id}`,
                        "_blank",
                      );
                    },
                  },
                ]
              : []
          }
        >
          <div className="space-y-6">
            <p>
              {t("extraction_point.link_meter.info.extraction_point_details")}
            </p>
            <p>{t("extraction_point.link_meter.info.unmetered")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={1}
          currentStep={currentStep}
          info={<StepInfo data={getMeterDetails().body} />}
          isOnChain={isLinkMeterComplete}
        >
          {t("meter.select_meter")}
        </StepButton>
      ),
      panel: <SelectMeter onCancel={handleCancel} />,
      infoPanel: (
        <InfoPanel
          actions={[
            {
              label: t("meter.add_new_meter"),
              action: () => {
                window.open("/polestar/meters/create", "_blank");
              },
            },
          ]}
        >
          <div className="space-y-6">
            <p>{t("extraction_point.link_meter.info.meter_details")}</p>
            <p>{t("extraction_point.link_meter.info.meters")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={2}
          currentStep={currentStep}
          info={
            isLinkMeterComplete ? null : (
              <StepInfo data={getEvidencesInfo().body} />
            )
          }
          isOnChain={isLinkMeterComplete}
        >
          {t("common.enter_evidence")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          onNext={stepHelpers.goToNextStep}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("extraction_point.link_meter.info.evidence")}</p>
            <p>{t("evidence.supported")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={3}
          currentStep={currentStep}
          isOnChain={isLinkMeterComplete}
        >
          {t("extraction_point.link_meter.confirm")}
        </StepButton>
      ),
      panel: (
        <ConfirmLinkMeter
          workflowInstanceIds={[]}
          onSubmit={handleLinkMeter}
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <InfoPanel
          successes={
            isLinkMeterComplete
              ? [
                  t("extraction_point.link_meter.info.success", {
                    serialNo: details.meter.serialNo,
                    extractionPointName: details.extractionPoint.name,
                  }),
                ]
              : [t("extraction_point.link_meter.info.confirm")]
          }
        >
          {isLinkMeterComplete ? (
            t("extraction_point.link_meter.info.end_workflow")
          ) : (
            <ul className="space-y-4">
              <li>{t("common.info.verify")}</li>
              <li>
                <strong>
                  {t("extraction_point.link_meter.extraction_point_details")}
                </strong>{" "}
                {t(
                  "extraction_point.link_meter.info.verify.extraction_point_details",
                )}
              </li>
              <li>
                <strong>
                  {t("extraction_point.link_meter.meter_to_link")}
                </strong>{" "}
                {t("extraction_point.link_meter.info.verify.meter_to_link")}
              </li>
              <li>
                <strong>{t("evidence.supporting")}:</strong>{" "}
                {t("evidence.info.verify")}
              </li>
              <li>{t("common.info.changes")}</li>
            </ul>
          )}
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={4}
          currentStep={currentStep}
          info={<StepInfo data={getDeclarationInfo().body} />}
        >
          {t("declaration.declare_initial_meter_reading")}
        </StepButton>
      ),
      panel: (
        <DeclarationForm
          variant={DECLARATION_VARIANTS.initial}
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("declaration.info.details")}</p>
            <p>{t("declaration.info.timestamp")}</p>
            <p>{t("declaration.info.is_estimated")}</p>
            <p>{t("declaration.info.enter_reading")}</p>
            <p>{t("declaration.info.initial_reading")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={5}
          currentStep={currentStep}
          info={<StepInfo data={getEvidencesInfo().body} />}
          isOnChain={isDeclarationComplete}
        >
          {t("common.enter_evidence")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          onNext={stepHelpers.goToNextStep}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("declaration.info.evidence")}</p>
            <p>{t("evidence.supported")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={6}
          currentStep={currentStep}
          isOnChain={isDeclarationComplete}
        >
          {t("declaration.form.confirm_read")}
        </StepButton>
      ),
      panel: (
        <ConfirmDeclaration
          workflowInstanceIds={[
            linkMeterWorkflowInstance?.id,
            declarationWorkflowInstance?.id,
          ]}
          onCancel={handleCancel}
          onSubmit={handleInitialDeclaration}
          startAtIndex={4}
        />
      ),
      infoPanel: (
        <InfoPanel
          successes={
            isDeclarationComplete
              ? [
                  t("declaration.info.success", {
                    serialNo: declaration.meter.serialNo,
                    reading: declaration.reading,
                  }),
                ]
              : [t("declaration.info.confirm")]
          }
        >
          {isDeclarationComplete ? (
            t("user.info.end_workflow")
          ) : (
            <ul className="space-y-4">
              <li>{t("common.info.verify")}</li>
              <li>
                <strong>{t("declaration.meter_read_details")}</strong>{" "}
                {t("declaration.info.verify.details")}
              </li>
              <li>
                <strong>{t("evidence.supporting")}:</strong>{" "}
                {t("evidence.info.verify")}
              </li>
              <li>{t("common.info.changes")}</li>
            </ul>
          )}
        </InfoPanel>
      ),
    },
  ];

  return <WorkflowPanels selectedStep={currentStep} steps={steps} />;
};

export default LinkMeterWorkflow;

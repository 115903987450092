import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import PermanentTradeChart from "./PermanentTradeChart";
import TemporaryTradeChart from "./TemporaryTradeChart";
import BidTable from "./BidTable";
import OfferTable from "./OfferTable";

const ExchangesDashboard = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Heading className="mb-6">{t("zenith.exchanges.title")}</Heading>

      <div className="grid lg:grid-cols-2 gap-4 md:gap-6">
        <PermanentTradeChart />

        <TemporaryTradeChart />

        <BidTable />

        <OfferTable />
      </div>
    </div>
  );
};

export default ExchangesDashboard;

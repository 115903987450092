type DropWaterIconProps = {
  className?: string;
};

const DropWaterIcon = ({ className }: DropWaterIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 14 17"
      width="14"
      height="17"
      fill="none"
      aria-hidden="true"
    >
      <path
        fill="currentColor"
        d="M7.55.65a.848.848 0 0 0-1.108 0C2.375 4.233.333 7.35.333 10c0 4.15 3.167 6.833 6.667 6.833S13.667 14.15 13.667 10c0-2.65-2.042-5.767-6.117-9.35Zm-4.025 9.517c.308 0 .558.216.617.516.341 1.85 1.9 2.484 3.033 2.392a.627.627 0 0 1 .658.625.63.63 0 0 1-.6.625c-1.775.108-3.85-.908-4.325-3.433a.625.625 0 0 1 .617-.725Z"
      />
    </svg>
  );
};

export default DropWaterIcon;

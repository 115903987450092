import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { sendCode as authSendCode } from "../../../services/auth";
import Heading from "../../layout/Heading";

type SendCodeFormProps = {
  email: string;
  password: string;
  tel: string;
  onCodeSent: (userData: any) => void;
};

const SendCodeForm: React.FunctionComponent<SendCodeFormProps> = ({
  email,
  password,
  tel,
  onCodeSent,
}) => {
  const { t } = useTranslation();
  const [mfaMethod, setMFAMethod] = useState("phone_number");
  const {
    mutateAsync: sendCode,
    isLoading,
    error,
  } = useMutation<any, any>(() => authSendCode({ email, password, mfaMethod }));

  const handleSendCodeClick = async (e: any) => {
    e.preventDefault();

    const { user } = await sendCode();

    onCodeSent({ password, mfaMethod, ...user });
  };

  return (
    <>
      <Heading className="mb-4 text-2xl">{t("auth.send_code_title")}</Heading>
      <p className="text-sm">{t("auth.send_code_subtitle")}</p>
      <form className="space-y-6" onSubmit={handleSendCodeClick}>
        <fieldset>
          <div className="rounded-md bg-white">
            <label className="border p-4 mt-1 flex cursor-pointer focus:outline-none">
              <input
                type="radio"
                name="mfa-method"
                value="phone_number"
                className="mt-0.5 h-4 w-4 shrink-0 cursor-pointer text-primary-2 border-gray-300 focus:ring-primary-3"
                checked={mfaMethod === "phone_number"}
                onChange={(e) => setMFAMethod(e.target.value)}
              />
              <span className="ml-3 flex flex-col">
                <span className="block text-sm font-medium">
                  {t("auth.send_code_to_tel", { tel })}
                </span>
              </span>
            </label>

            <label className="border p-4 mt-1 flex cursor-pointer focus:outline-none">
              <input
                type="radio"
                name="mfa-method"
                value="email"
                className="mt-0.5 h-4 w-4 shrink-0 cursor-pointer text-primary-2 border-gray-300 focus:ring-primary-3"
                checked={mfaMethod === "email"}
                onChange={(e) => setMFAMethod(e.target.value)}
              />
              <span className="ml-3 flex flex-col">
                <span className="block text-sm font-medium">
                  {t("auth.send_code_to_email", { email })}
                </span>
              </span>
            </label>
          </div>
        </fieldset>
        {error && (
          <p className="mt-0.5 text-sm text-red-600">
            {error.response?.data?.message || t("auth.send_code_error")}
          </p>
        )}
        <div>
          <button
            type="submit"
            className="btn-primary text-sm font-semibold w-full"
            disabled={isLoading}
          >
            {t("auth.send_code")}
          </button>
        </div>
      </form>
    </>
  );
};

export default SendCodeForm;

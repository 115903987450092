import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import Heading1 from "@components/layout/Heading";
import { useGeneralReportContext } from "@context/GeneralReportContext";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import GeneralReportFooter from "./GeneralReportFooter";

const GeneralReportStep1: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { saveData, goToNextStep, reportData } = useGeneralReportContext();
  const [reportName, setReportName] = useState<string>(reportData.name || "");

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    saveData({ name: reportName });
    goToNextStep();
  };

  return (
    <>
      <form onSubmit={handleSave} className="grow flex flex-col">
        <div className="p-4 grow flex flex-col">
          <Heading1 light>{t("reporting.general_step1_title")}</Heading1>
          <fieldset className="mt-4 space-y-4">
            <div className="w-1/3">
              <Label htmlFor="name">{t("reporting.select_name")}</Label>
              <TextInput
                id="name"
                name="name"
                required
                value={reportName}
                onChange={(e: any) => setReportName(e.target.value)}
              />
            </div>
          </fieldset>
        </div>
        <GeneralReportFooter disabled={!reportName} />
      </form>
    </>
  );
};

export default GeneralReportStep1;

import React from "react";
import { useTranslation } from "react-i18next";

import ConfirmModal from "@components/shared/ConfirmModal";
import TextArea from "@components/form/TextArea";
import Label from "@components/form/Label";

type ForwardDrawActionModalsProps = {
  confirmationModal: string;
  setConfirmationModal: (value: string) => void;
  setSampleText: (value: string) => void;
  handleSave: () => void;
  isLoading: boolean;
  isApproving?: boolean;
};

const ForwardDrawActionModals: React.FunctionComponent<
  ForwardDrawActionModalsProps
> = ({
  confirmationModal,
  setConfirmationModal,
  setSampleText,
  handleSave,
  isLoading,
  isApproving = false,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ConfirmModal
        open={confirmationModal === "acknowledge"}
        onClose={() => {
          setSampleText("");
          setConfirmationModal("");
        }}
        onConfirm={handleSave}
        isSubmitting={isLoading}
        confirmText={t("common.confirm") as string}
      >
        {t("approval.forward_draws.approve.modal.acknowledge_title")}

        {isApproving && (
          <div className="mt-8">
            <Label htmlFor="approveNote" optional>
              {t("common.approval_note")}
            </Label>
            <TextArea
              id="approveNote"
              className="text-sm"
              placeholder={t("common.write_approve_info") as string}
              onChange={e => setSampleText(e.target.value)}
            />
          </div>
        )}
      </ConfirmModal>
      <ConfirmModal
        open={confirmationModal === "request_info"}
        onClose={() => {
          setSampleText("");
          setConfirmationModal("");
        }}
        onConfirm={handleSave}
        isSubmitting={isLoading}
        confirmText={
          t("approval.forward_draws.approve.modal.action.request") as string
        }
      >
        {t("approval.forward_draws.approve.modal.request_info_title")}
        <div className="mt-8">
          <Label>{t("common.write_info")}</Label>
          <TextArea
            className="text-sm"
            placeholder={t("common.write_info_placeholder") as string}
            onChange={e => setSampleText(e.target.value)}
          ></TextArea>
        </div>
      </ConfirmModal>
      <ConfirmModal
        open={confirmationModal === "reject"}
        onClose={() => {
          setSampleText("");
          setConfirmationModal("");
        }}
        onConfirm={handleSave}
        isSubmitting={isLoading}
        confirmText={
          t("approval.forward_draws.approve.modal.action.reject") as string
        }
      >
        {t("approval.forward_draws.approve.modal.reject_title")}
        <div className="mt-8">
          <Label>{t("common.reason_for_rejection")}</Label>
          <TextArea
            className="text-sm"
            placeholder={t("common.write_info_placeholder") as string}
            onChange={e => setSampleText(e.target.value)}
          ></TextArea>
        </div>
      </ConfirmModal>
    </>
  );
};

export default ForwardDrawActionModals;

import axios from "axios";
import ENV from "../config/env";
import encryptedStorage from "./encryptedStorage";

const axiosInstance = axios.create({
  baseURL: ENV.API_HOST,
});

axiosInstance.interceptors.request.use((requestConfig) => {
  const newConfig = { ...requestConfig };
  const token = encryptedStorage.getItem("token");

  if (token && newConfig.headers)
    newConfig.headers.Authorization = `Bearer ${token}`;

  return newConfig;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { pathname: url } = window.location;
    const { status, config } = error.response;

    if (status === 401) {
      if (url.startsWith("/zenith")) {
        window.location.href = config.headers.Authorization
          ? "/zenith/logout"
          : "/zenith/login";
      } else {
        window.location.href = config.headers.Authorization
          ? "/polestar/logout"
          : "/polestar/login";
      }
    }
    throw error;
  }
);

export default axiosInstance;

import Flags from "country-flag-icons/react/3x2";

const languages = [
  {
    languageCode: "en",
    flagIcon: Flags.GB,
  },
  {
    languageCode: "de",
    flagIcon: Flags.DE,
  },
  {
    languageCode: "fr",
    flagIcon: Flags.FR,
  },
  {
    languageCode: "th",
    flagIcon: Flags.TH,
  },
];

export default languages;

import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Layout from "@components/layout/Layout";
import AdjustDeclarationWorkflow from "@components/form/declaration/AdjustDeclarationWorkflow";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";

const AdjustDeclaration = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const level1ResourceId = searchParams.get("level1ResourceId") ?? "";
  const { data: level1Resource = {} } = useGetLevel1Resource(level1ResourceId, {
    enabled: Boolean(level1ResourceId),
  });

  return (
    <Layout
      permissions={["CreateBalanceAdjustments"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: level1Resource?.name,
          href: `/polestar/level1wrs/${level1ResourceId}`,
        },
        {
          label: t("level1wrs.extraction_points"),
          href: `/polestar/level1wrs/${level1ResourceId}#5`,
        },
        {
          label: t("balance_adjustment.meter_reading.action"),
        },
      ]}
      title={t("balance_adjustment.meter_reading.reset")}
      accountingPeriodLevel1ResourceId={level1ResourceId}
    >
      <AdjustDeclarationWorkflow />
    </Layout>
  );
};

export default AdjustDeclaration;

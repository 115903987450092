import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import Table from "@components/layout/Table";
import { Link } from "react-router-dom";
import ExternalLinkIcon from "@components/icons/ExternalLinkIcon";
import { useAllLevel1Resources } from "@hooks/query/useAllLevel1Resources";

type Level1WRS = Record<string, any>;

type SelectLevel1WRSTableProps = {
  onSelect: (item: Level1WRS) => void;
  selected?: Level1WRS;
  excludedLevel1wrsId?: string;
  disableSelection?: boolean;
};

const SelectSingleLevel1WRSTable: React.FunctionComponent<
  SelectLevel1WRSTableProps
> = ({
  onSelect,
  selected,
  excludedLevel1wrsId = "",
  disableSelection = false,
}) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState("");
  const { data: level1Resources, isLoading } = useAllLevel1Resources({
    select: (level1Resources: any[]) => {
      return level1Resources.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    },
  });

  const tableFields = [
    {
      title: "",
      name: "radio",
    },
    {
      title: t("common.name"),
      name: "name",
    },
    {
      title: t("common.identifier"),
      name: "identifier",
    },
    {
      title: "",
      name: "view",
    },
  ];

  const tableData = level1Resources
    ?.filter((level1wrs: any) => {
      const matcher = filter.toLowerCase();
      return (
        excludedLevel1wrsId !== level1wrs.id &&
        (level1wrs?.identifier?.toLowerCase()?.includes(matcher) ||
          level1wrs?.name?.toLowerCase().includes(matcher))
      );
    })
    .map((level1wrs: any) => {
      return {
        id: level1wrs.id,
        radio: (
          <input
            type="radio"
            name="level1wrs_id"
            checked={selected?.id === level1wrs?.id}
            onChange={() => onSelect(level1wrs)}
            disabled={disableSelection}
          />
        ),
        name: level1wrs?.name,
        identifier: level1wrs?.identifier,
        view: (
          <Link
            to={`/polestar/level1wrs/${level1wrs.id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="btn-default flex items-center gap-3 w-min"
          >
            <ExternalLinkIcon className="w-4 h-4" />
            {t("common.view")}
          </Link>
        ),
      };
    });

  return (
    <>
      <div className="pb-0 flex flex-col gap-4 grow">
        <fieldset className="max-w-xs">
          <Label htmlFor="scheme_id">
            {t("level1wrs.search_name_identifier")}
          </Label>
          <SearchInput onChange={(e) => setFilter(e.target.value)} />
        </fieldset>
        <Table
          fields={tableFields}
          data={tableData}
          stickyHeader
          loading={isLoading}
          noRowsText={t("common.no_data")}
        ></Table>
      </div>
    </>
  );
};

export default SelectSingleLevel1WRSTable;

import { useQuery } from "@tanstack/react-query";
import { getExtractionPointById } from "@services/extractionPoints";

export const useExtractionPoint = (
  id: string,
  options?: Record<string, any>,
) => {
  return useQuery<any>({
    queryKey: ["extraction_points", id],
    queryFn: () => getExtractionPointById(id),
    enabled: Boolean(id),
    ...options,
  });
};

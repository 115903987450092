import { useTranslation } from "react-i18next";
import Layout from "@components/layout/Layout";
import InfoPanel from "@components/form/InfoPanel";
import WorkflowPanels from "@components/form/WorkflowPanels";
import EnterDistributionLossDetails from "@components/form/distribution_losses/EnterDistributionLossDetails";
import LedgerDistributionLoss from "@components/form/distribution_losses/LedgerDistributionLoss";
import UploadEvidencesWorkflowForm from "@components/form/UploadEvidenceWorkflowForm";
import WorkflowComplete from "@components/shared/WorkflowComplete";
import StepButton from "@components/shared/StepButton";
import StepInfo from "@components/shared/StepInfo";
import {
  CreateDistributionLossProvider,
  useCreateDistributionLossContext,
} from "@context/CreateDistributionLossContext";
import { StepProvider, useStepContext } from "@context/shared/StepContext";
import {
  EvidenceProvider,
  useEvidenceContext,
} from "@context/shared/EvidenceContext";
import DistributionLossHistoryTable from "@components/form/distribution_losses/DistributionLossHistoryTable";
import InformationCircleIcon from "@components/icons/InformationCircleIcon";
import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";

const CreateDistributionLoss = () => {
  return (
    <StepProvider maxStep={3}>
      <EvidenceProvider>
        <CreateDistributionLossProvider>
          <MainComponent />
        </CreateDistributionLossProvider>
      </EvidenceProvider>
    </StepProvider>
  );
};

const MainComponent = () => {
  const { t } = useTranslation();
  const {
    getDetails,
    isComplete,
    handleCancel,
    distributionLossData,
    workflowInstance,
    currentAccountingPeriod,
  } = useCreateDistributionLossContext();
  const { currentStep, stepHelpers } = useStepContext();
  const { getEvidencesInfo } = useEvidenceContext();

  const steps = [
    {
      label: (
        <StepButton
          index={0}
          currentStep={currentStep}
          isOnChain={isComplete}
          info={<StepInfo data={getDetails().body} />}
        >
          {t("distribution_losses.enter_volume")}
        </StepButton>
      ),
      panel: <EnterDistributionLossDetails />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>{t("distribution_losses.info.select_level1_resource")}</p>
            <p>{t("distribution_losses.info.select_account")}</p>
            <p>{t("distribution_losses.info.enter_data")}</p>
            <p>{t("distribution_losses.info.enter_date")}</p>
            <div className="flex gap-2">
              <InformationCircleIcon className="text-blue-600 w-5 h-5 shrink-0" />
              {t(
                `distribution_losses.info.${
                  currentAccountingPeriod
                    ? "enter_date_info_1_A"
                    : "enter_date_info_1"
                }`,
                {
                  currentAccountingPeriod: formatAccountingPeriod(
                    currentAccountingPeriod
                  ),
                }
              )}
            </div>
            <div className="flex gap-2">
              <InformationCircleIcon className="text-blue-600 w-5 h-5 shrink-0" />
              {t("distribution_losses.info.enter_date_info_2")}
            </div>
            <DistributionLossHistoryTable
              previousDeclarations={distributionLossData.previousDeclarations}
            />
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={1}
          currentStep={currentStep}
          isOnChain={isComplete}
          info={<StepInfo data={getEvidencesInfo().body} />}
        >
          {t("evidence.upload")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          onNext={stepHelpers.goToNextStep}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>{t("distribution_losses.info.select_file")}</p>
            <p>{t("distribution_losses.info.evidence")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton index={2} currentStep={currentStep} isOnChain={isComplete}>
          {t("distribution_losses.ledger")}
        </StepButton>
      ),
      panel: isComplete ? (
        <WorkflowComplete references={[workflowInstance?.id]} />
      ) : (
        <LedgerDistributionLoss />
      ),
      infoPanel: (
        <InfoPanel
          successes={
            isComplete
              ? [
                  t("distribution_losses.info.success", {
                    volume: `${distributionLossData.volume} ${t(
                      "common.volume_unit"
                    )}`,
                    extractionRightName:
                      distributionLossData.extractionRight.name,
                  }),
                ]
              : [t("distribution_losses.info.complete")]
          }
        >
          <div className="space-y-4"></div>
        </InfoPanel>
      ),
    },
  ];

  return (
    <Layout
      permissions={["ManageDistributionLosses"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("distribution_losses.title"),
        },
      ]}
      title={t("distribution_losses.title")}
      accountingPeriodLevel1ResourceId={distributionLossData.level1Resource.id}
    >
      <WorkflowPanels selectedStep={currentStep} steps={steps} />
    </Layout>
  );
};

export default CreateDistributionLoss;

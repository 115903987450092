import { useQuery } from "@tanstack/react-query";

import { getCurrentAccountingPeriodCheck } from "@services/accountingPeriodChecks";

export const useAccountingPeriodCheck = ({
  params,
  ...options
}: {
  params: any;
  [key: string]: any;
}) => {
  return useQuery<any>({
    queryKey: ["accountingPeriodChecks", params],
    queryFn: () => getCurrentAccountingPeriodCheck({ params }),
    ...options,
  });
};

import React from "react";
import { noop } from "lodash";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import ForwardDrawActionModals from "@components/modal/ForwardDrawActionModals";
import { useStepContext } from "@context/shared/StepContext";
import { toastSuccess, toastError } from "@utils/toast";
import ConfirmationDetail, {
  type ConfirmData,
} from "@components/shared/ConfirmationDetail";

type ForwardDrawLedgerProps = {
  onCancel: () => void;
  onConfirm: () => Promise<void>;
  data: ConfirmData[];
};

const ForwardDrawLedger: React.FunctionComponent<ForwardDrawLedgerProps> = ({
  onCancel,
  onConfirm,
  data,
}) => {
  const { t } = useTranslation();
  const { stepHelpers } = useStepContext();
  const [confirmationModal, setConfirmationModal] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      await onConfirm();
      setConfirmationModal("");
      toastSuccess(t("approval.forward_draws.approve.toast.create"));
    } catch (error: any) {
      toastError(t("approval.forward_draws.approve.toast.error"));
    }
    setIsLoading(false);
    setConfirmationModal("");
  };

  return (
    <>
      <div className="flex flex-col gap-4 grow">
        <div className="p-6 space-y-4">
          <Heading className="text-xl leading-6" light>
            {t("approval.forward_draws.approve.review_application")}
          </Heading>
          <ConfirmationDetail data={data} onEdit={stepHelpers.setStep} />
        </div>

        <div className="grow" />

        <footer className="flex gap-4 p-6 border-t border-gray-200">
          <button
            type="button"
            className="btn-outline-primary"
            onClick={stepHelpers.goToPrevStep}
          >
            {t("common.prev_step")}
          </button>
          <button
            type="button"
            className="btn-primary"
            disabled={isLoading}
            onClick={() => {
              setConfirmationModal("acknowledge");
            }}
          >
            {t("common.ledger")}
          </button>
          <button
            type="button"
            className="btn-outline-primary"
            onClick={onCancel}
          >
            {t("common.cancel")}
          </button>
        </footer>
      </div>

      <ForwardDrawActionModals
        confirmationModal={confirmationModal}
        setConfirmationModal={setConfirmationModal}
        setSampleText={noop}
        handleSave={handleSave}
        isLoading={isLoading}
      />
    </>
  );
};

export default ForwardDrawLedger;

import React from "react";
import { useParams } from "react-router-dom";
import { ArrowsRightLeftIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

import TransactionHistoryTable from "@components/form/transactionHistory/TransactionHistoryTable";
import Heading from "@components/layout/Heading";
import Loading from "@components/shared/Loading";
import { useAllEventTransactions } from "@hooks/query/useAllEventTransactions";
import { orderBy } from "lodash";
import { useSubscriber } from "@hooks/query/useSubscriber";
import { useAppContext } from "@context/AppContext";

const TransactionHistoryTab: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { setInfoPanel } = useAppContext();
  const { id: subscriberId = "", level0ResourceId = "" } = useParams();
  const { data: subscriber = {}, isLoading: isSubscriberLoading } =
    useSubscriber(subscriberId);
  const { data = {}, isLoading: isEventTransactionsLoading } =
    useAllEventTransactions({
      params: {
        walletId: subscriber.walletId,
        includeParentId: true,
        limit: -1,
      },
      enabled: Boolean(subscriber.walletId),
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    });

  React.useEffect(() => {
    setInfoPanel();
  }, [setInfoPanel]);

  if (isSubscriberLoading || isEventTransactionsLoading) {
    return (
      <div className="pt-20">
        <Loading />
      </div>
    );
  }

  const eventTransactions = data.eventTransactions ?? [];

  const filtered = eventTransactions?.filter(
    (log: any) =>
      Boolean(log.transactionAmount) &&
      Number(log.transactionAmount) !== 0 &&
      log.impactLevel0ResourceId === level0ResourceId &&
      log.eventType?.name !== "OveruseAdjustmentTokenTransfer"
  );

  return (
    <div className="flex flex-col grow space-y-4">
      <Heading className="flex items-center gap-2">
        <ArrowsRightLeftIcon className="w-6 h-6 shrink-0" />{" "}
        {t("audit_trail.widget_title")}
      </Heading>
      <TransactionHistoryTable
        eventTransactions={orderBy(filtered, ["effectiveAt"], ["desc"])}
        stickyHeader
      />
    </div>
  );
};

export default TransactionHistoryTab;

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import CreateSubscriberWorkflow from "@components/form/subscriber/create/CreateSubscriberWorkflow";
import Layout from "@components/layout/Layout";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";

const Level1WRSCreateSubscriber = () => {
  const { t } = useTranslation();
  const { id: level1ResourceId = "" } = useParams();
  const { data: level1Resource } = useGetLevel1Resource(level1ResourceId);

  return (
    <Layout
      permissions={["CreateSubscribers"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: level1Resource?.name,
          href: `/polestar/level1wrs/${level1ResourceId}`,
        },
        {
          label: t("common.subscribers"),
          href: `/polestar/level1wrs/${level1ResourceId}#2`,
        },
        {
          label: t("subscriber.add_subscriber"),
        },
      ]}
      title={t("subscriber.add_subscriber")}
      accountingPeriodLevel1ResourceId={level1ResourceId}
    >
      <CreateSubscriberWorkflow />
    </Layout>
  );
};

export default Level1WRSCreateSubscriber;

import {
  CreateSpecialAnnouncementPayload,
  createSpecialAnnouncement,
} from "@services/specialAnnouncements";
import { useMutation } from "@tanstack/react-query";

export const useCreateSpecialAnnouncement = (options: any = {}) => {
  return useMutation<any, any, CreateSpecialAnnouncementPayload>({
    mutationKey: ["create_special_announcement"],
    mutationFn: createSpecialAnnouncement,
    ...options,
  });
};

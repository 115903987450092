import SelectExtractionRights from "@components/form/admin_approvals/create_forward_draw/SelectExtractionRights";
import { useForwardDrawContext } from "@context/ForwardDrawContext";
import { useAllExtractionRights } from "@hooks/query/zenith/useAllExtractionRights";
import { extractionRightTypes } from "@services/extractionRight";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type WorkflowCreateForwardDrawStep1Props = {
  onSubmit: () => void;
};

const WorkflowCreateForwardDrawStep1: FunctionComponent<
  WorkflowCreateForwardDrawStep1Props
> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { details, setDetails } = useForwardDrawContext();

  const { data: extractionRights = [] } = useAllExtractionRights({
    params: {
      typeIds: [extractionRightTypes.WA, extractionRightTypes.WSA],
    },
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form className="flex flex-col grow" onSubmit={handleSubmit}>
      <div className="flex grow gap-4">
        <SelectExtractionRights
          extractionRights={extractionRights}
          selectedExtractionRights={details.extractionRights}
          setSelectedExtractionRights={(extractionRights: any[]) => {
            setDetails({
              ...details,
              extractionRights,
            });
          }}
        />
      </div>
      <footer className="p-4 flex gap-2">
        <button
          type="submit"
          className="btn-primary text-sm font-semibold"
          disabled={!details.extractionRights?.length}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-default text-sm font-semibold"
          onClick={() => navigate("/zenith/workflows")}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default WorkflowCreateForwardDrawStep1;

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Layout from "@components/layout/Layout";
import UpdateLevel0WRSWorkflow from "@components/form/level0_wrs/create_level0_wrs/UpdateLevel0WRSWorkflow";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";
import { useLevel0Resource } from "@hooks/query/useLevel0Resource";

const UpdateLevel0WRS = () => {
  const { t } = useTranslation();
  const { id: level1ResourceId = "", level0wrsId = "" } = useParams();
  const { data: level1Resource } = useGetLevel1Resource(level1ResourceId);
  const { data: level0Resource } = useLevel0Resource(level0wrsId);

  return (
    <Layout
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: level1Resource?.name,
          href: `/polestar/level1wrs/${level1ResourceId}`,
        },
        {
          label: t("level1wrs.zone_and_classes"),
          href: `/polestar/level1wrs/${level1ResourceId}#1`,
        },
        {
          label: level0Resource?.identifier,
          href: `/polestar/level1wrs/${level1ResourceId}#1`,
        },
        {
          label: t("level0wrs.update.title"),
        },
      ]}
      title={t("level0wrs.update.title")}
      permissions={["CreateLevel0Resource"]}
      accountingPeriodLevel1ResourceId={level1ResourceId}
    >
      <UpdateLevel0WRSWorkflow />
    </Layout>
  );
};

export default UpdateLevel0WRS;

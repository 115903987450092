import React from "react";

const WaterLedgerLogo = () => {
  return (
    <svg
      width="25"
      height="29"
      viewBox="0 0 25 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="25" height="29" fill="url(#pattern0)" />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_878_36076"
            transform="translate(-0.0075) scale(0.0103571 0.00892857)"
          />
        </pattern>
        <image
          id="image0_878_36076"
          width="98"
          height="112"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGIAAABwCAYAAADlu6WeAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAT8SURBVHgB7Z3hlZtGFIXvyUkB24EmFcSuAFKBnQqUVJCkApQKklSgTQW2K4AO7A5WrmC3gzXXI3xYWYg3MAMPeN8582OP0Iidb5jLCDEAy+SuLse6PNTlZxiTQwFFXR7r8twqlLKDMQlv4I+A5xuFku5gJCGrS4nbAtqFsvYwotHkwPPAQiEZjMF05cDQQpk7GEFwSGFPljTwP4HbU64J6SGDPAe4XfuU1cFLsfwYgYM8B9iIecS6MhhBOfB43laK5DTX8gPhOTB0XmD50UGG4TkwFAfLj284xMuBNhQrbTiHDedHqhxgve1ezobbCd+7ufxgT42dA31iQxoudP8WJyRDmhxgvdKGkw4rDl6sdLjaYwG4urxDmhwohfWWAfVe7vsR8n1/A4WE5sAfAfWGNE6O8eRYaH7sIf9iLlYOXIotAuqVNhz/L6mQWfMjQ9hwId3RkDOakIleW6xUnIPi/HBYVg5kuC42pOEcFOXH0nKAZ2KlsE7pUZVDPgzzf9ohMnssKwdSfJ3hEHaptt0eO4wkw3JzIKbYQrivMYS/wEEugB+SC+vNMG0OXCtHyDsMh9dYl2oHCSkElS4pB4aI/Sisd0g5QtgZip6K+LrlwARCCnT3KpFJpLvwk0psAQQ1YqzygBsdpWunJGSY/wu/I+bLgaElv7ZzBcJFOKS78FMK6y2hJwcmFzHVhZ8+sdLZrMO0OTCZiKPwg5Y00VuliBLpckAa8FpyYBYRD1jWRG+VIh4hY2s50CviR8Tlqed1CuBw8Sf6hxfW9W9d/oOs3uJc7yKJLeIWWV3u4XttH9zuL/QLIBR7wMLvFPoB08DeWqFfArf5pS6/o19CkwM8ahZ/u9ZUR4Tref0EP6x8gKyuI+L8YEANUx0RXbDXH+ryGv0SmoDnUZBjZUyZEZd8gh+GJDnQBPFq7xidU8QTZDlwD1nAL5q5h6Yumgs/FTYggWgUwWGIw1aODaFRRI4NonVo2hwmQgkmQgkmQgkmQgkmQgkmQgkmQgkmQgkmQgkmQgkmQgkmQgkmQgkmQgkmQgkmQgkmQgkmQgkmQgkmQgkmQgkmQgkmQgkmQgkmQgkmQgkmQgkmQgkmQgltEa4ubzu2K2AkpRHBO3S4dM6rju0O8HfwGImgCFeX9+i/UTCHv6vTSABF7CG/T23Vd3bOCUW8DXzPHkZ0KOJV4HtCtzcEUITkhvM2odsbAijiU9hbgrc3BFDE+4DtT/A3oRuRoQguTCXt5VyS5zOMGHCI51loxT+aCd2v8L39Fgd4acZ42KF/Qqs9GxGn8wu/4eXRQWsV/Coyf8MYSwU/Z/tudbbL1Wn+P5e7cznBiMEJflW2qmuDrm9fn2ASYtDkAEeb6taGc67XtHYOkK3Q+RUTEZ8KPmuDzi7twlA8mqXxWIJP8U3EeJoc4AKRFQYyRgRn5CdsmwMu5gNDGSOCy4O+Pu/M1qjg5wOcW0X5EnTs0PR03hn2inusn1E5cItYZ00n+AmLwzppFgpO9hVP7NPXE9bHAQHzgaHYPKKbCgPmA0OZ6vR1SReTKiTKAS04vHzcTNfvpErM82STkEd/rgL+CoRCtIgIeYLXKul6btCUIvhZOxhXmUIEPyOHcZOUIjaXA2N4hzQCCtjPRYNho8V6omIJy4FROMifh2o5MAEOYY895pG0h5EMNu4DLAfUcC0/GPCWAzPg4PNjNTnwBbnxTcvEDTzaAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  );
};

export default WaterLedgerLogo;

import { useQuery } from "@tanstack/react-query";

import { getAllNotifications } from "@services/notification";

type Params = {
  recipientWalletId: string;
  level1ResourceId: string;
  onlyNew: 1;
  read: 1;
  outbox: 1;
  limit: number;
  page: number;
  fromDate?: Date;
  toDate?: Date;
};

type UseAllNotificationParams = {
  params?: Partial<Params>;
  [key: string]: any;
};

export const useAllNotification = ({
  params,
  ...options
}: UseAllNotificationParams = {}) => {
  return useQuery<any>({
    queryKey: ["notifications", params],
    queryFn: () => getAllNotifications(params),
    ...options,
  });
};

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Layout from "@components/layout/Layout";
import CreateExtractionPointWorkflow from "@components/form/extraction_point/CreateExtractionPointWorkflow";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";

const Level1WRSCreateExtractionPoint = () => {
  const { t } = useTranslation();
  const { id: level1ResourceId = "" } = useParams();
  const { data: level1Resource = {} } = useGetLevel1Resource(level1ResourceId);

  return (
    <Layout
      permissions={["CreateExtractionPoint"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: level1Resource?.name,
          href: `/polestar/level1wrs/${level1ResourceId}`,
        },
        {
          label: t("common.extraction_points"),
          href: `/polestar/level1wrs/${level1ResourceId}#5`,
        },
        {
          label: t("extraction_point.create.title"),
        },
      ]}
      title={t("extraction_point.create.title")}
      accountingPeriodLevel1ResourceId={level1ResourceId}
    >
      <CreateExtractionPointWorkflow />
    </Layout>
  );
};

export default Level1WRSCreateExtractionPoint;

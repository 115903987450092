import api from "@services/api";

export const getAllSubscriberExtractionPoints = async ({
  params,
}: {
  params?: Record<string, any>;
} = {}) => {
  const { data } = await api.get("/extractionPoints", { params });

  return data.extractionPoints;
};

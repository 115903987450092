import classNames from "classnames";
import { twMerge } from "tailwind-merge";

type PaginationProps = {
  pageNo: number;
  lastPage: number;
  onPageChange: (i: number) => void;
  className?: string;
};

const Pagination: React.FunctionComponent<PaginationProps> = ({
  pageNo,
  lastPage,
  onPageChange,
  className,
}) => {
  return (
    <div
      className={twMerge(
        "flex items-center justify-center bg-white px-4 pt-3 sm:px-6 w-full",
        className
      )}
    >
      <nav
        className="isolate inline-flex -space-x-px rounded-md shadow-sm"
        aria-label="Pagination"
      >
        <button
          type="button"
          className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
          onClick={() => pageNo > 0 && onPageChange(pageNo - 1)}
        >
          <span className="sr-only">Previous</span>

          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        {lastPage > 8 ? (
          <>
            {Array.from(Array(3).keys()).map((_, i) => (
              <button
                key={i}
                type="button"
                aria-current="page"
                className={classNames(
                  "relative inline-flex items-center border px-4 py-2 text-sm font-medium",
                  {
                    "border-primary-3 bg-blue-50 text-primary-3 z-20":
                      pageNo === i,
                    "border-gray-300 bg-white text-gray-500 hover:bg-gray-50 z-10":
                      pageNo !== i,
                  }
                )}
                onClick={() => onPageChange(i)}
              >
                {i + 1}
              </button>
            ))}
            {pageNo > 3 && (
              <button className="relative inline-flex items-center border px-4 py-2 text-sm font-medium cursor-default border-gray-300 bg-white text-gray-500">
                ...
              </button>
            )}
            {pageNo >= 3 && pageNo < lastPage - 3 && (
              <button className="relative inline-flex items-center border px-4 py-2 text-sm font-medium cursor-default border-primary-3 bg-blue-50 text-primary-3 z-20">
                {pageNo + 1}
              </button>
            )}
            {pageNo < lastPage - 4 && (
              <button className="relative inline-flex items-center border px-4 py-2 text-sm font-medium cursor-default border-gray-300 bg-white text-gray-500">
                ...
              </button>
            )}
            {Array.from(Array(lastPage).keys())
              .slice(-3)
              .map((i) => (
                <button
                  key={i}
                  type="button"
                  aria-current="page"
                  className={classNames(
                    "relative inline-flex items-center border px-4 py-2 text-sm font-medium",
                    {
                      "border-primary-3 bg-blue-50 text-primary-3 z-20":
                        pageNo === i,
                      "border-gray-300 bg-white text-gray-500 hover:bg-gray-50 z-10":
                        pageNo !== i,
                    }
                  )}
                  onClick={() => onPageChange(i)}
                >
                  {i + 1}
                </button>
              ))}
          </>
        ) : (
          Array.from(Array(lastPage).keys()).map((_, i) => (
            <button
              key={i}
              type="button"
              aria-current="page"
              className={classNames(
                "relative inline-flex items-center border px-4 py-2 text-sm font-medium",
                {
                  "border-primary-3 bg-blue-50 text-primary-3 z-20":
                    pageNo === i,
                  "border-gray-300 bg-white text-gray-500 hover:bg-gray-50 z-10":
                    pageNo !== i,
                }
              )}
              onClick={() => onPageChange(i)}
            >
              {i + 1}
            </button>
          ))
        )}
        <button
          type="button"
          className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
          onClick={() => pageNo < lastPage - 1 && onPageChange(pageNo + 1)}
        >
          <span className="sr-only">Next</span>
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </nav>
    </div>
  );
};

export default Pagination;

import { useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import { useAllExtractionRights } from "@hooks/query/zenith/useAllExtractionRights";
import { formatVolume } from "@utils/formatVolume";

type ExtractionRight = Record<string, any>;

type SelectSingleExtractionRightTableForZenithProps = {
  onSelect: (item: ExtractionRight) => void;
  selected?: ExtractionRight;
};

const SelectSingleExtractionRightTableForZenith: React.FunctionComponent<
  SelectSingleExtractionRightTableForZenithProps
> = ({ onSelect, selected }) => {
  const { t } = useTranslation();
  const { data: extractionRights, isLoading } = useAllExtractionRights({
    params: {
      isActive: true,
    },
  });

  const tableFields = [
    {
      title: "",
      name: "radio",
    },
    {
      title: t("extraction_right.number"),
      name: "name",
    },
    {
      title: t("common.level0wrs"),
      name: "level0wrs",
    },
    {
      title: t("water_class.water_class_header"),
      name: "waterClass",
    },
    {
      title: t("extraction_right.volume"),
      name: "nominalVolume",
    },
  ];

  const tableData = extractionRights?.map((extractionRight: any) => {
    return {
      id: extractionRight.id,
      radio: (
        <input
          type="radio"
          name="level1wrs_id"
          checked={selected?.id === extractionRight?.id}
          onChange={() => onSelect(extractionRight)}
        />
      ),
      name: extractionRight?.name,
      nominalVolume: formatVolume(extractionRight?.volume),
      waterClass: extractionRight?.waterClass?.name,
      level0wrs: extractionRight?.level0Resource?.identifier,
    };
  });

  return (
    <>
      <div className="pb-0 flex flex-col gap-4 grow">
        <Table
          fields={tableFields}
          data={tableData}
          stickyHeader
          loading={isLoading}
          noRowsText={t("subscriber.has_no_extraction_rights")}
        ></Table>
      </div>
    </>
  );
};

export default SelectSingleExtractionRightTableForZenith;

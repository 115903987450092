import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Countdown from "react-countdown";
import { useTranslation } from "react-i18next";

import { useAppContext } from "../../context/AppContext";

import AuthLayout from "../layout/AuthLayout";
import encryptedStorage from "../../services/encryptedStorage";
import { useMutation } from "@tanstack/react-query";
import { logout } from "@services/auth";

const Logout: React.FunctionComponent = () => {
  const { clearLoggedInInfo, loggedInInfo } = useAppContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutateAsync: logoutMutation } = useMutation<any, any, { id: string }>(
    (logoutData) => logout(logoutData)
  );

  useEffect(() => {
    clearLoggedInInfo();
    if (encryptedStorage.getItem("userDetails")) {
      logoutMutation({
        id: encryptedStorage.getItem("userDetails").id,
      });
    }

    encryptedStorage.removeItem("isLoggedIn");
    encryptedStorage.removeItem("token");
    encryptedStorage.removeItem("userDetails");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loggedInInfo || loggedInInfo.userDetails?.isAdmin)
    return <Navigate to="../login" />;

  return (
    <AuthLayout>
      <div className="flex min-h-full flex-col justify-center">
        <div className="sm:mx-auto w-full text-white text-xl text-center">
          {t("auth.logout_title")}
        </div>
        <Countdown
          date={Date.now() + 10000}
          onComplete={() => {
            navigate("../login");
          }}
          renderer={({ seconds }) => (
            <div className="sm:mx-auto w-full text-white text-4xl text-center my-4 font-bold">
              {seconds}
            </div>
          )}
        />
      </div>
    </AuthLayout>
  );
};

export default Logout;

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ActionsButton from "@components/shared/DataTable/ActionsButton";
import { AdministrativeApprovalNumericStatus } from "@services/administrativeApprovals";

const ApprovalTableActionButtons: React.FunctionComponent<{ row: any }> = ({
  row,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ActionsButton
      menus={[
        {
          label: t("common.view"),
          onClick: () => navigate(`?id=${row.id}`),
          enable: true,
        },
        {
          label: t("common.response"),
          to: `/zenith/workflows/response_approval_info_request?selectedId=${row.id}`,
          enable:
            row.original.status ===
            AdministrativeApprovalNumericStatus.UnderReview,
        },
      ].filter((i) => Boolean(i.enable))}
    />
  );
};

export default ApprovalTableActionButtons;

import { useTranslation } from "react-i18next";

type Props = {
  htmlFor?: string;
  className?: string;
  children: React.ReactNode;
  optional?: boolean;
};

export default function Label({
  className = "",
  optional = false,
  children,
  ...restProps
}: Props) {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between mb-1">
      <label
        className={`text-sm leading-6 text-gray-700 ${className}`}
        {...restProps}
      >
        {children}
      </label>

      {optional ? (
        <span className="text-sm leading-6 text-gray-500" id="email-optional">
          {t("common.optional")}
        </span>
      ) : null}
    </div>
  );
}

type Props = {
  className?: string;
};

const ClockRotateIcon: React.FunctionComponent<Props> = ({ className }) => {
  return (
    <svg
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_62_4925)">
        <path
          d="M2.92969 2.92969L1.60156 1.60156C1.01172 1.01172 0 1.42969 0 2.26172V6.5625C0 7.08203 0.417969 7.5 0.9375 7.5H5.23828C6.07422 7.5 6.49219 6.48828 5.90234 5.89844L4.69922 4.69531C6.05469 3.33984 7.92969 2.5 10 2.5C14.1406 2.5 17.5 5.85938 17.5 10C17.5 14.1406 14.1406 17.5 10 17.5C8.40625 17.5 6.92969 17.0039 5.71484 16.1562C5.14844 15.7617 4.37109 15.8984 3.97266 16.4648C3.57422 17.0312 3.71484 17.8086 4.28125 18.207C5.90625 19.3359 7.87891 20 10 20C15.5234 20 20 15.5234 20 10C20 4.47656 15.5234 0 10 0C7.23828 0 4.73828 1.12109 2.92969 2.92969ZM10 5C9.48047 5 9.0625 5.41797 9.0625 5.9375V10C9.0625 10.25 9.16016 10.4883 9.33594 10.6641L12.1484 13.4766C12.5156 13.8438 13.1094 13.8438 13.4727 13.4766C13.8359 13.1094 13.8398 12.5156 13.4727 12.1523L10.9336 9.61328V5.9375C10.9336 5.41797 10.5156 5 9.99609 5H10Z"
          fill="#004A88"
        />
      </g>
      <defs>
        <clipPath id="clip0_62_4925">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ClockRotateIcon;

import React from "react";

type Message = {
  messageTemplate: any;
  subject: string;
  body: string;
  evidence?: File;
};

type ContextValue = {
  message: Message;
  setMessage: React.Dispatch<Message>;
  workflowInstance: any;
  setWorkflowInstance: React.Dispatch<any>;
};

const MessageContext = React.createContext<ContextValue | undefined>(undefined);

const MessageProvider = ({ children }: { children: React.ReactNode }) => {
  const [message, setMessage] = React.useState<any>();
  const [workflowInstance, setWorkflowInstance] = React.useState<any>();

  return (
    <MessageContext.Provider
      value={{
        message,
        setMessage,
        workflowInstance,
        setWorkflowInstance,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};

function useMessageContext() {
  const context = React.useContext(MessageContext);
  if (context === undefined) {
    throw new Error("useMessageContext must be used within a MessageProvider");
  }
  return context;
}

export { MessageProvider, useMessageContext };

import React from "react";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import ConfirmModal from "@components/shared/ConfirmModal";
import ConfirmationDetail from "@components/shared/ConfirmationDetail";
import WorkflowComplete from "@components/shared/WorkflowComplete";
import { useStepContext } from "@context/shared/StepContext";
import { useReplaceMeterContext } from "@context/ReplaceMeterContext";
import { useEvidenceContext } from "@context/shared/EvidenceContext";
import { toastError, toastSuccess } from "@utils/toast";
import { isValidationError } from "@utils/formError";

type ConfirmMeterReplacementProps = {
  onSubmit: () => Promise<any>;
  onCancel: () => void;
  workflowInstanceIds: string[];
  startAtIndex?: number;
};

const ConfirmMeterReplacement: React.FunctionComponent<
  ConfirmMeterReplacementProps
> = ({ onSubmit, onCancel, workflowInstanceIds, startAtIndex = 0 }) => {
  const { t } = useTranslation();
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const { stepHelpers } = useStepContext();
  const { isComplete, getMeterReplacementInfo } = useReplaceMeterContext();
  const { getEvidencesInfo } = useEvidenceContext();

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      await onSubmit();
      toastSuccess(t("meter.replacement.toast.success"));
    } catch (error: any) {
      const { data } = error?.response;
      const errorMessages = isValidationError(error)
        ? data?.errors?.map((i: any) => i.message)
        : [data?.message];

      toastError(
        <>
          <p>
            {t("meter.replacement.toast.error", {
              error: error?.response?.data?.message || error?.message,
            })}
          </p>
          {errorMessages?.length ? (
            <ul className="list-disc pl-4">
              {errorMessages.map((text: any) => {
                return <li key={text}>{text}</li>;
              })}
            </ul>
          ) : null}
        </>
      );
    }

    setIsLoading(false);
    setShowConfirmModal(false);
  };

  if (isComplete && workflowInstanceIds.length) {
    return <WorkflowComplete references={workflowInstanceIds} />;
  }

  return (
    <>
      <div className="flex flex-col gap-4 grow">
        <div className="p-6 space-y-4">
          <Heading light>{t("meter.replacement.confirm")}</Heading>

          <ConfirmationDetail
            data={[getMeterReplacementInfo(), getEvidencesInfo()]}
            onEdit={(step) => stepHelpers.setStep(step + startAtIndex)}
          />
        </div>

        <div className="grow" />

        <footer className="flex gap-4 p-6 border-t border-gray-200">
          <button
            type="button"
            className="btn-outline-primary"
            onClick={stepHelpers.goToPrevStep}
          >
            {t("common.prev_step")}
          </button>
          <button
            type="button"
            className="btn-primary"
            onClick={() => {
              setShowConfirmModal(true);
            }}
          >
            {t("common.ledger")}
          </button>
          <button
            type="button"
            className="btn-outline-primary"
            onClick={onCancel}
          >
            {t("common.cancel")}
          </button>
        </footer>
      </div>

      <ConfirmModal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleConfirm}
        isSubmitting={isLoading}
      >
        {t("meter.replacement.modal.confirm")}
      </ConfirmModal>
    </>
  );
};

export default ConfirmMeterReplacement;

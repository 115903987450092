import { useTranslation } from "react-i18next";
import HistoryIcon from "@components/icons/HistoryIcon";
import Card from "@components/layout/Card";
import Heading1 from "@components/layout/Heading";
import OnChain from "@components/shared/OnChain";
import EventTransactionPaginatedTable from "@components/table/EventTransactionPaginatedTable";
import { useResponseApprovalInfoRequestContext } from "@context/ResponseApprovalInfoRequestContext";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { formatDateTime } from "@utils/formatDateTime";
import { useEvidenceContext } from "@context/shared/EvidenceContext";
import Tag from "@components/shared/Tag";
import { getType } from "@utils/administrativeApproval";
import EndButtonModal from "@components/shared/EndButton";

const ResponseApprovalInfoRequestCompleted: React.FunctionComponent<{}> =
  () => {
    const { t } = useTranslation();

    const { approval, message, workflowInstance } =
      useResponseApprovalInfoRequestContext();

    const { getFileNames } = useEvidenceContext();

    return (
      <>
        <div className="flex flex-col">
          <div className="flex gap-4">
            <Card className="w-1/2 flex flex-col gap-4 p-4">
              <h1 className="w-full text-green-800 flex items-center">
                <CheckCircleIcon className="w-6 mr-2" /> {t("common.success")}
              </h1>
              <OnChain />
              <dl className="w-full">
                <div className="grid grid-cols-2 gap-2 text-sm text-gray-500">
                  <dt className="font-bold">
                    {t("workflow.workflow_instance_id")}:
                  </dt>
                  <dd>{workflowInstance?.id}</dd>
                  <dt className="font-bold">{t("workflow.started_at")}:</dt>
                  <dd>
                    {workflowInstance?.startedAt &&
                      formatDateTime(new Date(workflowInstance?.startedAt))}
                  </dd>
                  <dt className="font-bold">
                    {t("zenith.response_approval_info_request.from_subscriber")}
                    :
                  </dt>
                  <dd>{approval?.seller?.name ?? "-"}</dd>
                  <dt className="font-bold">
                    {t("zenith.response_approval_info_request.to_subscriber")}:
                  </dt>
                  <dd>{approval?.buyer?.name ?? "-"}</dd>
                  <dt className="font-bold">{t("common.level0wrs")}:</dt>
                  <dd>
                    {approval?.extractionRights?.[0].level0Resource?.identifier}
                  </dd>
                  <dt className="font-bold">
                    {t("common.extraction_rights")}:
                  </dt>
                  <dd>
                    {approval?.extractionRights
                      ?.map((i: any) => i.name)
                      ?.join(", ")}
                  </dd>
                  <dt className="font-bold">{t("common.type")}:</dt>
                  <dd>
                    <Tag status="info">{getType(approval?.type)}</Tag>
                  </dd>
                </div>
              </dl>
            </Card>
            <Card className="w-1/2 flex flex-col gap-2">
              <h1 className="w-full text-green-800 flex items-center">
                <CheckCircleIcon className="w-6 mr-2" />
                {t("notification.message")}
              </h1>
              <OnChain />
              <dl className="w-full">
                <div className="grid grid-cols-2 gap-2 text-sm text-gray-500">
                  <dt className="font-bold">{t("notification.subject")}:</dt>
                  <dd>{message?.subject}</dd>
                  <dt className="font-bold">{t("notification.body")}:</dt>
                  <dd>
                    <blockquote className="border-l-4 pl-4 whitespace-pre-wrap">
                      {message?.body}
                    </blockquote>
                  </dd>
                  <dt className="font-bold">{t("evidence.supporting")}:</dt>
                  <dd>{getFileNames()}</dd>
                </div>
              </dl>
            </Card>
          </div>
          <footer className="py-4 flex gap-2">
            <EndButtonModal />
          </footer>
        </div>
        <div className="grow border-t pt-1">
          <Heading1 light className="flex gap-2 items-center">
            <HistoryIcon /> {t("audit_trail.audit_trail")}
          </Heading1>
          <EventTransactionPaginatedTable
            references={[workflowInstance?.id]}
            stickyHeader={false}
          />
        </div>
      </>
    );
  };

export default ResponseApprovalInfoRequestCompleted;

import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { getAllEvidences } from "@services/evidence";

interface Options extends UseQueryOptions {
  params?: Record<string, any>;
}

export const useAllEvidences = (options: Options = {}) => {
  const { params, ...restOptions } = options;
  return useQuery<any>({
    queryKey: ["evidences", params],
    queryFn: () => getAllEvidences(params),
    ...restOptions,
  });
};

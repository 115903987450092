import React from "react";
import classNames from "classnames";
import { groupBy, uniq } from "lodash";
import {
  Label,
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Line,
  Tooltip,
} from "recharts";
import { useTranslation } from "react-i18next";

import Card from "@components/layout/Card";
import SelectAccountingPeriod from "@components/form/SelectAccountingPeriod";
import Loading from "@components/shared/Loading";
import DropWaterIcon from "@components/icons/DropWaterIcon";
import { useAppContext } from "@context/AppContext";
import { useAllRightFractionUsageHistories } from "@hooks/query/zenith/useAllRightFractionUsageHistories";
import { convertLiterToML } from "@utils/convertUnits";

type ViewBy = "summary" | "water_class" | "level0wrs";

const getRandomHexColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const colors = Array.from({ length: 30 }, getRandomHexColor);

const ExtractionHistoryWidget = () => {
  const { t } = useTranslation();
  const [viewBy, setViewBy] = React.useState<ViewBy>("summary");
  const { currentAccountingPeriod } = useAppContext();
  const [accountingPeriod, setAccountingPeriod] = React.useState(
    () => currentAccountingPeriod,
  );

  React.useEffect(() => {
    if (currentAccountingPeriod) {
      setAccountingPeriod(currentAccountingPeriod);
    }
  }, [currentAccountingPeriod]);

  const { periodStart, periodEnd } = accountingPeriod;

  const { data: histories = [], isLoading } = useAllRightFractionUsageHistories(
    {
      params: {
        periodStart,
        periodEnd,
      },
      select: (res: any[]) =>
        res.map(i => {
          const { extractionRight, ...rest } = i;
          return {
            ...rest,
            extractionRightName: extractionRight.name,
            level0WRSIdentifier: extractionRight.level0Resource.identifier,
            waterClassName: extractionRight.waterClass.name,
          };
        }),
    },
  );

  const groupHistoriesByMonth = groupBy(histories, "month");

  const viewByButtons: {
    label: string;
    value: ViewBy;
  }[] = [
    {
      label: t("common.summary"),
      value: "summary",
    },
    {
      label: t("water_class.water_class_header"),
      value: "water_class",
    },
    {
      label: t("zenith.dashboard.management_area"),
      value: "level0wrs",
    },
  ];

  const infos = React.useMemo(() => {
    if (!histories.length) {
      return {
        values: [],
        legends: [],
      };
    }

    const months = [
      t("shared.months.short.jan"),
      t("shared.months.short.feb"),
      t("shared.months.short.mar"),
      t("shared.months.short.apr"),
      t("shared.months.short.may"),
      t("shared.months.short.jun"),
      t("shared.months.short.jul"),
      t("shared.months.short.aug"),
      t("shared.months.short.sep"),
      t("shared.months.short.oct"),
      t("shared.months.short.nov"),
      t("shared.months.short.dec"),
    ];

    const key =
      viewBy === "water_class"
        ? "waterClassName"
        : viewBy === "level0wrs"
          ? "level0WRSIdentifier"
          : "extractionRightName";
    const legends = uniq(histories.map((i: any) => i[key])).sort() as string[];

    const values = months.map((month, index) => {
      const object: Record<string, any> = {
        month,
      };

      const currentData = groupHistoriesByMonth[index + 1];

      legends.forEach(val => {
        const item = currentData?.find(i => i[key] === val);
        const usage = Number(item?.usage ?? 0);
        const overused = Number(item?.overused ?? 0);
        object[val] =
          usage || overused ? Number(convertLiterToML(usage + overused)) : 0;
      });

      return object;
    });

    return {
      values,
      legends,
    };
  }, [histories, groupHistoriesByMonth, t, viewBy]);

  if (isLoading) {
    return (
      <div className="flex justify-center py-20">
        <Loading />
      </div>
    );
  }

  return (
    <Card
      header={
        <h2 className="flex gap-3 item-centers text-inherit">
          <DropWaterIcon className="w-5 h-5" />
          {t("zenith.balances.extraction_history")}
        </h2>
      }
    >
      <header className="space-y-4">
        <div className="max-w-sm">
          <Label>{t("common.accounting_period")}:</Label>
          <SelectAccountingPeriod
            value={accountingPeriod}
            onChange={(e: any) => {
              setAccountingPeriod({
                id: e?.value?.id,
                periodStart: new Date(e?.value?.periodStart),
                periodEnd: new Date(e?.value?.periodEnd),
              });
            }}
          />
        </div>
        <div className="text-sm">
          <Label>{t("common.view_by")}:</Label>
          <nav className="flex gap-3">
            {viewByButtons.map(({ label, value }) => (
              <button
                key={value}
                type="button"
                className={classNames(
                  "rounded",
                  value === viewBy ? "btn-primary" : "btn-default",
                )}
                onClick={e => {
                  e.preventDefault();
                  setViewBy(value);
                }}
              >
                {label}
              </button>
            ))}
          </nav>
        </div>
      </header>

      <div className="h-96 mt-6">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={infos.values}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis allowDataOverflow={true} />
            <Tooltip />
            <Legend />

            {infos.legends.map((name: string, index) => (
              <Line
                key={name}
                type="linear"
                dataKey={name}
                stroke={colors[index] ?? colors[0]}
                strokeWidth={2}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
};

export default ExtractionHistoryWidget;

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import UserWorkflow from "./UserWorkflow";
import Layout from "@components/layout/Layout";
import { useSubscriber } from "@hooks/query/useSubscriber";
import { useUpdateUser } from "@hooks/mutation/useUpdateUser";

const UserUpdate = () => {
  const { t } = useTranslation();
  const { id: subscriberId = "" } = useParams();
  const { data: subscriber = {} } = useSubscriber(subscriberId, {
    enabled: Boolean(subscriberId),
  });

  const { mutateAsync: updateUserMutation } = useUpdateUser();

  return (
    <Layout
      permissions={["UpdateUsers"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("common.subscribers"),
          href: "/polestar/subscribers",
        },
        {
          label: subscriber?.name,
          href: `/polestar/subscribers/${subscriber?.id}`,
        },
        {
          label: t("user.edit.title"),
        },
      ]}
      title={t("user.edit.title")}
    >
      <UserWorkflow onSubmit={updateUserMutation} />
    </Layout>
  );
};

export default UserUpdate;

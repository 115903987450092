import classNames from "classnames";
import { useTranslation } from "react-i18next";
import CheckCircleOutlineIcon from "@components/icons/CheckCircleOutlineIcon";

type ExtractionPointLinkToRightStep2LabelProps = {
  extractionRight?: any;
  isSelected?: boolean;
};

const ExtractionPointLinkToRightStep2Label: React.FunctionComponent<
  ExtractionPointLinkToRightStep2LabelProps
> = ({ extractionRight, isSelected }) => {
  const { t } = useTranslation();

  if (extractionRight) {
    return (
      <div className="flex flex-col">
        <div className="w-full h-full text-left flex items-center text-sm text-green-600">
          <span className="m-2">
            <CheckCircleOutlineIcon />
          </span>
          <span>{t("extraction_point.link.choose_entitlement")}</span>
        </div>

        <div>
          {extractionRight.name ? (
            <p className="block text-sm mt-1 pl-2 text-gray-600">
              <strong>{t("extraction_right.number")}:</strong>{" "}
              {extractionRight.name}
            </p>
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-full text-left flex items-center text-sm">
      <span
        className={classNames(
          "rounded-full h-5 w-5 font-bold text-center flex justify-center items-center m-2",
          {
            "bg-primary-2 text-white": isSelected,
            "bg-gray-500 text-white": !isSelected,
          }
        )}
      >
        2
      </span>
      <span>{t("extraction_point.link.choose_entitlement")}</span>
    </div>
  );
};

export default ExtractionPointLinkToRightStep2Label;

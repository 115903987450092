import React from "react";
import { useTranslation } from "react-i18next";

import { useAppContext } from "@context/AppContext";
import ExtractionHistoryWidget from "./ExtractionHistoryWidget";
import AvailableWaterWidget from "./AvailableWaterWidget";

const AccountBalances: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { setInfoPanel } = useAppContext();

  React.useEffect(() => {
    setInfoPanel(
      <>
        <p>{t("zenith.balances.info.intro")}</p>
        <p>{t("zenith.balances.info.view_by")}</p>
        <p>{t("zenith.balances.info.description")}</p>
      </>
    );
  }, [t, setInfoPanel]);

  return (
    <div className="space-y-6">
      <AvailableWaterWidget />
      <ExtractionHistoryWidget />
    </div>
  );
};

export default AccountBalances;

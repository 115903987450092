import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Layout from "@components/layout/Layout";
import { useSubscriber } from "@hooks/query/useSubscriber";
import { useLevel0Resource } from "@hooks/query/useLevel0Resource";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";
import UpdateExtractionRightWorkflow from "@components/form/extraction_right/UpdateExtractionRightWorkflow";
import { useExtractionRight } from "@hooks/query/useExtractionRight";
import {
  CreateOrUpdateExtractionRightProvider,
  useCreateOrUpdateExtractionRightContext,
} from "@context/CreateOrUpdateExtractionRightContext";
import { EvidenceProvider } from "@context/shared/EvidenceContext";

const UpdateExtractionRight = () => {
  return (
    <EvidenceProvider>
      <CreateOrUpdateExtractionRightProvider>
        <Consumer />
      </CreateOrUpdateExtractionRightProvider>
    </EvidenceProvider>
  );
};

const Consumer = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { id = "", extractionRightId = "" } = useParams();
  const subscriberId = searchParams.get("subscriberId") || "";
  const level0ResourceId = searchParams.get("level0ResourceId") || "";
  const {
    handleChangeDetails,
    setFromSubscriber,
    setFromLevel0wrs,
    details,
    setUpdatingRight,
    stepHelpers,
  } = useCreateOrUpdateExtractionRightContext();
  const { data: level1Resource } = useGetLevel1Resource(id, {
    enabled: Boolean(id),
    onSuccess: (data: any) => {
      handleChangeDetails("level1wrs", data);
    },
  });

  const { data: subscriber } = useSubscriber(subscriberId, {
    enabled: Boolean(subscriberId),
    onSuccess: (data) => {
      setFromSubscriber(data);
    },
  });

  const { data: extractionRight } = useExtractionRight(extractionRightId, {
    enabled: Boolean(extractionRightId),
    onSuccess: (data: any) => {
      setUpdatingRight(data);
      if (details.subscriber.id === "") {
        const { parent: level1wrs, ...level0wrs } = data.level0Resource;
        const {
          id: extractionRightId,
          rightFractions,
          name,
          description,
          reference,
          startAt,
          endAt,
        } = data;
        handleChangeDetails("id", extractionRightId);
        handleChangeDetails("rightFractions", rightFractions);
        handleChangeDetails("name", name);
        handleChangeDetails("description", description);
        handleChangeDetails("reference", reference);
        handleChangeDetails("startAt", startAt);
        handleChangeDetails("endAt", endAt);
        handleChangeDetails("volume", +data.volume / 1000000);
        handleChangeDetails("level0wrs", level0wrs);
        handleChangeDetails("level1wrs", level1wrs);
        handleChangeDetails("subscriber", data.subscriber);
        handleChangeDetails("waterClass", data.waterClass);
        handleChangeDetails("billingGroup", data.billingGroup);
        handleChangeDetails("itemNo", data.itemNo);
      }
    },
  });

  const { data: level0Resource } = useLevel0Resource(level0ResourceId, {
    enabled: Boolean(level0ResourceId),
    onSuccess: setFromLevel0wrs,
  });

  useEffect(() => {
    stepHelpers.setStep(2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      permissions={["CreateExtractionRight"]}
      breadcrumb={[
        { label: t("dashboard.dashboard"), href: "/polestar" },
        ...(subscriber
          ? [
              {
                label: t("common.subscribers"),
                href: "/polestar/subscribers",
              },
              {
                label: subscriber?.name,
                href: `/polestar/subscribers/${subscriber?.id}`,
              },
              {
                label: level0Resource?.identifier,
                href: `/polestar/subscribers/${subscriber?.id}?level0ResourceId=${level0Resource?.id}`,
              },
              {
                label: t("common.extraction_rights"),
                href: `/polestar/subscribers/${subscriber?.id}?level0ResourceId=${level0Resource?.id}&extractionRightId=random`,
              },
              {
                label: extractionRight?.name,
                href: `/polestar/subscribers/${subscriber?.id}?level0ResourceId=${extractionRight?.level0ResourceId}&extractionRightId=${extractionRight?.id}`,
              },
            ]
          : [
              {
                label: level1Resource?.name,
                href: `/polestar/level1wrs/${level1Resource?.id}`,
              },
              {
                label: t("common.extraction_rights"),
                href: `/polestar/level1wrs/${level1Resource?.id}#4`,
              },
              {
                label: extractionRight?.name,
                href: `/polestar/level1wrs/${level1Resource?.id}?extractionRightName=${extractionRight?.name}#4`,
              },
            ]),
        {
          label: t("extraction_right.update.title"),
        },
      ]}
      title={t("extraction_right.update.title")}
      accountingPeriodLevel1ResourceId={level1Resource?.id}
    >
      <UpdateExtractionRightWorkflow />
    </Layout>
  );
};

export default UpdateExtractionRight;

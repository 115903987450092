import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updatePrimaryUser } from "@services/user";

export const useUpdatePrimaryUser = (options: Record<string, any> = {}) => {
  const queryClient = useQueryClient();

  return useMutation<any, any, string>({
    mutationFn: updatePrimaryUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
    ...options,
  });
};

import { useTranslation } from "react-i18next";
import SubscriberTable from "@components/table/SubscriberTable";
import { useParams } from "react-router-dom";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";

type SelectSubscriberProps = {
  value: any;
  onSelect: (value: any) => void;
  onCancel: () => void;
  onNext: () => void;
  disabled?: boolean;
};

const SelectSubscriber: React.FunctionComponent<SelectSubscriberProps> = ({
  value,
  onSelect,
  onCancel,
  onNext,
  disabled,
}) => {
  const { t } = useTranslation();
  const { id: level1ResourceId = "" } = useParams();
  const { data: level1Resource, isLoading } =
    useGetLevel1Resource(level1ResourceId);

  if (isLoading) return null;

  return (
    <>
      <div className="flex flex-col grow p-6">
        <SubscriberTable
          title={t("extraction_point.unlink.select_subscriber")}
          selected={value}
          onSelect={onSelect}
          level1Resource={level1Resource}
          includeIds={disabled ? [value.id] : undefined}
        />
      </div>

      <footer className="flex gap-4 p-6 border-t">
        <button
          type="button"
          className="btn-primary"
          onClick={onNext}
          disabled={!value?.id}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </>
  );
};

export default SelectSubscriber;

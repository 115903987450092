import { useTranslation } from "react-i18next";

import { formatDateTime } from "@utils/formatDateTime";
import {
  NotificationType,
  useNotificationContext,
} from "@context/NotificationContext";
import _ from "lodash";

const CreateNotificationStep1Label: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    details: { message },
    context,
  } = useNotificationContext();

  return (
    <div className="w-full pl-2 flex flex-col gap-2">
      <dl className="grid grid-cols-1 gap-x-4">
        <div className="sm:col-span-1">
          <dt className="text-sm font-bold text-gray-500">
            {t("notification.type")}:
          </dt>
          <dd className="mt-1 text-sm text-gray-500">{_.startCase(context)}</dd>
        </div>
      </dl>
      {message?.type === NotificationType.Shutdown && (
        <dl className="grid grid-cols-1 gap-x-4">
          <div className="sm:col-span-1">
            <dt className="text-sm font-bold text-gray-500">
              {t("notification.shutdown_dates")}:
            </dt>
            <dd className="mt-1 text-sm text-gray-500 truncate">
              {message?.fromDate && formatDateTime(message?.fromDate)} -{" "}
              {message?.toDate && formatDateTime(message?.toDate)}
            </dd>
          </div>
        </dl>
      )}
      <dl className="grid grid-cols-1 gap-x-4">
        <div className="sm:col-span-1">
          <dt className="text-sm font-bold text-gray-500">
            {t("notification.subject")}:
          </dt>
          <dd className="mt-1 text-sm text-gray-500 truncate">
            {message?.subject}
          </dd>
        </div>
      </dl>
      <dl className="grid grid-cols-1 gap-x-4">
        <div className="sm:col-span-1">
          <dt className="text-sm font-bold text-gray-500">
            {t("notification.body")}:
          </dt>
          <dd className="mt-1 text-sm text-gray-500 truncate">
            {message?.body}
          </dd>
        </div>
      </dl>
      {message?.type === NotificationType.Bulletin && message?.evidence && (
        <dl className="grid grid-cols-1 gap-x-4">
          <div className="sm:col-span-1">
            <dt className="text-sm font-bold text-gray-500">
              {t("notification.evidence")}:
            </dt>
            <dd className="mt-1 text-sm text-gray-500 truncate">
              {message?.evidence?.name}
            </dd>
          </div>
        </dl>
      )}
    </div>
  );
};

export default CreateNotificationStep1Label;

import Legend from "@components/form/Legend";
import SelectSingleExtractionRightTableForZenith from "@components/table/SelectSingleExtractionRightTableForZenith";
import { useAdminTransferContext } from "@context/AdminTransferContext";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type WorkflowCreateAdminApprovalStep1Props = {
  onSubmit: () => void;
};

const WorkflowCreateAdminApprovalStep1: FunctionComponent<
  WorkflowCreateAdminApprovalStep1Props
> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { details, handleChangeDetails } = useAdminTransferContext();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form className="flex flex-col grow" onSubmit={handleSubmit}>
      <fieldset className="w-full flex flex-col grow gap-2">
        <Legend>
          {t("approval.admin_transfer.create.step_3.select_right")}
        </Legend>

        <SelectSingleExtractionRightTableForZenith
          onSelect={(item: any) => {
            handleChangeDetails("extractionRight", item);
            handleChangeDetails("extractionPoint", { id: "" });
          }}
          selected={details.extractionRight}
        />
      </fieldset>
      <footer className="p-4 flex gap-2">
        <button
          type="submit"
          className="btn-primary text-sm font-semibold"
          disabled={!details?.extractionRight?.id}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-default text-sm font-semibold"
          onClick={() => navigate("/zenith/workflows")}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default WorkflowCreateAdminApprovalStep1;

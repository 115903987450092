import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import SelectSingleLevel1WRSTable from "@components/table/SelectSingleLevel1WRSTable";
import Heading from "@components/layout/Heading";
import { useStepContext } from "@context/shared/StepContext";

type ForwardDrawSelectLevel1ResourceProps = {
  selectedLevel1Resource: any;
  onSelect: any;
  onCancel: () => void;
};

const ForwardDrawSelectLevel1Resource: FunctionComponent<
  ForwardDrawSelectLevel1ResourceProps
> = ({ selectedLevel1Resource, onSelect, onCancel }) => {
  const { t } = useTranslation();
  const { stepHelpers } = useStepContext();

  return (
    <div className="flex flex-col gap-6 p-6 grow justify-between">
      <Heading light>
        {t("approval.forward_draws.approve.select_level1wrs")}
      </Heading>
      <SelectSingleLevel1WRSTable
        onSelect={onSelect}
        selected={selectedLevel1Resource}
      />

      <footer className="flex gap-4 -mx-6 mt-2 p-6 pb-0 border-t border-gray-200">
        <button
          type="button"
          className="btn-primary"
          disabled={!selectedLevel1Resource}
          onClick={stepHelpers.goToNextStep}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </div>
  );
};

export default ForwardDrawSelectLevel1Resource;

import React from "react";
import { useTranslation } from "react-i18next";

import { useSeasonalTransferContext } from "@context/SeasonalTransferContext";
import SelectSingleSubscriberTable from "@components/table/SelectSingleSubscriberTable";
import Heading1 from "@components/layout/Heading";

const SeasonalTransferSeller: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    stepHelpers,
    navigateForCancel,
    details,
    handleSelectFromSubscriber,
  } = useSeasonalTransferContext();

  return (
    <>
      <div className="p-6 pb-0 flex flex-col gap-4 grow">
        <Heading1 light>
          {t("approval.seasonal_water_assignments.create.step_2.title")}
        </Heading1>

        <SelectSingleSubscriberTable
          onSelect={(item: any) => {
            handleSelectFromSubscriber({
              id: item.id,
              name: item.name,
              accountNumber: item.accountNumber,
              walletId: item.walletId,
            });
          }}
          selected={details?.from.subscriber}
          params={{
            level1ResourceId: details?.level1wrs?.id,
            hasBalance: true,
          }}
          showLevel0WRS
        />
      </div>

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>

        <button
          type="button"
          className="btn-primary"
          onClick={stepHelpers.goToNextStep}
          disabled={details.from.subscriber.id === ""}
        >
          {t("common.next_step")}
        </button>

        <button
          type="button"
          className="btn-outline-primary"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </>
  );
};

export default SeasonalTransferSeller;

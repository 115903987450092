import api from "./api";

export const getAdministrativeApproval = async (id: string): Promise<any> => {
  const { data } = await api.get(`/admin/administrativeApprovals/${id}`);

  return data.administrativeApproval;
};

type GetAllAdministrativeApprovalParams = {
  periodStart: Date;
  periodEnd: Date;
  [key: string]: any;
};

export const getAllAdministrativeApprovals = async (
  params?: Partial<GetAllAdministrativeApprovalParams>,
) => {
  const { data } = await api.get("/admin/administrativeApprovals", { params });

  return data.administrativeApprovals;
};

export enum AdministrativeApprovalType {
  PT = "PT",
  SWT = "SWT",
  AT = "AT",
  FD = "FD",
  AME = "AME",
  SDE = "SDE",
  SD = "SD",
}

export enum AdministrativeApprovalNumericType {
  PT = 1,
  SWT,
  AT,
  FD,
  AME,
  SDE,
  SD,
}

export enum ExtractionRightApprovalType {
  Amalgamate = "amalgamate",
  Subdivide = "subdivide",
}

export enum AdministrativeApprovalStatus {
  Pending = "Pending",
  Approved = "Approved",
  Declined = "Declined",
  UnderReview = "UnderReview",
  Delayed = "Delayed",
  Abandoned = "Abandoned",
  InDraft = "InDraft",
}

export enum AdministrativeApprovalNumericStatus {
  Pending = 1,
  Approved,
  Declined,
  UnderReview,
  Delayed,
  Abandoned,
  InDraft,
}

export enum ApprovalExtractionRightVersion {
  Old = 1,
  New,
}

export type PermanentTransferPayload = {
  sellerId: string;
  buyerId: string;
  extractionRights: {
    id: string;
    transferH2W: number;
    billingGroupId: string;
    itemNo: string;
  }[];
  extractionPointIds: string[];
  price: number;
  description?: string;
  lodgedAt?: Date;
};

export const createPermanentTransfer = async (
  payload: PermanentTransferPayload,
) => {
  const { data } = await api.post(
    "/admin/administrativeApprovals/permanentTransfer",
    payload,
  );

  return [data.administrativeApproval, data.workflowInstance];
};

export type AdminTransferPayload = {
  subscriberId: string;
  extractionPointId: string;
  extractionRightId: string;
  rejectionText?: string;
  action?: number;
};

export const requestAdminTransfer = async (payload: AdminTransferPayload) => {
  const { data } = await api.post(
    "/admin/administrativeApprovals/requestAdminTransfer",
    payload,
  );

  return data.administrativeApproval;
};

export const createAdminTransfer = async (payload: AdminTransferPayload) => {
  const { data } = await api.post(
    "/admin/administrativeApprovals/createAdminTransfer",
    payload,
  );

  return [data.administrativeApproval, data.workflowInstance];
};

export const checkAdminTransferRelationship = async (payload: {
  extractionRightId: string;
  extractionPointId: string;
}): Promise<any> => {
  const { data } = await api.post(
    "/administrativeApprovals/checkAdminTransferRelationship",
    payload,
  );

  return data.existRelation;
};

export const createForwardDraw = async (payload: {
  extractionRightIds: string[];
}) => {
  const { data } = await api.post(
    "/administrativeApprovals/forwardDraws",
    payload,
  );

  return [data.extractionRightIds, data.workflowInstance];
};

export const manualCreateForwardDraw = async (payload: {
  extractionRightIds: string[];
  volumes: number[];
  lodgedAt?: Date;
}) => {
  const { data } = await api.post(
    "/admin/administrativeApprovals/forwardDraws/create",
    payload,
  );

  return [data.administrativeApprovals, data.workflowInstance];
};

export const manualApproveForwardDraw = async (payload: {
  approvalId: string;
  effectiveDate: Date;
  volume: number;
  reason?: string;
}) => {
  const { data } = await api.post(
    "/admin/administrativeApprovals/forwardDraws/approve",
    payload,
  );

  return [data.administrativeApproval, data.workflowInstance];
};

export const manualRejectForwardDraw = async (payload: {
  approvalId: string;
  reason: string;
}) => {
  const { data } = await api.post(
    "/admin/administrativeApprovals/forwardDraws/reject",
    payload,
  );

  return [data.administrativeApproval, data.workflowInstance];
};

export const requestForwardDrawInfo = async (payload: {
  approvalId: string;
  reason: string;
}) => {
  const { data } = await api.post(
    "/admin/administrativeApprovals/forwardDraws/requestInfo",
    payload,
  );

  return [data.administrativeApproval, data.workflowInstance];
};

export type AmalgamationOrSubdivisionPayload = {
  extractionRightIds: string[];
  level1ResourceId: string;
  waterClassId: string;
  level0ResourceId: string;
  buyerId: string;
  volume: number;
  type: AdministrativeApprovalType.AME | AdministrativeApprovalType.SDE;
};

export const requestAmalgamationOrSubdivision = async (
  payload: AmalgamationOrSubdivisionPayload,
) => {
  const { data } = await api.post(
    "/admin/administrativeApprovals/amalgamationOrSubdivision",
    payload,
  );

  return data.administrativeApproval;
};

export type UpdateAmalgamationOrSubdivisionPayload = {
  newExtractionRightIds?: string[];
  abandoned?: boolean;
  pending?: boolean;
  administrativeApprovalId: string;
};

export const updateAmalgamationOrSubdivisionRequest = async ({
  administrativeApprovalId,
  ...payload
}: UpdateAmalgamationOrSubdivisionPayload) => {
  const { data } = await api.put(
    `/admin/administrativeApprovals/amalgamationOrSubdivision/${administrativeApprovalId}`,
    payload,
  );

  return data;
};

export const auditAmalgamation = async ({
  administrativeApprovalId,
  effectiveDate,
  action,
  reason,
}: {
  administrativeApprovalId: string;
  effectiveDate?: Date;
  action: string;
  reason?: string;
}) => {
  const payload = { administrativeApprovalId, effectiveDate, action, reason };
  const { data } = await api.post(
    `/admin/administrativeApprovals/auditAmalgamation`,
    payload,
  );

  return [data.administrativeApproval, data.workflowInstance];
};

export const auditSubdivision = async ({
  administrativeApprovalId,
  effectiveDate,
  action,
  reason,
}: {
  administrativeApprovalId: string;
  effectiveDate?: Date;
  action: string;
  reason?: string;
}) => {
  const payload = { administrativeApprovalId, effectiveDate, action, reason };
  const { data } = await api.post(
    `/admin/administrativeApprovals/auditSubdivision`,
    payload,
  );

  return [data.administrativeApproval, data.workflowInstance];
};

export type UpdateStatusPayload = {
  id: string;
  status: AdministrativeApprovalNumericStatus;
  reason?: string;
};

export const updateAdministrativeApprovalStatus = async (
  payload: UpdateStatusPayload,
) => {
  const { data } = await api.put(
    `/admin/administrativeApprovals/status`,
    payload,
  );

  return [data.administrativeApproval, data.workflowInstance];
};

import { useState } from "react";

export type GetInfoMessagesFunction = (
  infoType: InfoType,
  stepNumber: number
) => string[];

export type ModifyStatusesFunction = (params: {
  stepNumber: number;
  fieldName: string;
  message?: React.ReactNode;
  infoType?: InfoType;
}) => void;

type ResetFunction = (step: number) => void;

type InfoType = "success" | "error" | "warning";

type FieldStatusType = {
  message?: React.ReactNode;
  infoType?: InfoType;
};
type InfoPanelStatusType = Array<{
  step: number;
  fieldStatuses?: Record<string, FieldStatusType>;
}>;

const initializeStatuses = (maxStep: number) => {
  const initialStatuses = [];
  for (let i = 0; i < maxStep; i++) {
    initialStatuses.push({ step: i });
  }
  return initialStatuses;
};

export function useStepStatuses(maxStep: number): {
  getInfoMessages: GetInfoMessagesFunction;
  modifyStatuses: ModifyStatusesFunction;
  reset: ResetFunction;
} {
  const [statuses, setStatuses] = useState<InfoPanelStatusType>(
    initializeStatuses(maxStep)
  );

  const getInfoMessages = (infoType: InfoType, step: number) => {
    const messages = statuses
      .filter(
        (status: any) =>
          status.step === step &&
          Object.values(status).some((val: any) => val?.infoType === infoType)
      )
      .map((steppedStatuses: any) =>
        Object.values(steppedStatuses).filter(
          (val: any) => val?.infoType === infoType
        )
      )
      .flatMap((val: any) => val.map((val: any) => val.message));

    return messages;
  };

  const modifyStatuses: ModifyStatusesFunction = (params) => {
    setStatuses((prevStatuses: InfoPanelStatusType) => {
      const { stepNumber, fieldName, message, infoType } = params;

      const newFieldStatus = {
        [fieldName]: message && infoType ? { message, infoType } : undefined,
      };

      const updatedStepStatuses = {
        ...prevStatuses[stepNumber],
        ...newFieldStatus,
      };

      const updatedStatuses = [
        ...prevStatuses.slice(0, stepNumber),
        updatedStepStatuses,
        ...prevStatuses.slice(stepNumber + 1),
      ];

      return updatedStatuses;
    });
  };

  const reset: ResetFunction = (step) => {
    setStatuses((prev) => prev.map((i) => (i.step === step ? { step } : i)));
  };

  return { getInfoMessages, modifyStatuses, reset };
}

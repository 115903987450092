import { useState } from "react";
import { Link } from "react-router-dom";
import { capitalize } from "lodash";
import Select from "react-select";
import { t as translate } from "i18next";
import { useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import { useAllExtractionPoints } from "@hooks/query/useAllExtractionPoints";
import Tag from "@components/shared/Tag";

import ExternalLinkIcon from "@components/icons/ExternalLinkIcon";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";

import { getIsExtractionRightLinkableWithPoint } from "@utils/getIsExtractionRightLinkableWithPoint";
import ExtractionRightLinkableWithPointTag from "@components/shared/ExtractionRightLinkableWithPointTag";
import { useAllAdministrativeApprovals } from "@hooks/query/useAllAdministrativeApprovals";

type Props = {
  subscriber: any;
  onSelect: (values: Record<string, any>[]) => void;
  selectedKeys?: string[];
  disableLevel0ResourceId?: string;
  source?: string;
  noRowText?: string;
  showLinkageStatesWithRight?: any;
  disableSelection?: boolean;
  isActive?: any;
  level0ResourceId?: string;
};

const SelectMultipleExtractionPointTable: React.FunctionComponent<Props> = ({
  subscriber,
  selectedKeys = [],
  disableLevel0ResourceId,
  source,
  onSelect,
  noRowText = translate("common.no_data"),
  showLinkageStatesWithRight,
  disableSelection = false,
  isActive,
  level0ResourceId = "",
}) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<{
    extractionPointName: string;
    status?: boolean;
    level0ResourceId?: string;
  }>({ extractionPointName: "", level0ResourceId });
  const { data: extractionPoints = [] } = useAllExtractionPoints({
    params: {
      definedByWalletId: subscriber.walletId,
      source,
      isActive,
    },
  });

  const { data: administrativeApprovals } = useAllAdministrativeApprovals();

  const handleSelect = (ids: string[]) => {
    const selectedExtractionPoints = extractionPoints?.filter(
      (i: any) =>
        ids.some((id) => i.id === id) && disabledKeys.indexOf(i.id) === -1
    );
    onSelect(selectedExtractionPoints);
  };

  const disabledKeys = disableLevel0ResourceId
    ? extractionPoints
        .filter((ep: any) => {
          if (showLinkageStatesWithRight) {
            const linkReason = getIsExtractionRightLinkableWithPoint(
              showLinkageStatesWithRight,
              ep.id,
              administrativeApprovals
            ).reason;
            if (linkReason) return true;
          }
          return ep.level0ResourceId === disableLevel0ResourceId && ep.isActive;
        })
        .map((i: any) => i.id)
    : [];

  const tableFields = [
    ...(showLinkageStatesWithRight
      ? [
          {
            title: "",
            name: "linkStatus",
          },
        ]
      : []),
    {
      title: t("extraction_point.name"),
      name: "name",
    },
    {
      title: t("extraction_right.source"),
      name: "source",
    },
    {
      title: t("common.level0wrs"),
      name: "level0wrs",
    },
    {
      title: t("common.status"),
      name: "status",
    },
    {
      title: "",
      name: "view",
    },
  ];

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const getZoneOptions = () => {
    return (
      extractionPoints
        ?.map((ep: any) => ({
          label: ep.level0WRS?.identifier,
          value: ep.level0WRS?.id,
        }))
        .filter(
          (ep: any, i: number, self: any[]) =>
            self.findIndex((r: any) => r.value === ep.value) === i
        ) || []
    );
  };

  const tableData = extractionPoints
    ?.filter((extractionPoint: any) => {
      const matcher = filter.extractionPointName.toLowerCase();
      return extractionPoint?.name?.toLowerCase()?.includes(matcher);
    })
    ?.filter(
      (row: { isActive: boolean }) =>
        filter.status === undefined || row.isActive === filter.status
    )
    ?.filter(
      (row: any) =>
        !filter?.level0ResourceId ||
        row.level0ResourceId === filter?.level0ResourceId
    )
    .map((item: Record<string, any>) => {
      return {
        id: item.id,
        linkStatus: showLinkageStatesWithRight ? (
          <ExtractionRightLinkableWithPointTag
            extractionRight={showLinkageStatesWithRight}
            extractionPointId={item.id}
            administrativeApprovals={administrativeApprovals}
          />
        ) : (
          ""
        ),
        name: item.name,
        level0wrs: item?.level0WRS?.identifier,
        level0wrsId: item?.level0WRS?.id,
        source: capitalize(item?.source),
        status: item?.isActive ? (
          <Tag status="success">{t("common.active")}</Tag>
        ) : (
          <Tag status="error">{t("common.inactive")}</Tag>
        ),
        view: (
          <Link
            to={`/polestar/subscribers/${subscriber?.id}?level0ResourceId=${item?.level0WRS?.id}&extractionPointId=${item?.id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="btn-default flex items-center gap-3 w-min"
          >
            <ExternalLinkIcon className="w-4 h-4" />
            {t("common.view")}
          </Link>
        ),
      };
    });

  const activeOptions = [
    { label: t("common.active"), value: true },
    { label: t("common.inactive"), value: false },
  ];

  return (
    <>
      <form className="pb-0 flex flex-col gap-4 grow">
        <fieldset className="grid grid-cols-1 md:grid-cols-3 gap-4 ">
          <div className="w-full">
            <Label htmlFor="extractionPointId">
              {t("extraction_point.filter_name")}
            </Label>
            <SearchInput
              id="extractionPointId"
              onChange={(e) =>
                handleFilterChange("extractionPointName", e.target.value)
              }
            />
          </div>
          <div className="w-full">
            <Label htmlFor="status">
              {t("subscriber.filter_subscriber_status")}
            </Label>
            <Select
              id="status"
              options={activeOptions}
              value={activeOptions.find(
                (activeOption) => activeOption.value === filter.status
              )}
              onChange={(e) => handleFilterChange("status", e?.value)}
              isClearable
            />
          </div>
          <div className="w-full">
            <Label htmlFor="level0wrs">{t("level0wrs.filter_level0wrs")}</Label>
            <Select
              options={getZoneOptions()}
              value={getZoneOptions().find(
                (option: any) => option.value === filter.level0ResourceId
              )}
              onChange={(e) => {
                handleFilterChange("level0ResourceId", e?.value);
              }}
              isClearable
            />
          </div>
        </fieldset>
        <Table
          fields={tableFields}
          data={tableData}
          selectionKey="id"
          selectedKeys={[...selectedKeys, ...disabledKeys]}
          disabledKeys={[
            ...disabledKeys,
            ...(disableSelection ? tableData.map((td: any) => td.id) : []),
          ]}
          onSelectionChange={handleSelect}
          stickyHeader
          noRowsText={noRowText}
        />
      </form>
    </>
  );
};

export default SelectMultipleExtractionPointTable;

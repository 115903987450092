import React, { FunctionComponent } from "react";

const WorkflowUserIcon: FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#0C3C9D"
        fillRule="evenodd"
        d="M7.68 12a2.4 2.4 0 11-4.8 0 2.4 2.4 0 014.8 0zM6 12.76c0 .133-.078.2-.234.2h-.932c-.156 0-.234-.067-.234-.2 0-.065.02-.114.057-.146.037-.033.096-.05.177-.05h.218v-.977l-.215.127a.182.182 0 01-.094.027.161.161 0 01-.13-.066.229.229 0 01-.053-.148c0-.073.031-.13.094-.167l.43-.252a.452.452 0 01.223-.068c.072 0 .13.022.173.065a.236.236 0 01.066.176v1.284h.22c.08 0 .14.016.178.049.037.032.056.08.056.146z"
        clipRule="evenodd"
        opacity="0.48"
      ></path>
      <path
        fill="#0C3C9D"
        fillRule="evenodd"
        d="M21.12 12a3 3 0 11-6 0 3 3 0 016 0zm-1.98 1.367c-.06.049-.148.073-.268.073h-1.486a.311.311 0 01-.232-.087.3.3 0 01-.085-.22.44.44 0 01.138-.314l.863-.892c.18-.185.269-.356.269-.512 0-.091-.026-.161-.08-.21a.323.323 0 00-.225-.072.665.665 0 00-.218.034 1.978 1.978 0 00-.23.099l-.082.04-.063.035a.483.483 0 01-.083.038.244.244 0 01-.078.012.208.208 0 01-.17-.09.346.346 0 01-.07-.217c0-.059.009-.107.027-.145a.268.268 0 01.1-.105c.127-.086.27-.153.429-.201.159-.049.315-.073.47-.073.201 0 .378.033.53.099.151.066.269.16.352.282a.734.734 0 01.124.422c0 .164-.033.314-.1.45-.066.135-.18.287-.34.453l-.578.585h.818c.12 0 .209.024.267.07.059.048.088.121.088.22 0 .103-.03.178-.088.226z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#0C3C9D"
        d="M7.57 12.72h7.68a2.978 2.978 0 010-1.44H7.57a2.38 2.38 0 010 1.44z"
        opacity="0.24"
      ></path>
      <path
        fill="#0C3C9D"
        fillRule="evenodd"
        d="M6 12.76c0 .133-.078.2-.234.2h-.932c-.156 0-.234-.067-.234-.2 0-.065.02-.114.057-.147.037-.032.096-.048.177-.048h.218v-.978l-.215.127a.182.182 0 01-.094.027.161.161 0 01-.13-.066.23.23 0 01-.053-.148c0-.073.031-.13.094-.167l.43-.252a.452.452 0 01.223-.068c.072 0 .13.022.173.065a.236.236 0 01.066.176v1.284h.22c.08 0 .14.016.178.048.037.033.056.082.056.147z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default WorkflowUserIcon;

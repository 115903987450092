import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isFunction } from "lodash";

import api from "@services/api";
import { type AdministrativeApprovalNumericStatus } from "@services/administrativeApprovals";

export const updateStockAndDomestic = async (params: {
  id: string;
  status: AdministrativeApprovalNumericStatus;
  effectiveDate?: string;
  note?: string;
}) => {
  const { id, ...payload } = params;
  const { data } = await api.put(`/admin/stockAndDomestics/${id}`, payload);

  return [data.administrativeApproval, data.workflowInstance];
};

export const useUpdateStockAndDomestic = (params: any = {}) => {
  const { onSuccess, ...options } = params;
  const queryClient = useQueryClient();

  return useMutation(updateStockAndDomestic, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries({
        queryKey: ["administrative_approvals"],
      });
      if (isFunction(onSuccess)) {
        onSuccess(res);
      }
    },
    ...options,
  });
};

import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AccountingPeriodChecklistFooter from "./AccountingPeriodChecklistFooter";
import Heading from "@components/layout/Heading";
import ExternalLinkIcon from "@components/icons/ExternalLinkIcon";
import ArrowRightCalendarIcon from "@components/icons/ArrowRightCalendarIcon";
import TextInput from "@components/form/TextInput";
import Label from "@components/form/Label";
import SelectSubscriber from "@components/form/SelectSubscriber";
import Table from "@components/layout/Table";
import Tag from "@components/shared/Tag";
import PermanentTransferModal from "@components/modal/PermanentTransferModal";
import SelectAdminApprovalStatus from "@components/form/SelectAdminApprovalStatus";
import { getStatusAndText } from "@utils/administrativeApproval";
import { formatDate, formatDatetimeInput } from "@utils/formatDate";
import { formatDateTime } from "@utils/formatDateTime";
import { formatVolume } from "@utils/formatVolume";
import { convertMLToLiter } from "@utils/convertUnits";
import { useAllAdministrativeApprovals } from "@hooks/query/useAllAdministrativeApprovals";
import { useAppContext } from "@context/AppContext";
import { useAccountingPeriodChecksContext } from "@context/AccountingPeriodChecksContext";
import { AdministrativeApprovalType } from "@services/administrativeApprovals";
import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";

type Filter = {
  sellerId: string;
  buyerId: string;
  fromLodgedAt: string;
  toLodgedAt: string;
  fromVolume: number;
  toVolume: number;
  status: number;
};

const PermanentTradesChecklist: React.FunctionComponent = () => {
  const { loggedInInfo } = useAppContext();
  const { currentAccountingPeriodCheck } = useAccountingPeriodChecksContext();
  const { t } = useTranslation();
  const [filter, setFilter] = React.useState<Partial<Filter>>({
    fromLodgedAt: "",
    toLodgedAt: "",
  });
  const [selectedPermanentTrade, setSelectedPermanentTrade] =
    React.useState<any>();

  const { data: permanentTrades = [], isLoading } =
    useAllAdministrativeApprovals({
      params: {
        type: AdministrativeApprovalType.PT,
        level1ResourceId: currentAccountingPeriodCheck?.level1ResourceId,
        accountingPeriodId: currentAccountingPeriodCheck?.accountingPeriod.id,
      },
      options: {
        onSuccess: (data: any) => {
          if (data.length === 0) return;
          if (filter.fromLodgedAt || filter.toLodgedAt) return;
          const allTimestamps = data.map((d: any) => d.createdAt);
          const sortedTimestamps = _.sortBy(allTimestamps);

          const firstDate = sortedTimestamps[0];
          const lastDate = sortedTimestamps[sortedTimestamps.length - 1];

          setFilter(prevState => ({
            ...prevState,
            fromLodgedAt: firstDate,
            toLodgedAt: lastDate,
          }));
        },
      },
    });

  const tableFields = [
    {
      title: t("common.ledger_timestamp"),
      name: "createdAt",
    },
    {
      title: t("common.level1wrs"),
      name: "level1Resource",
    },
    {
      title: t("common.accounting_period"),
      name: "accountingPeriod",
    },
    {
      title: t("common.status"),
      name: "status",
    },
    {
      title: t("approval.permanent_trades.from_subscriber"),
      name: "sellerName",
    },
    {
      title: t("approval.permanent_trades.to_subscriber"),
      name: "buyerName",
    },
    {
      title: t("common.volume"),
      name: "volume",
    },
    {
      title: t("approval.permanent_trades.price"),
      name: "price",
    },
    {
      title: t("permanent_trade.lodged_at"),
      name: "lodgedAt",
    },
    {
      title: t("common.action"),
      name: "action",
    },
  ];

  const tableData = permanentTrades?.map((app: any) => ({
    ...app,
    level1Resource: app.level1Resource.name,
    createdAt: formatDateTime(new Date(app.createdAt)),
    createdAtRaw: app.createdAt,
    lodgedAt: formatDate(new Date(app.lodgedAt)),
    accountingPeriod: formatAccountingPeriod(app.accountingPeriod),
    sellerName: app.seller.name,
    sellerId: app.seller.id,
    buyerName: app.buyer.name,
    buyerId: app.buyer.id,
    volume: formatVolume(app.volume),
    volumeRaw: app.volume,
    price: `${t("common.currency")}${app.price}`,
    rawStatus: app.status,
    status: (
      <Tag status={getStatusAndText(app?.status)?.status ?? "default"}>
        {getStatusAndText(app?.status)?.text}
      </Tag>
    ),
    action: (
      <div className="flex gap-1 items-center w-full">
        <button
          type="button"
          onClick={() => setSelectedPermanentTrade(app)}
          className="btn-outline-primary text-xs rounded"
        >
          {t("common.view")}
        </button>
      </div>
    ),
  }));

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  return (
    <>
      <div className="flex flex-col grow gap-4 p-4">
        <header className="flex justify-between items-center">
          <Heading light>
            {t("accounting_period.checklist.permanent_trades")}{" "}
            <strong className="inline-block ml-2">
              {permanentTrades.length}
            </strong>
          </Heading>
        </header>

        <form className="flex flex-wrap gap-3 justify-start">
          <div className="w-full sm:w-1/2 md:w-auto">
            <Label>{t("permanent_trade.seller")}</Label>
            <div className="max-w-max">
              <SelectSubscriber
                className="w-full sm:w-48"
                onChange={(e: any) => {
                  handleFilterChange("sellerId", e?.value);
                }}
                placeholder={
                  t("accounting_period.checklist.choose_seller") as string
                }
              />
            </div>
          </div>
          <div className="w-full sm:w-1/2 md:w-auto">
            <Label>{t("permanent_trade.buyer")}</Label>
            <div className="max-w-max">
              <SelectSubscriber
                className="w-full sm:w-48"
                onChange={(e: any) => {
                  handleFilterChange("buyerId", e?.value);
                }}
                placeholder={
                  t("accounting_period.checklist.choose_buyer") as string
                }
              />
            </div>
          </div>
          <div>
            <Label>{t("common.status")}</Label>
            <SelectAdminApprovalStatus
              onValueChange={value => handleFilterChange("status", value)}
              adminApprovalsAndTransfers={permanentTrades}
            />
          </div>
          <div className="w-full md:w-auto">
            <Label htmlFor="from_volume">{t("common.volume_range")}</Label>
            <div className="flex flex-col sm:flex-row gap-1">
              <div className="max-w-max">
                <TextInput
                  type="number"
                  name="from_volume"
                  id="from_volume"
                  placeholder={t("common.date_range.from") as string}
                  className="w-full sm:w-32"
                  suffix={t("common.volume_unit")}
                  onChange={e => {
                    handleFilterChange("fromVolume", e.target.value);
                  }}
                  min={0}
                />
              </div>{" "}
              <div className="max-w-max">
                <TextInput
                  type="number"
                  name="to_volume"
                  placeholder={t("common.date_range.to") as string}
                  className="w-full sm:w-32"
                  suffix={t("common.volume_unit")}
                  onChange={e => {
                    handleFilterChange("toVolume", e.target.value);
                  }}
                  min={filter.fromVolume}
                />
              </div>
            </div>
          </div>
          <div className="w-full md:w-auto">
            <Label htmlFor="from_lodged_at">
              {t("common.ledger_timestamp")}
            </Label>
            <div className="flex flex-col sm:flex-row gap-1">
              <div className="max-w-max">
                <TextInput
                  type="datetime-local"
                  id="from_lodged_at"
                  placeholder={t("common.date_range.from") as string}
                  value={
                    filter.fromLodgedAt &&
                    formatDatetimeInput(new Date(filter.fromLodgedAt))
                  }
                  onChange={e => {
                    handleFilterChange(
                      "fromLodgedAt",
                      new Date(e.target.value),
                    );
                  }}
                  className="w-full sm:w-auto"
                />
              </div>
              <ArrowRightCalendarIcon className="w-10 h-10 hidden sm:block" />
              <div className="max-w-max">
                <TextInput
                  type="datetime-local"
                  placeholder={t("common.date_range.to") as string}
                  value={
                    filter.toLodgedAt &&
                    formatDatetimeInput(new Date(filter.toLodgedAt))
                  }
                  onChange={e => {
                    handleFilterChange("toLodgedAt", new Date(e.target.value));
                  }}
                  className="w-full sm:w-auto"
                />
              </div>
            </div>
          </div>
          <div className="flex items-end w-full ml-auto sm:w-auto">
            <Link
              to="/polestar/administrative_approvals/permanent_trade"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-secondary flex items-center gap-1 w-auto"
            >
              <ExternalLinkIcon className="w-4 h-4" />
              {t("accounting_period.checklist.add_permanent_trade")}
            </Link>
          </div>
        </form>

        <Table
          fields={tableFields}
          data={tableData
            ?.filter(
              (pt: any) => !filter.buyerId || pt.buyerId === filter.buyerId,
            )
            ?.filter(
              (pt: any) => !filter.sellerId || pt.sellerId === filter.sellerId,
            )
            ?.filter(
              (pt: any) =>
                !filter.fromVolume ||
                +pt.volumeRaw >= convertMLToLiter(+filter.fromVolume),
            )
            ?.filter(
              (pt: any) =>
                !filter.toVolume ||
                +pt.volumeRaw <= convertMLToLiter(+filter.toVolume),
            )
            ?.filter(
              (pt: any) =>
                !filter.fromLodgedAt ||
                new Date(filter.fromLodgedAt).getTime() <=
                  new Date(pt.createdAtRaw).getTime(),
            )
            ?.filter(
              (pt: any) =>
                !filter.toLodgedAt ||
                new Date(filter.toLodgedAt).getTime() >=
                  new Date(pt.createdAtRaw).getTime(),
            )
            ?.filter(
              (pt: any) => !filter.status || filter.status === pt.rawStatus,
            )}
          loading={isLoading}
          stickyHeader
        />
      </div>

      <AccountingPeriodChecklistFooter
        stepData={{
          permanentTradesCheckedAt: new Date(),
          permanentTradesCheckedByUserId: loggedInInfo?.userDetails?.id,
        }}
      />

      <PermanentTransferModal
        data={selectedPermanentTrade}
        onClose={() => {
          setSelectedPermanentTrade(undefined);
        }}
      />
    </>
  );
};

export default PermanentTradesChecklist;

import classNames from "classnames";

export type Stat = {
  name: string;
  value: string;
  change: string;
  changeType: "negative" | "positive" | "equal";
};

type Props = {
  data: Stat[];
};

const Stats: React.FunctionComponent<Props> = ({ data }) => {
  return (
    <dl className="grid grid-cols-1 sm:grid-cols-2">
      {data.map((stat) => (
        <div
          key={stat.name}
          className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 px-4 py-6 sm:px-6 xl:px-8"
        >
          <dt className="leading-6 text-gray-500">{stat.name}</dt>
          <dd
            className={classNames(
              stat.changeType === "negative"
                ? "text-rose-600"
                : "text-green-600",
              "text-xs font-medium"
            )}
          >
            {stat.change}
          </dd>
          <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-primary-1">
            {stat.value}
          </dd>
        </div>
      ))}
    </dl>
  );
};

export default Stats;

import ENV from "@config/env";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { noop } from "lodash";

import Layout from "@components/layout/Layout";
import WorkflowPanels from "@components/form/WorkflowPanels";
import TrialRun from "@components/form/meter/bulk_upload/TrialRun";
import UploadFile from "@components/form/meter/bulk_upload/UploadFile";
import InfoPanel from "@components/form/InfoPanel";
import SelectLevel1WRS from "@components/form/meter/bulk_upload/SelectLevel1WRS";
import StepButton from "@components/shared/StepButton";
import Loading from "@components/shared/Loading";
import StepInfo from "@components/shared/StepInfo";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";
import {
  BulkMeterUploadProvider,
  useBulkMeterUploadContext,
} from "@context/BulkMeterUploadContext";
import { StepProvider, useStepContext } from "@context/shared/StepContext";
import {
  EvidenceProvider,
  useEvidenceContext,
} from "@context/shared/EvidenceContext";

const BulkMeterUpload = () => {
  return (
    <StepProvider maxStep={3}>
      <EvidenceProvider
        accept={{
          "application/vnd.ms-excel": [".xls"],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
            ".xlsx",
          ],
        }}
        multiple={false}
      >
        <BulkMeterUploadProvider>
          <Consumer />
        </BulkMeterUploadProvider>
      </EvidenceProvider>
    </StepProvider>
  );
};

const Consumer = () => {
  const { t } = useTranslation();
  const { id: level1ResourceId = "" } = useParams();
  const { data: level1Resource, isLoading } = useGetLevel1Resource(
    level1ResourceId,
    {
      enabled: Boolean(level1ResourceId),
    }
  );
  const { currentStep } = useStepContext();
  const { getEvidencesInfo, evidences: files = [] } = useEvidenceContext();
  const { level1WRS, isValidFile, isComplete } = useBulkMeterUploadContext();

  if (isLoading) {
    return (
      <div className="pt-80">
        <Loading />
      </div>
    );
  }

  const templateDownloadUrl = `${process.env.PUBLIC_URL}${
    ENV.CLIENT_ID === "seqwater" ? "/seqwater" : ""
  }/bulk_meter_reading_template.xlsx`;

  const steps = [
    {
      label: (
        <StepButton
          index={0}
          currentStep={currentStep}
          info={
            <p>
              <strong>{t("common.level1wrs")}: </strong>
              {level1WRS?.name}
            </p>
          }
        >
          {t("bulk_meter_upload.select_level1wrs")}
        </StepButton>
      ),
      panel: <SelectLevel1WRS />,
    },
    {
      label: (
        <StepButton
          index={1}
          currentStep={currentStep}
          info={<StepInfo data={getEvidencesInfo().body} />}
        >
          {t("bulk_meter_upload.select_file")}
        </StepButton>
      ),
      panel: <UploadFile />,
      infoPanel: (
        <InfoPanel
          errors={
            isValidFile
              ? []
              : [
                  t("bulk_meter_upload.info.error_invalid_format", {
                    fileName: files?.[0]?.name ?? "",
                  }),
                ]
          }
          actions={[
            {
              label: (
                <a href={templateDownloadUrl} download>
                  {t("bulk_meter_upload.download_template")}
                </a>
              ),
              action: noop,
            },
          ]}
        >
          <div className="space-y-4 leading-relaxed">
            <p>
              {t("bulk_meter_upload.info.level1WRS", {
                level1WRSName: level1WRS?.name,
              })}
            </p>
            <div>
              <p>{t("bulk_meter_upload.info.template_description")}</p>

              <ul className="pl-2 list-inside list-disc">
                {[
                  ...(ENV.CLIENT_ID === "seqwater" ? ["group", "seq"] : []),
                  "account_number",
                  "extraction_point_name",
                  "meter_serial_no",
                  "meter_read",
                  "date",
                ].map((value) => (
                  <li key={value}>
                    <strong>{t(`bulk_meter_upload.table.${value}`)}:</strong>{" "}
                    {t(`bulk_meter_upload.info.template_columns.${value}`)}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton index={2} currentStep={currentStep}>
          {t("bulk_meter_upload.title.trial_run")}
        </StepButton>
      ),
      panel: <TrialRun />,
      infoPanel: (
        <InfoPanel
          successes={isComplete ? [t("bulk_meter_upload.info.complete")] : []}
        >
          <div className="space-y-6">
            {isComplete ? (
              t("user.info.end_workflow")
            ) : (
              <div className="space-y-6">
                <p>{t("bulk_meter_upload.info.trial_run")}</p>
                <div className="space-y-2">
                  <p>{t("bulk_meter_upload.info.status")}</p>
                  <ul className="space-y-2">
                    <li>
                      <strong>{t("common.success")}: </strong>
                      {t("bulk_meter_upload.info.success")}
                    </li>
                    <li>
                      <strong>{t("common.warning")}: </strong>
                      {t("bulk_meter_upload.info.warning")}
                    </li>
                    <li>
                      <strong>{t("common.error")}: </strong>
                      {t("bulk_meter_upload.info.error")}
                    </li>
                  </ul>
                </div>
                <p>{t("bulk_meter_upload.info.proceed")}</p>
              </div>
            )}
          </div>
        </InfoPanel>
      ),
    },
  ];

  return (
    <Layout
      permissions={["BulkUploadMeterReadings"]}
      breadcrumb={[
        {
          label: t("common.home"),
          href: "/polestar",
        },
        {
          label: level1Resource?.name,
          href: `/polestar/level1wrs/${level1ResourceId}`,
        },
        {
          label: t("level1wrs.extraction_points"),
          href: `/polestar/level1wrs/${level1ResourceId}#5`,
        },
        {
          label: t("bulk_meter_upload.title.upload"),
        },
      ]}
      title={t("bulk_meter_upload.title.upload")}
    >
      <WorkflowPanels selectedStep={currentStep} steps={steps} />
    </Layout>
  );
};

export default BulkMeterUpload;

type Props = {
  className?: string;
};

const LockOutlineIcon: React.FunctionComponent<Props> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      stroke="currentColor"
      viewBox="0 0 24 24"
      strokeWidth={0.3}
      className={className}
    >
      <path d="M16.844 22.73h0a3.123 3.123 0 003.12-3.12v0-6.826h0a3.124 3.124 0 00-3.12-3.12h0-9.687 0a3.123 3.123 0 00-3.12 3.12v6.826h0a3.123 3.123 0 003.12 3.12h9.687zM5.768 11.394a1.97 1.97 0 011.39-.577h9.686a1.967 1.967 0 011.967 1.966v6.83a1.97 1.97 0 01-1.967 1.967H7.158a1.97 1.97 0 01-1.967-1.967s0 0 0 0v-6.83s0 0 0 0c0-.52.208-1.02.577-1.39z"></path>
      <path d="M10.911 18.293l.106.106.106-.106 4.344-4.343.106-.106-.106-.106-.604-.604-.106-.106-.106.106-3.634 3.634-1.667-1.667-.106-.106-.106.106-.603.6-.107.105.106.107 2.377 2.38zM9.332 10.61h.15V7.667a2.517 2.517 0 015.034 0v2.94h1.15v-2.94a3.667 3.667 0 00-7.334 0v2.943h1z"></path>
      <path d="M18.234 10.61h.15V7.667a6.383 6.383 0 10-12.766 0v2.957H6.77V7.667a5.23 5.23 0 1110.46 0v2.943h1.003z"></path>
    </svg>
  );
};

export default LockOutlineIcon;

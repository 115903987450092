import { getAllReports } from "@services/reports";
import { useQuery } from "@tanstack/react-query";
import { formatDate } from "@utils/formatDate";
import { endOfQuarter, endOfYear } from "date-fns";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAppContext } from "../../../../context/AppContext";
import ReportIcon from "../../../icons/ReportIcon";
import Card from "../../../layout/Card";
import Table from "../../../layout/Table";

const ReportWidget: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { checkPermissions } = useAppContext();

  const { data: reports, isLoading } = useQuery(["reports"], getAllReports, {
    keepPreviousData: true,
    refetchOnWindowFocus: true,
  });

  if (!checkPermissions(["ViewReports"])) return null;

  return (
    <Card
      className="bg-inherit border-none p-0"
      header={
        <p className="flex gap-3 h-8 text-lg">
          <ReportIcon /> {t("reporting.widget_title")}
        </p>
      }
    >
      <Table
        className="text-xs rounded-lg"
        containerClassName="border border-gray-400 border-2 shadow-xl md:rounded-xl rounded-lg h-46"
        paginationClassName="bg-inherit"
        fields={[
          {
            title: t("reporting.report_name"),
            name: "name",
          },
          {
            title: t("reporting.due_date"),
            name: "dueDate",
          },
          {
            title: t("common.action"),
            name: "action",
          },
        ]}
        data={
          reports
            ?.filter((report) => report.reportFrequency)
            ?.map((report) => ({
              ...report,
              name: (
                <div className="overflow-hidden whitespace-normal line-clamp-2">
                  {report.name}
                </div>
              ),
              dueDate:
                report.reportFrequency === "Q"
                  ? formatDate(endOfQuarter(new Date()))
                  : report.reportFrequency === "A"
                  ? formatDate(endOfYear(new Date()))
                  : "",
              action: (
                <div className="flex gap-1 items-center w-full">
                  <Link
                    to={`/polestar/reports/${
                      report.category === 2 ? "billing" : "regulatory"
                    }/${report.id}`}
                    className="btn-primary py-2 px-10"
                  >
                    {t("common.view")}
                  </Link>
                </div>
              ),
            })) || []
        }
        noRowsText={
          isLoading
            ? t("reporting.widget_loading_data")
            : t("reporting.widget_no_data")
        }
        pageSize={2}
      />
    </Card>
  );
};

export default ReportWidget;

import React from "react";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import ConfirmationDetail, {
  ConfirmData,
} from "@components/shared/ConfirmationDetail";
import ConfirmModal from "@components/shared/ConfirmModal";
import WorkflowComplete from "@components/shared/WorkflowComplete";
import { isValidationError } from "@utils/formError";
import { toastError, toastSuccess } from "@utils/toast";

type ConfirmMeterProps = {
  workflowInstanceIds: string[];
  onConfirm: () => Promise<void>;
  onCancel: () => void;
  isComplete: boolean;
  data: ConfirmData[];
  onPrevious: () => void;
  onEdit: (step: number) => void;
};

const ConfirmMeter: React.FunctionComponent<ConfirmMeterProps> = ({
  workflowInstanceIds,
  onConfirm,
  onCancel,
  isComplete,
  data,
  onPrevious,
  onEdit,
}) => {
  const { t } = useTranslation();
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      await onConfirm();
      toastSuccess(t("meter.toast.success"));
    } catch (error: any) {
      const errorMessages: string[] = isValidationError(error)
        ? error.response?.data?.errors.map((i: any) => i.message)
        : [];

      toastError(
        <>
          <p>
            {t("meter.toast.failure", {
              error: error?.response?.data?.message || error?.message,
            })}
          </p>
          {errorMessages.length ? (
            <ul className="list-disc pl-4">
              {errorMessages.map((text: any) => (
                <li key={text}>{text}</li>
              ))}
            </ul>
          ) : null}
        </>
      );
    }
    setIsLoading(false);
    setShowConfirmModal(false);
  };

  if (isComplete && workflowInstanceIds.length) {
    return <WorkflowComplete references={workflowInstanceIds} />;
  }

  return (
    <>
      <div className="flex flex-col gap-4 grow">
        <div className="p-6 space-y-4">
          <Heading light>{t("meter.adding_meter.confirm")}</Heading>
          <ConfirmationDetail data={data} onEdit={onEdit} />
        </div>

        <div className="grow" />

        <footer className="flex gap-4 p-6 border-t border-gray-200">
          <button
            type="button"
            className="btn-outline-primary"
            onClick={onPrevious}
          >
            {t("common.prev_step")}
          </button>
          <button
            type="button"
            className="btn-primary"
            onClick={() => {
              setShowConfirmModal(true);
            }}
          >
            {t("common.ledger")}
          </button>
          <button
            type="button"
            className="btn-outline-primary"
            onClick={onCancel}
          >
            {t("common.cancel")}
          </button>
        </footer>
      </div>

      <ConfirmModal
        open={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
        }}
        onConfirm={handleConfirm}
        isSubmitting={isLoading}
      >
        {t("meter.adding_meter.confirm_message")}
      </ConfirmModal>
    </>
  );
};

export default ConfirmMeter;

import { useTranslation } from "react-i18next";
import { BellIcon } from "@heroicons/react/24/solid";

import Card from "@components/layout/Card";
import NotificationListCompact from "@components/form/notification/NotificationListCompact";
import Loading from "@components/shared/Loading";
import { useAllNotification } from "@hooks/query/useAllNotifications";

type SubscriberNotificationWidgetProps = {
  walletId: string;
};

const SubscriberNotificationWidget: React.FunctionComponent<
  SubscriberNotificationWidgetProps
> = ({ walletId }) => {
  const { t } = useTranslation();

  const { data = {}, isLoading } = useAllNotification({
    params: {
      recipientWalletId: walletId,
      limit: -1,
    },
  });

  if (isLoading) {
    return (
      <div className="pt-20">
        <Loading />
      </div>
    );
  }

  const notifications = data.data ?? [];

  return (
    <Card
      className="h-full"
      header={
        <p className="flex gap-3">
          <BellIcon className="w-6 h-6" />
          {t("notification.subscriber_widget_title")}
        </p>
      }
    >
      {notifications?.length > 0 ? (
        <NotificationListCompact notifications={notifications} />
      ) : (
        <div className="font-light text-primary-2 text-center text-xl">
          {t("notification.no_data")}
        </div>
      )}
    </Card>
  );
};

export default SubscriberNotificationWidget;

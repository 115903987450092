import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HandleGoBackOrClose from "@components/shared/HandleGoBackOrClose";
import { UseStepHelpers, useStep } from "@hooks/useStep";
import {
  GetInfoMessagesFunction,
  ModifyStatusesFunction,
  useStepStatuses,
} from "@hooks/useStepStatuses";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";
import { useLevel0Resource } from "@hooks/query/useLevel0Resource";
import { useValidateLevel0ResourcePeriodStart } from "@hooks/mutation/useValidateLevel0ResourcePeriodStart";
import { useValidateLevel0ResourcePeriodEnd } from "@hooks/mutation/useValidateLevel0ResourcePeriodEnd";
import { convertLiterToML } from "@utils/convertUnits";
import { formatDate } from "@utils/formatDate";
import { formatVolume } from "@utils/formatVolume";
import { getLatitudeLongitude } from "@utils/getLatitudeLongitude";
import { toastError } from "@utils/toast";

import { type ConfirmData } from "@components/shared/ConfirmationDetail";

type Trading = {
  isInternalTradeAllowed: boolean;
  isInternalTradeLimited: boolean;
  internalTradeLimit: string;
  isInboundTradeAllowed: boolean;
  isInboundTradeLimited: boolean;
  inboundTradeLimit: string;
  isOutboundTradeAllowed: boolean;
  isOutboundTradeLimited: boolean;
  outboundTradeLimit: string;
  isReciprocalTrading: boolean;
  inboundZoneExchanges: string[];
  outboundZoneExchanges: string[];
};

type WaterClass = {
  id: string;
  name: string;
  volume: number;
  acctPriority: number;
  volumeNextAccountingPeriod: number;
};

type Level0WRSDetails = {
  id: string;
  name: string;
  identifier: string;
  description: string;
  geography: string;
  yield: string;
  yieldNextAccountingPeriod: string;
  trade: Trading;
  administration: AdministrationApprovals;
  createdAt: string;
  level1WRS: {
    id: string;
    name: string;
  };
  accountingPeriodId: string;
  periodStart?: Date;
  periodEnd?: Date;
  waterClasses: WaterClass[];
  activatedEvidenceIds: string[];
  distributionLoss?: {
    extractionRightId?: string;
    extractionRightName?: string;
    volume?: number;
    percentage?: number;
  };
  source: string;
};

export type AdministrationApprovals = {
  stockAndDomestic: boolean;
  forwardDraw: boolean;
  seasonalTransfer: boolean;
  permanentTrade: boolean;
  waterHarvesting: boolean;
  specialAnnouncement: boolean;
};

type HandleChangeDetails = (
  key: keyof Level0WRSDetails,
  value: any,
  subKey?: keyof Trading | keyof AdministrationApprovals
) => void;

type ValidateYearStart = (
  level1wrsId: string,
  periodStart: Date
) => Promise<void>;
type ValidateYearEnd = (
  level1wrsId: string,
  periodStart: Date
) => Promise<void>;

type ContextValue = {
  currentStep: number;
  stepHelpers: UseStepHelpers;
  details: Level0WRSDetails;
  setDetails: React.Dispatch<React.SetStateAction<Level0WRSDetails>>;
  handleChangeDetails: HandleChangeDetails;
  locationCoordinates: LocationCoordinates;
  setLocationCoordinates: React.Dispatch<
    React.SetStateAction<LocationCoordinates>
  >;
  saveGeographyDetails: any;
  locationFiles: File[];
  setLocationFiles: React.Dispatch<React.SetStateAction<File[]>>;
  photoFiles: File[];
  setPhotoFiles: React.Dispatch<React.SetStateAction<File[]>>;
  workflowCompleted: boolean;
  setWorkflowCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  workflowInstance: any;
  setWorkflowInstance: React.Dispatch<any>;
  validateYearStart: ValidateYearStart;
  validateYearEnd: ValidateYearEnd;
  networkErrors: string[];
  setNetworkErrors: React.Dispatch<React.SetStateAction<string[]>>;
  getDetails: () => ConfirmData;
  getAccountingPeriodAndVolume: () => ConfirmData;
  getDistributionLoss: () => ConfirmData;
  getTradingLinks: () => ConfirmData;
  getAdministrationInfo: () => ConfirmData;
  getInfoMessages: GetInfoMessagesFunction;
  modifyStatuses: ModifyStatusesFunction;
  navigateForCancel: () => void;
  updatingLevel0WRS: any;
  setUpdatingLevel0WRS: React.Dispatch<any>;
  specialAction: string;
  setSpecialAction: React.Dispatch<React.SetStateAction<string>>;
};

const CreateLevel0WRSContext = React.createContext<ContextValue | undefined>(
  undefined
);

type LocationCoordinates = typeof initialLocationCoordinates;

const initialDetails: Level0WRSDetails = {
  id: "",
  name: "",
  identifier: "",
  description: "",
  geography: "",
  yield: "",
  yieldNextAccountingPeriod: "",
  trade: {
    isInternalTradeAllowed: false,
    isInternalTradeLimited: false,
    internalTradeLimit: "",
    isInboundTradeAllowed: false,
    isInboundTradeLimited: false,
    inboundTradeLimit: "",
    isOutboundTradeAllowed: false,
    isOutboundTradeLimited: false,
    outboundTradeLimit: "",
    isReciprocalTrading: false,
    inboundZoneExchanges: [],
    outboundZoneExchanges: [],
  },
  administration: {
    stockAndDomestic: false,
    forwardDraw: false,
    seasonalTransfer: false,
    permanentTrade: false,
    waterHarvesting: false,
    specialAnnouncement: false,
  },
  activatedEvidenceIds: [],
  createdAt: "",
  level1WRS: {
    id: "",
    name: "",
  },
  accountingPeriodId: "",
  waterClasses: [],
  distributionLoss: {
    percentage: 100,
    extractionRightName: "",
  },
  source: "",
};

const initialLocationCoordinates = {
  lat: "",
  lng: "",
};

const Level0WRSProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const handleGoBackOrClose = HandleGoBackOrClose();
  const { id: level1ResourceId, level0wrsId = "" } = useParams();
  const [searchParams] = useSearchParams();
  const startingStep = searchParams.get("startingStep");
  const maxStep = 8;
  const [currentStep, stepHelpers] = useStep(maxStep);
  const { getInfoMessages, modifyStatuses } = useStepStatuses(maxStep);

  const [details, setDetails] = React.useState({ ...initialDetails });
  const [locationCoordinates, setLocationCoordinates] = React.useState({
    ...initialLocationCoordinates,
  });
  const [locationFiles, setLocationFiles] = React.useState<File[]>([]);
  const [networkErrors, setNetworkErrors] = React.useState<string[]>([]);
  const [photoFiles, setPhotoFiles] = React.useState<File[]>([]);
  const [workflowCompleted, setWorkflowCompleted] = React.useState(false);
  const [workflowInstance, setWorkflowInstance] = React.useState<any>();
  const [updatingLevel0WRS, setUpdatingLevel0WRS] = React.useState<any>();
  const [specialAction, setSpecialAction] = React.useState("");

  const { mutateAsync: checkLevel0ResourceYearStartMutation } =
    useValidateLevel0ResourcePeriodStart();
  const { mutateAsync: checkLevel0ResourceYearEndMutation } =
    useValidateLevel0ResourcePeriodEnd();

  React.useEffect(() => {
    if (startingStep !== null && !isNaN(+startingStep)) {
      stepHelpers.setStep(+startingStep);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startingStep]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep, workflowCompleted]);

  useLevel0Resource(level0wrsId, {
    refetchOnWindowFocus: true,
    onSuccess: (data: any) => {
      setUpdatingLevel0WRS(data);

      if (details.id === "") {
        const {
          isValid: isValidLocation,
          lat,
          lng,
        } = getLatitudeLongitude(data.geography);
        if (isValidLocation) {
          setLocationCoordinates({
            lat: lat ? lat.toString() : "",
            lng: lng ? lng.toString() : "",
          });
        }

        setDetails(
          (prevState): Level0WRSDetails => ({
            ...prevState,
            id: data.id,
            name: data.name,
            identifier: data.identifier,
            yield: convertLiterToML(data.yield),
            yieldNextAccountingPeriod: convertLiterToML(
              data.yieldNextAccountingPeriod
            ),
            trade: {
              ...prevState.trade,
              isInternalTradeAllowed: data.inboundTradeAllowed,
              isInternalTradeLimited: data.internalLimit !== "0",
              internalTradeLimit: convertLiterToML(+data.internalLimit),
              isInboundTradeAllowed: data.inboundTradeAllowed,
              isInboundTradeLimited: data.inboundLimit !== "0",
              inboundTradeLimit: convertLiterToML(+data.inboundLimit),
              isOutboundTradeAllowed: data.outboundTradeAllowed,
              isOutboundTradeLimited: data.outboundLimit !== "0",
              outboundTradeLimit: convertLiterToML(+data.outboundLimit),
              inboundZoneExchanges:
                data.inboundExchangeZoneFrees?.map(
                  (zone: { id: string }) => zone.id
                ) ?? [],
              outboundZoneExchanges:
                data.outboundExchangeZoneFrees.map(
                  (zone: { id: string }) => zone.id
                ) ?? [],
            },
            administration: {
              stockAndDomestic: data.sdApproval,
              forwardDraw: data.fdApproval,
              seasonalTransfer: data.stApproval,
              permanentTrade: data.ptApproval,
              waterHarvesting: data.whApproval,
              specialAnnouncement: data.saApproval,
            },
            source: data.source,
          })
        );
      }

      const {
        waterClasses = [],
        yield: volume,
        yieldNextAccountingPeriod,
      } = data;

      setDetails((prev) => ({
        ...prev,
        waterClasses,
      }));

      if (+yieldNextAccountingPeriod > 0) {
        modifyStatuses({
          stepNumber: 2,
          fieldName: "yieldNextYear_1",
          message: (
            <div>
              {t("level0wrs.update.step_3.warning_7_A", {
                yieldNextAccountingPeriod: formatVolume(
                  +yieldNextAccountingPeriod
                ),
              })}
              {waterClasses.length > 0 && (
                <>
                  {" "}
                  <span>{t("level0wrs.update.step_3.warning_7_B")}</span>
                  <ul className="mt-1 pl-3 list-disc space-y-1">
                    {waterClasses.map((wc: any) => (
                      <li key={`${wc.name}-next-volume`}>
                        <strong>{wc.name}: </strong>
                        {formatVolume(wc.volumeNextAccountingPeriod)}
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          ),

          infoType: "warning",
        });
        modifyStatuses({
          stepNumber: 2,
          fieldName: "yieldNextYear_2",
          message: t("level0wrs.update.step_3.warning_8") as string,
          infoType: "warning",
        });

        if (+yieldNextAccountingPeriod !== +volume)
          modifyStatuses({
            stepNumber: 2,
            fieldName: "yieldNextYear_3",
            message: t("level0wrs.update.step_3.warning_9", {
              volume: formatVolume(+volume),
            }) as string,
            infoType: "warning",
          });
      }
    },
  });

  useGetLevel1Resource(level1ResourceId ?? "", {
    onSuccess: (data: any) => {
      setDetails(
        (prevState): Level0WRSDetails => ({
          ...prevState,
          accountingPeriodId: data.activeAccountingPeriod.id,
          periodStart: new Date(data.activeAccountingPeriod.periodStart),
          periodEnd: new Date(data.activeAccountingPeriod.periodEnd),
          level1WRS: {
            id: data.id,
            name: data.name,
          },
        })
      );
    },
  });

  const handleChangeDetails: HandleChangeDetails = (
    key: any,
    value: any,
    subKey: any
  ) => {
    setDetails((prevState: any) => {
      const updatedDetails: any = { ...prevState };
      if (subKey) {
        updatedDetails[key] = Object.assign({}, prevState[key], {
          [subKey]: value,
        });
      } else {
        updatedDetails[key] = value;
      }
      return updatedDetails;
    });
  };

  const validateYearStart: ValidateYearStart = async (
    level1wrsId,
    periodStart
  ) => {
    try {
      const status = await checkLevel0ResourceYearStartMutation({
        level1wrsId,
        periodStart,
      });

      if (!status.exists) {
        modifyStatuses({
          stepNumber: 2,
          fieldName: "periodStart",
          message: t(
            "level0wrs.create.info_panel.step_3.success_year_start"
          ) as string,
          infoType: "success",
        });
        return;
      }

      if (status.yearStartMatches) {
        modifyStatuses({
          stepNumber: 2,
          fieldName: "periodStart",
          message: t(
            "level0wrs.create.info_panel.step_3.success_year_start_matches"
          ) as string,
          infoType: "success",
        });
      } else {
        modifyStatuses({
          stepNumber: 2,
          fieldName: "periodStart",
          message: t(
            "level0wrs.create.info_panel.step_3.error_year_start_not_matches",
            { periodStart: formatDate(new Date(status.defaultYearStart)) }
          ) as string,
          infoType: "error",
        });
      }
    } catch (error) {
      toastError(t("level0wrs.create.toast.check_year_start_failure"));
    }
  };

  const validateYearEnd: ValidateYearEnd = async (level1wrsId, periodEnd) => {
    try {
      const status = await checkLevel0ResourceYearEndMutation({
        level1wrsId,
        periodEnd,
      });

      if (!status.exists) {
        modifyStatuses({
          stepNumber: 2,
          fieldName: "periodEnd",
          message: t(
            "level0wrs.create.info_panel.step_3.success_year_end"
          ) as string,
          infoType: "success",
        });
        return;
      }

      if (status.yearEndMatches) {
        modifyStatuses({
          stepNumber: 2,
          fieldName: "periodEnd",
          message: t(
            "level0wrs.create.info_panel.step_3.success_year_end_matches"
          ) as string,
          infoType: "success",
        });
      } else {
        modifyStatuses({
          stepNumber: 2,
          fieldName: "periodEnd",
          message: t(
            "level0wrs.create.info_panel.step_3.error_year_end_not_matches",
            { periodEnd: formatDate(new Date(status.defaultYearEnd)) }
          ) as string,
          infoType: "error",
        });
      }
    } catch (error) {
      toastError(t("level0wrs.create.toast.check_year_end_failure"));
    }
  };

  const saveGeographyDetails = () => {
    setDetails((prevState: any) => {
      const updatedDetails: any = { ...prevState };
      updatedDetails["geography"] =
        locationCoordinates.lat !== ""
          ? `${locationCoordinates.lat},${locationCoordinates.lng}`
          : locationFiles && locationFiles.length > 0
          ? locationFiles[0].name
          : "";
      return updatedDetails;
    });
  };

  const getDetails = () => {
    return {
      title: t("level0wrs.create.details"),
      body: [
        { key: t("level0wrs.create.name"), value: details?.name },
        {
          key: t("level0wrs.create.identifier"),
          value: details?.identifier,
        },
        {
          key: t("level0wrs.source"),
          value: details?.source,
        },
      ],
    };
  };

  const getAccountingPeriodAndVolume = () => {
    return {
      title: t("level0wrs.create.year_and_volume"),
      body: [
        {
          key: t("level0wrs.create.accounting_period"),
          value: `${formatDate(details?.periodStart as any)} - ${formatDate(
            details?.periodEnd as any
          )}`,
        },
        {
          key: t("level0wrs.create.volume"),
          value: `${details?.yield} ${t("common.volume_unit")}`,
        },
      ],
    };
  };

  const getDistributionLoss = () => {
    return {
      title: t("level0wrs.create.distribution_loss"),
      body: !details?.distributionLoss?.extractionRightName
        ? [
            {
              key: t(
                "level0wrs.create.enter_distribution_loss_extraction_right_name"
              ),
              value: "-",
            },
          ]
        : [
            {
              key: t(
                "level0wrs.create.enter_distribution_loss_extraction_right_name"
              ),
              value: details?.distributionLoss?.extractionRightName,
            },
            {
              key: t("level0wrs.create.enter_distribution_loss_volume"),
              value: `${details?.distributionLoss?.volume} ${t(
                "common.volume_unit"
              )}`,
            },
            {
              key: t("level0wrs.create.enter_distribution_loss_percentage"),
              value: `${details?.distributionLoss?.percentage} %`,
            },
          ],
    };
  };

  const getTradingLinks = () => {
    return {
      title: t("level0wrs.create.trading_links"),
      body: [
        {
          key: t("level0wrs.create.internal_trading"),
          value: details?.trade.isInternalTradeAllowed
            ? `${t("common.yes")}, ${
                details?.trade.isInternalTradeLimited
                  ? `${details?.trade.internalTradeLimit} ${t(
                      "common.volume_unit"
                    )}`
                  : t("level0wrs.create.no_trade_limit")
              }`
            : t("common.no"),
        },
        {
          key: t("level0wrs.create.inbound_trading"),
          value: details?.trade.isInboundTradeAllowed
            ? `${t("common.yes")}, ${
                details?.trade.isInboundTradeLimited
                  ? `${details?.trade.inboundTradeLimit} ${t(
                      "common.volume_unit"
                    )}`
                  : t("level0wrs.create.no_trade_limit")
              } ${t("level0wrs.create.trade_from_to", {
                identifier: details?.identifier,
                zones: details?.trade.inboundZoneExchanges.length,
              })}`
            : t("common.no"),
        },
        {
          key: t("level0wrs.create.outbound_trading"),
          value: details?.trade.isOutboundTradeAllowed
            ? `${t("common.yes")}, ${
                details?.trade.isOutboundTradeLimited
                  ? `${details?.trade.outboundTradeLimit} ${t(
                      "common.volume_unit"
                    )}`
                  : t("level0wrs.create.no_trade_limit")
              } ${t("level0wrs.create.trade_to_from", {
                identifier: details?.identifier,
                zones: details?.trade.outboundZoneExchanges.length,
              })}`
            : t("common.no"),
        },
      ],
    };
  };

  const getAdministrationInfo = () => {
    const { administration } = details;
    return {
      title: t("level0wrs.create.administration"),
      body: [
        {
          key: t("level0wrs.create.approval.stock_and_domestic"),
          value: administration?.stockAndDomestic
            ? t("common.yes")
            : t("common.no"),
        },
        {
          key: t("level0wrs.create.approval.forward_draw"),
          value: administration?.forwardDraw ? t("common.yes") : t("common.no"),
        },
        {
          key: t("level0wrs.create.approval.seasonal_transfer"),
          value: administration?.seasonalTransfer
            ? t("common.yes")
            : t("common.no"),
        },
        {
          key: t("level0wrs.create.approval.permanent_trade"),
          value: administration?.permanentTrade
            ? t("common.yes")
            : t("common.no"),
        },
        {
          key: t("level0wrs.create.approval.water_harvesting"),
          value: administration?.waterHarvesting
            ? t("common.yes")
            : t("common.no"),
        },
        {
          key: t("level0wrs.create.approval.special_announcement"),
          value: administration?.specialAnnouncement
            ? t("common.yes")
            : t("common.no"),
        },
      ],
    };
  };

  const navigateForCancel = handleGoBackOrClose;

  const values: ContextValue = {
    currentStep,
    stepHelpers,
    details,
    setDetails,
    handleChangeDetails,
    locationCoordinates,
    setLocationCoordinates,
    saveGeographyDetails,
    locationFiles,
    setLocationFiles,
    photoFiles,
    setPhotoFiles,
    workflowCompleted,
    setWorkflowCompleted,
    workflowInstance,
    setWorkflowInstance,
    validateYearStart,
    validateYearEnd,
    networkErrors,
    setNetworkErrors,
    getDetails,
    getAccountingPeriodAndVolume,
    getDistributionLoss,
    getTradingLinks,
    getAdministrationInfo,
    getInfoMessages,
    modifyStatuses,
    navigateForCancel,
    updatingLevel0WRS,
    setUpdatingLevel0WRS,
    specialAction,
    setSpecialAction,
  };

  return (
    <CreateLevel0WRSContext.Provider value={values}>
      {children}
    </CreateLevel0WRSContext.Provider>
  );
};

const useLevel0WRSContext = () => {
  const context = React.useContext(CreateLevel0WRSContext);
  if (context === undefined) {
    throw new Error(
      "useLevel0WRSContext must be used within a Level0WRSProvider"
    );
  }
  return context;
};

export { Level0WRSProvider, useLevel0WRSContext };

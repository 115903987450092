import { useMutation } from "@tanstack/react-query";

import {
  createExtractionPoint,
  CreateExtractionPointPayload,
} from "@services/extractionPoints";

export function useCreateExtractionPoint(options: any = {}) {
  return useMutation<any, any, CreateExtractionPointPayload>({
    mutationKey: ["create_extraction_point"],
    mutationFn: createExtractionPoint,
    ...options,
  });
}

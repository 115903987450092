import React from "react";
import Select from "react-select";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import Heading from "@components/layout/Heading";
import Legend from "@components/form/Legend";
import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import TextArea from "@components/form/TextArea";
import DropzoneBox from "@components/shared/DropzoneBox";
import { useCreateLevel1WRSContext } from "@context/CreateLevel1WRSContext";
import { useAllLevel2Resources } from "@hooks/query/useAllLevel2Resources";
import { useAllLevel3Resources } from "@hooks/query/useAllLevel3Resources";
import { useAllLevel4Resources } from "@hooks/query/useAllLevel4Resources";
import { formatDateInput } from "@utils/formatDate";
import { toastError } from "@utils/toast";

const transformToOptions = (data: any) => {
  return data.map((scheme: any) => ({
    label: scheme.name,
    value: scheme.id,
  }));
};
const Level1WRSDetails = () => {
  const { t } = useTranslation();
  const {
    stepHelpers,
    details,
    handleChangeDetails,
    photoFiles,
    setPhotoFiles,
    validateName,
    validateIdentifier,
    handleCancel,
    getInfoMessages,
  } = useCreateLevel1WRSContext();
  const { data: level4ResourceOptions, isFetching: isLevel4ResoucesFetching } =
    useAllLevel4Resources({
      select: transformToOptions,
      onSuccess: (data: any) => {
        const { level4WRS } = details;
        if (level4WRS.id && level4WRS.name) {
          return;
        }

        const selected = data.find(
          (i: any) => i.label.toLowerCase() === "australia"
        ) ?? {
          label: "",
          value: "",
        };
        handleChangeDetails("level4WRS", selected.label, "name");
        handleChangeDetails("level4WRS", selected.value, "id");
      },
    });
  const { data: level3ResourceOptions, isFetching: isLevel3ResourcesFetching } =
    useAllLevel3Resources({
      params: {
        parentId: details.level4WRS?.id,
      },
      enabled: Boolean(details.level4WRS?.id),
      onSuccess: (data: any) => {
        const { level3WRS } = details;
        if (level3WRS.id && level3WRS.name) {
          return;
        }

        const selected = data.find(
          (i: any) => i.label.toLowerCase() === "queensland"
        ) ?? {
          label: "",
          value: "",
        };
        handleChangeDetails("level3WRS", selected.label, "name");
        handleChangeDetails("level3WRS", selected.value, "id");
      },
      select: transformToOptions,
    });
  const { data: level2ResourceOptions, isFetching: isLevel2ResoucesFetching } =
    useAllLevel2Resources({
      params: {
        parentId: details.level3WRS?.id,
      },
      enabled: Boolean(details.level3WRS?.id),
      select: transformToOptions,
    });

  const accept = {
    "image/jpeg": [".jpeg", ".jpg"],
    "image/png": [".png"],
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => {
      const maxSize = 1024 * 1024 * 1024;
      const tooLargeFiles = files.filter((file) => file.size > maxSize);
      if (tooLargeFiles.length) {
        toastError(
          t("common.file_uploaded.file_too_large", { maxSize: "1GB" })
        );
        return;
      }
      setPhotoFiles(files);
    },
    onDropRejected: () => {
      toastError(t("common.file_uploaded.file_type_rejected"));
    },
    accept: accept,
    multiple: false,
  });

  const handleDelete = (index: number) => {
    setPhotoFiles((prevState) => {
      return prevState.filter((_, idx) => idx !== index);
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    stepHelpers.goToNextStep();
  };

  return (
    <form
      className="flex flex-col gap-6 p-6 grow justify-between"
      onSubmit={handleSubmit}
    >
      <div className="space-y-4">
        <Heading light>{t("level1wrs.create.details")}</Heading>

        <fieldset className="space-y-4">
          <div>
            <Label htmlFor="name">{t("level1wrs.create.name")}</Label>
            <TextInput
              className="max-w-md"
              id="name"
              value={details.name}
              onBlur={() => {
                validateName(details.name);
                validateIdentifier(details.identifier);
              }}
              onChange={(e) => handleChangeDetails("name", e.target.value)}
              required
            />
          </div>

          <div>
            <Label htmlFor="identifier">
              {t("level1wrs.create.identifier")}
            </Label>
            <TextInput
              className="max-w-md"
              id="identifier"
              value={details.identifier}
              onChange={(e) =>
                handleChangeDetails("identifier", e.target.value)
              }
              onBlur={() => validateIdentifier(details.identifier)}
              required
            />
          </div>

          <div>
            <Label htmlFor="description" optional>
              {t("level1wrs.create.description")}
            </Label>
            <TextArea
              className="max-w-md"
              id="description"
              value={details.description}
              onChange={(e) =>
                handleChangeDetails("description", e.target.value)
              }
            />
          </div>

          <div>
            <Label htmlFor="image" optional>
              {t("level1wrs.create.image")}
            </Label>
            <DropzoneBox
              title={t("level1wrs.create.image_drop_down.title") as string}
              subtitle={
                t("level1wrs.create.image_drop_down.subtitle") as string
              }
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              files={photoFiles}
              onDelete={handleDelete}
              acceptedFileTypes={accept}
            />
          </div>
        </fieldset>

        <fieldset>
          <Legend>{t("level1wrs.create.level2WRS_details")}</Legend>
          <div className="mt-4 space-y-4 max-w-md">
            <div>
              <Label>{t("level1wrs.create.select_level4WRS")}</Label>
              <Select
                options={level4ResourceOptions}
                onChange={(selected: any) => {
                  handleChangeDetails("level4WRS", selected.label, "name");
                  handleChangeDetails("level4WRS", selected.value, "id");
                }}
                value={level4ResourceOptions?.find(
                  (option: any) => option.value === details.level4WRS.id
                )}
                isLoading={isLevel4ResoucesFetching}
                required
              />
            </div>

            <div>
              <Label>{t("level1wrs.create.select_level3WRS")}</Label>
              <Select
                options={level3ResourceOptions}
                onChange={(selected: any) => {
                  handleChangeDetails("level3WRS", selected.label, "name");
                  handleChangeDetails("level3WRS", selected.value, "id");
                }}
                value={level3ResourceOptions?.find(
                  (option: any) => option.value === details.level3WRS.id
                )}
                isLoading={isLevel3ResourcesFetching}
                isDisabled={!Boolean(details.level4WRS.id)}
                required
              />
            </div>

            <div>
              <Label>{t("level1wrs.create.select_level2WRS")}</Label>
              <Select
                options={level2ResourceOptions}
                onChange={(selected: any) => {
                  handleChangeDetails("level2WRS", selected.label, "name");
                  handleChangeDetails("level2WRS", selected.value, "id");
                }}
                value={level2ResourceOptions?.find(
                  (option: any) => option.value === details.level2WRS.id
                )}
                isLoading={isLevel2ResoucesFetching}
                isDisabled={!Boolean(details.level3WRS.id)}
                required
              />
            </div>
          </div>
        </fieldset>

        <fieldset className="space-y-4">
          <Legend>{t("level1wrs.create.enter_year")}</Legend>

          <div>
            <Label htmlFor="year_start">
              {t("level1wrs.create.enter_year_start")}
            </Label>
            <TextInput
              id="year_start"
              type="date"
              className="max-w-md"
              value={
                details.periodStart ? formatDateInput(details.periodStart) : ""
              }
              onChange={(e) =>
                handleChangeDetails("periodStart", new Date(e.target.value))
              }
              required
            />
          </div>

          <div>
            <Label htmlFor="year_end">
              {t("level1wrs.create.enter_year_end")}
            </Label>
            <TextInput
              id="year_end"
              type="date"
              className="max-w-md"
              value={
                details.periodEnd ? formatDateInput(details.periodEnd) : ""
              }
              onChange={(e) =>
                handleChangeDetails("periodEnd", new Date(e.target.value))
              }
              min={
                details.periodStart
                  ? formatDateInput(new Date(details.periodStart))
                  : ""
              }
              required
            />
          </div>
        </fieldset>
      </div>

      <footer className="flex gap-4 -mx-6 mt-6 p-6 pb-0 border-t border-gray-200">
        <button
          type="submit"
          className="btn-primary"
          disabled={
            getInfoMessages("error", 0).length > 0 ||
            !Boolean(details.level2WRS.id)
          }
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={handleCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default Level1WRSDetails;

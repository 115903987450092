import { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Layout from "@components/layout/Layout";
import WorkflowPanels from "@components/form/WorkflowPanels";
import Level1WRSDashboard from "@components/form/level1_wrs/Level1WRSDashboard";
import Level1WRSMetersAndExtractionPoints from "@components/form/level1_wrs/Level1WRSMetersAndExtractionPoints";
import Level1WRSZoneAndClasses from "@components/form/level1_wrs/Level1WRSZoneAndClasses";
import Level1WRSBilling from "@components/form/level1_wrs/Level1WRSBilling";
import Level1WRSTradesAndApprovalsList from "@components/form/level1_wrs/Level1WRSTradesAndApprovalsList";
import Level1AuditTrailAndEvidence from "@components/form/level1_wrs/Level1AuditTrailAndEvidence";
import Level1WRSSubscribersAndUsers from "@components/form/level1_wrs/Level1WRSSubscribersAndUsers";
import ExchangeIcon from "@components/icons/ExchangeIcon";
import FaucetIcon from "@components/icons/FaucetIcon";
import FourDotsIcon from "@components/icons/FourDotsIcon";
import HammerIcon from "@components/icons/HammerIcon";
import UsersIcon from "@components/icons/UsersIcon";
import Loading from "@components/shared/Loading";
import ZoneIcon from "@components/icons/ZoneIcon";
import DollarSymbolIcon from "@components/icons/DollarSymbolIcon";
import ForwardIcon from "@components/icons/ForwardIcon";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";
import Level1WRSExtractionRights from "@components/form/level1_wrs/Level1WRSExtractionRights";
import ClockRotateIcon from "@components/icons/ClockRotateIcon";
import Level1WRSResetAccountingPeriod from "@components/form/level1_wrs/Level1WRSResetAccountingPeriod";

const Level1WRSIndex: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [currentMenu, setCurrentMenu] = useState(0);
  const { data: level1Resource, isLoading } = useGetLevel1Resource(id ?? "", {
    select: (data: any) => {
      if (data?.level0WRSMembers) {
        data.level0WRSMembers.sort((a: any, b: any) =>
          a.name.localeCompare(b.name)
        );
      }
      return data;
    },
  });

  let selectedPanel = 0;

  if (isLoading) {
    return (
      <div className="pt-80">
        <Loading />
      </div>
    );
  }

  return (
    <Layout
      permissions={["ViewLevel1Resource"]}
      breadcrumb={[
        {
          label: "Home",
          href: "/polestar",
        },
        {
          label: level1Resource?.name,
        },
      ]}
      title={level1Resource?.name}
      accountingPeriodLevel1ResourceId={level1Resource?.id}
    >
      <WorkflowPanels
        menuClassName="w-1/6 text-base min-w-min"
        contentClassName="border-none bg-transparent"
        activeItemClassName="border-r-4 border-r-primary-2 bg-blue-50 text-primary-3 font-semibold"
        inActiveItemClassName="text-primary-3 font-extralight opacity-70"
        selectedStep={currentMenu}
        keepSelectionOnRefresh={true}
        steps={[
          {
            label: (
              <button
                type="button"
                className="flex gap-3 items-center justify-start w-full text-left"
                onClick={() => setCurrentMenu(selectedPanel++)}
              >
                <FourDotsIcon />
                {t("level1wrs.dashboard")}
              </button>
            ),
            panel: <Level1WRSDashboard level1Resource={level1Resource} />,
          },
          {
            label: (
              <button
                type="button"
                className="flex gap-3 items-center justify-start w-full text-left"
                onClick={() => setCurrentMenu(0)}
              >
                <ZoneIcon />
                {t("level1wrs.zone_and_classes")}
              </button>
            ),
            panel: <Level1WRSZoneAndClasses level1Resource={level1Resource} />,
          },
          {
            label: (
              <button
                type="button"
                className="flex gap-3 items-center justify-start w-full text-left"
                onClick={() => setCurrentMenu(selectedPanel++)}
              >
                <UsersIcon />
                {t("level1wrs.subscribers_and_users")}
              </button>
            ),
            panel: (
              <Level1WRSSubscribersAndUsers level1Resource={level1Resource} />
            ),
          },
          {
            label: (
              <button
                type="button"
                className="flex gap-3 items-center justify-start w-full text-left"
                onClick={() => setCurrentMenu(selectedPanel++)}
              >
                <DollarSymbolIcon />
                {t("level1wrs.billing")}
              </button>
            ),
            panel: <Level1WRSBilling level1Resource={level1Resource} />,
          },
          {
            label: (
              <button
                type="button"
                className="flex gap-3 items-center justify-start w-full text-left"
                onClick={() => setCurrentMenu(selectedPanel++)}
              >
                <HammerIcon />
                {t("level1wrs.extraction_rights")}
              </button>
            ),
            panel: (
              <Level1WRSExtractionRights level1Resource={level1Resource} />
            ),
          },
          {
            label: (
              <button
                type="button"
                className="flex gap-3 items-center justify-start w-full text-left"
                onClick={() => setCurrentMenu(selectedPanel++)}
              >
                <FaucetIcon />
                {t("level1wrs.extraction_points")}
              </button>
            ),
            panel: (
              <Level1WRSMetersAndExtractionPoints
                level1Resource={level1Resource}
              />
            ),
          },
          {
            label: (
              <button
                type="button"
                className="flex gap-3 items-center justify-start w-full text-left"
                onClick={() => setCurrentMenu(selectedPanel++)}
              >
                <ExchangeIcon />
                {t("level1wrs.approval")}
              </button>
            ),
            panel: (
              <Level1WRSTradesAndApprovalsList
                level1Resource={level1Resource}
              />
            ),
          },
          {
            label: (
              <button
                type="button"
                className="flex gap-3 items-center justify-start w-full text-left"
                onClick={() => setCurrentMenu(selectedPanel++)}
              >
                <ForwardIcon />
                {t("common.audit_trail_and_evidence")}
              </button>
            ),
            panel: (
              <Level1AuditTrailAndEvidence level1Resource={level1Resource} />
            ),
          },
          {
            label: (
              <button
                type="button"
                className="flex gap-3 items-center justify-start w-full text-left"
                onClick={() => setCurrentMenu(selectedPanel++)}
              >
                <ClockRotateIcon />
                {t("common.accounting_period")}
              </button>
            ),
            panel: (
              <Level1WRSResetAccountingPeriod level1Resource={level1Resource} />
            ),
          },
        ]}
      />
    </Layout>
  );
};

export default Level1WRSIndex;

import React from "react";

type ModalInformationIconProps = {
  className?: string;
};

const ModalInformationIcon: React.FunctionComponent<
  ModalInformationIconProps
> = ({ className }) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M17 2.125C8.78555 2.125 2.125 8.78555 2.125 17C2.125 25.2145 8.78555 31.875 17 31.875C25.2145 31.875 31.875 25.2145 31.875 17C31.875 8.78555 25.2145 2.125 17 2.125ZM17 29.3516C10.1801 29.3516 4.64844 23.8199 4.64844 17C4.64844 10.1801 10.1801 4.64844 17 4.64844C23.8199 4.64844 29.3516 10.1801 29.3516 17C29.3516 23.8199 23.8199 29.3516 17 29.3516Z"
        fill="#1890FF"
      />
      <path
        opacity="0.1"
        d="M17 4.64844C10.1801 4.64844 4.64844 10.1801 4.64844 17C4.64844 23.8199 10.1801 29.3516 17 29.3516C23.8199 29.3516 29.3516 23.8199 29.3516 17C29.3516 10.1801 23.8199 4.64844 17 4.64844ZM15.9375 9.82812C15.9375 9.68203 16.057 9.5625 16.2031 9.5625H17.7969C17.943 9.5625 18.0625 9.68203 18.0625 9.82812V18.8594C18.0625 19.0055 17.943 19.125 17.7969 19.125H16.2031C16.057 19.125 15.9375 19.0055 15.9375 18.8594V9.82812ZM17 24.4375C16.5829 24.429 16.1858 24.2573 15.8939 23.9594C15.602 23.6614 15.4385 23.2609 15.4385 22.8438C15.4385 22.4266 15.602 22.0261 15.8939 21.7281C16.1858 21.4302 16.5829 21.2585 17 21.25C17.4171 21.2585 17.8142 21.4302 18.1061 21.7281C18.398 22.0261 18.5615 22.4266 18.5615 22.8438C18.5615 23.2609 18.398 23.6614 18.1061 23.9594C17.8142 24.2573 17.4171 24.429 17 24.4375Z"
        fill="#1890FF"
      />
      <path
        d="M16.2031 19.125H17.7969C17.943 19.125 18.0625 19.0055 18.0625 18.8594V9.82812C18.0625 9.68203 17.943 9.5625 17.7969 9.5625H16.2031C16.057 9.5625 15.9375 9.68203 15.9375 9.82812V18.8594C15.9375 19.0055 16.057 19.125 16.2031 19.125ZM15.4062 22.8438C15.4063 23.2664 15.5742 23.6718 15.873 23.9707C16.1719 24.2696 16.5773 24.4375 17 24.4375C17.4227 24.4375 17.8281 24.2696 18.127 23.9707C18.4258 23.6718 18.5938 23.2664 18.5938 22.8438C18.5938 22.4211 18.4258 22.0157 18.127 21.7168C17.8281 21.4179 17.4227 21.25 17 21.25C16.5773 21.25 16.1719 21.4179 15.873 21.7168C15.5742 22.0157 15.4063 22.4211 15.4062 22.8438Z"
        fill="#1890FF"
      />
    </svg>
  );
};

export default ModalInformationIcon;

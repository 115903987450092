import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Level0WRSDetails from "./Level0WRSDetails";
import Level0WRSBoundary from "./Level0WRSBoundary";
import Level0WRSAccountingPeriodVolume from "./Level0WRSAccountingPeriodVolume";
import Level0WRSDistributionLoss from "./Level0WRSDistributionLoss";
import Level0WRSTradingLinks from "./Level0WRSTradingLinks";
import Level0WRSAdministration from "./Level0WRSAdministration";
import Level0WRSLedger from "./Level0WRSLedger";
import Level0WRSWorkflowCompleted from "./Level0WRSWorkflowCompleted";
import InfoPanel from "@components/form/InfoPanel";
import WorkflowPanels from "@components/form/WorkflowPanels";
import BoundarySampleInformation from "@components/shared/BoundarySampleInformation";
import BoundarySelected from "@components/shared/BoundarySelected";
import UploadEvidencesWorkflowForm from "@components/form/UploadEvidenceWorkflowForm";
import StepButton from "@components/shared/StepButton";
import StepInfo from "@components/shared/StepInfo";
import InformationCircleIcon from "@components/icons/InformationCircleIcon";
import { useAppContext } from "@context/AppContext";
import {
  Level0WRSProvider,
  useLevel0WRSContext,
} from "@context/Level0WRSContext";
import {
  EvidenceProvider,
  useEvidenceContext,
} from "@context/shared/EvidenceContext";
import { WaterClassProvider } from "@context/WaterClassContext";
import { useAllExtractionRights } from "@hooks/query/useAllExtractionRights";
import { extractionRightTypes } from "@services/extractionRight";
import { toastSuccess } from "@utils/toast";

const UpdateLevel0WRSWorkflow = () => {
  return (
    <EvidenceProvider>
      <Level0WRSProvider>
        <WaterClassProvider>
          <Consumer />
        </WaterClassProvider>
      </Level0WRSProvider>
    </EvidenceProvider>
  );
};

const Consumer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { checkPermissions } = useAppContext();
  const {
    currentStep,
    stepHelpers,
    workflowCompleted,
    getDetails,
    getInfoMessages,
    details,
    setLocationCoordinates,
    getAccountingPeriodAndVolume,
    getTradingLinks,
    getAdministrationInfo,
    networkErrors,
    navigateForCancel,
    setSpecialAction,
    handleChangeDetails,
  } = useLevel0WRSContext();
  const { getEvidencesInfo } = useEvidenceContext();
  const { refetch: getZoneDistributionLoss } = useAllExtractionRights({
    params: {
      level0ResourceId: details?.id,
      types: [extractionRightTypes.DL],
    },
    options: {
      enabled: false,
      onSuccess: (data: any[]) => {
        if (data.length > 0) {
          const extractionRight = data[0];
          handleChangeDetails("distributionLoss", {
            ...details?.distributionLoss,
            extractionRightId: extractionRight.id,
            extractionRightName: extractionRight.name,
            volume: +extractionRight.volume / 1000000.0,
            percentage:
              +extractionRight.waterClass?.activeExchangeRate?.rate * 100,
          });
        }
      },
    },
  });

  useEffect(() => {
    if (details.id) {
      getZoneDistributionLoss();
    }
  }, [details.id, getZoneDistributionLoss]);

  let panelIndex = 0;

  const steps = [
    {
      label: (
        <StepButton
          index={panelIndex++}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
          info={<StepInfo data={getDetails().body} />}
        >
          {t("level0wrs.create.enter_details")}
        </StepButton>
      ),
      panel: <Level0WRSDetails />,
      infoPanel: (
        <InfoPanel
          successes={getInfoMessages("success", 0)}
          errors={getInfoMessages("error", 0)}
          warnings={getInfoMessages("warning", 0)}
        >
          <div className="space-y-2">
            <p>{t("level0wrs.update.step_1.info_intro")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={panelIndex++}
          currentStep={currentStep}
          info={<BoundarySelected selected={details.geography !== ""} />}
          isOnChain={workflowCompleted}
        >
          {t("level0wrs.create.enter_boundary")}
        </StepButton>
      ),
      panel: <Level0WRSBoundary />,
      infoPanel: (
        <InfoPanel
          warnings={[t("information.location_feature_info_warning")]}
          actions={[
            {
              label: t("level0wrs.create.info_panel.step_2.action_1"),
              action: () => {
                setLocationCoordinates({ lat: "", lng: "" });
                toastSuccess(
                  t("level0wrs.create.info_panel.step_2.action_1_toast")
                );
              },
            },
          ]}
        >
          <div className="space-y-2">
            <p>{t("level0wrs.create.info_panel.step_2.info_intro")}</p>
            <BoundarySampleInformation />
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={panelIndex++}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
          info={<StepInfo data={getAccountingPeriodAndVolume().body} />}
        >
          {t("level0wrs.create.year_and_volume")}
        </StepButton>
      ),
      panel: <Level0WRSAccountingPeriodVolume />,
      infoPanel: (
        <InfoPanel
          successes={getInfoMessages("success", 2)}
          errors={getInfoMessages("error", 2)}
          warnings={getInfoMessages("warning", 2)}
          actions={[
            ...(checkPermissions(["UpdateAccountingPeriod"])
              ? [
                  {
                    label: t("level0wrs.update.step_3.action_1"),
                    action: () => {
                      setSpecialAction("modifySchemeYear");
                    },
                  },
                ]
              : []),
            {
              label: (
                <Trans
                  i18nKey="water_class.add_level0wrs_water_class"
                  values={{
                    level0wrsIdentifier: details.identifier,
                  }}
                />
              ),
              action: () => {
                window.open(
                  `/polestar/level1wrs/${details.level1WRS?.id}/water_classes/create?level0ResourceId=${details.id}&level0ResourceIdentifier=${details.identifier}`,
                  "_blank"
                );
              },
            },
          ]}
        >
          <ul className="pl-2 space-y-3">
            <li>
              <Trans i18nKey={"level0wrs.update.step_3.water_year_info"} />
              <div className="flex gap-2 pt-2">
                <InformationCircleIcon className="text-blue-600 w-5 h-5 shrink-0" />
                {t("level0wrs.update.step_3.warning_1")}
              </div>
            </li>
            <li>
              <Trans i18nKey={"level0wrs.update.step_3.volume_info"} />
              <div className="flex gap-2 pt-2">
                <InformationCircleIcon className="text-blue-600 w-5 h-5 shrink-0" />
                <div>
                  {t("level0wrs.create.info_panel.step_3.info_of_zone_volume")}
                  <ul className="leading-relaxed">
                    <li>
                      {t(
                        "level0wrs.create.info_panel.step_3.info_of_zone_volume_a"
                      )}
                    </li>
                    <li>
                      {t(
                        "level0wrs.create.info_panel.step_3.info_of_zone_volume_b"
                      )}
                    </li>
                    <li>
                      {t(
                        "level0wrs.create.info_panel.step_3.info_of_zone_volume_c"
                      )}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-2 pt-2">
                <InformationCircleIcon className="text-blue-600 w-5 h-5 shrink-0" />
                {t("level0wrs.update.step_3.warning_3")}
              </div>
              <div className="flex gap-2 pt-2">
                <InformationCircleIcon className="text-blue-600 w-5 h-5 shrink-0" />
                {t("level0wrs.update.step_3.warning_4")}
              </div>
              <div className="flex gap-2 pt-2">
                <InformationCircleIcon className="text-blue-600 w-5 h-5 shrink-0" />
                {t("level0wrs.update.step_3.info_body_1")}
              </div>
              <div className="flex gap-2 pt-2">
                <InformationCircleIcon className="text-blue-600 w-5 h-5 shrink-0" />
                {t("level0wrs.update.step_3.info_body_2")}
              </div>
            </li>
          </ul>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={panelIndex++}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
        >
          {t("level0wrs.create.distribution_loss")}
        </StepButton>
      ),
      panel: <Level0WRSDistributionLoss />,
      infoPanel: (
        <InfoPanel
          warnings={[t("information.distribution_loss_feature_info_warning")]}
        >
          <div className="space-y-2">
            <p>{t("level0wrs.create.info_panel.distribution_loss.info_1")}</p>
            <p>{t("level0wrs.create.info_panel.distribution_loss.info_2")}</p>
            <p>{t("level0wrs.create.info_panel.distribution_loss.info_3")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={panelIndex++}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
          info={<StepInfo data={getTradingLinks().body} />}
        >
          {t("level0wrs.create.trading_links")}
        </StepButton>
      ),
      panel: <Level0WRSTradingLinks />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-2">
            <p>{t("level0wrs.create.info_panel.step_4.info_intro")}</p>
            <ul className="pl-2 space-y-2">
              <li>
                <strong>
                  {t("level0wrs.create.inside_zone", {
                    identifier: details.identifier,
                  })}
                  :
                </strong>{" "}
                {t("level0wrs.create.info_panel.step_4.specify_trading")}
              </li>
              <li>
                <strong>{t("level0wrs.create.between_zones")}:</strong>{" "}
                {t(
                  "level0wrs.create.info_panel.step_4.specify_inbound_outbound"
                )}
              </li>
            </ul>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={panelIndex++}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
          info={<StepInfo data={getAdministrationInfo().body} />}
        >
          {t("level0wrs.create.administration")}
        </StepButton>
      ),
      panel: <Level0WRSAdministration />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-2">
            <p>{t("level0wrs.create.info_panel.step_5.info_intro")}</p>
            <div className="flex gap-4">
              <span>
                <InformationCircleIcon className="text-blue-600 w-5 h-5"></InformationCircleIcon>
              </span>
              {t("level0wrs.create.info_panel.step_5.info_example")}
            </div>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={panelIndex++}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
          info={<StepInfo data={getEvidencesInfo().body} />}
        >
          {t("evidence.upload_menu_title")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          referenceTable="level0_wrs"
          referenceTableId={details.id}
          onNext={(activatedEvidenceIds) => {
            handleChangeDetails(
              "activatedEvidenceIds",
              activatedEvidenceIds.map((v: any) => v)
            );
            stepHelpers.goToNextStep();
          }}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={() => navigateForCancel()}
          activatedEvidences={details.activatedEvidenceIds}
          customDropboxTitle={t("level0wrs.create.step_6.title") as string}
          newUploadDescription={
            t("level0wrs.create.step_6.description", {
              level0wrsName: details.name,
            }) as string
          }
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>{t("level0wrs.create.step_6.info_intro")}</p>
            <p>{t("level0wrs.create.step_6.info_conclusion")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={panelIndex++}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
          forceSuccess={workflowCompleted}
        >
          {t("level0wrs.create.ledger")}
        </StepButton>
      ),
      panel: workflowCompleted ? (
        <Level0WRSWorkflowCompleted />
      ) : (
        <Level0WRSLedger />
      ),
      infoPanel: workflowCompleted ? (
        <InfoPanel
          successes={[
            t("level0wrs.create.info_panel.workflow_completed.success", {
              name: details.name,
            }),
          ]}
          actions={[
            {
              label: t(
                "level0wrs.create.info_panel.workflow_completed.action_1"
              ),
              action: () => {
                window.location.reload();
              },
            },
            {
              label: (
                <Trans
                  i18nKey="water_class.add_level0wrs_water_class"
                  values={{
                    level0wrsIdentifier: details?.identifier,
                  }}
                />
              ),
              action: () => {
                navigate(
                  `/polestar/level1wrs/${details.level1WRS?.id}/water_classes/create?level0ResourceId=${details?.id}`
                );
              },
            },
          ]}
        >
          <p className="inline">
            {t("level0wrs.create.info_panel.workflow_completed.info_intro")}
          </p>{" "}
        </InfoPanel>
      ) : (
        <InfoPanel
          successes={[t("level0wrs.create.info_panel.step_6.default_success")]}
          errors={networkErrors}
        >
          <div className="space-y-2">
            <p>{t("level0wrs.create.info_panel.step_6.info_intro")}</p>
            <ul className="pl-2 space-y-2">
              <li>
                <strong>{t("level0wrs.create.details")}:</strong>{" "}
                {t("level0wrs.create.info_panel.step_6.details_info")}
              </li>
              <li>
                <strong>{t("level0wrs.create.boundary")}:</strong>{" "}
                {t("level0wrs.create.info_panel.step_6.boundary_info")}
              </li>
              <li>
                <strong>{t("level0wrs.create.year_and_volume")}:</strong>{" "}
                {t("level0wrs.create.info_panel.step_6.year_volume_info")}
              </li>
              <li>
                <strong>{t("level0wrs.create.trading_links")}:</strong>{" "}
                {t("level0wrs.create.info_panel.step_6.trading_links_info")}
              </li>
              <li>
                <strong>{t("level0wrs.create.administration")}:</strong>{" "}
                {t("level0wrs.create.info_panel.step_6.administration_info")}
              </li>
              <li>
                <Trans i18nKey="information.edit_info_evidence" />
              </li>
            </ul>
            <p>{t("level1wrs.create.info_panel.step_3.info_conc")}</p>
          </div>
        </InfoPanel>
      ),
    },
  ];

  return (
    <>
      <WorkflowPanels selectedStep={currentStep} steps={steps} />
    </>
  );
};

export default UpdateLevel0WRSWorkflow;

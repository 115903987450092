import { useState } from "react";
import SelectMapLocation from "./SelectMapLocation";
import SearchLocationInput from "./SearchLocationInput";
import CoordinatesInput from "./CoordinatesInput";
import Label from "@components/form/Label";
import { useTranslation } from "react-i18next";

export enum MapInputOptions {
  Null,
  Coordinates,
  Address,
  Map,
}

type LocationInputsProps = {
  locationCoordinates: { lat: string; lng: string };
  setLocationCoordinates: (location: { lat: string; lng: string }) => void;
  predefinedInputOption?: MapInputOptions;
};

const LocationInputs: React.FunctionComponent<LocationInputsProps> = ({
  locationCoordinates,
  setLocationCoordinates,
  predefinedInputOption = MapInputOptions.Null,
}) => {
  const { t } = useTranslation();
  const [inputOption, setInputOption] = useState<MapInputOptions>(
    predefinedInputOption
  );

  return (
    <div>
      <Label className="mb-2" optional>
        {t("location.geography_form.how_enter_geography")}
      </Label>
      <div className="flex mb-2">
        <label className="mx-2">
          <input
            type="radio"
            name="geography-input"
            value="coordinates"
            onChange={() => {
              setInputOption(MapInputOptions.Coordinates);
            }}
            checked={inputOption === MapInputOptions.Coordinates}
          />{" "}
          {t("location.geography_form.enter_coordinates")}
        </label>
        <label className="mx-2">
          <input
            type="radio"
            name="geography-input"
            value="address"
            onChange={() => {
              setInputOption(MapInputOptions.Address);
            }}
            checked={inputOption === MapInputOptions.Address}
          />{" "}
          {t("location.geography_form.enter_address")}
        </label>
        <label className="mx-2">
          <input
            type="radio"
            name="geography-input"
            value="map"
            onChange={() => {
              setInputOption(MapInputOptions.Map);
            }}
            checked={inputOption === MapInputOptions.Map}
          />{" "}
          {t("location.geography_form.use_map")}
        </label>
      </div>
      {inputOption === MapInputOptions.Coordinates && (
        <CoordinatesInput
          locationCoordinates={locationCoordinates}
          setLocationCoordinates={setLocationCoordinates}
        />
      )}
      {inputOption === MapInputOptions.Address && (
        <div>
          {" "}
          {
            <SearchLocationInput
              locationCoordinates={locationCoordinates}
              setLocationCoordinates={setLocationCoordinates}
            />
          }
        </div>
      )}
      {inputOption === MapInputOptions.Map && (
        <SelectMapLocation
          locationCoordinates={locationCoordinates}
          setLocationCoordinates={setLocationCoordinates}
        />
      )}
    </div>
  );
};

export default LocationInputs;

import React from "react";

const ExchangeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <g clipPath="url(#clip0_1586_43473)">
        <path
          fill="#004A88"
          d="M1.063 3.688h10.625V1.563a1.066 1.066 0 011.816-.754l3.188 3.187a1.06 1.06 0 010 1.501l-3.188 3.188c-.305.305-.76.395-1.159.229a1.063 1.063 0 01-.657-.983V5.813H1.063a1.061 1.061 0 110-2.125zm14.875 8.5a1.061 1.061 0 110 2.124H5.313v2.126a1.066 1.066 0 01-1.816.753L.309 14.004a1.06 1.06 0 010-1.501l3.188-3.188a1.063 1.063 0 011.816.754v2.125h10.625v-.007z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1586_43473">
          <path
            fill="#fff"
            d="M0 0H17V17H0z"
            transform="translate(0 .5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default ExchangeIcon;

import api from "./api";

export const getWorkflowInstance = async (
  id: string,
  params: Record<string, any> = {},
) => {
  const { data } = await api.get(`/admin/workflowInstances/${id}`, {
    params,
  });

  return data.workflowInstance;
};

export const getAllWorkflowgroups = async (): Promise<any[]> => {
  const { data } = await api.get("/workflowGroups");

  return data.workflowGroups;
};

export const toggleFavoriteWorkflow = async (
  workflowId: string,
): Promise<any> => {
  const { data } = await api.post(`/workflows/${workflowId}/toggleFavorite`);

  return data.workflow;
};

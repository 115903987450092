import React from "react";
import { Trans, useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import { formatVolume } from "@utils/formatVolume";
import { sortBy } from "lodash";
import SubHeading from "@components/layout/SubHeading";

type WaterClassInfoTableProps = {
  data: any;
  totalVolume: number;
  level0wrsName?: string;
};

const WaterClassInfoTable: React.FunctionComponent<
  WaterClassInfoTableProps
> = ({ data, totalVolume, level0wrsName }) => {
  const { t } = useTranslation();
  const tableFields = [
    {
      title: t("water_class.priority"),
      name: "acctPriority",
    },
    {
      title: t("common.water_class"),
      name: "name",
    },
    {
      title: t("common.volume"),
      name: "volume",
    },
  ];

  const tableData = data.map((item: any) => {
    return {
      id: item.id,
      name: item.name,
      volume: formatVolume(+item.volume),
      acctPriority: item.acctPriority,
    };
  });
  const sortByPriority = sortBy(tableData, (i: any) => i.acctPriority);

  return (
    <>
      <SubHeading>
        <Trans
          i18nKey="water_class.info.currently_defined"
          context={level0wrsName ? "level0wrs_name" : undefined}
          values={{ level0wrsName }}
        />
      </SubHeading>
      <Table
        fields={tableFields}
        data={sortByPriority}
        totals={[
          {},
          {
            value: t("water_class.info.total_volume"),
          },
          {
            value: totalVolume ? formatVolume(totalVolume) : "0",
          },
        ]}
      />

      {data.length ? (
        <p>
          <strong>{t("water_class.info.total_water_classes")}:</strong>{" "}
          {data.length}
        </p>
      ) : null}
    </>
  );
};

export default WaterClassInfoTable;

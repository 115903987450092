import WorkflowSteps from "@components/form/WorkflowSteps";
import WorkflowCreateForwardDrawCompleted from "@components/form/workflows/create_forward_draw/WorkflowCreateForwardDrawCompleted";
import WorkflowCreateForwardDrawStep1 from "@components/form/workflows/create_forward_draw/WorkflowCreateForwardDrawStep1";
import WorkflowCreateForwardDrawStep2 from "@components/form/workflows/create_forward_draw/WorkflowCreateForwardDrawStep2";
import WorkflowCreateForwardDrawStep3 from "@components/form/workflows/create_forward_draw/WorkflowCreateForwardDrawStep3";
import { useAppContext } from "@context/AppContext";
import {
  ForwardDrawProvider,
  useForwardDrawContext,
} from "@context/ForwardDrawContext";
import { EvidenceProvider } from "@context/shared/EvidenceContext";
import { useMessageTemplates } from "@hooks/query/useMessageTemplates";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const WorkflowCreateForwardDraw: FunctionComponent = () => {
  return (
    <ForwardDrawProvider>
      <EvidenceProvider>
        <MainComponent />
      </EvidenceProvider>
    </ForwardDrawProvider>
  );
};

const MainComponent: FunctionComponent = () => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const {
    loggedInInfo: { userDetails },
  } = useAppContext();
  const { setDetails, setMessage } = useForwardDrawContext();

  useMessageTemplates({
    enabled: !!userDetails,
    onSuccess: (data: any[]) => {
      const messageTemplate = data.find(
        (t) => t.identifier === "ForwardDrawRequest"
      );

      if (messageTemplate) {
        setMessage({
          messageTemplate,
          subject: t(messageTemplate.subjectTextTemplate),
          body: t(messageTemplate.bodyTextTemplate, {
            recipientName: t("common.administrators"),
            senderName: userDetails?.name,
            subscriberAccountNumber: userDetails?.subscriber?.accountNumber,
          }),
        });
      }
    },
  });

  useEffect(() => {
    if (userDetails)
      setDetails({
        subscribers: [userDetails.subscriber],
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  return (
    <WorkflowSteps
      title={t("zenith.workflows.create_forward_draw")}
      selectedStep={currentStep}
      onStepButtonClick={(step) => setCurrentStep(step)}
      disableNavigation={currentStep === 3}
      steps={[
        {
          panel: (
            <WorkflowCreateForwardDrawStep1
              onSubmit={() => setCurrentStep(1)}
            />
          ),
        },
        {
          panel: (
            <WorkflowCreateForwardDrawStep2
              onCancel={() => setCurrentStep(0)}
              onSubmit={() => setCurrentStep(2)}
            />
          ),
        },
        {
          panel: (
            <WorkflowCreateForwardDrawStep3
              onCancel={() => setCurrentStep(1)}
              onSubmit={() => setCurrentStep(3)}
            />
          ),
        },
        {
          panel: <WorkflowCreateForwardDrawCompleted />,
        },
      ]}
    />
  );
};

export default WorkflowCreateForwardDraw;

import Heading from "@components/layout/Heading";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import SelectSingleLevel1WRSTable from "@components/table/SelectSingleLevel1WRSTable";
import { useBalanceAdjustmentContext } from "@context/BalanceAdjustmentContext";

type CreateBalanceAdjustmentSelectSchemeProps = {
  onSave: () => void;
  onGoBack: () => void;
};

const CreateBalanceAdjustmentSelectScheme: FunctionComponent<
  CreateBalanceAdjustmentSelectSchemeProps
> = ({ onSave }) => {
  const { t } = useTranslation();
  const { balanceAdjustmentData, setBalanceAdjustmentData, navigateForCancel } =
    useBalanceAdjustmentContext();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSave();
  };

  return (
    <form className="pb-4 flex flex-col h-full" onSubmit={handleSubmit}>
      <div className="p-6 grow flex flex-col">
        <div className="space-y-4">
          <Heading light>{t("balance_adjustment.select_level1wrs")}</Heading>
        </div>
        <SelectSingleLevel1WRSTable
          onSelect={(item: any) => {
            setBalanceAdjustmentData({
              ...balanceAdjustmentData,
              level1Resource: item,
            });
          }}
          selected={balanceAdjustmentData.level1Resource}
        />
      </div>

      <footer className="flex gap-4 -mx-2 mt-2 p-6 pb-0 border-t border-gray-200">
        <button
          type="submit"
          className="btn-primary"
          disabled={!balanceAdjustmentData?.level1Resource?.id}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default CreateBalanceAdjustmentSelectScheme;

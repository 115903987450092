import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import ExternalLinkIcon from "@components/icons/ExternalLinkIcon";
import Table from "@components/layout/Table";
import Tag from "@components/shared/Tag";
import { useAllSubscriberExtractionPoints } from "@hooks/query/zenith/useAllExtractionPoints";

type ExtractionPoint = Record<string, any>;

type SelectSingleExtractionPointTableForZenithProps = {
  definedByWalletId?: string;
  subscriberId?: string;
  onSelect: (item: ExtractionPoint) => void;
  selected?: ExtractionPoint;
};

const SelectSingleExtractionPointForZenith: React.FunctionComponent<
  SelectSingleExtractionPointTableForZenithProps
> = ({ definedByWalletId, onSelect, selected, subscriberId }) => {
  const { t } = useTranslation();
  const { data: extractionPoints, isLoading } =
    useAllSubscriberExtractionPoints({
      params: { definedByWalletId },
    });

  const tableFields = [
    {
      title: "",
      name: "radio",
    },
    {
      title: t("extraction_point.name"),
      name: "name",
    },
    {
      title: t("common.level0wrs"),
      name: "level0wrs",
    },
    {
      title: t("common.status"),
      name: "status",
    },
    {
      title: "",
      name: "view",
    },
  ];

  const tableData = extractionPoints?.map((extractionPoint: any) => {
    return {
      id: extractionPoint.id,
      radio: (
        <input
          type="radio"
          name="level1wrs_id"
          checked={selected?.id === extractionPoint?.id}
          onChange={() => onSelect(extractionPoint)}
        />
      ),
      name: extractionPoint?.name,
      level0wrs: extractionPoint?.level0WRS?.identifier,
      status: extractionPoint?.isActive ? (
        <Tag status="success">{t("common.active")}</Tag>
      ) : (
        <Tag status="error">{t("common.inactive")}</Tag>
      ),
      view: (
        <Link
          to={`/polestar/subscribers/${subscriberId}?level0ResourceId=${extractionPoint?.level0WRS?.id}&extractionPointId=${extractionPoint?.id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="btn-default flex items-center gap-3 w-min"
        >
          <ExternalLinkIcon className="w-4 h-4" />
          {t("common.view")}
        </Link>
      ),
    };
  });

  return (
    <>
      <div className="pb-0 flex flex-col gap-4 grow">
        <Table
          fields={tableFields}
          data={tableData}
          stickyHeader
          loading={isLoading}
          noRowsText={t("subscriber.has_no_extraction_points")}
        />
      </div>
    </>
  );
};

export default SelectSingleExtractionPointForZenith;

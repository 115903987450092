import { useQuery } from "@tanstack/react-query";

import { getSubscriberLevel0Resource } from "@services/subscriber";

export const useSubscriberLevel0Resource = ({
  params,
  ...options
}: {
  params: {
    subscriberId: string;
    level0ResourceId: string;
  };
  [key: string]: any;
}) => {
  return useQuery<any>({
    queryKey: ["subscriberLevel0Resources", params],
    queryFn: () => getSubscriberLevel0Resource(params),
    enabled: Boolean(params.subscriberId) && Boolean(params.level0ResourceId),
    ...options,
  });
};

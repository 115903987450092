import { useNavigate } from "react-router-dom";
import { noop } from "lodash";
import { t } from "i18next";

import { useStepContext } from "@context/shared/StepContext";

type BaseSignUpFormProps = any;

const BaseSignUpForm: React.FunctionComponent<BaseSignUpFormProps> = ({
  onSubmit = noop,
  title,
  children,
  step,
  submitText = t("common.next_step"),
  isSubmitting = false,
}: any) => {
  const navigate = useNavigate();
  const { currentStep, stepHelpers } = useStepContext();

  if (currentStep !== step) {
    return null;
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <p className="font-semibold text-primary-2">{title}</p>

      {children}

      <p className="font-semibold text-primary-2">
        Step {currentStep + 1} of 5
      </p>

      <div className="flex gap-3 items-center justify-center">
        {stepHelpers.canGoToPrevStep ? (
          <button
            type="button"
            className="btn-default"
            onClick={() => stepHelpers.goToPrevStep()}
          >
            {t("common.prev_step")}
          </button>
        ) : null}

        <button type="submit" className="btn-primary" disabled={isSubmitting}>
          {submitText}
        </button>

        <button
          type="button"
          className="btn-default"
          onClick={() => {
            let confirmed = true;
            if (currentStep > 0) {
              confirmed = window.confirm(
                t("zenith.signup.confirm_cancel") as string
              );
            }

            if (confirmed) {
              navigate("/zenith/login");
            }
          }}
        >
          {t("common.cancel")}
        </button>
      </div>
    </form>
  );
};

export default BaseSignUpForm;

import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { t } from "i18next";
import { isValidPhoneNumber } from "react-phone-number-input";

import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import PhoneInput from "@components/form/PhoneInput";

import BaseSignUpForm from "./BaseSignUpForm";

const schema = z
  .object({
    name: z.string().min(1, t("common.required.text") as string),
    email: z
      .string()
      .min(1, t("common.required.text") as string)
      .email(),
    tel: z
      .string()
      .min(1, t("common.required.text") as string)
      .refine(isValidPhoneNumber, {
        message: t("subscriber.create.invalid_phone_number") as string,
      }),
    password: z
      .string()
      .min(1, t("common.required.text") as string)
      .min(
        8,
        t("zenith.signup.validation.password.min", { length: 8 }) as string
      )
      .regex(
        /[A-Z]/,
        t("zenith.signup.validation.password.uppercase") as string
      )
      .regex(
        /[a-z]/,
        t("zenith.signup.validation.password.lowercase") as string
      )
      .regex(/\d/, t("zenith.signup.validation.password.numerical") as string)
      .regex(
        /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
        t("zenith.signup.validation.password.special_chars") as string
      ),
    confirmPassword: z.string().min(1, t("common.required.text") as string),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: t("zenith.signup.validation.password.not_match") as string,
    path: ["confirmPassword"],
  });

type FormValues = z.infer<typeof schema>;

const defaultValues: FormValues = {
  name: "",
  email: "",
  tel: "",
  password: "",
  confirmPassword: "",
};

type SignUpUserFormProps = {
  onSubmit: (values: FormValues) => void;
  values: FormValues;
};

const SignUpUserForm: React.FunctionComponent<SignUpUserFormProps> = ({
  onSubmit,
  values,
}) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: Object.assign({}, defaultValues, values),
  });

  return (
    <BaseSignUpForm
      title={t("zenith.signup.enter_address")}
      step={2}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    >
      <div>
        <Label htmlFor="name">{t("user.username")}</Label>
        <TextInput
          {...register("name")}
          id="name"
          errorMessage={errors?.name?.message as any}
        />
      </div>
      <div>
        <Label htmlFor="email">{t("user.email")}</Label>
        <TextInput
          {...register("email")}
          type="email"
          id="email"
          errorMessage={errors?.email?.message as any}
        />
      </div>
      <div>
        <Label htmlFor="tel">{t("user.tel")}</Label>
        <Controller
          name="tel"
          control={control}
          render={({ field: { onChange, value, name }, fieldState }) => (
            <PhoneInput
              id="tel"
              name={name}
              onChange={onChange}
              value={value}
              errorMessage={fieldState.error?.message}
            />
          )}
        />
      </div>
      <div>
        <Label htmlFor="password">{t("user.password")}</Label>
        <TextInput
          {...register("password")}
          type="password"
          id="password"
          errorMessage={errors?.password?.message as any}
        />
      </div>
      <div>
        <Label htmlFor="confirmPassword">
          {t("zenith.signup.confirm_password")}
        </Label>
        <TextInput
          {...register("confirmPassword")}
          type="password"
          id="confirmPassword"
          errorMessage={errors?.confirmPassword?.message as any}
        />
      </div>
    </BaseSignUpForm>
  );
};

export default SignUpUserForm;

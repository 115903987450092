import classNames from "classnames";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

const CustomChartTooltip: React.FunctionComponent<any> = (props) => {
  const { active, payload } = props;
  const { t } = useTranslation();

  if (active && payload?.length) {
    const { name, value, changeType, changePercentage } = payload[0]?.payload;

    return (
      <div className="flex gap-4 px-6 py-4 rounded-lg bg-black/70 text-white shadow backdrop-blur-md">
        <span className="text-lg font-bold">{`${name} : ${value.toLocaleString()} ${t(
          "common.volume_unit"
        )}`}</span>

        <ChangePercentage type={changeType}>
          {changePercentage}%
        </ChangePercentage>
      </div>
    );
  }

  return null;
};

const colorScheme = {
  increase: {
    text: "text-green-800",
    background: "bg-green-100",
  },
  decrease: {
    text: "text-red-800",
    background: "bg-red-100",
  },
  equal: {
    text: "text-gray-800",
    background: "bg-gray-100",
  },
};

const ChangePercentage = ({
  type,
  children,
}: {
  type: keyof typeof colorScheme;
  children: React.ReactNode;
}) => {
  const isIncrease = type === "increase";
  const isDecrease = type === "decrease";
  const color = colorScheme[type] ?? colorScheme.equal;

  return (
    <div
      className={classNames(
        color.text,
        color.background,
        "inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0"
      )}
    >
      {isIncrease ? (
        <ArrowUpIcon
          className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
          aria-hidden="true"
        />
      ) : null}

      {isDecrease ? (
        <ArrowDownIcon
          className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
          aria-hidden="true"
        />
      ) : null}

      <span className="sr-only">
        {isIncrease ? "Increased" : isDecrease ? "Decreased" : "Equal"}
      </span>
      {children}
    </div>
  );
};

export default CustomChartTooltip;

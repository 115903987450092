import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import Table from "@components/layout/Table";
import Label from "@components/form/Label";
import NotificationMessageViewModal from "@components/form/notification/NotificationMessageViewModal";
import { getMessages } from "@services/message";
import { useAppContext } from "@context/AppContext";
import { formatDateTime } from "@utils/formatDateTime";

type NotificationHistoryTabProps = {
  walletId: string;
};

const NotificationHistoryTab: React.FunctionComponent<
  NotificationHistoryTabProps
> = ({ walletId }) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<{
    type?: string;
  }>({});
  const [selectedNotificationMessage, setSelectedNotificationMessage] =
    useState<any>();
  const { loggedInInfo } = useAppContext();
  const { data: messages } = useQuery(
    ["messages", loggedInInfo?.userDetails?.id],
    () => getMessages({ recipientWalletId: walletId }),
    {
      keepPreviousData: true,
      enabled: !!walletId,
    }
  );

  const getNotificationTypeOptions = () => {
    return ["News", "Bulletin", "Shutdown", "Forward Draw"]?.map((e) => ({
      label: e,
      value: e,
    }));
  };

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  return (
    <>
      <div className="flex flex-col gap-4">
        <form className="flex gap-3 items-center">
          <div className="w-1/2 md:w-1/5">
            <Label htmlFor="type">{t("notification.type")}</Label>
            <Select
              inputId="type"
              options={getNotificationTypeOptions()}
              className="w-full"
              isClearable={true}
              value={getNotificationTypeOptions().find(
                (e) => e.value === filter?.type
              )}
              onChange={(e: any) => {
                handleFilterChange("type", e?.value);
              }}
              openMenuOnFocus
            />
          </div>
        </form>
        <Table
          pageSize={100}
          fields={[
            {
              title: t("notification.created_at"),
              name: "createdAt",
            },
            {
              title: t("notification.type"),
              name: "type",
            },
            {
              title: t("notification.created_by"),
              name: "createdBy",
            },
            {
              title: t("notification.read_status"),
              name: "readStatus",
            },
            {
              title: " ",
              name: "action",
            },
          ]}
          data={[...(messages || [])]
            ?.filter(
              (row: { type: string }) =>
                !filter?.type || row.type === filter?.type
            )
            ?.map((message) => ({
              ...message,
              createdAt: formatDateTime(new Date(message.createdAt)),
              createdBy: message?.sender?.name,
              readStatus: message?.recipients?.find(
                (r: any) => r.recipientWalletId === walletId && !!r.viewedAt
              ) ? (
                <span className="inline-flex bg-green-100 px-4 py-0.5 leading-5 text-xs text-poler-6 border border-poler-3 rounded">
                  {t("notification.read")}
                </span>
              ) : (
                <span className="inline-flex bg-gray-100 px-4 py-0.5 leading-5 text-xs text-gray-600 border border-gray-300 rounded">
                  {t("notification.not_read")}
                </span>
              ),
              action: (
                <div className="flex gap-1 items-center w-full">
                  <button
                    type="button"
                    className="btn-default text-sm"
                    onClick={() => setSelectedNotificationMessage(message)}
                  >
                    {t("common.view")}
                  </button>
                </div>
              ),
            }))}
        />
      </div>
      <NotificationMessageViewModal
        message={selectedNotificationMessage}
        notification={selectedNotificationMessage?.notification}
        onClose={() => setSelectedNotificationMessage(undefined)}
      />
    </>
  );
};

export default NotificationHistoryTab;

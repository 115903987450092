import { useTranslation } from "react-i18next";

import { useNotificationContext } from "@context/NotificationContext";

const CreateNotificationStep2Label: React.FunctionComponent = () => {
  const { details } = useNotificationContext();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <div className="w-full pl-2 flex flex-col gap-2">
        <p className="text-sm font-bold text-gray-500">
          {t("notification.selected_subscribers", {
            count: details.subscriberIds.length,
          })}
        </p>
      </div>
    </div>
  );
};

export default CreateNotificationStep2Label;

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import InfoPanel from "@components/form/InfoPanel";
import ConfirmUser from "@components/form/user/ConfirmUser";
import EnterUserDetails from "@components/form/user/EnterUserDetails";
import StepButton from "@components/shared/StepButton";
import WorkflowPanels from "@components/form/WorkflowPanels";
import { useEvidenceContext } from "@context/shared/EvidenceContext";
import {
  UserProvider,
  UserProviderProps,
  useUserContext,
} from "@context/UserContext";
import UploadEvidencesWorkflowForm from "@components/form/UploadEvidenceWorkflowForm";
import StepInfo from "@components/shared/StepInfo";

type UserWorkflowProps = Omit<UserProviderProps, "children">;

const UserWorkflow: React.FunctionComponent<UserWorkflowProps> = (props) => {
  return (
    <UserProvider {...props}>
      <Main />
    </UserProvider>
  );
};

const Main = () => {
  const { t } = useTranslation();
  const { userId = "" } = useParams();
  const { currentStep, isComplete, user, getUserInfo, stepHelpers, cancel } =
    useUserContext();
  const { getEvidencesInfo } = useEvidenceContext();
  const isLoading = userId && !user.subscriberId;

  const steps = [
    {
      label: (
        <StepButton
          index={0}
          currentStep={currentStep}
          isOnChain={isComplete}
          info={<StepInfo data={getUserInfo().body} />}
        >
          {t("user.enter_details")}
        </StepButton>
      ),
      panel: isLoading ? null : <EnterUserDetails />,
      infoPanel: <InfoPanel>{t("user.info.user_details")}</InfoPanel>,
    },
    {
      label: (
        <StepButton
          index={1}
          currentStep={currentStep}
          isOnChain={isComplete}
          info={<StepInfo data={getEvidencesInfo().body} />}
        >
          {t("common.enter_evidence")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          referenceTableId={userId}
          referenceTable="users"
          onNext={() => {
            stepHelpers.goToNextStep();
          }}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={cancel}
          customDropboxTitle={t("subscriber.evidence_data.title") as string}
          newUploadDescription={
            t("user.evidence_data.description", {
              name: user?.name,
            }) as string
          }
          selectableForArchiving={false}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("user.info.select_files")}</p>
            <p>{t("evidence.supported")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={2}
          currentStep={currentStep}
          isOnChain={isComplete}
          forceSuccess={isComplete}
        >
          {t("user.ledger")}
        </StepButton>
      ),
      panel: <ConfirmUser />,
      infoPanel: (
        <InfoPanel
          successes={
            isComplete
              ? [t("user.info.success", { name: user?.name })]
              : [t("user.info.confirm")]
          }
        >
          {isComplete ? (
            t("user.info.end_workflow")
          ) : (
            <ul className="space-y-4">
              <li>{t("common.info.verify")}</li>
              <li>
                <strong>{t("user.details")}:</strong>{" "}
                {t("user.info.verify.details")}
              </li>
              <li>
                <strong>{t("evidence.supporting")}:</strong>{" "}
                {t("evidence.info.verify")}
              </li>
              <li>{t("common.info.changes")}</li>
            </ul>
          )}
        </InfoPanel>
      ),
    },
  ];

  return <WorkflowPanels selectedStep={currentStep} steps={steps} />;
};

export default UserWorkflow;

import React from "react";
import { groupBy, orderBy } from "lodash";
import { useParams, useSearchParams } from "react-router-dom";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import MapView from "@components/shared/MapView";
import DeclarationTag from "@components/shared/DeclarationTag";
import ExtractionPointStatusTags from "@components/shared/ExtractionPointStatusTags";
import Tag from "@components/shared/Tag";
import Loading from "@components/shared/Loading";
import { useAllDeclarations } from "@hooks/query/zenith/useAllDeclarations";
import { extractionRightTypes } from "@services/extractionRight";
import { useAppContext } from "@context/AppContext";
import { useAllSubscriberExtractionPoints } from "@hooks/query/zenith/useAllExtractionPoints";
import { formatVolume } from "@utils/formatVolume";
import { formatDate } from "@utils/formatDate";
import { parseLatLng } from "@utils/parseLatLng";
import ENV from "@config/env";
import { ExtractionPointType } from "@services/extractionPoints";
import { READING_CALCULATION } from "@services/declarations";
import { useGetSubscriber } from "@hooks/query/zenith/useGetSubscriber";

const ExtractionPointTab: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { loggedInInfo } = useAppContext();
  const { id: level0ResourceId = "" } = useParams();
  const [searchParams] = useSearchParams();
  const [filter, setFilter] = React.useState({
    extractionPointName: searchParams.get("extractionPointName") ?? "",
  });

  const { data: subscriber = {}, isLoading: isSubscriberLoading } =
    useGetSubscriber({
      id: loggedInInfo?.userDetails?.subscriberId,
      options: { enabled: Boolean(loggedInInfo?.userDetails?.subscriberId) },
    });
  const { data: points = [], isLoading: isPointsLoading } =
    useAllSubscriberExtractionPoints({
      params: {
        level0ResourceId,
        definedByWalletId: subscriber.walletId,
        type: ExtractionPointType.Regular,
      },
      options: {
        enabled: Boolean(level0ResourceId) && Boolean(subscriber.walletId),
      },
    });

  const { data: declarations = [] } = useAllDeclarations({
    params: {
      extractionPointIds: points.map((i: any) => i.id),
    },
    select: (data: any) => {
      return data.declarations.filter((d: any) => !d.deletedAt);
    },
    enabled: points.length > 0,
  });

  if (isSubscriberLoading || isPointsLoading) {
    return (
      <div className="pt-20">
        <Loading />
      </div>
    );
  }

  const filteredExtractionPoints = points.filter(
    (point: any) =>
      filter.extractionPointName === "" ||
      point.name
        .toLowerCase()
        .includes(filter.extractionPointName.toLowerCase()),
  );
  const orderExtractionPoints = orderBy(
    filteredExtractionPoints,
    ["isActive", "name"],
    ["desc", "asc"],
  );

  const declarationGroupByPoint = groupBy(declarations, "extractionPointId");

  const determineTagValue = (
    declaration: any,
    index: number,
    declarations: any[],
  ): {
    tag: string;
    usage: number;
    allocationUsage: number;
    saUsage: number;
  } => {
    let tagValue = declaration.tag;

    const hasStockAndDomestic =
      declaration.classProportions?.rightTypeProportions?.some(
        (proportion: any) => proportion.hasOwnProperty(extractionRightTypes.SD),
      );

    if (hasStockAndDomestic) {
      const usage =
        declaration.classProportions?.rightTypeProportions?.find(
          (proportion: any) =>
            proportion.hasOwnProperty(extractionRightTypes.SD),
        )?.[extractionRightTypes.SD] ?? 0;

      const allocationUsage =
        declaration.classProportions?.rightTypeProportions?.find(
          (proportion: any) =>
            proportion.hasOwnProperty(extractionRightTypes.WA),
        )?.[extractionRightTypes.WA] ?? 0;

      return {
        tag: READING_CALCULATION.STOCK_AND_DOMESTIC,
        usage,
        allocationUsage,
        saUsage: 0,
      };
    }

    // Water harvesting logic
    const nextDeclaration = declarations[index - 1];
    const waterHarvestingStarted =
      tagValue === READING_CALCULATION.WATER_HARVESTING &&
      !declaration.isUnallocated;
    const waterHarvestingStartedWithSpecialAnnouncement =
      tagValue === READING_CALCULATION.SPECIAL_ANNOUNCEMENT &&
      declaration.isUnallocated &&
      nextDeclaration?.tag === READING_CALCULATION.WATER_HARVESTING;

    const waterHarvestingEnded =
      tagValue === READING_CALCULATION.WATER_HARVESTING &&
      (!nextDeclaration ||
        nextDeclaration.tag !== READING_CALCULATION.WATER_HARVESTING);

    if (waterHarvestingStarted) {
      tagValue = READING_CALCULATION.WATER_HARVESTING_START;
    } else if (waterHarvestingEnded) {
      tagValue = READING_CALCULATION.WATER_HARVESTING_END;
    } else if (waterHarvestingStartedWithSpecialAnnouncement) {
      const saUsage =
        declaration.classProportions?.rightTypeProportions?.find(
          (proportion: any) =>
            proportion.hasOwnProperty(extractionRightTypes.SA),
        )?.[extractionRightTypes.SA] ?? 0;
      return {
        tag: READING_CALCULATION.WATER_HARVESTING_START,
        usage: 0,
        allocationUsage: 0,
        saUsage,
      };
    }

    return {
      tag: tagValue,
      usage: 0,
      allocationUsage: 0,
      saUsage: 0,
    };
  };

  return (
    <>
      <header className="flex flex-col gap-3 md:flex-row md:items-center md:justify-between">
        <form className="basis-80">
          <Label htmlFor="extractionPointName">
            {t("extraction_point.filter_name")}
          </Label>
          <SearchInput
            id="extractionPointName"
            onChange={e =>
              setFilter(prev => ({
                ...prev,
                extractionPointName: e.target.value,
              }))
            }
            value={filter.extractionPointName || ""}
            placeholder={t("common.search") as string}
          />
        </form>
      </header>
      <div className="mt-4 overflow-auto">
        <ul className="space-y-4">
          {orderExtractionPoints?.length ? (
            orderExtractionPoints.map((point: any) => {
              const latLong = parseLatLng(point?.address?.location);
              const hasMeter = Boolean(point?.meter);
              const pointDeclarations = declarationGroupByPoint[point.id] ?? [];

              return (
                <li key={point.id}>
                  <div className="grid grid-cols-10 place-content-between">
                    <div className="col-span-7 border border-gray-300 rounded p-4">
                      <header className="flex flex-row gap-3 flex-wrap items-center text-gray-600 text-xs whitespace-nowrap">
                        <div>
                          {t("extraction_point.name")} #{point.name}
                        </div>
                        <div className="flex flex-row items-center gap-2">
                          {t("extraction_point.is_active")}:
                          <ExtractionPointStatusTags point={point} />
                        </div>
                        {hasMeter ? (
                          <div>
                            {t("meter.serial_no")}: {point.meter?.serialNo}
                          </div>
                        ) : null}
                        <div>
                          {t("extraction_point.source")}:{" "}
                          {point.source.toLowerCase()}
                        </div>
                        <div>
                          {t("extraction_point.lat")}:{" "}
                          {latLong?.lat?.toFixed(5) ?? "-"}
                        </div>
                        <div>
                          {t("extraction_point.long")}:{" "}
                          {latLong?.lng?.toFixed(5) ?? "-"}
                        </div>
                        {ENV.CLIENT_ID === "seqwater" && (
                          <>
                            <div>
                              {t("extraction_point.group")}:{" "}
                              {point.group ?? "-"}
                            </div>
                            <div>
                              {t("extraction_point.sequence")}:{" "}
                              {point.sequence ?? "-"}
                            </div>
                          </>
                        )}
                      </header>

                      <Table
                        containerClassName="rounded-none md:rounded-none text-xs"
                        fields={[
                          {
                            title: t("meter.serial_no"),
                            name: "meterId",
                          },
                          ...(ENV.CLIENT_ID === "seqwater"
                            ? [
                                {
                                  title: t("meter.meter_status"),
                                  name: "meterStatus",
                                },
                              ]
                            : []),
                          {
                            title: t("declaration.read_at"),
                            name: "readAt",
                          },
                          {
                            title: t("declaration.reading"),
                            name: "reading",
                          },
                          {
                            title: t("declaration.usage"),
                            name: "usage",
                          },
                          {
                            title: t("declaration.allocation_usage"),
                            name: "allocationUsage",
                          },
                          {
                            title: t("common.tag"),
                            name: "tag",
                          },
                          {
                            title: t("common.created_by"),
                            name: "createdBy",
                          },
                        ]}
                        data={pointDeclarations.map(
                          (
                            declaration: any,
                            index: number,
                            declarationArray: any[],
                          ) => {
                            const isDecommissioned =
                              declaration.meter?.deletedAt &&
                              !declaration.meter.isActive;
                            const isActive =
                              declaration.meter?.isActive &&
                              declaration.meter.id === point.meter?.id;
                            const usage = declaration.requireManualIntervention
                              ? 0
                              : declaration.volume;

                            const {
                              tag,
                              allocationUsage: partAllocationUsage,
                              usage: unAllocatedUsage,
                              saUsage,
                            } = determineTagValue(
                              declaration,
                              index,
                              declarationArray,
                            );

                            const allocationUsage =
                              tag === READING_CALCULATION.STOCK_AND_DOMESTIC
                                ? partAllocationUsage
                                : declaration.isUnallocated
                                  ? 0
                                  : usage;

                            return {
                              ...declaration,
                              meterId:
                                declaration.meter?.serialNo ??
                                t("meter.unmetered"),
                              meterStatus: (
                                <Tag status={isActive ? "success" : "error"}>
                                  {isDecommissioned
                                    ? t("common.decommission")
                                    : isActive
                                      ? t("common.active")
                                      : t("common.inactive")}
                                </Tag>
                              ),
                              readAt: formatDate(new Date(declaration.readAt)),
                              reading: declaration.meter?.serialNo
                                ? formatVolume(declaration.reading, "")
                                : "-",
                              usage: formatVolume(usage),
                              allocationUsage: (
                                <Tag
                                  status={
                                    allocationUsage < 0
                                      ? "error"
                                      : allocationUsage > 0
                                        ? "success"
                                        : "default"
                                  }
                                  className="inline-flex gap-1 items-center pl-1 pr-2"
                                >
                                  <span>
                                    {allocationUsage < 0 && (
                                      <ArrowDownIcon className="w-4 h-4" />
                                    )}
                                    {allocationUsage > 0 && (
                                      <ArrowUpIcon className="w-4 h-4" />
                                    )}
                                  </span>
                                  {formatVolume(allocationUsage)}
                                </Tag>
                              ),
                              tag: tag ? (
                                <DeclarationTag
                                  value={tag}
                                  volume={
                                    partAllocationUsage !== 0
                                      ? unAllocatedUsage
                                      : 0
                                  }
                                  saVolume={saUsage}
                                />
                              ) : null,
                              createdBy: declaration?.createdByUser
                                ? declaration.createdByUser.isAdmin
                                  ? t("common.admin")
                                  : t("common.user")
                                : t("common.system"),
                            };
                          },
                        )}
                        noRowsText={t("declaration.no_declaration")}
                        pageSize={5}
                      />
                    </div>
                    <div className="col-span-3 pl-4 h-80">
                      {latLong.isValid ? (
                        <MapView
                          zoomLevel={14}
                          markers={[
                            { lat: latLong.lat ?? 0, lng: latLong.lng ?? 0 },
                          ]}
                        />
                      ) : (
                        <div className="font-semibold text-primary-3 text-center text-sm pt-10">
                          {t("shared.map.invalid_data")}
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              );
            })
          ) : (
            <li>
              <div className="py-6 whitespace-nowrap text-gray-400 text-center">
                {t("extraction_point.no_extraction_point_data")}
              </div>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default ExtractionPointTab;

import { Fragment, ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import ExpandInfoPanelButton from "@components/shared/ExpandInfoPanelButton";
import { useAppContext } from "@context/AppContext";

type WorkflowPanelsProps = {
  selectedStep?: number;
  selectedSubStep?: number;
  steps: Array<{
    label: ReactNode;
    panel?: ReactNode;
    subSteps?: Array<{
      label: ReactNode;
      panel: ReactNode;
    }>;
    infoPanel?: ReactNode;
  }>;
  menuClassName?: string;
  contentClassName?: string;
  itemClassName?: string;
  activeItemClassName?: string;
  inActiveItemClassName?: string;
  activeSubItemClassName?: string;
  inActiveSubItemClassName?: string;
  keepSelectionOnRefresh?: boolean;
  keepSubSelectionOnRefresh?: boolean;
};

const WorkflowPanels: React.FunctionComponent<WorkflowPanelsProps> = ({
  selectedStep,
  selectedSubStep,
  steps,
  menuClassName,
  contentClassName,
  itemClassName,
  activeItemClassName = "border-l-primary-2 text-primary-3",
  inActiveItemClassName = "text-gray-400",
  activeSubItemClassName = "text-primary-3",
  inActiveSubItemClassName = "text-gray-400",
  keepSelectionOnRefresh,
  keepSubSelectionOnRefresh,
}) => {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const [stepNo, setStepNo] = useState(selectedStep || 0);
  const [subStepNo, setSubStepNo] = useState(selectedSubStep || 0);
  const { isExpandInfoPanel } = useAppContext();

  useEffect(() => {
    setStepNo(selectedStep || 0);
    setSubStepNo(selectedSubStep || 0);
  }, [selectedStep, selectedSubStep]);

  useEffect(() => {
    if (keepSelectionOnRefresh) {
      setStepNo(+hash.replace("#", "") || 0);
    }
    if (keepSubSelectionOnRefresh) {
      setSubStepNo(+hash.replace("#", "") || 0);
    }
  }, [hash, keepSelectionOnRefresh, keepSubSelectionOnRefresh]);

  const infoPanel = steps[stepNo]?.infoPanel;

  return (
    <div className="flex grow gap-4 min-w-min">
      <aside className={twMerge("w-1/5", menuClassName)}>
        <ul className="border divide-y bg-white">
          {steps.map((step, i) => (
            <Fragment key={`workflow-step--${i}`}>
              <li
                className={twMerge(
                  "p-4",
                  itemClassName,
                  stepNo === i ? activeItemClassName : inActiveItemClassName
                )}
                onClick={() => keepSelectionOnRefresh && navigate(`#${i}`)}
              >
                {step.label}
              </li>
              {stepNo === i &&
                step?.subSteps?.map((sStep, j) => {
                  return (
                    <li
                      key={j}
                      className={twMerge(
                        "px-5 bg-blue-50 border-transparent",
                        subStepNo === j
                          ? activeSubItemClassName
                          : inActiveSubItemClassName
                      )}
                      onClick={() => {
                        keepSubSelectionOnRefresh && navigate(`#${j}`);
                      }}
                    >
                      <div className="p-2">{sStep.label}</div>
                    </li>
                  );
                })}
            </Fragment>
          ))}
        </ul>
      </aside>
      <div
        className={twMerge(
          "flex flex-col grow bg-white border relative",
          steps[stepNo]?.infoPanel && isExpandInfoPanel ? "w-3/5" : "w-4/5",
          contentClassName
        )}
      >
        {steps[stepNo]?.panel}
        {steps[stepNo]?.subSteps?.[subStepNo]?.panel}
        {infoPanel && !isExpandInfoPanel && <ExpandInfoPanelButton />}
      </div>
      {infoPanel && isExpandInfoPanel && (
        <aside className="flex w-1/5">{infoPanel}</aside>
      )}
    </div>
  );
};

export default WorkflowPanels;

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Layout from "@components/layout/Layout";
import UserWorkflow from "@components/pages/polestar/users/UserWorkflow";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";
import { useCreateUser } from "@hooks/mutation/useCreateUser";

const Level1WRSCreateUser = () => {
  const { t } = useTranslation();
  const { id: level1ResourceId = "" } = useParams();
  const { data: level1Resource } = useGetLevel1Resource(level1ResourceId);

  const { mutateAsync: createUserMutation } = useCreateUser();

  return (
    <Layout
      permissions={["CreateUsers"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: level1Resource?.name,
          href: `/polestar/level1wrs/${level1ResourceId}#2`,
        },
        {
          label: t("user.create.title"),
        },
      ]}
      title={t("user.create.title")}
    >
      <UserWorkflow
        onSubmit={createUserMutation}
        level1ResourceId={level1ResourceId}
      />
    </Layout>
  );
};

export default Level1WRSCreateUser;

import React from "react";
import { useSearchParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { t as translate } from "i18next";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import Legend from "@components/form/Legend";
import TextInput from "@components/form/TextInput";
import Label from "@components/form/Label";
import SelectLevel1Resource from "@components/form/SelectLevel1Resource";
import ReadOnlyInput from "@components/form/ReadOnlyInput";
import { useGenerateSubscriberAccountNumber } from "@hooks/query/useGenerateSubscriberAccountNumber";
import { getAllSubscribers } from "@services/subscriber";
import ENV from "@config/env";

const schema = z
  .object({
    level1Resource: z
      .object({
        id: z.string().min(1, translate("common.required.text") as string),
        name: z.string(),
        identifier: z.string(),
      })
      .required(),
    accountNumber: z
      .string()
      .min(1, translate("common.required.text") as string),
    name: z.string().min(1, translate("common.required.text") as string),
    abn: z.string().optional(),
    isActive: z.boolean(),
    address: z.object({
      id: z.string(),
      street: z.string(),
      town: z.string(),
      region: z.string(),
      postcode: z.string(),
      country: z.string(),
      location: z.string(),
    }),
    originalAccountNumber: z.string().optional(),
  })
  .refine(
    async (values) => {
      if (values.originalAccountNumber === values.accountNumber) {
        return true;
      }

      const subscribers = await getAllSubscribers({
        accountNumber: values.accountNumber,
      });
      return subscribers.length === 0;
    },
    {
      message: translate("subscriber.create.invalid_account_number") as string,
      path: ["accountNumber"],
    }
  );

export type Subscriber = z.infer<typeof schema>;

const formValues: Subscriber = {
  level1Resource: {
    id: "",
    name: "",
    identifier: "",
  },
  accountNumber: "",
  name: "",
  abn: "",
  isActive: true,
  address: {
    id: "",
    street: "",
    town: "",
    region: "",
    postcode: "",
    country: "",
    location: "",
  },
};

type SubscriberFormProps = {
  onSubmit: (data: Subscriber) => void;
  onCancel: () => void;
  subscriberId?: string;
  defaultValues?: Subscriber;
  submitText?: string | null;
  isUpdating?: boolean;
};

const SubscriberForm: React.FunctionComponent<SubscriberFormProps> = ({
  onSubmit,
  onCancel,
  submitText = translate("common.next_step"),
  defaultValues = formValues,
  isUpdating = false,
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  });
  useGenerateSubscriberAccountNumber({
    refetchOnWindowFocus: false,
    onSuccess: (accountNumber: any) =>
      setValue("accountNumber", defaultValues.accountNumber || accountNumber),
  });

  const onSave = async (data: Subscriber) => {
    onSubmit(data);
  };

  const statusOptions = [
    { label: t("common.active"), value: true },
    { label: t("common.inactive"), value: false },
  ];

  return (
    <form
      className="flex flex-col grow space-y-8"
      onSubmit={handleSubmit(onSave)}
    >
      <fieldset>
        <div className="max-w-md">
          {Boolean(searchParams.get("level1ResourceId")) &&
          Boolean(defaultValues?.level1Resource?.name) ? (
            <ReadOnlyInput>{defaultValues.level1Resource.name}</ReadOnlyInput>
          ) : (
            <>
              <Label htmlFor="level1Resource">
                {t("exchange_rate.select_level1wrs")}
              </Label>
              <Controller
                name="level1Resource"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <SelectLevel1Resource
                    onChange={(selected) =>
                      onChange({
                        id: selected?.value ?? "",
                        name: selected?.label ?? "",
                        identifier: selected?.identifier ?? "",
                      })
                    }
                    value={value?.id}
                    name={name}
                    inputId={name}
                    errorMessage={errors?.level1Resource?.id?.message}
                    isDisabled={isUpdating}
                    openMenuOnFocus
                  />
                )}
              />
            </>
          )}
        </div>
      </fieldset>

      <fieldset className="space-y-4">
        <Legend>{t("subscriber.create.subscriber_details")}</Legend>
        {ENV.CLIENT_ID === "seqwater" && (
          <div className="max-w-md">
            <Label htmlFor="accountNumber">
              {t("subscriber.create.account_number")}
            </Label>
            <TextInput
              {...register("accountNumber")}
              id="accountNumber"
              placeholder={
                t("subscriber.create.account_number_placeholder") as string
              }
              errorMessage={errors?.accountNumber?.message as string}
            />
          </div>
        )}
        <div className="max-w-md">
          <Label htmlFor="name">{t("subscriber.create.subscriber_name")}</Label>
          <TextInput
            {...register("name")}
            id="name"
            placeholder={
              t("subscriber.create.subscriber_name_placeholder") as string
            }
            errorMessage={errors?.name?.message as string}
          />
        </div>

        <div className="max-w-md">
          <Label htmlFor="abn" optional>
            {t("subscriber.abn")}
          </Label>
          <TextInput
            {...register("abn")}
            id="abn"
            placeholder={t("subscriber.create.abn_placeholder") as string}
          />
        </div>

        <div className="max-w-md">
          <Label htmlFor="isActive">{t("common.status")}</Label>
          <Controller
            name="isActive"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <Select
                name={name}
                inputId={name}
                options={statusOptions}
                value={statusOptions.find((i) => i.value === value)}
                onChange={(e) => onChange(e?.value)}
                openMenuOnFocus
              />
            )}
          />
        </div>
      </fieldset>

      <fieldset className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
        <div className="col-span-full">
          <Legend>{t("subscriber.create.subscriber_address_details")}</Legend>
        </div>

        <div className="col-span-full">
          <Label htmlFor="street" optional>
            {t("subscriber.create.subscriber_street")}
          </Label>
          <TextInput
            {...register("address.street")}
            id="street"
            placeholder={
              t("subscriber.create.subscriber_street_placeholder") as string
            }
            errorMessage={errors?.address?.street?.message as string}
          />
        </div>
        <div className="sm:col-span-3">
          <Label htmlFor="town" optional>
            {t("subscriber.create.subscriber_town")}
          </Label>
          <TextInput
            {...register("address.town")}
            id="town"
            placeholder={
              t("subscriber.create.subscriber_town_placeholder") as string
            }
            errorMessage={errors?.address?.town?.message as string}
          />
        </div>
        <div className="sm:col-span-3">
          <Label htmlFor="region" optional>
            {t("subscriber.create.subscriber_region")}
          </Label>
          <TextInput
            {...register("address.region")}
            id="region"
            placeholder={
              t("subscriber.create.subscriber_region_placeholder") as string
            }
            errorMessage={errors?.address?.region?.message as string}
          />
        </div>
        <div className="sm:col-span-3">
          <Label htmlFor="postcode" optional>
            {t("subscriber.create.subscriber_postcode")}
          </Label>
          <TextInput
            {...register("address.postcode")}
            id="postcode"
            placeholder={
              t("subscriber.create.subscriber_postcode_placeholder") as string
            }
            errorMessage={errors?.address?.postcode?.message as string}
          />
        </div>
        <div className="sm:col-span-3">
          <Label htmlFor="country" optional>
            {t("subscriber.create.subscriber_country")}
          </Label>
          <TextInput
            {...register("address.country")}
            id="country"
            placeholder={
              t("subscriber.create.subscriber_country_placeholder") as string
            }
            errorMessage={errors?.address?.country?.message as string}
          />
        </div>
      </fieldset>

      <div className="grow" />

      <footer className="flex gap-4 -mx-6 mt-6 p-6 pb-0 border-t border-gray-200">
        <button type="submit" className="btn-primary" disabled={isSubmitting}>
          {submitText}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default SubscriberForm;

import React from "react";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import ConfirmationDetail from "@components/shared/ConfirmationDetail";
import ConfirmModal from "@components/shared/ConfirmModal";
import { useWaterClassContext } from "@context/WaterClassContext";
import { toastError, toastSuccess } from "@utils/toast";
import { convertLiterToML, convertMLToLiter } from "@utils/convertUnits";
import { useCreateWaterClass } from "@hooks/mutation/useCreateWaterClass";
import { isValidationError } from "@utils/formError";
import { useMutation } from "@tanstack/react-query";
import {
  CreateExchangeRatePayload,
  createExchangeRates,
} from "@services/exchangeRate";
import { useEvidenceContext } from "@context/shared/EvidenceContext";
import { useUpdateWaterClass } from "@hooks/mutation/useUpdateWaterClass";
import { DBTables } from "@utils/constants/dbTables";

const WaterClassLedger = () => {
  const { t } = useTranslation();
  const {
    stepHelpers,
    level0Resource,
    details,
    setDetails,
    classCategoryName,
    navigateForCancel,
    setWorkflowCompleted,
    setWorkflowInstance,
    updatingWaterClass,
    doAdjustWaterClassVolumeDetails,
    adjustedWaterClassVolumeDetails,
  } = useWaterClassContext();
  const {
    getEvidencesInfo,
    archiveUnselectedEvidences,
    uploadEvidences,
    isSubmitting,
    isArchivingUnselectedEvidence,
  } = useEvidenceContext();
  const isUpdating = Boolean(updatingWaterClass);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);

  const {
    mutateAsync: createWaterClassMutation,
    isLoading: isCreateWaterClassLoading,
  } = useCreateWaterClass();
  const {
    mutateAsync: updateWaterClassMutation,
    isLoading: isUpdatingWaterClasses,
  } = useUpdateWaterClass();

  const {
    mutateAsync: saveExchangeRatesData,
    isLoading: isLoadingExchangeRateSave,
  } = useMutation<any, any, CreateExchangeRatePayload>(exchangeRateData =>
    createExchangeRates(exchangeRateData),
  );

  const handleConfirm = async () => {
    try {
      const { startAt, allocation, ...otherWaterClassData } = details;

      let res = { id: "", name: "", level0Resource: { parentId: "" } };
      let instance: any;

      if (isUpdating) {
        const { name, classCategoryId, acctPriority } = otherWaterClassData;

        const [data, workflowInstance] = await updateWaterClassMutation({
          name,
          classCategoryId,
          acctPriority,
          waterClassId: updatingWaterClass?.id,
        });
        res = data;
        instance = workflowInstance;
        setDetails((prevState: any) => ({
          ...prevState,
          id: res.id,
        }));
        const isCurrentClassVolumeUpdated =
          details.volume !== +convertLiterToML(updatingWaterClass.volume);

        let updateWaterClasses: {
          waterClassId: string;
          volumeNextAccountingPeriod: number;
        }[] = [];
        if (doAdjustWaterClassVolumeDetails) {
          updateWaterClasses = adjustedWaterClassVolumeDetails.map(i => ({
            waterClassId: i.classId,
            volumeNextAccountingPeriod: convertMLToLiter(+i.adjustedVolume),
          }));
        } else if (isCurrentClassVolumeUpdated) {
          updateWaterClasses = [
            {
              waterClassId: updatingWaterClass?.id,
              volumeNextAccountingPeriod: convertMLToLiter(details.volume),
            },
          ];
        }
        await Promise.all(
          updateWaterClasses.map(async payload =>
            updateWaterClassMutation(payload),
          ),
        );

        if (doAdjustWaterClassVolumeDetails) {
          toastSuccess(
            t("level0wrs.update.toast.success_class", { name: res.name }),
          );
        }
      } else {
        const [data, workflowInstance] = await createWaterClassMutation({
          ...otherWaterClassData,
          volume: convertMLToLiter(details?.volume),
        });
        res = data;
        instance = workflowInstance;

        const [exchangeRates] = await saveExchangeRatesData({
          waterClassIds: [res.id],
          rate: allocation / 100,
          startAt: new Date(startAt),
          accountingPeriodId: details.accountingPeriodId,
        });
        setDetails((prevState: any) => ({
          ...prevState,
          id: res.id,
          exchangeRateIds: exchangeRates.map((er: any) => er.id),
        }));
      }
      setWorkflowInstance(instance);

      await archiveUnselectedEvidences({
        referenceId: res.id,
        referenceTable: "water_classes",
        evidenceIds: details.activatedEvidenceIds,
      });

      await uploadEvidences({
        description: t("water_class.create.step_3.description", {
          waterClassName: details.name,
        }),
        references: [
          {
            referenceId: res.id,
            referenceTable: DBTables.WaterClasses,
          },
          {
            referenceId: res.level0Resource?.parentId,
            referenceTable: DBTables.WRSHierarchies,
          },
          {
            referenceId: instance?.id,
            referenceTable: DBTables.WorkflowInstances,
          },
        ],
      });

      setWorkflowCompleted(true);
      toastSuccess(t("water_class.toast.success"));
    } catch (error: any) {
      const toastErrorMessage = t("water_class.toast.failed");
      if (isValidationError(error)) {
        const { errors = [] } = error?.response?.data;
        const messages = errors.map((i: any) => i.message);

        toastError(
          <>
            <p>{toastErrorMessage}</p>
            {messages.length ? (
              <ul className="list-disc pl-4">
                {messages.map((text: any) => {
                  return <li key={text}>{text}</li>;
                })}
              </ul>
            ) : null}
          </>,
        );
      } else {
        toastError(
          <>
            <p>{toastErrorMessage}</p>
            <p>{error?.message}</p>
          </>,
        );
      }
    }
    setShowConfirmModal(false);
  };

  const summary = [
    {
      title: t("water_class.details"),
      body: [
        {
          key: t("common.class"),
          value: details?.name,
        },
        {
          key: t("common.level0wrs"),
          value: level0Resource?.identifier,
        },
        {
          key: t("water_class.class_category"),
          value: classCategoryName,
        },
      ],
    },
    {
      title: t("water_class.class_volume"),
      body: [
        {
          key: t("common.volume"),
          value: `${details?.volume} ${t("common.volume_unit")}`,
        },
        {
          key: t("exchange_rate.rate"),
          value: `${details?.allocation}%`,
        },
        {
          key: t("common.water_class"),
          value: details?.acctPriority,
        },
      ],
    },
    getEvidencesInfo(),
  ];

  return (
    <>
      <div className="flex flex-col grow p-6 space-y-6">
        <Heading light>{t("water_class.definition_complete")}</Heading>

        <ConfirmationDetail data={summary} onEdit={stepHelpers.setStep} />

        <div className="grow" />

        <footer className="flex gap-4 p-6 pb-0 -mx-6 border-t border-gray-200">
          <button
            type="button"
            className="btn-outline-primary"
            onClick={stepHelpers.goToPrevStep}
          >
            {t("common.prev_step")}
          </button>
          <button
            type="button"
            className="btn-primary"
            onClick={() => {
              setShowConfirmModal(true);
            }}
          >
            {t("common.ledger")}
          </button>
          <button
            type="button"
            className="btn-outline-primary"
            onClick={navigateForCancel}
          >
            {t("common.cancel")}
          </button>
        </footer>
      </div>

      <ConfirmModal
        open={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
        }}
        onConfirm={handleConfirm}
        isSubmitting={
          isCreateWaterClassLoading ||
          isLoadingExchangeRateSave ||
          isSubmitting ||
          isArchivingUnselectedEvidence ||
          isUpdatingWaterClasses
        }
      >
        {t("water_class.modal.confirm_create")}
      </ConfirmModal>
    </>
  );
};

export default WaterClassLedger;

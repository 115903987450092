import { useQuery } from "@tanstack/react-query";
import { getAllWorkflowgroups } from "@services/workflow";

export function useGetAllWorkflowGroups(options?: Record<string, any>) {
  return useQuery<any>({
    queryKey: ["workflowGroups"],
    queryFn: () => getAllWorkflowgroups(),
    ...options,
  });
}

import { useTranslation } from "react-i18next";
import Heading from "@components/layout/Heading";
import { useCreateLevel1WRSContext } from "@context/CreateLevel1WRSContext";
import EventTransactionPaginatedTable from "@components/table/EventTransactionPaginatedTable";
import EndButtonModal from "@components/shared/EndButton";

const Level1WRSWorkflowCompleted = () => {
  const { t } = useTranslation();
  const { workflowInstance } = useCreateLevel1WRSContext();

  return (
    <>
      <div className="flex flex-col gap-6 grow p-6">
        <Heading light>{t("level1wrs.create.workflow_complete")}</Heading>

        <EventTransactionPaginatedTable
          references={[workflowInstance?.id || ""]}
          stickyHeader={true}
        />
      </div>
      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <EndButtonModal />
      </footer>
    </>
  );
};

export default Level1WRSWorkflowCompleted;

import { checkPeriodStart } from "@services/level0Resource";
import { useMutation } from "@tanstack/react-query";

export function useValidateLevel0ResourcePeriodStart(options: any = {}) {
  return useMutation<any, any, { level1wrsId: string; periodStart: Date }>({
    mutationKey: ["check_level_1_resource_period_start"],
    mutationFn: checkPeriodStart,
    ...options,
  });
}

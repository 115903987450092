import WorkflowPanels from "@components/form/WorkflowPanels";
import Layout from "@components/layout/Layout";
import GeneralReportStep1 from "@components/reports/general/GeneralReportStep1";
import GeneralReportStep2 from "@components/reports/general/GeneralReportStep2";
import GeneralReportStep3 from "@components/reports/general/GeneralReportStep3";
import GeneralReportStep4 from "@components/reports/general/GeneralReportStep4";
import Circle from "@components/shared/Circle";
import {
  GeneralReportProvider,
  useGeneralReportContext,
} from "@context/GeneralReportContext";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const GeneralReports: React.FunctionComponent = () => {
  return (
    <GeneralReportProvider>
      <MainComponent />
    </GeneralReportProvider>
  );
};

const MainComponent: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { currentStep, reportData } = useGeneralReportContext();

  return (
    <Layout
      permissions={["ViewReports"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("reporting.title"),
        },
      ]}
      title={t("reporting.new_general_report")}
    >
      <WorkflowPanels
        selectedStep={currentStep}
        steps={[
          {
            label: (
              <div
                className={classNames(
                  "flex gap-2 items-start justify-start w-full text-left",
                  {
                    "text-green-600": currentStep > 0,
                  }
                )}
              >
                <Circle
                  isActive={currentStep === 0}
                  isSuccess={currentStep > 0}
                >
                  1
                </Circle>
                <div>
                  {t("reporting.general_step1_title")}
                  {reportData?.name && (
                    <div className="text-gray-600">
                      <strong>Name</strong>: {reportData.name}
                    </div>
                  )}
                </div>
              </div>
            ),
            panel: <GeneralReportStep1 />,
          },
          {
            label: (
              <div
                className={classNames(
                  "flex gap-2 items-start justify-start w-full text-left",
                  {
                    "text-green-600": currentStep > 1,
                  }
                )}
              >
                <Circle
                  isActive={currentStep === 1}
                  isSuccess={currentStep > 1}
                >
                  2
                </Circle>
                <div>{t("reporting.general_step2_title")}</div>
              </div>
            ),
            panel: <GeneralReportStep2 />,
          },
          {
            label: (
              <div
                className={classNames(
                  "flex gap-2 items-start justify-start w-full text-left",
                  {
                    "text-green-600": currentStep > 2,
                  }
                )}
              >
                <Circle
                  isActive={currentStep === 2}
                  isSuccess={currentStep > 2}
                >
                  3
                </Circle>
                <div>{t("reporting.general_step3_title")}</div>
              </div>
            ),
            panel: <GeneralReportStep3 />,
          },
          {
            label: (
              <div
                className={classNames(
                  "flex gap-2 items-start justify-start w-full text-left",
                  {
                    "text-green-600": currentStep > 3,
                  }
                )}
              >
                <Circle
                  isActive={currentStep === 3}
                  isSuccess={currentStep > 3}
                >
                  4
                </Circle>
                <div>{t("reporting.general_step4_title")}</div>
              </div>
            ),
            panel: <GeneralReportStep4 />,
          },
        ]}
      />
    </Layout>
  );
};

export default GeneralReports;

import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { formatPercentage } from "../../../utils/formatPercentage";
import { formatVolume } from "../../../utils/formatVolume";
import BucketCircleIcon from "../../icons/BucketCircleIcon";
import ListIcon from "../../icons/ListIcon";
import UserCircleIcon from "../../icons/UserCircleIcon";
import WaterCircleIcon from "../../icons/WaterCircleIcon";
import Card from "../../layout/Card";

type SummaryWidgetProps = {
  level1Resource: any;
};

const SummaryWidget: React.FunctionComponent<SummaryWidgetProps> = ({
  level1Resource,
}) => {
  const { t } = useTranslation();
  const [noOfCustomers, setNoOfCustomers] = useState(0);

  useEffect(() => {
    const subsciberWallets = new Set();

    if (level1Resource) {
      level1Resource.level0WRSMembers.forEach((zone: any) => {
        zone.extractionRights.forEach((entitlement: any) =>
          subsciberWallets.add(entitlement.assertedByWalletId)
        );
      });

      setNoOfCustomers(subsciberWallets.size);
    }
  }, [level1Resource]);

  return (
    <Card
      className="w-1/2 lg:w-1/5 shadow-none"
      header={
        <p className="flex gap-3">
          <ListIcon /> {t("level1wrs.widget_title")}
        </p>
      }
    >
      <div className="flex flex-col gap-1">
        <div className="flex border-t py-3 w-full">
          <WaterCircleIcon />
          <section className="pl-2">
            <h4 className="text-gray-500 text-sm">
              {t("level1wrs.volume_available")}
            </h4>
            <p className="text-xl">{formatVolume(level1Resource?.volume)}</p>
          </section>
        </div>
        <div className="flex border-t py-3 w-full">
          <UserCircleIcon />
          <section className="pl-2">
            <h4 className="text-gray-500 text-sm">
              {t("level1wrs.subscribers_count")}
            </h4>
            <p className="text-xl">{noOfCustomers}</p>
          </section>
        </div>
        <div className="flex border-t py-3 w-full">
          <BucketCircleIcon />
          <section className="pl-2 w-full flex flex-col gap-3">
            <h4 className="text-gray-500 text-sm">
              {t("level1wrs.volume_used")}
            </h4>
            <div className="flex flex-col 2xl:flex-row space-y-4 2xl:space-y-0 2xl:space-x-4 text-xl">
              <p className="w-1/3 2xl:border-r-2">
                {level1Resource &&
                  formatPercentage(
                    level1Resource?.usedVolume / level1Resource?.volume
                  )}
              </p>
              <p className="w-2/3">
                {level1Resource && formatVolume(level1Resource?.usedVolume)}
              </p>
            </div>
            <h4 className="text-gray-500 text-sm">
              {t("level1wrs.volume_remain")}
            </h4>
            <div className="flex flex-col 2xl:flex-row space-y-4 2xl:space-y-0 2xl:space-x-4 text-xl">
              <p className="w-1/3 2xl:border-r-2">
                {level1Resource &&
                  formatPercentage(
                    level1Resource?.remainingVolume / level1Resource?.volume
                  )}
              </p>
              <p className="w-2/3">
                {level1Resource &&
                  formatVolume(level1Resource?.remainingVolume)}
              </p>
            </div>
          </section>
        </div>
      </div>
    </Card>
  );
};

export default SummaryWidget;

import { Trans, useTranslation } from "react-i18next";

import TabPanels from "@components/form/TabPanels";
import InfoPanel from "@components/form/InfoPanel";
import Level1WRSDeclarationList from "./Level1WRSDeclarationList";
import Level1WRSExtractionPointList from "./Level1WRSExtractionPointList";
import Level1WRSUploadList from "./Level1WRSUploadList";
import Level1WRSMeterList from "./Level1WRSMeterList";

type Level1WRSMetersAndExtractionPointsProps = {
  level1Resource: any;
};

const Level1WRSMetersAndExtractionPoints: React.FunctionComponent<
  Level1WRSMetersAndExtractionPointsProps
> = ({ level1Resource }) => {
  const { t } = useTranslation();

  if (!level1Resource) return null;

  return (
    <TabPanels
      className="bg-white"
      tabs={[
        {
          title: `${t("common.extraction_points")}`,
          panel: (
            <Level1WRSExtractionPointList level1Resource={level1Resource} />
          ),
          tabInfoPanel: (
            <InfoPanel>
              <div className="space-y-2">
                <ul className="flex flex-col gap-5 list-disc list-outside">
                  <li>
                    <Trans
                      i18nKey="level1wrs.tab_info_panel.extraction_point.intro"
                      values={{
                        level1wrsName: level1Resource.name,
                      }}
                    />
                  </li>
                  <li>{t("level1wrs.tab_info_panel.extraction_point.body")}</li>
                  <li>
                    {t("level1wrs.tab_info_panel.extraction_point.conclusion")}
                  </li>
                </ul>
              </div>
            </InfoPanel>
          ),
        },
        {
          title: `${t("common.meters")}`,
          panel: <Level1WRSMeterList level1Resource={level1Resource} />,
          tabInfoPanel: (
            <InfoPanel>
              <div className="space-y-2">
                <ul className="flex flex-col gap-5 list-disc list-outside">
                  <li>
                    <Trans
                      i18nKey="level1wrs.tab_info_panel.meter.intro"
                      values={{
                        level1wrsName: level1Resource.name,
                      }}
                    />
                  </li>
                  <li>{t("level1wrs.tab_info_panel.meter.body")}</li>
                  <li>{t("level1wrs.tab_info_panel.meter.conclusion")}</li>
                </ul>
              </div>
            </InfoPanel>
          ),
        },
        {
          title: t("common.declarations"),
          panel: <Level1WRSDeclarationList level1Resource={level1Resource} />,
          tabInfoPanel: (
            <InfoPanel>
              <div className="space-y-2">
                <ul className="flex flex-col gap-5 list-disc list-outside">
                  <li>
                    <Trans
                      i18nKey="level1wrs.tab_info_panel.meter.read.intro"
                      values={{
                        level1wrsName: level1Resource.name,
                      }}
                    />
                  </li>
                </ul>
              </div>
            </InfoPanel>
          ),
        },
        {
          title: t("common.declaration_uploads"),
          panel: <Level1WRSUploadList level1Resource={level1Resource} />,
          tabInfoPanel: (
            <InfoPanel>
              <div className="space-y-2">
                <ul className="flex flex-col gap-5 list-disc list-outside">
                  <li>
                    <Trans
                      i18nKey="level1wrs.tab_info_panel.meter.bulk_upload.intro"
                      values={{
                        level1wrsName: level1Resource.name,
                      }}
                    />
                  </li>
                </ul>
              </div>
            </InfoPanel>
          ),
        },
      ]}
    />
  );
};

export default Level1WRSMetersAndExtractionPoints;

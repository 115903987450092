import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import languages from "@config/languages";
import ENV from "@config/env";

let resources: Record<
  string,
  {
    translation: Record<string, string>;
    taxonomy: Record<string, string>;
  }
> = {};

for (const lang of languages) {
  resources[lang.languageCode] = {
    translation: require(`./translations/${lang.languageCode}/translation.json`),
    taxonomy: ENV.CLIENT_ID
      ? require(`./translations/${lang.languageCode}/${ENV.CLIENT_ID}/taxonomy.json`)
      : {},
  };
}

i18n.use(initReactI18next).init({
  debug: true,
  lng: "en",
  fallbackLng: "en",
  // ns: ["taxonomy", "translation"],
  // defaultNS: "translation",
  defaultNS: ["taxonomy", "translation"],
  fallbackNS: ["taxonomy", "translation"],
  resources,
  keySeparator: ".",
  nsSeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

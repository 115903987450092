import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Layout from "@components/layout/Layout";
import CreateLevel0WRSWorkflow from "@components/form/level0_wrs/create_level0_wrs/CreateLevel0WRSWorkflow";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";

const CreateLevel0WRS = () => {
  const { t } = useTranslation();
  const { id: level1ResourceId = "" } = useParams();
  const { data: level1Resource } = useGetLevel1Resource(level1ResourceId);

  return (
    <Layout
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: level1Resource?.name,
          href: `/polestar/level1wrs/${level1ResourceId}`,
        },
        {
          label: t("level1wrs.zone_and_classes"),
          href: `/polestar/level1wrs/${level1ResourceId}#1`,
        },
        {
          label: t("level0wrs.create.title"),
        },
      ]}
      title={t("level0wrs.create.title")}
      permissions={["CreateLevel0Resource"]}
      accountingPeriodLevel1ResourceId={level1ResourceId}
    >
      <CreateLevel0WRSWorkflow />
    </Layout>
  );
};

export default CreateLevel0WRS;

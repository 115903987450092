import React from "react";
import classNames from "classnames";

interface CheckboxInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  errorMessage?: string;
  suffix?: React.ReactNode;
  inline?: boolean;
  inputClassName?: string;
  htmlFor?: string;
  label: React.ReactNode;
  labelDescription?: string;
}

const CheckboxInputWithLabel = React.forwardRef<
  HTMLInputElement,
  CheckboxInputProps
>(
  (
    {
      className,
      errorMessage,
      suffix,
      inline = false,
      inputClassName,
      htmlFor,
      label,
      labelDescription,
      ...inputProps
    },
    ref
  ) => {
    return (
      <div
        className={classNames(
          "sm:text-sm",
          {
            "w-full": !inline,
          },
          className
        )}
      >
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              id={htmlFor}
              ref={ref}
              className={classNames(
                "h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer",
                {
                  "border-red-300": !!errorMessage,
                }
              )}
              {...inputProps}
              type="checkbox"
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label
              htmlFor={htmlFor}
              className="font-medium text-gray-900 cursor-pointer"
            >
              {label}
            </label>
            <p id="comments-description" className="text-gray-500">
              {labelDescription}
            </p>
          </div>
        </div>
      </div>
    );
  }
);

export default CheckboxInputWithLabel;

import api from "./api";

export const getAllReports = async (): Promise<any[]> => {
  const { data } = await api.get("/admin/reports");

  return data.reports;
};

export const getRegulatoryReports = async (): Promise<any> => {
  const { data } = await api.get("/admin/reports", { params: { category: 1 } });

  return data.reports;
};

export const getMeterReadSequenceReports = async (): Promise<any> => {
  const { data } = await api.get("/admin/reports", { params: { category: 4 } });

  return data.reports;
};

export const getBillingReports = async (): Promise<any> => {
  const { data } = await api.get("/admin/reports", { params: { category: 2 } });

  return data.reports;
};

export const getBillingReportHistories = async (params: {
  fromDate?: Date;
  toDate?: Date;
}): Promise<any> => {
  const { data } = await api.get("/admin/reportHistories", {
    params: { category: 2, ...params },
  });

  return data.reportHistories;
};

export const getGeneralReports = async (): Promise<any> => {
  const { data } = await api.get("/admin/reports", { params: { category: 3 } });

  return data.reports;
};

export const getWaterAccountsReport = async (): Promise<any> => {
  const { data } = await api.get("/admin/reports", { params: { category: 4 } });

  return data.reports.find((report: any) => report.type === "WaterAccounts");
};

export const getWaterTakenGWReport = async (params: {
  fromDate: Date;
  toDate: Date;
}): Promise<any> => {
  const { data } = await api.get("/admin/reports/waterTakenGW", { params });

  return data.data;
};

export const getWaterTakenSWReport = async (params: {
  fromDate: Date;
  toDate: Date;
}): Promise<any> => {
  const { data } = await api.get("/admin/reports/waterTakenSW", { params });

  return data.data;
};

export const getSeasonalAssign = async (params: {
  fromDate: Date;
  toDate: Date;
}): Promise<any> => {
  const { data } = await api.get("/admin/reports/seasonalAssign", { params });

  return data.data;
};

export const getWaterEntitlement = async (params: {
  fromDate: Date;
  toDate: Date;
}): Promise<any> => {
  const { data } = await api.get("/admin/reports/waterEntitlement", { params });

  return data.data;
};

export const getWaterTakenExcessGWReport = async (params: {
  fromDate: Date;
  toDate: Date;
}): Promise<any> => {
  const { data } = await api.get("/admin/reports/waterTakenExcessGW", {
    params,
  });

  return data.data;
};

export const getWaterTakenExcessSWReport = async (params: {
  fromDate: Date;
  toDate: Date;
}): Promise<any> => {
  const { data } = await api.get("/admin/reports/waterTakenExcessSW", {
    params,
  });

  return data.data;
};

export const getMeterReadSequenceReport = async (params: {}): Promise<any> => {
  const { data } = await api.get("/admin/reports/meterReadSequence", {
    params,
  });

  return data.data;
};

export const getWaterAccounts = async (params: {
  fromDate: Date;
  toDate: Date;
}): Promise<any> => {
  const { data } = await api.get("/admin/reports/waterAccounts", {
    params,
  });

  return data.data;
};

export const getBilling = async (params: {
  fromDate: Date;
  toDate: Date;
}): Promise<any> => {
  const { data } = await api.get("/admin/reports/billing", { params });

  return data.data;
};

export const getGeneralData = async (params: {
  fromDate: Date;
  toDate: Date;
  selectedTables: string[];
}): Promise<any> => {
  const { data } = await api.get("/admin/reports/general", { params });

  return data.data;
};

export const createReportHistory = async (params: {
  reportId: string;
  file: File;
  fromDate?: Date;
  toDate?: Date;
}) => {
  const formData = new FormData();

  formData.append("file", params.file);
  formData.append("fromDate", params.fromDate?.toISOString() || "");
  formData.append("toDate", params.toDate?.toISOString() || "");

  const { data } = await api.put(`/admin/reports/${params.reportId}`, formData);

  return data.reportHistory;
};

export const saveReportComment = async (params: {
  reportId: string;
  recordId: string;
  comment: string;
}) => {
  const { data } = await api.post("/admin/reportComments/", params);

  return data.reportComment;
};

import api from "./api";

export const getAllEvidences = async (
  params?: Record<string, any>,
): Promise<any> => {
  const { data } = await api.get("/admin/evidences", { params });

  return data.evidences;
};

export type CreateEvidencePayload = {
  title: string;
  description: string;
  references: Array<{ referenceId: string; referenceTable: string }>;
  isPublic?: boolean;
  isEncrypted?: boolean;
  attachment: File;
};

export const createEvidence = async (
  evidence: CreateEvidencePayload,
): Promise<any> => {
  const formData = new FormData();

  const isPublic = evidence.isPublic ? evidence.isPublic.toString() : "false";
  const isEncrypted = evidence.isEncrypted
    ? evidence.isEncrypted.toString()
    : "false";

  formData.append("title", evidence.title);
  formData.append("description", evidence.description);
  formData.append("isPublic", isPublic);
  formData.append("isEncrypted", isEncrypted);
  formData.append("attachment", evidence.attachment);
  for (let i = 0; i < evidence.references.length; i++) {
    formData.append(
      `references[${i}][referenceId]`,
      evidence.references[i].referenceId,
    );
    formData.append(
      `references[${i}][referenceTable]`,
      evidence.references[i].referenceTable,
    );
  }

  const { data } = await api.post("/evidences", formData);

  return data.evidence;
};

export type ArchiveUnselectedPayload = {
  referenceTable: string;
  referenceId: string;
  evidenceIds: any[];
};

export const archiveUnselected = async (
  payload: ArchiveUnselectedPayload,
): Promise<any> => {
  const { data } = await api.post(
    "/admin/evidences/archiveUnselected",
    payload,
  );

  return data;
};

export const downloadEvidence = async (id: string) => {
  const { data } = await api.get(`evidences/${id}/download`, {
    responseType: "blob",
  });
  return data;
};

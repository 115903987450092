import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isFunction } from "lodash";

import {
  createDeclaration,
  CreateDeclarationPayload,
} from "@services/declarations";

export function useCreateDeclaration(options: any = {}) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;

  return useMutation<any, any, CreateDeclarationPayload>({
    mutationKey: ["create_declaration"],
    mutationFn: createDeclaration,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["declarations"] });

      if (isFunction(onSuccess)) {
        onSuccess();
      }
    },
    ...restOptions,
  });
}

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { formatVolume } from "../../../../utils/formatVolume";
import { formatPercentage } from "../../../../utils/formatPercentage";
import Card from "../../../layout/Card";
import DropDownMenu from "../../../form/DropDownMenu";
import { useTranslation } from "react-i18next";
import MapView from "@components/shared/MapView";
import CircleScale from "../../../layout/CircleScale";
import PercentageIcon from "../../../icons/PercentageIcon";

type Level1ResourceWidgetProps = {
  level1Resource: any;
};

const Level1ResourceWidget: React.FunctionComponent<
  Level1ResourceWidgetProps
> = ({ level1Resource }) => {
  const { t } = useTranslation();
  const [selectedLevel0WRS, setSelectedLevel0WRS] = useState(
    level1Resource?.level0WRSMembers?.[0]
  );
  const [waterClasses, setWaterClasses] = useState<Record<string, any>>({});

  useEffect(() => {
    const waterClassRates: Record<string, any> = {};

    if (level1Resource?.level0WRSMembers) {
      for (const zone of level1Resource?.level0WRSMembers) {
        waterClassRates[zone.identifier] = {};
        for (const waterClass of zone?.waterClasses) {
          if (waterClassRates[zone.identifier][waterClass.name] === undefined)
            waterClassRates[zone.identifier][waterClass.name] =
              waterClass.activeExchangeRate?.rate;
        }
      }

      setSelectedLevel0WRS(level1Resource?.level0WRSMembers[0]);
      setWaterClasses(waterClassRates);
    }
  }, [level1Resource]);

  const getColors = (percentage: number) => {
    let fontColor: string, bgColor: string, borderColor: string;

    if (percentage >= 0.75) {
      fontColor = "#000";
      bgColor = "#CCF8FE";
      borderColor = "#006EB3";
    } else if (percentage >= 0.5) {
      fontColor = "#34B53A";
      bgColor = "#E2FBD7";
      borderColor = "#34B53A";
    } else if (percentage > 0 && percentage < 0.5) {
      fontColor = "#000";
      bgColor = "#FFE5D3";
      borderColor = "#FF3A29";
    } else {
      fontColor = "#000";
      bgColor = "#eee";
      borderColor = "#000";
    }

    return {
      fontColor,
      bgColor,
      borderColor,
    };
  };

  return (
    <Card className="p-0">
      <div className="flex flex-col h-full">
        <header
          className="w-full h-32 rounded-t-lg font-semibold flex flex-col justify-end relative"
          style={{
            background: `url('${level1Resource?.photo}')`,
            backgroundColor: "#004A88",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          {level1Resource?.geography && (
            <div className="absolute inset-0 z-0 rounded-t-lg overflow-hidden">
              <MapView
                className="h-full w-full relative"
                zoomLevel={6}
                zoomControl={false}
                center={{
                  lat: +level1Resource?.geography?.split(",")?.[0],
                  lng: +level1Resource?.geography?.split(",")?.[1],
                }}
              />
            </div>
          )}
          <div className="w-full flex items-end justify-between z-10 h-full p-4 text-white bg-gradient-to-t from-black to-transparent bg-opacity-10">
            <p className="w-1/2 drop-shadow-lg text-xl">
              {level1Resource?.name}
            </p>
            <div className="w-auto flex gap-2">
              <Link
                to={`/polestar/level1wrs/${level1Resource?.id}`}
                className="btn-secondary text-sm font-normal"
              >
                {t("level1wrs.view")}
              </Link>
            </div>
          </div>
        </header>
        <div className="grid grid-cols-2 h-full">
          <div className="border-r border-gray-200 p-4 pt-4 flex flex-col">
            <div className="flex flex-col 3xl:flex-row w-full">
              <h3 className="text-sm font-bold flex-none pt-0.5 flex gap-1">
                <PercentageIcon ifSmallSize={true} />{" "}
                {t("level1wrs.current_exchange_rates")}
              </h3>
              {level1Resource?.level0WRSMembers?.length > 0 && (
                <div className="w-full flex-grow pl-2 pb-0.5">
                  <DropDownMenu
                    className="text-sm font-sans px-2 py-0 bg-sky-950 text-white font-bold rounded-full border hover:bg-sky-800 hover:shadow-md transition duration-200 ease-in-out"
                    items={level1Resource?.level0WRSMembers
                      .sort((a: any, b: any) =>
                        a.identifier.localeCompare(b.identifier)
                      )
                      .map((zone: any) => ({
                        label: zone.identifier,
                        onClick: () => setSelectedLevel0WRS(zone),
                      }))}
                    placeHolder={selectedLevel0WRS?.identifier}
                  />
                </div>
              )}
            </div>
            <div className="flex flex-row flex-wrap gap-3">
              {waterClasses[selectedLevel0WRS?.identifier]
                ? Object.keys(waterClasses[selectedLevel0WRS.identifier])
                    .sort((a, b) => a.length - b.length)
                    .map((waterClass: string) => {
                      const { fontColor, bgColor, borderColor } = getColors(
                        waterClasses[selectedLevel0WRS.identifier][waterClass]
                      );

                      return (
                        <div
                          key={waterClass}
                          className="text-xxs font-semibold flex flex-col text-center gap-2 w-full lg:w-1/3 xl:w-1/4 3xl:w-1/5 pt-2"
                        >
                          <div className="w10 h-10 flex justify-center content-center">
                            <CircleScale
                              size={40}
                              borderSize={10}
                              scale={
                                waterClasses[selectedLevel0WRS.identifier][
                                  waterClass
                                ]
                              }
                              borderColor={borderColor}
                              bgColor={bgColor}
                            >
                              <h4
                                className="text-sm"
                                style={{ color: fontColor }}
                              >
                                {!isNaN(
                                  waterClasses[selectedLevel0WRS.identifier][
                                    waterClass
                                  ]
                                )
                                  ? formatPercentage(
                                      waterClasses[
                                        selectedLevel0WRS.identifier
                                      ][waterClass]
                                    )
                                  : ""}
                              </h4>
                            </CircleScale>
                          </div>
                          <p className="whitespace-nowrap pt-1">{waterClass}</p>
                        </div>
                      );
                    })
                : ""}
            </div>
          </div>
          <div className="p-4 border-r">
            {level1Resource?.level0WRSMembers?.length > 0 ? (
              <h3 className="text-xl pt-4">
                {t("level1wrs.volume_available")}
              </h3>
            ) : (
              <h3 className="text-base flex items-center justify-center 3xl:justify-start">
                {t("level1wrs.volume_available")}
              </h3>
            )}
            <div className="font-semibold text-3xl text-primary-2 pt-2">
              {level1Resource?.level0WRSMembers?.length > 0
                ? formatVolume(level1Resource?.volume)
                : ""}
            </div>
          </div>
          <div className="flex flex-col 3xl:flex-row justify-between border-r border-t border-gray-200 p-4 pr-0 3xl:items-center">
            <h3 className="text-base flex items-center justify-center 3xl:justify-start">
              {t("level1wrs.volume_used")}
            </h3>
            <div className="font-semibold text-lg text-primary-2 flex justify-center 3xl:justify-end w-full 3xl:w-auto">
              {level1Resource?.level0WRSMembers?.length > 0 ? (
                <div>
                  <div className="flex flex-col items-center xl:items-end text-2xl">
                    <span>
                      {formatPercentage(
                        level1Resource.usedVolume / level1Resource.volume
                      )}
                      <span className="text-sm pb-0.5 pl-0.5 3xl:pr-2">
                        ({formatVolume(level1Resource.usedVolume)})
                      </span>
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="flex flex-col 3xl:flex-row justify-between border-r border-t border-gray-200 p-4 pr-0 3xl:items-center">
            <h3 className="text-base flex items-center justify-center 3xl:justify-start">
              {t("level1wrs.volume_remain")}
            </h3>
            <div className="font-semibold text-lg text-primary-2 flex justify-center 3xl:justify-end w-full 3xl:w-auto">
              {level1Resource?.level0WRSMembers?.length > 0 ? (
                <div className="flex flex-col items-center xl:items-end text-2xl">
                  <span>
                    {formatPercentage(
                      level1Resource.remainingVolume / level1Resource.volume
                    )}
                    <span className="text-sm pb-0.5 pl-0.5 3xl:pr-2">
                      ({formatVolume(level1Resource.remainingVolume)})
                    </span>
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Level1ResourceWidget;

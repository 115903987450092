import React, { FunctionComponent, useState } from "react";
import classNames from "classnames";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import type { ColumnDef } from "@tanstack/react-table";

import IndeterminateCheckbox from "@components/form/IndeterminateCheckbox";
import SelectEventGroup from "@components/form/SelectEventGroup";
import SelectEventType from "@components/form/SelectEventType";
import SelectSubscriber from "@components/form/SelectSubscriber";
import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import AuditTrailDetailModal from "@components/modal/AuditTrailDetailModal";
import ActionsButton from "@components/shared/DataTable/ActionsButton";
import DataTableRenderer from "@components/shared/DataTable/DataTableRenderer";
import ExpandableCell from "@components/shared/DataTable/ExpandableCell";
import { usePaginationState } from "@components/shared/DataTable/Pagination";
import { getEventTransactions } from "@services/eventTransactions";
import { formatDateTime } from "@utils/formatDateTime";
import SelectFilter from "components/form/SelectFilter";

type EventTransaction = {
  id: string;
  eventGroup: string;
  eventType: string;
  description: string;
  status: string;
  ip: string;
  parentId?: string;
  timestamp: string;
};

type EventTransactionPaginatedTableProps = {
  stickyHeader?: boolean;
  className?: string;
  walletId?: string;
  references?: string[];
  eventGroups?: string[];
  eventTypes?: string[];
  level0ResourceId?: string;
  level1ResourceId?: string;
  subscriberId?: string;
  limit?: number;
  showEventFilters?: boolean;
  showDateFilters?: boolean;
  isModalView?: boolean;
};

const transformEventTransactions = (
  data: Record<string, any>,
): EventTransaction => {
  return {
    id: data.id,
    eventGroup: data.eventType?.eventGroup?.name,
    eventType: data.eventType?.name,
    description: data.description,
    status: Boolean(data.hcsId) ? "success" : "failed",
    ip: data.ip,
    parentId: data.parentId,
    timestamp: formatDateTime(new Date(data.createdAt)),
  };
};

const useEventTransactions = ({
  params,
  ...options
}: Record<string, any> = {}) => {
  return useQuery({
    queryKey: ["eventTransactions", "list", params],
    queryFn: () => getEventTransactions(params),
    select: res => {
      return {
        data: res.eventTransactions.map((item: any) => ({
          ...transformEventTransactions(item),
          childEventTransactions: item.childEventTransactions?.map(
            (child: any) => transformEventTransactions(child),
          ),
        })),
        meta: res.meta,
      };
    },
    ...options,
  });
};

const EventTransactionPaginatedTable: FunctionComponent<
  EventTransactionPaginatedTableProps
> = ({
  stickyHeader = true,
  walletId,
  eventGroups,
  eventTypes,
  level0ResourceId,
  level1ResourceId,
  subscriberId,
  references,
  className,
  showEventFilters = false,
  showDateFilters = false,
  isModalView = false,
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [pagination, setPagination] = usePaginationState();
  const [viewDetailId, setViewDetailId] = useState<string | null>(
    searchParams.get("id"),
  );
  const [selectedEventGroup, setSelectedEventGroup] = useState<string | null>(
    null,
  );
  const [selectEventType, setSelectEventType] = useState<string | null>(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedWalletId, setSelectedWalletId] = useState("");
  const [selectedFilter, setSelectedFilter] = useState<{
    value: string;
    label: string;
  } | null>(null);

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { data, isLoading, isFetching } = useEventTransactions({
    params: {
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      references,
      walletId: walletId ?? selectedWalletId,
      eventGroups: selectedEventGroup ? [selectedEventGroup] : eventGroups,
      eventTypes: selectEventType ? [selectEventType] : eventTypes,
      level0ResourceId,
      level1ResourceId,
      subscriberId,
      startDate,
      endDate,
      timeZone,
    },
  });

  const { refetch: fetchAllEventTractions, isFetching: isFetchingAll } =
    useEventTransactions({
      params: {
        references,
        walletId,
        eventGroups,
        eventTypes,
        startDate,
        endDate,
        limit: -1,
      },
      enabled: false,
    });

  const columns = React.useMemo<ColumnDef<EventTransaction>[]>(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllPageRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ table, row }) => {
          const selectedIds = table
            .getSelectedRowModel()
            .rows.map(row => row.id);

          return (
            <IndeterminateCheckbox
              {...{
                checked: selectedIds.includes(row.id),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          );
        },
      },
      {
        accessorKey: "eventGroup",
        header: t("audit_trail.group") as string,
        cell: context => <ExpandableCell {...context} />,
      },
      {
        accessorKey: "eventType",
        header: t("audit_trail.type") as string,
      },
      {
        accessorKey: "description",
        header: t("audit_trail.description") as string,
        cell: (info: any) => (
          <div
            className={classNames("max-w-sm", isModalView ? "" : "truncate")}
          >
            {info.getValue()}
          </div>
        ),
      },
      ...(isModalView
        ? []
        : [
            {
              accessorKey: "status",
              header: t("common.status") as string,
              cell: (info: any) => {
                const status = info.getValue() as string;
                const isSuccess = status === "success";
                const statuses = {
                  success: "text-green-400 bg-green-400/10",
                  offChain: "text-gray-400 bg-gray-400/10",
                };

                return (
                  <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                    <div
                      className={classNames(
                        statuses[isSuccess ? "success" : "offChain"],
                        "flex-none rounded-full p-1",
                      )}
                    >
                      <div className="h-1.5 w-1.5 rounded-full bg-current" />
                    </div>
                    <div>
                      {isSuccess ? t("common.success") : t("common.off_chain")}
                    </div>
                  </div>
                );
              },
            },
            {
              accessorKey: "timestamp",
              header: t("audit_trail.timestamp") as string,
            },
            {
              id: "actions",
              header: t("common.actions") as string,
              cell: ({ row }: any) => {
                return (
                  <ActionsButton
                    menus={[
                      {
                        label: t("common.view"),
                        onClick: () => setViewDetailId(row.id),
                      },
                    ]}
                  />
                );
              },
            },
          ]),
    ],
    [t, isModalView],
  );

  return (
    <>
      <section
        className={classNames(className, "flex flex-col flex-1 space-y-4")}
      >
        <div className="flex flex-col sm:flex-row gap-4 items-start relative z-30">
          {(showEventFilters || (!walletId && !subscriberId)) && (
            <>
              <div className="items-start gap-4 w-1/4">
                <Label htmlFor="filterSelector">
                  {t("audit_trail.select_filter")}
                </Label>
                <SelectFilter
                  id="filterSelector"
                  onChange={(option: any) => {
                    setSelectedFilter(option);
                    setSelectEventType(null);
                    setSelectedEventGroup(null);
                    setSelectedWalletId("");
                  }}
                  value={selectedFilter}
                  options={[
                    ...(showEventFilters
                      ? [
                          {
                            value: "eventGroup",
                            label: t("audit_trail.event_group"),
                          },
                          {
                            value: "eventType",
                            label: t("audit_trail.event_type"),
                          },
                        ]
                      : []),
                    ...(!walletId && !subscriberId
                      ? [
                          {
                            value: "subscriber",
                            label: t("audit_trail.subscriber"),
                          },
                        ]
                      : []),
                  ]}
                  isClearable
                />
              </div>
              <div className="items-start gap-4 w-1/4">
                {(selectedFilter?.value === "eventGroup" ||
                  !selectedFilter) && (
                  <>
                    <Label htmlFor="filterSelector">
                      {t("audit_trail.select_event_group")}
                    </Label>
                    <SelectEventGroup
                      onChange={option => {
                        setSelectedEventGroup(option?.label ?? "");
                        setPagination({ ...pagination, pageIndex: 0 });
                      }}
                      isClearable
                      disabled={selectedFilter?.value !== "eventGroup"}
                    />
                  </>
                )}
                {selectedFilter?.value === "eventType" && (
                  <>
                    <Label htmlFor="filterSelector">
                      {t("audit_trail.select_event_type")}
                    </Label>
                    <SelectEventType
                      onChange={option => {
                        setSelectEventType(option?.label ?? "");
                        setPagination({ ...pagination, pageIndex: 0 });
                      }}
                      isClearable
                    />
                  </>
                )}
                {selectedFilter?.value === "subscriber" && (
                  <>
                    <Label htmlFor="walletId">
                      {t("audit_trail.filter_subscriber")}
                    </Label>
                    <SelectSubscriber
                      id="walletId"
                      onChange={(option: any) => {
                        setSelectedWalletId(option ? option.walletId : null);
                        setPagination({ ...pagination, pageIndex: 0 });
                      }}
                      isClearable
                      showCustomerId
                    />
                  </>
                )}
              </div>
            </>
          )}

          {showDateFilters && (
            <>
              <div className="items-start gap-4 w-1/4">
                <Label htmlFor="startDate">{t("audit_trail.start_date")}</Label>
                <TextInput
                  type="date"
                  id="startDate"
                  value={startDate}
                  onChange={e => setStartDate(e.target.value ?? "")}
                />
              </div>
              <div className="items-start gap-4 w-1/4">
                <Label htmlFor="endDate">{t("audit_trail.end_date")}</Label>
                <TextInput
                  type="date"
                  id="endDate"
                  value={endDate}
                  onChange={e => setEndDate(e.target.value ?? "")}
                />
              </div>
            </>
          )}
        </div>
        <DataTableRenderer
          name="audit-trail"
          data={data?.data ?? []}
          columns={columns}
          pagination={pagination}
          pageCount={data?.meta?.lastPage ?? -1}
          onPaginationChange={setPagination}
          isLoading={isLoading}
          isFetching={isFetching || isFetchingAll}
          fetchAll={fetchAllEventTractions}
          columnsVisibility
          subRowsKey="childEventTransactions"
          stickyHeader={stickyHeader}
          transformExportData={({
            childEventTransactions: directChilds,
            ...parentRest
          }: any) => {
            if (directChilds?.length > 0) {
              const childEvents = directChilds.map(
                ({ childEventTransactions, ...childRest }: any) => {
                  return {
                    ...childRest,
                    parentId: parentRest.id,
                  };
                },
              );
              return [parentRest, ...childEvents];
            }
            return [parentRest];
          }}
        />
      </section>

      {viewDetailId ? (
        <AuditTrailDetailModal
          open={Boolean(viewDetailId)}
          id={viewDetailId}
          onClose={() => {
            setViewDetailId("");
          }}
        />
      ) : null}
    </>
  );
};

export default EventTransactionPaginatedTable;

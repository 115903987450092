import React from "react";

const HelpIcon: React.FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="31"
      fill="none"
      viewBox="0 0 30 31"
    >
      <path
        stroke="#004A88"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10.285 11.75c.686-1.457 2.538-2.5 4.715-2.5 2.762 0 5 1.679 5 3.75 0 1.75-1.597 3.219-3.757 3.633C15.565 16.763 15 17.31 15 18m0 3.75h.012M26.25 15.5c0 6.213-5.037 11.25-11.25 11.25S3.75 21.713 3.75 15.5 8.787 4.25 15 4.25 26.25 9.287 26.25 15.5z"
      ></path>
    </svg>
  );
};

export default HelpIcon;

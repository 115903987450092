import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { getAllClassCategories } from "@services/classCategory";

interface Params extends UseQueryOptions {
  params?: Record<string, any>;
}

export const useAllClassCategories = ({ params, ...options }: Params = {}) => {
  return useQuery<any>({
    queryKey: ["classCategories", params],
    queryFn: () => getAllClassCategories(params),
    ...options,
  });
};

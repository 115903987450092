import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Layout from "@components/layout/Layout";
import CreateWaterClassWorkflow from "@components/form/water_classes/create/CreateWaterClassWorkflow";
import {
  WaterClassProvider,
  useWaterClassContext,
} from "@context/WaterClassContext";
import { EvidenceProvider } from "@context/shared/EvidenceContext";
import { useLevel0Resource } from "@hooks/query/useLevel0Resource";

const CreateWaterClass = () => {
  return (
    <EvidenceProvider>
      <WaterClassProvider>
        <Main />
      </WaterClassProvider>
    </EvidenceProvider>
  );
};

const Main = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { level1Resource, details, setDetails } = useWaterClassContext();

  const level0ResourceId = searchParams.get("level0ResourceId") || "";
  useLevel0Resource(level0ResourceId, {
    onSuccess: (res: any) => {
      if (!details.level0ResourceId) {
        setDetails((prev: any) => ({
          ...prev,
          level0ResourceId: res.id,
        }));
      }
    },
  });

  return (
    <Layout
      permissions={["CreateWaterClasses"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: level1Resource?.name,
          href: `/polestar/level1wrs/${level1Resource?.id}#0`,
        },
        {
          label: t("level1wrs.zone_and_classes"),
          href: `/polestar/level1wrs/${level1Resource?.id}#1`,
        },
        {
          label: t("water_class.create.title"),
        },
      ]}
      title={t("water_class.create.title")}
      accountingPeriodLevel1ResourceId={level1Resource?.id}
    >
      <CreateWaterClassWorkflow />
    </Layout>
  );
};

export default CreateWaterClass;

import React from "react";
import { useTranslation } from "react-i18next";

import SelectLevel0Resource from "@components/form/SelectLevel0Resource";
import ConfirmModal from "@components/shared/ConfirmModal";
import { linkLevel0Resource } from "@services/subscriber";
import { toastError, toastSuccess } from "@utils/toast";

type AddLevel0ResourceProps = {
  subscriberId: string;
  level1ResourceId: string;
  excludeIds: string[];
  onSuccess: () => void;
};

const AddLevel0Resource: React.FunctionComponent<AddLevel0ResourceProps> = ({
  subscriberId,
  level1ResourceId,
  excludeIds,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const [level0Resource, setLevel0Resource] = React.useState({
    id: "",
    identifier: "",
  });
  const [isLoading, setIsLoading] = React.useState(false);

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      await linkLevel0Resource({
        id: subscriberId,
        level0ResourceId: level0Resource.id,
      });
      toastSuccess(
        t("subscriber.link_level0_resource.success", {
          level0ResourceIdentifier: level0Resource.identifier,
        })
      );
      onSuccess();
    } catch (err) {
      toastError(t("shared.toast.error"));
    }

    setIsLoading(false);
    onClose();
  };

  const onClose = () => {
    setLevel0Resource({
      id: "",
      identifier: "",
    });
  };

  return (
    <div className="font-normal text-gray-900">
      <SelectLevel0Resource
        className="whitespace-nowrap w-52"
        level1ResourceId={level1ResourceId}
        placeholder={t("subscriber.link_level0_resource.title")}
        onChange={(e) => {
          setLevel0Resource({
            id: e?.value ?? "",
            identifier: e?.label ?? "",
          });
        }}
        excludeIds={excludeIds}
      />
      <ConfirmModal
        open={Boolean(level0Resource.id)}
        onConfirm={handleConfirm}
        onClose={onClose}
        isSubmitting={isLoading}
      >
        {t("subscriber.link_level0_resource.confirm", {
          level0ResourceIdentifier: level0Resource.identifier,
        })}
      </ConfirmModal>
    </div>
  );
};

export default AddLevel0Resource;

import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import DropzoneBox from "@components/shared/DropzoneBox";
import { useEvidenceContext } from "@context/shared/EvidenceContext";

type Props = {
  onNext: () => void;
  onPrevious?: () => void;
  onCancel: () => void;
};

const UploadEvidencesForm: React.FunctionComponent<Props> = ({
  onNext,
  onPrevious,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { evidences, getRootProps, getInputProps, handleDeleteFile } =
    useEvidenceContext();

  return (
    <div className="flex flex-col grow p-6">
      <Heading light className="mb-8">
        {t("evidence.upload")}
      </Heading>

      <DropzoneBox
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        files={evidences}
        onDelete={handleDeleteFile}
      />
      <div className="grow" />

      <footer className="flex gap-4 -mx-6 mt-6 p-6 pb-0 border-t border-gray-200">
        {onPrevious ? (
          <button
            type="button"
            className="btn-outline-primary"
            onClick={onPrevious}
          >
            {t("common.prev_step")}
          </button>
        ) : null}
        <button type="button" className="btn-primary" onClick={onNext}>
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </div>
  );
};

export default UploadEvidencesForm;

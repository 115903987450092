import Select from "react-select";
import { t } from "i18next";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import ErrorMessage from "@components/form/ErrorMessage";
import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import { useAllPublicLevel1Resources } from "@hooks/query/zenith/useAllPublicLevel1Resources";

import BaseSignUpForm from "./BaseSignUpForm";

const schema = z.object({
  level1ResourceName: z.string().min(1, t("common.required.text") as string),
  name: z.string().min(1, t("common.required.text") as string),
  abn: z.string().optional(),
});

type FormValues = z.infer<typeof schema>;

const defaultValues: FormValues = {
  level1ResourceName: "",
  name: "",
  abn: "",
};

type SignUpSubscriberFormProps = {
  onSubmit: (values: FormValues) => void;
  values: FormValues;
};

const SignUpSubscriberForm: React.FunctionComponent<
  SignUpSubscriberFormProps
> = ({ onSubmit, values }) => {
  const { data: level1ResourceOptions = [] } = useAllPublicLevel1Resources({
    select: (data: { name: string }[]) =>
      data.map(({ name }) => ({
        label: name,
        value: name,
      })),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: Object.assign({}, defaultValues, values),
  });

  return (
    <BaseSignUpForm
      title={t("zenith.signup.enter_personal_details")}
      step={0}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    >
      <div>
        <Label htmlFor="level1ResourceName">
          {t("exchange_rate.select_level1wrs")}
        </Label>
        <Controller
          name="level1ResourceName"
          control={control}
          render={({ field: { onChange, value, name }, fieldState }) => (
            <>
              <Select
                onChange={({ value }: any) => onChange(value)}
                value={level1ResourceOptions.find(
                  (i: any) => i.value === value
                )}
                options={level1ResourceOptions}
                name={name}
                className="text-sm"
              />
              {fieldState.error?.message ? (
                <ErrorMessage>{fieldState.error?.message}</ErrorMessage>
              ) : null}
            </>
          )}
        />
      </div>
      <div>
        <Label htmlFor="name">{t("subscriber.create.subscriber_name")}</Label>
        <TextInput
          {...register("name")}
          id="name"
          errorMessage={errors?.name?.message as any}
        />
      </div>
      <div>
        <Label htmlFor="abn" optional>
          {t("subscriber.abn")}
        </Label>
        <TextInput
          {...register("abn")}
          id="abn"
          errorMessage={errors?.abn?.message as any}
        />
      </div>
    </BaseSignUpForm>
  );
};

export default SignUpSubscriberForm;

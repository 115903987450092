import { useNavigate } from "react-router-dom";

const HandleGoBackOrClose = () => {
  const navigate = useNavigate();

  const handleGoBackOrClose = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      window.close();
    }
  };

  return handleGoBackOrClose;
};

export default HandleGoBackOrClose;

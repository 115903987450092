import { useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import TabPanels from "@components/form/TabPanels";
import InfoPanel from "@components/form/InfoPanel";
import Level0ResourceTable from "@components/table/Level0ResourceTable";
import WaterClassTable from "@components/table/WaterClassTable";
import ExchangeRateTable from "@components/table/ExchangeRateTable";
import { useAppContext } from "@context/AppContext";

type Props = {
  level1Resource: any;
};

const Level1WRSZoneAndClasses: React.FunctionComponent<Props> = ({
  level1Resource,
}) => {
  const { checkPermissions } = useAppContext();
  const { t } = useTranslation();
  const { id: level1ResourceId = "" } = useParams();

  return (
    <div className="flex grow gap-4">
      <div className="grow">
        <TabPanels
          className="bg-white border"
          tabs={[
            {
              title: t("common.level0wrs"),
              panel: (
                <Level0ResourceTable level1ResourceId={level1ResourceId} />
              ),
              tabInfoPanel: (
                <InfoPanel className="h-full">
                  <ul className="flex flex-col gap-5 list-disc list-outside">
                    <li>
                      <Trans
                        i18nKey="level0wrs.info.zone.dashboard"
                        values={{
                          level1ResourceName: level1Resource?.name,
                        }}
                      />
                    </li>
                    <li>{t("level0wrs.info.zone.update")}</li>
                    <li>{t("level0wrs.info.zone.transfer")}</li>
                  </ul>
                </InfoPanel>
              ),
            },
            ...(checkPermissions(["ViewWaterClasses"])
              ? [
                  {
                    title: t("common.classes"),
                    panel: (
                      <WaterClassTable level1ResourceId={level1ResourceId} />
                    ),
                    tabInfoPanel: (
                      <InfoPanel className="h-full">
                        <ul className="flex flex-col gap-5 list-disc list-outside">
                          <li>
                            <Trans
                              i18nKey="level0wrs.info.priority.dashboard"
                              values={{
                                level1ResourceName: level1Resource?.name,
                              }}
                            />
                          </li>
                          <li>{t("level0wrs.info.priority.update")}</li>
                          <li>{t("level0wrs.info.priority.transfer")}</li>
                          <li>{t("level0wrs.info.priority.illustration")}</li>
                        </ul>
                      </InfoPanel>
                    ),
                  },
                ]
              : []),
            ...(checkPermissions(["ViewAllocationAnnouncement"])
              ? [
                  {
                    title: t("common.allocations"),
                    panel: (
                      <ExchangeRateTable level1Resource={level1Resource} />
                    ),
                    tabInfoPanel: (
                      <InfoPanel className="h-full">
                        <ul className="flex flex-col gap-5 list-disc list-outside">
                          <li>
                            <Trans
                              i18nKey="exchange_rate.info.intro"
                              values={{
                                level1ResourceName: level1Resource?.name,
                              }}
                            />
                          </li>
                          <li>{t("exchange_rate.info.body")}</li>
                          <li>{t("exchange_rate.info.conc")}</li>
                          <li>{t("exchange_rate.info.exchange_rate")}</li>
                        </ul>
                      </InfoPanel>
                    ),
                  },
                ]
              : []),
          ]}
        />
      </div>
    </div>
  );
};

export default Level1WRSZoneAndClasses;

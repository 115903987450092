import EndButtonModal from "@components/shared/EndButton";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import Heading from "../../layout/Heading";
import InfoPanel from "../InfoPanel";
import EventTransactionPaginatedTable from "@components/table/EventTransactionPaginatedTable";

type CreateEditBillingGroupCompletedProps = {
  billingGroup?: {
    id?: string;
    name: string;
    level1Resource: any;
  };
  workflowInstance?: any;
};

const CreateEditBillingGroupCompleted: React.FunctionComponent<
  CreateEditBillingGroupCompletedProps
> = ({ billingGroup, workflowInstance }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const waterChargeData = sessionStorage.getItem("waterCharge");

    if (waterChargeData && billingGroup) {
      try {
        const waterCharge = JSON.parse(waterChargeData);

        sessionStorage.setItem(
          "waterCharge",
          JSON.stringify({
            ...waterCharge,
            billingGroup,
            billingGroupId: billingGroup.id,
          })
        );
      } catch {
        sessionStorage.removeItem("waterCharge");
      }
    }
  }, [billingGroup]);

  return (
    <div className="flex gap-4 h-full">
      <div className="w-3/4 border bg-white h-auto">
        <form className="flex flex-col h-full justify-between">
          <div className="p-6 grow">
            <Heading className="text-xl leading-6" light={true}>
              {t("billing_groups.workflow_completed")}
            </Heading>
            <div className="flex flex-col grow h-full">
              <EventTransactionPaginatedTable
                references={[workflowInstance?.id]}
                stickyHeader={true}
              />
            </div>
          </div>
          <div className="border-t w-full border-gray-200 mt-5 p-6 flex gap-1">
            <EndButtonModal />
          </div>
        </form>
      </div>
      <div className="w-1/4 h-auto">
        <InfoPanel
          className="h-full"
          successes={[
            t("billing_groups.success_billing_group_workflow_completed", {
              name: billingGroup?.name,
            }),
          ]}
        >
          {t("billing_groups.success_billing_group_workflow_completed_info")}
        </InfoPanel>
      </div>
    </div>
  );
};

export default CreateEditBillingGroupCompleted;

import React from "react";
import classNames from "classnames";
import { noop } from "lodash";
import { useTranslation } from "react-i18next";

import SpinnerIcon from "@components/icons/SpinnerIcon";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import { downloadAsCSV } from "@utils/downloadAsCSV";
import { toastSuccess, toastError } from "@utils/toast";

type ExportAllRowsButtonProps = {
  fileName: string;
  fetch: any;
  dataKey: string;
  transformExportData?: (data: any) => any;
};

const ExportAllRowsButton: React.FunctionComponent<
  ExportAllRowsButtonProps
> = ({ fileName, fetch, dataKey, transformExportData = noop }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    try {
      const { data } = await fetch();
      if (data[dataKey]?.length) {
        downloadAsCSV({
          data: data[dataKey].flatMap(transformExportData) ?? [],
          fileName,
        });
        toastSuccess(t("shared.table.toast.export.success"));
      } else {
        throw new Error(`Cannot find ${dataKey} prop from fetched data`);
      }
    } catch (error: any) {
      toastError(
        t("shared.table.toast.export.failed", {
          error: error?.response?.data?.message ?? error?.message,
        })
      );
    }
    setIsLoading(false);
  };

  return (
    <button
      type="button"
      className={classNames(
        "btn-secondary inline-flex gap-1 items-center text-sm rounded",
        isLoading ? "opacity-50 cursor-progress" : "text-white"
      )}
      disabled={isLoading}
      onClick={handleClick}
    >
      {isLoading ? (
        <SpinnerIcon className="w-5 h-5" />
      ) : (
        <ArrowDownTrayIcon className="w-5 h-5" />
      )}
      {t("shared.table.export_all")}
    </button>
  );
};

export default ExportAllRowsButton;

import { t as translate } from "i18next";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import RadioInput from "@components/form/RadioInput";
import { useSeasonalTransferContext } from "@context/SeasonalTransferContext";

const zeroValueTradeReason: Array<{ [key: string]: string }> = [
  {
    A: translate(
      "approval.seasonal_water_assignments.create.step_5.price_option_a",
    ),
  },
  {
    B: translate(
      "approval.seasonal_water_assignments.create.step_5.price_option_b",
    ),
  },
  {
    C: translate(
      "approval.seasonal_water_assignments.create.step_5.price_option_c",
    ),
  },
  {
    D: translate(
      "approval.seasonal_water_assignments.create.step_5.price_option_d",
    ),
  },
  {
    E: translate(
      "approval.seasonal_water_assignments.create.step_5.price_option_e",
    ),
  },
];

const SeasonalTransferPrice = () => {
  const { t } = useTranslation();
  const {
    stepHelpers,
    details,
    handleChangeDetails,
    navigateForCancel,
    validatePrice,
    isSameAccount,
  } = useSeasonalTransferContext();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    stepHelpers.goToNextStep();
  };

  return (
    <form
      className="flex flex-col p-6 grow justify-between"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col grow gap-6">
        <Heading light>
          {t("approval.seasonal_water_assignments.create.step_5.title")}
        </Heading>

        <fieldset className="space-y-6">
          {!isSameAccount && (
            <>
              <div className="max-w-xs">
                <Label htmlFor="price">
                  {t(
                    "approval.seasonal_water_assignments.create.step_5.trade_value",
                  )}
                </Label>
                <TextInput
                  type="number"
                  className="w-96"
                  min={0}
                  step="0.01"
                  name="price"
                  id="price"
                  value={details.price}
                  onChange={(e) => {
                    validatePrice(+e.target.value);
                    handleChangeDetails("price", e.target.value);
                    handleChangeDetails("description", "");
                  }}
                  suffix={t("common.currency")}
                  placeholder="0"
                />
              </div>

              {+details.price === 0 && (
                <div>
                  <Label htmlFor="price">
                    {t(
                      "approval.seasonal_water_assignments.create.step_5.reason_zero_trade",
                    )}
                  </Label>
                  <div className="space-y-3">
                    {zeroValueTradeReason.map((value) => {
                      const key = Object.keys(value)[0];
                      return (
                        <RadioInput
                          htmlFor="zeroValueTradeReason"
                          label={`${key}: ${value[key]}`}
                          checked={details.description === value[key]}
                          onChange={(e) => {
                            validatePrice(+e.target.value);
                            handleChangeDetails("description", value[key]);
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </>
          )}
          <div>
            <Label htmlFor="price">
              {t("approval.seasonal_water_assignments.create.step_5.usage_in")}
            </Label>
            <div className="space-y-3">
              {["Irrigation", "Non-irrigation"].map((value) => {
                return (
                  <RadioInput
                    htmlFor="zeroValueTradeReason"
                    label={value}
                    checked={details.usageIn === value}
                    onChange={() => {
                      handleChangeDetails("usageIn", value);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </fieldset>
      </div>

      <footer className="flex gap-4 -mx-6 mt-6 p-6 pb-0 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={() => {
            stepHelpers.goToPrevStep();

            if (!isSameAccount) {
              stepHelpers.goToPrevStep();
            }
          }}
        >
          {t("common.prev_step")}
        </button>

        <button
          type="submit"
          className="btn-primary"
          disabled={
            (details.price === "" || +details.price <= 0) &&
            details.description === ""
          }
        >
          {t("common.next_step")}
        </button>

        <button
          type="button"
          className="btn-outline-primary"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default SeasonalTransferPrice;

import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { getSubscriber } from "@services/subscriber";

export function useSubscriber(id: string, options?: UseQueryOptions) {
  return useQuery<any>({
    queryKey: ["subscribers", id],
    queryFn: () => getSubscriber(id),
    enabled: !!id,
    ...options,
  });
}

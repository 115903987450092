import React, { FormEvent } from "react";
import OtpInput from "react18-input-otp";
import { useLocation } from "react-router-dom";
import { t } from "i18next";

import AuthLayout from "@components/layout/AuthLayout";
import Label from "@components/form/Label";
import { useConfirmSignUp } from "@hooks/mutation/zenith/useConfirmSignUp";
import { toastError, toastSuccess } from "@utils/toast";
import { useResendConfirmSignUpCode } from "@hooks/mutation/zenith/useResendConfirmSignUpCode";
import ErrorMessage from "@components/form/ErrorMessage";

const ConfirmSignUp = () => {
  const { state = {} } = useLocation();
  const email = state?.email;
  const [code, setCode] = React.useState("");

  const {
    mutateAsync: confirmSignUpMutation,
    isLoading: isSubmitting,
    isError,
    error,
  } = useConfirmSignUp();

  const { mutateAsync: resendMutation } = useResendConfirmSignUpCode();
  const goToLogin = () => {
    window.location.href = "/zenith/login";
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await confirmSignUpMutation({
        email,
        confirmationCode: code,
      });
      goToLogin();
      toastSuccess(t("zenith.confirm_signup.toast.success"));
    } catch (error) {
      toastError(t("zenith.confirm_signup.toast.failure"));
    }
  };

  const resendCode = async () => {
    try {
      await resendMutation({
        email,
      });
      toastSuccess(t("zenith.confirm_signup.resend.toast.success"));
    } catch (error: any) {
      toastError(
        t("zenith.confirm_signup.resend.toast.failure", {
          error: error?.response?.data?.message || error?.message,
        })
      );
    }
  };

  const LENGTH = 6;
  const isValidCode = code.length === LENGTH;

  if (!email) {
    goToLogin();
    return null;
  }

  const errorMessage = error?.response?.data?.message;

  return (
    <AuthLayout>
      <div className="flex min-h-full flex-col justify-center bg-primary-2 rounded-lg">
        <h1 className="sm:mx-auto w-full p-8 text-white text-3xl font-bold text-center leading-normal">
          {t("zenith.confirm_signup.title")}
        </h1>

        <div className="sm:mx-auto w-full bg-white shadow sm:rounded-lg py-8 px-4 sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <Label>{t("zenith.confirm_signup.enter_code")}</Label>
              <OtpInput
                value={code}
                onChange={(value: any) => setCode(value)}
                numInputs={LENGTH}
                inputStyle="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none text-5xl 2xl:text-6xl text-center"
                containerStyle="w-full flex gap-4 justify-center mt-3"
                errorStyle="border-red-300"
                isInputNum={true}
                hasErrored={isError}
                onSubmit={handleSubmit}
                isDisabled={isSubmitting}
              />
              {errorMessage ? (
                <ErrorMessage>{errorMessage}</ErrorMessage>
              ) : null}
            </div>

            <button
              type="button"
              className="text-primary-3 hover:text-primary-2 text-sm hover:underline"
              onClick={resendCode}
              disabled={isSubmitting}
            >
              {t("auth.resend_code")}
            </button>

            <button
              type="submit"
              className="btn-primary text-sm w-full rounded"
              disabled={!isValidCode || isSubmitting}
            >
              {t("auth.verify_code")}
            </button>
          </form>
        </div>
      </div>
    </AuthLayout>
  );
};

export default ConfirmSignUp;

import api from "./api";

export const getWaterClass = async (id: string): Promise<any> => {
  const { data } = await api.get(`admin/waterClasses/${id}`);
  return data.waterClass;
};

export type GetAllWaterClasses = {
  level0ResourceId: string;
  name: string;
};

export const getAllWaterClasses = async (
  params?: Partial<GetAllWaterClasses>
): Promise<any> => {
  const { data } = await api.get("/admin/waterClasses", { params });

  return data.waterClasses;
};

export const createWaterClass = async (payload: any) => {
  const { data } = await api.post(`/admin/waterClasses`, payload);
  return [data.waterClass, data.workflowInstance];
};

export const updateWaterClass = async ({
  waterClassId,
  ...payload
}: {
  waterClassId: string;
  classCategoryId?: string;
  name?: string;
  acctPriority?: number;
  volumeNextAccountingPeriod?: number;
}) => {
  const { data } = await api.put(
    `/admin/waterClasses/${waterClassId}`,
    payload
  );

  return [data.waterClass, data.workflowInstance];
};

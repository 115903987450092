import classNames from "classnames";

import Circle from "@components/shared/Circle";
import OnChain from "@components/shared/OnChain";

type Props = {
  index: number;
  currentStep: number;
  children?: React.ReactNode;
  info?: React.ReactNode;
  isOnChain?: boolean;
  forceSuccess?: boolean;
};

const StepButton: React.FunctionComponent<Props> = ({
  index,
  currentStep,
  children,
  info,
  isOnChain = false,
  forceSuccess = false,
}) => {
  const isComplete = currentStep > index;

  return (
    <div
      className={classNames(
        "flex gap-2 items-start justify-start w-full text-left",
        {
          "text-green-600": forceSuccess || isComplete,
        }
      )}
    >
      <Circle
        className="shrink-0"
        isActive={currentStep === index}
        isSuccess={forceSuccess || isComplete}
      >
        {index + 1}
      </Circle>
      <div className="space-y-2">
        {children}
        {isOnChain ? <OnChain /> : null}
        {isComplete || forceSuccess ? (
          <div className="text-sm text-gray-600">{info}</div>
        ) : null}
      </div>
    </div>
  );
};

export default StepButton;

import api from "./api";

export const getBillingGroups = async (
  params?: Record<string, any>
): Promise<any> => {
  const { data } = await api.get("/admin/billingGroups", { params });

  return data.billingGroups;
};

export const getBillingGroup = async (id: string): Promise<any> => {
  const { data } = await api.get(`/admin/billingGroups/${id}`);

  return data.billingGroup;
};

export const createBillingGroup = async (billingGroupData: {
  name: string;
  level1ResourceId: string;
}) => {
  const { data } = await api.post("/admin/billingGroups", billingGroupData);

  return [data.billingGroup, data.workflowInstance];
};
export const updateBillingGroup = async (billingGroupData: {
  id: string;
  name: string;
  level1ResourceId: string;
}) => {
  const { data } = await api.put(
    `/admin/billingGroups/${billingGroupData.id}`,
    billingGroupData
  );

  return [data.billingGroup, data.workflowInstance];
};

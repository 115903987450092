import { useMutation } from "@tanstack/react-query";

import {
  ResetAccountingPeriodPayload,
  resetAccountingPeriod,
} from "@services/accountingPeriodChecks";

export const useResetAccountingPeriod = (options: Record<string, any> = {}) => {
  return useMutation<any, any, ResetAccountingPeriodPayload>({
    mutationKey: ["reset_accounting_period"],
    mutationFn: resetAccountingPeriod,
    ...options,
  });
};

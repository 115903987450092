import { useTranslation } from "react-i18next";
import React from "react";
import { formatDate } from "../../../utils/formatDate";
import { formatPercentage } from "../../../utils/formatPercentage";
import EditIcon from "../../icons/EditIcon";
import Heading from "../../layout/Heading";

type CreateExchangeRateStep3Props = {
  level1Resource: any;
  level0Resources: any;
  rate: number;
  startAt: Date;
  disabled?: boolean;
  onSave: () => void;
  onEditZones: () => void;
  onEditRate: () => void;
};

const CreateExchangeRateStep3: React.FunctionComponent<
  CreateExchangeRateStep3Props
> = ({
  level1Resource,
  level0Resources,
  rate,
  startAt,
  disabled,
  onSave,
  onEditZones,
  onEditRate,
}) => {
  const { t } = useTranslation();

  const handleSave = (e: any) => {
    e.preventDefault();

    onSave();
  };

  return (
    <form className="space-y-8" onSubmit={handleSave}>
      <div className="p-6">
        <div>
          <Heading className="text-xl leading-6" light={true}>
            {t("exchange_rate.confirm_values")}
          </Heading>
        </div>
        <div className="mt-6 flex flex-col gap-2">
          <dl className="grid grid-cols-1 gap-x-4 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-bold text-gray-500">
                {t("common.level1wrs")}:
              </dt>
              <dd className="mt-1 text-sm text-gray-500">
                {level1Resource.name}
              </dd>
            </div>
          </dl>
          <dl className="grid grid-cols-1 gap-x-4 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-bold text-gray-500">
                {t("common.level0wrs")} {t("common.and")}{" "}
                {t("common.water_classes")}:
              </dt>
              {level0Resources.map((zone: any) => (
                <dd
                  key={zone.identifier}
                  className="mt-1 text-sm text-gray-500"
                >
                  {zone.identifier} -{" "}
                  {zone.selectedWaterClasses
                    .map((c: any) => c.name)
                    .join(" / ")}
                </dd>
              ))}
            </div>
            <button
              type="button"
              className="sm:col-span-2 text-left flex gap-1 items-center font-bold text-sm text-primary-3"
              onClick={onEditZones}
            >
              <EditIcon /> {t("common.edit")}
            </button>
          </dl>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 mt-4">
            <div className="sm:col-span-1">
              <dt className="text-sm font-bold text-gray-500">
                {t("exchange_rate.rate")}:
              </dt>
              <dd className="mt-1 text-sm text-gray-500">
                {formatPercentage(rate)}
              </dd>
            </div>
          </dl>
          <dl className="grid grid-cols-1 gap-x-4 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-bold text-gray-500">
                {t("exchange_rate.start_at")}:
              </dt>
              <dd className="mt-1 text-sm text-gray-500">
                {formatDate(startAt)}
              </dd>
            </div>
            <button
              type="button"
              className="sm:col-span-2 text-left flex gap-1 items-center font-bold text-sm text-primary-3"
              onClick={onEditRate}
            >
              <EditIcon /> {t("common.edit")}
            </button>
          </dl>
        </div>
      </div>
      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={onEditRate}
        >
          {t("common.prev_step")}
        </button>
        <button type="submit" className="btn-primary" disabled={disabled}>
          {t("common.next_step")}
        </button>
      </footer>
    </form>
  );
};

export default CreateExchangeRateStep3;

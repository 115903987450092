import React from "react";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import ConfirmModal from "@components/shared/ConfirmModal";
import ConfirmationDetail from "@components/shared/ConfirmationDetail";
import WorkflowComplete from "@components/shared/WorkflowComplete";
import { toastError, toastSuccess } from "@utils/toast";
import { isValidationError } from "@utils/formError";

type Props = {
  data: any;
  workflowInstance: any;
  isComplete: boolean;
  onSubmit: any;
  onPrevious: () => void;
  onEdit: (step: number) => void;
  onCancel: () => void;
};

const ConfirmSubscriber: React.FunctionComponent<Props> = ({
  isComplete,
  onSubmit,
  onPrevious,
  onEdit,
  onCancel,
  workflowInstance,
  data,
}) => {
  const { t } = useTranslation();
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowConfirmModal(true);
  };

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      await onSubmit();
      toastSuccess(t("subscriber.toast.success"));
    } catch (error: any) {
      const toastText = t("subscriber.toast.failure");
      if (isValidationError(error)) {
        const { errors } = error.response.data;
        const messages = errors.map((i: any) => i.message);
        toastError(
          <>
            <p>{toastText}</p>
            <ul className="list-disc pl-4">
              {messages.map((text: any) => {
                return <li key={text}>{text}</li>;
              })}
            </ul>
          </>
        );
      } else {
        toastError(toastText);
      }
    }
    setIsLoading(false);
    setShowConfirmModal(false);
  };

  if (isComplete) {
    return (
      <>
        <WorkflowComplete references={[workflowInstance?.id]} />
      </>
    );
  }

  return (
    <>
      <div className="flex flex-col gap-4 grow">
        <div className="p-6 space-y-4">
          <Heading light>{t("subscriber.create.confirm_subscriber")}</Heading>
          <ConfirmationDetail data={data} onEdit={onEdit} />
        </div>

        <div className="grow" />

        <footer className="flex gap-4 p-6 border-t border-gray-200">
          <button
            type="button"
            className="btn-outline-primary"
            onClick={onPrevious}
          >
            {t("common.prev_step")}
          </button>
          <button type="button" className="btn-primary" onClick={handleSubmit}>
            {t("common.ledger")}
          </button>
          <button
            type="button"
            className="btn-outline-primary"
            onClick={onCancel}
          >
            {t("common.cancel")}
          </button>
        </footer>
      </div>

      <ConfirmModal
        open={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
        }}
        onConfirm={handleConfirm}
        isSubmitting={isLoading}
      >
        {t("subscriber.modal.confirm")}
      </ConfirmModal>
    </>
  );
};

export default ConfirmSubscriber;

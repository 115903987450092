import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import Card from "@components/layout/Card";
import Alert from "@components/shared/Alert";
import { useAppContext } from "@context/AppContext";
import { zodResolver } from "@hookform/resolvers/zod";
import { updateCurrentUserSubscriber } from "@services/user";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FunctionComponent, createElement, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import DropDownMenu from "../DropDownMenu";
import { downloadAsCSV } from "@utils/downloadAsCSV";
import ReactDOMServer from "react-dom/server";
import { downloadAsPDF } from "@utils/downloadAsPDF";
import Modal from "@components/layout/Modal";
import ProcessingIcon from "@components/icons/ProcessingIcon";
import Heading from "@components/layout/Heading";
import UserSubscriberPrint from "./UserSubscriberPrint";

const UserSubscriberForm: FunctionComponent = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const {
    loggedInInfo: { userDetails },
  } = useAppContext();
  const [isExporting, setIsExporting] = useState(false);

  const subscriberSchema = z.object({
    name: z.string().min(1, t("common.required.text") as string),
    abn: z.string().min(1, t("common.required.text") as string),
    street: z.string().optional(),
    town: z.string().optional(),
    region: z.string().min(1, t("common.required.text") as string),
    country: z.string().min(1, t("common.required.text") as string),
    postCode: z.string().min(1, t("common.required.text") as string),
  });

  const {
    mutateAsync: updateCurrentUserSubscriberMutation,
    isLoading,
    isSuccess,
    isError,
  } = useMutation(updateCurrentUserSubscriber);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof subscriberSchema>>({
    resolver: zodResolver(subscriberSchema),
    values: {
      name: userDetails?.subscriber?.name || "",
      abn: userDetails?.subscriber?.abn || "",
      street: userDetails?.subscriber?.address?.street || "",
      town: userDetails?.subscriber?.address?.town || "",
      region: userDetails?.subscriber?.address?.region || "",
      country: userDetails?.subscriber?.address?.country || "",
      postCode: userDetails?.subscriber?.address?.postcode || "",
    },
  });

  const onSubmit = async (data: any) => {
    if (!userDetails?.isPrimary) return;

    await updateCurrentUserSubscriberMutation(data);

    queryClient.invalidateQueries(["refreshToken"]);
  };

  const exportCSV = () => {
    setIsExporting(true);

    downloadAsCSV({
      data: [
        {
          [t("profile.subscriber.name_field")]: userDetails?.subscriber?.name,
          [t("profile.subscriber.abn_field")]: userDetails?.subscriber?.abn,
          [t("profile.subscriber.street_field")]:
            userDetails?.subscriber?.address?.street,
          [t("profile.subscriber.town_field")]:
            userDetails?.subscriber?.address?.town,
          [t("profile.subscriber.region_field")]:
            userDetails?.subscriber?.address?.region,
          [t("profile.subscriber.country_field")]:
            userDetails?.subscriber?.address?.country,
          [t("profile.subscriber.postcode_field")]:
            userDetails?.subscriber?.address?.postcode,
        },
      ],
      fileName: `${userDetails?.subscriber?.name}-${Date.now().toString()}`,
    });

    setIsExporting(false);
  };

  const exportPDF = async () => {
    setIsExporting(true);

    const element = createElement(UserSubscriberPrint, {
      subscriber: userDetails?.subscriber!,
    });
    const printElement = ReactDOMServer.renderToString(element);
    await downloadAsPDF({
      data: printElement,
      fileName: `${userDetails?.subscriber?.name}-${Date.now().toString()}`,
    });

    setIsExporting(false);
  };

  return (
    <Card>
      {!userDetails?.isPrimary && (
        <Alert type="info">{t("profile.subscriber.not_primary")}</Alert>
      )}
      {isSuccess && (
        <Alert type="success">{t("profile.subscriber.saved_success")}</Alert>
      )}
      {isError && (
        <Alert type="error">{t("profile.subscriber.saved_failure")}</Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full lg:w-1/3">
          <div>
            <Label htmlFor="name">{t("profile.subscriber.name_field")}</Label>
            <TextInput
              {...register("name")}
              errorMessage={errors.name?.message}
              disabled={!userDetails?.isPrimary}
            />
          </div>
          <div>
            <Label htmlFor="abn">{t("profile.subscriber.abn_field")}</Label>
            <TextInput
              {...register("abn")}
              errorMessage={errors.abn?.message}
              disabled={!userDetails?.isPrimary}
            />
          </div>
          <div>
            <Label htmlFor="street">
              {t("profile.subscriber.street_field")}
            </Label>
            <TextInput
              {...register("street")}
              errorMessage={errors.street?.message}
              disabled={!userDetails?.isPrimary}
            />
          </div>
          <div>
            <Label htmlFor="town">{t("profile.subscriber.town_field")}</Label>
            <TextInput
              {...register("town")}
              errorMessage={errors.town?.message}
              disabled={!userDetails?.isPrimary}
            />
          </div>
          <div>
            <Label htmlFor="region">
              {t("profile.subscriber.region_field")}
            </Label>
            <TextInput
              {...register("region")}
              errorMessage={errors.region?.message}
              disabled={!userDetails?.isPrimary}
            />
          </div>
          <div>
            <Label htmlFor="postCode">
              {t("profile.subscriber.postcode_field")}
            </Label>
            <TextInput
              {...register("postCode")}
              errorMessage={errors.postCode?.message}
              disabled={!userDetails?.isPrimary}
            />
          </div>
          <div>
            <Label htmlFor="country">
              {t("profile.subscriber.country_field")}
            </Label>
            <TextInput
              {...register("country")}
              errorMessage={errors.country?.message}
              disabled={!userDetails?.isPrimary}
            />
          </div>
        </div>
        <footer className="mt-4 flex gap-2 justify-between">
          <button
            type="submit"
            className="btn btn-secondary"
            disabled={isLoading || !userDetails?.isPrimary}
          >
            {t("common.save")}
          </button>
          <DropDownMenu
            className="!text-base flex justify-center py-2 px-4 rounded-md border border-transparent focus:outline-none disabled:opacity-25 disabled:cursor-not-allowed transition text-primary-2 hover:text-white hover:bg-primary-2 border-primary-2"
            placeHolder={t("profile.export") as string}
            items={[
              {
                label: t("profile.export_csv"),
                onClick: exportCSV,
              },
              {
                label: t("profile.export_pdf"),
                onClick: exportPDF,
              },
            ]}
          />
        </footer>
      </form>
      <Modal open={isExporting}>
        <div className="py-8 px-4 flex justify-center items-center gap-4">
          <div className="flex h-12 w-12">
            <ProcessingIcon />
          </div>
          <Heading className="text-xl font-medium leading-6 text-primary-2">
            {t("common.loading")}
          </Heading>
        </div>
      </Modal>
    </Card>
  );
};

export default UserSubscriberForm;

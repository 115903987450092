import Table from "@components/layout/Table";
import { getWaterCharges } from "@services/waterCharge";
import { useQuery } from "@tanstack/react-query";
import { formatCurrency } from "@utils/formatCurrency";
import { formatDate } from "@utils/formatDate";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import Label from "../Label";
import SearchInput from "../SearchInput";
import { useAppContext } from "@context/AppContext";

type Level1WRSWaterChargeListProps = {
  level1Resource: any;
};

const Level1WRSWaterChargeList: React.FunctionComponent<
  Level1WRSWaterChargeListProps
> = ({ level1Resource }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { checkPermissions } = useAppContext();
  const { data: waterCharges, isLoading } = useQuery(
    ["water_charges", { level1ResourceId: level1Resource?.id }],
    () =>
      getWaterCharges({
        level1ResourceId: level1Resource.id,
      })
  );
  const [filter, setFilter] = useState({
    name: "",
  });

  const fields = [
    {
      title: t("water_charges.field_billing_group"),
      name: "billingGroup",
    },
    {
      title: t("water_charges.field_item_no"),
      name: "fixedItemNo",
    },
    {
      title: t("water_charges.field_name"),
      name: "fixedName",
    },
    {
      title: t("water_charges.field_fixed_value"),
      name: "fixedValue",
    },
    {
      title: t("water_charges.field_var_value"),
      name: "varValue",
    },
    {
      title: t("water_charges.field_effective_at"),
      name: "effectiveAt",
    },
    {
      title: t("common.action"),
      name: "actions",
    },
  ];

  const tableData = waterCharges
    ?.sort(
      (a: any, b: any) =>
        new Date(b.effectiveAt).valueOf() - new Date(a.effectiveAt).valueOf()
    )
    ?.filter(
      (row: {
        fixedName: string;
        varName: string;
        fixedItemNo: string;
        varItemNo: string;
        billingGroup: string;
      }) =>
        !filter.name.length ||
        row.fixedName
          .toString()
          .toLowerCase()
          .includes(filter.name.toString().toLowerCase()) ||
        row.fixedItemNo
          .toString()
          .toLowerCase()
          .includes(filter.name.toString().toLowerCase())
    )
    ?.map((wc: any) => ({
      ...wc,
      effectiveAt: formatDate(new Date(wc.effectiveAt)),
      billingGroup: wc.billingGroup.name,
      fixedValue: formatCurrency(wc.fixedValue),
      varValue: formatCurrency(wc.varValue),
      actions: (
        <Select
          placeholder={t("common.actions")}
          options={
            checkPermissions(["UpdateWaterCharges"])
              ? [
                  {
                    label: t("common.edit"),
                    value: `/polestar/water_charges/${wc.id}/edit?level1ResourceId=${level1Resource?.id}`,
                  },
                ]
              : []
          }
          onChange={(e) => navigate(e?.value ?? "")}
          menuPortalTarget={document.body}
          closeMenuOnScroll={() => true}
          isSearchable={false}
          className="w-32"
        />
      ),
    }));

  return (
    <>
      <div className="flex justify-between items-end">
        <form className="grid grid-cols-1 gap-2 md:grid-cols-3 md:gap-3 2xl:w-2/3 relative z-20">
          <div>
            <Label htmlFor="name">{t("water_charges.filter_name")}</Label>
            <SearchInput
              id="name"
              onChange={(e) =>
                setFilter({
                  ...filter,
                  name: e.target.value,
                })
              }
            />
          </div>
        </form>
        {checkPermissions(["CreateWaterCharges"]) && (
          <Link
            to={`/polestar/water_charges/create?level1ResourceId=${level1Resource?.id}`}
            className="btn-secondary text-sm"
          >
            {t("water_charges.add_water_charge")}
          </Link>
        )}
      </div>
      <Table
        containerClassName="rounded-none md:rounded-none text-sm"
        tableHeaderClassName="relative z-10"
        fields={fields}
        data={tableData}
        loading={isLoading}
        stickyHeader
      />
    </>
  );
};

export default Level1WRSWaterChargeList;

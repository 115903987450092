import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import { useSeasonalTransferContext } from "@context/SeasonalTransferContext";
import SelectLevel0ResouceTable from "@components/table/SelectLevel0ResourceTable";

const SeasonalTransferSelectLevel0Resource = () => {
  const { t } = useTranslation();
  const { stepHelpers, details, handleChangeDetails, navigateForCancel } =
    useSeasonalTransferContext();

  return (
    <>
      <div className="p-6 flex flex-col gap-4 grow">
        <Heading light>
          {t(
            "approval.seasonal_water_assignments.create.select_level0_resource.title",
          )}
        </Heading>
        <SelectLevel0ResouceTable
          subscriberId={details.to.subscriber.id}
          filterByWaterClassName={details.from.waterClass.name}
          filter={{
            level0ResourceId: details.from.level0Resource.id,
            waterClassName: details.from.waterClass.name,
          }}
          value={details.to}
          onSelect={(selected) => {
            handleChangeDetails("to", {
              subscriber: details.to.subscriber,
              ...selected,
            });
          }}
        />
      </div>

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>

        <button
          type="button"
          className="btn-primary"
          onClick={stepHelpers.goToNextStep}
          disabled={details.to.extractionRights.length === 0}
        >
          {t("common.next_step")}
        </button>

        <button
          type="button"
          className="btn-outline-primary"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </>
  );
};

export default SeasonalTransferSelectLevel0Resource;

import { useState } from "react";
import { Link } from "react-router-dom";
import { sumBy } from "lodash";
import { useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import ApprovalTag from "@components/shared/ApprovalTag";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import PermanentTransferModal from "@components/modal/PermanentTransferModal";
import {
  AdministrativeApprovalNumericStatus,
  AdministrativeApprovalStatus,
  AdministrativeApprovalType,
} from "@services/administrativeApprovals";
import { useAppContext } from "@context/AppContext";
import { useAllAdministrativeApprovals } from "@hooks/query/useAllAdministrativeApprovals";
import { useGetAdministrativeApproval } from "@hooks/query/useGetAdministrativeApproval";
import { formatDate } from "@utils/formatDate";
import { formatVolume } from "@utils/formatVolume";
import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";
import SelectAccountingPeriod from "../SelectAccountingPeriod";
import Select from "react-select";

type Level1WRSPermanentTradeProps = {
  level1Resource: any;
};

const Level1WRSPermanentTrade: React.FunctionComponent<
  Level1WRSPermanentTradeProps
> = ({ level1Resource }) => {
  const { checkPermissions } = useAppContext();
  const { t } = useTranslation();
  const [selectedId, setSelectedId] = useState("");
  const [filter, setFilter] = useState<{
    accountingPeriod?: {
      id: string;
      periodStart: Date;
      periodEnd: Date;
    };
    customerName?: string;
    status?: string;
  }>({ accountingPeriod: level1Resource?.activeAccountingPeriod });

  const { data: permanentTrades = [], isLoading } =
    useAllAdministrativeApprovals({
      params: {
        type: AdministrativeApprovalType.PT,
        status: filter.status,
      },
    });

  const { data: approval } = useGetAdministrativeApproval(selectedId);

  const statusOptions = [
    {
      label: t("common.pending"),
      value: AdministrativeApprovalNumericStatus.Pending,
    },
    {
      label: t("common.approved"),
      value: AdministrativeApprovalNumericStatus.Approved,
    },
    {
      label: t("common.rejected"),
      value: AdministrativeApprovalStatus.Declined,
    },
    {
      label: t("common.require_info"),
      value: AdministrativeApprovalNumericStatus.UnderReview,
    },
  ];

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  return (
    <>
      <div className="flex justify-between items-end gap-3">
        <form className="grid grid-cols-1 gap-2 md:grid-cols-4 md:gap-3 2xl:w-2/1 relative z-20 justify-between items-end">
          <div>
            <Label htmlFor="accountingPeriod">
              {t("common.accounting_period")}
            </Label>
            <SelectAccountingPeriod
              level1ResourceId={level1Resource.id}
              inputId="accountingPeriod"
              value={filter?.accountingPeriod}
              onChange={(e: any) =>
                handleFilterChange("accountingPeriod", {
                  id: e?.value?.id,
                  periodStart: new Date(e?.value?.periodStart),
                  periodEnd: new Date(e?.value?.periodEnd),
                })
              }
              openMenuOnFocus
            />
          </div>
          <div>
            <Label htmlFor="customer-name">{t("subscriber.filter.name")}</Label>
            <SearchInput
              id="customer-name"
              onChange={e => handleFilterChange("customerName", e.target.value)}
            />
          </div>
          <div>
            <Label htmlFor="status">{t("common.status")}</Label>
            <Select
              inputId="status"
              options={statusOptions}
              value={statusOptions.find(e => e.value === filter?.status)}
              onChange={(e: any) => {
                handleFilterChange("status", e?.value);
              }}
              isClearable
              openMenuOnFocus
            />
          </div>
        </form>
        {checkPermissions(["CreateApprovals"]) && (
          <Link
            to={`/polestar/administrative_approvals/permanent_trade?level1ResourceId=${level1Resource.id}`}
            className="btn-secondary text-sm whitespace-nowrap"
          >
            {t("accounting_period.checklist.add_permanent_trade")}
          </Link>
        )}
      </div>
      <Table
        fields={[
          {
            title: t("common.level1wrs"),
            name: "level1Resource",
          },
          {
            title: t("common.accounting_period"),
            name: "accountingPeriod",
          },
          {
            title: t("approval.permanent_trades.from_subscriber"),
            name: "sellerName",
          },
          {
            title: t("approval.permanent_trades.to_subscriber"),
            name: "buyerName",
          },
          {
            title: t("approval.permanent_trades.price"),
            name: "price",
          },
          {
            title: t("extraction_right.volume"),
            name: "volume",
          },
          {
            title: t("common.status"),
            name: "status",
          },
          {
            title: t("common.timestamp"),
            name: "createdAt",
          },
          {
            title: t("common.action"),
            name: "action",
          },
        ]}
        data={permanentTrades
          ?.filter(
            (row: { level1Resource: any }) =>
              row.level1Resource.id === level1Resource.id,
          )
          ?.filter(
            (row: { seller: any; buyer: any }) =>
              !filter?.customerName?.length ||
              row.seller?.name
                .toString()
                .toLowerCase()
                .includes(filter?.customerName.toString().toLowerCase()) ||
              row.buyer?.name
                .toString()
                .toLowerCase()
                .includes(filter?.customerName.toString().toLowerCase()),
          )
          ?.filter(
            (row: { accountingPeriod: any }) =>
              filter.accountingPeriod === undefined ||
              row.accountingPeriod?.id === filter.accountingPeriod?.id,
          )
          ?.filter(
            (row: { status: any }) =>
              filter.status === undefined || row.status === filter.status,
          )
          ?.map((app: any) => {
            return {
              ...app,
              level1Resource: app.level1Resource.identifier,
              createdAt: formatDate(new Date(app.lodgedAt)),
              accountingPeriod: formatAccountingPeriod(app.accountingPeriod),
              sellerName: app.seller.name,
              buyerName: app.buyer.name,
              volume: formatVolume(
                sumBy(app.extractionRights, (i: any) => +i.volume),
              ),
              price: `$${app.price}`,
              status: (
                <ApprovalTag
                  isAccounted={Boolean(app?.accountedAt)}
                  status={app?.status}
                />
              ),
              action: (
                <div className="flex gap-1 items-center w-full">
                  <button
                    type="button"
                    onClick={() => setSelectedId(app.id)}
                    className="btn-default text-sm"
                  >
                    {t("common.view")}
                  </button>
                </div>
              ),
            };
          })}
        stickyHeader
        loading={isLoading}
      />

      {selectedId && approval ? (
        <PermanentTransferModal
          data={approval}
          onClose={() => setSelectedId("")}
        />
      ) : null}
    </>
  );
};

export default Level1WRSPermanentTrade;

import { useQuery } from "@tanstack/react-query";

import {
  getAllExtractionPoints,
  GetAllExtractionPointsParams,
} from "@services/extractionPoints";

export const useAllExtractionPoints = ({
  params,
  options,
}: {
  params?: Partial<GetAllExtractionPointsParams>;
  options?: Record<string, any>;
} = {}) => {
  return useQuery<any>({
    queryKey: ["extraction_points", params],
    queryFn: () => getAllExtractionPoints({ params }),
    ...options,
  });
};

import { useMutation } from "@tanstack/react-query";

import api from "@services/api";

const mutation = async (payload: {
  subscriberId: string;
  level0ResourceId: string;
  declarationId: string;
  reading: number;
  notes?: string;
}) => {
  const { data } = await api.post(
    "/admin/balanceAdjustments/meterReading",
    payload
  );

  return [data.balanceAdjustment, data.workflowInstance];
};

export const useAdjustMeterReading = (options: Record<string, any> = {}) => {
  return useMutation(mutation, options);
};

import { useQuery } from "@tanstack/react-query";

import { getAllLevel2Resources } from "@services/level2Resource";

type UseAllLevel2ResourcesParams = {
  params: Parameters<typeof getAllLevel2Resources>[0];
  [key: string]: any;
};

export const useAllLevel2Resources = ({
  params,
  ...options
}: Partial<UseAllLevel2ResourcesParams>) => {
  return useQuery<any>({
    queryKey: ["level2Resources", params],
    queryFn: () => getAllLevel2Resources(params),
    ...options,
  });
};

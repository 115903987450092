import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Heading1 from "@components/layout/Heading";
import Table from "@components/layout/Table";
import { useAllExtractionPoints } from "@hooks/query/useAllExtractionPoints";
import Tag from "@components/shared/Tag";
import { startCase } from "lodash";

type ExtractionPointLinkToRightStep1Props = {
  level0ResourceId: string;
  definedByWalletId: string;
  subscriberId: string;
  onSave: (data: { extractionPointId: any }) => void;
  setCheckedExtractionPoint: (id: string) => void;
  checkedExtractionPoint: string;
};

const ExtractionPointLinkToRightStep1: React.FunctionComponent<
  ExtractionPointLinkToRightStep1Props
> = ({
  onSave,
  level0ResourceId,
  definedByWalletId,
  subscriberId,
  setCheckedExtractionPoint,
  checkedExtractionPoint,
}: ExtractionPointLinkToRightStep1Props) => {
  const { t } = useTranslation();
  const { data: extractionPoints } = useAllExtractionPoints({
    params: {
      level0ResourceId,
      definedByWalletId,
    },
  });

  const handleSave = (e: any) => {
    e.preventDefault();

    onSave({
      extractionPointId: checkedExtractionPoint,
    });
  };

  return (
    <>
      <section className="p-6 flex flex-col gap-4 grow">
        <Heading1 light>{t("extraction_point.link.choose_offtake")}</Heading1>
        <Table
          pageSize={50}
          containerClassName="rounded-none md:rounded-none text-sm"
          noRowsText={t("common.no_data") as string}
          fields={[
            {
              title: "",
              name: "radioButton",
            },
            {
              title: t("subscriber.account_number"),
              name: "accountNumber",
            },
            {
              title: t("subscriber.name"),
              name: "accountName",
            },
            {
              title: t("extraction_point.name"),
              name: "name",
            },
            {
              title: t("extraction_point.is_active"),
              name: "isActive",
            },
            {
              title: t("extraction_point.source"),
              name: "source",
            },
            {
              title: t("meter.serial_no"),
              name: "meterName",
            },
          ]}
          data={extractionPoints
            ?.map((ep: any) => ({
              ...ep,
              radioButton: (
                <input
                  type="radio"
                  className="h-4 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                  checked={checkedExtractionPoint === ep.id}
                  onChange={() => {
                    checkedExtractionPoint === ep.id
                      ? setCheckedExtractionPoint("")
                      : setCheckedExtractionPoint(ep.id);
                  }}
                />
              ),
              level1ResourceName: ep.subscriber?.level1WRS?.name,
              isAccountActive: (
                <Tag status={ep.subscriber?.isActive ? "success" : "error"}>
                  {ep.subscriber?.isActive
                    ? t("common.active")
                    : t("common.inactive")}
                </Tag>
              ),
              accountNumber: ep.subscriber?.accountNumber,
              accountName: ep.subscriber?.name,
              isActive: (
                <Tag status={ep.isActive ? "success" : "error"}>
                  {ep.isActive ? t("common.active") : t("common.inactive")}
                </Tag>
              ),
              meterName: ep.meter?.serialNo,
              source: startCase(ep.source),
            }))
            .sort((a: any, b: any) => +a.accountNumber - +b.accountNumber)}
          stickyHeader
        />
      </section>
      <footer className="border-t border-gray-200 p-6 flex gap-3">
        <button
          className="btn-primary"
          disabled={!checkedExtractionPoint}
          onClick={handleSave}
        >
          {t("common.continue")}
        </button>
        <Link
          to={`/polestar/subscribers/${subscriberId}?level0ResourceId=${level0ResourceId}`}
          className="btn-default"
        >
          {t("common.cancel")}
        </Link>
      </footer>
    </>
  );
};

export default ExtractionPointLinkToRightStep1;

import React from "react";

import { useStepContext } from "@context/shared/StepContext";
import { useLocation } from "react-router-dom";
import { useBulkMeterUploadContext } from "@context/BulkMeterUploadContext";

const SelectLevel1WRS = () => {
  const { stepHelpers } = useStepContext();
  const { state } = useLocation();

  const { setLevel1WRS } = useBulkMeterUploadContext();

  React.useEffect(() => {
    if (state?.level1Resource) {
      setLevel1WRS(state?.level1Resource);
    }
    stepHelpers.setStep(1);
  }, [state?.level1Resource, setLevel1WRS, stepHelpers]);

  return null;
};

export default SelectLevel1WRS;

import React from "react";
import { useTranslation } from "react-i18next";

import { useAllNotification } from "@hooks/query/useAllNotifications";
import { useAppContext } from "@context/AppContext";
import Breadcrumb from "@components/layout/Breadcrumb";
import { usePaginationState } from "@components/shared/DataTable/Pagination";
import { ColumnDef } from "@tanstack/react-table";
import NotificationViewDataTableRenderer from "@components/shared/DataTable/NotificationViewDataTableRenderer";
import { ClockIcon, Cog6ToothIcon, UserIcon } from "@heroicons/react/24/solid";
import { formatDateTime } from "@utils/formatDateTime";
import Tag from "@components/shared/Tag";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  ArrowUpOnSquareIcon,
  ArrowDownOnSquareIcon,
} from "@heroicons/react/24/outline";
import {
  markNotificationAsRead,
  markNotificationAsUnread,
} from "@services/notification";

type NotificationColumn = {
  id: string;
};

const Notification: React.FunctionComponent = () => {
  const { setInfoPanel, loggedInInfo } = useAppContext();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [pagination, setPagination] = usePaginationState();

  const {
    data = [],
    isLoading,
    isFetching,
  } = useAllNotification({
    params: {
      recipientWalletId: loggedInInfo.userDetails?.walletId,
      outbox: 1,
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
    },
    queryKey: ["notifications", { userId: loggedInInfo.userDetails?.id }],
    enabled: Boolean(loggedInInfo.userDetails?.walletId),
    select: (notificationMetaData: any) => {
      notificationMetaData.data = notificationMetaData.data.map((data: any) => {
        return {
          notificationItem: JSON.stringify({
            senderId: data.relatedMessage?.sender?.id,
            sender: data.relatedMessage?.sender?.name,
            recipients: data.relatedMessage?.recipients,
            subject: data.relatedMessage?.subject,
            sendAt: data.createdAt,
            viewedAt: data.viewedAt,
          }),
          ...data,
        };
      });
      return notificationMetaData;
    },
  });

  const { refetch: fetchAllNotification, isFetching: isFetchingAll } =
    useAllNotification({
      params: {
        recipientWalletId: loggedInInfo.userDetails?.walletId,
        outbox: 1,
        limit: -1,
      },
      enabled: false,
    });

  const { mutateAsync: markAsRead } = useMutation(
    (notification: any) => {
      return markNotificationAsRead({
        notificationId: notification.id,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "notifications",
          { userId: loggedInInfo.userDetails?.id },
        ]);
        queryClient.invalidateQueries([
          "notifications",
          { read: 1, limit: -1 },
        ]);
        queryClient.invalidateQueries([
          "notifications",
          { onlyNew: 1, limit: -1 },
        ]);
      },
    }
  );

  const { mutateAsync: markAsUnRead } = useMutation(
    (id: string) => {
      return markNotificationAsUnread({
        notificationId: id,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "notifications",
          { userId: loggedInInfo.userDetails?.id },
        ]);
        queryClient.invalidateQueries([
          "notifications",
          { read: 1, limit: -1 },
        ]);
        queryClient.invalidateQueries([
          "notifications",
          { onlyNew: 1, limit: -1 },
        ]);
      },
    }
  );

  React.useEffect(() => {
    setInfoPanel(
      <>
        <p>Notifications</p>
      </>
    );
  }, [setInfoPanel]);

  const breadcrumb = [
    {
      label: t("dashboard.dashboard"),
      href: "/zenith",
    },
    {
      label: t("notification.subscriber_widget_title"),
    },
  ];

  const columns = React.useMemo<ColumnDef<NotificationColumn>[]>(
    () => [
      {
        id: "notificationItem",
        accessorKey: "notificationItem",
        cell: ({ row, getValue }) => {
          const stringValue = getValue() as string;
          const value = JSON.parse(stringValue) as any;
          return (
            <div
              className="flex items-center h-auto cursor-pointer min-w-fit"
              onClick={(e: any) => {
                row.getToggleSelectedHandler()(e);
                markAsRead(row.original);
              }}
            >
              <div className="w-full">
                <input
                  id={row.id}
                  type="checkbox"
                  className="hidden"
                  {...{
                    checked: row.getIsSelected(),
                    disabled: !row.getCanSelect(),
                  }}
                  onChange={() => {}}
                />
                <div className="group relative flex items gap-x-3 rounded-lg p-2 items-center">
                  <div className="w-14 h-14 text-gray-300 shrink-0">
                    {value.sender ? (
                      <UserIcon className="border border-gray-300 rounded-full bg-transparent" />
                    ) : (
                      <Cog6ToothIcon className="border border-gray-300 rounded-full bg-transparent" />
                    )}
                  </div>
                  <div className="text-gray-500">
                    <div>
                      <span className="text-gray-700 font-semibold flex">
                        <span className="flex items-center gap-1 mr-2 text-gray-500">
                          {value.senderId === loggedInInfo.userDetails?.id ? (
                            <>
                              <ArrowUpOnSquareIcon className="w-4" />
                              {t("common.to")}
                            </>
                          ) : (
                            <>
                              <ArrowDownOnSquareIcon className="w-4" />
                              {t("common.from")}
                            </>
                          )}
                          :
                        </span>
                        {value.senderId === loggedInInfo.userDetails?.id
                          ? value.recipients.length > 1
                            ? `${value.recipients.length.toLocaleString()} ${t(
                                "notification.recipients"
                              )}`
                            : value.recipients
                                ?.map((r: any) => r.recipientWallet?.name)
                                ?.join(", ")
                          : value.sender
                          ? value.sender
                          : t("audit_trail.system")}
                      </span>{" "}
                      <p className="line-clamp-2">{value.subject}</p>
                    </div>
                    <div className="text-sm flex items-center mt-1 text-gray-400 gap-4">
                      {!value.viewedAt && (
                        <Tag status="info">{t("common.unread")}</Tag>
                      )}

                      <p className="flex gap-2">
                        <ClockIcon className="w-4 h-4" />
                        {formatDateTime(new Date(value.sendAt))}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        },
      },
    ],
    [markAsRead, t, loggedInInfo.userDetails?.id]
  );

  return (
    <>
      <section className="flex flex-col flex-1 space-y-4">
        <Breadcrumb items={breadcrumb} />
        <NotificationViewDataTableRenderer
          name="notifications"
          data={data?.data ?? []}
          columns={columns}
          pagination={pagination}
          pageCount={data?.meta?.lastPage ?? -1}
          onPaginationChange={setPagination}
          isLoading={isLoading}
          isFetching={isFetching || isFetchingAll}
          fetchAll={fetchAllNotification}
          columnsVisibility
          stickyHeader
          onClickMarkUnread={(id) => {
            markAsUnRead(id);
          }}
        />
      </section>
    </>
  );
};

export default Notification;

import api from "./api";

export const getAllSpecialAnnouncements = async (params?: any) => {
  const { data } = await api.get("/admin/specialAnnouncements", { params });
  return data.specialAnnouncements ?? [];
};

export type CreateSpecialAnnouncementPayload = {
  startAt: Date;
  subscriberIds: Array<string>;
  level0ResourceIds: Array<string>;
};

export const createSpecialAnnouncement = async (
  payload: CreateSpecialAnnouncementPayload
): Promise<any> => {
  const { data } = await api.post("/admin/specialAnnouncements", payload);

  return [data.specialAnnouncement, data.workflowInstance];
};

export type EndSpecialAnnouncementPayload = {
  endAt?: Date;
  specialAnnouncementIds: Array<string>;
};

export const endSpecialAnnouncement = async (
  payload: EndSpecialAnnouncementPayload
) => {
  const { data } = await api.post(`/admin/specialAnnouncements/end`, payload);

  return [data.specialAnnouncements, data.workflowInstance];
};

import React from "react";
import Select from "react-select";
import { noop } from "lodash";

import { getStatusAndText } from "@utils/administrativeApproval";

type SelectAdminApprovalStatusProps = {
  adminApprovalsAndTransfers: any[];
  name?: string;
  value?: string;
  className?: string;
  onValueChange?: (value: any) => void;
};

const SelectAdminApprovalStatus: React.FunctionComponent<
  SelectAdminApprovalStatusProps
> = ({
  adminApprovalsAndTransfers,
  name,
  value,
  className,
  onValueChange = noop,
}) => {
  const getStatusOptions = (approvalAndTransfers: any) => {
    if (!approvalAndTransfers) return [];
    const statuses = approvalAndTransfers.map(
      (forwardDraw: any) => forwardDraw.status
    );
    const uniqueStatuses = Array.from(new Set(statuses));
    return uniqueStatuses.map((status: any) => ({
      label: getStatusAndText(status)?.text ?? "",
      value: status,
    }));
  };

  const options = getStatusOptions(adminApprovalsAndTransfers);

  return (
    <Select
      name={name}
      options={options}
      value={options.find((i: any) => i.value === value)}
      className={className}
      onChange={(e) => onValueChange(e?.value)}
      isClearable
    />
  );
};

export default SelectAdminApprovalStatus;

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { activateExtractionRight } from "@services/extractionRight";
import { isFunction } from "lodash";

export const useActivateExtractionRight = (
  options: Record<string, any> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation(activateExtractionRight, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries({ queryKey: ["extractionRights"] });
      if (isFunction(options.onSuccess)) {
        options.onSuccess(res);
      }
    },
    ...options,
  });
};

import { useSubscriber } from "@hooks/query/useSubscriber";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import TabPanels from "@components/form/TabPanels";
import SubscriberNotes from "@components/form/subscriber/SubscriberNotes";
import NotificationHistoryTab from "@components/form/subscriber/dashboard/subscriber_history/NotificationHistoryTab";
import EvidenceTable from "@components/table/EvidenceTable";
import Loading from "@components/shared/Loading";
import { useAppContext } from "@context/AppContext";
import EventTransactionPaginatedTable from "@components/table/EventTransactionPaginatedTable";

const SubscriberAuditTrailsAndEvidences: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { checkPermissions } = useAppContext();
  const { id: subscriberId = "" } = useParams();
  const { data: subscriber = {}, isLoading } = useSubscriber(subscriberId);

  if (isLoading) {
    return (
      <div className="pt-20">
        <Loading />
      </div>
    );
  }

  return (
    <TabPanels
      tabs={[
        ...(checkPermissions(["ViewEventTransactions"])
          ? [
              {
                title: t("subscriber.audit_trail"),
                panel: (
                  <EventTransactionPaginatedTable
                    subscriberId={subscriber.id}
                  />
                ),
              },
            ]
          : []),
        ...(checkPermissions(["ViewEvidences"])
          ? [
              {
                title: t("evidence.documents"),
                panel: <EvidenceTable subscriberId={subscriberId} />,
              },
            ]
          : []),
        ...(checkPermissions(["ViewSubscribers"])
          ? [
              {
                title: t("subscriber.notes"),
                panel: <SubscriberNotes subscriberId={subscriberId} />,
              },
            ]
          : []),
        ...(checkPermissions(["ViewNotifications"]) ? [] : []),
        {
          title: t("notification.title"),
          panel: <NotificationHistoryTab walletId={subscriber.walletId} />,
        },
      ]}
    />
  );
};

export default SubscriberAuditTrailsAndEvidences;

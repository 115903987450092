type ReadOnlyProps = {
  children: React.ReactNode;
};

const ReadOnlyInput: React.FunctionComponent<ReadOnlyProps> = ({
  children,
}) => {
  return (
    <div className="py-2 px-3 border rounded-sm bg-gray-50 text-gray-500 cursor-not-allowed">
      {children ? children : <span>&nbsp;</span>}
    </div>
  );
};

export default ReadOnlyInput;

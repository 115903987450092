import React from "react";
import { useTranslation } from "react-i18next";

import SelectExtractionRights from "./SelectExtractionRights";
import { useStepContext } from "@context/shared/StepContext";

type ForwardDrawSelectExtractionRightsProps = {
  extractionRights: any;
  selectedExtractionRights: any;
  onSelect: any;
  onCancel: () => void;
};

const ForwardDrawSelectExtractionRights: React.FunctionComponent<
  ForwardDrawSelectExtractionRightsProps
> = ({
  extractionRights,
  selectedExtractionRights,
  onSelect,
  onCancel,
}: ForwardDrawSelectExtractionRightsProps) => {
  const { t } = useTranslation();
  const { stepHelpers } = useStepContext();

  return (
    <>
      <SelectExtractionRights
        extractionRights={extractionRights}
        selectedExtractionRights={selectedExtractionRights}
        setSelectedExtractionRights={onSelect}
      />

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>
        <button
          className="btn-primary"
          onClick={stepHelpers.goToNextStep}
          disabled={!selectedExtractionRights.length}
        >
          {t("common.next_step")}
        </button>
        <button className="btn-outline-primary" onClick={onCancel}>
          {t("common.cancel")}
        </button>
      </footer>
    </>
  );
};

export default ForwardDrawSelectExtractionRights;

import { useTranslation } from "react-i18next";

import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import { formatDate } from "@utils/formatDate";
import ENV from "@config/env";

type EvidenceListProps = {
  data: any[];
};

const EvidenceList: React.FunctionComponent<EvidenceListProps> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="px-4">
      <h3 className="font-semibold mb-1 pl-2">{t("common.evidences")}</h3>
      <ul className="text-sm">
        {data.map((i) => {
          return (
            <li
              key={i.id}
              className="flex justify-between hover:bg-gray-50 px-2 py-1.5 rounded"
            >
              <p className="flex items-baseline gap-2">
                <a
                  className="hover:underline"
                  title={i.fileName}
                  href={`${ENV.API_HOST}/evidences/${i.id}/preview`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {i.fileName}
                </a>
                <small className="text-gray-400">
                  {formatDate(new Date(i.createdAt))}
                </small>
              </p>
              <div className="flex">
                <a
                  className="px-1.5 text-gray-400 hover:text-primary-2"
                  title={t("common.download") as string}
                  href={`${ENV.API_HOST}/evidences/${i.id}/download`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ArrowDownTrayIcon className="w-5 h-5" />
                </a>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default EvidenceList;

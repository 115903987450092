import React from "react";
import Select from "react-select";
import { uniqBy } from "lodash";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import Label from "@components/form/Label";
import SelectExtractionPoint from "@components/form/SelectExtractionPoint";
import TextInput from "@components/form/TextInput";
import { useAllExtractionRights } from "@hooks/query/useAllExtractionRights";
import { useExtractionPoint } from "@hooks/query/useExtractionPoint";
import { formatDateInput } from "@utils/formatDate";
import ENV from "@config/env";

type EnterDetailsProps = {
  onChange: any;
  values: any;
  onNext: () => void;
  onPrev: () => void;
  onCancel: () => void;
};

const EnterDetails: React.FunctionComponent<EnterDetailsProps> = ({
  onChange,
  values,
  onNext,
  onPrev,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const level0ResourceId = searchParams.get("level0ResourceId") || "";
  const extractionPointId = searchParams.get("extractionPointId") || "";

  const { data: extractionRightOptions = [] } = useAllExtractionRights({
    params: {
      assertedByWalletId: values.subscriber.walletId,
      isActive: true,
    },
    options: {
      select: (data: any[]) => {
        return data.map((i) => ({
          value: i.id,
          label: i.name,
        }));
      },
      onSuccess: (res: any[]) => {
        if (ENV.CLIENT_ID !== "seqwater" || !res.length) return;

        onChange((prev: any) => ({
          ...prev,
          extractionRights: res.map((i: any) => ({
            id: i.value,
            name: i.label,
          })),
        }));
      },
    },
  });

  useExtractionPoint(extractionPointId, {
    onSuccess: (res: any) => {
      onChange((prev: any) => ({
        ...prev,
        extractionPoint: {
          id: res.id,
          name: res.name,
        },
      }));
    },
  });

  const { data: level0ResourceOptions = [] } = useAllExtractionRights({
    params: {
      assertedByWalletId: values.subscriber.walletId,
      isActive: true,
    },
    options: {
      select: (data: any) => {
        const level0ResourceOptions = uniqBy(
          data,
          (i: any) => i.level0ResourceId
        ).map((i) => {
          return {
            value: i.level0Resource.id,
            label: i.level0Resource.identifier,
          };
        });

        return level0ResourceOptions;
      },
      onSuccess: (res: any) => {
        const level0Resource = res.find(
          (i: any) => i.value === level0ResourceId
        );

        if (!level0Resource) return;

        onChange((prev: any) => ({
          ...prev,
          level0Resource: {
            id: level0Resource.value,
            identifier: level0Resource.label,
          },
        }));
      },
    },
  });

  return (
    <form
      className="flex flex-col grow"
      onSubmit={(e) => {
        e.preventDefault();
        onNext();
      }}
    >
      <div className="flex flex-col grow p-6 space-y-4">
        <Heading light>{t("extraction_point.unlink.enter_details")}</Heading>

        <div className="max-w-xs">
          <Label>{t("common.level0wrs")}:</Label>
          <Select
            options={level0ResourceOptions}
            value={level0ResourceOptions.find(
              (i: any) => i.value === values.level0Resource.id
            )}
            onChange={(selected) => {
              onChange((prev: any) => ({
                ...prev,
                level0Resource: {
                  id: selected?.value ?? "",
                  identifier: selected?.label ?? "",
                },
              }));
            }}
            isDisabled={Boolean(level0ResourceId)}
          />
        </div>

        <div className="max-w-xs">
          <Label>{t("extraction_point.name")}:</Label>
          <SelectExtractionPoint
            value={values.extractionPoint.id}
            onChange={(selected: any) => {
              onChange((prev: any) => ({
                ...prev,
                extractionPoint: {
                  id: selected.id,
                  name: selected.value,
                },
              }));
            }}
            definedByWalletId={values.subscriber.walletId}
            level0ResourceId={values.level0Resource.id}
            isActive
          />
        </div>

        {ENV.CLIENT_ID !== "seqwater" ? (
          <div className="max-w-xs">
            <Label>{t("extraction_right.name")}:</Label>
            <Select
              options={extractionRightOptions}
              value={extractionRightOptions.filter((i: any) => {
                const ids = values.extractionRights.map((i: any) => i.id);

                return ids.includes(i.value);
              })}
              onChange={(selected) => {
                onChange((prev: any) => ({
                  ...prev,
                  extractionRights: selected
                    .map((i: any) =>
                      i
                        ? {
                            id: i.value,
                            name: i.label,
                          }
                        : null
                    )
                    .filter(Boolean),
                }));
              }}
              isMulti
              required
            />
          </div>
        ) : null}

        <div className="max-w-xs">
          <Label htmlFor="effectiveDate">{t("common.timestamp")}:</Label>
          <TextInput
            type="date"
            id="effectiveAt"
            value={formatDateInput(values.effectiveAt)}
            onChange={(e) => {
              onChange((prev: any) => ({
                ...prev,
                effectiveAt: new Date(e.target.value),
              }));
            }}
          />
        </div>
      </div>

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button type="button" className="btn-outline-primary" onClick={onPrev}>
          {t("common.prev_step")}
        </button>
        <button
          type="submit"
          className="btn-primary"
          disabled={
            !values.extractionPoint.id || !values.extractionRights.length
          }
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default EnterDetails;

import { useState } from "react";
import { sortBy, startCase } from "lodash";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import Table from "@components/layout/Table";
import Tag from "@components/shared/Tag";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import { useAppContext } from "@context/AppContext";
import { useExtractionPointContext } from "@context/ExtractionPointContext";
import { useAllMeters } from "@hooks/query/useAllMeters";
import { convertLiterToML } from "@utils/convertUnits";
import { formatVolume } from "@utils/formatVolume";

const ExtractionPointLinkToMeter = () => {
  const { t } = useTranslation();
  const { stepHelpers, handleChangeDetails, navigateForCancel, details } =
    useExtractionPointContext();
  const { checkPermissions } = useAppContext();
  const { data: meters = [] } = useAllMeters({
    params: {
      isActive: false,
      isDelete: false,
    },
  });
  const [filter, setFilter] = useState<{
    meterSerialNo?: string;
  }>({});

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const tableData = meters
    ?.filter(
      (row: any) =>
        !filter?.meterSerialNo?.length ||
        row.serialNo
          ?.toString()
          .toLowerCase()
          .includes(filter?.meterSerialNo.toString().toLowerCase())
    )
    .map((item: any) => {
      return {
        ...item,
        extractionPointName: item.extractionPoint?.name || "",
        level0wrs: item.extractionPoint?.level0WRS?.identifier || "",
        clickOver: item?.threshold && formatVolume(+item?.clickOver),
        source: startCase(item.extractionPoint?.source || ""),
        radio: (
          <input
            type="radio"
            name="meter"
            onChange={() => {
              handleChangeDetails("meter", {
                id: item.id,
                serialNo: item.serialNo,
                extractionPointName: item.extractionPoint?.name ?? "",
              });
            }}
            checked={item.id === details.meter.id}
          />
        ),
        status: (
          <Tag status={item.isActive ? "success" : "error"}>
            {item.isActive ? t("common.active") : t("common.inactive")}
          </Tag>
        ),
        threshold: `${convertLiterToML(+item.threshold)} ${t(
          "common.volume_unit"
        )}`,
      };
    });

  const sortedTableData = sortBy(tableData, ({ id }) =>
    id === details.meter.id ? 0 : 1
  );

  const tableFields = [
    {
      title: "",
      name: "radio",
    },
    {
      title: t("meter.serial_no"),
      name: "serialNo",
    },
    {
      title: t("meter.click_over"),
      name: "clickOver",
    },
    {
      title: t("common.status"),
      name: "status",
    },
    ...(checkPermissions(["viewThreshold"])
      ? [
          {
            title: `${t("meter.threshold")}`,
            name: "threshold",
          },
        ]
      : []),
    {
      title: "",
      name: "view",
    },
  ];

  return (
    <>
      <div className="p-6 flex flex-col gap-6 grow">
        <header className="flex justify-between">
          <Heading light>{t("extraction_point.create.link_to_meter")}</Heading>
        </header>

        <form>
          <div className="max-w-sm">
            <Label htmlFor="meterSerialNo">{t("meter.filter_serial_no")}</Label>
            <SearchInput
              id="meterSerialNo"
              onChange={(e) =>
                handleFilterChange("meterSerialNo", e.target.value)
              }
            />
          </div>
        </form>

        <Table fields={tableFields} data={sortedTableData} stickyHeader />
      </div>

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>
        <button
          type="button"
          className="btn-primary"
          onClick={stepHelpers.goToNextStep}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={() => navigateForCancel()}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </>
  );
};

export default ExtractionPointLinkToMeter;

import Select from "react-select";
import { noop } from "lodash";

import ErrorMessage from "./ErrorMessage";
import { useAllClassCategories } from "@hooks/query/useAllClassCategories";

type SelectProps = React.ComponentProps<Select>;

type SelectExtractionPointProps = Exclude<SelectProps, "value"> & {
  value?: string;
  onChange?: (value: any) => void;
  errorMessage?: string;
};

const SelectClassCategory: React.FunctionComponent<
  SelectExtractionPointProps
> = ({ errorMessage, value, onChange = noop, ...restProps }) => {
  const { isLoading, data: options = [] } = useAllClassCategories({
    select: (data: any) => {
      return data.map((i: any) => ({
        label: i.name,
        value: i.id,
      }));
    },
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Select
        options={options}
        value={options.find((i: any) => i.value === value)}
        onChange={onChange}
        openMenuOnFocus
        {...restProps}
      />

      {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
    </>
  );
};

export default SelectClassCategory;

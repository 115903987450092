const randomNum = (min: number, max: number) => {
  return Math.round(min + Math.random() * (max - min));
};

const makeYearlyData = (name: string) => {
  return {
    name,
    value: randomNum(5000000, 10000000),
  };
};

const makeMonthlyData = (name: string) => {
  return {
    name,
    value: randomNum(100000, 250000),
  };
};

const getChangeType = (value: number) => {
  if (value < 0) {
    return "increase";
  } else if (value > 0) {
    return "decrease";
  }
  return "equal";
};

const calculatePercentageDifference = (num1: number, num2: number) => {
  return ((num1 - num2) / num1) * 100;
};

const transformData = (item: any, index: number, list: any[]) => {
  let changePercentage = 0;
  let changeType = "equal";

  if (index > 0) {
    const percentageDiff = calculatePercentageDifference(
      list[index - 1].value,
      item.value
    );

    changeType = getChangeType(percentageDiff);
    changePercentage = Math.abs(Math.round(percentageDiff));
  }

  return {
    ...item,
    changePercentage,
    changeType,
  };
};

export const getYearlyData = () => {
  return ["2021", "2022", "2023"].map(makeYearlyData).map(transformData);
};

export const getMonthlyData = () => {
  return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"]
    .map(makeMonthlyData)
    .map(transformData);
};

import classNames from "classnames";
import React from "react";

type PaperClipIconProps = {
  className?: string;
};

const PaperClipIcon: React.FunctionComponent<PaperClipIconProps> = ({
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      aria-hidden="true"
      className={classNames("h-5 w-5 flex-shrink-0 text-gray-400", className)}
      viewBox="0 0 20 20"
    >
      <path
        fillRule="evenodd"
        d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default PaperClipIcon;

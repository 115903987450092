import React from "react";
import { toast } from "react-toastify";

export function toastSuccess(body: React.ReactNode) {
  toast.success(body, {
    className: "toast toast-success",
  });
}

export function toastError(body: React.ReactNode) {
  toast.error(body, {
    className: "toast toast-error",
  });
}

export function toastWarning(body: React.ReactNode) {
  toast.error(body, {
    className: "toast toast-warning",
  });
}

import Heading from "@components/layout/Heading";
import ConfirmModal from "@components/shared/ConfirmModal";
import ConfirmationDetail from "@components/shared/ConfirmationDetail";
import Label from "@components/form/Label";
import TextArea from "@components/form/TextArea";
import { useDeleteExtractionRight } from "@hooks/mutation/useDeleteExtractionRight";
import { useExtractionRight } from "@hooks/query/useExtractionRight";
import { toastError, toastSuccess } from "@utils/toast";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

type DeleteExtractionRightLedgerProps = {
  onSuccess: (workflowInstance: any) => void;
};

const DeleteExtractionRightLedger: FunctionComponent<
  DeleteExtractionRightLedgerProps
> = ({ onSuccess }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: level1ResourceId, extractionRightId } = useParams();
  const [searchParams] = useSearchParams();
  const subscriberId = searchParams.get("subscriberId") || "";
  const level0ResourceId = searchParams.get("level0ResourceId") || "";

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [note, setNote] = useState("");
  const { data: extractionRight } = useExtractionRight(extractionRightId!, {
    enabled: !!extractionRightId,
  });
  const { mutateAsync: deleteExtractionRightMutation, isLoading: isDeleting } =
    useDeleteExtractionRight();

  const navigateForCancel = () => {
    const returnString = subscriberId
      ? `/polestar/subscribers/${subscriberId}?level0ResourceId=${level0ResourceId}`
      : `/polestar/level1wrs/${level1ResourceId}#4`;
    navigate(returnString);
  };

  const handleOnDeleteConfirm = async () => {
    if (!extractionRight) return;

    try {
      const [, workflowInstance] = await deleteExtractionRightMutation({
        extractionRightId: extractionRight?.id,
        note,
      });

      onSuccess(workflowInstance);
      toastSuccess(
        t("extraction_right.delete.toast.success", {
          name: extractionRight?.name,
        })
      );
    } catch (error: any) {
      toastError(t("extraction_right.delete.toast.failed"));
    }
  };

  return (
    <>
      <div className="flex flex-col gap-6 p-6 grow justify-between">
        <div className="space-y-4">
          <Heading light>{t("extraction_right.delete.step1.title")}</Heading>
          <ConfirmationDetail
            data={[
              {
                title: "",
                body: [
                  {
                    key: t("common.extraction_right"),
                    value: extractionRight?.name,
                  },
                ],
                disableEdit: true,
              },
            ]}
            onEdit={() => {}}
          />
          <div className="max-w-lg">
            <Label optional>
              {t("extraction_right.delete.step1.enter_note")}
            </Label>
            <TextArea rows={4} onChange={(e) => setNote(e.target.value)} />
          </div>
        </div>

        <footer className="flex gap-4 -mx-6 mt-6 p-6 pb-0 border-t border-gray-200">
          <button
            type="button"
            className="btn-primary"
            onClick={() => setShowConfirmModal(true)}
          >
            {t("common.ledger")}
          </button>

          <button
            type="button"
            className="btn-outline-primary"
            onClick={() => navigateForCancel()}
          >
            {t("common.cancel")}
          </button>
        </footer>
      </div>
      <ConfirmModal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleOnDeleteConfirm}
        isSubmitting={isDeleting}
        confirmText={t("common.ledger")}
      >
        {t("extraction_right.delete.confirm", {
          name: extractionRight?.name,
        })}
      </ConfirmModal>
    </>
  );
};

export default DeleteExtractionRightLedger;

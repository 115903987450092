import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HandleGoBackOrClose from "@components/shared/HandleGoBackOrClose";
import ExternalLinkIcon from "@components/icons/ExternalLinkIcon";
import Table from "@components/layout/Table";
import InformationCircleIcon from "@components/icons/InformationCircleIcon";
import Tag from "@components/shared/Tag";
import { useStep, UseStepHelpers } from "@hooks/useStep";
import {
  GetInfoMessagesFunction,
  ModifyStatusesFunction,
  useStepStatuses,
} from "@hooks/useStepStatuses";
import {
  CanAmalgamate,
  getExtractionRightsCanAmalgamate,
} from "@utils/getExtractionRightsCanAmalgamate";
import { formatVolume } from "@utils/formatVolume";
import { ExtractionRightApprovalType } from "@services/administrativeApprovals";

const initialDetails = {
  id: "",
  subscriber: {
    id: "",
    name: "",
    accountNumber: "",
  },
  level1wrs: { id: "", name: "", identifier: "" },
  level0wrs: { id: "", name: "", identifier: "" },
  effectiveDate: new Date(),
};

type Details = typeof initialDetails;

type HandleChangeDetails = <TValue, TKeys extends keyof Details>(
  key: TKeys,
  value: TValue,
  subKey?: string,
) => void;

type ContextValue = {
  currentStep: number;
  stepHelpers: UseStepHelpers;
  details: Details;
  setDetails: React.Dispatch<React.SetStateAction<typeof initialDetails>>;
  handleChangeDetails: HandleChangeDetails;
  workflowCompleted: boolean;
  setWorkflowCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  workflowInstance: any;
  setWorkflowInstance: React.Dispatch<React.SetStateAction<any>>;
  navigateForCancel: () => void;
  networkErrors: string[];
  setNetworkErrors: React.Dispatch<React.SetStateAction<string[]>>;
  getInfoMessages: GetInfoMessagesFunction;
  modifyStatuses: ModifyStatusesFunction;
  inputExtractionRights: any[];
  outputExtractionRights: any[];
  setInputExtractionRights: React.Dispatch<React.SetStateAction<any[]>>;
  setOutputExtractionRights: React.Dispatch<React.SetStateAction<any[]>>;
  context: ExtractionRightApprovalType;
  setContext: React.Dispatch<React.SetStateAction<ExtractionRightApprovalType>>;
  validateAmalgamation: (rights: any) => void;
  inputExtractionRightTable: JSX.Element;
  outputExtractionRightTable: JSX.Element;
  cancelActions: Array<{
    label: string;
    warning?: string;
  }>;
  cancelInformation: JSX.Element;
  cancelIndex: number;
  setCancelIndex: React.Dispatch<React.SetStateAction<number>>;
};

const AmalgamateOrSubdivideExtractionRightContext = React.createContext<
  ContextValue | undefined
>(undefined);

const AmalgamateOrSubdivideExtractionRightsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const maxStep = 7;
  const [currentStep, stepHelpers] = useStep(maxStep);
  const { getInfoMessages, modifyStatuses } = useStepStatuses(maxStep);
  const handleGoBackOrClose = HandleGoBackOrClose();
  const [details, setDetails] = React.useState(initialDetails);
  const [context, setContext] = React.useState<ExtractionRightApprovalType>(
    ExtractionRightApprovalType.Amalgamate,
  );
  const [inputExtractionRights, setInputExtractionRights] = React.useState<
    any[]
  >([]);
  const [outputExtractionRights, setOutputExtractionRights] = React.useState<
    any[]
  >([]);
  const [workflowCompleted, setWorkflowCompleted] =
    React.useState<boolean>(false);
  const [workflowInstance, setWorkflowInstance] = React.useState<any>();
  const [networkErrors, setNetworkErrors] = React.useState<string[]>([]);
  const [cancelIndex, setCancelIndex] = React.useState<number>(-1);

  const handleChangeDetails = (key: string, value: any, subKey?: string) => {
    setDetails((prevState: any) => {
      const updatedDetails: any = { ...prevState };
      if (subKey) {
        updatedDetails[key] = { ...prevState[key], [subKey]: value };
      } else {
        updatedDetails[key] = value;
      }
      return updatedDetails;
    });
  };

  const validateAmalgamation = (rights: any) => {
    const canAmalgamate: CanAmalgamate =
      getExtractionRightsCanAmalgamate(rights);
    const fieldName = "input_extraction_rights";

    if (canAmalgamate === "") {
      modifyStatuses({
        stepNumber: 2,
        fieldName,
      });
      return;
    }

    if (canAmalgamate === "yes") {
      modifyStatuses({
        stepNumber: 2,
        fieldName,
        infoType: "success",
        message: t("level1wrs.tab_info_panel.extraction_right.success", {
          context: canAmalgamate,
        }) as string,
      });
      return;
    }

    modifyStatuses({
      stepNumber: 2,
      fieldName,
      infoType: "error",
      message: t("level1wrs.tab_info_panel.extraction_right.warning", {
        context: canAmalgamate,
      }) as string,
    });
  };

  const mapExtractionRight = (extractionRight: any) => {
    return {
      id: extractionRight.id,
      name: extractionRight?.name,
      nominalVolume: formatVolume(extractionRight?.volume),
      waterClass: extractionRight?.waterClass?.name,
      level0wrs: extractionRight?.level0Resource?.identifier,
      view: (
        <Link
          to={`/polestar/subscribers/${extractionRight?.subscriber?.id}?level0ResourceId=${extractionRight?.level0Resource?.id}&extractionRightId=${extractionRight?.id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="btn-default flex items-center gap-3 w-min"
        >
          <ExternalLinkIcon className="w-4 h-4" />
          {t("common.view")}
        </Link>
      ),
      status: extractionRight?.isActive ? (
        <Tag status="success">{t("common.active")}</Tag>
      ) : (
        <Tag status="error">{t("common.inactive")}</Tag>
      ),
    };
  };

  const inputExtractionRightsTableData = inputExtractionRights
    ? inputExtractionRights.map((extractionRight) =>
        mapExtractionRight(extractionRight),
      )
    : [];
  const outputExtractionRightsTableData = outputExtractionRights
    ? outputExtractionRights.map((extractionRight) =>
        mapExtractionRight(extractionRight),
      )
    : [];

  const rightsFields = [
    {
      title: t("extraction_right.number"),
      name: "name",
    },
    {
      title: t("common.level0wrs"),
      name: "level0wrs",
    },
    {
      title: t("water_class.water_class_header"),
      name: "waterClass",
    },
    {
      title: t("extraction_right.volume"),
      name: "nominalVolume",
    },
    {
      title: t("common.status"),
      name: "status",
    },
    {
      title: "",
      name: "view",
    },
  ];

  const navigateForCancel = handleGoBackOrClose;

  const outputExtractionRightTable = (
    <Table
      containerClassName="scrollbar-hide mt-2"
      data={outputExtractionRightsTableData}
      fields={rightsFields}
    />
  );

  const inputExtractionRightTable = (
    <Table
      containerClassName="scrollbar-hide mt-2"
      data={inputExtractionRightsTableData}
      fields={rightsFields}
    />
  );

  const cancelActions: Array<{
    label: string;
    warning?: string;
  }> = [
    {
      label: t(
        "approval.subdivide_and_amalgamate.create.step_4.info_option_1",
        {
          context,
        },
      ),
    },
    {
      label: t(
        "approval.subdivide_and_amalgamate.create.step_4.info_option_2",
        {
          context,
        },
      ),
      warning: t(
        "approval.subdivide_and_amalgamate.create.step_4.info_option_2_warning",
        {
          context,
        },
      ) as string,
    },
  ];

  const cancelInformation = (
    <div>
      <p>{t("approval.subdivide_and_amalgamate.create.step_4.info")}</p>
      <div>
        <ul className="pl-4">
          {cancelActions.map((item, index) => (
            <li key={index}>
              {index + 1}. {item.label}
            </li>
          ))}
        </ul>
        <ul className="pt-4">
          {cancelActions.map((ca, index) => {
            if (!ca.warning) {
              return null;
            } else {
              return (
                <li key={index} className="flex items-start gap-2">
                  <InformationCircleIcon className="text-yellow-600 w-5 h-5 shrink-0" />
                  <span className="text-sm">{ca.warning as string}</span>
                </li>
              );
            }
          })}
        </ul>
      </div>
    </div>
  );

  const value: ContextValue = {
    currentStep,
    stepHelpers,
    details,
    setDetails,
    handleChangeDetails,
    workflowCompleted,
    setWorkflowCompleted,
    workflowInstance,
    setWorkflowInstance,
    navigateForCancel,
    networkErrors,
    setNetworkErrors,
    getInfoMessages,
    modifyStatuses,
    inputExtractionRights,
    outputExtractionRights,
    setInputExtractionRights,
    setOutputExtractionRights,
    context,
    setContext,
    validateAmalgamation,
    inputExtractionRightTable,
    outputExtractionRightTable,
    cancelActions,
    cancelInformation,
    cancelIndex,
    setCancelIndex,
  };

  return (
    <AmalgamateOrSubdivideExtractionRightContext.Provider value={value}>
      {children}
    </AmalgamateOrSubdivideExtractionRightContext.Provider>
  );
};

const useAmalgamateOrSubdivideExtractionRightsContext = () => {
  const context = React.useContext(AmalgamateOrSubdivideExtractionRightContext);
  if (context === undefined) {
    throw new Error(
      "useAmalgamateOrSubdivideExtractionRightsContext must be used within a AmalgamateOrSubdivideExtractionRightsProvider",
    );
  }
  return context;
};

export {
  AmalgamateOrSubdivideExtractionRightsProvider,
  useAmalgamateOrSubdivideExtractionRightsContext,
};

import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import { useAdminTransferContext } from "@context/AdminTransferContext";
import EventTransactionPaginatedTable from "@components/table/EventTransactionPaginatedTable";
import EndButtonModal from "@components/shared/EndButton";

const AdminTransferWorkflowCompleted = () => {
  const { t } = useTranslation();
  const { workflowInstance } = useAdminTransferContext();

  return (
    <div className="flex flex-col gap-6 p-6 grow justify-between">
      <div className="flex flex-col h-full">
        <div className="flex flex-row gap-10">
          <Heading light>{t("level0wrs.create.workflow_complete")}</Heading>
        </div>
        <div className="flex flex-col grow h-full">
          <EventTransactionPaginatedTable
            references={[workflowInstance?.id || ""]}
            stickyHeader={true}
          />
        </div>
      </div>
      <footer className="flex gap-4 -mx-2 mt-6 p-6 pb-0 border-t border-gray-200">
        <EndButtonModal />
      </footer>
    </div>
  );
};

export default AdminTransferWorkflowCompleted;

const TabNav: React.FunctionComponent<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <nav
      className="flex space-x-8 px-6 overflow-x-auto border-b border-gray-200"
      aria-label="Tabs"
    >
      {children}
    </nav>
  );
};

export default TabNav;

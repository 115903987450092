import DecimalInput from "@components/form/DecimalInput";
import Label from "@components/form/Label";
import Legend from "@components/form/Legend";
import DropzoneBox from "@components/shared/DropzoneBox";
import { useMeterDeclarationContext } from "@context/MeterDeclarationContext";
import { useEvidenceContext } from "@context/shared/EvidenceContext";
import { useCreateDeclaration } from "@hooks/mutation/zenith/useCreateDeclaration";
import { useCreateEvidence } from "@hooks/mutation/useCreateEvidence";
import { formatVolume } from "@utils/formatVolume";
import { toastError, toastSuccess } from "@utils/toast";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ConfirmModal from "@components/shared/ConfirmModal";
import { useNavigate } from "react-router-dom";
import { DBTables } from "@utils/constants/dbTables";

type WorkflowWaterDeclarationStep2Props = {
  onCancel: () => void;
  onSubmit: () => void;
};

const WorkflowWaterDeclarationStep2: FunctionComponent<
  WorkflowWaterDeclarationStep2Props
> = ({ onCancel, onSubmit }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { evidences, getRootProps, getInputProps, handleDeleteFile } =
    useEvidenceContext();
  const { declaration, setDeclaration, setWorkflowInstance } =
    useMeterDeclarationContext();
  const [errors, setErrors] = useState({
    reading: "",
  });
  const {
    mutateAsync: createDeclarationMutation,
    isLoading: isCreateDeclarationLoading,
  } = useCreateDeclaration();
  const {
    mutateAsync: createEvidenceMutation,
    isLoading: isCreateEvidenceLoading,
  } = useCreateEvidence();

  const getCurrentUsage = (currentReading: number, lastReading: number) => {
    if (!lastReading) {
      return currentReading || 0;
    } else if (currentReading < lastReading) {
      return 0;
    }

    return currentReading - lastReading;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (!declaration?.reading) {
      setErrors({
        ...errors,
        reading: t("zenith.water_declaration.errors.reading_required"),
      });
      return;
    }

    setErrors({
      reading: "",
    });

    setShowConfirmModal(true);
  };

  const handleConfirm = async () => {
    try {
      const response = await createDeclarationMutation({
        reading: +declaration.reading / 1000000.0,
        readAt: new Date(declaration.readAt ?? ""),
        isEstimated: declaration.isEstimated,
        extractionPointName: declaration.extractionPoint?.name,
        meterId: declaration.meter?.serialNo,
        isOpening: !declaration.extractionPoint?.lastDeclaration,
      });

      if (evidences.length) {
        await uploadFiles(response.declaration, response.workflowInstance);
      }
      toastSuccess(t("declaration.toast.save_success"));

      setDeclaration({
        ...declaration,
        ...response.declaration,
      });
      setWorkflowInstance(response.workflowInstance);
    } catch (error) {
      toastError(t("declaration.toast.save_failure"));
    }
    setShowConfirmModal(false);

    onSubmit();
  };

  const uploadFiles = async (declaration: any, workflowInstance: any) => {
    const promises = evidences.map(async (file: any) => {
      return createEvidenceMutation({
        title: t("declaration.evidence_data.title"),
        references: [
          {
            referenceId: declaration?.id,
            referenceTable: DBTables.Declarations,
          },
          {
            referenceId: declaration?.subscriber?.id,
            referenceTable: DBTables.Subscribers,
          },
          {
            referenceId: workflowInstance?.id,
            referenceTable: DBTables.WorkflowInstances,
          },
        ],
        description: `${t("declaration.evidence_data.description_first")} ${formatVolume(
          declaration?.reading,
        )}, ${t("declaration.evidence_data.description_second")} ${
          declaration?.extractionPoint?.name
        }`,
        isPublic: false,
        isEncrypted: true,
        attachment: file,
      });
    });

    return Promise.all(promises);
  };

  useEffect(() => {
    setDeclaration({
      ...declaration,
      evidences,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evidences]);

  return (
    <>
      <form className="flex flex-col grow" onSubmit={handleSubmit}>
        <div className="flex grow gap-4">
          <fieldset className="w-2/3 flex flex-col gap-4">
            <Legend>{t("common.declaration")}</Legend>
            <div className="mt-2 grid grid-cols-1 sm:grid-cols-3">
              <div>
                <Label>{t("declaration.form.last_read")}</Label>
                <p className="mt-1 font-bold text-2xl">
                  {declaration?.extractionPoint?.lastDeclaration
                    ? formatVolume(
                        declaration?.extractionPoint?.lastDeclaration?.reading,
                        "",
                      )
                    : "-"}
                </p>
              </div>
              <div>
                <Label>{t("declaration.enter_read")}</Label>
                <DecimalInput
                  value={String(declaration?.reading / 1000000.0)}
                  onChange={value => {
                    setDeclaration((declaration: any) => {
                      return {
                        ...declaration,
                        reading: Number(value) * 1000000,
                      };
                    });
                  }}
                  errorMessage={errors?.reading}
                />
              </div>
              <div>
                <Label>{t("declaration.form.usage_calculation")}</Label>
                <p className="mt-1 font-bold text-2xl">
                  {formatVolume(
                    getCurrentUsage(
                      declaration?.reading,
                      declaration?.extractionPoint?.lastDeclaration?.reading,
                    ),
                  )}
                </p>
              </div>
            </div>
            <div className="flex flex-wrap gap-6">
              <p className="w-full">{t("declaration.form.is_estimated")}</p>
              <Label className="flex items-center gap-2 cursor-pointer">
                <input
                  type="radio"
                  name="estimated"
                  value="estimated"
                  checked={declaration?.isEstimated}
                  onChange={() => {
                    setDeclaration({
                      ...declaration,
                      isEstimated: true,
                    });
                  }}
                />
                {t("declaration.estimated")}
              </Label>
              <Label className="flex items-center gap-2 cursor-pointer">
                <input
                  type="radio"
                  name="estimated"
                  value="actual"
                  checked={!declaration?.isEstimated}
                  onChange={() => {
                    setDeclaration({
                      ...declaration,
                      isEstimated: false,
                    });
                  }}
                />
                {t("declaration.actual")}
              </Label>
            </div>
          </fieldset>
          <fieldset className="w-1/3 flex flex-col gap-2">
            <Legend>{t("evidence.supporting")}</Legend>
            <div className="mt-2">
              <DropzoneBox
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                files={evidences}
                onDelete={handleDeleteFile}
              />
            </div>
          </fieldset>
        </div>
        <footer className="p-4 flex gap-2">
          <button
            type="button"
            className="btn-outline-primary text-sm font-semibold"
            onClick={onCancel}
          >
            {t("common.prev_step")}
          </button>
          <button type="submit" className="btn-primary text-sm font-semibold">
            {t("common.next_step")}
          </button>
          <button
            type="button"
            className="btn-default text-sm font-semibold"
            onClick={() => navigate("/zenith/workflows")}
          >
            {t("common.cancel")}
          </button>
        </footer>
      </form>
      <ConfirmModal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleConfirm}
        isSubmitting={isCreateDeclarationLoading || isCreateEvidenceLoading}
      >
        {t("declaration.modal.confirm")}
      </ConfirmModal>
    </>
  );
};

export default WorkflowWaterDeclarationStep2;

import React from "react";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import Label from "@components/form/Label";
import TextArea from "@components/form/TextArea";
import TextInput from "@components/form/TextInput";
import {
  NotificationType,
  SpecialAnnouncementType,
  useNotificationContext,
} from "@context/NotificationContext";
import ArrowRightCalendarIcon from "@components/icons/ArrowRightCalendarIcon";
import { formatDatetimeInput } from "@utils/formatDate";

const CreateNotificationStep1: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    details,
    handleChangeDetails,
    navigateForCancel,
    stepHelpers,
    context,
    fromSpecialAnnouncement,
  } = useNotificationContext();

  const isShutdownType = context === NotificationType.Shutdown;
  const isBulletinType = context === NotificationType.Bulletin;
  const isSpecialAnnouncementType =
    details.message.type === NotificationType.SpecialAnnouncement;
  const canUploadEvidence = isSpecialAnnouncementType || isBulletinType;

  const notificationTypes = [
    ...(!fromSpecialAnnouncement
      ? [
          {
            type: NotificationType.News,
            label: t("notification.type_news"),
          },
          {
            type: NotificationType.Bulletin,
            label: t("notification.type_bulletin"),
          },
          {
            type: NotificationType.Shutdown,
            label: t("notification.type_shutdown"),
          },
          {
            type: NotificationType.ForwardDraw,
            label: t("notification.type_forward_draw"),
          },
        ]
      : []),
    {
      type: NotificationType.SpecialAnnouncement,
      label: t("notification.type_special_announcement"),
    },
  ];

  const specialAnnouncementTypes = [
    ...(fromSpecialAnnouncement === undefined ||
    fromSpecialAnnouncement === "new"
      ? [
          {
            type: SpecialAnnouncementType.Start,
            label: t("notification.special_type_start"),
          },
        ]
      : []),
    {
      type: SpecialAnnouncementType.End,
      label: t("notification.special_type_end"),
    },
  ];

  const handleSave = (e: any) => {
    e.preventDefault();
    stepHelpers.goToNextStep();
  };

  const validateEvidenceFileSize = () => {
    if (details.message.evidence && details.message.evidence.size > 10485760) {
      return false;
    }
    return true;
  };

  return (
    <form className="flex flex-col grow" onSubmit={handleSave}>
      <div className="p-6 grow">
        <Heading className="text-xl leading-6" light={true}>
          {t("notification.select_type")}
        </Heading>
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-6">
            <Label>{t("notification.enter_type")}</Label>
            <fieldset className="mt-4">
              <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                {notificationTypes.map((notiType) => (
                  <div key={notiType.type} className="flex items-center">
                    <input
                      id={notiType.type}
                      type="radio"
                      name="notificationType"
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      checked={notiType.type === details.message.type}
                      value={details.message.type}
                      onChange={(e) => {
                        if (e.target.checked) {
                          handleChangeDetails("message", notiType.type, "type");
                          handleChangeDetails("level0wrsIds", []);
                          handleChangeDetails("subscriberIds", []);
                          handleChangeDetails("specialAnnouncementIds", []);
                        }
                      }}
                    />
                    <label
                      htmlFor={notiType.type}
                      className="ml-3 block text-sm font-medium text-gray-700"
                    >
                      {notiType.label}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>
        </div>
        {details.message.type && (
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            {isShutdownType && (
              <div className="sm:col-span-4">
                <Label>{t("notification.enter_shutdown_dates")}</Label>
                <div className="mt-1 flex rounded-md items-center">
                  <TextInput
                    type="datetime-local"
                    placeholder={t("common.from") as string}
                    value={
                      details.message?.fromDate &&
                      formatDatetimeInput(details.message?.fromDate)
                    }
                    onChange={(e) => {
                      handleChangeDetails(
                        "message",
                        new Date(e.target.value),
                        "fromDate"
                      );
                    }}
                  />
                  <div className="w-10">
                    <ArrowRightCalendarIcon />
                  </div>
                  <TextInput
                    type="datetime-local"
                    placeholder={t("common.to") as string}
                    min={formatDatetimeInput(details.message?.fromDate)}
                    value={
                      details.message?.toDate &&
                      formatDatetimeInput(details.message?.toDate)
                    }
                    onChange={(e) => {
                      handleChangeDetails(
                        "message",
                        new Date(e.target.value),
                        "toDate"
                      );
                    }}
                  />
                </div>
              </div>
            )}
            {isSpecialAnnouncementType && (
              <div className="sm:col-span-6">
                <Label>{t("notification.enter_special_type")}</Label>
                <fieldset className="mt-4">
                  <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                    {specialAnnouncementTypes.map((notiType) => (
                      <div key={notiType.type} className="flex items-center">
                        <input
                          id={notiType.type}
                          name="specialAnnouncementType"
                          type="radio"
                          className="h-4 w-4 border-gray-300"
                          defaultChecked={
                            notiType.type ===
                            details.message.specialAnnouncementType
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              handleChangeDetails(
                                "message",
                                notiType.type,
                                "specialAnnouncementType"
                              );
                              handleChangeDetails("level0wrsIds", []);
                              handleChangeDetails("subscriberIds", []);
                            }
                          }}
                        />
                        <label
                          htmlFor={notiType.type}
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          {notiType.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
                <fieldset className="sm:col-span-4 mt-4">
                  <div className="max-w-md">
                    <Label htmlFor="specialAnnouncementDate">
                      {t("notification.enter_date", { context })}
                    </Label>
                    <TextInput
                      type="datetime-local"
                      id="specialAnnouncementDate"
                      value={
                        details.message?.specialAnnouncementDate &&
                        formatDatetimeInput(
                          details.message?.specialAnnouncementDate
                        )
                      }
                      onChange={(e) => {
                        handleChangeDetails(
                          "message",
                          new Date(e.target.value),
                          "specialAnnouncementDate"
                        );
                      }}
                    />
                  </div>
                </fieldset>
              </div>
            )}
            <div className="sm:col-span-6">
              <Label htmlFor="subject">{t("notification.enter_subject")}</Label>
              <div className="mt-1 flex rounded-md">
                <TextInput
                  type="text"
                  id="subject"
                  value={details.message?.subject || ""}
                  onChange={(e) =>
                    handleChangeDetails("message", e.target.value, "subject")
                  }
                  required
                />
              </div>
            </div>
            <div className="sm:col-span-6">
              <Label htmlFor="body">{t("notification.enter_body")}</Label>
              <div className="mt-1 flex rounded-md">
                <TextArea
                  id="body"
                  value={details.message?.body || ""}
                  onChange={(e) => {
                    handleChangeDetails("message", e.target.value, "body");
                  }}
                  rows={8}
                  required
                />
              </div>
            </div>
            {canUploadEvidence && (
              <div className="sm:col-span-2">
                {details.message?.evidence ? (
                  <>
                    <button
                      className="btn-default text-sm"
                      onClick={() =>
                        handleChangeDetails("message", undefined, "evidence")
                      }
                    >
                      {t("notification.remove_evidence", {
                        name: details.message.evidence?.name,
                      })}
                    </button>
                    {!validateEvidenceFileSize() && (
                      <p className="text-red-600 text-sm m-2">
                        {t("notification.evidence_error")}
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    <label htmlFor="evidence" className="btn-default text-sm">
                      {`${t("notification.enter_evidence")} (${
                        isBulletinType
                          ? t("common.required.text")
                          : t("common.optional")
                      })`}
                    </label>
                    <input
                      type="file"
                      id="evidence"
                      accept="application/pdf"
                      className="opacity-0"
                      required={isBulletinType}
                      onChange={(e) =>
                        handleChangeDetails(
                          "message",
                          e.target.files?.[0],
                          "evidence"
                        )
                      }
                    />
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <div className="border-t w-full border-gray-200 mt-5 py-4 px-6 flex gap-1">
        <button
          type="submit"
          className="btn-primary"
          disabled={
            !details.message.type ||
            !details.message.subject ||
            !details.message.body ||
            (details.message.type === NotificationType.Bulletin &&
              !validateEvidenceFileSize())
          }
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </div>
    </form>
  );
};

export default CreateNotificationStep1;

import WorkflowGroup from "@components/shared/WorkflowGroup";
import { useAppContext } from "@context/AppContext";
import { useGetAllWorkflowGroups } from "@hooks/query/useGetAllWorkflowGroups";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const WorkflowIndex: FunctionComponent = () => {
  const { t } = useTranslation();
  const { setInfoPanel } = useAppContext();
  const [myWorkflows, setMyWorkflows] = useState<any[]>([]);
  const { data: workflowGroups } = useGetAllWorkflowGroups({
    onSuccess: (data: any[]) => {
      const myWorkflows: any[] = [];
      for (const workflowGroup of data) {
        for (const workflow of workflowGroup.workflows) {
          if (workflow.isFavorite) {
            myWorkflows.push(workflow);
          }
        }
      }
      setMyWorkflows(myWorkflows);
    },
  });

  useEffect(() => {
    setInfoPanel(
      <div className="space-y-1 leading-7 text-gray-500 text-sm flex flex-col gap-4">
        <p>{t("zenith.workflows.info1")}</p>
        <p>{t("zenith.workflows.info2")}</p>
        <p>{t("zenith.workflows.info3")}</p>
        <p>{t("zenith.workflows.info4")}</p>
      </div>
    );
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex flex-col gap-4 lg:gap-6">
      {myWorkflows.length > 0 && (
        <WorkflowGroup identifier="favorites" workflows={myWorkflows} />
      )}
      {workflowGroups?.map((workflowGroup: any) => (
        <WorkflowGroup
          identifier={workflowGroup.identifier}
          workflows={workflowGroup.workflows}
        />
      ))}
    </div>
  );
};

export default WorkflowIndex;

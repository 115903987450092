import { useTranslation } from "react-i18next";

import StepButton from "@components/shared/StepButton";
import WorkflowPanels from "@components/form/WorkflowPanels";
import {
  SpecialAnnouncementType,
  useNotificationContext,
} from "@context/NotificationContext";
import CreateNotificationStep1Label from "./CreateNotificationStep1Label";
import CreateNotificationStep1 from "./CreateNotificationStep1";
import CreateNotificationStep2Label from "./CreateNotificationStep2Label";
import CreateNotificationStep2 from "./CreateNotificationStep2";
import CreateNotificationStep3 from "./CreateNotificationStep3";
import CreateNotificationStep2SelectLevel0WRS from "./CreateNotificationStep2SelectLevel0WRS";
import CreateNotificationStep2SelectLevelSpecialAnnouncements from "./CreateNotificationStep2SelectSpecialAnnouncements";
import InfoPanel from "../InfoPanel";

const CreateNotificationWorkflow = () => {
  const { t } = useTranslation();
  const { currentStep, details, context } = useNotificationContext();

  const isStartingSA = context === SpecialAnnouncementType.Start;
  const isEndingSA = context === SpecialAnnouncementType.End;

  const steps = [
    {
      label: (
        <StepButton
          index={0}
          currentStep={currentStep}
          info={<CreateNotificationStep1Label />}
        >
          {t("notification.select_type")}
        </StepButton>
      ),
      panel: <CreateNotificationStep1 />,
    },
    ...(isStartingSA
      ? [
          {
            label: (
              <StepButton
                index={1}
                currentStep={currentStep}
                info={
                  <p className="pl-2 text-sm font-bold text-gray-500">
                    {t("notification.selected_level0wrs", {
                      count: details.level0wrsIds.length,
                    })}
                  </p>
                }
              >
                {t("water_class.select_level0_resource")}
              </StepButton>
            ),
            panel: <CreateNotificationStep2SelectLevel0WRS />,
            infoPanel: (
              <InfoPanel
                warnings={[t("notification.info_panel.step_2_info")]}
              ></InfoPanel>
            ),
          },
        ]
      : []),
    {
      label: (
        <StepButton
          index={isStartingSA ? 2 : 1}
          currentStep={currentStep}
          info={isEndingSA ? <></> : <CreateNotificationStep2Label />}
        >
          {isEndingSA
            ? t("notification.select_special_announcements")
            : t("notification.select_subscribers")}
        </StepButton>
      ),
      panel: isEndingSA ? (
        <CreateNotificationStep2SelectLevelSpecialAnnouncements />
      ) : (
        <CreateNotificationStep2 />
      ),
    },
    {
      label: (
        <StepButton index={isStartingSA ? 3 : 2} currentStep={currentStep}>
          {t("notification.confirm_values")}
        </StepButton>
      ),
      panel: <CreateNotificationStep3 />,
    },
  ];

  return <WorkflowPanels selectedStep={currentStep} steps={steps} />;
};

export default CreateNotificationWorkflow;

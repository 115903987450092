import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Heading1 from "@components/layout/Heading";
import Table from "@components/layout/Table";
import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import SearchInput from "@components/form/SearchInput";
import ExternalLinkIcon from "@components/icons/ExternalLinkIcon";
import AccountingPeriodChecklistFooter from "./AccountingPeriodChecklistFooter";
import { useAppContext } from "@context/AppContext";
import { useAccountingPeriodChecksContext } from "@context/AccountingPeriodChecksContext";
import { useAllDeclarations } from "@hooks/query/useAllDeclarations";
import { formatDateTime } from "@utils/formatDateTime";
import { formatVolume } from "@utils/formatVolume";

const MeterReadingsChecklist: React.FunctionComponent = () => {
  const { loggedInInfo } = useAppContext();
  const { currentAccountingPeriodCheck } = useAccountingPeriodChecksContext();
  const { t } = useTranslation();

  const [filter, setFilter] = useState<{
    subscriberIdName?: string;
    extractionPointName?: string;
    meterName?: string;
    selectedScheme?: { name: string; id: string };
  }>({});

  const { data: declarations = [], isLoading } = useAllDeclarations({
    params: {
      level1ResourceId: currentAccountingPeriodCheck?.level1ResourceId,
      periodStart: currentAccountingPeriodCheck?.accountingPeriod?.periodStart,
      periodEnd:
        currentAccountingPeriodCheck?.accountingPeriod?.periodEnd &&
        new Date(currentAccountingPeriodCheck.accountingPeriod.periodEnd),
    },
  });

  const tableFields = [
    { title: t("common.timestamp"), name: "timestamp" },
    { title: t("subscriber.name"), name: "subscriberName" },
    { title: t("subscriber.account_number"), name: "subscriberAccountNumber" },
    { title: t("extraction_point.name"), name: "extractionPointName" },
    { title: t("meter.serial_no"), name: "meterName" },
    { title: t("common.level0wrs"), name: "level0Resource" },
    {
      title: `${t("declaration.reading")}`,
      name: "reading",
    },
    {
      title: `${t("common.volume")} (${t("common.volume_unit")})`,
      name: "volume",
    },
  ];

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  return (
    <>
      <div className="flex flex-col p-4 grow">
        <div className="flex flex-row gap-10">
          <Heading1 light>{t("common.declarations")}</Heading1>
          <Heading1>{declarations.length}</Heading1>
        </div>
        <div className="flex flex-col grow gap-4">
          <form className="flex flex-wrap gap-3 justify-start">
            <div className="w-full sm:w-auto">
              <Label htmlFor="from-date">
                {t("subscriber.filter_name_id")}
              </Label>
              <div className="flex flex-col sm:flex-row gap-1">
                <div className="max-w-max">
                  <SearchInput
                    id="subscriber"
                    placeholder={t("common.search") as string}
                    value={filter.subscriberIdName}
                    onChange={e => {
                      handleFilterChange("subscriberIdName", e?.target?.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-full sm:w-auto">
              <Label htmlFor="from-date">
                {t("accounting_period.checklist.filter_by_extraction_point_id")}
              </Label>
              <div className="flex flex-col sm:flex-row gap-1">
                {" "}
                <div className="max-w-max">
                  <SearchInput
                    id="extraction_point_name"
                    placeholder={t("common.search") as string}
                    value={filter.extractionPointName}
                    onChange={e => {
                      handleFilterChange(
                        "extractionPointName",
                        e?.target?.value,
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-full sm:w-auto">
              <Label htmlFor="from-date">
                {t("accounting_period.checklist.filter_by_meter_id")}
              </Label>
              <div className="flex flex-col sm:flex-row gap-1">
                {" "}
                <div className="max-w-max">
                  <TextInput
                    type="text"
                    placeholder={t("common.search") as string}
                    value={filter.meterName}
                    onChange={e => {
                      handleFilterChange("meterName", e?.target?.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex items-end w-full ml-auto sm:w-auto">
              <Link
                className="btn-secondary flex items-center gap-1"
                to={`/polestar/level1wrs/${
                  declarations[0]
                    ? declarations[0].extractionPoint?.subscriber
                        ?.level1ResourceId
                    : " "
                }/declarations/create?newWindow=true`}
                target="_blank"
              >
                <ExternalLinkIcon className="w-4 h-4" />
                {t("accounting_period.checklist.add_declaration")}
              </Link>
            </div>
            <div className="flex items-end  w-full sm:w-auto">
              <Link
                className="btn-secondary flex items-center gap-1 "
                to={`/polestar/level1wrs/${
                  declarations[0]
                    ? declarations[0].extractionPoint?.subscriber
                        ?.level1ResourceId
                    : " "
                }/bulk_meter_upload?newWindow=true`}
                target="_blank"
              >
                <ExternalLinkIcon className="w-4 h-4" />
                {t("extraction_point.upload_bulk_meter")}
              </Link>
            </div>
          </form>
          <Table
            fields={tableFields}
            data={declarations
              .map((record: any) => {
                return {
                  ...record,
                  timestamp: formatDateTime(new Date(record.readAt)),
                  subscriberName: record?.extractionPoint?.subscriber?.name,
                  subscriberAccountNumber:
                    record?.extractionPoint?.subscriber?.accountNumber,
                  extractionPointName: record?.extractionPoint?.name,
                  meterName: record?.meter?.serialNo,
                  zone: record?.extractionPoint?.level0ResourceId,
                  level0Resource:
                    record?.extractionPoint?.level0WRS?.identifier,
                  volume: formatVolume(record?.volume, ""),
                  reading: formatVolume(record?.reading, ""),
                };
              })
              ?.filter(
                (row: any) =>
                  !filter.subscriberIdName ||
                  !filter.subscriberIdName.length ||
                  row?.subscriberName
                    ?.toString()
                    .toLowerCase()
                    .includes(
                      filter.subscriberIdName.toString().toLowerCase(),
                    ) ||
                  row?.subscriberAccountNumber
                    ?.toString()
                    .includes(filter.subscriberIdName.toString()),
              )
              ?.filter(
                (row: any) =>
                  !filter.extractionPointName ||
                  !filter.extractionPointName.length ||
                  row?.extractionPointName
                    ?.toString()
                    .toLowerCase()
                    .includes(
                      filter.extractionPointName.toString().toLowerCase(),
                    ),
              )
              ?.filter(
                (row: any) =>
                  !filter.meterName ||
                  !filter.meterName.length ||
                  row?.meterName
                    ?.toString()
                    .toLowerCase()
                    .includes(filter.meterName.toString().toLowerCase()),
              )}
            pageSize={100}
            loading={isLoading}
            stickyHeader
          />
        </div>
      </div>

      <AccountingPeriodChecklistFooter
        stepData={{
          meterReadsCheckedAt: new Date(),
          meterReadsCheckedByUserId: loggedInInfo?.userDetails?.id,
        }}
      />
    </>
  );
};

export default MeterReadingsChecklist;

import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import UserForm from "@components/form/user/UserForm";
import { useSubscriberCreateOrEditContext } from "@context/SubscriberCreateOrEditContext";
import { useStepContext } from "@context/shared/StepContext";

const CreatePrimaryUser = () => {
  const { t } = useTranslation();
  const { stepHelpers } = useStepContext();
  const { cancel, subscriber, user, setUser } =
    useSubscriberCreateOrEditContext();

  const handleSubmit = (data: any) => {
    setUser(data);
    stepHelpers.goToNextStep();
  };

  return (
    <div className="flex flex-col grow p-6">
      <Heading light className="mb-8">
        {t("user.create_primary_user.details")}
      </Heading>

      <UserForm
        defaultValues={{
          ...user,
          subscriberId: subscriber.id,
          isPrimary: true,
        }}
        onSubmit={handleSubmit}
        onCancel={cancel}
        isPrimary
      />
    </div>
  );
};

export default CreatePrimaryUser;

import classNames from "classnames";
import { InputHTMLAttributes } from "react";

interface ITextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  errorMessage?: string;
  rows?: number;
}

const TextArea: React.FunctionComponent<ITextAreaProps> = ({
  className,
  errorMessage,
  rows,
  ...props
}) => {
  return (
    <>
      <textarea
        className={classNames(
          "block w-full appearance-none rounded-sm border border-gray-300 px-3 py-2 placeholder-gray-400 focus:border-blue-500 focus:outline-blue-500 sm:text-sm",
          {
            "border-red-300": !!errorMessage,
          },
          className
        )}
        rows={rows}
        {...props}
      />
      {errorMessage && (
        <p className="mt-2 text-sm text-red-600">{errorMessage.toString()}</p>
      )}
    </>
  );
};

export default TextArea;

import React from "react";
import classNames from "classnames";
import {
  getCoreRowModel,
  getPaginationRowModel,
  getExpandedRowModel,
  useReactTable,
  getFilteredRowModel,
  getSortedRowModel,
  RowSelectionState,
  PaginationState,
  Row,
} from "@tanstack/react-table";
import { isFunction, kebabCase, noop, pick, values } from "lodash";
import { useTranslation } from "react-i18next";

import DebouncedInput from "@components/form/DebouncedInput";
import DataTable from "./DataTable";
import ColumnVisibility from "./ColumnVisibility";
import ExportCSVButton from "./ExportCSVButton";
import ExportAllRowsButton from "./ExportAllRowsButton";
import Pagination from "./Pagination";
import { useCacheRows } from "@hooks/useCacheRows";

type DataTableRendererProps = {
  name: string;
  data: any[];
  columns: any[];
  pagination: PaginationState;
  onPaginationChange: any;
  pageCount: number;
  isLoading: boolean;
  isFetching: boolean;
  stickyHeader?: boolean;
  fetchAll?: any;
  columnsVisibility?: boolean;
  subRowsKey?: string;
  onRowClick?: (row: Row<any>) => void;
  fetchAllDataKey?: string;
  transformExportData?: (data: any) => any;
};

const DataTableRenderer: React.FunctionComponent<DataTableRendererProps> = ({
  name,
  data,
  columns,
  pageCount,
  pagination,
  onPaginationChange,
  isLoading,
  isFetching,
  stickyHeader = false,
  fetchAll,
  columnsVisibility = false,
  subRowsKey = "subRows",
  onRowClick = noop,
  fetchAllDataKey = "data",
  transformExportData = noop,
}) => {
  const { t } = useTranslation();
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
  const containerRef = React.useRef<HTMLDivElement>(null);

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      pagination,
      rowSelection,
    },
    pageCount,
    onPaginationChange,
    manualPagination: true,
    enableRowSelection: true,
    getRowId: (originalRow) => originalRow.id,
    getSubRows: (row) => row[subRowsKey],
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const [cacheRows] = useCacheRows(table, isFetching);
  const selectionRows = React.useMemo(
    () => values(getSelectionRowsModel(rowSelection, cacheRows)),
    [rowSelection, cacheRows]
  );
  const totalSelectionRows = selectionRows.length;

  React.useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
  }, [pagination.pageIndex]);

  const canExportAllRows = data.length && isFunction(fetchAll);
  const fileName = `${kebabCase(name)}-${Date.now()}`;

  return (
    <>
      <div className="flex items-center justify-between">
        <DebouncedInput
          type="search"
          value={globalFilter ?? ""}
          onChange={(value) => setGlobalFilter(String(value))}
          placeholder={t("shared.table.search_all") as string}
        />

        <div className="inline-flex items-center gap-4">
          {totalSelectionRows ? (
            <ExportCSVButton
              fileName={fileName}
              data={selectionRows.flatMap((item) => transformExportData(item))}
            />
          ) : canExportAllRows ? (
            <ExportAllRowsButton
              fileName={fileName}
              fetch={fetchAll}
              dataKey={fetchAllDataKey}
              transformExportData={transformExportData}
            />
          ) : null}

          {columnsVisibility ? <ColumnVisibility table={table} /> : null}
        </div>
      </div>

      <div className="flex flex-col flex-1 bg-white">
        <div
          ref={containerRef}
          className={classNames({
            "h-0 grow relative overflow-auto scroll-smooth": stickyHeader,
          })}
        >
          <DataTable
            table={table}
            pagination={pagination}
            rowSelection={rowSelection}
            isLoading={isLoading}
            isFetching={isFetching}
            onRowClick={onRowClick}
            stickyHeader
          />
        </div>

        <Pagination
          className="border-t"
          table={table}
          isFetching={!isLoading && isFetching}
          totalSelectionRows={totalSelectionRows}
        />
      </div>
    </>
  );
};

const getSelectionRowsModel = (rowSelection: RowSelectionState, rows: any) => {
  const selectionIds = Object.entries(rowSelection).map(([id]) => id);

  return pick(rows, selectionIds);
};

export default DataTableRenderer;

import { checkPeriodEnd } from "@services/level0Resource";
import { useMutation } from "@tanstack/react-query";

export function useValidateLevel0ResourcePeriodEnd(options: any = {}) {
  return useMutation<any, any, { level1wrsId: string; periodEnd: Date }>({
    mutationKey: ["check_level_1_resource_period_end"],
    mutationFn: checkPeriodEnd,
    ...options,
  });
}

import { useMutation } from "@tanstack/react-query";
import { decomission, DecommissionPayload } from "@services/meter";

export function useDecommissionMeter(options: any = {}) {
  return useMutation<any, any, DecommissionPayload>({
    mutationKey: ["decommission_meter"],
    mutationFn: decomission,
    ...options,
  });
}

import { useTranslation } from "react-i18next";

import Tag from "@components/shared/Tag";
import { READING_CALCULATION } from "@services/declarations";
import { formatVolume } from "@utils/formatVolume";

type DeclarationTagProps = {
  value: string;
  volume?: number;
  saVolume?: number;
};

const DeclarationTag: React.FunctionComponent<DeclarationTagProps> = ({
  value,
  volume = 0,
  saVolume = 0,
}) => {
  const { t } = useTranslation();
  const stringMapping: Record<string, string> = {
    [READING_CALCULATION.CLICK_OVER]: t("declaration.click_over"),
    [READING_CALCULATION.BACKWARD]: t("declaration.backward"),
    [READING_CALCULATION.WATER_HARVESTING_START]: t(
      "declaration.water_harvesting_start",
    ),
    [READING_CALCULATION.WATER_HARVESTING]: t("declaration.water_harvesting"),
    [READING_CALCULATION.WATER_HARVESTING_END]: t(
      "declaration.water_harvesting_end",
    ),
    [READING_CALCULATION.SPECIAL_ANNOUNCEMENT]: t(
      "declaration.special_announcement",
    ),
    [READING_CALCULATION.METER_READING_ADJUSTMENT]: t(
      "declaration.meter_reading_adjustment",
    ),
    [READING_CALCULATION.METER_USAGE_ADJUSTMENT]: t(
      "declaration.meter_usage_adjustment",
    ),
    [READING_CALCULATION.DISTRIBUTION_LOSS]: t("declaration.distribution_loss"),
    [READING_CALCULATION.STOCK_AND_DOMESTIC]: t(
      "declaration.stock_and_domestic",
    ),
  };

  return (
    <Tag status="warning" className="">
      {stringMapping[value] ?? ""}
      {volume !== 0 ? ` ${formatVolume(volume)}` : ""}
      {saVolume !== 0
        ? `, ${stringMapping[READING_CALCULATION.SPECIAL_ANNOUNCEMENT]} ${formatVolume(saVolume)}`
        : ""}
    </Tag>
  );
};

export default DeclarationTag;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isFunction } from "lodash";

import api from "@services/api";

const createSeasonalTransfer = async (payload: {
  from: {
    subscriberId: string;
    waterClassId: string;
    level0ResourceId: string;
  };
  to: {
    subscriberId: string;
    waterClassId: string;
    level0ResourceId: string;
  };
  volume: number;
  price: number;
  description: string;
}) => {
  const { data } = await api.post("/admin/seasonalTransfers", payload);

  return [data.administrativeApproval, data.workflowInstance];
};

export const useCreateSeasonalTransfer = (params: any = {}) => {
  const { onSuccess, ...options } = params;
  const queryClient = useQueryClient();

  return useMutation(createSeasonalTransfer, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries({
        queryKey: ["administrative_approvals"],
      });
      if (isFunction(onSuccess)) {
        onSuccess(res);
      }
    },
    ...options,
  });
};

import classNames from "classnames";

type Props = {
  children: React.ReactNode;
  isSuccess: boolean;
  isActive?: boolean;
  className?: string;
};
const Circle: React.FunctionComponent<Props> = ({
  children,
  isSuccess,
  isActive = false,
  className,
}: any) => {
  return (
    <div
      className={classNames(
        "w-6 h-6 rounded-full grid text-white text-sm",
        {
          "bg-primary-2": isActive,
          "bg-green-600": isSuccess,
          "bg-gray-400": !isActive && !isSuccess,
        },
        className
      )}
    >
      <span className="m-auto">{children}</span>
    </div>
  );
};

export default Circle;

import WorkflowSteps from "@components/form/WorkflowSteps";
import WorkflowWaterDeclarationCompleted from "@components/form/workflows/water_declaration/WorkflowWaterDeclarationCompleted";
import WorkflowWaterDeclarationStep1 from "@components/form/workflows/water_declaration/WorkflowWaterDeclarationStep1";
import WorkflowWaterDeclarationStep2 from "@components/form/workflows/water_declaration/WorkflowWaterDeclarationStep2";
import {
  MeterDeclarationProvider,
  useMeterDeclarationContext,
} from "@context/MeterDeclarationContext";
import { EvidenceProvider } from "@context/shared/EvidenceContext";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const WorkflowWaterDeclaration: FunctionComponent = () => {
  return (
    <MeterDeclarationProvider>
      <EvidenceProvider>
        <MainComponent />
      </EvidenceProvider>
    </MeterDeclarationProvider>
  );
};

const MainComponent: FunctionComponent = () => {
  const { t } = useTranslation();
  const { setDeclaration } = useMeterDeclarationContext();
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    setDeclaration({
      readAt: new Date(),
      reading: 0,
      isEstimated: false,
      evidences: [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <WorkflowSteps
        title="Declare water extraction"
        selectedStep={currentStep}
        onStepButtonClick={(step) => setCurrentStep(step)}
        disableNavigation={currentStep === 2}
        steps={[
          {
            panel: (
              <WorkflowWaterDeclarationStep1
                onSubmit={() => setCurrentStep(1)}
              />
            ),
            infoPanel: (
              <>
                <p>{t("zenith.water_declaration.step1_info1")}</p>
                <p>{t("zenith.water_declaration.step1_info2")}</p>
                <p>{t("zenith.water_declaration.step1_info3")}</p>
                <p>{t("zenith.water_declaration.step1_info4")}</p>
              </>
            ),
          },
          {
            panel: (
              <WorkflowWaterDeclarationStep2
                onCancel={() => setCurrentStep(0)}
                onSubmit={() => setCurrentStep(2)}
              />
            ),
            infoPanel: (
              <>
                <p>{t("zenith.water_declaration.step2_info1")}</p>
                <p>{t("zenith.water_declaration.step2_info2")}</p>
                <p>{t("zenith.water_declaration.step2_info3")}</p>
                <p>{t("zenith.water_declaration.step2_info4")}</p>
                <p>{t("zenith.water_declaration.step2_info5")}</p>
                <p>{t("zenith.water_declaration.step2_info6")}</p>
              </>
            ),
          },
          {
            panel: <WorkflowWaterDeclarationCompleted />,
            infoPanel: (
              <>
                <p>{t("zenith.water_declaration.step3_info1")}</p>
                <p>{t("zenith.water_declaration.step3_info2")}</p>
                <p>{t("zenith.water_declaration.step3_info3")}</p>
              </>
            ),
          },
        ]}
      />
    </>
  );
};

export default WorkflowWaterDeclaration;

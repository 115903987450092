import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import SelectSingleSubscriberTable from "@components/table/SelectSingleSubscriberTable";
import { useCreateOrUpdateExtractionRightContext } from "@context/CreateOrUpdateExtractionRightContext";

const ExtractionRightSelectSubscriber = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const {
    stepHelpers,
    details,
    handleChangeDetails,
    navigateForCancel,
    amalgamateOrSubdivideDetails,
  } = useCreateOrUpdateExtractionRightContext();

  useEffect(() => {
    if (amalgamateOrSubdivideDetails) {
      stepHelpers.goToNextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amalgamateOrSubdivideDetails]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    stepHelpers.goToNextStep();
  };

  return (
    <form
      className="flex flex-col gap-6 p-6 grow justify-between"
      onSubmit={handleSubmit}
    >
      <div className="space-y-4">
        <Heading light>
          {t("extraction_right.create.step_1.select_subscriber")}
        </Heading>
      </div>

      {details?.level1wrs?.id !== "" && (
        <SelectSingleSubscriberTable
          onSelect={(item: any) => {
            handleChangeDetails("subscriber", item);
            handleChangeDetails("level0wrs", { id: "" });
            handleChangeDetails("waterClass", { id: "" });
            handleChangeDetails("linkToExtractionPoints", []);
          }}
          selected={details?.subscriber}
          showLevel0WRS
          params={{
            level1ResourceId: details?.level1wrs?.id,
          }}
          noRowText={
            t("approval.admin_transfer.create.step_2.no_row_text", {
              level1wrsName: details.level1wrs?.name,
            }) as string
          }
          predefinedFilter={
            searchParams.get("subscriberId")
              ? {
                  subscriberAccountIdName: details?.subscriber?.accountNumber,
                }
              : undefined
          }
        />
      )}

      <footer className="flex gap-4 -mx-6 mt-6 p-6 pb-0 border-t border-gray-200">
        <button
          type="submit"
          className="btn-primary"
          disabled={details?.subscriber?.id === ""}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={() => navigateForCancel()}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default ExtractionRightSelectSubscriber;

import { useQuery } from "@tanstack/react-query";
import { getAllUsers } from "@services/user";

type UseAllUsersParams = {
  params?: Partial<{ level1ResourceId: string; subscriberId: string }>;
  [key: string]: any;
};

export const useAllUsers = ({ params, ...options }: UseAllUsersParams = {}) => {
  return useQuery<any>({
    queryKey: ["users", params],
    queryFn: () => getAllUsers(params),
    ...options,
  });
};

import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { isFunction } from "lodash";
import { useTranslation } from "react-i18next";

import { useAppContext } from "@context/AppContext";
import { useUpdatePrimaryUser } from "@hooks/mutation/useUpdatePrimaryUser";
import { toastError, toastSuccess } from "@utils/toast";

type UserTableActionsProps = {
  user: Record<string, any>;
  refetch: () => void;
};

const UserTableActions: React.FunctionComponent<UserTableActionsProps> = ({
  user,
  refetch,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { checkPermissions } = useAppContext();
  const { mutateAsync: updatePrimaryUserMutation } = useUpdatePrimaryUser();

  return (
    <Select
      placeholder={t("common.actions")}
      options={[
        ...(checkPermissions(["UpdateUsers"])
          ? [
              {
                label: t("user.edit.title"),
                value: `/polestar/subscribers/${user.subscriberId}/users/${user.id}/edit`,
              },
              ...(user.isPrimary
                ? []
                : [
                    {
                      label: t("user.set_as_primary_user"),
                      callback: async () => {
                        try {
                          await updatePrimaryUserMutation(user.id);
                          toastSuccess(
                            t("user.toast.update_primary_user.success", {
                              username: user.name,
                            })
                          );
                          refetch();
                        } catch (error: any) {
                          toastError(
                            t("user.toast.update_primary_user.failed")
                          );
                        }
                      },
                    },
                  ]),
            ]
          : []),
      ]}
      onChange={(e) => {
        if (isFunction(e?.callback)) {
          e!.callback();
        } else if (e?.value) {
          navigate(e.value);
        }
      }}
      menuPortalTarget={document.body}
      closeMenuOnScroll={() => true}
      isSearchable={false}
      key={user.id + Date.now()}
    />
  );
};

export default UserTableActions;

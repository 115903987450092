import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { noop } from "lodash";
import { useTranslation } from "react-i18next";

import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";

type MenuItem = {
  label: string;
  to?: string;
  onClick?: () => void;
};

type ActionsButtonProps = {
  menus: MenuItem[];
};

const ActionsButton: React.FunctionComponent<ActionsButtonProps> = ({
  menus,
}) => {
  const { t } = useTranslation();
  const [position, setPosition] = React.useState({
    top: "0px",
    left: "0px",
  });
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const bounding = buttonRef.current?.getBoundingClientRect();
    setPosition({
      top: `${bounding?.top ? bounding.top + window.scrollY : 0}px`,
      left: `${bounding?.left ? bounding.left + window.scrollX : 0}px`,
    });
  };

  return (
    <Menu
      as="div"
      className="relative ml-2 inline-block flex-shrink-0 text-left"
    >
      <Menu.Button
        ref={buttonRef}
        className="group relative inline-flex h-8 w-8 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-primary-1 focus:ring-offset-2 bg-white hover:bg-gray-100"
        onClick={handleClick}
      >
        <span className="absolute -inset-1.5" />
        <span className="sr-only">{t("shared.table.open_actions")}</span>
        <span className="flex h-full w-full items-center justify-center rounded-full">
          <EllipsisVerticalIcon
            className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
        </span>
      </Menu.Button>

      {ReactDOM.createPortal(
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className="absolute z-10 w-28 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none -translate-x-full"
            style={{
              top: position.top,
              left: position.left,
            }}
          >
            <div className="py-1">
              {menus.map(({ label, onClick = noop, ...props }) => {
                return (
                  <Menu.Item
                    as={props.to ? Link : "button"}
                    className="block px-4 py-2 text-sm hover:bg-gray-50 w-full text-left"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      onClick();
                    }}
                    key={label}
                    {...props}
                  >
                    {label}
                  </Menu.Item>
                );
              })}
            </div>
          </Menu.Items>
        </Transition>,
        document.body
      )}
    </Menu>
  );
};

export default ActionsButton;

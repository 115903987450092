import { useQuery } from "@tanstack/react-query";
import {
  getAllWaterClasses,
  type GetAllWaterClasses,
} from "@services/waterClass";

interface Options {
  params?: Partial<GetAllWaterClasses>;
  [key: string]: any;
}

export const useAllWaterClasses = (options: Options = {}) => {
  const { params, ...restOptions } = options;
  return useQuery<any>({
    queryKey: ["waterClasses", params],
    queryFn: () => getAllWaterClasses(params),
    refetchOnWindowFocus: false,
    ...restOptions,
  });
};

import React from "react";
import { z } from "zod";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { t as translate } from "i18next";

import { getMeterUnit } from "@components/form/SelectMeterUnits";
import { ConfirmData } from "@components/shared/ConfirmationDetail";
import { useMeter } from "@hooks/query/useMeter";
import { getAllMeters } from "@services/meter";
import { formatDate, formatDateInput } from "@utils/formatDate";
import { convertLiterToML } from "@utils/convertUnits";
import ENV from "@config/env";

export const schema = z
  .object({
    type: z.string().optional(),
    serialNo: z.string().min(1, translate("common.required.text") as string),
    ipAddress: z.string().optional(),
    unit: z.number(),
    clickOver: z.string().optional(),
    verifiedAt: z.string().optional(),
    threshold: z.string().optional(),
    location: z.string().optional(),
    originalSerialNo: z.string().optional(),
  })
  .refine(
    async (values) => {
      if (values.originalSerialNo === values.serialNo) {
        return true;
      }

      const res = await getAllMeters({
        serialNo: values.serialNo,
      });
      return res.length === 0;
    },
    {
      message: translate("meter.form.duplicate_serial_no") as string,
      path: ["serialNo"],
    }
  );

export type MeterDetails = z.infer<typeof schema>;

export const initialMeterDetails: MeterDetails = {
  type: "",
  serialNo: "",
  ipAddress: "",
  unit: 1,
  clickOver: "",
  verifiedAt: "",
  threshold: "",
  location: "",
};

type ContextValue = {
  meterDetails: MeterDetails;
  setMeterDetails: React.Dispatch<MeterDetails>;
  isComplete: boolean;
  setIsComplete: React.Dispatch<boolean>;
  getMeterInfo: () => ConfirmData;
  workflowInstance: any;
  setWorkflowInstance: React.Dispatch<any>;
};

const MeterCreateOrEditContext = React.createContext<ContextValue | undefined>(
  undefined
);

const MeterCreateOrEditProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const { meterId = "" } = useParams();
  const [meterDetails, setMeterDetails] = React.useState({
    ...initialMeterDetails,
  });
  const [isComplete, setIsComplete] = React.useState(false);
  const [workflowInstance, setWorkflowInstance] = React.useState<any>();

  useMeter(meterId, {
    onSuccess: (data: any) => {
      setMeterDetails({
        type: data?.type ?? "",
        serialNo: data?.serialNo ?? "",
        ipAddress: data?.ipAddress ?? "",
        unit: data?.unit ?? "",
        clickOver: data?.clickOver ? convertLiterToML(data.clickOver) : "",
        verifiedAt: data?.verifiedAt
          ? formatDateInput(new Date(data.verifiedAt))
          : "",
        threshold: data?.threshold ? convertLiterToML(data.threshold) : "",
        location: data?.location ?? "",
        originalSerialNo: data?.serialNo ?? "",
      });
    },
  });

  const getMeterInfo = () => {
    return {
      title: t("meter.adding_meter.details"),
      body: [
        { key: t("meter.adding_meter.type"), value: meterDetails.type },
        {
          key: t("meter.adding_meter.serial_no"),
          value: meterDetails.serialNo,
        },
        {
          key: t("meter.adding_meter.ip_address"),
          value: meterDetails.ipAddress,
        },
        {
          key: t("meter.adding_meter.units"),
          value: getMeterUnit(meterDetails.unit),
        },
        {
          key: t("meter.adding_meter.click_over"),
          value: meterDetails.clickOver || `${ENV.DEFAULT_METER_CLICK_OVER}`,
        },
        {
          key: t("meter.adding_meter.verification_date"),
          value: meterDetails.verifiedAt
            ? formatDate(new Date(meterDetails.verifiedAt))
            : "",
        },
        {
          key: t("common.location"),
          value: meterDetails.location,
        },
      ],
    };
  };

  const contextValues = {
    meterDetails,
    setMeterDetails,
    isComplete,
    setIsComplete,
    getMeterInfo,
    workflowInstance,
    setWorkflowInstance,
  };

  return (
    <MeterCreateOrEditContext.Provider value={contextValues}>
      {children}
    </MeterCreateOrEditContext.Provider>
  );
};

function useMeterCreateOrEditContext() {
  const context = React.useContext(MeterCreateOrEditContext);
  if (context === undefined) {
    throw new Error(
      "useMeterCreateOrEditContext must be used within a MeterCreateOrEditProvider"
    );
  }
  return context;
}

export { MeterCreateOrEditProvider, useMeterCreateOrEditContext };

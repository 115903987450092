import api from "@services/api";

type EventGroup = {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
};

type EventType = {
  id: string;
  eventGroupId: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  eventGroup: EventGroup;
};

export type EventTransaction = {
  id: string;
  calculation: string | null;
  createdByUserId: string | null;
  description: string;
  eventTypeId: string;
  hcsHash: string | null;
  hcsId: string;
  hcsMessage: string | null;
  impactLevel0ResourceId: string | null;
  impactLevel1ResourceId: string | null;
  impactWalletId: string | null;
  reference: string;
  struckAt: string | null;
  transactionAmount: number;
  createdAt: string;
  updatedAt: string;
  eventType: EventType;
  childEventTransactions?: EventTransaction[];
};

export const getEventTransactions = async (params: any): Promise<any> => {
  const { data } = await api.get("/eventTransactions", { params });

  return data.eventTransactions;
};

export const getEventTransactionById = async (id: string) => {
  const { data } = await api.get(`/eventTransactions/${id}`);
  return data.eventTransaction;
};

import classNames from "classnames";
import { ReactNode } from "react";

type CirclePercentageProps = {
  size?: number;
  borderSize?: number;
  scale: number;
  bgColor?: string;
  borderColor?: string;
  children?: ReactNode;
};

const CirclePercentage: React.FunctionComponent<CirclePercentageProps> = ({
  size = 80,
  borderSize = 8,
  scale,
  bgColor = "#555555",
  borderColor = "#000000",
  children,
}) => {
  return (
    <div
      className={classNames("relative text-center rounded-full bg-black")}
      style={{
        width: `${size + borderSize}px`,
        height: `${size + borderSize}px`,

        background:
          scale > 0.5
            ? `linear-gradient(${
                360 * scale - 90
              }deg, transparent 50%, ${borderColor} 50%), linear-gradient(-90deg, ${borderColor} 50%, ${bgColor} 50%)`
            : `linear-gradient(${
                360 * scale - 90
              }deg, ${bgColor} 50%, transparent 50%), linear-gradient(-90deg, ${borderColor} 50%, ${bgColor} 50%)`,
      }}
    >
      <div
        className={classNames(
          "rounded-full text-center relative flex justify-center items-center"
        )}
        style={{
          backgroundColor: bgColor,
          top: borderSize / 2,
          left: borderSize / 2,
          width: `${size}px`,
          height: `${size}px`,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default CirclePercentage;

import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import HandleGoBackOrClose from "@components/shared/HandleGoBackOrClose";
import Layout from "@components/layout/Layout";
import WorkflowPanels from "@components/form/WorkflowPanels";
import Level1WRSDetails from "@components/form/level1_wrs/create_level1_wrs/Level1WRSDetails";
import Level1WRSBoundary from "@components/form/level1_wrs/create_level1_wrs/Level1WRSBoundary";
import Level1WRSLedger from "@components/form/level1_wrs/create_level1_wrs/Level1WRSLedger";
import InfoPanel from "@components/form/InfoPanel";
import StepButton from "@components/shared/StepButton";
import StepInfo from "@components/shared/StepInfo";
import BoundarySelected from "@components/shared/BoundarySelected";
import BoundarySampleInformation from "@components/shared/BoundarySampleInformation";
import Level1WRSWorkflowCompleted from "@components/form/level1_wrs/create_level1_wrs/Level1WRSWorkflowCompleted";
import {
  CreateLevel1WRSProvider,
  useCreateLevel1WRSContext,
} from "@context/CreateLevel1WRSContext";
import { toastSuccess } from "@utils/toast";
import {
  EvidenceProvider,
  useEvidenceContext,
} from "@context/shared/EvidenceContext";
import UploadEvidencesWorkflowForm from "@components/form/UploadEvidenceWorkflowForm";

const CreateLevel1WRS = () => {
  return (
    <EvidenceProvider
      accept={{
        "image/jpeg": [".jpeg", ".jpg"],
        "image/png": [".png"],
      }}
    >
      <CreateLevel1WRSProvider>
        <Consumer />
      </CreateLevel1WRSProvider>
    </EvidenceProvider>
  );
};

const Consumer = () => {
  const { t } = useTranslation();
  const {
    currentStep,
    stepHelpers,
    getInfoMessages,
    details,
    workflowCompleted,
    networkErrors,
    setLocationCoordinates,
    getLevel1WRSDetails,
  } = useCreateLevel1WRSContext();
  const { getEvidencesInfo } = useEvidenceContext();
  const navigate = useNavigate();
  const handleGoBackOrClose = HandleGoBackOrClose();

  const steps = [
    {
      label: (
        <StepButton
          index={0}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
          info={<StepInfo data={getLevel1WRSDetails().body} />}
        >
          {t("level1wrs.create.enter_details")}
        </StepButton>
      ),
      panel: <Level1WRSDetails />,
      infoPanel: (
        <InfoPanel
          successes={getInfoMessages("success", 0)}
          errors={getInfoMessages("error", 0)}
          warnings={getInfoMessages("warning", 0)}
        >
          <div className="space-y-2">
            <p>{t("level1wrs.create.info_panel.step_1.info_intro")}</p>
            <ul className="pl-2 space-y-2">
              <li>
                <strong>{t("level1wrs.create.name")}:</strong>{" "}
                <Trans i18nKey="level1wrs.create.info_panel.step_1.name_info" />
              </li>
              <li>
                <strong>{t("level1wrs.create.identifier")}:</strong>{" "}
                {t("level1wrs.create.info_panel.step_1.identifier_info")}
              </li>
              <li>
                <strong>{t("level1wrs.create.description")}:</strong>{" "}
                {t("level1wrs.create.info_panel.step_1.description_info")}
              </li>
              <li>
                <strong>{t("level1wrs.create.image")}:</strong>{" "}
                {t("level1wrs.create.info_panel.step_1.image_info")}
              </li>
              <li>
                <strong>{t("level1wrs.create.level2WRS")}:</strong>{" "}
                {t("level1wrs.create.info_panel.step_1.level2WRS_info")}
              </li>
              <li>
                <Trans i18nKey="level1wrs.create.info_panel.step_1.water_year_info" />
              </li>
            </ul>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={1}
          currentStep={currentStep}
          info={<BoundarySelected selected={details.geography !== ""} />}
          isOnChain={workflowCompleted}
        >
          {t("level1wrs.create.enter_boundary")}
        </StepButton>
      ),
      panel: <Level1WRSBoundary />,
      infoPanel: (
        <InfoPanel
          warnings={[t("information.location_feature_info_warning")]}
          actions={[
            {
              label: t("level1wrs.create.info_panel.step_2.action_1"),
              action: () => {
                setLocationCoordinates({ lat: "", lng: "" });
                toastSuccess(
                  t("level1wrs.create.info_panel.step_2.action_1_toast")
                );
              },
            },
          ]}
        >
          <div className="space-y-2">
            <p>{t("level1wrs.create.info_panel.step_2.info_intro")}</p>
            <BoundarySampleInformation />
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={2}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
          info={<StepInfo data={getEvidencesInfo().body} />}
        >
          {t("evidence.upload_menu_title")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          referenceTable="level1_wrs"
          referenceTableId={details.id}
          onNext={stepHelpers.goToNextStep}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={handleGoBackOrClose}
          customDropboxTitle={
            t("level1wrs.create.info_panel.step_4.title") as string
          }
          newUploadDescription={
            t("level1wrs.create.info_panel.step_4.description", {
              level1wrsName: details.name,
            }) as string
          }
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>{t("level1wrs.create.info_panel.step_4.info_intro")}</p>
            <p>{t("level1wrs.create.info_panel.step_4.info_conclusion")}</p>
          </div>
        </InfoPanel>
      ),
    },

    {
      label: (
        <StepButton
          index={3}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
          forceSuccess={workflowCompleted}
        >
          {t("level1wrs.create.ledger")}
        </StepButton>
      ),
      panel: workflowCompleted ? (
        <Level1WRSWorkflowCompleted />
      ) : (
        <Level1WRSLedger />
      ),
      infoPanel: workflowCompleted ? (
        <InfoPanel
          successes={[
            t("level1wrs.create.info_panel.workflow_completed.success", {
              name: details.name,
            }),
          ]}
          actions={[
            {
              label: t("level1wrs.create.info_panel.workflow_completed.action"),
              action: () => {
                navigate(`/polestar/level1wrs/${details.id}/level0wrs/create`);
              },
            },
          ]}
        >
          <p className="inline">
            {t("level1wrs.create.info_panel.workflow_completed.info_intro")}
          </p>{" "}
          <strong>{details.name}</strong>.
        </InfoPanel>
      ) : (
        <InfoPanel
          successes={[
            t("level1wrs.create.info_panel.step_3.success", {
              name: details.name,
            }),
          ]}
          errors={networkErrors}
        >
          <div className="space-y-2">
            <p>{t("level1wrs.create.info_panel.step_3.info_intro")}</p>
            <ul className="pl-2 space-y-2">
              <li>
                <strong>{t("level1wrs.create.details")}:</strong>{" "}
                {t("level1wrs.create.info_panel.step_3.details_info")}
              </li>
              <li>
                <strong>{t("level1wrs.create.boundary")}:</strong>{" "}
                {t("level1wrs.create.info_panel.step_3.boundary_info")}
              </li>
              <li>
                <Trans i18nKey="information.edit_info_evidence" />
              </li>
            </ul>
            <p>{t("level1wrs.create.info_panel.step_3.info_conc")}</p>
          </div>
        </InfoPanel>
      ),
    },
  ];

  return (
    <Layout
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("level1wrs.create.title"),
        },
      ]}
      title={t("level1wrs.create.title")}
      permissions={["CreateLevel1Resource"]}
    >
      <WorkflowPanels selectedStep={currentStep} steps={steps} />
    </Layout>
  );
};

export default CreateLevel1WRS;

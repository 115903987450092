import React from "react";

const ReportIcon: React.FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="23"
      fill="none"
      viewBox="0 0 24 23"
    >
      <path
        fill="#004A88"
        d="M16.5 2.875c0-.381.158-.747.44-1.016.28-.27.662-.421 1.06-.421h3c.398 0 .78.151 1.06.42.282.27.44.636.44 1.017v17.25h.75c.199 0 .39.076.53.21.141.135.22.318.22.509 0 .19-.079.373-.22.508a.767.767 0 01-.53.21H.75a.767.767 0 01-.53-.21.704.704 0 01-.22-.508c0-.19.079-.374.22-.509a.767.767 0 01.53-.21h.75v-4.313c0-.38.158-.746.44-1.016.28-.27.662-.421 1.06-.421h3c.398 0 .78.152 1.06.421.282.27.44.635.44 1.017v4.312H9V10.062c0-.38.158-.746.44-1.016.28-.27.662-.421 1.06-.421h3c.398 0 .78.151 1.06.421.282.27.44.635.44 1.017v10.062h1.5V2.875zm1.5 17.25h3V2.875h-3v17.25zm-4.5 0V10.062h-3v10.063h3zm-7.5 0v-4.313H3v4.313h3z"
      ></path>
    </svg>
  );
};

export default ReportIcon;

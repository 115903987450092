import React from "react";

const ListIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#004A88"
        fillRule="evenodd"
        d="M7.5 17.25a.75.75 0 01.75-.75h13.5a.75.75 0 010 1.5H8.25a.75.75 0 01-.75-.75zM7.5 11.25a.75.75 0 01.75-.75h13.5a.75.75 0 010 1.5H8.25a.75.75 0 01-.75-.75zM7.5 5.25a.75.75 0 01.75-.75h13.5a.75.75 0 010 1.5H8.25a.75.75 0 01-.75-.75zM3 6.75a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM3 12.75a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM3 18.75a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default ListIcon;

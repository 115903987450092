import React from "react";
import { usePaginationState } from "@components/shared/DataTable/Pagination";
import { useTranslation } from "react-i18next";
import type { ColumnDef } from "@tanstack/react-table";

import BidIcon from "@components/icons/BidIcon";
import Card from "@components/layout/Card";
import DataTableRenderer from "@components/shared/DataTable/DataTableRenderer";
import ActionsButton from "@components/shared/DataTable/ActionsButton";

const BidTable = () => {
  const { t } = useTranslation();
  const [pagination, setPagination] = usePaginationState();

  const columns = React.useMemo<ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "destination",
        header: t("zenith.exchanges.bids.destination") as string,
      },
      {
        accessorKey: "volume",
        header: `${t("zenith.exchanges.bids.volume")} ${t(
          "common.volume_unit"
        )}`,
      },
      {
        accessorKey: "price",
        header: `${t("zenith.exchanges.bids.price")} ${t(
          "common.currency"
        )}/${t("common.volume_unit")}`,
      },
      {
        accessorKey: "class",
        header: t("common.water_class") as string,
      },
      {
        id: "actions",
        header: t("common.actions") as string,
        cell: ({ row }) => {
          return (
            <ActionsButton
              menus={[
                {
                  label: t("common.view"),
                  to: `?id=${row.id}`,
                },
              ]}
            />
          );
        },
      },
    ],
    [t]
  );

  return (
    <Card>
      <div className="space-y-3">
        <div className="flex justify-between items-center">
          <h3 className="flex gap-3 items-baseline text-primary-2">
            <BidIcon className="w-5 h-5" />
            <span className="text-lg font-semibold">
              {t("zenith.exchanges.bids.title")}
            </span>
          </h3>

          <button className="btn-secondary text-sm rounded">
            {t("zenith.exchanges.bids.add_new")}
          </button>
        </div>

        <DataTableRenderer
          columns={columns}
          data={[]}
          name="bids"
          pagination={pagination}
          onPaginationChange={setPagination}
          pageCount={1}
          isLoading={false}
          isFetching={false}
        />
      </div>
    </Card>
  );
};

export default BidTable;

import UserSubscriberForm from "@components/form/profile/UserSubscriberForm";
import Breadcrumb from "@components/layout/Breadcrumb";
import Heading from "@components/layout/Heading";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

const UserSubscriber: FunctionComponent = () => {
  const { t } = useTranslation();
  const breadcrumb = [
    {
      label: t("dashboard.dashboard"),
      href: "/zenith",
    },
    {
      label: t("profile.subscriber.title"),
    },
  ];

  return (
    <section className="flex flex-col flex-1 space-y-4">
      <Breadcrumb items={breadcrumb} />
      <Heading>{t("profile.subscriber.title")}</Heading>
      <UserSubscriberForm />
    </section>
  );
};

export default UserSubscriber;

import React from "react";
import { useTranslation } from "react-i18next";

import Modal from "@components/layout/Modal";
import Heading from "@components/layout/Heading";
import ModalInformationIcon from "@components/icons/ModalInformationIcon";
import { convertLiterToML } from "@utils/convertUnits";
import { READING_CALCULATION } from "@services/declarations";
import type { Declaration } from "@context/DeclarationContext";

type Props = {
  open: boolean;
  data: Declaration;
  onChange: (value: string) => void;
  onSubmit: (value: string) => void;
  onCancel: () => void;
};

const DeclarationMethodModal: React.FunctionComponent<Props> = ({
  open,
  data,
  onChange,
  onSubmit,
  onCancel,
}: any) => {
  const { t } = useTranslation();
  const options = [
    {
      label: t("declaration.modal.calculation.click_over"),
      value: READING_CALCULATION.CLICK_OVER,
    },
    {
      label: t("declaration.modal.calculation.backward"),
      value: READING_CALCULATION.BACKWARD,
    },
    {
      label: t("declaration.modal.update_click_over.option"),
      value: "update",
    },
  ];

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <Modal open={open}>
      <form onSubmit={handleSubmit}>
        <div className="py-8 px-4 flex gap-4">
          <ModalInformationIcon className="shrink-0 h-12 w-12" />

          <div className="text-gray-500">
            <Heading className="text-inherit mb-6">
              {t("declaration.modal.calculation.title")}
            </Heading>
            <div className="space-y-4">
              {options.map((item) => (
                <div key={item.value} className="flex items-center">
                  <input
                    id={item.value}
                    name="declarationMethod"
                    type="radio"
                    className="h-4 w-4 border-gray-300 text-primary-1 focus:ring-primary-1"
                    value={item.value}
                    checked={data.calculation === item.value}
                    onChange={() => {
                      onChange(item.value);
                    }}
                    required
                  />
                  <label htmlFor={item.value} className="ml-3 block">
                    {item.label}
                  </label>
                </div>
              ))}

              <p className="text-lg font-semibold">
                {t("declaration.modal.update_click_over.current_value")}{" "}
                {convertLiterToML(data.meter.clickOver)}
              </p>
            </div>
          </div>
        </div>

        <div className="border-t p-4 flex justify-end gap-4">
          <button
            type="button"
            className="btn-primary text-sm rounded"
            onClick={onCancel}
          >
            {t("common.cancel")}
          </button>
          <button type="submit" className="btn-default text-sm rounded">
            {t("common.confirm")}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default DeclarationMethodModal;

import { useTranslation } from "react-i18next";
import Card from "../../../layout/Card";

const Level1ResourceAddWidget: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Card className="p-0 relative">
      <a href="/polestar/level1WRS/create">
        <div className="flex flex-col">
          <header
            className="w-full h-32 p-4 rounded-t-lg text-white font-semibold flex flex-col justify-end relative"
            style={{
              background: `url('${"/cedar_pocket.jpg"}')`,
              backgroundColor: "#004A88",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <div className="w-full flex items-end justify-between">
              <p className="w-2/3 drop-shadow-lg">Add new scheme</p>
            </div>
            <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center">
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32 24V32M32 32V40M32 32H40M32 32H24M56 32C56 45.2548 45.2548 56 32 56C18.7452 56 8 45.2548 8 32C8 18.7452 18.7452 8 32 8C45.2548 8 56 18.7452 56 32Z"
                  stroke="#4B5563"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </header>
          <div className="grid grid-cols-2 text-gray-400">
            <div className="border-r border-gray-200 p-4 flex flex-col">
              <h3 className="text-sm">
                {t("level1wrs.current_exchange_rates")}
              </h3>
              <div className="flex flex-row flex-wrap gap-3">-</div>
            </div>
            <div className="p-4">
              <h3 className="text-sm">{t("level1wrs.volume_available")}</h3>
              <div className="font-semibold text-lg">-</div>
            </div>
            <div className="border-r border-t border-gray-200 p-4">
              <h3 className="text-sm">{t("level1wrs.volume_used")}</h3>
              <div className="font-semibold text-lg">-</div>
            </div>
            <div className="border-t border-gray-200 p-4">
              <h3 className="text-sm">{t("level1wrs.volume_remain")}</h3>
              <div className="font-semibold text-lg">-</div>
            </div>
          </div>
        </div>
      </a>
    </Card>
  );
};

export default Level1ResourceAddWidget;

import React from "react";
import sumBy from "lodash/sumBy";
import { useTranslation } from "react-i18next";

import AreaChart from "./AreaChart";
import Stats, { type Stat } from "./Strats";
import { getMonthlyData, getYearlyData } from "./mockData";

const monthlyData = getMonthlyData();
const yearlyData = getYearlyData();

const PermanentTradeChart = () => {
  const { t } = useTranslation();
  const [data, setData] = React.useState(() => [...monthlyData]);

  const totalVolume = sumBy(data, (i: any) => i.value).toLocaleString();

  const stats: Stat[] = [
    {
      name: `${t("zenith.exchanges.chart.average_price")} (${t(
        "common.currency"
      )}/${t("common.volume_unit")})`,
      value: `${t("common.currency")}91.25`,
      change: "-4.75%",
      changeType: "negative",
    },
    {
      name: `${t("zenith.exchanges.chart.total_volume")} (${t(
        "common.currency"
      )}/${t("common.volume_unit")})`,
      value: `${totalVolume} ${t("common.volume_unit")}`,
      change: "+54.02%",
      changeType: "positive",
    },
  ];

  return (
    <div className="relative rounded-lg overflow-hidden bg-white shadow-sm">
      <AreaChart
        title={t("zenith.exchanges.chart.permanent", {
          level0ResourceName: "CLV",
        })}
        data={data}
        dark
        onChange={(e) => {
          setData(e.target.value === "monthly" ? monthlyData : yearlyData);
        }}
      />

      <Stats data={stats} />
    </div>
  );
};

export default PermanentTradeChart;

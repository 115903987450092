import React from "react";

const HammerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <g clipPath="url(#clip0_1586_43109)">
        <path
          fill="#004A88"
          d="M12.445.367a1.252 1.252 0 00-1.77 0L5.989 5.055a1.252 1.252 0 000 1.77l.625.624a1.252 1.252 0 001.77 0l.156-.156 4.172 4.168-.156.156a1.252 1.252 0 000 1.77l.625.625a1.252 1.252 0 001.77 0l4.687-4.688a1.252 1.252 0 000-1.77l-.625-.624a1.252 1.252 0 00-1.77 0l-.156.156-4.172-4.172.156-.156a1.252 1.252 0 000-1.77l-.625-.625v.004zm-5.937 11.25a1.252 1.252 0 00-1.77 0L.363 15.992a1.252 1.252 0 000 1.77l1.875 1.875a1.252 1.252 0 001.77 0l4.375-4.375a1.252 1.252 0 000-1.77l-.055-.055 2.297-2.292-1.77-1.77-2.293 2.293-.054-.055v.004z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1586_43109">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default HammerIcon;

import ConfirmModal from "@components/shared/ConfirmModal";
import ConfirmationDetail from "@components/shared/ConfirmationDetail";
import { useAppContext } from "@context/AppContext";
import { useMessageContext } from "@context/MessageContext";
import { useCreateEvidence } from "@hooks/mutation/useCreateEvidence";
import { useCreateMessage } from "@hooks/mutation/zenith/useCreateMessage";
import { DBTables } from "@utils/constants/dbTables";
import { toastError, toastSuccess } from "@utils/toast";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type CreateNotificationLedgerProps = {
  onEditStep: (step: number) => void;
  onCancel: () => void;
  onSubmit: () => void;
};

const CreateNotificationLedger: FunctionComponent<
  CreateNotificationLedgerProps
> = ({ onEditStep, onCancel, onSubmit }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loggedInInfo } = useAppContext();
  const { message, setMessage } = useMessageContext();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const {
    mutateAsync: createMessageMutation,
    isLoading: isCreateMessageLoading,
  } = useCreateMessage();
  const {
    mutateAsync: createEvidenceMutation,
    isLoading: isCreateEvidenceLoading,
  } = useCreateEvidence();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setShowConfirmModal(true);
  };

  const handleConfirm = async () => {
    try {
      const response = await createMessageMutation({
        notificationTypeId: message.messageTemplate?.notificationTypeId,
        subject: message.subject,
        body: message.body,
      });

      if (message.evidence) {
        await uploadFile(response, message.evidence, response.workflowInstance);
      }
      toastSuccess(t("notification.save_succeed"));

      setMessage({
        ...message,
        ...response.message,
      });

      onSubmit?.();
    } catch (error) {
      toastError(t("notification.save_failed"));
    } finally {
      setShowConfirmModal(false);
    }
  };

  const uploadFile = async (
    message: any,
    file: File,
    workflowInstance: any,
  ) => {
    await createEvidenceMutation({
      title: t("notification.evidence_data.title"),
      references: [
        {
          referenceId: message?.id,
          referenceTable: DBTables.Messages,
        },
        {
          referenceId: loggedInInfo.userDetails?.subscriberId,
          referenceTable: DBTables.Subscribers,
        },
        ...(Boolean(workflowInstance?.id)
          ? [
              {
                referenceId: workflowInstance?.id,
                referenceTable: DBTables.WorkflowInstances,
              },
            ]
          : []),
      ],
      description: t("notification.evidence_saved", {
        senderName: loggedInInfo.userDetails?.name,
      }),
      isPublic: false,
      isEncrypted: true,
      attachment: file,
    });
  };

  const getConfirmData = () => {
    return [
      {
        title: t("notification.details"),
        body: [
          {
            key: t("notification.type"),
            value: t(
              message.messageTemplate?.nameText || "message_template.general",
            ),
          },
          {
            key: t("notification.recipients"),
            value: t("common.administrators"),
          },
          {
            key: t("notification.subject"),
            value: message.subject,
          },
          {
            key: t("notification.body"),
            value: (
              <blockquote className="border-l-4 pl-4 whitespace-pre-wrap">
                {message.body}
              </blockquote>
            ),
          },
        ],
      },
      {
        title: t("evidence.supporting"),
        body: [
          {
            key: t("evidence.file.name"),
            value: message.evidence?.name || "-",
          },
        ],
      },
    ];
  };

  return (
    <>
      <form className="flex flex-col grow" onSubmit={handleSubmit}>
        <div className="flex flex-col grow gap-10">
          <ConfirmationDetail data={getConfirmData()} onEdit={onEditStep} />
        </div>
        <footer className="p-4 flex gap-2">
          <button
            type="button"
            className="btn-outline-primary text-sm font-semibold"
            onClick={onCancel}
          >
            {t("common.prev_step")}
          </button>
          <button type="submit" className="btn-primary text-sm font-semibold">
            {t("common.next_step")}
          </button>
          <button
            type="button"
            className="btn-default text-sm font-semibold"
            onClick={() => navigate("/zenith/notifications")}
          >
            {t("common.cancel")}
          </button>
        </footer>
      </form>
      <ConfirmModal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleConfirm}
        isSubmitting={isCreateEvidenceLoading || isCreateMessageLoading}
      >
        {t("notification.modal_confirmation")}
      </ConfirmModal>
    </>
  );
};

export default CreateNotificationLedger;

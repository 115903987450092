import Label from "@components/form/Label";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import MapView from "./MapView";

type DrawGeographyProps = {
  locationCoordinates: { lat: string; lng: string };
  setLocationCoordinates: (location: { lat: string; lng: string }) => void;
  setFiles: (files: any) => void;
};

const DrawGeography: React.FunctionComponent<DrawGeographyProps> = ({
  setLocationCoordinates,
  locationCoordinates,
  setFiles,
}) => {
  const { t } = useTranslation();
  const [center] = useState<{ lat: number; lng: number }>({
    lat: isNaN(parseFloat(locationCoordinates.lat))
      ? -27.619841404315526
      : parseFloat(locationCoordinates.lat),
    lng: isNaN(parseFloat(locationCoordinates.lng))
      ? 152.75642671041598
      : parseFloat(locationCoordinates.lng),
  });

  const onClick = (e: any) => {
    setFiles(null);
    setLocationCoordinates({ lat: e.lat.toString(), lng: e.lng.toString() });
  };

  return (
    <div className="space-y-4 mt-4">
      <div>
        <Label className="mb-2">{t("shared.map.ask_drop_pin")}</Label>
        <div className="h-96 max-w-md mt-4">
          <MapView
            center={center}
            zoomLevel={14}
            onClick={(e: any) => {
              onClick(e);
            }}
            markers={[
              {
                lat: Number(locationCoordinates.lat),
                lng: Number(locationCoordinates.lng),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default DrawGeography;

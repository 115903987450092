import Heading from "@components/layout/Heading";
import DropzoneBox from "@components/shared/DropzoneBox";
import { useBalanceAdjustmentContext } from "@context/BalanceAdjustmentContext";
import { FunctionComponent } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import TextArea from "../TextArea";
import Legend from "../Legend";

type CreateBalanceAdjustmentUploadEvidenceProps = {
  onSave: () => void;
  onGoBack: () => void;
};

const CreateBalanceAdjustmentUploadEvidence: FunctionComponent<
  CreateBalanceAdjustmentUploadEvidenceProps
> = ({ onSave, onGoBack }) => {
  const { t } = useTranslation();
  const { balanceAdjustmentData, setBalanceAdjustmentData, navigateForCancel } =
    useBalanceAdjustmentContext();

  const accept = {
    "image/jpeg": [".jpeg", ".jpg"],
    "image/png": [".png"],
    "application/pdf": [".pdf"],
    "application/msword": [".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files: any) =>
      setBalanceAdjustmentData({ ...balanceAdjustmentData, file: files[0] }),
    accept: accept,
  });

  function handleDeleteFile() {
    setBalanceAdjustmentData({ ...balanceAdjustmentData, file: undefined });
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();

    onSave();
  };

  return (
    <form className="pb-4 flex flex-col h-full" onSubmit={handleSubmit}>
      <div className="p-6 flex flex-col h-full">
        <div>
          <Heading className="text-xl leading-6" light>
            {t("balance_adjustment.upload_file")}
          </Heading>
        </div>

        <div className="max-w-xl mt-6">
          <DropzoneBox
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            files={
              balanceAdjustmentData.file ? [balanceAdjustmentData.file] : []
            }
            onDelete={handleDeleteFile}
            acceptedFileTypes={accept}
          />
        </div>

        <fieldset className="mt-6">
          <Legend>{t("balance_adjustment.create.note_field")}</Legend>
          <div>
            <TextArea
              value={balanceAdjustmentData.notes}
              onChange={(e: any) =>
                setBalanceAdjustmentData({
                  ...balanceAdjustmentData,
                  notes: e.target.value,
                })
              }
            />
          </div>
        </fieldset>
      </div>
      <footer className="border-t border-gray-200 py-4 px-6 flex gap-3">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={onGoBack}
        >
          {t("common.prev_step")}
        </button>
        <button type="submit" className="btn-primary flex items-center gap-2">
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default CreateBalanceAdjustmentUploadEvidence;

import React from "react";
import { useTranslation } from "react-i18next";

import ApprovalTable from "./ApprovalTable";
import { useResponseApprovalInfoRequestContext } from "@context/ResponseApprovalInfoRequestContext";
import { useSearchParams } from "react-router-dom";

type SelectApprovalProps = {
  onSubmit: () => void;
  onCancel: () => void;
};

const SelectApproval: React.FunctionComponent<SelectApprovalProps> = ({
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { approval, setApproval } = useResponseApprovalInfoRequestContext();
  const [searchParams] = useSearchParams();
  const approvalId = searchParams.get("selectedId") ?? "";

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form className="flex flex-col grow" onSubmit={handleSubmit}>
      <ApprovalTable
        onSelect={(value: any) => setApproval(value)}
        selected={approval}
        filterId={approvalId}
      />

      <footer className="py-4 flex gap-2">
        <button
          type="submit"
          className="btn-primary text-sm font-semibold"
          disabled={!approval}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-default text-sm font-semibold"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default SelectApproval;

import { useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Link } from "react-router-dom";

import { useAllSubscribers } from "@hooks/query/useAllSubscribers";

import Table from "@components/layout/Table";
import Tag from "@components/shared/Tag";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import SelectLevel0Resource from "@components/form/SelectLevel0Resource";
import ReadOnlyInput from "@components/form/ReadOnlyInput";
import ExternalLinkIcon from "@components/icons/ExternalLinkIcon";
import { type GetAllSubscribersParams } from "@services/subscriber";

type Subscriber = Record<string, any>;

type Filter = {
  subscriberAccountIdName: string;
  isActive: boolean;
  level0wrsIdentifier: string;
};

type SelectSingleSubscriberTableProps = {
  onSelect: (item: Subscriber) => void;
  selected?: Subscriber;
  excludedSubscriberId?: string;
  noRowText?: string;
  predefinedFilter?: Partial<Filter>;
  showLevel0WRS?: boolean;
  showStatus?: boolean;
  showPrioritiesOfLevel0WRSIdentifier?: string;
  filter?: any;
  params?: Partial<GetAllSubscribersParams>;
};

const SelectSingleSubscriberTable: React.FunctionComponent<
  SelectSingleSubscriberTableProps
> = ({
  onSelect,
  selected,
  excludedSubscriberId,
  noRowText,
  predefinedFilter = {},
  showLevel0WRS = false,
  showPrioritiesOfLevel0WRSIdentifier = "",
  params = {},
}) => {
  const { t } = useTranslation();
  const { data: subscribers, isLoading } = useAllSubscribers({
    params,
    options: {
      refetchOnWindowFocus: true,
    },
  });
  const [filter, setFilter] = useState<Partial<Filter>>(() => ({
    subscriberAccountIdName: "",
    level0wrsIdentifier: "",
    ...predefinedFilter,
  }));

  const handleFilterChange = (field: keyof Filter, value: any) => {
    setFilter(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  const activeOptions = [
    { label: t("common.active"), value: true },
    { label: t("common.inactive"), value: false },
  ];

  const tableFields = [
    {
      title: "",
      name: "radio",
    },
    {
      title: t("subscriber.account_number"),
      name: "accountNumber",
    },
    {
      title: t("subscriber.name"),
      name: "name",
    },
    ...(showLevel0WRS
      ? [
          {
            title: t("common.entitlement_zones"),
            name: "zones",
          },
        ]
      : []),
    ...(showPrioritiesOfLevel0WRSIdentifier !== ""
      ? [
          {
            title: t("water_class.water_class_level0wrs", {
              level0ResourceIdentifier: showPrioritiesOfLevel0WRSIdentifier,
            }),
            name: "waterClasses",
          },
        ]
      : []),
    {
      title: t("common.status"),
      name: "status",
    },
    {
      title: "",
      name: "view",
    },
  ];

  const tableData = subscribers
    ?.filter((subscriber: any) => {
      const matcher = filter.subscriberAccountIdName?.toLowerCase();

      return (
        excludedSubscriberId !== subscriber?.id &&
        (subscriber?.accountNumber
          ?.toString()
          .toLowerCase()
          ?.includes(matcher) ||
          subscriber?.name?.toLowerCase().includes(matcher))
      );
    })
    ?.filter(
      (row: { isActive: boolean }) =>
        filter.isActive === undefined || row.isActive === filter.isActive,
    )
    .map((subscriber: any) => {
      const waterClasses = subscriber.extractionRights
        .filter((right: any) => right.isActive)
        .filter((right: any) => {
          if (showPrioritiesOfLevel0WRSIdentifier !== "") {
            return (
              right?.level0Resource.identifier ===
              showPrioritiesOfLevel0WRSIdentifier
            );
          }
          return true;
        })
        .map((right: any) => right?.waterClass.name)
        .filter(
          (value: any, index: any, self: string | any[]) =>
            self.indexOf(value) === index,
        )
        .join(", ");

      return {
        id: subscriber.id,
        radio: (
          <input
            type="radio"
            name="level1wrs_id"
            checked={selected?.id === subscriber?.id}
            onChange={() => onSelect(subscriber)}
          />
        ),
        name: subscriber?.name,
        zones:
          subscriber.extractionRights
            .filter((right: any) => right.isActive)
            .map((right: any) => right?.level0Resource.identifier)
            .filter(
              (value: any, index: any, self: string | any[]) =>
                self.indexOf(value) === index,
            )
            .join(", ") || "-",
        waterClasses: waterClasses.length > 0 ? waterClasses : "-",
        accountNumber: subscriber?.accountNumber,
        status: subscriber?.isActive ? (
          <Tag status="success">{t("common.active")}</Tag>
        ) : (
          <Tag status="error">{t("common.inactive")}</Tag>
        ),
        view: (
          <Link
            to={`/polestar/subscribers/${subscriber.id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="btn-default flex items-center gap-3 w-min"
          >
            <ExternalLinkIcon className="w-4 h-4" />
            {t("common.view")}
          </Link>
        ),
      };
    })
    ?.filter(
      (row: { zones: string }) =>
        !filter.level0wrsIdentifier ||
        row.zones.split(", ").includes(filter.level0wrsIdentifier),
    );
  return (
    <div className="pb-0 flex flex-col gap-4 grow">
      <fieldset className="flex flex-row gap-4">
        <div className="flex-1">
          <Label htmlFor="subscriberNameOrAccNo">
            {t("subscriber.filter_name_id")}
          </Label>
          <SearchInput
            id="subscriberNameOrAccNo"
            onChange={e =>
              handleFilterChange("subscriberAccountIdName", e.target.value)
            }
            value={filter.subscriberAccountIdName}
            disabled={Boolean(predefinedFilter?.subscriberAccountIdName)}
          />
        </div>

        {showLevel0WRS && (
          <div className="flex-1">
            <Label htmlFor="level0Resource">{t("common.level0wrs")}</Label>
            {predefinedFilter?.level0wrsIdentifier ? (
              <ReadOnlyInput>
                {predefinedFilter.level0wrsIdentifier}
              </ReadOnlyInput>
            ) : (
              <SelectLevel0Resource
                inputId="level0Resource"
                level1ResourceId={params.level1ResourceId}
                value={filter.level0wrsIdentifier}
                onChange={e => {
                  handleFilterChange("level0wrsIdentifier", e?.label);
                }}
                isClearable
              />
            )}
          </div>
        )}

        <div className="flex-1">
          <Label htmlFor="status">
            {t("subscriber.filter_subscriber_status")}
          </Label>
          {predefinedFilter?.isActive !== undefined ? (
            <ReadOnlyInput>
              {
                activeOptions.find(i => i.value === predefinedFilter.isActive)
                  ?.label
              }
            </ReadOnlyInput>
          ) : (
            <Select
              inputId="status"
              options={activeOptions}
              value={activeOptions.find(
                activeOption => activeOption.value === filter.isActive,
              )}
              onChange={e => handleFilterChange("isActive", e?.value)}
              openMenuOnFocus
              isClearable
            />
          )}
        </div>
      </fieldset>
      <Table
        fields={tableFields}
        data={tableData}
        stickyHeader
        loading={isLoading}
        noRowsText={noRowText ?? t("common.no_data")}
      />
    </div>
  );
};

export default SelectSingleSubscriberTable;

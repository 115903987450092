import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Modal from "@components/layout/Modal";
import Table from "@components/layout/Table";
import Tag from "@components/shared/Tag";
import ApprovalTag from "@components/shared/ApprovalTag";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import ForwardDrawActionModals from "@components/modal/ForwardDrawActionModals";
import ApplicationActionButtons from "@components/form/ApplicationActionButtons";
import SelectLevel1Resource from "@components/form/SelectLevel1Resource";
import SelectAdminApprovalStatus from "@components/form/SelectAdminApprovalStatus";
import { useAllAdministrativeApprovals } from "@hooks/query/useAllAdministrativeApprovals";
import { useAppContext } from "@context/AppContext";
import { formatDate } from "@utils/formatDate";
import { getActionTimestamp } from "@utils/administrativeApproval";
import { toastError, toastSuccess } from "@utils/toast";
import {
  AdministrativeApprovalNumericStatus,
  AdministrativeApprovalType,
} from "@services/administrativeApprovals";
import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";
import EvidenceList from "@components/shared/EvidenceList";
import { useManualApproveForwardDraw } from "@hooks/mutation/useManualApproveForwardDraw";
import { useManualRejectForwardDraw } from "@hooks/mutation/useManualRejectForwardDraw";
import { useRequestForwardDrawInfo } from "@hooks/mutation/useRequestForwardDrawInfo";
import { extractionRightTypes } from "@services/extractionRight";
import { formatVolume } from "@utils/formatVolume";
import TextInput from "@components/form/TextInput";
import { formatDateInput } from "@utils/formatDate";
import { convertMLToLiter, convertLiterToML } from "@utils/convertUnits";
import { useSubscriberLevel0Resource } from "@hooks/query/useSubscriberLevel0Resource";
import SelectAccountingPeriod from "@components/form/SelectAccountingPeriod";

type Level1WRSForwardDrawProps = {
  level1Resource?: any;
};

const ForwardDrawsTable: React.FunctionComponent<Level1WRSForwardDrawProps> = ({
  level1Resource,
}) => {
  const { t } = useTranslation();
  const { checkPermissions } = useAppContext();
  const [selectedForwardDraw, setSelectedForwardDraw] = useState<any>();
  const [filter, setFilter] = useState<{
    accountingPeriod?: {
      id: string;
      periodStart: Date;
      periodEnd: Date;
    };
    customerName?: string;
    status?: string;
    level1wrsId?: string;
  }>({ accountingPeriod: level1Resource?.activeAccountingPeriod });

  const [confirmationModal, setConfirmationModal] = useState("");
  const [sampleText, setSampleText] = useState("");
  const [effectiveDate, setEffectiveDate] = useState<Date>();
  const [volume, setVolume] = useState(0);
  const [subscriberId, setSubscriberId] = useState("");
  const [level0ResourceId, setLevel0ResourceId] = useState("");
  const [showMaxAllowed, setShowMaxAllowed] = useState(false);
  const [selectedWaterClass, setSelectedWaterClass] = useState<any>();

  const { data: level0Resource = {} } = useSubscriberLevel0Resource({
    params: {
      subscriberId,
      level0ResourceId,
    },
    enabled: subscriberId !== "" && level0ResourceId !== "",
  });

  const [maxAllowedVolume, setMaxAllowedVolume] = useState(0);

  useEffect(() => {
    if (level0Resource?.overusedBalance !== undefined && selectedForwardDraw) {
      const waterClass = level0Resource.waterClasses.find(
        (wc: any) => wc.id === selectedForwardDraw.waterClassId,
      );
      setSelectedWaterClass(waterClass);
      const calculatedMaxVolume = Number(
        convertLiterToML(
          Math.min(selectedForwardDraw?.volume, waterClass?.overusedBalance),
        ),
      );
      setMaxAllowedVolume(calculatedMaxVolume);
      setVolume(calculatedMaxVolume);
    }
  }, [level0Resource, selectedForwardDraw]);

  const {
    data: forwardDraws = [],
    isLoading,
    refetch,
  } = useAllAdministrativeApprovals({
    params: {
      type: AdministrativeApprovalType.FD,
    },
  });

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  useEffect(() => {
    if (selectedForwardDraw) {
      setSubscriberId(selectedForwardDraw.buyer?.id);
      setLevel0ResourceId(
        selectedForwardDraw.extractionRights[0]?.level0Resource?.id,
      );
    }
  }, [selectedForwardDraw]);

  const {
    mutateAsync: manualApproveForwardDraws,
    isLoading: manualApproveIsLoading,
  } = useManualApproveForwardDraw();

  const { mutateAsync: manualRejectForwardDraws } =
    useManualRejectForwardDraw();

  const { mutateAsync: requestForwardDrawInfo } = useRequestForwardDrawInfo();

  const handleSave = async () => {
    const approvalId = selectedForwardDraw.id;
    try {
      if (confirmationModal === "acknowledge") {
        await manualApproveForwardDraws({
          approvalId,
          effectiveDate: effectiveDate!,
          volume: convertMLToLiter(volume!),
          reason: sampleText,
        });
        toastSuccess(`${t("approval.forward_draws.approve.toast.approve")}`);
      } else if (confirmationModal === "reject") {
        await manualRejectForwardDraws({
          approvalId,
          reason: sampleText,
        });
        toastSuccess(`${t("approval.forward_draws.approve.toast.rejected")}`);
      } else if (confirmationModal === "request_info") {
        await requestForwardDrawInfo({
          approvalId,
          reason: sampleText,
        });
        toastSuccess(`${t("approval.forward_draws.approve.toast.req_info")}`);
      }
      setEffectiveDate(undefined);
      setConfirmationModal("");
      setVolume(0);
      refetch();
    } catch (e: any) {
      toastError(t("approval.forward_draws.approve.toast.error"));
    }
  };

  return (
    <>
      <header className="flex justify-between items-end gap-3">
        <form className="grid grid-cols-1 gap-2 md:grid-cols-4 md:gap-3 2xl:w-2/1 relative z-20 justify-between items-end">
          {!level1Resource && (
            <div>
              <Label htmlFor="level1Resource">{t("common.level1wrs")}</Label>
              <SelectLevel1Resource
                inputId="level1Resource"
                onChange={e => {
                  handleFilterChange("level1wrsId", e?.value);
                }}
                isClearable
              />
            </div>
          )}

          {level1Resource || filter.level1wrsId ? (
            <div>
              <Label htmlFor="accountingPeriod">
                {t("common.accounting_period")}
              </Label>
              <SelectAccountingPeriod
                inputId="accountingPeriod"
                level1ResourceId={filter.level1wrsId}
                value={filter.accountingPeriod}
                onChange={(e: any) => {
                  handleFilterChange("accountingPeriod", {
                    id: e?.value?.id,
                    periodStart: e?.value?.perideStart
                      ? new Date(e?.value?.periodStart)
                      : "",
                    periodEnd: e?.value?.periodEnd
                      ? new Date(e?.value?.periodEnd)
                      : "",
                  });
                }}
              />
            </div>
          ) : null}

          <div>
            <Label htmlFor="customerName">
              {t("subscriber.filter_subscriber_name")}
            </Label>
            <SearchInput
              id="customerName"
              onChange={e => handleFilterChange("customerName", e.target.value)}
            />
          </div>
          <div>
            <Label>{t("common.status")}</Label>
            <SelectAdminApprovalStatus
              onValueChange={value => handleFilterChange("status", value)}
              adminApprovalsAndTransfers={forwardDraws}
            />
          </div>
        </form>
        {checkPermissions(["CreateApprovals"]) && (
          <Link
            to={`/polestar/administrative_approvals/approve_forward_draw?level1ResourceId=${
              level1Resource?.id ?? ""
            }`}
            className="btn-secondary text-sm rounded whitespace-nowrap"
          >
            {t("approval.forward_draw.create")}
          </Link>
        )}
      </header>
      <Table
        fields={[
          {
            title: t("common.level1wrs"),
            name: "level1Resource",
          },
          {
            title: t("common.accounting_period"),
            name: "accountingPeriod",
          },
          {
            title: t("approval.forward_draws.customer"),
            name: "buyerName",
          },
          {
            title: t("extraction_point.link.entitlement_id"),
            name: "extractionRightName",
          },
          {
            title: t("approval.forward_draws.priority"),
            name: "priority",
          },
          {
            title: t("common.volume"),
            name: "formatVolume",
          },
          {
            title: t("common.status"),
            name: "status",
          },
          {
            title: t("approval.forward_draws.timestamp"),
            name: "timestamp",
          },
          {
            title: t("common.effective_date"),
            name: "effectiveDate",
          },
          {
            title: t("common.action"),
            name: "action",
          },
        ]}
        data={forwardDraws
          ?.filter(
            (row: { level1Resource: any }) =>
              level1Resource === undefined ||
              row.level1Resource?.id === level1Resource?.id,
          )
          ?.filter(
            (row: { level1Resource: any }) =>
              filter.level1wrsId === undefined ||
              row.level1Resource?.id === filter.level1wrsId,
          )
          ?.filter(
            (row: { accountingPeriod: any }) =>
              filter.accountingPeriod === undefined ||
              row.accountingPeriod?.id === filter.accountingPeriod?.id,
          )
          ?.filter(
            (row: { status: any }) =>
              filter.status === undefined || row.status === filter.status,
          )
          ?.filter(
            (row: { seller: any; buyer: any }) =>
              !filter?.customerName?.length ||
              row.seller?.name
                .toString()
                .toLowerCase()
                .includes(filter?.customerName.toString().toLowerCase()) ||
              row.buyer?.name
                .toString()
                .toLowerCase()
                .includes(filter?.customerName.toString().toLowerCase()),
          )
          ?.map((app: any) => {
            const extractionRightName = app.extractionRights
              .filter(
                (i: any) => i.extractionRightTypeId !== extractionRightTypes.FD,
              )
              .map((i: any) => i.name)
              .join(", ");
            return {
              ...app,
              level1Resource: app.level1Resource?.identifier,
              extractionRightName,
              buyerName: app.buyer.name,
              accountingPeriod: formatAccountingPeriod(app.accountingPeriod),
              priority: app.extractionRights[0]?.waterClass?.name,
              status: (
                <ApprovalTag
                  isAccounted={app?.accountedAt}
                  status={app?.status}
                />
              ),
              timestamp: getActionTimestamp(app),
              effectiveDate:
                app.status === 2
                  ? formatDate(new Date(app.effectiveDate))
                  : "-",
              formatVolume: formatVolume(+app.volume),
              action: (
                <div className="flex gap-1 items-center w-full">
                  <button
                    type="button"
                    onClick={() => {
                      setConfirmationModal("FD");
                      setSelectedForwardDraw({
                        ...app,
                        extractionRightName,
                      });
                    }}
                    className="btn-default text-sm"
                  >
                    {t("common.view")}
                  </button>
                </div>
              ),
            };
          })}
        stickyHeader
        loading={isLoading}
        noRowsText={t("common.no_data")}
      />
      <Modal open={selectedForwardDraw && confirmationModal === "FD"}>
        <div className="flex flex-col">
          <div className="border-b p-4">
            <h3 className="text-lg font-bold leading-6 text-gray-900">
              {t("approval.forward_draws.title")}
            </h3>
          </div>
          <div className="p-4">
            <dl className="grid grid-cols-2 gap-x-4 gap-y-2 sm:grid-cols-1 mb-4">
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt className="font-medium text-gray-900">
                  {t("common.level1wrs")}:
                </dt>
                <dd className="text-gray-500">
                  {selectedForwardDraw?.level1Resource.name}
                </dd>
              </div>
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt className="font-medium text-gray-900">
                  {t("extraction_right.name")}:
                </dt>
                <dd className="text-gray-500">
                  {selectedForwardDraw?.extractionRightName}
                </dd>
              </div>
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt className="font-medium text-gray-900">
                  {t("common.accounting_period")}:
                </dt>
                <dd className="text-gray-500">
                  {formatAccountingPeriod(
                    selectedForwardDraw?.accountingPeriod,
                  )}
                </dd>
              </div>
              <div className="col-span-2 sm:col-span-1 flex gap-1 items-center">
                <dt className="font-medium text-gray-900">
                  {t("approval.forward_draws.volume")}:
                </dt>
                <dd className="text-gray-500">
                  {selectedForwardDraw?.status === 2 ? (
                    convertLiterToML(selectedForwardDraw?.volume) +
                    " " +
                    t("common.volume_unit")
                  ) : (
                    <div className="flex gap-1 items-center">
                      <TextInput
                        type="number"
                        value={volume ?? 0}
                        min={0}
                        max={maxAllowedVolume}
                        step={0.001}
                        onChange={e => {
                          setShowMaxAllowed(+e.target.value > maxAllowedVolume);
                          setVolume(
                            Math.min(+e.target.value, maxAllowedVolume),
                          );
                        }}
                        suffix={t("common.volume_unit")}
                      />
                      {showMaxAllowed && (
                        <span className="text-gray-400 whitespace-nowrap text-sm ml-2">
                          {t("approval.forward_draws.approve.max_allowed")}{" "}
                          {maxAllowedVolume}
                        </span>
                      )}
                    </div>
                  )}
                </dd>
              </div>
              {selectedForwardDraw?.status === 2 ? (
                <></>
              ) : (
                <div className="col-span-2 sm:col-span-1 flex gap-1">
                  <dt className="font-medium text-gray-900">
                    {t("approval.forward_draws.over_usage")}:
                  </dt>
                  <dd className="text-red-500">
                    {convertLiterToML(selectedWaterClass?.overusedBalance)}{" "}
                    <span className="text-gray-500">
                      {t("common.volume_unit")}
                    </span>
                  </dd>
                </div>
              )}
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt className="font-medium text-gray-900">
                  {t("approval.forward_draws.created_at")}:
                </dt>
                <dd className="text-gray-500">
                  {selectedForwardDraw?.lodgedAt
                    ? formatDate(new Date(selectedForwardDraw?.lodgedAt))
                    : "-"}
                </dd>
              </div>
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt className="font-medium text-gray-900">
                  {t("common.status")}:
                </dt>
                <dd className="text-gray-500">
                  <Tag
                    className="text-sx inline mr-1"
                    status={
                      selectedForwardDraw?.status === 1
                        ? "warning"
                        : selectedForwardDraw?.status === 2
                          ? "success"
                          : selectedForwardDraw?.status === 3
                            ? "error"
                            : selectedForwardDraw?.status === 4
                              ? "info"
                              : "default"
                    }
                  >
                    {selectedForwardDraw?.status === 2
                      ? t("common.approved")
                      : selectedForwardDraw?.status === 3
                        ? t("common.rejected")
                        : selectedForwardDraw?.status === 4
                          ? t("common.require_info")
                          : t("common.pending")}
                  </Tag>
                  {selectedForwardDraw?.accountedAt ? (
                    <Tag status="success">{t("common.finalized")}</Tag>
                  ) : selectedForwardDraw?.status ===
                    AdministrativeApprovalNumericStatus.Approved ? (
                    <Tag status="warning">{t("common.scheduled")}</Tag>
                  ) : (
                    <></>
                  )}
                </dd>
              </div>
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt className="font-medium text-gray-900">
                  {t("approval.permanent_trades.timestamp")}:
                </dt>
                <dd className="text-gray-500">
                  {formatDate(
                    new Date(
                      selectedForwardDraw?.status === 2
                        ? selectedForwardDraw?.approvedAt
                        : selectedForwardDraw?.status === 3
                          ? selectedForwardDraw?.rejectedAt
                          : selectedForwardDraw?.status === 4
                            ? selectedForwardDraw?.updatedAt
                            : selectedForwardDraw?.lodgedAt,
                    ),
                  )}
                </dd>
              </div>

              <div className="col-span-2 sm:col-span-1 flex gap-1 items-center">
                <dt className="font-medium text-gray-900">
                  {t("common.effective_date")}:
                </dt>
                <dd className="text-gray-500">
                  {selectedForwardDraw?.status === 2 ? (
                    formatDate(new Date(selectedForwardDraw?.effectiveDate))
                  ) : (
                    <div className="flex gap-1 items-center">
                      <TextInput
                        type="date"
                        max={
                          selectedForwardDraw?.accountingPeriod?.periodEnd
                            ? formatDateInput(
                                new Date(
                                  selectedForwardDraw.accountingPeriod.periodEnd,
                                ),
                              )
                            : ""
                        }
                        min={
                          selectedForwardDraw?.accountingPeriod?.periodStart
                            ? formatDateInput(
                                new Date(
                                  selectedForwardDraw.accountingPeriod.periodStart,
                                ),
                              )
                            : ""
                        }
                        value={
                          effectiveDate ? formatDateInput(effectiveDate) : ""
                        }
                        onChange={e =>
                          setEffectiveDate(new Date(e.target.value))
                        }
                      />
                      {!effectiveDate && (
                        <span className="text-red-500 ml-2 whitespace-nowrap">
                          {t(
                            "approval.forward_draws.approve.choose_effective_date_to_approve",
                          )}
                        </span>
                      )}
                    </div>
                  )}
                </dd>
              </div>
            </dl>
            <div className="flex gap-2">
              <div className="w-full border rounded-lg p-2 flex flex-col gap-3">
                <h3 className="font-semibold">
                  {t("approval.forward_draws.customer")}
                </h3>
                <dl className="text-sm grid grid-cols-2 gap-x-4 gap-y-2 sm:grid-cols-1">
                  <div className="col-span-2 sm:col-span-1 flex gap-1">
                    <dt className="font-medium text-gray-800">
                      {t("subscriber.name")}:
                    </dt>
                    <dd className="text-gray-400">
                      {selectedForwardDraw?.buyer?.name}
                    </dd>
                  </div>
                  <div className="col-span-2 sm:col-span-1 flex gap-1">
                    <dt className="font-medium text-gray-800">
                      {t("subscriber.account_number")}:
                    </dt>
                    <dd className="text-gray-400">
                      {selectedForwardDraw?.buyer?.accountNumber}
                    </dd>
                  </div>
                  <div className="col-span-2 sm:col-span-1 flex gap-1">
                    <dt className="font-medium text-gray-800">
                      {t("common.water_class")}:
                    </dt>
                    <dd className="text-gray-400">
                      {
                        selectedForwardDraw?.extractionRights[0]?.waterClass
                          ?.name
                      }
                    </dd>
                  </div>
                  <div className="col-span-2 sm:col-span-1 flex gap-1">
                    <dt className="font-medium text-gray-800">
                      {t("common.level0wrs")}:
                    </dt>
                    <dd className="text-gray-400">
                      {
                        selectedForwardDraw?.extractionRights[0]?.level0Resource
                          ?.identifier
                      }
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>

          {selectedForwardDraw?.evidences?.length ? (
            <EvidenceList data={selectedForwardDraw.evidences} />
          ) : null}

          <ApplicationActionButtons
            status={selectedForwardDraw?.status}
            onApprove={() => {
              if (!effectiveDate) {
                toastError(
                  t(
                    "approval.forward_draws.approve.choose_effective_date_to_approve",
                  ),
                );
                return;
              }
              if (volume === 0) {
                toastError(t("approval.forward_draws.approve.volume_is_0"));
                return;
              }
              setConfirmationModal("acknowledge");
            }}
            onCancel={() => {
              setSelectedForwardDraw(undefined);
              setEffectiveDate(undefined);
              setVolume(0);
            }}
            onReject={() => {
              setConfirmationModal("reject");
            }}
            onRequireMoreInfo={() => {
              setConfirmationModal("request_info");
            }}
          />
        </div>
      </Modal>
      <ForwardDrawActionModals
        confirmationModal={confirmationModal}
        setConfirmationModal={setConfirmationModal}
        setSampleText={setSampleText}
        handleSave={handleSave}
        isLoading={manualApproveIsLoading}
        isApproving
      />
    </>
  );
};

export default ForwardDrawsTable;

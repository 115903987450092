import { Link } from "react-router-dom";
import { sumBy } from "lodash";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import ExternalLinkIcon from "@components/icons/ExternalLinkIcon";
import { useAmalgamateOrSubdivideExtractionRightsContext } from "@context/AmalgamateOrSubdivideExtractionRightContext";
import { ExtractionRightApprovalType } from "@services/administrativeApprovals";

const AmalgamateOrSubdivideDetails = () => {
  const { t } = useTranslation();
  const {
    details,
    inputExtractionRights,
    outputExtractionRights,
    context,
    stepHelpers,
    outputExtractionRightTable,
    inputExtractionRightTable,
    setCancelIndex,
  } = useAmalgamateOrSubdivideExtractionRightsContext();

  const canCreateNewRight = () => {
    if (context === ExtractionRightApprovalType.Amalgamate) {
      return outputExtractionRights?.length === 0;
    } else if (context === ExtractionRightApprovalType.Subdivide) {
      const maxVolume = +inputExtractionRights?.[0]?.volume;
      const totalVolume =
        outputExtractionRights?.length > 0
          ? sumBy(outputExtractionRights, (i) => +i.volume)
          : 0;
      return totalVolume < maxVolume;
    }

    return false;
  };

  const createNewExtractionRightURL = `/polestar/level1wrs/${
    details?.level1wrs?.id
  }/extraction_rights/create?subscriberId=${details?.subscriber?.id}${
    inputExtractionRights.length
      ? `&level0ResourceId=${inputExtractionRights[0]?.level0ResourceId}`
      : ""
  }&administrativeApprovalId=${details.id}&isActive=false&newWindow=true`;

  const isSubmitDisabled =
    context === ExtractionRightApprovalType.Amalgamate
      ? outputExtractionRights?.length === 0
      : outputExtractionRights?.length < 2;

  return (
    <div className="flex flex-col gap-6 p-6 grow justify-between">
      <div className="space-y-4">
        <Heading light>
          {t("approval.subdivide_and_amalgamate.create.step_4.new", {
            context,
          })}
        </Heading>
      </div>
      {canCreateNewRight() ? (
        <Link
          className="btn-secondary flex items-center gap-2 w-max"
          target="_blank"
          to={createNewExtractionRightURL}
        >
          <ExternalLinkIcon className="w-4 h-4" />
          <span>
            {t("approval.subdivide_and_amalgamate.create.step_4.add", {
              context,
            })}
          </span>
        </Link>
      ) : (
        <button
          className="btn-secondary flex items-center gap-2 w-max"
          disabled
        >
          <ExternalLinkIcon className="w-4 h-4" />
          <span>
            {t("approval.subdivide_and_amalgamate.create.step_4.add", {
              context,
            })}
          </span>
        </button>
      )}
      {outputExtractionRightTable}

      <div className="space-y-4">
        <Heading light>
          {t("approval.subdivide_and_amalgamate.create.step_4.original", {
            context,
          })}
        </Heading>
      </div>
      {inputExtractionRightTable}

      <footer className="flex gap-4 -mx-6 mt-6 p-6 pb-0 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
          disabled={Boolean(details.id)}
        >
          {t("common.prev_step")}
        </button>

        <button
          type="button"
          className="btn-primary"
          onClick={stepHelpers.goToNextStep}
          disabled={isSubmitDisabled}
        >
          {t("common.next_step")}
        </button>

        <button
          type="button"
          className="btn-outline-primary"
          onClick={() => setCancelIndex(0)}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </div>
  );
};

export default AmalgamateOrSubdivideDetails;

import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import {
  ArrowUpOnSquareIcon,
  ArrowDownOnSquareIcon,
} from "@heroicons/react/24/outline";

import Table from "@components/layout/Table";
import NotificationMessageViewModal from "@components/form/notification/NotificationMessageViewModal";
import Label from "@components/form/Label";
import { useAppContext } from "@context/AppContext";
import { formatDateTime } from "@utils/formatDateTime";
import { Link } from "react-router-dom";
import { getSentMessages } from "@services/message";
import _ from "lodash";

const NotificationHistory: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<{
    level1ResourceId?: string;
    type?: string;
  }>({});
  const [selectedNotificationMessage, setSelectedNotificationMessage] =
    useState<any>();
  const { loggedInInfo } = useAppContext();
  const { data: messages, isLoading } = useQuery(
    ["messages", loggedInInfo?.userDetails?.id],
    getSentMessages
  );

  const getNotificationTypeOptions = () => {
    const uniqueOptions = messages?.reduce((acc, current) => {
      const { type } = current;
      const isDistinctType = acc.findIndex((i: any) => i.value === type) === -1;
      if (isDistinctType) {
        acc.push({
          label: _.startCase(type),
          value: type,
        });
      }
      return acc;
    }, []);
    return uniqueOptions;
  };

  const waterAccountStatementCheck = (message: any) => {
    if (message?.type === "Water Account Statement") {
      const urlRegex =
        /(http|https):\/\/[^ "\n]+\/evidences\/[a-zA-Z0-9-]+\/download/g;
      const foundUrls = message?.body?.match(urlRegex);
      if (foundUrls && foundUrls.length > 0) {
        message.evidenceUrl = foundUrls[0];
        message.body = message.body.replace(message.evidenceUrl, "");
        message.evidence = {
          id: message.evidenceUrl.split("/")[4],
          fileName: message.type,
        };
      }
    }
  };

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };
  const tableData = [...(messages || [])]
    ?.filter(
      (row: { type: string }) => !filter?.type || row.type === filter?.type
    )
    ?.map((message) => ({
      ...message,
      direction:
        !message.sender?.id ||
        message.sender?.id === loggedInInfo?.userDetails?.id ? (
          <ArrowUpOnSquareIcon
            className="text-gray-500 w-4 h-4"
            title={t("notification.outgoing") as string}
          />
        ) : (
          <ArrowDownOnSquareIcon
            className="text-gray-500 w-4 h-4"
            title={t("notification.incoming") as string}
          />
        ),
      createdAt: formatDateTime(new Date(message.createdAt)),
      readStatus: `${
        message.recipients.filter((r: any) => !!r.viewedAt).length
      }/${message.recipients.length}`,
      createdBy: message?.sender?.name || t("audit_trail.system"),
      recipients: t("notification.selected_subscribers", {
        count: message.recipients?.length,
      }),
      title: message.subject,
      type: _.startCase(message.type),
      action: (
        <div className="flex gap-1 items-center w-full">
          <button
            type="button"
            className="btn-default text-xs"
            onClick={() => {
              waterAccountStatementCheck(message);
              setSelectedNotificationMessage(message);
            }}
          >
            {t("common.view")}
          </button>
        </div>
      ),
    }));

  return (
    <>
      <div className="flex flex-col grow gap-4">
        <form className="flex gap-3 items-center justify-between">
          <div className="w-1/2 md:w-1/5">
            <Label htmlFor="type">{t("notification.type")}</Label>
            <Select
              options={getNotificationTypeOptions()}
              className="w-full"
              isClearable={true}
              value={getNotificationTypeOptions()?.find(
                (e: any) => e.value === filter?.type
              )}
              onChange={(e: any) => {
                handleFilterChange("type", e?.value);
              }}
            />
          </div>
          <Link className="btn-secondary text-sm" to="./create">
            {t("notification.new_notification")}
          </Link>
        </form>
        <Table
          pageSize={100}
          fields={[
            {
              title: "",
              name: "direction",
            },
            {
              title: t("notification.created_at"),
              name: "createdAt",
            },
            {
              title: t("notification.type"),
              name: "type",
            },
            {
              title: t("notification.created_by"),
              name: "createdBy",
            },
            {
              title: t("notification.subject"),
              name: "title",
            },
            {
              title: t("notification.read_status"),
              name: "readStatus",
            },
            {
              title: " ",
              name: "action",
            },
          ]}
          data={tableData}
          stickyHeader
          loading={isLoading}
        />
      </div>
      <NotificationMessageViewModal
        message={selectedNotificationMessage}
        notification={selectedNotificationMessage?.notification}
        onClose={() => setSelectedNotificationMessage(undefined)}
        showRecipient
      />
    </>
  );
};

export default NotificationHistory;

import html2pdf from "html2pdf.js";

export const downloadAsPDF = async ({
  data,
  fileName = Date.now().toString(),
}: {
  data: string;
  fileName?: string;
}) => {
  var opt = {
    margin: [0.5, 1.5, 0, 1.5],
    image: { type: "jpeg", quality: 1 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: "in", format: "a3", orientation: "portrait" },
    pagebreak: { mode: "avoid-all", before: "#page2el" },
  };
  const blob = await html2pdf().from(data).set(opt).outputPdf("blob");
  const downloadUrl = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = downloadUrl;
  link.download = fileName + ".pdf";
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(downloadUrl);
};

import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link } from "react-router-dom";
import ResetPasswordConfirmationForm from "../form/auth/ResetPasswordConfirmationForm";
import ResetPasswordForm from "../form/auth/ResetPasswordForm";
import AuthLayout from "../layout/AuthLayout";

const ForgotPassword: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");

  const handleResetPassword = (email: string) => {
    if (email) {
      setEmail(email);
      setStep(2);
    }
  };

  const handleResetPasswordConfirmed = () => {
    setStep(3);
  };

  return (
    <AuthLayout>
      <div className="flex min-h-full flex-col justify-center bg-primary-2 rounded-lg">
        <div className="sm:mx-auto w-full px-6 py-10 text-white text-xl text-center">
          {t("auth.reset_your_password")}
        </div>

        <div className="sm:mx-auto w-full">
          {step === 1 && <ResetPasswordForm onSubmit={handleResetPassword} />}
          {step === 2 && (
            <ResetPasswordConfirmationForm
              email={email}
              onConfirmed={handleResetPasswordConfirmed}
              onResendCode={() => setStep(1)}
            />
          )}
          {step === 3 && (
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <p className="text-sm w-full text-center">
                {t("auth.reset_password_success_message")}
              </p>
              <div className="flex items-center justify-between mt-2">
                <div className="text-sm text-center w-full">
                  <Link
                    to="../login"
                    className="font-semibold text-primary-3 hover:text-primary-2 mx-auto"
                  >
                    {t("auth.reset_password_back_button")}
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </AuthLayout>
  );
};

export default ForgotPassword;

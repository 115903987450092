import React from "react";
import { t as translate } from "i18next";
import { useTranslation } from "react-i18next";

import DropzoneIcon from "@components/icons/DropzoneIcon";
import CheckCircleIcon from "@components/icons/CheckCircleIcon";
import TrashIcon from "@components/icons/TrashIcon";

type Props = {
  title?: string;
  subtitle?: string | null;
  getRootProps: () => any;
  getInputProps: () => any;
  files?: File[];
  onDelete?: (index: number) => void;
  multiple?: boolean;
  className?: string;
  acceptedFileTypes?: Record<string, string[]>;
};

const DropzoneBox: React.FunctionComponent<Props> = ({
  title,
  subtitle = translate("evidence.supported"),
  getRootProps,
  getInputProps,
  files,
  onDelete,
  multiple = true,
  className = "max-w-xl",
  acceptedFileTypes,
}) => {
  const { t } = useTranslation();
  const headerText = title
    ? title
    : multiple
    ? t("shared.dropzone.title")
    : t("shared.dropzone.title_single");

  const formatAcceptedFiles = (acceptedFileTypes: Record<string, string[]>) => {
    return Object.entries(acceptedFileTypes)
      .map(([, extensions]) => {
        const exts = extensions.join(", ");
        return exts;
      })
      .join(", ");
  };

  return (
    <section className="w-full">
      <div className="py-6 px-4 border border-gray-300 border-dashed rounded text-center bg-gray-50 pb-12">
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <DropzoneIcon className="inline-block w-12 h-12 text-sky-500" />
          <h3 className="text-xl mb-1 mt-3">{headerText}</h3>
          <p className="text-gray-400">{subtitle}</p>
          {acceptedFileTypes && (
            <p className="text-gray-400">
              {t("level1wrs.create.image_drop_down.supported_file")}{" "}
              {formatAcceptedFiles(acceptedFileTypes)}
            </p>
          )}
        </div>
      </div>

      {files?.length ? (
        <table className="w-full text-sm border-collapse border border-gray-100 rounded mt-6">
          <thead>
            <tr>
              <th className="bg-gray-50 p-2 text-left font-normal" colSpan={2}>
                {t("bulk_meter_upload.file_to_upload")}
              </th>
            </tr>
          </thead>

          <tbody>
            {files.map((file: any, index: number) => {
              return (
                <tr key={`${file.name}--${index}`}>
                  <td className="p-2 border-t border-gray-100 flex items-center gap-1">
                    {file.name}
                    <CheckCircleIcon className="w-5 h-5 text-green-600" />
                  </td>
                  <td className="p-2 border-t border-gray-100">
                    {onDelete ? (
                      <button
                        className="p-2 btn-outline-primary flex items-center gap-1 text-xs rounded-sm px-3 py-1 ml-auto"
                        onClick={() => onDelete(index)}
                      >
                        <TrashIcon className="w-3 h-3" />
                        {t("common.delete")}
                      </button>
                    ) : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : null}
    </section>
  );
};

export default DropzoneBox;

import { useEffect } from "react";
import { t } from "i18next";
import { isFunction } from "lodash";

import Modal from "@components/layout/Modal";
import AlertIcon from "@components/icons/AlertIcon";
import XIcon from "@components/icons/XIcon";
import Loading from "@components/shared/Loading";

type Props = {
  children: React.ReactNode;
  open: boolean;
  onClose?: () => void;
  onConfirm: () => void;
  isSubmitting?: boolean;
  disabled?: boolean;
  confirmText?: string | null;
  cancelText?: string | null;
};
const ConfirmModal = ({
  children,
  open,
  onClose,
  onConfirm,
  isSubmitting = false,
  disabled = false,
  confirmText = t("common.ledger"),
  cancelText = t("common.cancel"),
}: Props) => {
  const isClosable = isFunction(onClose);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        if (isClosable) {
          event.preventDefault();
          onClose();
        }
      }
    };
    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [onClose, isClosable]);

  return (
    <Modal open={open}>
      {isSubmitting ? (
        <div className="py-10">
          <Loading />
        </div>
      ) : (
        <form
          onSubmit={e => {
            e.preventDefault();
            onConfirm();
          }}
        >
          <div className="flex justify-between items-start gap-4 p-6 pb-10  text-primary-2">
            <AlertIcon className="w-110 h-10" />
            <div className="flex-1 text-lg font-bold">{children}</div>
            {isClosable ? (
              <button
                onClick={onClose}
                className="text-gray-500 hover:text-gray-400"
              >
                <XIcon className="w-4 h-4" />
              </button>
            ) : null}
          </div>

          <footer className="flex flex-row justify-end gap-3 px-6 py-3 border-t border-gray-200">
            <button
              type="submit"
              className="btn-outline-primary py-1 rounded"
              disabled={isSubmitting}
            >
              {confirmText}
            </button>
            {isClosable ? (
              <button className="btn-primary py-1 rounded" onClick={onClose}>
                {cancelText}
              </button>
            ) : null}
          </footer>
        </form>
      )}
    </Modal>
  );
};

export default ConfirmModal;

import { format, parseISO, isDate } from "date-fns";

export const formatDate = (
  date: Date | string,
  formatString = "dd/MM/yyyy",
): string => {
  if (!date || !isDate(date)) return "";

  if (typeof date === "string") {
    date = parseISO(date);
  }
  try {
    return format(date, formatString);
  } catch (e) {
    return "";
  }
};

export const formatDateInput = (date: Date) => {
  return formatDate(date, "yyyy-MM-dd");
};

export const formatDatetimeInput = (date: Date) => {
  const isoDateTime = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000,
  ).toISOString();
  return isoDateTime.substring(0, 16);
};

export const formatReportDate = (date: Date) => {
  return formatDate(date, "yyyy-MM-dd");
};

import React from "react";

import { useSearchParams } from "react-router-dom";
import { useSubscriber } from "@hooks/query/useSubscriber";
import { useExtractionPoint } from "@hooks/query/useExtractionPoint";
import type { ConfirmData } from "@components/shared/ConfirmationDetail";
import { useTranslation } from "react-i18next";

type SelectMeterHandler = (value: { id: string; serialNo: string }) => void;

type ContextValue = {
  details: Details;
  setDetails: React.Dispatch<React.SetStateAction<Details>>;
  isComplete: boolean;
  setIsComplete: React.Dispatch<boolean>;
  handleSelecteMeter: SelectMeterHandler;
  getMeterReplacementInfo: () => ConfirmData;
  workflowInstance: any;
  setWorkflowInstance: React.Dispatch<any>;
};

const ReplaceMeterContext = React.createContext<ContextValue | undefined>(
  undefined,
);

type Details = {
  subscriber: {
    id: string;
    name: string;
    walletId: string;
  };
  extractionPoint: {
    id: string;
    name: string;
  };
  oldMeter: {
    id: string;
    serialNo: string;
    reading: string;
  };
  replacementMeter: {
    id: string;
    serialNo: string;
  };
};

const initialDetails: Details = {
  subscriber: {
    id: "",
    name: "",
    walletId: "",
  },
  extractionPoint: {
    id: "",
    name: "",
  },
  oldMeter: {
    id: "",
    serialNo: "",
    reading: "",
  },
  replacementMeter: {
    id: "",
    serialNo: "",
  },
};

type Props = { children: React.ReactNode };

const ReplaceMeterProvider: React.FunctionComponent<Props> = ({ children }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const subscriberId = searchParams.get("subscriberId") ?? "";
  const extractionPointId = searchParams.get("extractionPointId") ?? "";
  const [details, setDetails] = React.useState(() => ({
    ...initialDetails,
  }));
  const [isComplete, setIsComplete] = React.useState(false);
  const [workflowInstance, setWorkflowInstance] = React.useState<any>();

  useSubscriber(subscriberId ?? "", {
    onSuccess: (data: any) => {
      setDetails((prev) => ({
        ...prev,
        subscriber: {
          id: data.id,
          name: data.name,
          walletId: data.walletId,
          level1ResourceId: data.level1ResourceId,
        },
      }));
    },
  });

  useExtractionPoint(extractionPointId, {
    refetchOnWindowFocus: false,
    onSuccess: (data: any) => {
      setDetails((prev) => ({
        ...prev,
        extractionPoint: {
          id: data.id,
          name: data.name,
        },
        meter: {
          id: data?.meter?.id ?? "",
          serialNo: data?.meter?.serialNo ?? "unmetered",
        },
      }));
    },
  });

  const handleSelecteMeter: SelectMeterHandler = ({ id, serialNo }) => {
    setDetails((prev) => ({
      ...prev,
      replacementMeter: {
        id,
        serialNo,
      },
    }));
  };

  const getMeterReplacementInfo = () => {
    return {
      title: t("meter.replacement.details"),
      body: [
        {
          key: t("common.subscriber"),
          value: details.subscriber.name,
        },
        {
          key: t("extraction_point.name"),
          value: details.extractionPoint.name,
        },
        {
          key: t("meter.replacement.old_serial_no"),
          value: details.oldMeter.serialNo,
        },
        {
          key: t("meter.replacement.final_old_meter_reading"),
          value: `${details.oldMeter.reading}`,
        },
        {
          key: t("meter.replacement.replacement_serial_no"),
          value: details.replacementMeter.serialNo,
        },
      ],
    };
  };

  const value = {
    details,
    setDetails,
    isComplete,
    setIsComplete,
    workflowInstance,
    setWorkflowInstance,
    handleSelecteMeter,
    getMeterReplacementInfo,
  };

  return (
    <ReplaceMeterContext.Provider value={value}>
      {children}
    </ReplaceMeterContext.Provider>
  );
};

function useReplaceMeterContext() {
  const context = React.useContext(ReplaceMeterContext);
  if (context === undefined) {
    throw new Error(
      "useReplaceMeterContext must be used within a ReplaceMeterProvider",
    );
  }
  return context;
}

export { ReplaceMeterProvider, useReplaceMeterContext };

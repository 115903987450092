import {
  UpdateAmalgamationOrSubdivisionPayload,
  updateAmalgamationOrSubdivisionRequest,
} from "@services/administrativeApprovals";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useUpdateAmalgamationOrSubdivisionRequest = (
  options: Record<string, any> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation<any, any, UpdateAmalgamationOrSubdivisionPayload>({
    mutationFn: updateAmalgamationOrSubdivisionRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["request_amalgamation"] });
    },
    ...options,
  });
};

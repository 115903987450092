import React from "react";
import classNames from "classnames";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";

import Legend from "@components/form/Legend";
import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import SelectMeterType from "@components/form/SelectMeterType";
import SelectMeterUnits from "@components/form/SelectMeterUnits";
import LocationInputs from "@components/shared/LocationInputs";
import { convertLiterToML } from "@utils/convertUnits";
import { useAppContext } from "@context/AppContext";
import {
  initialMeterDetails,
  schema,
  type MeterDetails,
} from "@context/MeterCreateOrEditContext";
import ENV from "@config/env";

type MeterFormProps = {
  onSubmit: (data: any) => void;
  onCancel: () => void;
  submitText?: string;
  defaultValues?: Partial<MeterDetails>;
  children?: React.ReactNode;
  className?: string;
  level0ResourceId?: string;
  subscriberWalletId?: string;
  disableExtractionPointSelection?: boolean;
};

const MeterForm: React.FunctionComponent<MeterFormProps> = ({
  onSubmit,
  onCancel,
  submitText,
  defaultValues,
  children,
  className,
}) => {
  const { checkPermissions } = useAppContext();
  const { t } = useTranslation();

  const [lat, lng] = defaultValues?.location
    ? defaultValues?.location.split(",")
    : ["", ""];
  const [locationCoordinates, setLocationCoordinates] = React.useState(() => ({
    lat,
    lng,
  }));

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: Object.assign({}, initialMeterDetails, defaultValues),
  });

  const submit = (data: MeterDetails) => {
    const { lat, lng } = locationCoordinates;
    onSubmit({
      ...data,
      location: lat && lng ? `${lat},${lng}` : undefined,
    });
  };

  return (
    <form
      className={classNames("flex flex-col grow space-y-8", className)}
      onSubmit={handleSubmit(submit)}
    >
      <fieldset>
        <Legend>{t("meter.adding_meter.details")}</Legend>

        <div className="max-w-md mt-4 space-y-4">
          <div>
            <Label optional>{t("meter.adding_meter.type")}</Label>
            <Controller
              name="type"
              control={control}
              render={({ field: { onChange, value, name }, fieldState }) => (
                <SelectMeterType
                  onChange={({ value }) => onChange(value)}
                  value={value}
                  name={name}
                  errorMessage={fieldState.error?.message}
                />
              )}
            />
          </div>
          <div>
            <Label htmlFor="serialNo">
              {t("meter.adding_meter.serial_no")}
            </Label>
            <TextInput
              {...register("serialNo")}
              id="serialNo"
              errorMessage={errors?.serialNo?.message as string}
              placeholder={t("meter.adding_meter.enter_serial_no") as string}
            />
          </div>
          <div>
            <Label htmlFor="ipAddress" optional>
              {t("meter.adding_meter.ip_address")}
            </Label>
            <TextInput
              {...register("ipAddress")}
              id="ipAddress"
              errorMessage={errors?.ipAddress?.message as string}
              placeholder={t("meter.adding_meter.enter_ip_address") as string}
            />
          </div>
        </div>
      </fieldset>

      <fieldset>
        <Legend>{t("meter.adding_meter.set_units_and_click_over")}</Legend>
        <div className="max-w-md mt-4 space-y-4">
          <div>
            <Label>{t("meter.adding_meter.units")}</Label>
            <Controller
              name="unit"
              control={control}
              render={({ field: { onChange, value, name }, fieldState }) => (
                <SelectMeterUnits
                  onChange={({ value }) => onChange(value)}
                  value={value}
                  name={name}
                  errorMessage={fieldState.error?.message}
                />
              )}
            />
          </div>
          <div>
            <Label htmlFor="clickOver" optional>
              {t("meter.click_over")}
            </Label>
            <TextInput
              {...register("clickOver")}
              id="clickOver"
              placeholder={ENV.DEFAULT_METER_CLICK_OVER}
              errorMessage={errors?.clickOver?.message as string}
            />
          </div>
          <div>
            <Label htmlFor="verifiedAt" optional>
              {t("meter.adding_meter.verification_date")}
            </Label>
            <TextInput
              {...register("verifiedAt")}
              type="date"
              id="verifiedAt"
              errorMessage={errors?.verifiedAt?.message as string}
            />
          </div>
          {checkPermissions(["ViewThreshold", "UpdateThreshold"]) && (
            <div>
              <Label htmlFor="threshold" optional>
                {t("meter.adding_meter.enter_threshold")}
              </Label>
              <TextInput
                {...register("threshold")}
                type="number"
                id="threshold"
                placeholder={convertLiterToML(+ENV.DEFAULT_METER_THRESHOLD)}
                errorMessage={errors?.threshold?.message as string}
              />
            </div>
          )}
        </div>
      </fieldset>

      <fieldset>
        <Legend>{t("extraction_point.create.enter.location")}</Legend>
        <div className="mt-4">
          <LocationInputs
            locationCoordinates={locationCoordinates}
            setLocationCoordinates={setLocationCoordinates}
          />
        </div>
      </fieldset>

      {children}

      <footer className="border-t border-gray-200 flex gap-4 p-6 pb-0 -mx-6">
        <button type="submit" className="btn-primary" disabled={isSubmitting}>
          {submitText ?? t("meter.adding_meter.title")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default MeterForm;

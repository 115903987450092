import { RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import router from "./routers";
import AppContextProvider from "./context/AppContext";

import "./i18n";
import "react-toastify/dist/ReactToastify.css";
import "./styles/index.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      retry(failureCount, error: any) {
        const { status } = error?.response;
        if (status >= 400 && status < 500) return false;
        else if (failureCount < 2) return true;
        else return false;
      },
      useErrorBoundary: (error: any) => {
        const isAccessDenied = error.response?.status === 403;
        const isInternalServerError = error.response?.status >= 500;

        return isAccessDenied || isInternalServerError;
      },
    },
  },
});

export default function App() {
  // Note for local development had to remove strict mode for maps
  // ref: https://github.com/google-map-react/google-map-react/issues/1113
  return (
    <QueryClientProvider client={queryClient}>
      <AppContextProvider>
        <RouterProvider router={router} />
      </AppContextProvider>
      <ToastContainer autoClose={8000} hideProgressBar={true} />
    </QueryClientProvider>
  );
}

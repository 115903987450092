import AuthLayout from "@components/layout/AuthLayout";
import Loading from "@components/shared/Loading";
import ENV from "@config/env";
import { useAppContext } from "@context/AppContext";
import { ssoGetTokens } from "@services/auth";
import encryptedStorage from "@services/encryptedStorage";
import { useQuery } from "@tanstack/react-query";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

const SingleSignOn: FunctionComponent = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [error, setError] = useState<boolean>(false);
  const appContext = useAppContext();

  const { isFetching } = useQuery(
    ["sso", searchParams.get("code")],
    () =>
      ssoGetTokens({
        code: searchParams.get("code")!,
      }),
    {
      enabled: !!searchParams.get("code"),
      onSuccess: (data) => {
        if (!data) {
          setError(true);
        } else {
          appContext.setLoggedInInfo({
            isLoggedIn: true,
            token: data.token,
            userDetails: data.user,
          });

          encryptedStorage.setItem("token", data.token);
          encryptedStorage.setItem("isLoggedIn", "true");
          encryptedStorage.setItem("userDetails", JSON.stringify(data.user));

          const rootPath = data.user.isAdmin ? "/polestar" : "/zenith";
          navigate(`${rootPath}?welcome=1`);
        }
      },
    }
  );

  return (
    <AuthLayout>
      <div className="flex min-h-full flex-col justify-center bg-primary-2 rounded-lg">
        <div className="sm:mx-auto w-full px-6 py-10 text-white text-xl text-center">
          {t("auth.login_title")}
        </div>
        <div className="sm:mx-auto w-full">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="py-8 px-4 sm:px-10">
              {isFetching && (
                <Loading loadingText={`${t("auth.logging_in")}...`} />
              )}
              {(error || !searchParams.get("code")) && (
                <>
                  <div className="text-red-500 my-2">{t("auth.sso_error")}</div>
                  <div className="flex items-center justify-between">
                    <div className="text-sm">
                      <Link
                        to={`${ENV.API_HOST}/sso`}
                        className="font-semibold text-primary-3 hover:text-primary-2"
                      >
                        {t("auth.try_again")}
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default SingleSignOn;

import Select from "react-select";
import { t } from "i18next";

type Props = {
  onChange?: (value: any) => void;
  className?: string;
  value?: string;
  name?: string;
  isDisabled?: boolean;
};

const sources = ["ground", "surface", "channel", "pipeline"].map((value) => {
  return {
    label: t(`extraction_point.source_of_water.${value}`),
    value: value,
  };
});

const SelectSourceOfWater: React.FunctionComponent<Props> = ({
  onChange,
  className,
  value = 1,
  name,
  isDisabled = false,
}) => {
  return (
    <Select
      className={className}
      value={sources.find((i) => i.value === value) ?? sources[0]}
      name={name}
      options={sources}
      onChange={onChange}
      isDisabled={isDisabled}
    />
  );
};

export default SelectSourceOfWater;

import { useTranslation } from "react-i18next";
import Heading from "@components/layout/Heading";
import EventTransactionPaginatedTable from "@components/table/EventTransactionPaginatedTable";
import EndButtonModal from "@components/shared/EndButton";

type Props = {
  children?: React.ReactNode;
  references: string[];
};

const WorkflowComplete: React.FunctionComponent<Props> = ({
  children,
  references = [],
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col grow p-6 gap-4">
      <Heading light>{t("common.workflow_complete")}</Heading>
      {children}
      <div className="flex flex-col grow h-full">
        <EventTransactionPaginatedTable
          references={references}
          stickyHeader={true}
        />
      </div>
      <footer className="flex gap-4 p-6 pb-0 -mx-6 border-t border-gray-200">
        <EndButtonModal />
      </footer>
    </div>
  );
};

export default WorkflowComplete;

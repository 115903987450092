import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { getBillingGroups } from "@services/billingGroup";

export function useGetBillingGroups({
  params,
  options,
}: {
  params?: Record<string, any>;
  options?: UseQueryOptions;
} = {}) {
  return useQuery<any>({
    queryKey: [
      "billing_groups",
      { level1ResourceId: params?.level1ResourceId },
    ],
    queryFn: () => getBillingGroups(params),
    refetchOnWindowFocus: false,
    ...options,
  });
}

import { useMutation } from "@tanstack/react-query";

import { createInitialDeclaration } from "@services/declarations";

export const useCreateInitialDeclaration = (
  options: Record<string, any> = {}
) => {
  return useMutation({
    mutationFn: createInitialDeclaration,
    ...options,
  });
};

import { useQuery } from "@tanstack/react-query";
import { getMeterById } from "@services/meter";

export const useMeter = (id: string, options: Record<string, any> = {}) => {
  return useQuery<any>({
    queryKey: ["meters", id],
    queryFn: () => getMeterById(id),
    enabled: Boolean(id),
    ...options,
  });
};

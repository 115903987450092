import React from "react";
import { useTranslation } from "react-i18next";

import Heading1 from "@components/layout/Heading";
import SelectSingleLevel1WRSTable from "@components/table/SelectSingleLevel1WRSTable";
import { useSeasonalTransferContext } from "@context/SeasonalTransferContext";

const SeasonalTransferSelectLevel1WRS = () => {
  const { t } = useTranslation();
  const { details, stepHelpers, handleChangeDetails, navigateForCancel } =
    useSeasonalTransferContext();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    stepHelpers.goToNextStep();
  };

  return (
    <form
      className="flex flex-col gap-6 p-6 grow justify-between"
      onSubmit={handleSubmit}
    >
      <div className="space-y-4">
        <Heading1 light>
          {t("approval.admin_transfer.create.step_1.select_level1wrs")}
        </Heading1>
      </div>
      <SelectSingleLevel1WRSTable
        onSelect={(item: any) => {
          handleChangeDetails("level1wrs", item);
        }}
        selected={details.level1wrs}
      />

      <footer className="flex gap-4 -mx-6 mt-2 p-6 pb-0 border-t border-gray-200">
        <button
          type="submit"
          className="btn-primary"
          disabled={details?.level1wrs?.id === ""}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default SeasonalTransferSelectLevel1WRS;

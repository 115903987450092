import React from "react";
import { useTranslation } from "react-i18next";

import Tag from "@components/shared/Tag";
import { AdministrativeApprovalNumericStatus } from "@services/administrativeApprovals";
import { getStatusAndText } from "@utils/administrativeApproval";

interface ApprovalTagProps {
  status: number;
  isAccounted: boolean;
}

const ApprovalTag: React.FC<ApprovalTagProps> = ({ status, isAccounted }) => {
  const { t } = useTranslation();
  const { status: tagStatus, text } = getStatusAndText(status);

  return (
    <>
      <Tag className="mr-1" status={tagStatus ?? "default"}>
        {text}
      </Tag>
      {tagStatus === "success" && isAccounted ? (
        <Tag status="success">{t("common.finalized")}</Tag>
      ) : status === AdministrativeApprovalNumericStatus.Approved ? (
        <Tag status="warning">{t("common.scheduled")}</Tag>
      ) : null}
    </>
  );
};

export default ApprovalTag;

import React from "react";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import Table from "@components/layout/Table";
import Label from "@components/form/Label";
import Legend from "@components/form/Legend";
import TextInput from "@components/form/TextInput";
import SelectLevel1Resource from "@components/form/SelectLevel1Resource";
import ExternalLinkIcon from "@components/icons/ExternalLinkIcon";
import { formatVolume } from "@utils/formatVolume";
import { formatDateInput } from "@utils/formatDate";
import { useStepContext } from "@context/shared/StepContext";
import { useCreateDistributionLossContext } from "@context/CreateDistributionLossContext";
import { useAllExtractionRights } from "@hooks/query/useAllExtractionRights";
import { extractionRightTypes } from "@services/extractionRight";

const EnterDistributionLossDetails: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    distributionLossData,
    setDistributionLossData,
    isValidData,
    handleCancel,
    currentAccountingPeriod,
  } = useCreateDistributionLossContext();

  const { stepHelpers } = useStepContext();
  const level1ResourceId = distributionLossData?.level1Resource?.id;
  const {
    data: extractionRights = [],
    isFetching: isFetchingExtractionRights,
  } = useAllExtractionRights({
    params: {
      level1ResourceId,
      types: [extractionRightTypes.DL],
      preload: ["extractionPoints"],
    },
    options: {
      enabled: Boolean(level1ResourceId),
    },
  });

  const tableFields = [
    {
      title: "",
      name: "radio",
    },
    {
      title: t("common.extraction_right"),
      name: "name",
    },
    {
      title: t("distribution_losses.table.account_number"),
      name: "accountNumber",
    },
    {
      title: t("common.subscriber"),
      name: "subscriberName",
    },
    {
      title: t("distribution_losses.table.volume"),
      name: "volume",
    },
    {
      title: t("distribution_losses.table.remaining_balance"),
      name: "remainingBalance",
    },
    {
      title: t("common.actions"),
      name: "actions",
    },
  ];

  const tableData = extractionRights.map((i: any) => {
    return {
      ...i,
      accountNumber: i.subscriber.accountNumber,
      subscriberName: i.subscriber.name,
      remainingBalance: formatVolume(+i.remainingBalance),
      volume: formatVolume(+i.volume),
      radio: (
        <input
          type="radio"
          onChange={() => {
            setDistributionLossData((prev: any) => ({
              ...prev,
              extractionRight: {
                id: i.id,
                name: i.name,
              },
              extractionPointName: i.extractionPoints?.[0]?.name,
              previousDeclarations: i.extractionPoints?.[0]?.declarations,
              meterSerialNo: i.extractionPoints?.[0]?.meter?.serialNo,
              subscriberWalletId: i.subscriber?.walletId,
              subscriber: {
                id: i.subscriber?.id,
                name: i.subscriber?.name,
              },
            }));
          }}
          checked={distributionLossData.extractionRight.id === i.id}
          name="extractionRight"
        />
      ),
      actions: (
        <a
          href={`/polestar/subscribers/${i.subscriber?.id}?level0ResourceId=${i.level0ResourceId}&extractionPointId=${i.extractionPoints?.[0]?.id}`}
          target="_blank"
          rel="noreferrer"
          className="flex gap-3 items-center btn-default text-sm rounded"
        >
          <ExternalLinkIcon className="w-4 h-4" />
          {t("distribution_losses.view_declarations")}
        </a>
      ),
    };
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    stepHelpers.goToNextStep();
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="overflow-auto flex flex-col grow p-6 space-y-6"
    >
      <Heading light>{t("distribution_losses.enter_volume")}</Heading>

      <fieldset className="space-y-4">
        <div className="max-w-md">
          <Label>{t("common.level1wrs")}</Label>
          <SelectLevel1Resource
            value={distributionLossData.level1Resource.id}
            onChange={(option) => {
              setDistributionLossData((prev: any) => {
                if (prev.level1Resource.id === option.value) {
                  return prev;
                }

                return {
                  ...prev,
                  level1Resource: {
                    id: option.value,
                    name: option.label,
                  },
                  extractionRight: {
                    id: "",
                    name: "",
                  },
                  extractionPointName: "",
                  previousDeclarations: [],
                  meterSerialNo: "",
                  subscriberWalletId: "",
                  subscriber: {
                    id: "",
                    name: "",
                  },
                };
              });
            }}
          />
        </div>
        <Table
          fields={tableFields}
          data={tableData}
          loading={isFetchingExtractionRights}
        />
      </fieldset>

      <fieldset className="space-y-4">
        <Legend>{t("distribution_losses.title")}</Legend>
        <div className="max-w-md">
          <Label htmlFor="volume">{t("distribution_losses.new_volume")}</Label>
          <TextInput
            className="max-w-xl"
            id="volume"
            type="number"
            value={distributionLossData.volume}
            onChange={(e) =>
              setDistributionLossData({
                ...distributionLossData,
                volume: e.target.value,
              })
            }
            suffix={t("common.volume_unit")}
            required
          />
        </div>
        <div className="max-w-md">
          <Label htmlFor="readAt">{t("common.effective_date")}</Label>
          <TextInput
            type="date"
            id="readAt"
            value={distributionLossData.readAt}
            onChange={(e) => {
              setDistributionLossData((prev: any) => ({
                ...prev,
                readAt: formatDateInput(new Date(e.target.value)),
              }));
            }}
            min={
              distributionLossData.previousDeclarations?.length
                ? formatDateInput(
                    new Date(
                      distributionLossData.previousDeclarations[0]?.["readAt"]
                    )
                  )
                : formatDateInput(
                    new Date(currentAccountingPeriod?.periodStart)
                  )
            }
            max={formatDateInput(new Date(currentAccountingPeriod?.periodEnd))}
            disabled={!distributionLossData?.extractionRight?.id}
            required
          />
        </div>
      </fieldset>

      <div className="grow" />

      <footer className="-mx-6 flex gap-4 p-6 pb-0 border-t border-gray-200">
        <button type="submit" className="btn-primary" disabled={!isValidData}>
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={handleCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default EnterDistributionLossDetails;

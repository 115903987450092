import { t } from "i18next";

export const getIsExtractionRightLinkableWithPoint = (
  extractionRight: any,
  extractionPointId: string,
  administrativeApprovals: any[]
): { isLinkable: boolean; reason?: string } => {
  const hasAdminTransfer = administrativeApprovals?.find(
    (t: any) =>
      t.type === 3 &&
      t.extractionPointId &&
      t.extractionPointId === extractionPointId &&
      t.extractionRightId === extractionRight.id
  );
  const isLinkable =
    extractionRight.rightFractions.find(
      (rf: any) =>
        rf.extractionPointId === extractionPointId &&
        rf.extractionRightId === extractionRight.id
    )?.isLinked === true
      ? true
      : hasAdminTransfer?.status === 1
      ? true
      : false;

  const reason =
    hasAdminTransfer?.status === 1
      ? t("common.statuses.await_action")
      : undefined;

  return { isLinkable, reason };
};

import { useTranslation } from "react-i18next";
import NotificationListCompact from "@components/form/notification/NotificationListCompact";
import { useAppContext } from "@context/AppContext";
import NotificationIcon from "@components/icons/NotificationIcon";
import Card from "@components/layout/Card";

type NotificationWidgetProps = {
  level1Resource: any;
};

const NotificationWidget: React.FunctionComponent<NotificationWidgetProps> = ({
  level1Resource,
}) => {
  const { notifications } = useAppContext();
  const { t } = useTranslation();

  return (
    <Card
      header={
        <p className="flex gap-3">
          <NotificationIcon />
          {t("notification.subscriber_widget_title")}
        </p>
      }
    >
      <NotificationListCompact
        notifications={notifications.filter(
          (notification) => notification.level1ResourceId === level1Resource?.id
        )}
      />
    </Card>
  );
};

export default NotificationWidget;

import React from "react";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import ConfirmModal from "@components/shared/ConfirmModal";
import WorkflowComplete from "@components/shared/WorkflowComplete";
import ConfirmationDetail from "@components/shared/ConfirmationDetail";
import { useStepContext } from "@context/shared/StepContext";
import { useDeclarationContext } from "@context/DeclarationContext";
import { useEvidenceContext } from "@context/shared/EvidenceContext";
import { toastSuccess, toastError } from "@utils/toast";
import { isValidationError } from "@utils/formError";

type ConfirmDecommissionMeterProps = {
  onSubmit: () => Promise<any>;
  onCancel: () => void;
  startAtIndex: number;
  isComplete: boolean;
  workflowInstanceIds: string[];
};

const ConfirmDecommissionMeter: React.FunctionComponent<
  ConfirmDecommissionMeterProps
> = ({ onSubmit, onCancel, workflowInstanceIds, startAtIndex, isComplete }) => {
  const { t } = useTranslation();
  const { stepHelpers } = useStepContext();
  const { declaration } = useDeclarationContext();
  const { getEvidencesInfo } = useEvidenceContext();

  const [isLoading, setIsLoading] = React.useState(false);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      await onSubmit();
      toastSuccess(t("declaration.toast.save_success"));
    } catch (error: any) {
      const { data } = error?.response;
      const errorMessages = isValidationError(error)
        ? data?.errors?.map((i: any) => i.message)
        : [data?.message];

      toastError(
        <>
          <p>{t("declaration.toast.save_failure")}</p>
          {errorMessages?.length ? (
            <ul className="list-disc pl-4">
              {errorMessages.map((text: any) => {
                return <li key={text}>{text}</li>;
              })}
            </ul>
          ) : null}
        </>
      );
    }

    setIsLoading(false);
    setShowConfirmModal(false);
  };

  if (isComplete && workflowInstanceIds.length) {
    return <WorkflowComplete references={workflowInstanceIds} />;
  }

  return (
    <>
      <div className="p-6 flex-col gap-4 grow space-y-4">
        <Heading light>{t("meter.decommission.confirm_unlink")}</Heading>

        <ConfirmationDetail
          data={[
            {
              title: t("meter.decommission.meter_to_unlink"),
              body: [
                {
                  key: t("meter.serial_no"),
                  value: declaration.meter.serialNo,
                },
                {
                  key: t("extraction_point.name"),
                  value: declaration.extractionPoint.name,
                },
              ],
              disableEdit: true,
            },
            getEvidencesInfo(),
          ]}
          onEdit={(step) => stepHelpers.setStep(step + startAtIndex)}
        />
      </div>

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={() => stepHelpers.goToPrevStep()}
        >
          {t("common.prev_step")}
        </button>
        <button
          type="button"
          className="btn-primary"
          onClick={() => {
            setShowConfirmModal(true);
          }}
        >
          {t("common.ledger")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>

      <ConfirmModal
        open={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
        }}
        onConfirm={handleConfirm}
        isSubmitting={isLoading}
      >
        {t("meter.decommission.modal.confirm_unlink")}
      </ConfirmModal>
    </>
  );
};

export default ConfirmDecommissionMeter;

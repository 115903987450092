import { parse } from "json2csv";

export const downloadAsCSV = ({
  data,
  fileName = Date.now().toString(),
  parser = parse,
}: {
  data: Record<string, any>[];
  fileName?: string;
  parser?: any;
}) => {
  const csv = parser(data);
  const blob = new Blob([csv], { type: "text/csv" });
  const downloadUrl = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = downloadUrl;
  link.download = fileName + ".csv";
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(downloadUrl);
};

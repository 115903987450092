import LockIcon from "@components/icons/LockIcon";
import { useTranslation } from "react-i18next";
import HandleGoBackOrClose from "@components/shared/HandleGoBackOrClose";

const EndButton = () => {
  const handleGoBackOrClose = HandleGoBackOrClose();
  const { t } = useTranslation();

  return (
    <button type="button" className="btn-primary" onClick={handleGoBackOrClose}>
      <LockIcon className="w-5 h-5 pr-1" />
      {t("common.end")}
    </button>
  );
};

export default EndButton;

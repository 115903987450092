type Props = {
  className?: string;
};

const OpenFileIcon: React.FunctionComponent<Props> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 64 80"
      version="1.1"
      xmlSpace="preserve"
      x="0px"
      y="0px"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      className={className}
    >
      <rect x="0" y="0" width="64" height="64" fill="none" />
      <path d="M10.344,51l5.4,-18c0,-0 37.975,0 37.975,0c0,-0 -4.5,18 -4.5,18l-38.875,0Z" />
      <path d="M16,21l0,10l-1,0c-0.442,0 -0.831,0.29 -0.958,0.713l-4.042,13.474l0,-24.187l6,-0Z" />
      <path d="M47,26l2,0c0,0 0,5 0,5l-2,-0l0,-5Z" />
      <path d="M18,31l0,-18c-0,0 27,0 27,0c0,0 0,18 0,18l-27,-0Zm3,-2l21,0c0.552,0 1,-0.448 1,-1c0,-0.552 -0.448,-1 -1,-1l-21,0c-0.552,0 -1,0.448 -1,1c0,0.552 0.448,1 1,1Zm-0,-4l21,0c0.552,-0 1,-0.448 1,-1c-0,-0.552 -0.448,-1 -1,-1l-21,0c-0.552,-0 -1,0.448 -1,1c-0,0.552 0.448,1 1,1Zm-0,-4l21,0c0.552,-0 1,-0.448 1,-1c-0,-0.552 -0.448,-1 -1,-1l-21,0c-0.552,-0 -1,0.448 -1,1c-0,0.552 0.448,1 1,1Zm-0,-4l8,0c0.552,-0 1,-0.448 1,-1c-0,-0.552 -0.448,-1 -1,-1l-8,0c-0.552,-0 -1,0.448 -1,1c-0,0.552 0.448,1 1,1Z" />
    </svg>
  );
};

export default OpenFileIcon;

import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";

export function useHashValue(merge?: Record<string, any>) {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const newHash = { ...queryString.parse(hash), ...(merge || {}) };

  return {
    hash: newHash,
    queryString: queryString.stringify(newHash),
    replaceHash: (merge?: Record<string, any>) => {
      const newHash = { ...queryString.parse(hash), ...(merge || {}) };

      navigate(`#${queryString.stringify(newHash)}`);
    },
  };
}

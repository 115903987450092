import { useQuery } from "@tanstack/react-query";

import { getAllSubscriberAdministrativeApprovals } from "@services/zenith/administrativeApprovals";

type UseAllAdministrativeApprovalsParams = {
  params: Record<string, any>;
  [key: string]: any;
};

export const useAllAdministrativeApprovals = ({
  params,
  ...options
}: Partial<UseAllAdministrativeApprovalsParams> = {}) => {
  return useQuery<any>({
    queryKey: ["administrativeApprovals", params],
    queryFn: () => getAllSubscriberAdministrativeApprovals(params),
    ...options,
  });
};

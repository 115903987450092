import React from "react";
import classNames from "classnames";
import { sumBy } from "lodash";
import { useTranslation } from "react-i18next";

import Legend from "@components/form/Legend";
import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import Table from "@components/layout/Table";
import CheckboxInputWithLabel from "@components/form/CheckboxInputWithLabel";
import InformationCircleIcon from "@components/icons/InformationCircleIcon";
import { AdjustedWaterClassVolumeDetail } from "@context/WaterClassContext";
import { convertLiterToML, convertMLToLiter } from "@utils/convertUnits";
import { formatVolume } from "@utils/formatVolume";

type AdjustLevel0ResourceWaterClassesTableProps = {
  waterClasses: any;
  currentYearLevel0wrsVolume: number;
  nextYearLevel0wrsVolume: number;
  level0wrsIdentifier: string;
  hasValidationError: boolean;
  doAdjustWaterClassVolumeDetails: boolean;
  setDoAdjustWaterClassVolumeDetails: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setAdjustedWaterClassVolumeDetails: React.Dispatch<
    React.SetStateAction<AdjustedWaterClassVolumeDetail[]>
  >;
  adjustedWaterClassVolumeDetails: AdjustedWaterClassVolumeDetail[];
  validateAdjustedClassVolume: any;
  isAdjustingClassBalanceMandatory?: boolean;
  keepFixedNextYearVolume?: { classId: string; nextYearVolume: number };
};

const AdjustLevel0ResourceWaterClassesTable = ({
  waterClasses,
  currentYearLevel0wrsVolume,
  nextYearLevel0wrsVolume,
  level0wrsIdentifier,
  doAdjustWaterClassVolumeDetails,
  setDoAdjustWaterClassVolumeDetails,
  setAdjustedWaterClassVolumeDetails,
  adjustedWaterClassVolumeDetails,
  validateAdjustedClassVolume,
  hasValidationError,
  isAdjustingClassBalanceMandatory = false,
  keepFixedNextYearVolume,
}: AdjustLevel0ResourceWaterClassesTableProps) => {
  const { t } = useTranslation();

  const availableToDistribute =
    nextYearLevel0wrsVolume -
    sumBy(adjustedWaterClassVolumeDetails, (i) => {
      const isAdjustmentDisabled =
        keepFixedNextYearVolume &&
        keepFixedNextYearVolume?.classId === i.classId;

      if (isAdjustmentDisabled) return +keepFixedNextYearVolume.nextYearVolume;

      return +i.adjustedVolume;
    });

  const tableFields = [
    {
      title: t("level0wrs.update.step_3.class_name"),
      name: "name",
    },
    {
      title: t("level0wrs.update.step_3.class_volume"),
      name: "volume",
    },
    {
      title: t("level0wrs.update.step_3.adjust_class_volume"),
      name: "adjustedBalance",
    },
  ];

  const tableData = waterClasses.map((waterClass: any) => {
    const isAdjustmentDisabled =
      keepFixedNextYearVolume &&
      keepFixedNextYearVolume?.classId === waterClass.id;
    return {
      name: waterClass.name,
      volume: formatVolume(+waterClass.volume),
      adjustedBalance: (
        <TextInput
          className="max-w-sm"
          type="number"
          step="0.001"
          min={0}
          suffix={t("common.volume_unit")}
          required={isAdjustingClassBalanceMandatory && hasValidationError}
          placeholder="0.000"
          disabled={isAdjustmentDisabled}
          onChange={(e) => {
            setAdjustedWaterClassVolumeDetails((prev) => {
              return prev.map((i) => {
                if (waterClass.id === i.classId) {
                  return {
                    ...i,
                    adjustedVolume: e.target.value,
                  };
                }
                return i;
              });
            });
          }}
          value={
            isAdjustmentDisabled
              ? keepFixedNextYearVolume.nextYearVolume.toFixed(3)
              : adjustedWaterClassVolumeDetails.find(
                  (i) => i.classId === waterClass.id
                )?.adjustedVolume
          }
          onBlur={() => {
            validateAdjustedClassVolume({
              availableToDistribute,
              currentYearLevel0wrsYield: convertLiterToML(
                currentYearLevel0wrsVolume
              ),
            });
          }}
        />
      ),
    };
  });

  return (
    <fieldset className="space-y-4">
      <Legend>
        {t("level0wrs.update.step_3.title", {
          level0wrsIdentifier: level0wrsIdentifier,
        })}
      </Legend>

      <div>
        <CheckboxInputWithLabel
          htmlFor="do_adjust_water_classes"
          label={
            <strong>{t("level0wrs.update.step_3.adjust_checkbox")}</strong>
          }
          checked={doAdjustWaterClassVolumeDetails}
          onChange={(e) => {
            setDoAdjustWaterClassVolumeDetails(e.target.checked);
          }}
        />
        {isAdjustingClassBalanceMandatory ? (
          <p className="ml-7 text-sm text-gray-500 flex gap-2">
            <span>
              <InformationCircleIcon className="text-yellow-600 w-5 h-5" />
            </span>
            {t("level0wrs.update.step_3.adjust_checkbox_subtitle_1")}
          </p>
        ) : (
          <p className="ml-7 text-sm text-gray-500">
            {t("level0wrs.update.step_3.adjust_checkbox_subtitle_2")}
          </p>
        )}
      </div>

      {doAdjustWaterClassVolumeDetails && (
        <>
          <div>
            <Label htmlFor="level0wrs_volume">
              {t("level0wrs.update.step_3.available_to_distribute", {
                level0wrsName: level0wrsIdentifier,
              })}
            </Label>
            <p
              className={classNames(
                "mt-1 font-bold text-2xl",
                +availableToDistribute >= 0 ? "text-green-500" : "text-red-400"
              )}
            >
              {formatVolume(+convertMLToLiter(+availableToDistribute))}
            </p>
          </div>

          <div className="space-y-4">
            <p>
              {t("level0wrs.update.step_3.title", {
                level0wrsIdentifier: level0wrsIdentifier,
              })}
              <span
                className={classNames("ml-2", {
                  hidden: isAdjustingClassBalanceMandatory,
                })}
              >
                ({t("common.optional")})
              </span>
            </p>
            <Table
              data={tableData}
              fields={tableFields}
              noRowsText={t("level0wrs.update.step_3.no_row_text")}
              totals={[
                {
                  value: t("level0wrs.update.step_3.zone_volume"),
                  justifyRight: true,
                },
                {
                  value: formatVolume(currentYearLevel0wrsVolume),
                },
                {
                  value: (
                    <TextInput
                      className="max-w-sm"
                      min={0}
                      suffix={t("common.volume_unit")}
                      required
                      placeholder="0.000"
                      value={formatVolume(
                        convertMLToLiter(nextYearLevel0wrsVolume),
                        ""
                      )}
                      disabled
                    />
                  ),
                },
              ]}
            />
          </div>
        </>
      )}
    </fieldset>
  );
};

export default AdjustLevel0ResourceWaterClassesTable;

import { useTranslation } from "react-i18next";
import AuthLayout from "@components/layout/AuthLayout";
import DropzoneBox from "@components/shared/DropzoneBox";
import { useDropzone } from "react-dropzone";
import { useFileVerification } from "@hooks/mutation/useFileVerification";
import Modal from "@components/layout/Modal";
import Loading from "@components/shared/Loading";
import { useState } from "react";
import EntryVCModal from "@components/layout/VCModal";
import OpenFileIcon from "@components/icons/OpenFileIcon";
import Tag from "@components/shared/Tag";
import { toastError } from "@utils/toast";

interface VerificationData {
  contentIdentifier: string;
  result: string;
  status: string;
  vc: any;
}

export default function FileVerification() {
  const { t } = useTranslation();
  const { mutate: verify, isLoading } = useFileVerification();
  const [verification, setVerification] = useState<VerificationData>();
  const [isVCModalOpen, setIsVCModalOpen] = useState(false);

  const handleCloseVCModal = () => {
    setIsVCModalOpen(false);
  };

  const handleOpenVCModal = (data: any) => {
    setIsVCModalOpen(true);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => {
      verify(
        {
          file: files[0],
          isEncrypted: false,
        },
        {
          onSuccess: (data) => {
            setVerification(data);
          },
          onError: (error) => {
            toastError("File verification failed, Network error");
          },
        }
      );
    },
    multiple: false,
  });

  return (
    <AuthLayout>
      <div className="flex min-h-full flex-col justify-center bg-transparent rounded-lg">
        <div className="mx-auto w-full px-6 py-6 text-white xl:text-6xl text-4xl text-center">
          {t("verification.title")}
        </div>

        {!verification && (
          <>
            <div className="mx-auto w-full">
              <div className="bg-white shadow rounded-3xl">
                <div className="mx-auto w-full px-6 py-8 text-blue-700 text-2xl text-center">
                  <p className="pb-2">{t("verification.upload_file_title")}</p>
                  <p>{t("verification.only1file")}</p>
                </div>
                <div className="pb-10 px-5">
                  <DropzoneBox
                    title={t("shared.dropzone.title_single") as string}
                    getRootProps={getRootProps}
                    getInputProps={getInputProps}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {verification && (
          <div className="mx-auto w-full">
            <div className="bg-white shadow rounded-3xl mb-10 py-10">
              <div className="mx-auto w-full px-6 py-6 text-3xl font-bold text-center">
                {verification.result === "Passed" && (
                  <p className="text-green-500">
                    {t("verification.file_found")}
                  </p>
                )}
                {verification.result === "Failed" && (
                  <p className="text-red-400">
                    {t("verification.file_not_found")}
                  </p>
                )}
              </div>
              <div className="shadow bg-white border-2 rounded-lg xl:mx-20 mx-5">
                <div className="bg-gray-200 rounded-t-lg">
                  <p className="ml-3 py-1 text-xl font-bold">
                    {t("verification.details")}
                  </p>
                </div>
                <div className="py-6 px-10">
                  <div className="text-left text-xl font-bold">
                    <p className="break-words">
                      {t("verification.file_hash")}{" "}
                      <span className="text-l font-normal">
                        {verification?.contentIdentifier}
                      </span>
                    </p>
                    <p className="">
                      {t("verification.result")}{" "}
                      <span
                        className={`text-l font-normal break-words ${
                          verification?.result === "Passed"
                            ? "text-green-500"
                            : "text-red-500"
                        }`}
                      >
                        {verification?.result}
                      </span>
                    </p>
                    <p className="break-words">
                      {t("verification.status")}{" "}
                      {verification.status === "Active" && (
                        <Tag status="success">{verification?.status}</Tag>
                      )}
                      {verification.status === "Archived" && (
                        <Tag status="warning">{verification?.status}</Tag>
                      )}
                      {verification.status === "Invalid" && (
                        <Tag status="error">{verification?.status}</Tag>
                      )}
                    </p>
                    <p className="break-words">{t("verification.vc_json")}</p>
                    <div className="relative shadow border-2 rounded-lg my-2 bg-black">
                      <div className="bg-gray-200 rounded-t-lg h-8"></div>
                      <div className="py-4 px-4 text-white text-base font-normal">
                        <ul>
                          {Object.entries(verification?.vc || {})
                            .slice(0, 2)
                            .map(([key, value]) => (
                              <li key={key} className="break-words">
                                {key}: {JSON.stringify(value)}
                              </li>
                            ))}
                          <li>...</li>
                        </ul>
                      </div>
                      <button
                        className="absolute top-0 right-0 p-1"
                        onClick={() => handleOpenVCModal(verification?.vc)}
                        title="Show VC details"
                      >
                        <OpenFileIcon className="w-8 h-8" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center space-x-4">
                <button
                  className="btn-secondary font-bold text-sm mt-5"
                  onClick={() => setVerification(undefined)}
                >
                  {t("verification.go_back_verification")}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal open={isLoading}>
        <div className="py-20">
          <Loading />
        </div>
      </Modal>
      <Modal open={isVCModalOpen}>
        <div className="flex flex-col ">
          <div className="p-4 space-y-5 ">
            <EntryVCModal
              data={verification?.vc}
              onClose={handleCloseVCModal}
            />
          </div>
        </div>
      </Modal>
    </AuthLayout>
  );
}

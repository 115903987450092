import Heading from "@components/layout/Heading";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useBalanceAdjustmentContext } from "@context/BalanceAdjustmentContext";
import SelectSingleSubscriberTable from "@components/table/SelectSingleSubscriberTable";

type CreateBalanceAdjustmentSelectSubscriberProps = {
  onSave: () => void;
  onGoBack: () => void;
};

const CreateBalanceAdjustmentSelectSubscriber: FunctionComponent<
  CreateBalanceAdjustmentSelectSubscriberProps
> = ({ onSave, onGoBack }) => {
  const { t } = useTranslation();
  const { balanceAdjustmentData, setBalanceAdjustmentData, navigateForCancel } =
    useBalanceAdjustmentContext();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSave();
  };

  return (
    <form className="pb-4 flex flex-col h-full" onSubmit={handleSubmit}>
      <div className="p-6 grow flex flex-col">
        <div className="space-y-4">
          <Heading light>{t("balance_adjustment.select_subscriber")}</Heading>
        </div>
        <SelectSingleSubscriberTable
          onSelect={(item: any) => {
            setBalanceAdjustmentData({
              ...balanceAdjustmentData,
              subscriber: item,
              meter: null,
              extractionRight: null,
            });
          }}
          selected={balanceAdjustmentData.subscriber}
          params={{
            level1ResourceId: balanceAdjustmentData.level1Resource?.id,
          }}
          noRowText={t("common.no_data") as string}
        />
      </div>

      <footer className="flex gap-4 -mx-2 mt-2 p-6 pb-0 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={onGoBack}
        >
          {t("common.prev_step")}
        </button>
        <button
          type="submit"
          className="btn-primary"
          disabled={!balanceAdjustmentData?.subscriber?.id}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default CreateBalanceAdjustmentSelectSubscriber;

import api from "./api";

export const getAllLevel1Resources = async () => {
  const { data } = await api.get("/admin/level1Resources");
  return data.level1Resources ?? [];
};

export const getLevel1Resource = async (id: string): Promise<any> => {
  const { data } = await api.get(`/admin/level1Resources/${id}`);

  return data.level1Resource;
};

export type CreateLevel1ResourcePayload = {
  periodStart: Date;
  periodEnd: Date;
  name: string;
  description: string;
  identifier: string;
  level2ResourceId: string;
  geography: string;
  platform: number;
  image: File;
};

export const createLevel1Resource = async (
  payload: CreateLevel1ResourcePayload
): Promise<any> => {
  const formData = new FormData();
  formData.append("name", payload.name);
  formData.append("description", payload.description);
  formData.append("identifier", payload.identifier);
  formData.append("level2ResourceId", payload.level2ResourceId);
  formData.append("geography", payload.geography);
  formData.append("platform", payload.platform.toString());
  formData.append("periodStart", payload.periodStart.toISOString());
  formData.append("periodEnd", payload.periodEnd.toISOString());
  if (payload.image) formData.append("image", payload.image);
  const { data } = await api.post("/admin/level1Resources", formData);

  return [data.level1Resource, data.workflowInstance];
};

export const checkName = async (payload: { name: string }): Promise<any> => {
  const { data } = await api.post("/admin/level1Resources/checkName", payload);

  return data.exists;
};

export const checkIdentifier = async (payload: {
  identifier: string;
}): Promise<any> => {
  const { data } = await api.post(
    "/admin/level1Resources/checkIdentifier",
    payload
  );

  return data.exists;
};

import React from "react";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import SelectSingleLevel1WRSTable from "@components/table/SelectSingleLevel1WRSTable";
import { useStepContext } from "@context/shared/StepContext";
import { usePermanentTransferContext } from "@context/PermanentTransferContext";

const SelectLevel1Resource = () => {
  const { t } = useTranslation();
  const { level1Resource, selectLevel1Resource, handleCancel } =
    usePermanentTransferContext();
  const { stepHelpers } = useStepContext();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    stepHelpers.goToNextStep();
  };

  return (
    <form
      className="flex flex-col gap-6 p-6 grow justify-between"
      onSubmit={handleSubmit}
    >
      <Heading light>{t("approval.permanent_trades.select_level1wrs")}</Heading>
      <SelectSingleLevel1WRSTable
        onSelect={(item: any) => {
          selectLevel1Resource({
            id: item.id,
            name: item.name,
          });
        }}
        selected={level1Resource}
      />

      <footer className="flex gap-4 -mx-6 mt-2 p-6 pb-0 border-t border-gray-200">
        <button
          type="submit"
          className="btn-primary"
          disabled={!level1Resource?.id}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={handleCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default SelectLevel1Resource;

import React from "react";
import { useTranslation } from "react-i18next";

import { useSeasonalTransferContext } from "@context/SeasonalTransferContext";
import Heading1 from "@components/layout/Heading";
import SelectSingleSubscriberTable from "@components/table/SelectSingleSubscriberTable";

const SeasonalTransferBuyer: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    stepHelpers,
    navigateForCancel,
    details,
    handleChangeDetails,
    validateBuyer,
    getInfoMessages,
    isSameAccount,
  } = useSeasonalTransferContext();

  return (
    <>
      <div className="p-6 flex flex-col gap-4 grow">
        <Heading1 light>
          {t("approval.seasonal_water_assignments.create.step_4.title")}
        </Heading1>

        <SelectSingleSubscriberTable
          onSelect={(selectedSubscriber: any) => {
            validateBuyer(selectedSubscriber);
            handleChangeDetails("to", selectedSubscriber, "subscriber");

            if (details.from.subscriber.id === selectedSubscriber.id) {
              handleChangeDetails(
                "description",
                t(
                  "approval.seasonal_water_assignments.create.step_5.price_option_a",
                ),
              );
            }
          }}
          selected={details?.to.subscriber}
          params={{
            level1ResourceId: details?.level1wrs?.id,
          }}
          showLevel0WRS
          showPrioritiesOfLevel0WRSIdentifier={
            details.from.level0Resource.identifier
          }
          predefinedFilter={{
            level0wrsIdentifier: details.from.level0Resource.identifier,
          }}
        />
      </div>

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>
        <button
          type="button"
          className="btn-primary"
          onClick={() => {
            stepHelpers.goToNextStep();

            if (!isSameAccount) {
              stepHelpers.goToNextStep();
            }
          }}
          disabled={
            details.to.subscriber.id === "" ||
            getInfoMessages("error", 3).length > 0
          }
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </>
  );
};

export default SeasonalTransferBuyer;

import { useMutation } from "@tanstack/react-query";

import { createEvidence, CreateEvidencePayload } from "@services/evidence";

export function useCreateEvidence(options: any = {}) {
  return useMutation<any, any, CreateEvidencePayload>({
    mutationKey: ["create_evidence"],
    mutationFn: createEvidence,
    ...options,
  });
}

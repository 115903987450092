import React, { FunctionComponent } from "react";

const WorkflowDeclarationIcon: FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="42"
      fill="none"
      viewBox="0 0 34 42"
    >
      <path
        fill="#0C3C9D"
        d="M18.375 1.375a2.119 2.119 0 00-2.77 0C5.437 10.333.332 18.125.332 24.75.333 35.125 8.25 41.833 17 41.833c8.75 0 16.667-6.708 16.667-17.083 0-6.625-5.105-14.417-15.292-23.375zM8.313 25.167c.77 0 1.395.541 1.541 1.291.854 4.625 4.75 6.209 7.584 5.98A1.567 1.567 0 0119.082 34c0 .833-.666 1.52-1.5 1.563-4.437.27-9.625-2.271-10.812-8.584a1.563 1.563 0 011.542-1.812z"
      ></path>
    </svg>
  );
};

export default WorkflowDeclarationIcon;

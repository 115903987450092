import React from "react";

interface PercentageIconProps {
  ifSmallSize?: boolean;
}

const PercentageIcon = ({ ifSmallSize = false }: PercentageIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={ifSmallSize ? "18" : "24"}
      height={ifSmallSize ? "18" : "24"}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#004A88"
        d="M20.163 3.837a.938.938 0 010 1.326l-15 15a.938.938 0 01-1.326-1.326l15-15a.937.937 0 011.326 0zM6.75 9a2.25 2.25 0 110-4.5 2.25 2.25 0 010 4.5zm0 1.5a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5zM17.25 19.5a2.25 2.25 0 110-4.5 2.25 2.25 0 010 4.5zm0 1.5a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z"
      ></path>
    </svg>
  );
};

export default PercentageIcon;

import React from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SelectLevel1Resource from "./SelectLevel1Resource";
import SelectSeller from "./SelectSeller";
import SelectExtractionRights from "./SelectExtractionRights";
import SelectBuyer from "./SelectBuyer";
import SelectExtractionPoints from "./SelectExtractionPoints";
import EnterTransferExtractionRightsVolume from "./EnterTransferExtractionRightsVolume";
import EnterDetails from "./EnterDetails";
import EnterPriceInfomation from "./EnterPriceInformation";
import ConfirmPermanentTransfer from "./ConfirmPermanentTransfer";
import Layout from "@components/layout/Layout";
import StepInfo from "@components/shared/StepInfo";
import StepButton from "@components/shared/StepButton";
import WorkflowPanels from "@components/form/WorkflowPanels";
import UploadEvidencesWorkflowForm from "@components/form/UploadEvidenceWorkflowForm";
import InfoPanel from "@components/form/InfoPanel";
import {
  PermanentTransferProvider,
  usePermanentTransferContext,
} from "@context/PermanentTransferContext";
import {
  EvidenceProvider,
  useEvidenceContext,
} from "@context/shared/EvidenceContext";
import { StepProvider, useStepContext } from "@context/shared/StepContext";
import { useSubscriber } from "@hooks/query/useSubscriber";
import { useAllSubscribers } from "@hooks/query/useAllSubscribers";
import { useCreatePermanentTransfer } from "@hooks/mutation/useCreatePermanentTransfer";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";
import { DBTables } from "@utils/constants/dbTables";
import { convertMLToLiter } from "@utils/convertUnits";

const PermanentTransfer = () => {
  return (
    <StepProvider maxStep={10}>
      <EvidenceProvider>
        <PermanentTransferProvider>
          <Consumer />
        </PermanentTransferProvider>
      </EvidenceProvider>
    </StepProvider>
  );
};

const Consumer = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const level1ResourceId = searchParams.get("level1ResourceId") ?? "";
  const sellerId = searchParams.get("sellerId") ?? "";
  const isMounted = React.useRef(false);

  const { currentStep, stepHelpers } = useStepContext();
  const {
    level1Resource,
    level0Resource,
    selectLevel1Resource,
    seller,
    selectSeller,
    buyer,
    selectedExtractionRights,
    getTransferExtractionRightNames,
    isTransferComplete,
    handleCancel,
    price,
    description,
    setWorkflowInstance,
    info,
    buyerHasExtractionPoint,
    selectedExtractionPoints,
    canEnterPrice,
  } = usePermanentTransferContext();
  const { getEvidencesInfo, uploadEvidences } = useEvidenceContext();

  const { data: subscribers = [] } = useAllSubscribers({
    params: {
      isActive: true,
      level1ResourceId: level1Resource?.id,
    },
    options: {
      refetchOnWindowFocus: true,
      enabled: Boolean(level1Resource?.id),
    },
  });

  useGetLevel1Resource(level1ResourceId, {
    enabled: Boolean(level1ResourceId),
    refetchOnWindowFocus: false,
    onSuccess: (res: any) => {
      selectLevel1Resource({
        id: res.id,
        name: res.name,
      });
    },
  });

  useSubscriber(sellerId, {
    enabled: Boolean(level1Resource?.id) && Boolean(sellerId),
    onSuccess: (res: any) => {
      if (!isMounted.current) {
        selectSeller(res);
        stepHelpers.setStep(2);
        isMounted.current = true;
      }
    },
  });

  const rightNames = getTransferExtractionRightNames();
  const hasTransferH2W = selectedExtractionRights.some(i => i.isTransferH2W);

  const { mutateAsync: createPermanentTransferMutation } =
    useCreatePermanentTransfer();
  const handleSubmit = async () => {
    const [res, workflowInstance] = await createPermanentTransferMutation({
      sellerId: seller.id,
      buyerId: buyer.id,
      extractionRights: selectedExtractionRights.map(i => ({
        id: i.id,
        transferH2W: i.isTransferH2W ? convertMLToLiter(+i.transferH2W) : 0,
        billingGroupId: i.billingGroup.id,
        itemNo: i.itemNo,
      })),
      extractionPointIds: selectedExtractionPoints.map(i => i.id),
      price: hasTransferH2W ? Number(price) : 0,
      description: hasTransferH2W ? description : "",
    });

    setWorkflowInstance(workflowInstance);

    await uploadEvidences({
      description: t("approval.permanent_trades.evidence_description", {
        sellerName: seller?.name,
        buyerName: buyer?.name,
        extractionRightNames: rightNames,
      }),
      references: [
        {
          referenceId: res.id,
          referenceTable: DBTables.AdministrativeApproval,
        },
        {
          referenceId: buyer.id,
          referenceTable: DBTables.Subscribers,
        },
        {
          referenceId: seller.id,
          referenceTable: DBTables.Subscribers,
        },
        {
          referenceId: workflowInstance?.id,
          referenceTable: DBTables.WorkflowInstances,
        },
      ],
    });
  };

  let stepIndex = 0;
  const steps = [
    {
      label: (
        <StepButton
          index={stepIndex++}
          currentStep={currentStep}
          info={<StepInfo data={info.getLevel1Resource().body} />}
          isOnChain={isTransferComplete}
        >
          {t("approval.permanent_trades.select_level1wrs")}
        </StepButton>
      ),
      panel: <SelectLevel1Resource />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("approval.permanent_trades.info.intro")}</p>
            <p>{t("approval.permanent_trades.info.volume")}</p>
            <p>{t("approval.permanent_trades.info.addition")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={stepIndex++}
          currentStep={currentStep}
          info={<StepInfo data={info.getSeller().body} />}
          isOnChain={isTransferComplete}
        >
          {t("permanent_trade.select_seller")}
        </StepButton>
      ),
      panel: (
        <SelectSeller
          subscribers={subscribers.filter((i: any) => i.id !== buyer.id)}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("approval.permanent_trades.info.select_seller")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={stepIndex++}
          currentStep={currentStep}
          info={<StepInfo data={info.getExtractionRights().body} />}
          isOnChain={isTransferComplete}
        >
          {t("permanent_trade.select_extraction_right")}
        </StepButton>
      ),
      panel: <SelectExtractionRights />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("approval.permanent_trades.info.select_extraction_right")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={stepIndex++}
          currentStep={currentStep}
          info={<StepInfo data={info.getVolumes().body} />}
          isOnChain={isTransferComplete}
        >
          <div className="space-y-6">
            <p>{t("permanent_trade.enter_volume")}</p>
          </div>
        </StepButton>
      ),
      panel: <EnterTransferExtractionRightsVolume />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("approval.permanent_trades.info.enter_volume")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={stepIndex++}
          currentStep={currentStep}
          info={<StepInfo data={info.getExtractionPoints().body} />}
          isOnChain={isTransferComplete}
        >
          {t("approval.permanent_trades.select_extraction_point")}
        </StepButton>
      ),
      panel: <SelectExtractionPoints />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("approval.permanent_trades.info.extraction_points")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={stepIndex++}
          currentStep={currentStep}
          info={<StepInfo data={info.getBuyer().body} />}
          isOnChain={isTransferComplete}
        >
          {t("permanent_trade.select_buyer")}
        </StepButton>
      ),
      panel: (
        <SelectBuyer
          subscribers={subscribers.filter((i: any) => i.id !== seller.id)}
        />
      ),
      infoPanel: (
        <InfoPanel
          errors={
            buyer.id &&
            !buyerHasExtractionPoint &&
            selectedExtractionPoints.length === 0
              ? [
                  t("approval.permanent_trades.info.no_extraction_point", {
                    buyerName: buyer.name,
                    level0ResourceIdentifier: level0Resource.identifier,
                    extractionRightName: selectedExtractionRights
                      .map(i => i.name)
                      .join(", "),
                  }),
                ]
              : []
          }
          actions={[
            {
              label: t("subscriber.add_subscriber"),
              action: () => {
                window.open(
                  "/polestar/subscribers/create?newWindow=true",
                  "_blank",
                );
              },
            },
            ...(buyer.id
              ? [
                  {
                    label: t("extraction_point.create.title"),
                    action: () => {
                      window.open(
                        `/polestar/level1wrs/${level1Resource.id}/extraction_points/create?subscriberId=${buyer.id}&level0ResourceId=${level0Resource.id}`,
                        "_blank",
                      );
                    },
                  },
                ]
              : []),
          ]}
        >
          <div className="space-y-6">
            <p>{t("approval.permanent_trades.info.select_buyer")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={stepIndex++}
          currentStep={currentStep}
          isOnChain={isTransferComplete}
          info={<StepInfo data={info.getDetails().body} />}
        >
          {t("approval.permanent_trades.enter_details.title")}
        </StepButton>
      ),
      panel: (
        <EnterDetails
          onNext={stepHelpers.goToNextStep}
          onPrev={stepHelpers.goToPrevStep}
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>
              {t(
                "approval.seasonal_water_assignments.create.step_5.info_conclusion",
              )}
            </p>
            <p>{t("extraction_right.update.step_2.info_conclusion")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={stepIndex++}
          currentStep={currentStep}
          info={<StepInfo data={info.getPriceInfo().body} />}
          isOnChain={isTransferComplete}
        >
          {t("approval.permanent_trades.enter_price.title")}
        </StepButton>
      ),
      panel: (
        <EnterPriceInfomation
          onNext={stepHelpers.goToNextStep}
          onPrev={stepHelpers.goToPrevStep}
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <InfoPanel
          warnings={[
            t("approval.seasonal_water_assignments.create.step_5.warning_1"),
          ]}
        >
          <div className="space-y-4">
            <p>
              {t(
                "approval.seasonal_water_assignments.create.step_5.info_intro",
              )}
            </p>
          </div>
        </InfoPanel>
      ),
      disabled: !canEnterPrice,
    },
    {
      label: (
        <StepButton
          index={stepIndex++}
          currentStep={currentStep}
          info={<StepInfo data={getEvidencesInfo().body} />}
          isOnChain={isTransferComplete}
        >
          {t("common.enter_evidence")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          onNext={stepHelpers.goToNextStep}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("approval.permanent_trades.info.evidence")}</p>
            <p>{t("approval.permanent_trades.info.application_form")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={stepIndex++}
          currentStep={currentStep}
          isOnChain={isTransferComplete}
        >
          {t("permanent_trade.confirm_transfer")}
        </StepButton>
      ),
      panel: <ConfirmPermanentTransfer onSubmit={handleSubmit} />,
      infoPanel: (
        <InfoPanel
          successes={
            isTransferComplete
              ? [
                  t("approval.permanent_trades.info.success", {
                    sellerName: seller?.name,
                    buyerName: buyer?.name,
                    extractionRightNames: rightNames,
                  }),
                ]
              : [t("approval.permanent_trades.info.confirm")]
          }
        >
          <div className="space-y-6">
            {isTransferComplete ? (
              t("approval.permanent_trades.info.end_workflow")
            ) : (
              <>
                <p>{t("approval.permanent_trades.info.approved")}</p>
                <p>{t("common.info.changes")}</p>
              </>
            )}
          </div>
        </InfoPanel>
      ),
    },
  ].filter(i => !i.disabled);

  return (
    <Layout
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        ...(level1ResourceId
          ? [
              {
                label: level1Resource?.name,
                href: `/polestar/level1wrs/${level1ResourceId}`,
              },
            ]
          : []),
        {
          label: t("common.administrative_approvals"),
          href: level1ResourceId
            ? `/polestar/level1wrs/${level1ResourceId}#6`
            : `/polestar/administrative_approvals#tab=1`,
        },
        {
          label: t("approval.permanent_trades.title"),
        },
      ]}
      title={t("approval.permanent_trades.title")}
      permissions={["TransferExtractionRight"]}
      accountingPeriodLevel1ResourceId={level1Resource.id}
    >
      <WorkflowPanels selectedStep={currentStep} steps={steps} />
    </Layout>
  );
};

export default PermanentTransfer;

import { t } from "i18next";

import {
  AdministrativeApprovalNumericType,
  AdministrativeApprovalNumericStatus,
} from "@services/administrativeApprovals";
import { formatDate } from "@utils/formatDate";

const statusMap: Record<number, { status: any; text: string }> = {
  [AdministrativeApprovalNumericStatus.Pending]: {
    status: "warning",
    text: t("common.pending"),
  },
  [AdministrativeApprovalNumericStatus.Approved]: {
    status: "success",
    text: t("common.approved"),
  },
  [AdministrativeApprovalNumericStatus.Declined]: {
    status: "error",
    text: t("common.rejected"),
  },
  [AdministrativeApprovalNumericStatus.UnderReview]: {
    status: "info",
    text: t("common.require_info"),
  },
  [AdministrativeApprovalNumericStatus.Abandoned]: {
    status: "error",
    text: t("common.abandoned"),
  },
  [AdministrativeApprovalNumericStatus.InDraft]: {
    status: "warning",
    text: t("common.in_draft"),
  },
};

export const getStatusAndText = (statusNumber: number) => {
  const { status, text } =
    statusNumber in statusMap
      ? statusMap[statusNumber]
      : {
          status: "error",
          text: t("common.invalid"),
        };

  return { status, text };
};

const typeMapper: Record<number, string> = {
  [AdministrativeApprovalNumericType.PT]: t(
    "approval.permanent_trades.short_title"
  ),
  [AdministrativeApprovalNumericType.SWT]: t(
    "approval.seasonal_water_assignments.short_title"
  ),
  [AdministrativeApprovalNumericType.AT]: t("approval.admin_transfer.title"),
  [AdministrativeApprovalNumericType.FD]: t("approval.forward_draws.title"),
  [AdministrativeApprovalNumericType.AME]: t(
    "approval.subdivide_and_amalgamate.create.short_title_amalgamate"
  ),
  [AdministrativeApprovalNumericType.SDE]: t(
    "approval.subdivide_and_amalgamate.create.short_title_subdivide"
  ),
  [AdministrativeApprovalNumericType.SD]: t(
    "approval.stock_and_domestic.title"
  ),
};

export const getType = (type: number) => {
  return typeMapper[type] ?? t("common.invalid");
};

export const getActionTimestamp = (data: Record<string, any>) => {
  let timestamp = data?.lodgedAt;

  switch (data?.status) {
    case AdministrativeApprovalNumericStatus.Approved:
      timestamp = data?.approvedAt;
      break;
    case AdministrativeApprovalNumericStatus.Declined:
      timestamp = data?.rejectedAt;
      break;
    case AdministrativeApprovalNumericStatus.UnderReview:
      timestamp = data?.updatedAt;
      break;
  }

  return formatDate(new Date(timestamp));
};

export const getActionByUser = (data: Record<string, any>) => {
  if (data?.status === AdministrativeApprovalNumericStatus.Approved) {
    return data?.approvedByUser;
  } else if (data?.status === AdministrativeApprovalNumericStatus.Declined) {
    return data?.rejectedByUser;
  }

  return data?.lodgedByUser;
};

export const getEvidenceDescription = (approval: any) => {
  switch (approval.type) {
    case AdministrativeApprovalNumericType.PT:
      return t("approval.permanent_trades.evidence_description", {
        sellerName: approval?.seller?.name,
        buyerName: approval?.buyer?.name,
        extractionRightNames: approval?.extractionRights
          .map((i: any) => i.name)
          .join(", "),
      });

    case AdministrativeApprovalNumericType.SWT:
      return t(
        "approval.seasonal_water_assignments.create.step_6.description",
        {
          buyerName: approval?.buyer?.name,
          sellerName: approval?.seller?.name,
        }
      );

    case AdministrativeApprovalNumericType.AT:
      return t("approval.admin_transfer.create.step_5.description", {
        extractionRightName: approval?.extractionRights?.[0]?.name,
        extractionPointName: approval?.extractionPoint?.name,
      });

    case AdministrativeApprovalNumericType.FD:
      return t("approval.forward_draws.evidence_description", {
        extractionRightNames: approval?.extractionRights
          ?.map((i: any) => i.name)
          ?.join(", "),
      });

    case AdministrativeApprovalNumericType.SD:
      return t("approval.stock_and_domestic.evidence_description", {
        extractionRightName: approval?.extractionRights?.[0].name,
        subscriberName: approval?.buyer?.name,
      });
    default:
      return t("common.invalid");
  }
};

import {
  ChangeEvent,
  FunctionComponent,
  InputHTMLAttributes,
  ReactNode,
  useState,
} from "react";
import { noop, orderBy, values } from "lodash";
import { t as translate } from "i18next";
import { useTranslation } from "react-i18next";

import DoubleCaretLeft from "@components/icons/DoubleCaretLeft";
import DoubleCaretRight from "@components/icons/DoubleCaretRight";
import Alert from "@components/shared/Alert";
import Label from "./Label";
import SearchInput from "./SearchInput";

type SelectionOption = {
  label: string;
  value: any;
};

type GroupOption = {
  label: string;
  options: SelectionOption[];
};

type AdvSelectProps = {
  groupOptions: GroupOption[];
  value?: GroupOption[];
  labelLeft?: ReactNode;
  labelRight?: ReactNode;
  size?: number;
  onChange?: (groupOptions: GroupOption[]) => void;
  filterLabel: string;
};

const filterBySelectedOptions = (
  base: GroupOption[],
  matcher: GroupOption[]
) => {
  const result: GroupOption[] = [];

  base.forEach((baseItem) => {
    let filtered = [...baseItem.options];

    matcher.forEach((matchItem) => {
      if (matchItem.label !== baseItem.label) {
        return;
      }

      const selectedOptionValues = matchItem.options.map((i) => i.value);
      filtered = filtered.filter(
        (i) => !selectedOptionValues.includes(i.value)
      );
    });

    if (filtered.length) {
      result.push({
        ...baseItem,
        options: filtered,
      });
    }
  });

  return result;
};

const filterByOptionLabel = (groupOptions: GroupOption[], matcher: string) => {
  const regex = new RegExp(
    matcher.replace(/[#-.]|[[-^]|[?|{}]/g, "\\$&"),
    "ig"
  );
  const result: GroupOption[] = [];

  groupOptions.forEach((item) => {
    const filteredOptions = item?.options.filter((opt) =>
      opt.label.match(regex)
    );
    if (filteredOptions?.length) {
      result.push({
        ...item,
        options: filteredOptions,
      });
    }
  });

  return result;
};

const mergedGroupOptions = (base: GroupOption[], update: GroupOption[]) => {
  const merged: any = {};

  const callback = (item: GroupOption) => {
    if (merged[item.label]) {
      merged[item.label].options = [
        ...merged[item.label].options,
        ...item.options,
      ];
      return;
    }
    merged[item.label] = { ...item };
  };

  base.forEach(callback);
  update.forEach(callback);

  return values(merged);
};

const AdvSelect: FunctionComponent<AdvSelectProps> = ({
  groupOptions,
  value: selectedGroupOptions = [],
  labelLeft = translate("level0wrs.create.available_zones"),
  labelRight,
  size = 10,
  onChange = noop,
  filterLabel,
}) => {
  const { t } = useTranslation();
  const [selectedLeftValues, setSelectedLeftValues] = useState<string[]>([]);
  const [selectedRightValues, setSelectedRightValues] = useState<string[]>([]);
  const [filter, setFilter] = useState("");

  const handleChange = (selected: GroupOption[]) => {
    setSelectedLeftValues([]);
    setSelectedRightValues([]);
    onChange(selected);
  };

  const handleAddOptions = () => {
    if (!selectedLeftValues.length) {
      return;
    }

    let selected: GroupOption[] = [];
    groupOptions.forEach(({ label, options }) => {
      const filtered = options.filter((i) =>
        selectedLeftValues.some((v) => i.value === v)
      );

      if (filtered.length) {
        selected.push({
          label,
          options: filtered,
        });
      }
    });

    const merged = mergedGroupOptions(selectedGroupOptions, selected);
    handleChange(merged);
  };

  const handleRemoveOptions = () => {
    if (!selectedRightValues.length) {
      return;
    }

    let selected: GroupOption[] = [];
    selectedGroupOptions.forEach((group) => {
      const options = group.options.filter((i) =>
        selectedRightValues.every((selected) => i.value !== selected)
      );

      if (options.length) {
        selected.push({
          label: group.label,
          options: orderBy(options, (i) => i.label),
        });
      }
    });

    handleChange(selected);
  };

  const getSelectedValues = (selected: HTMLCollectionOf<HTMLOptionElement>) =>
    Array.from(selected, (o) => o.value);

  const filteredByLabel = filter.trim().length
    ? filterByOptionLabel(groupOptions, filter)
    : groupOptions;

  const isSelectedDifferentLevel1WRS =
    selectedGroupOptions.map((i) => i.label).length > 1;

  return (
    <div className="space-y-4">
      <div className="max-w-xs">
        <Label htmlFor="filter">{filterLabel}</Label>
        <SearchInput
          id="filter"
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
        />
      </div>
      {isSelectedDifferentLevel1WRS ? (
        <Alert type="warning">
          {t("level0wrs.create.warning_different_level1resource")}
        </Alert>
      ) : null}
      <div className="flex gap-4 items-stretch">
        <MultiSelect
          label={labelLeft}
          size={size}
          groupOptions={filterBySelectedOptions(
            filteredByLabel,
            selectedGroupOptions
          )}
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            setSelectedLeftValues(getSelectedValues(e.target.selectedOptions))
          }
        />
        <div className="flex flex-col justify-center gap-4">
          <button type="button" onClick={handleAddOptions}>
            <DoubleCaretRight />
          </button>
          <button type="button" onClick={handleRemoveOptions}>
            <DoubleCaretLeft />
          </button>
        </div>
        <MultiSelect
          label={labelRight}
          size={size}
          groupOptions={selectedGroupOptions}
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            setSelectedRightValues(getSelectedValues(e.target.selectedOptions))
          }
        />
      </div>
    </div>
  );
};

interface MultiSelectProps extends InputHTMLAttributes<HTMLSelectElement> {
  groupOptions: GroupOption[];
  label?: ReactNode;
}

const MultiSelect: FunctionComponent<MultiSelectProps> = ({
  groupOptions = [],
  multiple = true,
  label,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex flex-col">
      {label ? <Label>{label}</Label> : null}

      <select
        {...props}
        multiple={multiple}
        className="grow appearance-none rounded-sm border border-gray-300 px-3 py-2 placeholder-gray-400 focus:outline-blue-500"
      >
        {groupOptions.length ? (
          groupOptions.map((i: GroupOption) => {
            return (
              <optgroup key={i.label} label={i.label}>
                {i.options.map(({ label, value }, index) => (
                  <option
                    key={`${label}--${index}`}
                    value={value}
                    className="py-1 first-of-type:mt-1 cursor-pointer hover:bg-blue-50"
                  >
                    {label}
                  </option>
                ))}
              </optgroup>
            );
          })
        ) : (
          <option disabled className="text-center text-gray-400">
            {t("common.no_data")}
          </option>
        )}
      </select>
    </div>
  );
};

export default AdvSelect;

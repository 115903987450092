import classNames from "classnames";
import { ReactNode } from "react";

type CirclePercentageProps = {
  borderSize?: number;
  scale: number;
  bgColor?: string;
  borderColor?: string;
  children?: ReactNode;
};

const CirclePercentage: React.FunctionComponent<CirclePercentageProps> = ({
  borderSize = 8,
  scale,
  bgColor = "#555555",
  borderColor = "#000000",
  children,
}) => {
  return (
    <div
      className={classNames("relative text-center rounded-full w-full")}
      style={{
        height: `${borderSize}px`,
        backgroundColor: bgColor,
      }}
    >
      <div
        className={classNames(
          "rounded-full text-center relative flex justify-center items-center"
        )}
        style={{
          top: 0,
          left: 0,
          width: `${scale * 100}%`,
          height: `${borderSize}px`,
          backgroundColor: borderColor,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default CirclePercentage;

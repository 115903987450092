import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Link, generatePath } from "react-router-dom";
import classNames from "classnames";
import { get, noop } from "lodash";
import Pagination from "./Pagination";
import Loading from "@components/shared/Loading";
import { t } from "i18next";

type TableProps = {
  containerClassName?: string;
  tableHeaderClassName?: string;
  paginationClassName?: string;
  className?: string;
  fields: Array<{
    title: string;
    name: string;
    linkTo?: string;
  }>;
  data: Array<Record<string, any>>;
  noRowsText?: ReactNode | null;
  pageSize?: number;
  selectionKey?: string;
  selectedKeys?: any[];
  disabledKeys?: any[];
  clearSelectionOnDataChanged?: boolean;
  onSelectionChange?: (data: any[]) => void;
  stickyHeader?: boolean;
  loading?: boolean;
  totals?: Array<{
    justifyRight?: boolean;
    value?: ReactNode | string | number | null;
  }>;
  multiple?: boolean;
};

const Table: React.FunctionComponent<TableProps> = ({
  containerClassName,
  tableHeaderClassName,
  paginationClassName,
  className,
  fields,
  data = [],
  noRowsText = t("common.no_data"),
  pageSize = 100,
  selectionKey,
  selectedKeys = [],
  disabledKeys = [],
  onSelectionChange = noop,
  stickyHeader = false,
  loading = false,
  clearSelectionOnDataChanged = false,
  totals = [],
  multiple = true,
}) => {
  const [pageNo, setPageNo] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const tableWrapperRef = useRef<HTMLDivElement>(null);

  const isSelectedAll =
    selectedKeys.length > 0 && selectedKeys.length === data?.length;

  // ref: https://github.com/facebook/react/issues/15282
  const latestOnChange = useRef(onSelectionChange);
  useEffect(() => {
    latestOnChange.current = onSelectionChange;
  }, [onSelectionChange]);

  useEffect(() => {
    if (data?.length && pageSize) {
      const newLastPage = Math.ceil(data?.length / pageSize);
      setLastPage(newLastPage);
      if (data?.length <= pageSize) {
        setPageNo(0);
      } else if (newLastPage < pageNo) {
        setPageNo(newLastPage - 1);
      }
    } else if (!data?.length && clearSelectionOnDataChanged) {
      latestOnChange.current([]);
    }
  }, [pageSize, pageNo, data, clearSelectionOnDataChanged]);

  const thClassNames = classNames(
    "backdrop-blur-none table-cell backdrop-filter text-left font-semibold px-2 py-4 first-of-type:pl-4 bg-opacity-100 bg-gray-50 border-b-opacity-100",
    {
      "sticky top-0 z-0": stickyHeader,
    },
  );

  const handleDataSelectionClick = (record: any) => {
    const recordIndex = selectionKey
      ? selectedKeys.indexOf(record[selectionKey])
      : -1;
    let updatedSelectedRecords = [...selectedKeys];

    if (multiple) {
      if (recordIndex === -1) {
        updatedSelectedRecords.push(record[selectionKey as string]);
      } else {
        updatedSelectedRecords.splice(recordIndex, 1);
      }
    } else {
      updatedSelectedRecords =
        recordIndex === -1 ? [record[selectionKey as string]] : [];
    }

    latestOnChange.current(updatedSelectedRecords);
  };

  const handleSelectAllClick = () => {
    const updatedSelectedRecords = isSelectedAll
      ? []
      : data.map((record) => record[selectionKey as string]);
    latestOnChange.current(updatedSelectedRecords);
  };

  const handlePageChange = (page: number) => {
    setPageNo(page);
    if (tableWrapperRef.current) {
      tableWrapperRef.current.scrollTo(0, 0);
    }
  };

  return (
    <div className="flex flex-col grow pb-3">
      <div
        ref={tableWrapperRef}
        className={classNames(
          "md:rounded relative overflow-auto grow border border-gray-200",
          {
            "h-0": stickyHeader,
          },
          containerClassName,
        )}
      >
        <table
          className={classNames(
            "table-auto min-w-full divide-y divide-gray-200 text-sm",
            className,
          )}
        >
          <thead className={classNames("bg-gray-50", tableHeaderClassName)}>
            <tr>
              {selectionKey && (
                <th scope="col" className={thClassNames}>
                  {multiple ? (
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 mt-1"
                      checked={isSelectedAll}
                      onChange={handleSelectAllClick}
                      disabled={!!disabledKeys.length}
                    />
                  ) : null}
                </th>
              )}
              {fields?.map((th, i) => (
                <th
                  key={`${th.name}:${i}`}
                  scope="col"
                  className={classNames(
                    "align-top whitespace-nowrap text-base",
                    thClassNames,
                  )}
                >
                  {th.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {loading ? (
              <tr>
                <td colSpan={fields.length} className="pt-10">
                  <Loading />
                </td>
              </tr>
            ) : data?.length ? (
              [...data]
                ?.slice(
                  pageSize ? pageNo * pageSize : 0,
                  pageSize ? pageNo * pageSize + pageSize : data?.length,
                )
                ?.map((td, i) => (
                  <tr key={`tbody-row--${i}`}>
                    {selectionKey && (
                      <td
                        key={`td-checkbox--${i}`}
                        className="whitespace-nowrap px-2 py-3 first-of-type:pl-4"
                      >
                        <input
                          type={multiple ? "checkbox" : "radio"}
                          className="h-4 w-4 rounded border-gray-300 mt-1"
                          checked={selectedKeys.includes(td[selectionKey])}
                          onChange={() => handleDataSelectionClick(td)}
                          disabled={disabledKeys.includes(td[selectionKey])}
                        />
                      </td>
                    )}
                    {fields.map((field, j) => (
                      <td
                        key={`${i}_${j}`}
                        className={classNames(
                          "whitespace-nowrap px-2 py-3 first-of-type:pl-4",
                          get(td, `cellClassName.${field.name}`),
                        )}
                      >
                        {field.linkTo ? (
                          <Link
                            to={generatePath(field.linkTo, td)}
                            className="text-indigo-700 underline"
                          >
                            {get(td, field.name)}
                          </Link>
                        ) : (
                          get(td, field.name)
                        )}
                      </td>
                    ))}
                  </tr>
                ))
            ) : (
              <tr>
                <td
                  colSpan={selectionKey ? fields.length + 1 : fields.length}
                  className="whitespace-nowrap text-gray-400 text-center h-28"
                >
                  {noRowsText}
                </td>
              </tr>
            )}
            {totals?.length > 0 && (
              <tr className="bg-gray-50">
                {fields.map((_, i) => {
                  return (
                    <td
                      key={`${i}`}
                      className={classNames(
                        "whitespace-nowrap px-2 py-3 first-of-type:pl-4 font-bold",
                        totals[i]?.justifyRight ? "text-right" : "",
                      )}
                    >
                      {totals[i]?.value}
                    </td>
                  );
                })}
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {data?.length > pageSize && (
        <Pagination
          pageNo={pageNo}
          lastPage={lastPage}
          onPageChange={handlePageChange}
          className={paginationClassName}
        />
      )}
    </div>
  );
};

export default Table;

import Label from "@components/form/Label";
import Legend from "@components/form/Legend";
import TextArea from "@components/form/TextArea";
import TextInput from "@components/form/TextInput";
import { useAdminTransferContext } from "@context/AdminTransferContext";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type WorkflowCreateAdminApprovalStep3Props = {
  onSubmit: () => void;
  onCancel: () => void;
};

const WorkflowCreateAdminApprovalStep3: FunctionComponent<
  WorkflowCreateAdminApprovalStep3Props
> = ({ onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message, setMessage } = useAdminTransferContext();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form className="flex flex-col grow" onSubmit={handleSubmit}>
      <fieldset className="w-full flex flex-col grow gap-2">
        <Legend>{t("notification.message")}</Legend>
        <div>
          <Label>{t("notification.enter_subject")}</Label>
          <TextInput
            type="text"
            required
            value={message?.subject}
            onChange={(e) =>
              setMessage({ ...message, subject: e.target.value })
            }
          />
        </div>
        <div>
          <Label>{t("notification.enter_body")}</Label>
          <TextArea
            className="h-64"
            required
            value={message?.body}
            onChange={(e) => setMessage({ ...message, body: e.target.value })}
          />
        </div>
      </fieldset>
      <footer className="p-4 flex gap-2">
        <button
          type="button"
          className="btn-outline-primary text-sm font-semibold"
          onClick={onCancel}
        >
          {t("common.prev_step")}
        </button>
        <button type="submit" className="btn-primary text-sm font-semibold">
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-default text-sm font-semibold"
          onClick={() => navigate("/zenith/workflows")}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default WorkflowCreateAdminApprovalStep3;

import React from "react";
import { useTranslation } from "react-i18next";

import { useAdminTransferContext } from "@context/AdminTransferContext";
import Heading1 from "@components/layout/Heading";
import SelectSingleSubscriberTable from "@components/table/SelectSingleSubscriberTable";

const AdminTransferSelectSubscriber = () => {
  const { t } = useTranslation();
  const { stepHelpers, details, handleChangeDetails, navigateForCancel } =
    useAdminTransferContext();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    stepHelpers.goToNextStep();
  };

  return (
    <form
      className="flex flex-col gap-6 p-6 grow justify-between"
      onSubmit={handleSubmit}
    >
      <div className="space-y-4">
        <Heading1 light>
          {t("approval.admin_transfer.create.step_2.select_subscriber")}
        </Heading1>
      </div>
      <SelectSingleSubscriberTable
        onSelect={(item: any) => {
          handleChangeDetails("subscriber", item);
          handleChangeDetails("extractionRight", { id: "" });
          handleChangeDetails("extractionPoint", { id: "" });
        }}
        selected={details.subscriber}
        params={{
          level1ResourceId: details.level1wrs?.id,
        }}
        noRowText={
          t("approval.admin_transfer.create.step_2.no_row_text", {
            level1wrsName: details.level1wrs?.name,
          }) as string
        }
      />

      <footer className="flex gap-4 -mx-2 mt-2 p-6 pb-0 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>

        <button
          type="button"
          className="btn-primary"
          onClick={() => stepHelpers.goToNextStep()}
          disabled={details.subscriber?.id === ""}
        >
          {t("common.next_step")}
        </button>

        <button
          type="button"
          className="btn-outline-primary"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default AdminTransferSelectSubscriber;

import React from "react";
import { useSearchParams } from "react-router-dom";

import { useExtractionPoint } from "@hooks/query/useExtractionPoint";
import { useSubscriber } from "@hooks/query/useSubscriber";
import { useTranslation } from "react-i18next";
import type { ConfirmData } from "@components/shared/ConfirmationDetail";

type ContextValue = {
  details: Details;
  setDetails: React.Dispatch<React.SetStateAction<Details>>;
  isComplete: boolean;
  setIsComplete: any;
  getExtractionPointDetails: () => ConfirmData;
  getMeterDetails: () => ConfirmData;
  workflowInstance: any;
  setWorkflowInstance: React.Dispatch<any>;
};

type Details = {
  subscriber: {
    id: string;
    name: string;
    walletId: string;
    level1ResourceId: string;
  };
  extractionPoint: {
    id: string;
    name: string;
    level0ResourceId: string;
    isActive: boolean;
    oldMeter: { id: string; serialNo: string };
  };
  meter: {
    id: string;
    serialNo: string;
  };
};

const LinkMeterContext = React.createContext<ContextValue | undefined>(
  undefined
);

const LinkMeterProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const subscriberId = searchParams.get("subscriberId") ?? "";
  const extractionPointId = searchParams.get("extractionPointId") ?? "";
  const [details, setDetails] = React.useState({
    subscriber: {
      id: "",
      name: "",
      walletId: "",
      level1ResourceId: "",
    },
    extractionPoint: {
      id: "",
      name: "",
      level0ResourceId: "",
      isActive: false,
      oldMeter: { id: "", serialNo: "" },
    },
    meter: {
      id: "",
      serialNo: "",
    },
  });
  const [isComplete, setIsComplete] = React.useState(false);
  const [workflowInstance, setWorkflowInstance] = React.useState<any>();

  useExtractionPoint(extractionPointId, {
    onSuccess: (data: any) => {
      setDetails((prev) => ({
        ...prev,
        extractionPoint: {
          id: data.id,
          name: data.name,
          level0ResourceId: data.level0ResourceId,
          isActive: data.isActive,
          oldMeter: {
            id: data.meter?.id ?? "",
            serialNo: data.meter?.serialNo ?? "",
          },
        },
      }));
    },
  });
  useSubscriber(subscriberId, {
    onSuccess: (data: any) => {
      setDetails((prev) => ({
        ...prev,
        subscriber: {
          id: data.id,
          name: data.name,
          walletId: data.walletId,
          level1ResourceId: data.level1ResourceId,
        },
      }));
    },
  });

  const getExtractionPointDetails = () => {
    return {
      title: t("extraction_point.link_meter.extraction_point_details"),
      body: [
        {
          key: t("subscriber.name"),
          value: details.subscriber.name,
        },
        {
          key: t("extraction_point.name"),
          value: details.extractionPoint.name,
        },
      ],
    };
  };

  const getMeterDetails = () => {
    return {
      title: t("extraction_point.link_meter.meter_to_link"),
      body: [
        {
          key: t("meter.serial_no"),
          value: details?.meter?.serialNo,
        },
      ],
    };
  };

  const value = {
    details,
    setDetails,
    isComplete,
    setIsComplete,
    workflowInstance,
    setWorkflowInstance,
    getExtractionPointDetails,
    getMeterDetails,
  };

  return (
    <LinkMeterContext.Provider value={value}>
      {children}
    </LinkMeterContext.Provider>
  );
};

function useLinkMeterContext() {
  const context = React.useContext(LinkMeterContext);
  if (context === undefined) {
    throw new Error(
      "useLinkMeterContext must be used within a LinkExtractionPointToMeterProvider"
    );
  }
  return context;
}

export { LinkMeterProvider, useLinkMeterContext };
